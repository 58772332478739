import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import AddButton from '../../../../Automations/common/addButton';
import reactionExamples from './_data/examples';
import AutomationText from './ui/automationText';
import ReactorIconsTemplate from './ui/iconChain';
import reactors from './_data/templates/reactors';

export default function ExamplesView({ setPhase }) {
  const { setFieldValue } = useFormikContext();
  const handleChoose = useCallback((ex) => {
    const reactorTemplate = reactors[ex.reactor];
    setFieldValue('action', { possible_action_id: ex.id, metadata: reactorTemplate.initialMetadata, _isComplete: false });
    setPhase(2);
  }, [setPhase, setFieldValue]);
  return (
    <div className="examples">
      {reactionExamples.map((ex) => (
        <div className="exbox" key={ex.id} onClick={() => handleChoose(ex)}>
          {/* onClick={() => !ex.isPremium && handleSelect(ex)} */}
          <div className="exbox-text">
            <AutomationText reactorAbbr={ex.reactor} isExample />
          </div>
          <div className="exbox-bottom">
            <ReactorIconsTemplate reactorAbbr={ex.reactor} />
            <AddButton isPremium={ex.isPremium} />
          </div>
        </div>
      ))}
    </div>
  );
}
