import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { ReactComponent as CheckIco } from '../../../Assets/Images/icons/checkmark-csv.svg';

export default function SelectItem({
  onClick, label, icon, itemKey, isSelected,
}) {
  const listeners = useMemo(() => {
    const invocation = () => onClick(itemKey);
    return {
      onClick: invocation,
      onKeyDown: (e) => (['Enter', ' '].includes(e.key) ? invocation() : null),
    };
  }, [onClick, itemKey]);
  return (
    <motion.div
      className={`item ${isSelected ? 'isSelected' : ''}`}
      tabIndex={0}
      key={itemKey}
      // layoutScroll
      // layout="position"
      // layoutId={`lnc-select-item-${itemKey}`}
      {...listeners}
    >
      <span>{icon}</span>
      <span className="item-label">{label}</span>
      <span className="item-check">
        <CheckIco />
      </span>
    </motion.div>
  );
}
