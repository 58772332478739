/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-bind */
import {
  Col, Layout, List, Row, Collapse,
} from 'antd';
import React, { Component } from 'react';
import { toJS, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import Dal from '../../Data/Dal';
import IntegrationCardView from './IntegrationCardView';
import IntegrationDetailsEmptyStateView from './IntegrationDetailsEmptyStateView';
import IntegrationModal from '../../Integrations/Facebook/Groups/connectWithFacebook';
import IntegrationDetailsView from './integrationDetailsView';
import FacebookPageModal from '../../Integrations/Facebook/Pages/facebookPageModal';
import CSVModal from '../../Integrations/CSV/modal';
import MessengerModal from '../../Integrations/Facebook/Messenger/connectMessengerModal';
import TwitterModal from '../../Integrations/Twitter/twitter';
import SlackModal from '../../Integrations/Slack/slackModal';
import ZendeskModal from '../../Integrations/Zendesk/zendesk';
import IntercomModal from '../../Integrations/Intercom/intercom';
import RedditModal from '../../Integrations/Reddit/redditModal';
import SalesforceModal from '../../Integrations/Salesforce/salesforce';
import expandIcon from '../../Assets/Images/icons/menuchevron-chevron-down-grey.svg';
import premiumMiddlewareCheck from '../../Components/PremiumModal/premiumMiddleware';
import './styles.scss';
import BasicTokenModal from '../../Integrations/BasicIntegrationModal/BasicToken';
import BasicOAuth from '../../Integrations/BasicIntegrationModal/BasicOAuth';

const { Panel } = Collapse;

const { Header, Content } = Layout;

class IntegrationsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIntegrationModalOpen: false,
      selectedIntegration: null,
      isRedirectedFromSlack: false,
    };
    this.integrationClicked = this.integrationClicked.bind(this);
    this.assetAdded = this.assetAdded.bind(this);
    this.addAsset = this.addAsset.bind(this);
    this.slackConnected = this.slackConnected.bind(this);
    this.isCardSelected = this.isCardSelected.bind(this);
    this.onIntegrationSuccess = this.onIntegrationSuccess.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const twitter = params.get('twitter');
    const reddit = params.get('reddit');
    const intercom = params.get('intercom');
    const salesforce = params.get('salesforce');

    if (!window.location.search && Dal.isAfterOnboarding()) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'S7_Platforms',
      });
    }

    if (twitter) {
      const twIntegration = Dal.integrations.find(
        (i) => i.targetTag === 'twitter',
      );
      this.integrationClicked(twIntegration, () => this.setState({ isIntegrationModalOpen: true }));
    }
    if (reddit) {
      const rdtIntegration = Dal.integrations.find(
        (i) => i.targetTag === 'reddit',
      );
      this.integrationClicked(rdtIntegration, () => this.setState({ isIntegrationModalOpen: true }));
    }
    if (intercom) {
      const icmIntegration = Dal.integrations.find(
        (i) => i.targetTag === 'intercom',
      );
      this.integrationClicked(icmIntegration, () => this.setState({ isIntegrationModalOpen: true }));
    }
    if (salesforce) {
      const sfIntegration = Dal.integrations.find(
        (i) => i.targetTag === 'salesforce',
      );
      this.integrationClicked(sfIntegration, () => this.setState({ isIntegrationModalOpen: true }));
      Dal.getAssets();
    }

    const importCsv = params.get('importCsv');
    if (importCsv !== null) {
      const importCsvIntegration = Dal.integrations.find(
        (i) => i.assetTag === 'csv',
      );
      this.integrationClicked(importCsvIntegration, () => this.setState({ isIntegrationModalOpen: true }));
    }

    const slack = params.get('slack');
    if (slack) {
      const slackIntegration = Dal.integrations.find(
        (x) => x.title === 'Slack workspace',
      );
      this.integrationClicked(slackIntegration, () => this.setState({
        isIntegrationModalOpen: true,
        isRedirectedFromSlack: true,
      }));
    }
    runInAction(() => {
      Dal.selectedMenuIndex = '3';
    });
  }

  /**
   * @param {string} str - "premium" or "available"
   */
  getIntegrations(type) {
    const compare = (v) => v.isPremium === (type === 'premium') && !v.isHidden;
    return Dal.integrations.filter(compare);
  }

  getWidth() {
    if (window.screen.width < 1500) {
      return 16;
    }
    return 15;
  }

  getAssets(integration) {
    const assets = [];
    integration.assets.forEach((asset) => {
      const a = assets.find((item) => item.id === asset.id);
      if (a === undefined) {
        assets.push(asset);
      }
    });
    return assets;
  }

  getModalForService() {
    const title = this.state.selectedIntegration?.title;
    switch (title) {
      case 'Facebook Group':
        return (
          <div>
            <div className="facebookBackdrop" />
            <Row justify="center" style={{ height: 0 }}>
              <IntegrationModal
                closeModal={this.assetAdded}
                onIntegrationSuccess={this.onIntegrationSuccess}
                closeModalWithoutFetching={this.closeModalWithoutFetching.bind(
                  this,
                )}
              />
            </Row>
          </div>
        );
      case 'Facebook Page':
        return (
          <FacebookPageModal
            isVisible
            closeModal={this.assetAdded}
            onIntegrationSuccess={this.onIntegrationSuccess}
            closeModalWithoutFetching={this.closeModalWithoutFetching.bind(
              this,
            )}
          />
        );
      case 'CSV, Excel':
        return (
          <CSVModal
            closeModal={this.assetAdded}
            onIntegrationSuccess={() => {
              this.onIntegrationSuccess();
              this.refreshAssetsAfterConnect();
            }}
            closeModalWithoutFetching={() => this.setState({ isIntegrationModalOpen: false })}
          />
        );

      case 'Facebook messenger':
        return (
          <MessengerModal
            isVisible
            closeModal={this.assetAdded}
            closeModalWithoutFetching={() => this.setState({ isIntegrationModalOpen: false })}
          />
        );

      case 'Twitter page':
        return (
          <TwitterModal
            closeModalWithoutFetching={this.closeModalWithoutFetching.bind(
              this,
            )}
            isVisible
            closeModal={this.assetAdded}
            onIntegrationSuccess={this.onIntegrationSuccess}
            assetAdded={this.refreshAssetsAfterConnect.bind(this)}
            closeTwitterModal={this.closeTwitterModal}
          />
        );

      case 'Slack workspace':
        return (
          <SlackModal
            isVisible
            isRedirectedFromSlack={this.state.isRedirectedFromSlack}
            onIntegrationSuccess={this.onIntegrationSuccess}
            closeModalWithoutFetching={this.closeSlackModal.bind(this)}
            slackConnected={this.slackConnected}
          />
        );

      case 'Zendesk account':
        return (
          <ZendeskModal
            isVisible
            closeModalWithoutFetching={this.closeModalWithoutFetching.bind(
              this,
            )}
            assetAdded={this.assetAdded}
            zendeskConnected={this.zendeskConnected.bind(this)}
          />
        );
      case 'Pendo Workspace':
        return (
          <BasicTokenModal
            closeModalWithoutFetching={this.closeModalWithoutFetching.bind(
              this,
            )}
            assetAdded={this.assetAdded}
            serviceName="pendo"
            serviceType="workspace"
          />
        );
      case 'Mixpanel Project':
        return (
          <BasicTokenModal
            closeModalWithoutFetching={this.closeModalWithoutFetching.bind(
              this,
            )}
            assetAdded={this.assetAdded}
            serviceName="mixpanel"
            serviceType="project"
          />
        );
      case 'Hubspot Account':
        return (
          <BasicOAuth
            closeModalWithoutFetching={this.closeModalWithoutFetching.bind(
              this,
            )}
            assetAdded={this.assetAdded}
            serviceName="hubspot"
            serviceType="account"
          />
        );
      case 'Intercom workspace':
        return (
          <IntercomModal
            isVisible
            closeModalWithoutFetching={this.closeSuccessModal.bind(this)}
          />
        );
      case 'Reddit subreddit':
        return (
          <RedditModal
            isVisible
            closeModalWithoutFetching={this.closeModalWithoutFetching.bind(
              this,
            )}
            onIntegrationSuccess={this.onIntegrationSuccess}
            assetAdded={this.assetAdded}
            subredditsConnected={this.refreshAssetsAfterConnect.bind(this)}
          />
        );
      case 'Salesforce account':
        return (
          <SalesforceModal
            onClose={this.closeSuccessModal.bind(this)}
            getSalesforceInsights={this.salesforceConnected.bind(this)}
          />
        );
      default:
        return <></>;
    }
  }

  isCardSelected(integration) {
    integration = toJS(integration); // eslint-disable-line
    const defaultIntegration = toJS(Dal.defaultIntegration);
    const selectedIntegration = toJS(this.state.selectedIntegration);
    if (!selectedIntegration) {
      if (
        integration
        && defaultIntegration
        && integration.title === defaultIntegration.title
      ) {
        return true;
      }
    } else if (
      integration.title === selectedIntegration.title
      && integration.connected
    ) {
      return true;
    }
    return false;
  }

  async assetAdded(keepOpen) {
    await Dal.fetchUser();
    const selected = Dal.integrations.find(
      (integration) => this.state.selectedIntegration.title === integration.title,
    );
    this.setState({
      isIntegrationModalOpen: keepOpen ?? false,
      selectedIntegration: selected,
    });
  }

  async refreshAssetsAfterConnect() {
    await Dal.fetchUser();
    const selected = Dal.integrations.find(
      (integration) => this.state.selectedIntegration.title === integration.title,
    );
    this.setState({ selectedIntegration: selected });
  }

  async zendeskConnected() {
    await Dal.fetchUser();
    const selected = Dal.integrations.find(
      (integration) => integration.targetTag === 'zendesk',
    );
    this.setState({
      selectedIntegration: selected,
      isIntegrationModalOpen: false,
    });
  }

  closeTwitterModal() {
    this.closeModalWithoutFetching();
  }

  closeModalWithoutFetching() {
    window.history.replaceState({}, '', '/dashboard/integrations');
    this.setState({ isIntegrationModalOpen: false });
  }

  closeSlackModal() {
    this.closeModalWithoutFetching();
    this.setState({ isRedirectedFromSlack: false });
    window.history.replaceState({}, '', '/dashboard/integrations');
  }

  closeSuccessModal() {
    this.closeModalWithoutFetching();
    window.history.replaceState({}, '', '/dashboard/integrations');
  }

  addAsset(integration) {
    this.integrationClicked(integration, () => this.setState({ isIntegrationModalOpen: true }));
  }

  slackConnected() {
    const slack = Dal.integrations.find((x) => x.title === 'Slack workspace');
    this.setState({
      selectedIntegration: slack,
      isIntegrationModalOpen: false,
    });
    window.history.replaceState({}, '', '/dashboard/integrations');
  }

  salesforceConnected() {
    window.history.replaceState({}, '', '/dashboard/integrations');
    this.setState({ isIntegrationModalOpen: false });
  }

  onIntegrationSuccess() {
    if (Dal.isAfterOnboarding()) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'S8_Connection' });
    }
  }

  integrationClicked(item, callback) {
    if (this.state.isRedirectedFromSlack && !item) {
      window.location.pathname = 'home';
    } else if (item.supportedNow && !item.connected) {
      this.setState({
        selectedIntegration: item,
        isIntegrationModalOpen: true,
      });
    } else {
      this.setState(
        { selectedIntegration: item },
        callback ? callback() : null,
      );
    }
  }

  render() {
    return (
      <>
        <div style={{ backgroundColor: 'white', height: '100%' }}>
          {this.state.isIntegrationModalOpen ? this.getModalForService() : null}
          <div style={{ backgroundColor: 'white' }}>
            <Layout>
              <Header style={{ background: 'white' }}>
                <span className="header-title" style={{}}>
                  Integrations
                </span>
              </Header>
              <Content style={{ background: 'white' }}>
                <div
                  flex="auto"
                  style={{
                    backgroundColor: 'white',
                    overflow: 'hidden',
                    height: 'calc(100vh - 64px)',
                    borderTop: 'solid 1px #d8d8d8',
                    minWidth: '700px',
                    boxShadow: 'rgb(216 216 216) -6px 0px 9px inset',
                  }}
                >
                  <Row
                    style={{
                      height: '100%',
                      width: '100%',
                      paddingLeft: '50px',
                    }}
                  >
                    <Col
                      span={this.getWidth()}
                      style={{ height: '100%', overflowY: 'auto' }}
                    >
                      <div style={{ marginLeft: '40px', marginTop: '20px' }}>
                        <p className="integration-title">
                          Connect your favorite platforms
                        </p>
                        <p className="integration-subtitle">
                          Select any platform or data source you use to manage
                          the communication with your audience.
                          <br />
                          {' '}
                          Can&apos;t
                          find what you are looking for? No worries, Let us know
                          we are here for you!
                        </p>
                      </div>

                      <Collapse
                        className="integrations-collapse"
                        defaultActiveKey={['1', '2']}
                        ghost
                        expandIcon={({ isActive }) => ( // eslint-disable-line react/no-unstable-nested-components
                          <img
                            className={`expandIcon ${isActive ? 'active' : 'non-active'
                              }`}
                            src={expandIcon}
                            width="20"
                            height="20"
                            alt="expand"
                          />
                        )}
                      >
                        <Panel header="Available integrations" key="1">
                          <ListRenderer
                            dataSource={this.getIntegrations('available')}
                            getAssets={this.getAssets}
                            isCardSelected={this.isCardSelected}
                            onClickHandler={this.integrationClicked}
                            premiumList={false}
                          />
                        </Panel>
                        <Panel header="Premium integrations" key="2">
                          <ListRenderer
                            dataSource={this.getIntegrations('premium')}
                            getAssets={this.getAssets}
                            isCardSelected={this.isCardSelected}
                            onClickHandler={this.integrationClicked}
                            premiumList
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                    <Col
                      span={24 - this.getWidth()}
                      style={{
                        borderLeft: 'solid 1px #d8d8d8',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        overflowY: 'auto',
                      }}
                    >
                      {this.state.selectedIntegration
                        && this.state.selectedIntegration.assets.length > 0 ? (
                        <IntegrationDetailsView
                          selectedIntegration={this.state.selectedIntegration}
                          assets={this.state.selectedIntegration.assets}
                          addAsset={this.addAsset}
                        />
                      ) : Dal.defaultIntegration
                        && !this.state.selectedIntegration ? (
                        <IntegrationDetailsView
                          selectedIntegration={Dal.defaultIntegration}
                          assets={Dal.defaultIntegration.assets}
                          addAsset={this.addAsset}
                        />
                      ) : (
                        <IntegrationDetailsEmptyStateView />
                      )}
                    </Col>
                  </Row>
                </div>
              </Content>
            </Layout>
          </div>
        </div>
      </>
    );
  }
}

const ListRenderer = observer(
  ({
    dataSource, getAssets, isCardSelected, onClickHandler, premiumList,
  }) => (
    <List
      split={false}
      grid={{ gutter: 12 }}
      style={{ overflowX: 'hidden', backgroundColor: 'white' }}
      dataSource={dataSource}
      renderItem={(integration) => (
        <List.Item
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            marginTop: '10px',
          }}
        >
          <IntegrationCardView
            title={integration.title}
            id={integration.id}
            image={integration.image}
            ctaTitle={
              integration.connected
                ? `${getAssets(integration).length} connected`
                : integration.ctaTitle
            }
            ctaImage={integration.ctaImage}
            ctaImageSelected={integration.ctaImageSelected}
            connected={integration.connected}
            assets={integration.assets}
            supportedNow={integration.supportedNow}
            onClick={
              premiumList
                ? premiumMiddlewareCheck(onClickHandler)
                : onClickHandler
            } // This function is not executed, see its file
            isCardSelected={!!isCardSelected(integration)}
          />
        </List.Item>
      )}
    />
  ),
);

export default observer(IntegrationsView);
