import { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import chevIcon from '../../../../../Assets/Images/icons/chevron-blue-down.png';
import useOutsideClick from '../../../../../Utils/useOutsideClick';
import Select from '../../../../../Components/Select';

const UsageSelect = observer(({
  value, placeholder, onChange, values, color, icon,
}) => {
  // const { settingsQuery } = useSettingsContext();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();
  const popupContain = useRef();
  const onChangeMiddleware = (v) => {
    setOpen(false);
    onChange(v);
  };
  useOutsideClick(popupContain, () => setOpen(false));
  return (
    <div ref={popupContain} className="hidden-select">
      <div style={color ? { color } : {}} className="hidden-select_text" onClick={() => setOpen(!isOpen)}>
        <img src={chevIcon} width="24" height="24" alt="" />
        <span>{value || placeholder}</span>
      </div>
      <div>
        <Select
          className="usage-select hidden-select_select"
          bordered={false}
          // placeholder={placeholderDisplay}
          value={null}
          onChange={onChangeMiddleware}
          open={isOpen}
          getPopupContainer={() => popupContain.current}
        >
          {(values).map((opt) => (
            <Select.Option className="superfilter-option" key={opt.id} value={opt.label}>
              {icon}
              <span>{opt.label}</span>
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
});

export default UsageSelect;
