import { toJS } from 'mobx';
import { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import segmentIcon from '../../../../../Assets/Images/icons/cat-ico-sentiment.svg';
import sentimentNeutral from '../../../../../Assets/Images/icons/revamp/cat-ico-sentiment-2.svg';
import sentimentNegative from '../../../../../Assets/Images/icons/revamp/cat-ico-sentiment-3.svg';
import sentimentPositive from '../../../../../Assets/Images/icons/revamp/cat-ico-sentiment (1).svg';
import Sentiment from '../../../../../Components/Sentiment';
import DrawerHandler from '../../state/handler';
import MiniSpinner from '../common/miniSpinner';
import Collapse from './collapse';

function SentimentCollapse() {
  const currentIcon = useMemo(() => {
    if (DrawerHandler.isLoading.sentiment) {
      return <IconLoader />;
    }
    if (DrawerHandler.data.sentiment.overall_emotion_name) {
      const { overall_emotion_name: emotion } = DrawerHandler.data.sentiment;
      const theIcon = (() => {
        if (emotion === 'NEGATIVE') return sentimentNegative;
        if (emotion === 'POSITIVE') return sentimentPositive;
        return sentimentNeutral;
      })();
      return <img src={theIcon} width="30" height="30" alt="Expand / Collapse" />;
    }
    return <img src={sentimentNeutral} width="30" height="30" alt="Expand / Collapse" />;
  }, [DrawerHandler.isLoading.sentiment, DrawerHandler.data.sentiment]); // eslint-disable-line
  if (!DrawerHandler.id) return <></>;
  return (
    <Collapse
      iconComp={currentIcon}
      title="Sentiment"
      subContent={DrawerHandler.isLoading.sentiment ? <MiniSpinner /> : <></>}
    >
      <div className="collapse-sentiment">
        {!DrawerHandler.isLoading.sentiment && <Sentiment sentiment={toJS(DrawerHandler.data.sentiment)} loading={false} />}
      </div>
    </Collapse>
  );
}

function IconLoader() {
  const [phase, setPhase] = useState(0);
  useEffect(() => {
    const tm = setInterval(() => {
      setPhase((cur) => cur === 2 ? 0 : cur + 1); // eslint-disable-line
    }, 500);
    return () => clearInterval(tm);
  }, []);
  return (
    <div className="sentiment-icon">
      <img src={sentimentNegative} style={{ opacity: phase === 0 ? 1 : 0 }} width="30" height="30" alt="Sentiment" />
      <img src={sentimentNeutral} style={{ opacity: phase === 1 ? 1 : 0 }} width="30" height="30" alt="Sentiment" />
      <img src={sentimentPositive} style={{ opacity: phase === 2 ? 1 : 0 }} width="30" height="30" alt="Sentiment" />
    </div>
  );
}

export default observer(SentimentCollapse);
