/* eslint-disable no-mixed-operators */
// import useDictionaryQueries from '../../../../../Utils/community/dictionaries/useDictionaryQueries';
import { useMemo } from 'react';
import _merge from 'lodash/merge';
import WidgetWrapper from '../wrapper';
import widgetSettings from '../_settings';
import RedFaceEmotion from '../../../../../Assets/Images/icons/emotions/negativeIcon';
import GreyFaceEmotion from '../../../../../Assets/Images/icons/emotions/neutralIcon';
import GreenFaceEmotion from '../../../../../Assets/Images/icons/emotions/positiveIcon';
import widgetSkeletons from './_skeletons';
import { ReactComponent as Icon } from '../../../../../Assets/Images/icons/dictionaries/bysentiment.svg';

export default function WidgetSentiment({ query }) {
  const SentimentComp = useMemo(
    () => ({
      Negative: <RedFaceEmotion fill="#EB5757" />,
      Neutral: <GreyFaceEmotion fill="#7F909E" />,
      Positive: <GreenFaceEmotion fill="#65BB6A" />,
    }),
    [],
  );
  const series = useMemo(() => {
    if (query.isLoading) return widgetSkeletons.sentiment.series;
    const data = query?.data?.sentiment || {};
    const sum = Object.values(data).reduce((acc, cur) => acc + cur, 0);
    return [{
      name: 'Sentiment',
      size: '100%',
      innerSize: '73%',
      enableMouseTracking: false,
      data: [{
        name: 'Neutral',
        displayName: 'Neutral',
        color: '#C7CED3',
        y: Math.round(data.NEUTRAL / sum * 100) || 0,
        displayY: `${Math.round(data.NEUTRAL / sum * 100) || 0}%`,
      }, {
        name: 'Negative',
        displayName: 'Negative',
        color: '#FF7676',
        y: Math.round(data.NEGATIVE / sum * 100) || 0,
        displayY: `${Math.round(data.NEGATIVE / sum * 100) || 0}%`,
      }, {
        name: 'Positive',
        displayName: 'Positive',
        color: '#00C784',
        y: Math.round(data.POSITIVE / sum * 100) || 0,
        displayY: `${Math.round(data.POSITIVE / sum * 100) || 0}%`,
      }],
    }];
  }, [query.data, query.isLoading]);
  const settings = useMemo(() => (_merge({
    chart: {
      type: 'pie',
      width: 250,
      height: 250,
      margin: 0,
    },
    series,
  }, widgetSettings({ isPercentage: true, tooltipDisabled: true }))), [series]);
  const highest = useMemo(() => series[0].data.reduce((acc, cur) => {
    if (cur.y > acc.val) {
      return {
        name: cur.displayName,
        val: cur.displayY,
      };
    }
    return acc;
  }, { name: '', val: 0 }), [series]);
  return (
    <WidgetWrapper
      widgetData={settings}
      className="widget-bysentiment"
      title={(
        <>
          <Icon />
          {' '}
          Matches by sentiment
        </>
      )}
      isEmpty={highest.val === 0 && !query.isLoading}
      centerContent={(
        <div className="sentiment-center">
          <div>{SentimentComp[highest.name]}</div>
          <div>{highest.name}</div>
          <div>
            {highest.val}
          </div>
        </div>
      )}
    >
      <div className="info-table">
        {series[0].data.map((dataPoint) => (
          <div className="info-table_row" key={dataPoint.name}>
            <div>{SentimentComp[dataPoint.name]}</div>
            <div>
              {dataPoint.displayY}
            </div>
          </div>
        ))}
      </div>
    </WidgetWrapper>
  );
}
