import { ReactComponent as DashboardIcon } from '../../Assets/Images/icons/dashboards/board-icon-new.svg';

export default function DashboardsEmpty({ setBoardModal }) {
  return (
    <div className="dashboard-empty">
      <DashboardIcon />
      <div className="desc">
        <span>Create a dashboard</span>
        {' '}
        and customize the way you look at your data.
      </div>
      <div className="add-board" onClick={() => setBoardModal(true)}><span>Add a dashboard</span></div>
    </div>
  );
}
