import { motion } from 'framer-motion';
import {
  memo,
  useCallback, useMemo,
} from 'react';

import { Tooltip } from 'antd';
import moment from 'moment';
import closeIcon from '../../Assets/Images/icons/general-chevron-chevron-left.svg';
import { ReactComponent as AutoIcon } from '../../Assets/Images/icons/revamp/cat-ico-automate.svg';
import { ReactComponent as ShareComIcon } from '../../Assets/Images/icons/cat-ico-share-comment.svg';

import ThreadsList from './components/threadsList';
import ErrorBoundary from '../ErrorBoundary/errorBoundary';
import defaultIntegrations from '../../Data/integrations';
import { useCommunityContext } from '../../Utils/contexts/community';
import useInteractionQueries from '../Queries/hooks/useInteractionQueries';

function SetsThreadDrawer({ interactionId, onClose }) {
  // const isLoading = useMemo(() => !selectedMatch, [selectedMatch]);
  const { interaction } = useInteractionQueries(interactionId);
  const { assets } = useCommunityContext();
  const asset = useMemo(() => {
    if (interaction.data) return assets[interaction.data.asset_id];
    return {};
  }, [interaction.data, assets]);
  const mainImage = useMemo(() => {
    if (interaction.data) {
      if (asset && asset.picture) {
        return <img src={asset.picture} style={{ borderRadius: '50%' }} alt="" width="50" height="50" />;
      }
    }
    return (
      <div style={{
        background: '#d6d6d6', width: '50px', height: '50px', borderRadius: '50%',
      }}
      />
    );
  }, [interaction.data, asset]);
  const srcImage = useMemo(() => {
    if (asset) {
      const pic = defaultIntegrations.find((x) => x.id === Number(asset.integration_id))?.image;
      return pic;
    }
    return '';
  }, [asset]);
  // const subtitle = useMemo(() => `${kFormatter(asset?.member_count || 0)} members`, [asset]);

  const classSkeleton = useCallback((c) => {
    if (interaction.isLoading) return `${c} isSkeleton`;
    return c;
  }, [interaction.isLoading]);

  const combinedThread = useMemo(() => {
    if (interaction.data) {
      const allInteractions = [
        ...(interaction.data.thread || []),
        {
          ...interaction.data,
          isOrigin: true,
        },
      ].sort((a, b) => moment(a.origin_time).diff(moment(b.origin_time)));
      return allInteractions;
    }
    return [];
  }, [interaction.data]);

  return (
    <motion.div
      key="threaddrawer"
      initial={{ x: '100%' }}
      animate={{ x: '0%' }}
      exit={{ x: '100%' }}
      transition={{ type: 'tween', duration: 0.25 }}
      className="tdrawer-wrapper"
    >

      <div className="tdrawer-shadow" />
      <div className="tdrawer">
        <div className="tdrawer-heading">
          <div className="tdrawer-heading_left">
            <img src={closeIcon} onClick={onClose} width="24" height="24" alt="" />
            <span className="title">Engagement</span>
          </div>
          <div className="tdrawer-heading_right">
            <Tooltip color="white" title={<span style={{ color: 'black' }}>Automate (Coming soon)</span>}>
              <div>
                <AutoIcon />
              </div>
            </Tooltip>
            <Tooltip color="white" title={<span style={{ color: 'black' }}>Share (Coming soon)</span>}>
              <div>
                <ShareComIcon />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="tdrawer-titles">
          <div className="tdrawer-titles_img">
            {mainImage}
            {!interaction.isLoading && (
              <img
                width="16"
                height="16"
                src={srcImage}
                className="src-image"
                alt=""
              />
            )}
          </div>
          <div className="tdrawer-titles_title">
            <div className={classSkeleton('')}>
              {interaction.isLoading ? 'Loading name...' : asset?.name}
            </div>
            {/* <div className={classSkeleton('')}>
              {interaction.isLoading ? 'Loading asset...' : asset?.member_count}
            </div> */}
          </div>
        </div>
        <div className="tdrawer-threads">
          <ErrorBoundary componentName="ThreadDrawerList">
            {/* thread drawer */}
            <ThreadsList
              originalInteraction={interaction.data}
              // markedBy={selectedMatch.marked_by_keywords}
              isLoading={interaction.isLoading}
              list={combinedThread}
            />
          </ErrorBoundary>
        </div>
      </div>

    </motion.div>
  );
}

export default memo(SetsThreadDrawer);
