import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import slackIcon from '../../../Assets/Images/redesign/repo-icon-slack.svg';
import slackConnect from '../../../Assets/Images/icons/automation/connector-slack.png';
import nlogo from '../../../Assets/Images/icons/nlogo2.png';

export default function RulePlaceholderSlack() {
  const history = useHistory();
  return (
    <div className="rule-placeholder rule-placeholder_slack">
      <div className="rule-placeholder_logo">
        <img src={nlogo} width="600" height="698" alt="lnc" />
      </div>
      <div className="rule-placeholder_main">
        <div className="rule-placeholder_main_connector">
          <img width="230" height="90" alt="slack" src={slackConnect} />
        </div>
        <div className="rule-placeholder_main_desc">
          <div>Boost your workflow with Slack</div>
          <div>
            Get inbound notifications about the things that matter you the most, straight to your slack teammate or channel.
          </div>
        </div>
        <div className="rule-placeholder_main_btn">
          <Button onClick={() => history.push('/dashboard/integrations')} className="rule-placeholder_btn">
            <img src={slackIcon} width="24" height="24" alt="slack" />
            Connect Slack
          </Button>
        </div>
      </div>
    </div>
  );
}
