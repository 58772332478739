import { useMutation } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';
import { useCommunityContext } from '../../contexts/community';
import useTagsQuery from './useTagsQuery';

export default function useTagsMutations() {
  const { communityId: commId } = useCommunityContext();
  const { tags } = useTagsQuery();
  const removeCommunityTag = useMutation((id) => NetworkManager.deleteCommunityTag(commId, id), {
    onSuccess: () => {
      tags.refetch();
    },
  });

  const addCommunityTag = useMutation((label) => NetworkManager.insertCommunityTag(commId, label), {
    onSuccess: () => {
      tags.refetch();
    },
  });
  return {
    removeCommunityTag,
    addCommunityTag,
  };
}
