import { min } from 'underscore';

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function parseQuery(queryString) {
  const query = {};
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function getInitials(name) {
  if (name === undefined || name === '') {
    return '';
  }
  const tokens = name.split(' ');
  if (tokens.length === 2) {
    return tokens[0][0] + tokens[1][0];
  }
  return tokens[0][0];
}

/**
 * @see http://stackoverflow.com/q/7616461/940217
 * @return {number}
 */

String.prototype.hashCode = function () { // eslint-disable-line
  if (Array.prototype.reduce) {
    return this.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  }
  let hash = 0;
  if (this.length === 0) return hash;
  for (let i = 0; i < this.length; i++) {
    const character = this.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash &= hash; // Convert to 32bit integer
  }
  return hash;
};

export function formattedMessage(keywordsRaw, message, isShortForm, colors) {
  let res;
  const keywords = keywordsRaw.map((k) => k?.keyword);
  const presentedTextLength = isShortForm ? 70 : message.length;
  const preKeywordLength = 20;

  if (message.length > presentedTextLength) {
    let substring = message;
    let i;
    for (i = 0; i < keywords.length; i++) {
      const index = message.toLowerCase().indexOf(keywords[i].toLowerCase());
      if (index !== -1) {
        const startIndex = index - min([preKeywordLength, index]);
        substring = message.substring(startIndex);
        break;
      }
    }
    res = `${substring.substring(
      0,
      min([substring.length, presentedTextLength]),
    )}...`;
  } else {
    res = message;
  }
  if (keywords !== undefined && keywords.length > 0) {
    res = highlightText(res, keywords, colors);
  }
  return res;
}

export function capitalizeFirstLetter(string) {
  if (string === undefined) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function highlightText(string, keywords, colors) {
  let newStr = string;
  const { bgColor } = colors;
  const { textColor } = colors;
  const reg = new RegExp(keywords.map((k) => `(${k})`).join('|'), 'gi');
  [...string.matchAll(reg)].forEach((s) => {
    newStr = newStr.replace(s[0], `<span style="color:${textColor};background-color:${bgColor};font-weight:600">${s[0]}</span>`);
  });
  return newStr;
}
