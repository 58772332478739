import { useField, useFormikContext } from 'formik';
import Highcharts from 'highcharts';

import HighchartsReact from 'highcharts-react-official';
import { useEffect, useMemo } from 'react';
import Edittable from '../../../Edittable';
import metadataSchema from '../../_data/metadataSchemas';
import previewMaker from '../../_data/previewMaker';
import widgetSchemas from '../../_data/widgetSchemas';

require('highcharts/modules/wordcloud')(Highcharts);

const Field = ({
  field, setFieldValue, error,
}) => {
  const [{ value }] = useField({ name: field.key });
  useEffect(() => () => {
    if (field.clearOnClose) {
      setFieldValue(field.key, null);
    }
  }, []); // eslint-disable-line
  return (
    <div className="settings-field" key={field.key}>
      <div className="settings-field_label">{field.label}</div>
      <field.Template key={field.key} error={error} value={value} onChange={(v) => setFieldValue(field.key, v)} />
    </div>
  );
};

export default function ChartStep({ isSegmentBoard }) {
  const {
    values, setFieldValue, errors, touched,
  } = useFormikContext();
  const previewOptions = useMemo(() => previewMaker({
    type: values.type,
    measureY: values.measureY,
    measureFunc: values.measureFunc,
    dimension: values.dimension,
    timespan: values.timespan,
    timerange: values.timerange,
    metadata: values.metadata,
  }), [values.dimension, values.measureY, values.type, values.measureFunc, values.timespan, values.timerange, values.metadata]);

  const metadatas = useMemo(() => {
    const arr = metadataSchema?.[values.measureY]?.[values.measureFunc]?.[values.dimension] || [];
    if (isSegmentBoard) { // Segment boards do not get metadata.segment_id
      return arr.filter((a) => a.key !== 'metadata.segment_id');
    }
    return arr;
  }, [values, isSegmentBoard]);
  return (
    <div className="board-edittor_step step-chart">
      <div className="step-chart_settings">
        {widgetSchemas.fieldsSchema.map((field) => {
          if ((field?.showWhen && field.showWhen({ values })) || typeof field?.showWhen === 'undefined') {
            return (<Field key={field.key} field={field} error={errors?.[field.key] && touched?.[field.key]} values={values} setFieldValue={setFieldValue} />);
          }
          return null;
        })}
        {metadatas?.map((f) => (
          <Field key={f.key} field={f} values={values} error={errors?.metadata?.[f.key.split('metadata.')[1]]} setFieldValue={setFieldValue} />))}
      </div>
      <div className="step-chart_preview">
        <Edittable defaultValue={values.name || 'My chart'} onChange={(v) => setFieldValue('name', v)} className="step-chart_preview_title" />
        <Edittable defaultValue={values.desc || 'A short description about the metric'} onChange={(v) => setFieldValue('desc', v)} className="step-chart_preview_desc" />
        <div className="chart-preview-separator" />
        {previewOptions && (
          <HighchartsReact
            highcharts={Highcharts}
            options={previewOptions}
          />
        )}
      </div>
    </div>
  );
}
