import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { _quickActionsByCategory, _quickActionsData } from './_data';
import ExampleBox from './exampleBox';

export default function QuickActionsExamples({ category, onChoose }) {
  const { setFieldValue } = useFormikContext();
  const items = useMemo(() => {
    if (category === 'all') return _quickActionsData;
    return _quickActionsByCategory[category];
  }, [category]);
  const handleExampleChoose = useCallback((item) => {
    if (item?.prefill?.filters) {
      setFieldValue('metadata.isEdited', true);
      setFieldValue('filters', item.prefill.filters);
      onChoose();
    }
  }, [onChoose, setFieldValue]);
  return (
    <div className="quick-actions_examples">
      {items.map((item) => (
        <ExampleBox onChoose={handleExampleChoose} item={item} key={item.id} />
      ))}
    </div>
  );
}
