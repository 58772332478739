/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */

import reactors from '../_data/templates/reactors';
import triggers from '../_data/templates/triggers';

const AutomationText = ({
  triggerAbbr, reactorAbbr, triggerMetadata, reactorMetadata, isExample, isEditMode,
}) => {
  const Trigger = triggers[triggerAbbr].templates[isEditMode ? 'Edit' : 'Display'];
  const Reactor = reactors[reactorAbbr].templates[isEditMode ? 'Edit' : 'Display'];
  return (
    <>
      <div className="autotext">
        <Trigger isExample={isExample} metadata={triggerMetadata} />
        {' '}
        <Reactor isExample={isExample} metadata={reactorMetadata} />
      </div>
    </>
  );
};

export default AutomationText;
