/* eslint-disable jsx-a11y/label-has-associated-control */
import { useField, useFormikContext } from 'formik';
import { Input } from 'antd';
import { object, string } from 'yup';
import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import QueryIcon from '../../../../Assets/Images/icons/query-icon';
import CreateModal from '../../../Modal/create/createModal';
import { platformColorsByBg } from '../../../../Data/colors';
import ColorPicker from './colorPicker';
import useQueriesMutations from '../../hooks/useQueriesMutations';
import filtersSanitize from '../../queries/utils/filtersSanitize';

const validationSchema = object({
  name: string().required('Name is required').matches(/^[a-zA-Z \d]+$/g, 'Name can only contain letters and numbers'),
});

export default function CRUDModal({ onClose, isShown }) {
  const { values: { metadata, filters }, setFieldValue } = useFormikContext();
  const { update, create } = useQueriesMutations();
  const { queryId } = useParams();
  const history = useHistory();
  const [field] = useField('metadata.name');
  const onSubmit = useCallback(async () => {
    try {
      const isUpdate = metadata.state !== 'UNFINISHED';
      const defaultOptions = {
        onSuccess: () => {
          onClose();
        },
      };
      const { name } = metadata;
      await validationSchema.validate({ name });
      if (isUpdate) {
        await update.mutateAsync({
          id: Number(queryId),
          filters: filtersSanitize(filters),
          name: metadata.name,
          color: metadata.color,
        }, defaultOptions);
        setFieldValue('metadata.isEdited', false);
      } else {
        const qres = await create.mutateAsync({
          filters: filtersSanitize(filters),
          name: metadata.name,
          color: metadata.color,
        }, defaultOptions);
        setFieldValue('metadata.isEdited', false);
        history.replace(`${history.location.pathname}/${qres.id}`);
      }
    } catch (e) {
      if (e.name === 'ValidationError') {
        return toast.error(e.message);
      }
      throw e;
    }
  }, [filters, metadata]);
  return (
    <CreateModal
      isShown={isShown}
      isLoading={update.isLoading || create.isLoading}
      onClose={onClose}
      onApply={onSubmit}
      title={metadata.state === 'UNFINISHED' ? 'Create query' : 'Update query'}
      icon={(
        <div
          className="crud-modal_icon"
          style={{
            backgroundColor: metadata?.color || '#99d2ff',
          }}
        >
          <QueryIcon color={metadata?.color ? platformColorsByBg[metadata.color].textColor : '#076cbe'} />
        </div>
      )}
    >
      <div className="crud-modal">
        <div className="crud-modal_field">
          <label>Query name</label>
          <div>
            <Input className="lnc-input" {...field} type="text" />
          </div>

        </div>
        <div className="crud-modal_field">
          <label>Query Color</label>
          <div>
            <ColorPicker />
          </div>
        </div>
      </div>

    </CreateModal>

  );
}
