import { HiDotsHorizontal } from 'react-icons/hi';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Button } from 'antd';
import DropdownMenu from '../../../DropdownMenu';
import { ReactComponent as TrashIcon } from '../../../../Assets/Images/icons/dashboards/trash.svg';
import { ReactComponent as EditIcon } from '../../../../Assets/Images/icons/dashboards/edit-widget.svg';
import { ReactComponent as Edit2 } from '../../../../Assets/Images/icons/cat-ico-edit.svg';
import { ReactComponent as Pause } from '../../../../Assets/Images/icons/playbooks/pause.svg';
import { ReactComponent as Rabbit } from '../../../../Assets/Images/icons/automation/rabbit.svg';
import { ReactComponent as Dots } from '../../../../Assets/Images/icons/playbooks/dots.svg';
import { ReactComponent as Start } from '../../../../Assets/Images/icons/playbooks/start.svg';
import MiniSpinner from '../../../Loaders/miniSpinner';
import usePlaybooksMutations from '../../hooks/usePlaybooksMutations';

export default function PbAction({ pbId, status }) {
  const { updateStatus, deletePlaybook, testPlaybook } = usePlaybooksMutations();
  const history = useHistory();
  const handleStatusChange = useCallback(() => {
    updateStatus.mutate({
      active: status === 'active' ? 0 : 1,
      id: pbId,
    });
  }, [status, pbId, updateStatus]);

  const handleDeleteClick = useCallback(() => {
    deletePlaybook.mutate({ id: pbId });
  }, [deletePlaybook, pbId]);

  const handleTestClick = useCallback(() => {
    const toastId = toast.loading((
      <div className="toast-flex">
        Invoking playbook test...
        <Button className="lnc-lightblue-btn" onClick={() => toast.dismiss(toastId)}>Dismiss</Button>
      </div>));
    testPlaybook.mutate({ id: pbId }, {
      onSettled: () => {
        toast.success('Test has been invoked', { id: toastId });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pbId]);

  const items = useMemo(
    () => [
      {
        key: 'edit',
        label: 'Edit playbook',
        icon: <Edit2 />,
        onClick: () => history.push(`/dashboard/playbooks/${pbId}/edit`),
      },
      {
        key: 'test',
        label: 'Test playbook',
        icon: <Rabbit />,
        onClick: handleTestClick,
      },
      {
        key: 'stop',
        label: status === 'active' ? 'Stop playbook' : 'Activate playbook',
        icon: status === 'active' ? <Pause /> : <Start />,
        onClick: handleStatusChange,
      },
      {
        key: 'div1',
        divider: true,
      },
      {
        key: 'Remove',
        label: 'Delete playbook',
        danger: true,
        icon: <TrashIcon />,
        onClick: handleDeleteClick,
      },
    ],
    [status],
  );
  return (
    <DropdownMenu
      onVisibleChange={() => console.log('c')}
      button={<div className="dot-btn">{updateStatus.isLoading ? <MiniSpinner /> : <Dots />}</div>}
      items={items}
    />
  );
}
