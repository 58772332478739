import { ReactComponent as RecentIco } from '../../../Assets/Images/icons/cat-ico-recent-vari.svg';
import { ReactComponent as SegIco } from '../../../Assets/Images/icons/revamp/cat-ico-segment-vari.svg';
import { ReactComponent as UserIco } from '../../../Assets/Images/icons/cat-ico-user-vari.svg';
import { ReactComponent as AccountIco } from '../../../Assets/Images/icons/cat-ico-case-vari.svg';
import { ReactComponent as TagIco } from '../../../Assets/Images/icons/cat-ico-tag-vari.svg';
import { ReactComponent as SetsIco } from '../../../Assets/Images/icons/cat-ico-sets-vari.svg';
import { ReactComponent as SentimentIco } from '../../../Assets/Images/icons/cat-ico-sentiment-fill.svg';
import { ReactComponent as SrcIco } from '../../../Assets/Images/icons/revamp/cat-ico-source-vari.svg';
import { ReactComponent as CogIco } from '../../../Assets/Images/icons/cat-ico-cog-fill.svg';

const categories = [
  {
    label: 'Recent',
    Icon: RecentIco,
  },
  {
    label: 'Segments',
    Icon: SegIco,
  },
  {
    label: 'User',
    Icon: UserIco,
  },
  {
    label: 'Account',
    Icon: AccountIco,
  },
  {
    label: 'Tags & Labels',
    Icon: TagIco,
  },
  {
    label: 'Conversations',
    Icon: SetsIco,
  },
  {
    label: 'Sentiment',
    Icon: SentimentIco,
  },
  {
    label: 'Sources',
    Icon: SrcIco,
  },
  {
    label: 'Custom',
    Icon: CogIco,
  },
];

export default function AutomationSidebar() {
  return (
    <div className="automation-modal_main_sidebar">
      <div className="subtitle">CATEGORIES</div>
      <div className="sidebar-items">
        {categories.map((c) => (
          <div key={c.label} className={`sidebar-items_item ${c.label === 'Segments' ? 'selected' : ''}`}>
            <div className="sidebar-items_item_icon"><c.Icon /></div>
            <div className="sidebar-items_item_title">{c.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
