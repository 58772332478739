import { useCallback, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import QueryIcon from '../../../../../Assets/Images/icons/query-icon';
import Box from '../../box';
import useQueriesQueries from '../../../../Queries/hooks/useQueriesQueries';
import { platformColorsByBg } from '../../../../../Data/colors';
import ChooseQueryModal from '../queryModal';

export default function QuerySelectPart() {
  const [queriesModal, setQueriesModal] = useState(false);
  const { queries } = useQueriesQueries();
  const { values, setFieldValue } = useFormikContext();
  const activePayload = useMemo(() => {
    if (values.trigger && queries.data) {
      const q = queries.data.find((d) => d.id === values.trigger);
      return {
        title: q.name,
        desc: `${q.count} total mentions`,
        icon: <QueryIcon color={platformColorsByBg[q.color].textColor} />,
        bgColor: q.color,
      };
    }
    return null;
  }, [queries.data, values]);
  const handleChooseQuery = useCallback((id) => {
    setQueriesModal(false);
    setFieldValue('trigger', id);
  }, []);
  return (
    <div className="flow-part">
      <ChooseQueryModal onChoose={handleChooseQuery} isShown={queriesModal} onClose={() => setQueriesModal(false)} />

      <div className="flow-part_title">Trigger</div>
      <Box
        onClick={() => setQueriesModal(true)}
        placeholderTitle="Select query"
        placeholderDesc="Select a query to trigger playbook’s automation"
        placeholderIcon={<QueryIcon color="gray" />}
        activePayload={activePayload}
        onEditClick={() => setQueriesModal(true)}
        onDeleteClick={(e) => { e.stopPropagation(); setFieldValue('trigger', null); }}
      />
    </div>
  );
}
