/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useEffect, useRef, useState } from 'react';

export default function useHover() {
  const [isHover, setHover] = useState(false);
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      const overListen = () => setHover(true);
      const outListen = () => setHover(false);
      ref.current.addEventListener('mouseover', overListen);
      ref.current.addEventListener('mouseout', outListen);
      return () => {
        ref.current.removeEventListener('mouseover', overListen);
        ref.current.removeEventListener('mouseout', outListen);
      };
    }
  }, [ref]);
  return [isHover, ref];
}
