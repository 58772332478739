import React from 'react';

import {
  List, Avatar, Button, Divider, Space, Checkbox,
} from 'antd';

import functions from './functions';

import ErrorState from './errorState';

import styles from './buttonsStyles';

export default class FacebookGroupsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authorizedGroups: [],
    };
  }

  componentDidMount() {
    functions.fetchAssetsFromNovak(
      this.props.communityID,
      this.setAuthorizedGroupIDs,
    );
  }

  setStep3Completed = () => {
    if (this.state.authorizedGroups.length > 0) {
      this.props.setStep3Completed();
    }
  };

  setAuthorizedGroupIDs = (assets) => {
    const authorized = [];
    for (const asset in assets) {
      authorized.push(assets[asset].asset_id);
    }
    this.setState({ authorizedGroups: authorized }, () => this.setStep3Completed());
  };

  getAction(group) {
    if (window.screen.width < 1500) {
      return (
        <Checkbox
          style={{
            marginRight: 50, color: 'red', width: 24, height: 24,
          }}
          className="authorizeCheck"
          checked={
            !!this.state.authorizedGroups.includes(group.id)
          }
          onChange={(event) => this.props.authorizeGroupClicked(group)}
          disabled={
            !!this.state.authorizedGroups.includes(group.id)
          }
        />
      );
    }
    return (
      <Button
        disabled={
            !!this.state.authorizedGroups.includes(group.id)
          }
        style={
            this.state.authorizedGroups.includes(group.id)
              ? styles.authorizedBtn
              : styles.authorizeBtn
          }
        onClick={(event) => this.props.authorizeGroupClicked(group)}
      >
        Authorize
      </Button>
    );
  }

  render() {
    return (
      <>
        {this.props.wasGroupsFetchingSuccessfull ? (
          <div>
            <p className="selectGroupTitle">Select a group to authorize</p>
            <div
              className="groupsListWrapper"
              style={{ height: 280, overflowY: 'auto' }}
            >
              <List
                style={{ marginTop: 0 }}
                itemLayout="horizontal"
                split={false}
                dataSource={this.props.groups}
                renderItem={(group) => (
                  <List.Item
                    extra={(
                      <Button
                        disabled={
                          !!this.state.authorizedGroups.includes(group.id)
                        }
                        style={
                          this.state.authorizedGroups.includes(group.id)
                            ? styles.authorizedBtn
                            : styles.authorizeBtn
                        }
                        onClick={(event) => this.props.authorizeGroupClicked(group)}
                      >
                        Authorize
                      </Button>
                    )}
                  >
                    <List.Item.Meta
                      avatar={(
                        <Avatar
                          shape="square"
                          size={60}
                          className="facebookGroupAvatar"
                          src={group.picture.data.url}
                        />
                      )}
                      title={<p className="groupData">{group.name}</p>}
                      description={(
                        <p className="groupData groupDescription">
                          {group.member_count}
                          {' '}
                          members
                        </p>
                      )}
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        ) : (
          <ErrorState />
        )}
        <Divider className="facebookDivider" />
        <Space>
          <Button style={styles.backBtn} onClick={this.props.goBack}>
            Back
          </Button>
          <Button
            disabled={
              !(this.state.authorizedGroups.length > 0
              && this.props.wasGroupsFetchingSuccessfull)
            }
            style={
              this.state.authorizedGroups.length > 0
              && this.props.wasGroupsFetchingSuccessfull
                ? styles.doneBtnEnabled
                : styles.doneBtn
            }
            onClick={this.props.authorizeDone}
          >
            Done
          </Button>
        </Space>
      </>
    );
  }
}
