import * as Yup from 'yup';

const validationSchema = Yup.object({
  filters: Yup.array().of(
    Yup.object({
      field: Yup.mixed().required('Required field'),
      operator: Yup.string().required('Required field'),
      value: Yup.mixed().test(
        'is-valid',
        'Required field',
        (value) => {
          if (Array.isArray(value)) return value.length > 0;
          return !!value;
        },
      ).required('Required field'),
    }),
  ),
});

export default validationSchema;
