/* eslint-disable no-confusing-arrow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import {
  useMemo, useCallback, useState, useEffect, useLayoutEffect, useRef,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Select, Tooltip } from 'antd';

import { ReactComponent as Arrow } from '../../../../../Assets/Images/icons/arr-grey.svg';
import connectorImg from '../../../../../Assets/Images/icons/plug.svg';
import checkmark from '../../../../../Assets/Images/icons/checkmark-green.svg';
import question from '../../../../../Assets/Images/icons/question.svg';
import cog from '../../../../../Assets/Images/icons/cat-ico-cog.svg';
import { ReactComponent as ExcelIcon } from '../../../../../Assets/Images/icons/csv-preview.svg';
import { ReactComponent as SelectDown } from '../../../../../Assets/Images/icons/select-down.svg';
import { ReactComponent as EditIcon } from '../../../../../Assets/Images/icons/edit.svg';
import CustomFieldEditor from './customFieldEditor';
import useDebounce from '../../../../../Utils/useDebounce';

const { Option, OptGroup } = Select;

export default function FieldLine({
  source_field,
  dest_field,
  unChangeable,
  onUpdateLine,
  status,
  sampleData,
  createCustomField,
  lineIndex,
  columns,
  fields,
}) {
  const [editCustom, setEditCustom] = useState(null);
  const [isErrorVisible, setErrorVisible] = useState(false);
  const debouncedStatus = useDebounce(status, 400);

  const lineRef = useRef();

  const className = useMemo(() => {
    const classes = ['fieldline'];
    if (status === 1) classes.push('success');
    if (typeof status === 'string') classes.push('error');
    return classes.join(' ');
  }, [status]);

  const sampleDataUpOrDown = useMemo(() => {
    if (lineIndex <= 1) return { bottom: '-360px', top: 'auto' };
    return { top: '-10px', bottom: 'auto' };
  }, [lineIndex]);

  const selectClassName = useCallback((changeType) => {
    const stat = status === 1 ? 'success' : (typeof status === 'string' ? 'error' : 'regular'); // eslint-disable-line
    if (changeType === unChangeable) {
      return `unchangeable ${stat}`;
    }
    return `changeable ${stat}`;
  }, [unChangeable, status]);

  const canEditCustomField = useMemo(() => {
    if (!dest_field) return false;
    if (dest_field.isCustom && dest_field.canStillEdit) return true;
    return false;
  }, [dest_field]);

  const arrowSymbol = useMemo(() => {
    if (status === 1) return checkmark;
    if (typeof status === 'string') return question;
    if (dest_field?.connector) return connectorImg;
    return false;
  }, [status, dest_field]);

  const onCompleteCustomField = useCallback((field) => { // eslint-disable-line
    if (!field.label) return false;
    const stat = createCustomField(field);
    if (typeof stat === 'string') return setEditCustom(stat);
    setEditCustom(false);
  }, [createCustomField]);

  const handleCustomFieldClose = useCallback(() => {
    if (dest_field.key === 'NEW_CUSTOM') onUpdateLine(source_field, null);
    setEditCustom(false);
  }, [dest_field, source_field, onUpdateLine]);

  useEffect(() => {
    if (canEditCustomField && dest_field.key === 'NEW_CUSTOM') {
      setEditCustom(true);
    }
  }, [dest_field, canEditCustomField]);

  useLayoutEffect(() => {
    if (typeof status === 'string') {
      setErrorVisible(1);
      setTimeout(() => {
        setErrorVisible((cur) => cur === true ? true : false); // eslint-disable-line
      }, 2000);
    } else {
      setErrorVisible(false);
    }
  }, [status]);
  return (
    <motion.div
      className={className}
      layoutId="fieldline"
      onMouseEnter={() => typeof status === 'string' && setErrorVisible((cur) => cur === 1 ? 1 : true)}
      onMouseLeave={() => typeof status === 'string' && setErrorVisible((cur) => cur === 1 ? 1 : false)}
      animate={{ height: 'auto', opacity: 1 }}
      exit={{ height: 0, overflow: 'hidden', opacity: 0 }}
      ref={lineRef}
    >
      <div className="fieldline-col">
        {source_field && sampleData?.[source_field] && (
          <div className={`sample-data ${unChangeable === 'source' ? 'stick' : ''}`}>
            <div className="sample-data_icon">
              <ExcelIcon />
            </div>
            <div className="sample-data_list" style={sampleDataUpOrDown}>
              <div className="sample-data_list_blanker" />
              <div className="sample-data_list_header">
                {source_field}
              </div>
              {sampleData[source_field].map((s, ind) => (<div key={`${s}-${ind}`} className="sample-data_list_item">{s}</div>))}
            </div>
          </div>
        )}
        <Select
          showSearch
          placeholder="Select a column"
          className={selectClassName('source')}
          optionFilterProp="children"
          value={source_field}
          onChange={(v) => onUpdateLine(v, dest_field?.key)}
          suffixIcon={<SelectDown />}
          allowClear
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {columns.map((c) => (
            <Option className="csv-option" key={c.label} value={c.label}>{c.label}</Option>
          ))}
        </Select>
      </div>
      <div className="fieldline-arrow">
        {arrowSymbol && (
          <div className={`symbol ${status === 0 && dest_field?.connector ? 'connector' : ''}`}>
            {dest_field?.connector && !isErrorVisible ? (
              <Tooltip title="We use these fields to identify your contacts" color="white" overlayInnerStyle={{ fontSize: '12px', fontFamily: 'Poppins', color: '#3a434a' }}>
                <img src={arrowSymbol} width="50" height="50" alt="" />
              </Tooltip>
            ) : (
              <img src={arrowSymbol} width="50" height="50" alt="" />
            )}
          </div>
        )}
        <Arrow />
        <AnimatePresence>
          {isErrorVisible && (
            <motion.div
              className="fieldline-error"
              initial={{ opacity: 0, y: 'calc(50% - 13px)', x: '-50%' }}
              animate={{ opacity: 1, y: 'calc(-100% - 13px)', x: '-50%' }}
              exit={{ opacity: 0 }}
            >
              {typeof debouncedStatus === 'string' ? debouncedStatus : status}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="fieldline-field">
        <Select
          showSearch
          placeholder="Assign a field"
          className={selectClassName('dest')}
          optionFilterProp="children"
          value={dest_field?.key || undefined}
          onChange={(v) => onUpdateLine(source_field, v)}
          suffixIcon={<SelectDown />}
          allowClear
          filterOption={(input, option) => {
            if (option.children) {
              return option.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            return option.options.find((opt) => opt.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0);
          }}
        >
          {fields[0] !== undefined && (
            <>
              {fields.filter((f) => !f.isCustom).map((f) => (
                <Option className="csv-option" key={f.key} value={f.key}>
                  {f.icon && <img src={f.icon} width="30" height="30" alt="" />}
                  <span>{f.label}</span>
                </Option>
              ))}
              <OptGroup key="custom" label="Custom fields">
                {fields.filter((f) => f.isCustom).map((f) => (
                  <Option className="csv-option" key={f.key} value={f.key}>
                    {f.icon && <img src={f.icon} width="30" height="30" alt="" />}
                    <span>{f.label}</span>
                  </Option>
                ))}
                <Option className="csv-option new_custom" key="NEW_CUSTOM" value="NEW_CUSTOM">
                  <img src={cog} width="30" height="30" alt="" />
                  <span style={{ color: '#0084ff', fontStyle: 'italic', fontWeight: 500 }}>New custom field</span>
                </Option>
              </OptGroup>
            </>
          )}
        </Select>
      </div>
      <AnimatePresence>
        {canEditCustomField && (
          <motion.div
            initial={{ scale: 0, x: 'calc(100% + 10px)' }}
            animate={{ scale: 1, x: 'calc(100% + 10px)' }}
            exit={{ scale: 0, x: 'calc(100% + 10px)' }}
            className={`fieldline-edit ${editCustom ? 'active' : ''}`}
            onClick={() => setEditCustom(!editCustom)}
          >
            {editCustom && (
              <CustomFieldEditor
                onClose={handleCustomFieldClose}
                error={typeof editCustom === 'string' ? editCustom : false}
                onComplete={onCompleteCustomField}
                isNew={dest_field.key === 'NEW_CUSTOM'}
                defaultValues={{ label: source_field, field_type: dest_field.field_type }}
              />
            )}
            <EditIcon />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
