import * as Yup from 'yup';
import logo from '../../Assets/Images/icons/ico-integrate-with-hubspot.png';
import connected from '../../Assets/Images/icons/ico-integration-succes-with-hubspot.png';

const fieldsSchema = [
  {
    label: 'Friendly name',
    defaultValue: '',
    placeholder: 'Enter a name so you can recognize this source across LoudNClear platform',
    key: 'name',
  },
];

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
});

const HubspotAccountSchema = {
  config: {
    name: 'Hubspot',
    image: logo,
    connectedImage: connected,
    oauth: true,
    info: [
      'Fetch your contacts from Hubspot directly into LoudNClear',
    ],
  },
  fieldsSchema,
  validationSchema,
  oauth: {
    // eslint-disable-next-line
    popupUrl: `https://app-eu1.hubspot.com/oauth/authorize?client_id=${process.env.REACT_APP_HUBSPOT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_URL}/afterIntegration/hubspotaccount&scope=crm.objects.contacts.read%20crm.objects.companies.read%20conversations.read%20crm.objects.custom.read%20crm.schemas.custom.read`,
    extractors: {
      params: { // Map URL paramaters to their respective DB column
        code: 'code',
      },
    },
  },
};
export default HubspotAccountSchema;
