import { useCallback, useContext } from 'react';
import { ReactComponent as SevenIcon } from '../../../../Assets/Images/icons/dashboards/last-7-days.svg';
import { ReactComponent as ThirtyIcon } from '../../../../Assets/Images/icons/dashboards/last-30-days.svg';
import { ReactComponent as SixtyIcon } from '../../../../Assets/Images/icons/dashboards/last-60.svg';
import { ReactComponent as NinetyIcon } from '../../../../Assets/Images/icons/dashboards/last-90-days.svg';
import DropdownMenu from '../../../DropdownMenu';
import GhostButton from '../../../Buttons/ghostButton';
import { WidgetContext } from '../../hooks/useWidget';
import { BoardContext } from '../../hooks/useBoard';

const icons = {
  7: <SevenIcon />,
  30: <ThirtyIcon />,
  60: <SixtyIcon />,
  90: <NinetyIcon />,
};

const TimerangeMenu = ({ widgetId }) => {
  const { updateWidgetMutation } = useContext(BoardContext);
  const { widgetQuery: { data } } = useContext(WidgetContext);
  const mutate = useCallback((timerange) => {
    updateWidgetMutation.mutate({ id: widgetId, timerange: Number(timerange) });
  }, [updateWidgetMutation, widgetId]);
  const menu = [
    {
      key: '7',
      label: 'Last 7 days',
      icon: <SevenIcon />,
      onClick: () => mutate(7),
    },
    {
      key: '30',
      label: 'Last 30 days',
      icon: <ThirtyIcon />,
      onClick: () => mutate(30),
    },
    {
      key: '60',
      label: 'Last 60 days',
      icon: <SixtyIcon />,
      onClick: () => mutate(60),
    },
    {
      key: '90',
      label: 'Last 90 days',
      icon: <NinetyIcon />,
      onClick: () => mutate(90),
    },
  ];
  return (
    <DropdownMenu
      button={<GhostButton styles={{ padding: '0 2px', marginRight: '5px', height: '30px' }} icon={icons[data?.config.timerange]} />}
      items={menu}
    />
  );
};

export default TimerangeMenu;
