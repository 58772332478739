import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';

import { toJS } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import DrawerHandler from './state/handler';
import Heading from './comps/heading';

import UserDetailsCollapse from './comps/collapsibles/details/index';
import SegmentsCollapse from './comps/collapsibles/segments';
import SentimentCollapse from './comps/collapsibles/sentiment';
import KeywordsCollapse from './comps/collapsibles/keywords';

import './style.scss';
import AccountView from './comps/account/accountView';
import ErrorBoundary from '../../../Components/ErrorBoundary/errorBoundary';
import useDrawer from './hooks/useDrawer';
import { DrawerContextProvider } from './context/DrawerContext';
import DictionariesCollapse from './comps/collapsibles/dictionaries';
import useActivitiesFilter from './hooks/useActivitiesFilter';
import { ActivitiesFilterContextProvider } from './context/ActivitiesFilterContext';
import ActivityAndHistoryWrapper from './comps/activity/wrapper';
import EmptyDrawer from './comps/empty';

function CrmDrawer() {
  const location = useLocation();
  const history = useHistory();
  const id = toJS(DrawerHandler.id);
  const drawerUse = useDrawer(id);
  const { activities: { platformLinks } } = drawerUse;
  const activitiesFilter = useActivitiesFilter(id);
  const highlightActivityId = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('activityId');
  }, [location]);
  const onClose = useCallback(() => {
    history.replace(history.location.pathname);
  }, [history]);
  return (
    <DrawerContextProvider value={drawerUse}>
      <ActivitiesFilterContextProvider value={activitiesFilter}>
        <AnimatePresence>
          {DrawerHandler.isShown && (
            <motion.div
              key="crm-overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={onClose}
              exit={{ opacity: 0 }}
              className="crmDrawer-overlay"
            />
          )}
          {DrawerHandler.isShown && DrawerHandler.id && (
            <motion.div
              key="crm-drawer"
              initial={{ x: '100%' }}
              animate={{ x: '0%' }}
              exit={{ x: '100%' }}
              transition={{ type: 'tween', duration: 0.5 }}
              className="crmDrawer-wrap"
            >
              <div className="crmDrawer-shadow" />
              <div className={`crmDrawer ${DrawerHandler.hasAccount ? 'with-account' : ''}`}>
                {DrawerHandler.id && DrawerHandler.isShown && (
                  <>
                    {DrawerHandler.isShown && DrawerHandler.hasAccount && (
                      <ErrorBoundary componentName="CrmDrawer - AccountView">
                        <div className="account-wrapper">
                          <motion.div
                            key="shadow-account"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.1, transition: { delay: 0.3, type: 'tween', duration: 0.2 } }}
                            exit={{ opacity: 0 }}
                            className="account-shadow"
                          />
                          <AccountView onClose={onClose} />
                        </div>
                      </ErrorBoundary>
                    )}
                    <div className="crmDrawer-wrapper">
                      <Heading onClose={onClose} />
                      {!DrawerHandler.isUserNotFound ? (
                        <div className="crmDrawer-main">
                          <div className="crmDrawer-collapses">
                            <ErrorBoundary componentName="crmDrawer-collapses">
                              <UserDetailsCollapse contactId={id} platformLinks={platformLinks} />
                              <SegmentsCollapse />
                              <SentimentCollapse />
                              <KeywordsCollapse />
                              <DictionariesCollapse />
                            </ErrorBoundary>
                          </div>
                          <div className="crmDrawer-activities">
                            <ErrorBoundary additionalInfo={{ contactId: DrawerHandler?.id }} componentName="crmDrawer-activities">
                              <ActivityAndHistoryWrapper contactId={id} highlightActivityId={highlightActivityId} />
                            </ErrorBoundary>
                          </div>
                        </div>
                      ) : (
                        <EmptyDrawer />
                      )}
                    </div>
                  </>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ActivitiesFilterContextProvider>
    </DrawerContextProvider>
  );
}

export default observer(CrmDrawer);
