import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import hamburger from '../../../Assets/Images/icons/header-hamburger.svg';
import Dal from '../../../Data/Dal';

function Indicator({ isShown, toggleSideView }) {
  const steps = [
    {
      title: 'Onboarding complete',
      completed: true,
    },
    {
      title: 'Connect a platform',
      completed: Dal.sources.length !== 0,
    },
    {
      title: 'Create your first set',
      completed: Dal.dictionaries.length > 1,
    },
    {
      title: 'Create your first segment',
      completed: Dal.segments.segments.length > 0,
    },
    {
      title: 'Create new automation',
    },
  ];
  return (
    <AnimatePresence>
      {isShown && (
        <motion.div
          className="homescreen-indicator"
          initial={{ opacity: 0, y: '20px' }}
          animate={{ opacity: 1, y: '0px', transition: { delay: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <ProgressBar current={steps.filter((s) => s.completed).length} max={5} />
          <div className="homescreen-indicator_text">
            {steps.filter((s) => s.completed).length}
            {' of '}
            5
          </div>
          <img onClick={toggleSideView} src={hamburger} width="24" height="24" alt="Toggle sidebar" />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export const ProgressBar = ({ current, max }) => (
  <div className="miniprogress">
    <AnimatePresence>
      <motion.div
        className="miniprogress-bar"
        animate={{ width: `${(current / max) * 100}%`, transition: { delay: 0.4, duration: 0.6 } }}
        initial={{ width: '0%' }}
      />
    </AnimatePresence>
  </div>
);

export default observer(Indicator);