import React from 'react';

const positiveIcon = ({
  fill, width = 25, height = 25,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <g fill={fill} transform="translate(.96 .96)">
      <path
        d="M0.75,9.03947368 C0.75,13.6176236 4.46132378,17.3289474 9.03947368,17.3289474 C13.6176236,17.3289474 17.3289474,13.6176236 17.3289474,9.03947368 C17.3289474,4.46132378 13.6176236,0.75 9.03947368,0.75 C4.46132378,0.75 0.75,4.46132378 0.75,9.03947368 Z"
        opacity=".1"
      />
      <path
        fillRule="nonzero"
        d="M9.03947368,0 C4.04711022,0 0,4.04711022 0,9.03947368 C0,14.0318371 4.04711022,18.0789474 9.03947368,18.0789474 C14.0318371,18.0789474 18.0789474,14.0318371 18.0789474,9.03947368 C18.0789474,4.04711022 14.0318371,0 9.03947368,0 Z M9.03947368,1.5 C13.20341,1.5 16.5789474,4.87553735 16.5789474,9.03947368 C16.5789474,13.20341 13.20341,16.5789474 9.03947368,16.5789474 C4.87553735,16.5789474 1.5,13.20341 1.5,9.03947368 C1.5,4.87553735 4.87553735,1.5 9.03947368,1.5 Z"
      />
      <path
        fillRule="nonzero"
        d="M6.27631579 5.80263158L6.36487024 5.80639877C6.89022963 5.851297 7.30263158 6.29196137 7.30263158 6.82894737 7.30263158 7.39576593 6.84313435 7.85526316 6.27631579 7.85526316 5.70949723 7.85526316 5.25 7.39576593 5.25 6.82894737 5.25 6.29196137 5.66240195 5.851297 6.18776133 5.80639877L6.27631579 5.80263158zM11.8026316 5.80263158L11.891186 5.80639877C12.4165454 5.851297 12.8289474 6.29196137 12.8289474 6.82894737 12.8289474 7.39576593 12.3694501 7.85526316 11.8026316 7.85526316 11.235813 7.85526316 10.7763158 7.39576593 10.7763158 6.82894737 10.7763158 6.29196137 11.1887177 5.851297 11.7140771 5.80639877L11.8026316 5.80263158zM12.8238716 10.9170061C13.0077791 10.545858 13.4577405 10.3940694 13.8288887 10.5779769 14.2000368 10.7618844 14.3518253 11.2118458 14.1679178 11.5829939 13.201812 13.5327155 11.2139538 14.7662282 9.038 14.7662282 6.86204622 14.7662282 4.87418798 13.5327155 3.90808216 11.5829939 3.7241747 11.2118458 3.87596324 10.7618844 4.24711135 10.5779769 4.61825946 10.3940694 5.06822091 10.545858 5.25212837 10.9170061 5.96511301 12.3558977 7.43214939 13.2662282 9.038 13.2662282 10.6438506 13.2662282 12.110887 12.3558977 12.8238716 10.9170061z"
      />
    </g>
  </svg>
);

export default positiveIcon;
