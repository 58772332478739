import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import GaugeChart from 'react-gauge-chart';
import {
  renderText,
} from '../../Data/UserDrawerUtil';

import skeleton3 from '../../Assets/Images/redesign/user-drawer-skeleton-3.svg';

import redFaceEmotion from '../../Assets/Images/icons/emotions/emotion-angry.svg';
import greyFaceEmotion from '../../Assets/Images/icons/emotions/emotion-neutral.svg';
import greenFaceEmotion from '../../Assets/Images/icons/emotions/emotion-satisfied.svg';

import './styles.scss';

export default function Sentiment({ sentiment, loading }) {
  const [emotionColor, setEmotionColor] = useState(greyFaceEmotion);
  const controls = useAnimation();

  const sentimentName = sentiment?.overall_emotion_name;

  useEffect(() => {
    if (!loading && typeof sentiment?.overall_emotion_percentage !== 'undefined') {
      /* const controls = animate(rotation, `${sentiment?.percentage}deg`, {
          duration: 5, ease: "linear",
        }); */
      /* const controls = animate(rotation, sentiment?.percentage || -90, {
          duration: 5,
          ease: "linear",
          onComplete: () => rotation.set(sentiment?.percentage),
        }); */
      (async () => {
        await controls.start({
          '--some-rotating': '-90deg',
          transition: { duration: 0.001 },
        });
        await controls.start({
          '--some-rotating': `${sentiment.overall_emotion_percentage}deg`,
          transition: {
            duration: 2,
            type: 'spring',
            bounce: 0.3,
          },
        });
      })();
      if (sentiment?.overall_emotion_percentage <= -30) {
        setEmotionColor(redFaceEmotion);
      } else if (sentiment?.overall_emotion_percentage >= 30) {
        setEmotionColor(greenFaceEmotion);
      } else {
        setEmotionColor(greyFaceEmotion);
      }
    }
  }, [loading, sentiment]); // eslint-disable-line
  return (
    <div className="card sentiment-card">
      <img
        className="skeleton-loader"
        style={{
          width: 230,
          height: loading ? 157 : 0,
          visibility: loading ? 'visible' : 'hidden',
          marginTop: -30,
          marginLeft: 50,
        }}
        src={skeleton3}
        alt="skeleton"
      />
      <div className={loading ? 'hidden' : ''}>
        {sentiment ? (
          <div className="sentiment-indicator">
            <motion.div
              animate={controls}
              style={{
                width: 200,
                flexDirection: 'column',
                alignItems: 'baseline',
                textAlign: 'center',
              }}
            >
              <GaugeChart
                id="gauge-chart-emotion"
                nrOfLevels={3}
                colors={['#ff2f23', '#7f909e', '#2bd461']}
                percent={sentiment?.overall_emotion_percentage}
                hideText
                arcPadding={0.02}
                needleColor="#b2b2b2"
                needleBaseColor="#ffffff"
                arcWidth={0.15}
              />

              <img
                style={{ position: 'relative', top: '-42px', zIndex: '2' }}
                src={emotionColor}
                alt="emotion "
              />
            </motion.div>

            <div style={{ marginTop: -22 }}>
              {renderText(sentimentName)}
              <p style={{ marginTop: -5 }}>
                Based on
                {' '}
                {sentiment?.total_interactions}
                {' '}
                interactions
                {' '}
              </p>
            </div>
          </div>
        ) : (
          <p style={{ padding: '40px 0px', textAlign: 'center' }}>
            No Data Available
          </p>
        )}
      </div>
    </div>
  );
}
