import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Input, Select } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const { Option } = Select;

const fieldsSchema = [
  {
    label: 'Full name',
    defaultValue: '',
    placeholder: 'Enter full name',
    key: 'fullname',
  },
  {
    label: 'Role / Position',
    defaultValue: '',
    placeholder: '',
    key: 'role',
    Template: ({ field }) => {
      const [value, setValue] = useState('');
      const { setFieldValue } = useFormikContext();
      useEffect(() => {
        setFieldValue('role', value);
      }, [value, setFieldValue]);
      return (
        <>
          <Select placeholder="Select your role" onChange={setValue}>
            <Option value="marketing & growth">Marketing & Growth</Option>
            <Option value="customer success">Customer Success</Option>
            <Option value="product management">Product Management</Option>
            <Option value="operations">Operations</Option>
            <Option value="business development">Business Development</Option>
            <Option value="sales">Sales</Option>
            <Option value="community">Community</Option>
          </Select>
        </>
      );
    },
  },
  {
    label: 'Email',
    defaultValue: '',
    placeholder: 'Enter email address',
    key: 'email',
  },
  {
    label: 'Password',
    defaultValue: '',
    placeholder: 'At least 8 characters and 1 digit',
    type: 'password',
    key: 'password',
    Template: ({ field, schema }) => {
      const [isVisiblePassword, setVisiblePass] = useState(false);
      return (
        <div className="password-control">
          <div
            className="eye"
            tabIndex="0"
            onKeyDown={(e) => e.key !== 'Tab' && setVisiblePass((cur) => !cur)}
            onClick={() => setVisiblePass((cur) => !cur)}
          >
            {isVisiblePassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          </div>
          <Input
            {...field}
            type={isVisiblePassword ? 'text' : 'password'}
            id={schema.key}
            placeholder={schema?.placeholder}
          />
        </div>
      );
    },
  },
];

const validationSchema = Yup.object().shape({
  fullname: Yup.string().required('Required').matches(/^[a-zA-Z \d]+$/g, 'Name can only contain letters'),
  password: Yup.string()
    .min(8, 'Minimum length of 8 letters is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g,
      'At least one lowercase letter, uppercase letter and one number',
    )
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const userDetailsSchemas = {
  fieldsSchema,
  validationSchema,
};
export default userDetailsSchemas;
