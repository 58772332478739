import { memo, useMemo } from 'react';
import RedFaceEmotion from '../../../../Assets/Images/icons/emotions/negativeIcon';
import GreyFaceEmotion from '../../../../Assets/Images/icons/emotions/neutralIcon';
import GreenFaceEmotion from '../../../../Assets/Images/icons/emotions/positiveIcon';

function ToneColumn({ value }) {
  const Comp = useMemo(
    () => {
      if (value === 'NEGATIVE') return <RedFaceEmotion fill="#EB5757" />;
      if (value === 'POSITIVE') return <GreenFaceEmotion fill="#65BB6A" />;
      return <GreyFaceEmotion fill="#7F909E" />;
    },
    [value],
  );
  return (
    <div className="tone_col">
      {Comp}
    </div>
  );
}

export default memo(ToneColumn);
