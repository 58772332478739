import { Row, Col } from 'antd';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import bg from '../../../Assets/Images/Bg/bg-onboarding.jpg';
import Logo from '../../../Assets/Images/logo-top-positive.svg';
import OnboardingSider from './sider';

function OnboardingLayout({ children, step }) {
  const location = useLocation();
  const isInviteFlow = useMemo(() => {
    if (location.pathname.includes('onboarding/join')) return true;
    return false;
  }, [location]);
  return (
    <div className="onboarding">
      <Row className="onboarding-layout">
        <Col span={14} className="onboarding-layout_content">
          <img src={Logo} width="130" height="58" alt="LoudnClear Logo" />
          <div className="onboarding-layout_content_innerWrapper">
            <div className="inner">{children}</div>
            <div className="onboarding-layout_content_footer">
              © 2022 LoudNClear.ai
              {'  •  '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://simpoco-privacy-policy-terms-of-use.s3.amazonaws.com/LoudNClear+-+Terms+of+Service_2022_Website.pdf"
              >
                Terms
              </a>
              {'  •  '}
              <a
                href="https://simpoco-privacy-policy-terms-of-use.s3.amazonaws.com/LoudNClear+-+Privacy_Policy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy
              </a>
            </div>
          </div>
        </Col>
        <Col
          span={10}
          style={{ backgroundImage: `url(${bg})` }}
          className="onboarding-layout_sider"
        >
          <OnboardingSider step={isInviteFlow ? 3 : step} />
        </Col>
      </Row>
    </div>
  );
}

export default OnboardingLayout;
