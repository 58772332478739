import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import checkChecked from '../../../Assets/Images/icons/check_circle_checked.png';
import checkUnchecked from '../../../Assets/Images/icons/check_circle.png';
import { ReactComponent as Rabbit } from '../../../Assets/Images/icons/automation/rabbit.svg';
import Spinner from '../../Skeletons/spinner';
import reactors from '../_data/templates/reactors';

export default function RuleActions() {
  const {
    setFieldValue, values, isSubmitting, submitForm,
  } = useFormikContext();
  const handleTest = useCallback(() => {
    setFieldValue('isTest', true);
    setFieldValue('hasSubmitted', true);
    submitForm();
  }, []); // eslint-disable-line
  const handleSubmit = useCallback(() => {
    setFieldValue('isTest', false);
    setFieldValue('hasSubmitted', true);
    submitForm();
  }, []); // eslint-disable-line
  const removeRetro = useMemo(() => Object.values(reactors).find((i) => i.id === values.reactor.reactorTemplateId).removeRetro, [values]);

  return (
    <div className="newActions">
      {!removeRetro ? (
        <div className="check-retro" onClick={() => setFieldValue('retroactive', !values?.retroactive)}>
          <img src={values?.retroactive ? checkChecked : checkUnchecked} width="20" height="20" alt="check" />
          Apply automation retroactively on the specified segment
        </div>
      ) : (<div />)}
      <div className="btns">
        <Button className={`at-btn at-btn-test ${isSubmitting ? 'disabled' : ''}`} onClick={handleTest}>
          <Rabbit />
          <span>Test drive</span>
        </Button>
        <Button className={`at-btn at-btn-create ${isSubmitting ? 'disabled' : ''}`} onClick={handleSubmit}>
          {isSubmitting ? (
            <Spinner style={{ borderColor: 'white' }} />
          ) : (
            'Create rule'
          )}
        </Button>
      </div>
    </div>
  );
}
