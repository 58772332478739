import playImg from '../../../Assets/Images/icons/play-circle.svg';

export default function Heading({
  icon, title, desc, learnMore, overrides,
}) {
  return (
    <div className="step-heading" style={overrides || {}}>
      <div className="step-heading_icon">{icon}</div>
      <div className="step-heading_title">{title}</div>
      {learnMore && (
        <div className="step-heading_learn">
          <img src={playImg} width="20" height="20" alt="play" />
          <span>Learn how CSV upload works</span>
        </div>
      )}
      <div className="step-heading_desc">{desc}</div>
    </div>
  );
}
