import {
  Button, Divider, Input, Modal,
} from 'antd';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import dalInstance from '../../../Data/Dal';
import useAsset from '../../../Utils/community/useAsset';
import '../styles.scss';

function BasicTokenModal({
  closeModalWithoutFetching, serviceName, serviceType, assetAdded,
}) {
  const [globalError, setGlobalError] = useState('');
  const { assetSchema, connect } = useAsset({
    communityId: dalInstance.getCurrentCommunityId(),
    assetId: null,
    serviceName,
    serviceType,
  });
  const [step, setStep] = useState(0); // 0 - initial, 1 - form, 2 - success

  const initals = useMemo(() => assetSchema.fieldsSchema.reduce((acc, field) => ({
    ...acc,
    [field.key]: field.defaultValue,
  }), {}), [assetSchema]);

  const handleBtn = useCallback(() => {
    if (step === 0) {
      setStep(1);
    } else {
      // this is step 2
      closeModalWithoutFetching();
    }
  }, [closeModalWithoutFetching, step]);
  return (
    <Modal
      visible
      closable
      onCancel={closeModalWithoutFetching}
      className="integrationModal"
      wrapClassName="integrationModalWrapper"
      footer={null}
    >
      <img src={step === 2 ? assetSchema.config.connectedImage : assetSchema.config.image} width="250" alt="" />
      <p className="pagesHeader">
        {step === 2 ? (
          <>
            LoudNCLear is connected to your
            {' '}
            {assetSchema.config.name}
          </>
        ) : (
          <>
            {assetSchema.config.name}
            {' '}
            Integration
          </>
        )}
      </p>
      {step !== 2 && <Divider />}
      <Formik
        initialValues={initals}
        validationSchema={assetSchema.validationSchema}
        onSubmit={(values, actions) => {
          connect.mutate(values, {
            onSettled: async (res) => {
              if (res instanceof Error) {
                actions.setSubmitting(false);
                setGlobalError('Token is invalid, please recheck and try again');
              } else {
                await assetAdded(true);
                actions.setSubmitting(false);
                setStep(2);
              }
            },
          });
        }}
      >
        {({
          errors, touched, isSubmitting, submitForm,
        }) => (
          <Form>
            {step === 0 && (
              <ul className="textList">
                {assetSchema.config.info.map((i) => (
                  <li key={i} style={{ marginBottom: 5 }}>
                    {i}
                  </li>
                ))}
              </ul>
            )}
            {step === 1 && (
              <div className="int-form">
                {assetSchema.fieldsSchema.map((f) => (
                  <Field name={f.key}>
                    {({ field }) => (
                      <div className="int-form_field">
                        <label htmlFor={f.key}>{f.label}</label>
                        {/* <span>{f.placeholder}</span> */}
                        <Input type="text" placeholder={f.placeholder} {...field} />
                      </div>
                    )}
                  </Field>
                ))}
                {Object.keys(errors).filter((er) => !!touched?.[er]).map((er) => (
                  <div className="int-form_errors">
                    {errors[er]}
                  </div>
                ))}
                {globalError && (
                  <div className="int-form_errors">
                    {globalError}
                  </div>
                )}
              </div>
            )}
            <Divider />
            <Button loading={isSubmitting} className="connectSalesforceBtn pendo" onClick={step === 1 ? submitForm : handleBtn}>
              {step === 2 ? 'Get Insights' : (
                <>
                  Connect
                  with
                  {' '}
                  {assetSchema.config.name}
                </>
              )}
            </Button>
            <p className="closeSalesforceText" onClick={closeModalWithoutFetching}>
              Close
            </p>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default observer(BasicTokenModal);
