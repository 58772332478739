import { useMemo } from 'react';
import { useMutation } from 'react-query';
import assetsSchemaMatch from '../../Data/assets/_match';
import NetworkManager from '../../Network/NetworkManager';

export default function useAsset({
  assetId, communityId, serviceName, serviceType,
}) {
  const assetSchema = useMemo(() => assetsSchemaMatch[`${serviceName}${serviceType}`], [serviceName, serviceType]);

  const connect = useMutation((d) => NetworkManager.createNewAssetsWithTzipi(communityId, {
    payload: d,
    serviceName,
    serviceType,
  }));
  return {
    assetSchema,
    connect,
  };
}
