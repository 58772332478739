import { Button } from 'antd';
import { Field as FormikField, useFormikContext } from 'formik';
import { useMemo } from 'react';
import schema from './_fields';

const Field = ({ fieldSchema }) => (
  <div className="form-tab_field" key={fieldSchema.key}>
    <div className="form-tab_field_name">{fieldSchema.label}</div>
    {fieldSchema.desc && <div className="form-tab_field_desc">{fieldSchema.desc}</div>}
    <FormikField name={fieldSchema.key}>
      {({ field, meta }) => (
        <fieldSchema.Template meta={meta} field={field} schema={fieldSchema} />
      )}
    </FormikField>
  </div>
);

export default function FormTab({ setStep, isUpdate, onClose }) {
  const { submitForm, isSubmitting } = useFormikContext();
  const sides = useMemo(() => [
    schema.fieldsSchema.filter((s) => s.side === 'left'),
    schema.fieldsSchema.filter((s) => s.side === 'right'),
  ], []);
  return (
    <div className="inner form-tab">
      <div className="container">
        {sides.map((side, ind) => (
          <div className="form-tab-side" key={ind === 0 ? 'left' : 'right'}>
            {side.map((f) => (
              <div className="form-tab_field" key={f.key}>
                <div className="form-tab_field_name">{f.label}</div>
                {f.desc && <div className="form-tab_field_desc">{f.desc}</div>}
                <FormikField name={f.key}>
                  {({ field, meta }) => (
                    <f.Template meta={meta} field={field} schema={f} />
                  )}
                </FormikField>
              </div>
            ))}
          </div>
        ))}

      </div>
      <div className="form-tab_btns">
        <Button className="btn_back" onClick={() => isUpdate ? onClose() : setStep(0)}>Back</Button>
        <Button onClick={submitForm} loading={isSubmitting} className="btn_next">Done</Button>
      </div>
    </div>
  );
}
