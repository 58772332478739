import { memo } from 'react';
import firstImg from '../../../../Assets/Images/onboarding_sider/3/door.png';

const WorkspaceSider = memo(
  () => (
    <div className="companydetails">
      <div className="sider-content">
        <img src={firstImg} width="496" height="572" alt="Company details" />
        <div className="sidertext">
          The power to
          {' '}
          <span>control</span>
          {' '}
          any customer interaction
          <br />
          <span>is in your hands.</span>
        </div>
      </div>
    </div>
  ),
  () => true,
);

export default WorkspaceSider;
