/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-await */
import {
  makeObservable,
  observable,
  computed,
  runInAction,
  toJS,
  transaction,
} from 'mobx';
import { Auth } from 'aws-amplify';
import { message } from 'antd';
import moment from 'moment';
import _ from 'underscore';
import NetworkManager from '../Network/NetworkManager';
import AmplifyManager from '../Network/AmplifyManager';

import systemFieldsSchema from './fields/_systemFieldsSchema';

import advocateIcon from '../Assets/Images/redesign/advocate-icon.svg';
import spammerIcon from '../Assets/Images/redesign/spammer-icon.svg';
import atRiskIcon from '../Assets/Images/redesign/at-risk-icon.svg';
import staffIcon from '../Assets/Images/redesign/staff-icon.svg';
import generalUserIcon from '../Assets/Images/redesign/general-user-icon.svg';

import avatar1 from '../Assets/Images/redesign/face-random-1.svg';
import avatar2 from '../Assets/Images/redesign/face-random-2.svg';
import avatar3 from '../Assets/Images/redesign/face-random-3.svg';
import avatar4 from '../Assets/Images/redesign/face-random-4.svg';
import avatar5 from '../Assets/Images/redesign/face-random-5-generic.svg';

import analytics, { AnalyticsService } from '../config/analytics';
import integrationsList from './integrations';

class Dal {
  user = undefined;
  isLoggedIn = false;
  cognitoUser = undefined;
  selectedMenuIndex = '1';
  selectedDataSources = ['All', 'Facebook', 'Slack'];
  defaultIntegration = undefined;
  showAddSetModal = 0;
  showInviteUser = 0;
  isInInviteFlow = false;
  notifyTargetItem = undefined;
  notifyTargetItemDataPoint = undefined;
  showNotifyTargetView = false;
  matchDetailsItem = undefined;
  showMatchDetailsView = false;
  showCrmUserView = false;
  showReportModal = false;
  crmUserItem = undefined;
  selectedCrmSegmentItem = undefined;
  crmSegmentItemToEdit = undefined;
  customFields = [];
  sources = [];
  allKeywords = {};

  dictionaries = undefined;
  boards = undefined;
  communities = undefined;
  assets = undefined;
  targets = undefined;
  workflows = undefined;
  communityUsers = undefined;
  superFilterSources = undefined;
  segments = undefined;

  defaultIntegrations = integrationsList;

  integrations = [];

  segmentCategories = [
    {
      name: 'None',
      id: 'none',
      color: '#ffffff',
      bgColor: '#b2b2b2',
      icon: generalUserIcon,
    },
    {
      name: 'Staff',
      id: 'staff',
      color: '#ffffff',
      bgColor: '#545775',
      icon: staffIcon,
    },
    {
      name: 'Advocate',
      id: 'advocate',
      color: '#d88000',
      bgColor: '#ffd89f',
      icon: advocateIcon,
    },
    {
      name: 'At Risk',
      id: 'requires_attention',
      color: '#cb2421',
      bgColor: '#f09695',
      icon: atRiskIcon,
    },
    {
      name: 'Spammer',
      id: 'spammer',
      color: '#7a7a7a',
      bgColor: '#e6e6e6',
      icon: spammerIcon,
    },
  ];

  getSegmentCategoryById(id) {
    const category = this.segmentCategories.find((x) => x.id === id);
    if (category !== undefined) {
      return category;
    }
    return this.segmentCategories[0];
  }

  getPieColorByAssetTag(assetTag) {
    if (assetTag === 's3file') {
      assetTag = 'csv'; // eslint-disable-line
    }
    return this.integrations.find((x) => x.assetTag === assetTag)?.pieColor;
  }

  getPieInnerAccentColorByAssetTag(assetTag) {
    if (assetTag === 's3file') {
      assetTag = 'csv'; // eslint-disable-line
    }
    return this.integrations.find((x) => x.assetTag === assetTag)
      ?.pieInnerColor;
  }

  getAccentColorByAssetTag(assetTag) {
    if (assetTag === 's3file') {
      assetTag = 'csv'; // eslint-disable-line
    }
    return this.integrations.find((x) => x.assetTag === assetTag)?.accentColor;
  }

  getTextColorByAssetTag(assetTag) {
    if (assetTag === 's3file') {
      assetTag = 'csv'; // eslint-disable-line
    }
    return this.integrations.find((x) => x.assetTag === assetTag)?.textColor;
  }

  getBgColorByAssetTag(assetTag) {
    if (assetTag === 's3file') {
      assetTag = 'csv'; // eslint-disable-line
    }
    return this.integrations.find((x) => x.assetTag === assetTag)?.bgColor;
  }

  getAccentIconByAssetTag(assetTag) {
    if (assetTag === 's3file') {
      assetTag = 'csv'; // eslint-disable-line
    }
    return this.integrations.find((x) => x.assetTag === assetTag)?.image;
  }

  getAccentIconByTargetTag(targetTag) {
    if (targetTag === 's3') {
      targetTag = 'csv'; // eslint-disable-line
    }
    return this.integrations.find((x) => x.targetTag === targetTag)?.image;
  }

  setsColors = [
    {
      bgColor: '#d6caff',
      textColor: '#4821cc',
      name: 'PURPLE',
    },
    {
      bgColor: '#dbe9ff',
      textColor: '#5382b0',
      name: 'BLUE',
    },
    {
      bgColor: '#99d2ff',
      textColor: '#076cbe',
      name: 'BLUE 2',
    },
    {
      bgColor: '#bad8e6',
      textColor: '#518097',
      name: 'BLUE GRAY',
    },
    {
      bgColor: '#cab4ac',
      textColor: '#695048',
      name: 'BROWN',
    },
    {
      bgColor: '#96dde6',
      textColor: '#028a9b',
      name: 'CYAN',
    },
    {
      bgColor: '#ffd89f',
      textColor: '#d88000',
      name: 'ORANGE',
    },
    {
      bgColor: '#ff9d7e',
      textColor: '#c84e27',
      name: 'DEEP ORANGE',
    },
    {
      bgColor: '#aee7b2',
      textColor: '#339438',
      name: 'GREEN',
    },
    {
      bgColor: '#e6e6e6',
      textColor: '#7a7a7a',
      name: 'GRAY',
    },
    {
      bgColor: '#dbe67a',
      textColor: '#7b8612',
      name: 'LIME',
    },
    {
      bgColor: '#ffa4c3',
      textColor: '#cb2b61',
      name: 'PINK',
    },
    {
      bgColor: '#f09695',
      textColor: '#cb2421',
      name: 'RED',
    },
  ];

  setAvatars = [avatar1, avatar2, avatar3, avatar4, avatar5];

  getSetColorByBgColor(color) {
    const setColor = this.setsColors.find((x) => x.bgColor === color);
    if (setColor !== undefined) {
      return setColor;
    }
    return this.setsColors[0];
  }

  getRandomSetColor(key) {
    let index = Math.floor(Math.random() * 100) % this.setsColors.length;
    if (key !== undefined) {
      index = Math.abs(key.hashCode()) % this.setsColors.length;
    }
    return this.setsColors[index].textColor;
  }

  getRandomAvatarImage(key) {
    let index = Math.floor(Math.random() * 100) % this.setAvatars.length;
    if (key !== undefined && key !== '') {
      index = Math.abs(key.hashCode()) % this.setAvatars.length;
    }
    return this.setAvatars[index];
  }

  constructor() {
    if (!Dal.instance) {
      Dal.instance = this;
    }

    makeObservable(this, {
      user: observable,
      selectedMenuIndex: observable,
      selectedDataSources: observable,
      defaultIntegration: observable,
      integrations: observable,
      isLoggedIn: observable,
      communities: observable,
      dictionaries: observable,
      assets: observable,
      boards: observable,
      showAddSetModal: observable,
      showInviteUser: observable,
      showNotifyTargetView: observable,
      showMatchDetailsView: observable,
      showCrmUserView: observable,
      showReportModal: observable,
      hasIntegration: computed,
      crmUserItem: observable.deep,
      customFields: observable,
      selectedCrmSegmentItem: observable.deep,
      crmSegmentItemToEdit: observable.deep,
      segments: observable.deep,
      sources: observable,
      cognitoUser: observable,
    });

    runInAction(() => {
      this.integrations = [...this.defaultIntegrations];
    });

    return Dal.instance; // eslint-disable-line
  }

  getCurrentCommunityId() {
    if (this.communities) {
      return this.communities[0].id;
    }
    return null;
  }

  isCommunityPremium() {
    const comm = this.communities?.[0];
    if (!comm) return false;
    return comm.is_premium !== 0;
  }

  async getAssets(withSources) {
    const assets = await NetworkManager.getAssetsForCommunity(
      this.getCurrentCommunityId(),
    );
    const integrations = toJS(this.integrations);
    for (const integrationIndex in integrations) { // eslint-disable-line
      integrations[integrationIndex].assets = [];
    }
    for (const [, assetsArray] of Object.entries(assets)) { // eslint-disable-line
      assetsArray.forEach((asset) => {
        integrations.forEach((integration) => {
          if (asset.integration_id === integration.id) {
            integration.connected = true; // eslint-disable-line
            integration.assets.splice(0, 0, asset);
          }
        });
      });
    }
    const existingIntegration = integrations.find((i) => i.connected === true);
    if (withSources) {
      const rawData = await NetworkManager.getCrmFilterSources();
      runInAction(() => {
        this.sources = rawData.sources || [];
      });
    }
    transaction(() => {
      this.assets = assets;
      this.integrations = integrations;
      this.defaultIntegration = existingIntegration;
    });
  }

  hasConnectedIntegrations() {
    return this.integrations.filter((i) => i.assets.length > 0).length > 0;
  }

  async getTaskDetails(taskID) { // eslint-disable-line
    return await NetworkManager.getTaskDetails(taskID); // eslint-disable-line
  }

  async sendFilesToNovak(files) {
    const communityID = this.getCurrentCommunityId();
    const response = await NetworkManager.sendFilesToNovak(files, communityID);
    return response;
  }

  getCommunityAssetByTag(assetTag) {
    return this.assets.accounts.find(
      (asset) => `${asset.service_name}${asset.type}` === assetTag,
    );
  }

  async injectAnalyticsAndIdentify(userData) {
    try {
      if (process.env.REACT_APP_ENV === 'prod') {
        await analytics.identify(userData.email, {
          ...userData,
          $email: userData.email,
          $name: `${userData?.firstName || ''} ${userData?.lastName || ''}`,
        });
      }
      AnalyticsService.enterWorkspace();
      window.__LNC_USER_DATA__ = userData;
      window.__LNC_ANALYTICS__ = analytics;
    } catch (e) {
      NetworkManager.sendErrorToSlack(`[DEBUG] Failed to identify user to Mixpanel\n${JSON.stringify(userData, null, 2)}\n\n${e?.stack}`).then(() => null).catch(() => null);
      console.error('Failed injecting user data to browser'); // eslint-disable-line
    }
  }

  async fetchUser() {
    const user = await NetworkManager.getUser();
    if (user === undefined) return null;
    const communities = await NetworkManager.getCommunities();
    if (communities === undefined || communities[0] === undefined) {
      if (!window.location.href.includes('onboarding')) {
        message.error('No workspaces attached to specified user');
        window.location = '/onboarding';
      }
      return {
        ...user,
        noCommunities: true,
      };
    }
    const userData = {
      id: user.id,
      community: communities[0].name,
      unique_id: user.email,
      user_id: user.email,
      email: user.email,
      firstName: user.first_name,
      lastName: user.family_name,
    };
    await this.injectAnalyticsAndIdentify(userData);
    const communityId = communities[0].id;

    const [targets, communityUsers] = await Promise.all([
      NetworkManager.getTargets(communityId),
      NetworkManager.getCommunityUsers(),
      this.fetchSegments(),
      this.getAllCustomFields(),
    ]);

    NetworkManager.getCrmFilterSources().then((x) => {
      this.sources = x.sources;
      x.platforms = x.platforms.map((platform) => ({ id: platform, name: platform })); // eslint-disable-line
      this.superFilterSources = x;
    });

    transaction(() => {
      this.targets = targets;
      this.user = user;
      this.communities = communities;
      this.communityUsers = communityUsers;
    });
    await this.getAssets();
    return user ?? null;
  }

  async fetchSegments() {
    console.warn('Deprecated method #fetchSegments');
    return [];
    const updatedData = await NetworkManager.getCrmSegments();
    runInAction(() => {
      this.segments = updatedData;
    });
    return updatedData;
  }

  async getAllCustomFields() {
    const result = await NetworkManager.getAllCustomFields() || [];
    runInAction(() => {
      this.customFields = result.filter((cust) => !systemFieldsSchema[cust.label]);
    });
    return result;
  }

  async getStoredMapping(data_type) {
    const result = await NetworkManager.getStoredMapping(data_type);
    return result;
  }

  async uploadCsvFile(file) {
    const asset_id = await NetworkManager.uploadCsvFile(file);
    return asset_id;
  }

  async mapCsvFile(data_type, asset_id, map) {
    const transformMappingToBackEndSchema = (mapping) => {
      const filtered = mapping.filter((mapLine) => mapLine.dest_field && mapLine.source_field);
      const mapped = filtered.map((mapLine) => ({
        src_field_name: mapLine.source_field,
        dst_field_name: mapLine.dest_field.isCustom ? mapLine.dest_field.label : mapLine.dest_field.key,
        field_type: mapLine.dest_field.field_type ?? 'string',
      }));

      // Check for name / last_name occurances and handle accordingly
      const nameDst = mapped.find((m) => m.dst_field_name === 'name');
      if (nameDst) {
        // check if also has last_name
        const lastDst = mapped.find((m) => m.dst_field_name === 'last_name');
        if (lastDst) {
          nameDst.dst_field_name = 'first_name';
        }
      }
      return mapped;
    };
    const result = await NetworkManager.mapCsvFile(data_type, asset_id, transformMappingToBackEndSchema(map));
    return result;
  }

  getSegmentById(id) {
    if (this.segments === undefined) {
      return undefined;
    }
    const segment = _.find(this.segments.segments, (seg) => seg.id === id);
    if (segment !== undefined) {
      return segment;
    }
    return undefined;
  }

  getSegmentTotalItemsCount() {
    if (this.selectedCrmSegmentItem !== undefined) {
      if (this.selectedCrmSegmentItem.id === undefined) {
        const category = this.getSegmentCategoryByName(
          this.selectedCrmSegmentItem.name,
        );
        if (category !== undefined) {
          return category.count;
        }
      } else {
        return this.selectedCrmSegmentItem.count;
      }
      return 0;
    }
    return this.segments?.allMembers?.count;
  }

  getSegmentUnreadItemsCount() {
    if (this.selectedCrmSegmentItem !== undefined) {
      if (this.selectedCrmSegmentItem.id === undefined) {
        const category = this.getSegmentCategoryByName(
          this.selectedCrmSegmentItem.name,
        );
        if (category !== undefined) {
          return category.unreadCount;
        }
      } else {
        return this.selectedCrmSegmentItem.unreadCount;
      }
      return 0;
    }
    return this.segments?.allMembers?.unreadCount;
  }

  declementReadCountOnCurrentSegment() {
    if (this.selectedCrmSegmentItem !== undefined) {
      this.selectedCrmSegmentItem.unreadCount = Math.max(
        0,
        this.selectedCrmSegmentItem.unreadCount - 1,
      );
    } else if (
      this.segments !== undefined
      && this.segments.allMembers !== undefined
    ) {
      this.segments.allMembers.unreadCount = Math.max(
        0,
        this.segments.allMembers.unreadCount - 1,
      );
    }
  }

  markAllReadOnCurrentSegment() {
    if (this.selectedCrmSegmentItem !== undefined) {
      this.selectedCrmSegmentItem.unreadCount = 0;
    } else {
      this.segments.allMembers.unreadCount = 0;
    }
  }

  async markSegmentAsUnread(segmentId) {
    return NetworkManager.markSegmentAsUnread(this.communities[0].id, segmentId);
  }

  getSegmentCategoryByName(name) {
    if (this.segments === undefined) {
      return undefined;
    }
    const category = _.find(this.segments.categories, (cat) => cat.name === name);
    if (category !== undefined) {
      return category;
    }
    return undefined;
  }

  async fetchDictionaries(diff) {
    const hasMeaningFullDiff = (() => {
      if (diff) {
        const dict = this.dictionaries.find((d) => d.id === diff.id);
        if (Array.isArray(diff?.keywords?.removed_ids) && diff?.keywords?.removed_ids.length > 0) return true;
        if (Array.isArray(diff?.keywords?.new_words) && diff.keywords.new_words.length > 0) return true;
        if (dict.enrich !== diff.enrich) return true;
        if (_.difference(dict.emotions || [], diff.emotions || []).length > 0) return true;
      }
      return false;
    })();
    const updatedData = await NetworkManager.getFeedDictionaries(
      this.getCurrentCommunityId(),
    );
    runInAction(() => {
      const dicts = updatedData.sort((a, b) => moment(b.created_time).valueOf() - moment(a.created_time).valueOf());
      if (diff && hasMeaningFullDiff) {
        const relevantDict = dicts.find((d) => d.id === diff.id);
        if (diff?.keywords?.remove_ids) {
          relevantDict.keywords = relevantDict.keywords.filter((x) => !diff.keywords.removed_ids.includes(x.id));
        }
        relevantDict.last_updated = moment.utc();
      }
      this.dictionaries = dicts;
    });
  }

  hideDictionaryForDelete(dictId) {
    runInAction(() => {
      this.dictionaries = this.dictionaries.filter((d) => d.id !== dictId);
    });
  }
  updateDictionaryCount(dictId, count) {
    runInAction(() => {
      const dict = this.dictionaries.find((d) => d.id === dictId);
      if (count) {
        dict.total_matches = count;
      }
    });
  }

  async refreshCommunities() {
    const communities = await NetworkManager.getCommunities();
    if (communities === undefined || communities[0] === undefined) {
      if (!window.location.href.endsWith('onboarding')) {
        message.error('No workspaces attached to specified user');
      }
      return;
    }
    this.communities = communities;
  }

  async connectIntegration() {
    await this.getAssets();
  }

  async refreshTargets() {
    if (!this.targets) {
      this.targets = await NetworkManager.getTargets(
        this.getCurrentCommunityId(),
      );
    }
  }

  async reportBadMergeIssue(problem, description, member) {
    return await NetworkManager.reportMergeIssue( // eslint-disable-line
      problem,
      description,
      member,
      this.getCurrentCommunityId(),
    );
  }

  async refreshCommunityUsers() {
    this.communityUsers = await NetworkManager.getCommunityUsers(
      this.getCurrentCommunityId(),
    );
  }

  get hasIntegration() {
    return this.integrations.findIndex((i) => i.connected === true) >= 0;
  }

  get hasSlackIntegration() {
    return this.integrations.find((i) => i.assetTag === 'slackworkspace')
      .connected;
  }

  getIntegrationByAssetTag(assetTag) {
    const tag = (() => {
      if (assetTag === 's3file') {
        return 'csv';
      }
      return assetTag;
    })();
    return this.integrations.find((i) => i.assetTag === tag);
  }

  getIntegrationByTargetTag(targetTag) {
    let t = targetTag;
    if (targetTag === 's3') {
      t = 'csv'; // eslint-disable-line
    }
    return this.integrations.find((i) => i.targetTag === t);
  }

  isAfterOnboarding() {
    if (
      typeof this.assets?.accounts !== 'undefined'
      && this.assets.accounts.length < 1
      && this.assets.files.length < 1
    ) {
      return true;
    }
    if (this.isInInviteFlow) {
      return true;
    }
    return false;
  }

  async signUp(username, password, email) { // eslint-disable-line
    try { // eslint-disable-line
      const user = await AmplifyManager.signUp(username, password, email);
      return user;
    } catch (err) {
      throw err;
    }
  }

  async resendPasscode(username) { // eslint-disable-line
    AmplifyManager.resendPasscode(username);
  }

  async confirmSignIn(cognitoUser, code, shouldFetchUser, type) {
    const result = await AmplifyManager.confirmSignIn(cognitoUser, code, type);
    switch (result.status) {
      case 0: // success
        if (shouldFetchUser) {
          await this.fetchUser();
        }

        runInAction(() => {
          if (shouldFetchUser) {
            this.isLoggedIn = true;
          }
        });
        return true;
      default:
        console.log('ERRROR IN CONFIRM SIGNIN ', result);
        return null;
    }
  }

  async signin(email, password, shouldFetchUser) {
    const result = await AmplifyManager.signIn(email, password);
    console.log('signin result ', result);
    switch (result.status) {
      case 0: // success
        runInAction(() => {
          if (shouldFetchUser) {
            this.cognitoUser = result.user;
            this.isLoggedIn = true;
          }
        });
        return true;
      case 1: // error
        return {
          message: result.error.message,
          code: result.error.code,
          status: 1,
        };
      case 2: // change password
        return null;
      case 3: // sms mfa
        return {
          status: 3,
          code: result.user.challengeName,
          user: result.user,
        };
      default:
        return null;
    }
  }

  async confirmSignUp(username, code) {
    return await AmplifyManager.confirmSignUp(username, code);
  }

  async createNewAssets(assets, callback, communityId) {
    const resp = await NetworkManager.createNewAssets(assets, communityId);
    if (callback) {
      await this.connectIntegration();
      callback();
    }
    return resp;
  }

  async updateMemberName(originID, name) {
    return await NetworkManager.updateMemberName(originID, name);
  }

  async createNewChannels(channels) {
    const resp = await NetworkManager.createNewChannels(channels);
    return resp;
  }

  async signOut() {
    await Auth.signOut();
    runInAction(() => { this.showMatchDetailsView = false; });// eslint-disable-line
    runInAction(() => { this.matchDetailsItem = undefined; });// eslint-disable-line
    transaction(() => {
      this.selectedMenuIndex = 0;
      this.isLoggedIn = false;
      this.user = undefined;
      this.communities = undefined;
      this.assets = undefined;
      this.communityUsers = undefined;
      this.isInInviteFlow = false;
      this.showCrmUserView = false;
      this.crmUserItem = undefined;
      this.selectedCrmSegmentItem = undefined;
      this.crmSegmentItemToEdit = undefined;
      this.showNotifyTargetView = false;
      this.notifyTargetItem = undefined;
      this.notifyTargetItemDataPoint = undefined;
      this.integrations = [...this.defaultIntegrations];
      this.segments = undefined;
    });
  }

  getAssetDetails(assetId) {
    if (
      assetId === undefined
      || this.assets === undefined
      || !this.assets.accounts
    ) {
      return;
    }

    const asset = this.assets["accounts"].find((asset) => asset.id == assetId); // eslint-disable-line
    return asset; // eslint-disable-line
  }
  // eslint-disable-next-line
  specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

  getCommunityUser(userId) {
    if (userId === undefined || this.communityUsers === undefined) {
      return;
    }
    return this.communityUsers.find((x) => x.id === userId); // eslint-disable-line
  }

  getTargetsToNotify(serviceName) {
    const targets = this.targets[serviceName];
    if (targets !== undefined && targets.length > 0) {
      return targets.sort(this.targetsCompare);
    }
    return [];
  }

  targetsCompare(t1, t2) {
    if (t1.name < t2.name) {
      return -1;
    } if (t1.name > t2.name) {
      return 1;
    }
    return 0;
  }
}

const dalInstance = new Dal();

export default dalInstance;
