import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button, Input,
} from 'antd';
import { observer } from 'mobx-react';
import LNCModal from '../../../Components/Modal';
import { ReactComponent as BoardIcon } from '../../../Assets/Images/icons/dashboards/board-icon-new.svg';
import dalInstance from '../../../Data/Dal';
import ColorPicker from './colorPicker';

function NewBoardModal({
  isShown, onClose, createMutation, updateMutation,
}) {
  return (
    <LNCModal className="newboard" isShown={!!isShown} onClose={onClose}>
      <div className="newboard-header">
        <BoardIcon />
        <div className="newboard-header_title">
          {typeof isShown === 'object' ? 'Edit' : 'New'}
          {' '}
          Dashboard
        </div>
      </div>
      <div className="newboard-content">
        {!!isShown && (
          <Formik
            initialValues={typeof isShown === 'object' ? {
              ...isShown,
              color: dalInstance.setsColors.find((v) => v.textColor === isShown.color),
            } : {
              name: '',
              desc: '',
              color: {
                bgColor: '#d6caff',
                textColor: '#4821cc',
                name: 'PURPLE',
              },
            }}
            validationSchema={Yup.object({
              name: Yup.string().required('Required'),
              desc: Yup.string().default(''),
              color: Yup.object({
                bgColor: Yup.string(),
                textColor: Yup.string(),
                name: Yup.string(),
              }),
            })}
            onSubmit={(values) => {
              const final = {
                ...values,
                color: values.color.textColor,
              };
              if (typeof isShown === 'object') {
                updateMutation.mutate(final, {
                  onSettled: () => {
                    onClose();
                  },
                });
              } else {
                createMutation.mutate(final, {
                  onSettled: () => {
                    onClose();
                  },
                });
              }
            }}
          >
            {({ submitForm }) => (
              <Form>
                <div className="theform">
                  <Field name="name">
                    {({ field, meta }) => (
                      <div className="field">
                        <div className="subtitles">
                          <div className="subtitle">Name</div>
                          {meta.error && <div className="error">{meta.error}</div>}
                        </div>
                        <Input {...field} />
                      </div>
                    )}
                  </Field>
                  <Field name="desc">
                    {({ field, meta }) => (
                      <div className="field">
                        <div className="subtitles">
                          <div className="subtitle">Description</div>
                          {meta.error && <div className="error">{meta.error}</div>}
                        </div>
                        <Input {...field} />
                      </div>
                    )}
                  </Field>
                  <Field name="color">
                    {({ meta }) => (
                      <div className="field">
                        <div className="subtitles">
                          <div className="subtitle">Color</div>
                          {meta.error && <div className="error">{meta.error}</div>}
                        </div>
                        <ColorPicker />
                      </div>
                    )}
                  </Field>
                </div>
                <div className="thefooter">
                  <Button className="btn_back" onClick={onClose}>Cancel</Button>
                  <Button className="btn_next" loading={updateMutation.isLoading || createMutation.isLoading} onClick={submitForm} type="submit">Done</Button>
                </div>

              </Form>
            )}
          </Formik>
        )}
      </div>
    </LNCModal>
  );
}

export default observer(NewBoardModal);
