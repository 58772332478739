import { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import {
  useParams, Switch, Route, useRouteMatch, useHistory,
} from 'react-router-dom';
import QueryNewInitialPhase from '../../../Components/Queries/newView/phases/initial';
import QueryNewNewPhase from '../../../Components/Queries/newView/phases/newQuery';
import QueryNewResultsPhase from '../../../Components/Queries/newView/phases/newQueryResults';
import FormikUpdater from '../../../Components/Queries/queries/utils/formikUpdater';
import validationSchema from '../../../Components/Queries/newView/filters/config/_validation';

export default function QueriesNewView() {
  const { queryId } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [phase, setPhase] = useState();
  // phases are: 1 - examples, 2 - edit query, 3 - query results (mentions/reports)
  const Comp = useMemo(() => {
    if (phase === 1) return QueryNewInitialPhase;
    if (phase === 2) return QueryNewNewPhase;
    return QueryNewResultsPhase;
  }, [phase]);

  const submitQuery = () => {
    if (queryId) {
      history.push(`/dashboard/queries/${queryId}`);
    } else {
      setPhase(3);
    }
  };

  useEffect(() => {
    if (queryId) {
      setPhase(3);
    } else {
      setPhase(1);
    }
  }, [queryId]);

  useEffect(() => {
    if (path === '/dashboard/queries') setPhase(1);
    if (path === '/dashboard/queries/new') setPhase(2);
  }, [path]);

  return (
    <div className="queries-view">
      <Formik
        initialValues={{ filters: [], metadata: { state: 'UNFINISHED', color: '#99d2ff' } }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
        onSubmit={submitQuery}
      >
        <Form>
          <FormikUpdater currentPhase={phase} path={path} />
          <Switch>
            <Route path={`${path}/edit`}>
              <QueryNewNewPhase setPhase={setPhase} />
            </Route>
            <Route path={`${path}`}>
              <Comp setPhase={setPhase} />
            </Route>
          </Switch>
          {/* <div onClick={() => setPhase(() => (phase === 3 ? 1 : phase + 1))}>click</div> */}
        </Form>
      </Formik>
    </div>
  );
}
