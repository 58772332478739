import { motion } from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import UploadImg from '../../../../../Assets/Images/illustration-upload.svg';

export default function PhaseOneUpload({ onDrop }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <motion.div
      className={`dropzone ${isDragActive ? 'active' : ''}`}
      {...getRootProps()}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <input {...getInputProps()} accept=".csv" />
      <div>
        <img src={UploadImg} width="56" height="56" alt="upload" />
      </div>
      {isDragActive ? (
        <p>
          <span>Drop here</span>
        </p>
      ) : (
        <p>
          <span>Drag a file</span>
          {' '}
          or
          {' '}
          <span>click</span>
          {' '}
          to upload from your computer
        </p>
      )}
    </motion.div>
  );
}
