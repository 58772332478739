import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import {
  Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import Dal from '../../Data/Dal';
import EntitySidebar from '../../Components/EntitySidebar';

import './styles.scss';
import Board from '../../Components/Boards/board';
import useDashboards, { DashboardsContext } from '../../Components/Boards/hooks/useDashboards';
import { ReactComponent as BoardIcon } from '../../Assets/Images/icons/dashboards/board-icon.svg';
import WidgetDashboardHeading from './header';
import NewBoardModal from './newBoardModal';
import DashboardsEmpty from './emptyState';
import ErrorBoundary from '../../Components/ErrorBoundary/errorBoundary';
import { AnalyticsService } from '../../config/analytics';

function DashboardsScreen() {
  const dashboards = useDashboards(Dal.communities?.[0]?.id, toJS(Dal.boards));
  const {
    data: allBoards, selectedBoard, createMutation, updateMutation, deleteMutation,
  } = dashboards;
  const [boardModal, setBoardModal] = useState(null);
  const { path, url } = useRouteMatch();
  const [shouldHighlightEdit, setHighlight] = useState(false);
  const history = useHistory();
  const actionsRef = useRef();
  const sidebarList = useMemo(() => {
    if (allBoards) {
      return allBoards.filter((d) => !d.segmentId).map((d) => ({
        ...d,
        id: d.id,
        desc: null, //  d.private ? 'Private' : 'Shared',
        title: d.name,
        icon: <BoardIcon stroke={d.color} fill={d.color} />,
        onClick: () => {
          AnalyticsService.generalDashboardView(d.name); history.push(`${url}/${d.id}`);
        },
        onEditClick: () => setBoardModal(d),
      }));
    }
    return [];
  }, [allBoards]); // eslint-disable-line

  useEffect(() => {
    if (sidebarList.length !== 0 && !selectedBoard) {
      history.push(`${url}/${sidebarList[0].id}`);
    }
  }, [sidebarList, selectedBoard]); // eslint-disable-line
  return (
    <div
      className="screen screen-dashboards"
    >
      <DashboardsContext.Provider value={dashboards}>
        <ErrorBoundary dimensions={[300, 'auto']} componentName="EntitySidebar-CRM">
          <EntitySidebar
            list={sidebarList}
            entity={{ singularName: 'dashboard', pluralName: 'dashboards', apiName: 'boards' }}
            communityId={toJS(Dal.communities)?.[0]?.id}
            deleteMutation={deleteMutation}
            shouldShowDeleteConfirm={{ title: 'Delete dashboard?', desc: 'All the team members you shared this dashboard with will lose their access' }}
            defaultCollapsed={allBoards && allBoards.length === 0}
            selected={selectedBoard}
            onCreateClick={() => setBoardModal(true)}
            title="Dashboards"
          />
        </ErrorBoundary>
        <NewBoardModal createMutation={createMutation} updateMutation={updateMutation} isShown={boardModal} onClose={() => setBoardModal(null)} />
        <div className="screen-dashboards_board">
          {allBoards && allBoards.length !== 0 && (<WidgetDashboardHeading isEditHighlighted={shouldHighlightEdit} actionsRef={actionsRef} />)}
          <Switch>
            <Route exact path={path}>
              <DashboardsEmpty setBoardModal={setBoardModal} />
            </Route>
            <Route path={`${path}/:boardId`}>
              <Board boardId={selectedBoard} onEditModeChange={setHighlight} actionsNodeRef={actionsRef.current} />
            </Route>
          </Switch>
        </div>
      </DashboardsContext.Provider>
    </div>
  );
}

export default observer(DashboardsScreen);
