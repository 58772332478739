import { useState, useEffect } from 'react';
import { Divider } from 'antd';
import { motion, AnimatePresence } from 'framer-motion';
import LogoCircle from '../../../Assets/Images/icons/welcome-ico_circle.svg';
import LogoImg from '../../../Assets/Images/icons/welcome-ico_icon.svg';
import WelcomeSlider from './slider';
import './style.scss';

import xicon from '../../../Assets/Images/icons/icon-20-x-20-ic-20-plus-circle.svg';
import bulb from '../../../Assets/Images/icons/icon-20-x-20-ic-20-bulb.svg';
import VideoModal from './videoModal';

// TODO: When to show welcome?

export default function WelcomeContainer({ minified }) {
  const [isShown, setShown] = useState(minified ? true : false);
  const [video, setVideo] = useState('');
  useEffect(() => {
    if (minified) {
      setShown(true);
    } else {
      try {
        if (window.localStorage.getItem('__welcomeaboard_open') === null) {
          window.localStorage.setItem('__welcomeaboard_open', 'true');
        }
        const isOpen = window.localStorage.getItem('__welcomeaboard_open') === 'true';
        setShown(isOpen);
      } catch (e) {
        // nothing
      }
    }
  }, [minified]); // eslint-disable-line

  const handleOpen = () => { // eslint-disable-line
    window.localStorage.setItem('__welcomeaboard_open', 'true');
    setShown(minified ? true : true); // eslint-disable-line
  };
  const handleClose = () => {
    window.localStorage.setItem('__welcomeaboard_open', 'false');
    setShown(minified ? true : false); // eslint-disable-line
  };
  return (
    <div className={`welcome ${(isShown || minified) && 'open'} ${minified ? 'minified' : ''}`}>
      <AnimatePresence>
        {(isShown || minified) && (
          <motion.div
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: minified ? 'auto' : 0, opacity: minified ? 1 : 0 }}
          >
            <VideoModal video={video} setClosed={() => setVideo('')} />
            {!minified && (
              <>
                <div className="spacer" style={{ height: '35px' }} />
                <div className="welcome-header">
                  <div className="welcome-header_logo">
                    <img src={LogoCircle} width="80" height="80" alt="welcome" />
                    <img src={LogoImg} width="40" height="40" alt="welcome" />
                  </div>
                  <div className="welcome-header_text">
                    <div className="title">Welcome aboard!</div>
                    <div className="text">
                      Discover LoudNClear and start grooming you workspace while we
                      are doing the hard work for you.
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="slider-wrapper">
              <WelcomeSlider minified={minified} openVideo={setVideo} />
              {!minified && (
                <div className="slider-wrapper_closer" role="button" tabIndex="0" onClick={handleClose}>
                  <img src={xicon} width="20" height="20" alt="close" />
                  <span>Got it</span>
                  <Divider className="discovery-divider" />
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {!isShown && !minified && (
        <motion.div
          className="slider-opener"
          onClick={handleOpen}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          initial={{ height: 0, opacity: 0 }}
        >
          <img src={bulb} width="20" height="20" alt="show" />
          <span>Discover</span>
        </motion.div>
      )}
    </div>
  );
}
