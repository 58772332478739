import HappyIcon from '../Assets/Images/redesign/ui-icon-emotion-4-outlined-color.svg';
import MixedIcon from '../Assets/Images/redesign/ui-icon-emotion-mixed-outlined-color.svg';
import AngryIcon from '../Assets/Images/redesign/ui-icon-emotion-1-outlined-color.svg';

const Colors = [
  { id: 1, color: 'red', hexa: '#2bd461' },
  { id: 2, color: 'green', hexa: '#2bd461' },
  { id: 3, color: 'yellow', hexa: '#2bd461' },
  { id: 4, color: 'blue', hexa: '#2bd461' },
];
let i = 0;
export function renderKeywordColor() {
  if (i >= 4) {
    i = 0;
  }
  i++;
  return Colors.find(({ id }) => i === id)?.color;
}

export function renderContent(content, top_keywords) {
  if (content !== undefined) {
    if (top_keywords && top_keywords.length > 0) {
      let newContent = content;
      top_keywords?.forEach(({ keyword, color }, i) => {
        if (content.search(new RegExp(keyword, 'i')) > 0) {
          newContent = !newContent
            ? content.replace(
              new RegExp(keyword, 'gi'),
              `<div class="tag-keyword ${color}">${keyword}</div>`,
            )
            : newContent.replace(
              new RegExp(keyword, 'gi'),
              `<div class="tag-keyword ${color}">${keyword}</div>`,
            );
        }
      });
      return newContent;
    }
    return content;
  }
  return null;
}

export function renderEmotion(sentimentName) {
  if (sentimentName.toLowerCase() === 'positive') return HappyIcon;
  if (sentimentName.toLowerCase() === 'negative') return AngryIcon;
  return MixedIcon;
}

export function renderText(sentimentName) {
  if (sentimentName.toLowerCase() === 'positive') return <h2 className="green"> Positive!</h2>;
  if (sentimentName.toLowerCase() === 'negative') return <h2 className="red">Negative!</h2>;
  return <h2 className="gray">Neutral</h2>;
}

export function renderColor(sentimentName) {
  if (sentimentName.toLowerCase() === 'positive') return '#2bd461';
  if (sentimentName.toLowerCase() === 'negative') return '#ff2f23';
  return '#7f909e';
}
