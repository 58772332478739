import { useState } from 'react';
import { useFormikContext } from 'formik';
import { ReactComponent as ConvoIco } from '../../../../Assets/Images/icons/convo.svg';
import { ReactComponent as IconReports } from '../../../../Assets/Images/icons/queries/iconReports.svg';
import TableView from '../tableView';
import ReportsView from '../reportsView';
import useQueryQueries from '../../hooks/useQueryQueries';
import QueryLoader from './queryLoader';
import QueryEmptyState from './queryEmptyState';
import './resultsWrapper.scss';

export default function QueriesInnerWrapper() {
  const [mode, setMode] = useState('mentions');
  const {
    values: { filters },
  } = useFormikContext();
  const { matches } = useQueryQueries({ filters });
  const { data: queryResult, isFetching, hasNextPage } = matches;

  if (isFetching && !hasNextPage) {
    return (
      <div className="queries-inner">
        <QueryLoader />
      </div>
    );
  }

  if (!isFetching && queryResult?.pages[0]?.total === 0) {
    return (
      <div className="queries-inner">
        <QueryEmptyState />
      </div>
    );
  }

  return (
    <div className="queries-inner">
      <div className="queries-inner_buttons">
        <div
          className={`crm-btn crm-btn-border ${mode === 'mentions' ? 'selected' : ''}`}
          onClick={() => setMode('mentions')}
        >
          <ConvoIco />
          Mentions
        </div>
        <div
          className={`crm-btn crm-btn-border ${mode === 'dashboard' ? 'selected' : ''}`}
          onClick={() => setMode('dashboard')}
        >
          <IconReports />
          Reports
        </div>
      </div>
      <div className="queries-inner_boxwrap">
        {mode === 'mentions' ? <TableView /> : <ReportsView />}
      </div>
    </div>
  );
}
