import { Modal, Button } from 'antd';
import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react';
import {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import Dal from '../../Data/Dal';

import StepIndicator from './common/stepIndicator';
import stepsConfig from './steps/_context';

import './styles/csv.scss';

function CSVIntegration({ closeModalWithoutFetching, onIntegrationSuccess }) {
  const [step, setStep] = useState(0);
  const [globalContext, setGlobalContext] = useState({
    file: null,
    listType: null,
    csvData: [],
    mapping: [],
    storedMapping: [],
    filePostError: false,
    isFetchingNeededData: true, // true / false, string - error
  });

  const updateContext = useCallback((updater) => {
    setGlobalContext((cur) => ({ ...cur, ...updater }));
  }, []);

  const Comp = useMemo(() => stepsConfig[step]?.Component ?? stepsConfig[3].Component, [step]);

  const isNextEnabled = useMemo(() => {
    if (step === 0) {
      return globalContext.listType !== null;
    } else if (step === 1) { // eslint-disable-line
      return globalContext.isFetchingNeededData === false && globalContext.csvData.length !== 0;
    } else if (step === 2) {
      return globalContext.mapping.length !== 0;
    }
    return false;
  }, [step, globalContext]);

  const isBackEnabled = useMemo(() => step !== 0, [step]);

  const onClickNext = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  useEffect(() => {
    if (step === 1) {
      // in choose csv step, need to get stored mappings
      (async () => {
        try {
          await Dal.getAllCustomFields();
          const mapping = await Dal.getStoredMapping(globalContext.listType);
          updateContext({ storedMapping: mapping, isFetchingNeededData: false });
        } catch (e) {
          updateContext({ isFetchingNeededData: 'Failed to interact with the server, try again ' });
        }
      })();
    }
  }, [step, globalContext.listType, updateContext]);

  return (
    <Modal
      onCancel={closeModalWithoutFetching}
      closable
      visible
      footer={null}
      className="csv-modal"
      wrapClassName="csv-modal_wrapper"
      width="860px"
    >
      <StepIndicator step={step} />
      <div className="step-contain" style={{ overflow: step < 4 ? 'hidden' : 'visible' }}>
        <AnimatePresence>
          <Comp
            globalContext={globalContext}
            updateContext={updateContext}
            onIntegrationSuccess={onIntegrationSuccess}
            setStep={setStep}
          />
        </AnimatePresence>
      </div>
      {step < 3 && step !== 0 && (
        <div className="csv-modal_footer">
          <div style={{ borderTop: '1px solid #d8d8d8' }} />
          <div className="csv-modal_footer_buttons">
            {step !== 0 && (<Button className="csv-modal_back" onClick={() => setStep(step - 1)} disabled={!isBackEnabled}>Back</Button>)}
            <Button className="csv-modal_next" onClick={onClickNext} disabled={!isNextEnabled}>Next</Button>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default observer(CSVIntegration);
