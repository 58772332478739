import React from 'react';
import { observer } from 'mobx-react';

import { Modal, Button } from 'antd';
import { Link } from 'react-router-dom';

import Logo from '../../Assets/Images/logo-white.svg';
import bg from '../../Assets/Images/bloom2.png';
import Dal from '../../Data/Dal';

const isSmallScreen = () => window.screen.width < 1500;

const button = {
  width: isSmallScreen() ? 170 : 190,
  height: isSmallScreen() ? 40 : 50,
  borderRadius: '4px',
  backgroundColor: Dal.isInInviteFlow ? '#542fda' : '#0084ff',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 600,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.02px',
  textAlign: 'center',
  color: '#ffffff',
  marginTop: 40,
  zIndex: 10000,
};

class ModalAfterOnboarding extends React.Component {

  componentDidMount() {
    if (Dal.isAfterOnboarding()) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'S6_Welcome',
      });
    }
  }

  getTitle() {
    if (Dal.isInInviteFlow) {
      return 'Great! Welcome to your workspace';
    }
    return 'Welcome to LoudNClear';
  }

  getSubtitle() {
    if (Dal.isInInviteFlow) {
      return 'Learn how LoudNClear will forever change how you track, monetize and gain insights from your audience';
    }
    return 'Before you start, connect at least one platform and you are ready to go!';
  }

  getButtonText() {
    if (Dal.isInInviteFlow) {
      return "Let's dig in";
    }
    return 'Connect platforms';
  }

  render() {
    return (
      <Modal
        width={isSmallScreen() ? 550 : 780}
        closable={false}
        style={{ borderRadius: '10px', marginTop: 40 }}
        wrapClassName="helperWrapper modalOnboardingWrapper"
        className="onboardingModal"
        visible={this.props.shouldRenderHelper}
        footer={null}
      >
        <img src={bg} className="overlay-bg" width="650" height="565" alt="bg" />
        <div className="modal-logo">
          <img src={Logo} width="140" height="63" alt="logo" />
        </div>
        {/*         <p className="helperTitle">{this.getTitle()}</p>
        <div className="modal-separator"></div> */ }
        <p className="helperSubtitle">{this.getSubtitle()}</p>
        <Link
          onClick={() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'S7_Platforms',
            });
          }}
          to={{ pathname: '/dashboard/integrations' }}
        >
          <Button style={button} onClick={this.props.connectTapped}>
            {' '}
            {this.getButtonText()}
          </Button>
        </Link>
      </Modal>
    );
  }
}

export default observer(ModalAfterOnboarding);
