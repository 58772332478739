/* eslint-disable no-mixed-operators */
// import useDictionaryQueries from '../../../../Utils/community/dictionaries/useDictionaryQueries';
import { useCallback, useMemo, useRef } from 'react';
import _merge from 'lodash/merge';
import WidgetWrapper from '../wrapper';
import widgetSettings from '../_settings';
import { useCommunityContext } from '../../../../Utils/contexts/community';
import defaultIntegrations from '../../../../Data/integrations';
import widgetSkeletons from './_skeletons';
import SkeletonLine from '../../../Skeletons/line';
import kFormatter from '../../../../Utils/funcs/kFormatter';
import { ReactComponent as Icon } from '../../../../Assets/Images/icons/dictionaries/bysource.svg';

export default function WidgetSource({ query }) {
  const { assets } = useCommunityContext();
  const overflower = useRef();
  const series = useMemo(() => {
    if (query.isLoading) return widgetSkeletons.source.series;
    const data = query?.data?.source || {};
    return [{
      name: 'Source',
      size: '100%',
      innerSize: '73%',
      enableMouseTracking: false,
      data: Object.keys(data).filter((a) => !!assets?.[a]).map((assetId) => ({
        name: assets?.[assetId]?.name,
        displayName: assets?.[assetId]?.name,
        color: defaultIntegrations?.find((int) => int.id === assets?.[assetId]?.integration_id)?.pieColor,
        y: data[assetId],
        displayY: kFormatter(data[assetId]),
      })).sort((a, b) => b.y - a.y),
    }];
  }, [query.data, query.isLoading, assets]);
  const settings = useMemo(() => (_merge({
    chart: {
      type: 'pie',
      width: 250,
      height: 250,
      margin: 0,
    },
    series,
  }, widgetSettings({ tooltipDisabled: true }))), [series]);
  const sum = useMemo(() => Object.values(query?.data?.source || {}).reduce((acc, cur) => acc + cur, 0), [query.data]);
  const imagesByAssetName = useMemo(() => Object.keys(query?.data?.source || {}).reduce((acc, cur) => {
    if (!assets?.[cur]) return acc;
    return {
      ...acc,
      [assets[cur].name]: defaultIntegrations.find((int) => int.id === assets[cur].integration_id).image,
    };
  }, {}), [assets, query.data]);
  const scrollHandler = useCallback((e) => {
    if (overflower.current) {
      if (e.target.scrollTop === 0) overflower.current.style.opacity = 1;
      else overflower.current.style.opacity = 0;
    }
  }, []);
  return (
    <WidgetWrapper
      widgetData={settings}
      className="widget-bysource"
      title={(
        <>
          <Icon />
          {' '}
          Matches by source
        </>
      )}
      isEmpty={series[0].data.length === 0}
      centerContent={(
        <div className="widget-bysource_center">
          <div>{kFormatter(sum)}</div>
          <div>Interactions</div>
        </div>
      )}
    >
      <div className="info-table" onScroll={scrollHandler}>
        {series[0].data.length >= 6 && (
          <div className="overflower" ref={overflower} />
        )}
        <div className="info-table_row info-table_header">
          <div />
          <div />
          <div>Interactions</div>
        </div>
        {series[0]?.data.map((dataPoint) => (
          <div key={dataPoint.name} className="info-table_row">
            <div>
              {query.isLoading ? (
                <SkeletonLine style={{ width: '24px', height: '24px' }} />
              ) : (
                <img src={imagesByAssetName[dataPoint.displayName]} width="24" height="24" alt="" />
              )}
            </div>
            <div>{dataPoint.displayName}</div>
            <div>{dataPoint.displayY}</div>
          </div>
        ))}
      </div>
    </WidgetWrapper>
  );
}
