import React from 'react';

import { Card, Divider } from 'antd';
import { Stepper, Step } from 'react-form-stepper';
import { CloseOutlined } from '@ant-design/icons';
import Dal from '../../../Data/Dal';
import ConnectGroup from './connectGroup';
import FacebookGroupsList from './facebookGroupsList';
import AuthorizeApp from './authorizeApp';
import functions from './functions';
import facebookConnectImage from '../../../Assets/Images/redesign/facebook-connect.png';
import facebookMatch from '../../../Assets/Images/redesign/facebook-page-match.svg';

export default class connectWithFacebook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      facebookGroups: [],
      communityID: null,
      groupToAuthorize: '',
      facebookAccessToken: '',
      wasGroupsFetchingSuccessfull: true,
      isStep3Completed: false,
    };
  }

  componentDidMount() {
    // Dal.fetchUser();
    this.setState({
      communityID:
        Dal.communities === undefined || Dal.communities[0] === undefined
          ? null
          : Dal.communities[0].id,
    });
  }

  goBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  goNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  authorizeDone = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  backToAuthorizeCallback = () => {
    window.setTimeout(
      () => this.setState({ activeStep: this.state.activeStep - 1 }),
      600,
    );
  };

  isScreenSmall() {
    return window.screen.width < 1500;
  }

  facebookLoginCallback = (groups, token) => {
    this.setState({
      facebookGroups: groups,
      activeStep: this.state.activeStep + 1,
      facebookAccessToken: token,
    });
  };

  groupsFetchingFailed = () => {
    this.setState({
      wasGroupsFetchingSuccessfull: false,
      activeStep: this.state.activeStep + 1,
    });
  };

  renderContentForStep = () => {
    switch (this.state.activeStep) {
      case 0:
        return (
          <ConnectGroup
            facebookLoginCompleted={(facebookResponse) => functions.getAdminGroups(
              facebookResponse,
              this.facebookLoginCallback,
              this.groupsFetchingFailed,
            )}
            closeModalWithoutFetching={this.props.closeModalWithoutFetching}
          />
        );
      case 1:
        return (
          <div>
            <FacebookGroupsList
              wasGroupsFetchingSuccessfull={
                this.state.wasGroupsFetchingSuccessfull
              }
              groups={this.state.facebookGroups}
              goBack={this.goBack}
              authorizeDone={this.props.closeModal}
              setStep3Completed={() => this.setState({ isStep3Completed: true })}
              communityID={this.state.communityID}
              authorizeGroupClicked={(group) => this.setState({
                groupToAuthorize: group,
                activeStep: this.state.activeStep + 1,
              })}
            />
          </div>
        );
      case 2:
        return (
          <AuthorizeApp
            goBack={this.goBack}
            communityID={this.state.communityID}
            makeGroupAuthorized={this.makeGroupAuthorized}
            backToAuthorizeCallback={this.backToAuthorizeCallback}
            group={this.state.groupToAuthorize}
            accessToken={this.state.facebookAccessToken}
            onIntegrationSuccess={this.props.onIntegrationSuccess}
          />
        );
      default:
    }
  };

  render() {
    return (
      <Card
        style={{ borderRadius: 10 }}
        className="facebookIntegrationCard"
        bodyStyle={{ padding: 0 }}
        headStyle={{ border: 'none', height: '24px', minHeight: '24px' }}
        extra={(
          <CloseOutlined
            style={{ color: '#b2b2b2' }}
            onClick={this.props.closeModalWithoutFetching}
          />
        )}
      >
        <img
          src={this.state.activeStep > 0 ? facebookMatch : facebookConnectImage}
          alt=""
          width={window.screen.width < 1500 ? 154 : 271}
        />
        <p className="connectWithFacebookTitle">Facebook group integration</p>

        <Stepper
          connectorStyleConfig={{
            activeColor: '#d5d7ff',
            disabledColor: '#d5d7ff',
            completedColor: '#d5d7ff',
            size: 4,
          }}
          styleConfig={{
            activeTextColor: '#14005d',
            completedBgColor: '#d5d7ff',
            inactiveTextColor: '#14005d',
            completedTextColor: '#14005d',
            fontWeight: 600,
          }}
          className="facebookSteps"
          activeStep={this.state.activeStep}
        >
          <Step
            label="Connect"
            active={this.state.activeStep === 0}
            className={
              this.state.activeStep === 0
                ? 'facebookStepActive'
                : 'facebookStep'
            }
            completed={this.state.activeStep > 0}
          />
          <Step
            label="Select groups"
            active={this.state.activeStep === 1}
            className={
              this.state.activeStep === 1
                ? 'facebookStepActive'
                : 'facebookStep'
            }
            completed={this.state.activeStep > 1}
          />
          <Step
            label="Authorize"
            active={this.state.activeStep === 2}
            className={
              (this.state.activeStep === 2 || this.state.isStep3Completed)
              && this.state.activeStep !== 0
                ? 'facebookStepActive'
                : 'facebookStep'
            }
            completed={
              !!((this.state.activeStep === 2 || this.state.isStep3Completed)
              && this.state.activeStep !== 0)
            }
          />
        </Stepper>

        <Divider className="facebookDivider" />
        {this.renderContentForStep()}
      </Card>
    );
  }
}
