import { DatePicker, Input, Select } from 'antd';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import * as Yup from 'yup';
import moment from 'moment';
import Dal from '../../../Data/Dal';
import systemFields from '../../../Data/fields/_systemFieldsSchema';
import { ReactComponent as SelectDown } from '../../../Assets/Images/icons/select-down.svg';
import GeneralUserMultiIcon from '../../../Assets/Images/redesign/generalMultiUserIcon';
import dateIcon from '../../../Assets/Images/icons/ico-date.svg';
import cog from '../../../Assets/Images/icons/cat-ico-cog.svg';
import PlatformTemplate from './commonTemplates/platform';
import defaultIntegrations from '../../../Data/integrations';
import { TagsDisplayValue, TagsEditMode } from './propertyFilters/tags';

const { Option } = Select;
const OPERATOR_NUMBER_OPTIONS = ['equals', 'at least', 'less than'];

const OPERATOR_DEFAULT = ['exact match', 'contains'];

const SPECIAL_FIELDS = [{
  key: 'segment',
  label: 'Segment',
}, {
  key: 'source',
  label: 'Source',
}, {
  key: 'platform',
  label: 'Platform',
}, {
  key: 'tag',
  label: 'Tag',
}, {
  key: 'sentiment',
  label: 'Sentiment',
}]; // fields that have a different "value" field template

const LIST_FIELDS = [
  systemFields.email,
  systemFields.name,
  systemFields.phone,
  systemFields.title,
  systemFields.address,
  {
    key: 'assigned_employee',
    label: 'Assigned employee',
  },
];
export const COMBINED_FIELDS = [...SPECIAL_FIELDS, ...LIST_FIELDS];

const operatorOptions = {
  _default: OPERATOR_DEFAULT, // Default is a STRING!
  _dates: ['exact match', 'at least', 'less than'],
  _int: OPERATOR_NUMBER_OPTIONS,
  origin_id: [...OPERATOR_DEFAULT, 'at least', 'less than'],
  type: [...OPERATOR_DEFAULT, 'at least', 'less than'],
  account_id: [...OPERATOR_DEFAULT, 'at least', 'less than'],
};

const PropertyFilterSchema = {
  validationSchema: Yup.object({
    field: Yup.mixed().required('Property is required'),
    operator: Yup.mixed().required('Condition is required'),
    value: Yup.mixed().when('operator', {
      is: (operator) => OPERATOR_NUMBER_OPTIONS.includes(operator), // supposed to be a number
      then: () => Yup.mixed().required('Value is required')
        .test('is-number-valid', 'Must be a number', (value) => value.includes('-') || Number.isInteger(parseInt(value, 10))),
      otherwise: () => Yup.mixed().required('Value is required'),
    }),
  }),
  fields: [
    {
      label: 'Property',
      key: 'field',
      defaultValue: null,
      type: 'select',
      styles: {
        flexBasis: '180px',
      },
      DisplayValue: observer(({ value }) => {
        const systemField = Object.keys(COMBINED_FIELDS).find((k) => k === value);
        const customs = useMemo(() => toJS(Dal.customFields), []);
        const displayFields = useMemo(() => {
          const mapper = (f) => ({
            id: f.id,
            label: f.label,
            icon: defaultIntegrations.find((a) => a.targetTag === (f.service_name === 's3' ? 'csv' : f.service_name))?.image,
          });
          return customs.map(mapper);
        }, [customs]);
        const isCustomField = displayFields.find((c) => c.label === value);
        if (systemField) {
          return COMBINED_FIELDS[systemField].label;
        }
        if (isCustomField) {
          return (
            <div className="superfilter-option">
              <img src={isCustomField.icon} width="18" height="18" alt="" />
              <span style={{ marginLeft: '-3px' }}>{value}</span>
            </div>
          );
        }
        if (value === 'assigned_employee') return 'Assigned employee';
        if (value && typeof value === 'string') {
          return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
      }),
      Template: observer(({ value, onChange }) => {
        const customs = useMemo(() => toJS(Dal.customFields), []);

        const displayFields = useMemo(() => {
          const mapper = (f) => ({
            id: f.id,
            label: f.label,
            icon: defaultIntegrations.find((a) => a.targetTag === (f.service_name === 's3' ? 'csv' : f.service_name))?.image,
          });
          return customs.map(mapper);
        }, [customs]);
        return (
          <Select
            dropdownMatchSelectWidth={false}
            placeholder="Select a property"
            suffixIcon={<SelectDown />}
            value={value}
            onChange={onChange}
            showSearch
          >
            {COMBINED_FIELDS.map((f) => (
              <Option className="superfilter-option" key={`spf-${f.key}`} value={f.key}>{f.label}</Option>
            ))}
            {displayFields.map((cu) => (
              <Option className="superfilter-option" key={`cu-${cu.id}`} value={cu.label}>
                <img src={cu.icon} width="18" height="18" alt="" />
                <span style={{ marginLeft: '-3px' }}>{cu.label}</span>
              </Option>
            ))}
          </Select>
        );
      }),
    },
    {
      label: 'Condition',
      key: 'operator',
      defaultValue: null,
      DisplayValue: ({ value }) => <>{value.charAt(0).toUpperCase() + value.slice(1)}</>,
      Template: observer(({ value, onChange, getValueByKey }) => {
        const isSpecialField = useMemo(() => !!SPECIAL_FIELDS.find((s) => s.key === getValueByKey('field')), [getValueByKey]); // special fields are only exact match
        const isCustomField = useMemo(() => toJS(Dal.customFields).find((c) => c.label === getValueByKey('field')), [getValueByKey]);
        const options = useMemo(() => {
          const field = getValueByKey('field');
          if (operatorOptions[field]) return operatorOptions[field];
          if (SPECIAL_FIELDS.find((s) => s.key === field)) return ['exact match'];
          if (isCustomField && isCustomField.field_type === 'date') return operatorOptions._dates;
          if (isCustomField && isCustomField.field_type === 'int') return operatorOptions._int;
          return operatorOptions._default;
        }, [getValueByKey, isCustomField]);
        useEffect(() => {
          if (!options.includes(value) && value !== null) onChange(null);
        }, [options, value, onChange]);

        useEffect(() => {
          if (isSpecialField) {
            onChange('exact match');
          }
        }, [isSpecialField, onChange]);
        return (
          <Select placeholder="Select a condition" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
            {options.map((opt) => (
              <Option className="superfilter-option" key={`sp-${opt}`} value={opt}>
                {opt.charAt(0).toUpperCase() + opt.slice(1)}
              </Option>
            ))}
          </Select>
        );
      }),
    },
    {
      label: 'Value',
      defaultValue: null,
      key: 'value',
      styles: {
        flexBasis: '180px',
      },
      DisplayValue: observer(({ value, getValueByKey }) => {
        const property = getValueByKey('field');

        if (property === 'platform') {
          const searchBy = ['csv', 's3'].includes(value.toLowerCase()) ? 'csv' : value;
          const int = Dal.integrations.find((f) => f.targetTag === searchBy);
          if (!int) return value;
          return (
            <div className="superfilter-option">
              <img width="20" height="20" alt="platform" src={int.image} />
              <span>{searchBy === 'csv' ? 'CSV' : value.charAt(0).toUpperCase() + value.slice(1)}</span>
            </div>
          );
        }
        if (property === 'segment') {
          const seg = Dal.segments.segments.find((s) => s.id === value);
          if (seg) {
            return (
              <div className="superfilter-option">
                <GeneralUserMultiIcon fill={seg.color} />
                <span>{seg.name}</span>
              </div>
            );
          }
        }
        if (property === 'source') {
          const src = Dal.sources.find((s) => s.id === value);
          const findImageBySourcePlatform = (plat) => {
            let searchBy = plat;
            if (plat === 's3file') searchBy = 'csv';
            const found = Dal.integrations.find((int) => int.assetTag === searchBy);
            if (found) return <img width="20" height="20" alt="platform" src={found.image} />;
            return null;
          };
          if (src) {
            return (
              <div className="superfilter-option">
                {findImageBySourcePlatform(src.platform_name + src.platform_type)}
                <span>{src.name}</span>
              </div>
            );
          }
        }
        if (property === 'tag') {
          return <TagsDisplayValue value={value} />;
        }
        if (property === 'sentiment') {
          return (value.charAt(0).toUpperCase() + value.slice(1));
        }
        return value;
      }),
      Template: observer(({ value, onChange, getValueByKey }) => {
        const [isDateOpen, setDateOpen] = useState(false);
        const property = useMemo(() => getValueByKey('field'), [getValueByKey]);
        const isSpecial = useMemo(() => SPECIAL_FIELDS.find((t) => t.key === property), [property]);
        const isCustomFieldDate = useMemo(() => toJS(Dal.customFields).find((c) => c.label === property)?.field_type === 'date', [property]);
        const template = useMemo(() => { // eslint-disable-line
          if (isCustomFieldDate) {
            return (
              <div className="date-picker_filter">
                <Input key={`${property}_value`} onClick={() => setDateOpen(false)} type="text" value={value} onChange={(e) => onChange(e.target.value)} />
                <div className="date-picker_filter_icon" onClick={() => setDateOpen(!isDateOpen)}>
                  <img src={dateIcon} width="30" height="30" alt="" />
                </div>
                <div className="hidden-date">
                  <DatePicker
                    open={isDateOpen}
                    // defaultValue={moment(value, 'YYYY-MM-DD')._isValid ? moment(value) : undefined}
                    className="datepicker-input"
                    popupClassName="datepicker-picker"
                    onChange={(date, str) => { setDateOpen(false); onChange(str); }}
                  />
                </div>
              </div>
            );
          }
          if (property === 'platform') return <PlatformTemplate value={value} onChange={onChange} />;
          if (property === 'segment') {
            return (
              <Select placeholder="Select a segment" showSearch filterOption={(val, options) => options.key.toLowerCase().includes(val.toLowerCase())} suffixIcon={<SelectDown />} value={value} onChange={onChange}>
                {toJS(Dal.segments).segments.map((opt) => (
                  <Option className="superfilter-option" key={`seg-${opt.name}-${opt.id}`} value={opt.id}>
                    <GeneralUserMultiIcon fill={opt.color} />
                    {opt.name}
                  </Option>
                ))}
              </Select>
            );
          }
          if (property === 'source') {
            const findImageBySourcePlatform = (plat) => {
              let searchBy = plat;
              if (plat === 's3file') searchBy = 'csv';
              const found = Dal.integrations.find((int) => int.assetTag === searchBy);
              if (found) return <img width="20" height="20" alt="platform" src={found.image} />;
              return null;
            };
            return (
              <Select placeholder="Select a source" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
                {toJS(Dal.sources).map((opt) => (
                  <Option className="superfilter-option" key={`sefg-${opt.id}`} value={opt.id}>
                    {findImageBySourcePlatform(opt.platform_name + opt.platform_type)}
                    {opt.name}
                  </Option>
                ))}
              </Select>
            );
          }
          if (property === 'tag') {
            return (
              <TagsEditMode value={value} onChange={onChange} />
            );
          }
          if (property === 'sentiment') {
            return (
              <Select
                placeholder="Select a sentiment"
                suffixIcon={<SelectDown />}
                value={value}
                onChange={onChange}
              >
                <Option className="superfilter-option" value="negative">Negative</Option>
                <Option className="superfilter-option" value="neutral">Neutral</Option>
                <Option className="superfilter-option" value="positive">Positive</Option>
              </Select>
            );
          }
        }, [value, onChange, property, isCustomFieldDate, isDateOpen]);
        return (
          <div>
            {isSpecial || isCustomFieldDate ? (
              template
            ) : (
              <Input key={`${property}_value`} type="text" value={value} onChange={(e) => onChange(e.target.value)} />
            )}
          </div>
        );
      }),
    },
  ],
};

export default PropertyFilterSchema;
