import { Button } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, useMemo, useCallback } from 'react';
import { Form, Formik } from 'formik';
import AmplifyManager from '../../Network/AmplifyManager';
import ChooseStep from './steps/choose';
import VerifyStep from './steps/verify';
import SuccessStep from './steps/success';

const steps = {
  0: ChooseStep,
  1: VerifyStep,
  2: SuccessStep,
};

export default function MFAModal({ onClose, email }) {
  const [phase, setPhase] = useState(1);
  const [context, setContext] = useState({
    selected: 'TOTP',
  });
  const CurrentComp = useMemo(() => steps[phase], [phase]);

  const verifyTOTP = useCallback(async (code) => {
    const result = await AmplifyManager.verifyTotpSetup(code);
    return result;
  }, []);
  return (
    <>
      <motion.div
        key="mfa-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="mfa-overlay"
        onClick={onClose}
      />
      <motion.div
        key="mfa-modal"
        initial={{ opacity: 0, y: '0%', x: '-50%' }}
        animate={{
          opacity: 1, y: '-50%', x: '-50%', transition: { type: 'tween', duration: 0.25, delay: 0.2 },
        }}
        exit={{ opacity: 0, y: '-50%', x: '-50%' }}
        className="mfa-modal"
      >
        <Formik
          initialValues={{
            pin: ['', '', '', '', '', ''],
          }}
          onSubmit={(values, actions) => {
            if (context.selected === 'TOTP') {
              verifyTOTP(values.pin.join('')).then((result) => {
                if (result) {
                  setPhase(2);
                } else {
                  actions.setFieldError('pin', 'Incorrect code, try again');
                }
                actions.setSubmitting(false);
              });
            }
          }}
        >
          {({ isSubmitting, submitForm, values }) => (
            <Form>
              {phase !== 2 && (
                <div className="mfa-modal_header">
                  Activate your authentication app
                </div>
              )}
              <div className="mfa-modal_content">
                <AnimatePresence>
                  <CurrentComp context={context} email={email} setContext={setContext} setPhase={setPhase} />
                </AnimatePresence>
              </div>
              <div className="mfa-modal_footer">
                <Button className={phase === 2 ? 'btn_next' : 'btn_back'} style={{ marginRight: phase === 0 }} onClick={onClose}>
                  {phase === 2 ? 'Close' : 'Back'}
                </Button>
                {phase === 1 && <Button className="btn_next" style={{ marginLeft: '5px' }} disabled={values.pin.join('').length !== 6} loading={isSubmitting} onClick={submitForm}>Verify</Button>}
              </div>
            </Form>
          )}
        </Formik>
      </motion.div>
    </>
  );
}
