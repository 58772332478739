import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import collapseIcon from '../../../../../Assets/Images/icons/drawer-collapse-icon.svg';

export default function Collapse({ icon, iconComp, title, subContent, children, onExpandChange }) {
  const [isExpanded, setExpanded] = useState(false);
  useEffect(() => {
    if (onExpandChange) onExpandChange(isExpanded);
  }, [isExpanded, onExpandChange]);
  return (
    <div className={`crmDrawer-collapse ${isExpanded ? 'expanded' : 'closed'}`}>
      <div className="crmDrawer-collapse_heading" onClick={(e) => { e.stopPropagation(); setExpanded(!isExpanded); }}>
        <div className="crmDrawer-collapse_heading_arrow">
          <img src={collapseIcon} width="22" height="22" alt="Expand / Collapse" />
        </div>
        <div className="crmDrawer-collapse_heading_titles">
          {iconComp || <img src={icon} width="30" height="30" alt="Expand / Collapse" />}
          <span>{title}</span>
        </div>
        <div className="crmDrawer-collapse_heading_subContent">
          {subContent}
        </div>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="crmDrawer-collapse_content"
            initial={{ height: '0px', overflow: 'hidden' }}
            animate={{ height: 'auto' }}
            exit={{ height: '0px' }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}