import React from 'react';

import { observer } from 'mobx-react';
import {
  Modal, Input, Select, Button, Divider,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { makeObservable, observable, runInAction } from 'mobx';

import Dal from '../../Data/Dal';
import config from '../../config/config.json';
import redditImage from '../../Assets/Images/ico-integrate-with-reddit.svg';
import redditIcon from '../../Assets/Images/icons/repo-icon-reddit.svg';
import searchIcon from '../../Assets/Images/icons/general-search-search-ico-active.svg';
import successImage from '../../Assets/Images/ico-integration-succes-with-reddit.svg';
import { getUserSubreddits } from './functions';
import Stepper from '../Stepper/stepper';
import ChannelsList from '../Utils/ChannelsList/channelsSelect.js';

import '../Facebook/Pages/styles.scss';
import './styles.scss';

const SCOPES = 'history%20identity%20read%20mysubreddits';

const { Option } = Select;

const options = [
  { value: 'all', label: 'All channels' },
  { value: 'admin', label: 'Admin' },
  { value: 'public', label: 'Public' },
];

class RedditModal extends React.Component {
  refreshToken = null;
  accessToken = null;
  activeStep = 1;
  subreddits = [];
  filterValue = '';
  isLoading = false;
  channelTypeSelected = 'all';

  constructor() {
    super();

    makeObservable(this, {
      activeStep: observable,
      subreddits: observable,
      refreshToken: observable,
      accessToken: observable,
      filterValue: observable,
      isLoading: observable,
    });
  }

  authorizeReddit() {
    const url = `https://www.reddit.com/api/v1/authorize?client_id=${
      process.env.REACT_APP_REDDIT_CLIENT_ID
    }&state=${Dal.getCurrentCommunityId()}&response_type=code&redirect_uri=${
      process.env.REACT_APP_NOVAK_BASE_URI
    }${
      config.NovakURLs.integrations.reddit
    }&duration=permanent&scope=${SCOPES}`;
    window.location = url;
  }

  async componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const reddit = params.get('reddit');
    if (reddit) {
      runInAction(() => { this.activeStep = 2; });
      const accessToken = params.get('access_token');
      const refreshToken = params.get('refresh_token');
      const userSubreddits = await getUserSubreddits(accessToken);
      userSubreddits.forEach((s) => (s.isSelected = false));
      runInAction(
        () => (this.subreddits = userSubreddits),
        (this.refreshToken = refreshToken),
        (this.accessToken = accessToken),
      );
    }
  }

  async sendSubredditsToNovak() {
    runInAction(() => { this.isLoading = true; });
    const subreddits = this.subreddits.filter((s) => s.isSelected);
    const subredditsParsed = [];
    subreddits.forEach((s) => subredditsParsed.push({
      asset_id: s.id,
      token: this.accessToken,
      service_name: 'reddit',
      type: 'subreddit',
      community_id: Dal.getCurrentCommunityId(),
      name: s.name,
      token_secret: this.refreshToken,
      icon: s.picture,
      subscribers: s.followers_count,
    }));
    await Dal.createNewAssets(subredditsParsed, null);
    this.props.subredditsConnected();
    window.history.replaceState({}, '', '/dashboard/integrations');
    runInAction(() => { this.activeStep = 3; this.isLoading = false; });
  }

  getInsights() {
    this.props.onIntegrationSuccess();
    runInAction(() => { this.activeStep = 1; });
    this.props.closeModalWithoutFetching();
  }

  isEnabled() {
    const subreddits = this.subreddits.filter((s) => s.isSelected);
    return subreddits.length > 0;
  }

  onChannelClick(channel) {
    const { subreddits } = this;
    subreddits.forEach((s) => {
      if (s.id === channel.id) {
        s.isSelected = !s.isSelected;
      }
    });
    runInAction(() => { this.subreddits = subreddits; });
    this.forceUpdate();
  }

  goBackClicked() {
    runInAction(() => this.activeStep--);
    window.history.replaceState({}, '', '/dashboard/integrations');
  }

  onTypeSelected(value) {
    runInAction(() => { this.channelTypeSelected = value; });
    this.forceUpdate();
  }

  renderContentForStep() {
    switch (this.activeStep) {
      case 1:
        return (
          <>
            <ul className="textList">
              <li>Gain insights related to subreddits you follow or manage</li>
              <li id="secParagraph">View members related data</li>
            </ul>
            <p className="Simpoco-automaticall">
              LoudnClear automaticall will get all the posts and comments in
              your community subreddit. Our team of analysts will analyze the
              content and send you a weekly report.
            </p>
            <Divider />
            <Button
              className="connect-reddit-btn"
              icon={<img alt="reddit" src={redditIcon} style={{ marginRight: 10 }} />}
              onClick={this.authorizeReddit}
            >
              Connect with Reddit
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <div style={{ width: 460, margin: '0 auto' }}>
              <Input
                className="channel-search-input reddit"
                placeholder="Search by @feedName"
                allowClear
                suffix={<img alt="search" src={searchIcon} />}
                onChange={(event) => runInAction(() => { this.filterValue = event.target.value; })}
              />
              <Select
                className="select-channels-type"
                defaultValue={options[0].label}
                bordered={false}
                onSelect={this.onTypeSelected.bind(this)}
              >
                {options.map((option) => (
                  <Option
                    className="select-channels-type-option"
                    key={option.value}
                  >
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
            <ChannelsList
              channels={this.subreddits}
              filterValue={this.filterValue}
              onChannelClick={this.onChannelClick.bind(this)}
              filterByType={this.channelTypeSelected}
            />
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className="channels-select-cancel-btn"
                onClick={this.goBackClicked.bind(this)}
              >
                Back
              </Button>
              <Button
                disabled={!this.isEnabled()}
                className="channels-select-connect-btn"
                onClick={this.sendSubredditsToNovak.bind(this)}
                data-step="reddit-integration-connect-after-authorize"
              >
                {this.isLoading ? <LoadingOutlined /> : <p>Connect</p>}
              </Button>
            </div>
          </>
        );
      case 3:
        return (
          <span>
            <p className="reddit-success-text">
              LoudnClear is now connected to your Reddit feeds.
            </p>
            <Button
              className="reddit-get-insights-btn"
              onClick={this.getInsights.bind(this)}
              data-step='reddit-get-insights'
            >
              Get insights
            </Button>
          </span>
        );
      default:
    }
  }

  render() {
    return (
      <Modal
        visible={this.props.isVisible}
        closable
        onCancel={this.props.closeModalWithoutFetching}
        className={`integrationModal reddit step-${this.activeStep}`}
        wrapClassName="integrationModalWrapper"
        footer={null}
      >
        <>
          <img
            src={this.activeStep === 3 ? successImage : redditImage}
            style={this.activeStep === 3 ? { marginBottom: 30 } : {}}
            alt=""
            width={window.screen.width < 1500 ? 154 : 271}
          />
          <p className="pagesHeader">Reddit community integration</p>
          {this.activeStep < 3 ? (
            <span>
              <Stepper step={this.activeStep} />
              <Divider />
            </span>
          ) : null}
          {this.renderContentForStep()}
        </>
      </Modal>
    );
  }
}

export default observer(RedditModal);
