import { memo } from 'react';
import { getInitials } from '../../../../Data/StringUtils';
import { getRandomColors } from '../../../../Data/colors/index';

function AvatarNameColumn({ value, className }) {
  return (
    <div className={className}>
      {value && (
        <div className="created-by_col_avatar" style={{ backgroundColor: getRandomColors().textColor }}>
          {getInitials(value)}
        </div>
      )}
      <div>{value}</div>
    </div>
  );
}

export default memo(AvatarNameColumn);
