import { observer } from 'mobx-react';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Tag, Spin } from 'antd';
import axios from 'axios';
import { runInAction, autorun, toJS } from 'mobx';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import closeIcon from '../../../../Assets/Images/icons/drawer-close.svg';
import closeTagIcon from '../../../../Assets/Images/icons/general-x-ui-x-ico-active.svg';
import lastActiveIcon from '../../../../Assets/Images/icons/general-time-ui-time-active.svg';
import { ReactComponent as ReportIcon } from '../../../../Assets/Images/redesign/drawer-report.svg';
import DrawerHandler from '../state/handler';

import useDrawerContext from '../context/DrawerContext';
import dalInstance from '../../../../Data/Dal';
import UsageChart from './chart';
import ErrorBoundary from '../../../../Components/ErrorBoundary/errorBoundary';
import TagButton from './tags/buttonGateway';
import useTagsQuery from '../../../../Utils/community/tags/useTagsQuery';
import SkeletonLine from '../../../../Components/Skeletons/line';

function transformName(name) {
  const splitted = name.split(' ');
  return splitted.map((n) => n.charAt(0).toUpperCase() + n.slice(1)).join(' ');
}

function avatarName(name) { // eslint-disable-line
  if (name.split(' ')[1]) {
    return name.split(' ')[0].charAt(0).toUpperCase() + name.split(' ')[1].charAt(0).toUpperCase();
  }
  return name.charAt(0).toUpperCase();
}

function Heading({ onClose }) {
  const { tags: { data: tagsData } } = useTagsQuery();
  const isLoading = DrawerHandler.isLoading.userDetails;
  const { updateTag } = useDrawerContext();
  const [avatar, setAvatar] = useState(avatarName(toJS(DrawerHandler.data.userDetails)?.names?.[0] || 'Member'));
  const [isRemovingTag, setTagRemoving] = useState('');
  const lastActive = useMemo(() => {
    if (DrawerHandler.data.userDetails?.activity?.[0]) {
      const parsed = moment(DrawerHandler.data.userDetails.latest_activity_time, 'YYYY-MM-DD HH:mm:ss');
      return `Last active ${parsed.fromNow()}`;
    }
    return null;
  }, []);

  const getTagLabel = useCallback((id) => (tagsData || []).find((t) => t.id === id)?.label || '', [tagsData]);
  const handleTagRemove = useCallback((tagId) => {
    setTagRemoving(tagId);
    const tag = { id: tagId, label: getTagLabel(tagId) };
    updateTag.mutate({ action: 'REMOVE', payload: tag }, {
      onSuccess: () => {
        setTagRemoving('');
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTagLabel]);

  useEffect(() => autorun(() => {
    if (!DrawerHandler.hasAccount && !DrawerHandler.isLoading.userDetails && DrawerHandler.data.userDetails.custom_data) {
      const website = Object.keys(toJS(DrawerHandler.data.userDetails.custom_data)).reduce((acc, cur) => {
        if (cur.toLowerCase() === 'website') return DrawerHandler.data.userDetails.custom_data[cur];
        return acc;
      }, '');
      if (website) {
        (async () => {
          try {
            const url = new URL(website.includes('http') ? website : `https://${website}`);
            await axios.get(`https://logo.clearbit.com/${url.host}`);
            setAvatar(<img src={`https://logo.clearbit.com/${url.host}`} width="70" alt="logo" />);
          } catch (e) {
            // do nothing
            setAvatar(avatarName(DrawerHandler.data.userDetails.names?.[0]));
          }
        })();
      } else if (DrawerHandler.data.userDetails) {
        setAvatar(avatarName(DrawerHandler.data.userDetails.names?.[0]));
      }
    }
  }), []);

  if (!DrawerHandler.data.userDetails) return <></>;
  return (
    <div className="crmDrawer-heading">
      <div className="crmDrawer-heading_topbar">
        <div className="crmDrawer-heading_topbar_name">
          <img onClick={onClose} style={{ cursor: 'pointer' }} src={closeIcon} width="24" height="24" alt="close" />
          <div className="username">
            {isLoading ? <SkeletonLine style={{ width: '100px', height: '20px' }} /> : transformName(DrawerHandler.data.userDetails.names?.[0] || '') || 'Member'}
          </div>
          <div className={`crmDrawer-heading_info_desc_bottom_tags ${DrawerHandler.data.userDetails.tags?.length !== 0 ? 'hastags' : 'notags'}`}>
            {DrawerHandler.isLoading.userDetails ? (
              <></>
            ) : (
              <>
                {DrawerHandler.data.userDetails.tags?.length !== 0 && DrawerHandler.data.userDetails?.tags?.slice(0, 2)?.map((t) => (
                  <Tag
                    key={t}
                    closable={false}
                    icon={
                      isRemovingTag === t ? (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 11, marginRight: '3px' }} spin />} />
                      ) : (
                        <img
                          src={closeTagIcon}
                          onClick={() => handleTagRemove(t)}
                          alt=""
                        />
                      )
                    }
                    color="#cce7ff"
                    className="crmDrawer-tag"
                  >
                    <p className="crmDrawer-tag_text">{getTagLabel(t).toUpperCase()}</p>
                  </Tag>
                ))}
                {DrawerHandler.data.userDetails.tags?.length > 2 && (
                  <Tag
                    closable={false}
                    color="#cce7ff"
                    icon={<></>}
                    className="crmDrawer-tag"
                  >
                    <p className="crmDrawer-tag_text">
                      +
                      {DrawerHandler.data.userDetails.tags.length - 2}
                    </p>
                  </Tag>
                )}
              </>
            )}
          </div>
          {lastActive && (
            <div className="lastactive">
              <img src={lastActiveIcon} width="20" height="20" alt="last active" />
              <span>
                {lastActive}
              </span>
            </div>
          )}
        </div>
        <div className="crmDrawer-heading_topbar_actions">
          <div className="crmDrawer-heading_topbar_actions_tag">
            <TagButton />
          </div>
          <div className="crmDrawer-heading_topbar_actions_report" onClick={() => runInAction(() => { dalInstance.showReportModal = true; })}>
            <ReportIcon />
          </div>
        </div>
      </div>
      {!DrawerHandler.isUserNotFound && (
        <div className="crmDrawer-heading_info">
          <div className="crmDrawer-heading_info_left">
            <div className="crmDrawer-heading_info_left_avatar">
              <div className="theAvatar" style={{ backgroundColor: typeof avatar === 'string' ? 'rgb(44, 131, 231)' : '#fff' }}>{avatar}</div>
            </div>
            <div className="crmDrawer-heading_info_left_desc">
              <div className="crmDrawer-heading_info_desc_name notags">
                {isLoading ? <SkeletonLine style={{ width: '100px', height: '20px' }} /> : transformName(DrawerHandler.data.userDetails.names?.[0] || '') || 'Member'}
              </div>
              <div className="crmDrawer-heading_info_desc_bottom">
                {/* <div className="crmDrawer-heading_info_desc_bottom_segment">
              <CrmUserLabelView
                userType={getUserType(DrawerHandler.data.userDetails.labels)}
              />
            </div> */}
              </div>
            </div>
          </div>
          <div className="crmDrawer-heading_info_chart">
            <ErrorBoundary componentName="crmDrawer-usage">
              <UsageChart />
            </ErrorBoundary>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(Heading);
