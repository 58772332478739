import { useQuery } from 'react-query';
import NetworkManager from '../../Network/NetworkManager';
import { useCommunityContext } from '../contexts/community';

export default function useCustomFields() {
  const { communityId } = useCommunityContext();
  return useQuery([communityId, 'customFields'], () => NetworkManager.getAllCustomFields(), {
    enabled: !!communityId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 60, // 60 minutes stale time
  });
}
