import React from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';

import { observer } from 'mobx-react';

import { Button, Divider, Space } from 'antd';

class ConnectPage extends React.Component {
  getUserPages = async (userToken) => {
    try {
      const url = `https://graph.facebook.com/v9.0/me/accounts?fields=name,picture,cover,id,followers_count,access_token&access_token=${userToken}`;
      const options = { method: 'GET' };
      const pagesResponse = await fetch(url, options);
      const pagesData = await pagesResponse.json();
      this.props.pagesConnected(pagesData.data, userToken);
    } catch (error) {
      console.error('Failed to fetch user pages: ', error);
    }
  };

  facebookLoginCompleted = async (facebookResponse) => {
    const userToken = facebookResponse.tokenDetail.accessToken;
    await this.getUserPages(userToken);
  };
  render() {
    return (
      <>
        <ul className="textList">
          <li>
            Gain insights related to content in your group (Posts and Comments)
          </li>
          <li id="secParagraph">
            View members related data (this will be relevant only to LoudNClear app
            opt-in members)
          </li>
        </ul>
        <p className="Simpoco-automaticall">
          LoudNClear automaticall will get all the posts and comments in your
          community feed. Our team of analysts will analyze thee content and
          send you a weekly report.
        </p>
        <Divider />
        <Space direction="vertical" size={window.screen.width < 1500 ? 10 : 30}>
          <FacebookProvider
            appId={process.env.REACT_APP_AUTHORIZED_FACEBOOK_APP_ID}
          >
            <LoginButton
              className="connectPageBtn"
              scope="pages_show_list,pages_read_user_content,pages_manage_metadata,pages_messaging,pages_read_engagement"
              onCompleted={this.facebookLoginCompleted}
              onError={(error) => console.error('Login with Facebook failed: ', error)}
            >
              <span>Connect Messenger</span>
            </LoginButton>
          </FacebookProvider>
          <Button
            type="link"
            className="closePageModalBtn"
            onClick={this.props.closeModalWithoutFetching}
          >
            Close
          </Button>
        </Space>
      </>
    );
  }
}

export default observer(ConnectPage);
