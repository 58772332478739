/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import _ from 'underscore';
import dalInstance from '../../../../../../Data/Dal';
import DrawerHandler from '../../../state/handler';

import userDetaisIco from '../../../../../../Assets/Images/icons/cat-ico-user.svg';
import Collapse from '../collapse';
import MiniSpinner from '../../common/miniSpinner';
import { useSettingsContext } from '../../../../../../Utils/community/useSettings';
import DetailsFieldLine from './fieldLine';
import Platforms from './platforms';

function UserDetailsCollapse({ platformLinks }) {
  const [showMore, setShowMore] = useState(false);
  const isLoading = DrawerHandler.isLoading.userDetails;
  const { data: { 'drawer.pinned': pinned, 'drawer.hidden_fields': hiddenFields, 'drawer.display_names_map': displayNames }, updateMutation } = useSettingsContext();
  const [draftPinned, setDraftPinned] = useState(pinned || []);
  const allCustomFields = useMemo(() => toJS(dalInstance.customFields), []);
  const currentDetailsDisplay = useMemo(() => {
    const userDetails = toJS(DrawerHandler.data.userDetails);
    const mapped = [];
    const getCustomFieldVariants = (field) => { // returns the values that are inaccurate
      if (userDetails.crm_custom_data_variants) {
        const variants = userDetails.crm_custom_data_variants?.[field];
        if (variants) {
          const values = [...new Set([
            userDetails.custom_data?.[field] === '' ? 'N/A' : userDetails.custom_data?.[field],
            ...variants.map((x) => x === '' ? 'N/A' : x),
          ])];
          if (values.length > 1) return values;
          return values[0];
        }
      }
      return null;
    };
    userDetails?.emails?.[0] && mapped.push({
      label: 'Email',
      id: 'system-email',
      value: userDetails.emails[0],
      pinned: draftPinned.indexOf('Email') === -1 ? false : draftPinned.indexOf('Email') + 1,
    });
    userDetails?.phones?.[0] && mapped.push({
      label: 'Phone',
      id: 'system-phone',
      value: userDetails.phones[0],
      pinned: draftPinned.indexOf('Phone') === -1 ? false : draftPinned.indexOf('Phone') + 1,
    });
    if (userDetails?.assigned_employees && userDetails.assigned_employees?.length > 0) {
      const hasUsefulData = userDetails.assigned_employees.find((a) => a?.id && a?.name);
      if (hasUsefulData) {
        const obj = {
          label: 'Assigned Employee',
          originLabel: 'Assigned Employee',
          id: 'system-assigned-employee',
          value: [],
          pinned: draftPinned.indexOf('Assigned Employee') === -1 ? false : draftPinned.indexOf('Assigned Employee') + 1,
        };
        userDetails.assigned_employees.forEach((emp) => {
          if (emp?.id && emp?.name) {
            obj.value.push(emp.name);
          }
        });
        if (obj.value.length > 0) {
          if (obj.value.length === 1) obj.value = obj.value[0];
          mapped.push(obj);
        }
      }
    }
    mapped.push(...Object.keys(userDetails.custom_data || {}).map((key) => ({
      label: (displayNames || {})?.[key] ? displayNames[key] : key,
      originLabel: key,
      id: allCustomFields.find((x) => x.label === key)?.id,
      value: getCustomFieldVariants(key) ?? userDetails.custom_data?.[key],
      pinned: draftPinned.indexOf(key) === -1 ? false : draftPinned.indexOf(key) + 1,
    })));
    const final = [
      ...mapped.filter((p) => p.pinned).sort((a, b) => a.pinned - b.pinned),
      ...mapped.filter((b) => !b.pinned),
    ];
    return final.filter((dt) => dt.value && !(hiddenFields || []).includes(String(dt.id))); // fitler by those who have value, and not hidden
  }, [showMore, DrawerHandler.data.userDetails, isLoading, draftPinned, hiddenFields]); // eslint-disable-line

  const pinnedLength = useMemo(() => currentDetailsDisplay.reduce((acc, cur) => cur.pinned ? acc + 1 : acc, 0), [currentDetailsDisplay]);

  const handlePinClick = useCallback((dt) => {
    const newV = dt.pinned !== false ? draftPinned.filter((p) => p !== (dt.originLabel || dt.label)) : [...draftPinned, dt?.originLabel || dt.label];
    setDraftPinned(newV);
    updateMutation.mutate({ 'drawer.pinned': newV });
  }, [updateMutation, draftPinned]);

  useEffect(() => {
    if (_.isEqual(pinned, !draftPinned)) {
      setDraftPinned(pinned);
    }
  }, [pinned]); // eslint-disable-line
  if (!DrawerHandler.id) return <></>;
  return (
    <Collapse icon={userDetaisIco} subContent={DrawerHandler.isLoading.userDetails ? <MiniSpinner /> : <></>} title="Details">
      <div className="collapse-userdetails">
        <Platforms platformLinks={platformLinks} sources={DrawerHandler.data.userDetails.sources} isLoading={DrawerHandler.isLoading.userDetails} />
        <div className="collapse-userdetails_list">
          {isLoading ? (
            Array(3).fill(null).map((_, ind) => (
              <motion.div
                className="collapse-userdetails_list_item skeleton-wrapper"
                key={ind}
              >
                <div className="isSkeleton" />
              </motion.div>
            ))
          ) : (
            <AnimatePresence initial={false}>
              {currentDetailsDisplay.slice(0, showMore ? 9999 : Math.max(pinnedLength, 4)).map((dt, ind) => (
                <DetailsFieldLine field={dt} handlePinClick={handlePinClick} currentLength={currentDetailsDisplay.length} allCustomFields={allCustomFields} ind={ind} key={`${dt.label}`} />
              ))}
            </AnimatePresence>
          )}
        </div>
        <AnimatePresence initial={false}>
          {!DrawerHandler.isLoading.userDetails && currentDetailsDisplay.length > (Math.max(pinnedLength, 4)) && (
            <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto' }} className="collapse-userdetails_showmore" onClick={() => setShowMore(!showMore)}>
              {showMore ? '- Show less' : '+ Show more'}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Collapse>
  );
}

export default observer(UserDetailsCollapse);
