import { Button, Input } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import verifyEmailSchemas from '../../../Components/OnboardingNew/steps/verify/_fieldsSchema';
import '../styles.scss';
import phoneIco from '../../../Assets/Images/icons/check-phone-ico.svg';
import authIco from '../../../Assets/Images/icons/check-code-ico.svg';
import arrow from '../../../Assets/Images/icons/arrow-left.svg';

export default function MFASms({ type, error, onSubmit, goBack }) {
  const [value, setValue] = useState('');
  return (
    <>
      <div className="login-verify">
        <img className="login-verify_arrow" onClick={goBack} src={arrow} width="24" height="24" alt="" />
        <Formik
          initialValues={{
            pin: ['', '', '', '', '', ''],
          }}
          onSubmit={(values, actions) => {
            onSubmit(values.pin.join('')).then(() => {
              actions.setSubmitting(false);
            });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="smalltitle">
                {type === 'SMS_MFA' ? (
                  'Please check your phone for authentication code'
                ) : (
                  'Please check your authentication app for your code'
                )}
              </div>
              <div className="login-verify_img">
                <img src={type === 'SMS_MFA' ? phoneIco : authIco} width="80" height="80" alt="" />
              </div>
              <div className="pin-inputs">
                {verifyEmailSchemas.fieldsSchema.map((f) => (
                  <Field name={f.key}>
                    {({ field }) => (
                      <f.Template key={f.key} field={field} />
                    )}
                  </Field>
                ))}
              </div>
              <div className="pin-inputs_error">
                {error}
              </div>
              <Button
                style={{
                  marginTop: 30,
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  display: 'block',
                  width: 380,
                  height: 50,
                }}
                className="login-btn"
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Verify
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
