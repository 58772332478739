import emptyStateIcon from '../../../Assets/Images/redesign/no-filter-match-ico.svg';
import useDictionaryContext from '../context/dictionary.context';

export default function EmptyFilterResultState() {
  const { setSuperFilterVisible } = useDictionaryContext();
  return (
    <div className="dict-empty filter-state">
      <img src={emptyStateIcon} alt="" width="80" height="80" />
      <div className="dict-empty_title">No matching results</div>
      <div className="dict-empty_desc">
        You can
        {' '}
        <span className="dict-empty_link" onClick={() => setSuperFilterVisible(true)}>try a different filter option</span>
      </div>
    </div>
  );
}
