import { FiFolder } from 'react-icons/fi';
import { HiPlus } from 'react-icons/hi';
import DropdownMenu from '../../DropdownMenu';

const Add = ({
  entityName, entityIcon, handleNewEntity, handleNewFolder,
}) => {
  const menu = [];
  if (entityName !== 'segment') {
    menu.push({
      key: 'new_entity',
      label: `New ${entityName}`,
      icon: entityIcon,
      onClick: handleNewEntity,
    });
  }
  menu.push({
    key: 'new_folder',
    label: 'New folder',
    icon: <FiFolder />,
    onClick: handleNewFolder,
  });
  return (
    <DropdownMenu
      button={(
        <div className="entity-add">
          <HiPlus />
          Add
        </div>
      )}
      items={menu}
    />
  );
};

export default Add;
