import React from 'react';
import { observer } from 'mobx-react';
import {
  Modal, Button, Input, Divider,
} from 'antd';
import { makeObservable, observable, runInAction } from 'mobx';
import { TwitterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import config from '../../config/config.json';
import twitterConnectImage from '../../Assets/Images/icons/ico-integrate-with-twitter.svg';
import twitterConnectSuccessImage from '../../Assets/Images/ico-integration-succes-with-twitter.svg';
import TwitterDal from './twitterDal';
import Dal from '../../Data/Dal';
import Stepper from '../Stepper/stepper.js';
import ChannelsSelect from '../Utils/ChannelsList/channelsSelect';
import searchIcon from '../../Assets/Images/icons/general-search-search-ico-active.svg';

import '../Facebook/Pages/styles.scss';
import '../Utils/ChannelsList/styles.scss';
import NetworkManager from '../../Network/NetworkManager';
import MiniSpinner from '../../Screens/Crm/Drawer/comps/common/miniSpinner';

class TwitterModal extends React.Component {
  twitterConnected = false;
  getInsightsClicked = false;

  constructor() {
    super();

    makeObservable(this, {
      twitterConnected: observable,
      getInsightsClicked: observable,
    });
    this.state = {
      isLoading: false,
      error: '',
    };
    this.signIn = this.signIn.bind(this);
  }

  componentDidMount() {
    // runInAction(() => TwitterDal.step = 3)
    const params = new URLSearchParams(window.location.search);
    const twitter = params.get('twitter');
    if (twitter) {
      runInAction(() => { this.twitterConnected = true; });
    }
  }

  async signIn() {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const resp = await window.fetch(
      `${process.env.REACT_APP_NOVAK_BASE_URI}${config.NovakURLs.integrations.twitter}`,
      options,
    );
    const url = await resp.json();
    window.location = url;
  }

  getInsights() {
    this.props.onIntegrationSuccess();
    this.props.closeTwitterModal();
    runInAction(() => { TwitterDal.step = 1; });
  }

  onChannelClick(channel) {
    const { channels } = TwitterDal;

    channels.forEach((ch) => {
      if (ch.id === channel.id) {
        ch.isSelected = !ch.isSelected;
      }
    });
    runInAction(() => { TwitterDal.channels = channels; });
    this.forceUpdate();
  }

  async storeAssets() {
    const community_id = Dal.getCurrentCommunityId();
    const channelsParsed = [];
    const selectedChannels = TwitterDal.channels.filter(
      (ch) => ch.isSelected === true,
    );
    selectedChannels.forEach((c) => {
      channelsParsed.push({
        community_id,
        id: c.id,
        name: c.name,
        service_name: 'twitter',
        type: 'page',
        access_token: TwitterDal.token,
        token_secret: TwitterDal.secret,
        image: c.picture,
        followers_count: c.followers_count,
      });
    });
    await Dal.createNewAssets(channelsParsed, null);
    this.props.assetAdded();
    runInAction(() => TwitterDal.step++);
  }

  onBackClick() {
    runInAction(() => TwitterDal.step--);
  }

  isConnectEnabled() {
    const channelToConnect = TwitterDal.channels.find(
      (ch) => ch.isSelected === true,
    );
    return channelToConnect !== undefined;
  }

  async onSubmitSearch(value) {
    if (!value) return null;
    this.setState({ isLoading: true, error: '' });
    const username = (() => {
      if (value.includes('http')) {
        const reg = /(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/g;
        const matches = [...value.matchAll(reg)];
        return matches?.[0]?.[2];
      }
      const reg = /(\w+)/g;
      const matches = [...value.matchAll(reg)];
      return matches?.[0]?.[1];
    })();
    const user = await NetworkManager.searchTwitterUser(username);
    this.setState({ isLoading: false });
    if (user) {
      runInAction(() => {
        TwitterDal.channels.push({
          isSelected: true,
          id: user.id,
          name: user.name,
          picture: user.picture,
          followers_count: user.followers_count,
        });
      });
    } else {
      this.setState({ error: `User ${value} not found` });
    }
    return user;
  }

  renderContentForStep() {
    switch (TwitterDal.step) {
      case 1:
        return (
          <>
            <img src={twitterConnectImage} alt="" width={271} />
            <p className="pagesHeader">Twitter feed integration</p>
            <Stepper step={1} />
            <div
              style={{
                paddingTop: 20,
                borderTop: '1px solid rgba(0, 0, 0, 0.06)',
              }}
            >
              <ul className="textList twitterTextList">
                <li>
                  Gain insights related to content in feeds you follow or manage
                </li>
                <li id="secParagraph">View followers related data</li>
              </ul>
            </div>
            <p className="Simpoco-automaticall shortTextTwitter">
              LoudnClear automaticall will get all the posts and comments in
              your community feed. Our team of analysts will analyze thee
              content and send you a weekly report.
            </p>
            <Divider />
            <Button
              icon={<TwitterOutlined />}
              onClick={this.signIn}
              className="twitterBtn"
            >
              Connect with Twitter
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <img
              src={twitterConnectImage}
              alt=""
              width={window.screen.width < 1500 ? 154 : 271}
            />
            <p className="pagesHeader">Twitter feed integration</p>
            <Stepper step={2} />
            <Divider />
            <p className="twitter-select-feed-text">Search feeds to connect</p>
            <p className="twitter-select-feed-text-small">
              Search Twitter feeds you want to connect by providing a
              {' '}
              <span style={{ fontWeight: 'bold' }}>link</span>
              {' '}
              or by
              {' '}
              <span style={{ fontWeight: 'bold' }}>username</span>
              {' '}
            </p>
            <Input
              className="channel-search-input"
              placeholder="Search by @feedName or link"
              allowClear
              suffix={this.state.isLoading ? <MiniSpinner /> : <img style={{ cursor: 'pointer ' }} onClick={(e) => this.onSubmitSearch(e.target.value)} alt="search" src={searchIcon} />}
              onKeyDown={(e) => e.key === 'Enter' ? this.onSubmitSearch(e.target.value) : null}
              onChange={(event) => runInAction(
                () => (TwitterDal.filterInputValue = event.target.value),
              )}
            />
            {this.state.error && <div className="channel-error">{this.state.error}</div>}
            <ChannelsSelect
              channels={TwitterDal.channels}
              onChannelClick={this.onChannelClick.bind(this)}
              filterValue=""
            />
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                className="channels-select-cancel-btn"
                onClick={this.onBackClick.bind(this)}
              >
                Back
              </Button>
              <Button
                disabled={!this.isConnectEnabled()}
                className="channels-select-connect-btn"
                onClick={this.storeAssets.bind(this)}
              >
                Connect
              </Button>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <img src={twitterConnectSuccessImage} alt="" />
            <p className="pagesHeader">Twitter feeds connected</p>
            <p className="pagesSubtitle">
              LoudnClear is now connected to your Twitter feeds.
            </p>
            <Button
              className="getInsigntsBtn twitter"
              style={{ marginTop: '35px' }}
              onClick={this.getInsights.bind(this)}
            >
              Get insights
            </Button>
            <div className="connect-more">
              <Link to="/dashboard/integrations">Connect more feeds</Link>
            </div>
          </>
        );
      default:
    }
  }

  closeModal() {
    this.props.closeModalWithoutFetching();
    runInAction(() => { TwitterDal.step = 1; });
  }

  render() {
    return (
      <Modal
        visible={this.props.isVisible}
        closable
        onCancel={this.closeModal.bind(this)}
        className="integrationModal"
        wrapClassName="integrationModalWrapper"
        footer={null}
      >
        {this.renderContentForStep()}
      </Modal>
    );
  }
}

export default observer(TwitterModal);
