import { runInAction, toJS } from 'mobx';
import { createContext, useContext } from 'react';
import { useQuery, useMutation } from 'react-query';
import dalInstance from '../../../Data/Dal';
import NetworkManager from '../../../Network/NetworkManager';
import { BoardContext } from './useBoard';

export const WidgetContext = createContext({});

export default function useWidget(communityId, boardId, segmentBoard, widgetId) {
  const { refreshThisBoard } = useContext(BoardContext);
  const widgetQuery = useQuery(['board', boardId, 'widgets', widgetId, segmentBoard], () => NetworkManager.getBoardWidget(communityId, boardId, segmentBoard, widgetId), {
    enabled: !!boardId && !!widgetId,
    staleTime: 1000 * 60 * 15, // 15 minutes stale time
  });

  const deleteMutation = useMutation(() => NetworkManager.deleteWidget(communityId, boardId, widgetId), {
    onSuccess: () => {
      // setDraft((cur) => ({ ...cur, layout: cur.layout.filter((l) => l.i !== widgetId) }));
      runInAction(() => {
        const all = toJS(dalInstance.boards);
        const b = all.find((bo) => bo.id.toString() === boardId.toString());
        b.layout = b.layout.filter((l) => l.i !== widgetId);
        dalInstance.boards = all;
        refreshThisBoard();
      });
    },
  });

  return {
    widgetQuery,
    deleteMutation,
  };
}
