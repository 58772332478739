import { ReactComponent as MenuIcon } from '../../../../Assets/Images/icons/dashboards/dotmenu.svg';
import { ReactComponent as EditIcon } from '../../../../Assets/Images/icons/dashboards/edit-widget.svg';
import { ReactComponent as TrashIcon } from '../../../../Assets/Images/icons/dashboards/trash.svg';
import { ReactComponent as DupIcon } from '../../../../Assets/Images/icons/dashboards/duplicate.svg';
import DropdownMenu from '../../../DropdownMenu';
import GhostButton from '../../../Buttons/ghostButton';

const MoreMenu = ({ openEdittor, onDelete }) => {
  const menu = [
    {
      key: 'edit',
      label: 'Edit chart',
      icon: <EditIcon />,
      onClick: openEdittor,
    },
    {
      key: 'dup',
      label: 'Duplicate',
      disabled: true,
      icon: <DupIcon />,
      onClick: openEdittor,
    },
    {
      key: 'div1',
      divider: true,
    },
    {
      key: 'Remove',
      label: 'Remove',
      danger: true,
      icon: <TrashIcon />,
      onClick: onDelete,
    },
  ];
  return (
    <DropdownMenu
      button={<GhostButton styles={{ padding: '0 0px', height: '30px' }} icon={<MenuIcon />} />}
      items={menu}
    />
  );
};

export default MoreMenu;
