import { useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo } from 'react';
import { ReactComponent as Check } from '../../../../Assets/Images/icons/checkmark-csv.svg';

export default function EnrichPicker({ data, handleSelect }) {
  const { values, setFieldValue } = useFormikContext();
  const { enrich } = values;
  const enrichType = useMemo(() => {
    if (typeof enrich === 'object') return Object.keys(enrich)[0];
    return null;
  }, [enrich]);
  const enrichSubs = useMemo(() => {
    if (typeof enrich === 'object') return Object.values(enrich)[0];
    return [];
  }, [enrich]);
  const isSelected = useMemo(() => enrichType === data.key, [enrichType, data]);
  const choose = useCallback((v) => {
    if (Object.values(enrich)[0].includes(v)) {
      setFieldValue('enrich', { [enrichType]: enrichSubs.filter((f) => f !== v) });
    } else {
      setFieldValue('enrich', { [enrichType]: [...enrichSubs, v] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrichType, enrich, enrichSubs]);
  return (
    <div className={`dict-checker_picker_item ${isSelected ? 'selected' : ''}`} onClick={() => handleSelect(data.key)}>
      <div className="dict-checker_picker_item_main">
        <div className="dict-checker_picker_item_icon">
          {data.icon}
        </div>
        <div className="dict-checker_picker_item_info">
          <div>{data.title}</div>
          <div>{data.desc}</div>
        </div>
      </div>
      <AnimatePresence>
        {isSelected && (
          <motion.div
            initial={{ height: 0, overflow: 'hidden' }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            className="subs"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="inner">
              {data.subs.map((s) => (
                <div key={s.key} onClick={() => choose(s.label)} className={`subs-item ${enrichSubs.includes(s.label) ? 'selected' : ''}`}>
                  <Check />
                  <span>{s.label.charAt(0).toUpperCase() + s.label.slice(1)}</span>
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
