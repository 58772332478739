const contactInteractionAddTrigger = {
  id: 3, // IDS ARE EQUIVALENT TO SQL
  entity: 'interaction',
  action: 'add',
  relation: 'contact',
  acceptedMetadata: [],
  validationSchema: null,
  templates: {
    Display: () => (
      <span>
        When a contact made an interaction
      </span>
    ),
    Edit: () => (
      <span>
        <span className="highlight">When</span>
        {' '}
        a contact made an interaction
      </span>
    ),
  },
};

export default contactInteractionAddTrigger;
