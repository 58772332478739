import { observer } from 'mobx-react';
import { Select } from 'antd';
import { ReactComponent as SelectDown } from '../../../../Assets/Images/icons/select-down.svg';

const { Option } = Select;

const TimespanTemplate = observer(({ value, onChange }) => (
  <Select placeholder="Select time period" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
    <Option className="superfilter-option" value="7d">Last 7 days</Option>
    <Option className="superfilter-option" value="30d">Last 30 days</Option>
    <Option className="superfilter-option" value="60d">Last 60 days</Option>
    <Option className="superfilter-option" value="90d">Last 90 days</Option>
    <Option className="superfilter-option" value="all_time">All time</Option>
  </Select>
));

export const TimespanContentTemplate = observer(({ value, onChange }) => (
  <Select placeholder="Select time period" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
    <Option className="superfilter-option" value="30d">Last 30 days</Option>
  </Select>
));

export const TimespanDisplayValue = ({ value }) => value.toString() !== 'all_time' ? `Last ${value.slice(0, value.length - 1)} days` : 'All time';

export default TimespanTemplate;
