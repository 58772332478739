import {
  useCallback, useLayoutEffect, useMemo, useState,
} from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import NetworkManager from '../../../../Network/NetworkManager';
import { useCommunityContext } from '../../../../Utils/contexts/community';
import DrawerHandler from '../state/handler';
// import useOneMutation from '../../../../Utils/useOneMutation';

export default function useDrawer(contactId) {
  const { communityId } = useCommunityContext();
  const queryClient = useQueryClient();
  // const [page, setPage] = useState(1);
  const [platformLinks, setPlatformLinks] = useState({});
  const activitiesQuery = useInfiniteQuery(['crm', contactId, 'activities'], ({ pageParam = 1 }) => NetworkManager.getMemberActivity(communityId, contactId, pageParam), {
    staleTime: 1000 * 60 * 60,
    enabled: !!contactId && !!communityId,
    retry: 7,
    retryDelay: 3000 * 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => lastPage.paging.nextPage,
  });

  const combinedActivitiesPagingData = useMemo(() => {
    const currentData = activitiesQuery?.data?.pages?.reduce((acc, cur) => [
      ...acc,
      ...cur.results,
    ], []);
    if (activitiesQuery.isFetchingNextPage) return [...(currentData || [])];

    if (activitiesQuery.isLoading) return [];
    return currentData || [];
  }, [activitiesQuery]);

  const updateOriginPlatformLink = useCallback((sname, stype, linkDetails) => {
    setPlatformLinks((cur) => {
      const curLinks = cur?.[sname + stype];
      if (curLinks) {
        if (!curLinks.find((f) => f.link === linkDetails.link)) {
          return {
            ...cur,
            [sname + stype]: [...curLinks, linkDetails],
          };
        }
        return {
          ...cur,
        };
      }
      return {
        ...cur,
        [sname + stype]: [linkDetails],
      };
    });
  }, []);

  const updateTag = useMutation(({ action, payload }) => NetworkManager.updateContactTags(communityId, contactId, action, payload), {
    onSuccess: (d) => {
      queryClient.refetchQueries(['crm', contactId, 'history'], { exact: false });
      DrawerHandler.updateTagLocally(d.action, d.payload);
    },
  });
  // const updateTag = useOneMutation(updateTagOriginalMutation);

  useLayoutEffect(() => {
    setPlatformLinks({});
  }, [contactId]);

  // const fetchNextPage = useCallback(() => setPage((cur) => cur + 1), []);
  return {
    activities: {
      data: combinedActivitiesPagingData,
      hasNextPage: activitiesQuery.hasNextPage,
      fetchNextPage: activitiesQuery.fetchNextPage,
      isFetching: activitiesQuery.isFetching,
      isError: activitiesQuery.isError,
      isLoading: activitiesQuery.isLoading,
      updateOriginPlatformLink,
      platformLinks,
    },
    updateTag,
  };
}
