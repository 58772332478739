export default function PlaybookIcon({ color }) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2335_198371)">
        <path d="M14.9998 28.3938C13.4591 28.3938 13.4463 27.273 12.1198 26.3173C9.81197 24.6545 6.26554 22.698 3.44126 22.3166C2.93479 22.2605 2.46707 22.0187 2.12834 21.6381C1.78961 21.2574 1.60387 20.7647 1.60697 20.2552V3.66947C1.60716 3.37461 1.67061 3.08323 1.79302 2.81498C1.91543 2.54673 2.09397 2.30786 2.31658 2.1145C2.53919 1.92115 2.80068 1.77779 3.08342 1.69412C3.36616 1.61044 3.66356 1.5884 3.95554 1.62947C8.20883 2.33487 12.0999 4.45407 14.9998 7.64447M14.9998 28.3938V7.64447M14.9998 28.3938C16.5405 28.3938 16.5534 27.273 17.8798 26.3173C20.1877 24.6545 23.7341 22.698 26.5584 22.3166C27.0649 22.2605 27.5326 22.0187 27.8713 21.6381C28.2101 21.2574 28.3958 20.7647 28.3927 20.2552V3.66947C28.3925 3.37461 28.3291 3.08323 28.2066 2.81498C28.0842 2.54673 27.9057 2.30786 27.6831 2.1145C27.4605 1.92115 27.199 1.77779 26.9162 1.69412C26.6335 1.61044 26.3361 1.5884 26.0441 1.62947C21.7908 2.33487 17.8997 4.45407 14.9998 7.64447" stroke={color} strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2335_198371">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}