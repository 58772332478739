/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import * as Yup from 'yup';
import { Button } from 'antd';
import {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import { useFormikContext } from 'formik';
import { motion, AnimatePresence } from 'framer-motion';

import CloseImg from '../../../../Assets/Images/icons/general-x-ui-x-ico-active.svg';
import PlusIcon from '../../../../Assets/Images/icons/general-plus-ui-plus-ico-up.svg';

function Tag({
  variant, label, onClose, onClick,
}) {
  return (
    <motion.div
      className={`keywords-tag ${variant || ''}`}
      tabIndex={variant ? 0 : undefined}
      onKeyDown={(e) => onClick && ['Enter', 'Space'].includes(e.key) && onClick()}
      onClick={onClick || null}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
    >
      {onClose && (
        <div
          className="keywords-tag_close"
          onClick={onClose}
          onKeyDown={(e) => ['Enter', 'Space'].includes(e.key) && onClose()}
          tabIndex={0}
        >
          <img src={CloseImg} width="20" height="20" alt="close" />
        </div>
      )}
      <div className="keywords-tag_label">{label}</div>
    </motion.div>
  );
}

function Editable({
  onSelect, onFocus, onBlur, onHasValue,
}) {
  const [value, setValue] = useState('');
  const keyDownHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onSelect(value);
        setValue('');
      }
    },
    [value, onSelect],
  );
  useEffect(() => {
    onHasValue(value?.length !== 0);
  }, [value]); // eslint-disable-line
  return (
    <input
      className="keywords-editable"
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={keyDownHandler}
    />
  );
}

const fieldsSchema = [
  {
    label: 'Keywords',
    defaultValue: [],
    placeholder: '',
    key: 'keywords',
    Template: ({ field }) => {
      const { setFieldValue } = useFormikContext();
      const [inputHasValue, setInputHasValue] = useState(false);
      const mainBox = useRef();

      const removeWord = useCallback(
        (word) => {
          word
            && setFieldValue('keywords', [
              ...new Set([...field.value.filter((w) => w !== word)]),
            ]);
        },
        [field, setFieldValue],
      );

      const addWord = useCallback(
        (word) => {
          word
            && setFieldValue('keywords', [...new Set([...field.value, word])]);
        },
        [field, setFieldValue],
      );

      const focusEditable = useCallback(() => {
        try {
          document.querySelector('.keywords-editable').focus();
        } catch (e) {} // eslint-disable-line
      }, []);

      const editableOnFocus = useCallback(() => {
        mainBox.current.classList.add('focused');
      }, [mainBox]);

      const editableOnBlur = useCallback(() => {
        mainBox.current.classList.remove('focused');
      }, [mainBox]);

      useEffect(() => {
        if (!field.value) {
          setFieldValue('keywords', [
            'stunning',
            'great stuff',
            'awesome',
            'shiny',
            'great service',
            'super!',
          ]);
        }
      }, []); // eslint-disable-line

      return (
        <div
          className="ant-input keywords"
          ref={mainBox}
          onClick={focusEditable}
        >
          {field?.value?.length === 0 && !inputHasValue && (
            <div className="keywords-empty">
              You can set you brand name, main features or functionalities and
              more...
            </div>
          )}
          <AnimatePresence>
            {field.value
              && field.value.map((tag) => (
                <Tag label={tag} key={tag} onClose={() => removeWord(tag)} />
              ))}
          </AnimatePresence>
          <Editable
            onSelect={addWord}
            onHasValue={setInputHasValue}
            onFocus={editableOnFocus}
            onBlur={editableOnBlur}
          />
          <Button
            className="keywords-add"
            icon={<img src={PlusIcon} width="24" height="24" alt="add" />}
          >
            Add Keyword
          </Button>
        </div>
      );
    },
  },
  {
    label: null,
    defaultValue: '',
    key: 'suggestions',
    Template: () => {
      const { setFieldValue, values } = useFormikContext();
      const def = useMemo(
        () => ['great', 'super', 'love', 'like', 'amazing'],
        [],
      );
      const [currentTags, setTags] = useState(def);

      const clickHandler = useCallback(
        (word) => {
          const current = values?.keywords || [];
          setFieldValue('keywords', [...new Set([...current, word])]);
        },
        [values, setFieldValue],
      );

      const addAll = useCallback(() => {
        const current = values?.keywords || [];
        setFieldValue('keywords', [...new Set([...current, ...def])]);
      }, [values, def, setFieldValue]);

      useEffect(() => {
        if (values.keywords) {
          def.forEach((defKey) => {
            if (!values.keywords.includes(defKey)) {
              setTags((cur) => [...new Set([...cur, defKey])]);
            } else {
              setTags((cur) => [
                ...new Set([...cur.filter((t) => t !== defKey)]),
              ]);
            }
          });
        }
      }, [values.keywords, def]);
      return (
        <div className="suggestions">
          {currentTags.length !== 0 && (
            <>
              <div className="suggestions-header">
                <div className="text">SUGGESTIONS</div>
                <div className="action" tabIndex="0" onClick={addAll}>
                  ADD ALL
                </div>
              </div>
              <div className="suggestions-tags">
                <AnimatePresence>
                  {currentTags.map((d) => (
                    <Tag
                      key={d}
                      variant="outline"
                      label={d}
                      onClick={() => clickHandler(d)}
                    />
                  ))}
                </AnimatePresence>
              </div>
            </>
          )}
        </div>
      );
    },
  },
];

const validationSchema = Yup.object().shape({
  // workspaceName: Yup.string().required('Required'),
});

const workspaceDetailsSchemas = {
  fieldsSchema,
  validationSchema,
};
export default workspaceDetailsSchemas;
