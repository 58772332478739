import { useField, useFormikContext } from 'formik';
import {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import FieldPart from './parts/field';
import OperatorPart from './parts/operator';
import ValuePart from './parts/value';
import { ReactComponent as TrashIcon } from '../../../../Assets/Images/icons/trash.svg';

const getOperatorValue = (field, operator) => {
  if (operator) return operator?.key ?? operator;
  if (field?.operators?.length === 1) return field.operators[0]?.key;
  return operator;
};

export default function FilterLine({
  ind, onFilterRemoved, fields, activeFields, filter,
}) {
  const [{ name }] = useField(`filters[${ind}]`);
  const initiated = useRef(false);
  const { setFieldValue } = useFormikContext();

  const setActiveField = useCallback(
    (fieldKey, option, operator) => {
      const operatorWithDefault = getOperatorValue(option, operator);
      setFieldValue(name, {
        field: fieldKey,
        operator: operatorWithDefault,
        isCustom: option.isCustom ?? false,
        type: option.type,
        fieldObject: option,
      });
    },
    [name, setFieldValue],
  );

  const setActiveOperator = useCallback(
    (operatorKey) => {
      setFieldValue(`${name}.operator`, operatorKey);
    },
    [name, setFieldValue],
  );

  const setCurrentValue = useCallback(
    (value, option) => {
      const selectedOption = option?.key ?? option;
      console.log('etered set current', value, option, selectedOption);
      setFieldValue(`${name}.value`, value);
      setFieldValue(`${name}.optionKey`, selectedOption ?? value);
    },
    [name, setFieldValue],
  );

  const getAvailableFields = useMemo(
    () => Object.values(fields),
    [filter, activeFields, fields],
  );

  useEffect(() => {
    if (!filter.field || !fields?.[filter.field]?.operators || initiated.current) return;
    initiated.current = true;
    const {
      field, operator, type, isCustom, value, optionKey,
    } = filter;
    setActiveField(
      field,
      {
        key: field,
        operators: fields?.[field]?.operators,
        type,
        isCustom,
      },
      operator,
    );
    setCurrentValue(value, optionKey);
  }, [filter, fields, setCurrentValue, setActiveField]);

  return (
    <div key={`filter-${ind}`} className="filter" style={{ display: 'flex' }}>
      <div className="filter-label">{ind === 0 ? 'Where' : 'And'}</div>
      <FieldPart
        name={name}
        value={filter?.field}
        availableFields={getAvailableFields}
        onSelect={setActiveField}
      />
      <OperatorPart
        name={name}
        value={filter?.operator}
        availableOperators={filter?.fieldObject?.operators}
        onSelect={setActiveOperator}
      />

      <ValuePart
        name={name}
        value={{
          selectedField: filter.fieldObject,
          selectedOperator: filter.operator,
          value: filter.optionKey,
        }}
        onSelect={setCurrentValue}
      />
      <div className="filter-trash" onClick={() => onFilterRemoved(ind)}>
        <TrashIcon />
      </div>
      <div />
    </div>
  );
}
