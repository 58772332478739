import { toJS } from 'mobx';
import { useEffect, useState } from 'react';
import Board from '../../Components/Boards/board';
import dalInstance from '../../Data/Dal';

export default function CrmBoardGateway({ dalSelectedSegment, actionsRef }) {
  const [boardId, setBoardId] = useState(undefined);
  useEffect(() => {
    const boardForSegment = toJS(dalInstance.boards).find((b) => b?.segmentId?.toString() === dalSelectedSegment?.id?.toString());
    setBoardId(boardForSegment?.id);
  }, [dalSelectedSegment]);
  if (!dalSelectedSegment?.id) return <></>;
  return (
    <Board boardId={boardId} segmentBoard={dalSelectedSegment.id} onBoardCreated={(id) => setBoardId(id)} onEditModeChange={() => null} actionsNodeRef={actionsRef.current} />
  );
}
