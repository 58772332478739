import {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Input, Select } from 'antd';

import closeIcon from '../../../../../Assets/Images/icons/drawer-close.svg';
import { ReactComponent as SelectDown } from '../../../../../Assets/Images/icons/select-down.svg';
import { ReactComponent as Checkmark } from '../../../../../Assets/Images/icons/general-checkmark-ui-checkmark-active.svg';
import useOutsideClick from '../../../../../Utils/useOutsideClick';

const { Option } = Select;

export default function CustomFieldEditor({
  error, defaultValues, onComplete, onClose,
}) {
  const [values, setValues] = useState({
    label: defaultValues?.label || '',
    field_type: defaultValues?.field_type || null,
  });
  const [internalError, setInternalError] = useState(''); // { label: STRING, err: STRING }
  const inputRef = useRef();
  const containerRef = useRef();
  // useOutsideClick(containerRef, onClose);

  const onKeyInputDown = useCallback((e) => {
    if (e.key === 'Enter') onComplete(values);
  }, [onComplete, values]);
  useEffect(() => {
    if (error && !internalError) {
      setInternalError({ label: values.label, err: error });
      inputRef.current.focus();
    }
  }, [error, internalError, values]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.select(0, 999);
    }
  }, [inputRef]);
  return (
    <motion.div
      initial={{ scale: 0, x: '-78%', y: '-9%' }}
      animate={{ scale: 1, x: '-78%', y: '-9%' }}
      exit={{ scale: 0, x: '-78%', y: '-9%' }}
      className="cfcreator"
      ref={containerRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="inner">
        <AnimatePresence>
          {internalError && internalError?.label === values.label && (
            <motion.div
              className="fieldline-error"
              initial={{ opacity: 0, y: 'calc(50% - 13px)', x: '-140px' }}
              animate={{ opacity: 1, y: 'calc(-100% - 13px)', x: '-140px' }}
              exit={{ opacity: 0 }}
            >
              {internalError.err}
            </motion.div>
          )}
        </AnimatePresence>
        <Input ref={inputRef} onKeyDown={onKeyInputDown} placeholder="Custom field name" value={values.label} onChange={(e) => setValues((cur) => ({ ...cur, label: e.target.value }))} type="text" />
        <Select
          onClick={(e) => e.stopPropagation()}
          dropdownStyle={{ minWidth: '150px' }}
          suffixIcon={<SelectDown />}
          allowClear={false}
          value={values.field_type}
          onChange={(v) => setValues((cur) => ({ ...cur, field_type: v }))}
        >
          <Option className="csv-option" onClick={(e) => e.stopPropagation()} value="string">Text</Option>
          <Option className="csv-option" onClick={(e) => e.stopPropagation()} value="int">Number</Option>
          <Option className="csv-option" onClick={(e) => e.stopPropagation()} value="date">Date</Option>
          <Option className="csv-option" onClick={(e) => e.stopPropagation()} value="bool">True / False</Option>
        </Select>
        <Button className="complete-btn" onClick={() => onComplete(values)}>
          <Checkmark />
        </Button>
        <Button onClick={onClose} className="close-btn">
          <img style={{ cursor: 'pointer' }} src={closeIcon} width="24" height="24" alt="close" />
        </Button>
      </div>
    </motion.div>
  );
}
