export const operatorsNames = {
  is: 'Is',
  contains: 'Contains',
  lt: 'Less than',
  lte: 'Less than or equals',
  gt: 'Greater than',
  gte: 'Greater than or equals',
  in: 'Is in',
  within: 'Within',
};

export const operators = {
  string: ['is', 'contains'],
  exact_string: ['is'],
  date: ['is', 'lt', 'gt', 'lte', 'gte', 'within'],
  int: ['is', 'lt', 'gt', 'lte', 'gte'],
  'string[]': ['in'],
};
