import SegmentCommonTemplate from './common/segment';

const segmentAccountAddTrigger = {
  id: 2, // IDS ARE EQUIVALENT TO SQL
  entity: 'account',
  action: 'add',
  relation: 'segment',
  acceptedMetadata: ['segment_id'],
  validationSchema: SegmentCommonTemplate.validationSchema,
  templates: {
    Display: ({ isExample, metadata }) => (
      <span className="autotext-inner">
        When a new account is added to
        {' '}
        <SegmentCommonTemplate.Display isExample={isExample} metadata={metadata} />
      </span>
    ),
    Edit: ({ metadata }) => (
      <span className="autotext-inner trigger">
        <span className="highlight">When</span>
        {' '}
        a new account is added to
        {' '}
        <SegmentCommonTemplate.Edit metadata={metadata} />
      </span>
    ),
  },
};

export default segmentAccountAddTrigger;
