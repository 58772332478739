import { observer } from 'mobx-react';
import salesforceIcon from '../../../../../Assets/Images/icons/repo-icon-salesforce.svg';

const salesforceAccountCreateInteractionReactor = {
  id: 2,
  service_name: 'salesforce',
  service_type: 'account',
  action: 'create_interaction',
  acceptedMetadata: [],
  validationSchema: null,
  templates: {
    ShortDisplay: observer(() => (
      <span>
        create an
        {' '}
        <span className="highlight">
          Interaction
        </span>
        {' '}
        on
        {' '}
        <span className="highlight flex-align platform">
          <img src={salesforceIcon} width="16" height="16" alt="salesforce" />
          Salesforce
        </span>
      </span>
    )),
    Edit: observer(() => (
      <span>
        <span className="highlight then">Then</span>
        {' '}
        create an
        {' '}
        <span className="highlight">
          Interaction
        </span>
        {' '}
        on
        {' '}
        <span className="highlight flex-align platform">
          <img src={salesforceIcon} width="16" height="16" alt="salesforce" />
          Salesforce
        </span>
      </span>
    )),
  },
};

export default salesforceAccountCreateInteractionReactor;
