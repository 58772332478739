import { motion } from 'framer-motion';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../Assets/Images/icons/back.svg';

import PlaybooksHeader from '../common/header';
import usePlaybooksQueries from '../hooks/usePlaybooksQueries';

export default function PlaybooksAllHeader() {
  const history = useHistory();
  const { playbooks } = usePlaybooksQueries();
  return (
    <PlaybooksHeader>
      <motion.div onClick={() => history.goBack()} className="playbooks-header_back" layoutId="playbooks-header_icon">
        <BackIcon />
      </motion.div>
      <motion.div className="playbooks-header_text" layout="position" layoutId="playbooks-header_text">
        <div>
          {playbooks.data ? playbooks.data.length : 'All'}
          {' '}
          playbooks
        </div>
      </motion.div>
      <motion.div className="playbooks-header_action" layout="position" layoutId="playbooks-header_action">
        <Button className="lnc-blue-btn" onClick={() => history.push('/dashboard/playbooks/new')}>New Playbook</Button>
      </motion.div>
    </PlaybooksHeader>
  );
}
