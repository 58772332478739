/* eslint-disable no-mixed-operators */
// import useDictionaryQueries from '../../../../Utils/community/dictionaries/useDictionaryQueries';
import { useMemo } from 'react';
import _merge from 'lodash/merge';
import moment from 'moment';
import { useFormikContext } from 'formik';
import WidgetWrapper from '../wrapper';
import widgetSettings from '../_settings';
import widgetSkeletons from './_skeletons';
import { ReactComponent as Icon } from '../../../../../Assets/Images/icons/dictionaries/bytime.svg';
import { platformColorsByBg } from '../../../../../Data/colors';

export default function WidgetTime({ query }) {
  const { values: { metadata } } = useFormikContext();
  const color = useMemo(() => metadata?.color ? platformColorsByBg[metadata.color].bgColor : '#076cbe', [metadata]);
  const seriesAndCategories = useMemo(() => {
    if (query.isLoading) return widgetSkeletons.time;
    const data = query?.data?.time || {};
    const polyfill = Array(12).fill(null).reduce((acc, __, ind) => {
      acc[moment().subtract(11, 'months').add(ind, 'months').format('MMM YY')] = 0;
      return acc;
    }, {});
    const populated = Object.keys(polyfill).reduce((acc, cur) => {
      acc[cur] = data?.[cur] || 0;
      return acc;
    }, {});
    return {
      series: [{
        name: 'Interactions count',
        data: Object.values(populated),
        color,
      }],
      categories: Object.keys(populated),
    };
  }, [color, query.data, query.isLoading]);
  const settings = useMemo(() => (_merge({
    chart: {
      type: 'column',
      width: null,
      height: 250,
      // margin: 0,
    },
    plotOptions: {
      series: {
        color,
      },
    },
    xAxis: {
      categories: seriesAndCategories.categories,
    },
    series: seriesAndCategories.series,
  }, widgetSettings({ isPercentage: false, tooltipDisabled: false }))), [color, seriesAndCategories]);
  return (
    <WidgetWrapper
      widgetData={settings}
      className="widget-bytime"
      title={(
        <>
          <Icon />
          {' '}
          Matches by time
        </>
      )}
      isEmpty={seriesAndCategories.series[0].data.length === 0}
    >
      {/* none */}
    </WidgetWrapper>
  );
}
