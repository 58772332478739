import { Button } from 'antd';
import { motion } from 'framer-motion';
import { _quickActionsCategoryColors as catColors } from './_data';

export default function ExampleBox({ item, onChoose }) {
  return (
    <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} exit={{ scale: 0 }} layoutId={`example-item-${item.id}`} className="item" key={item.id}>
      <div className="item-tags">
        {item.tags.map((tag) => <div key={tag} style={{ backgroundColor: catColors[tag].bg, color: catColors[tag].text }}>{tag}</div>)}
      </div>
      <div className="item-content">
        <div>{item.title}</div>
        <div>{item.desc}</div>
      </div>
      <div className="item-actions">
        <Button className="lnc-lightblue-btn" onClick={() => onChoose(item)}>Add</Button>
      </div>
    </motion.div>
  );
}
