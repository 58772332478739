import {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import * as XLSX from 'xlsx';

import Heading from '../../common/heading';
import PhaseOneUpload from './phases/1_upload';

import { ReactComponent as CSVUploadIcon } from '../../../../Assets/Images/icons/csv-icon-upload.svg';
import PhaseTwoLoad from './phases/2_loading';
import StepWrapper from '../../common/stepWrapper';

const FILE_LIMIT_MB = 30;

function LoadStep({ globalContext, updateContext }) {
  const [phase, setPhase] = useState(0); // 0 - upload, 1 - parsing, 2 - done, 3 - error
  const [errorMsg, setErrorMsg] = useState('');
  const className = useMemo(() => {
    const classes = ['step-upload', 'step-component'];
    if (phase === 2) classes.push('success');
    if (phase === 3) classes.push('error');
    return classes.join(' ');
  }, [phase]);

  const titlesAndDesc = useMemo(() => {
    const def = {
      0: {
        title: 'Upload CSV file',
        desc: '',
      },
      1: {
        title: 'Upload CSV file',
        desc: 'Parsing your file',
      },
      2: {
        title: 'Success!',
        desc: 'You are good to go!',
      },
      3: {
        title: 'Please validate your CSV',
        desc: 'Your file seems to be empty or incompatible',
      },
    };
    return def[phase];
  }, [phase]);

  const onDrop = useCallback((files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      /* Parse data */
      try {
        const ab = e.target.result;
        const wb = XLSX.read(ab, { type: 'array', raw: true });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        /* Update state */
        updateContext({ csvData: data.slice(0, 15) });
        setPhase(1);
      } catch (er) {
        setPhase(3);
      }
    };
    if (file.size / 1024 / 1024 > FILE_LIMIT_MB) {
      setPhase(3);
      setErrorMsg('File is too large (30MB max)');
    } else {
      reader.readAsArrayBuffer(file);
    }
    updateContext({ file });
  }, [updateContext]);

  useEffect(() => {
    updateContext({ file: null, csvData: [] }); // On re-mount, remove file
  }, [updateContext]);

  useEffect(() => {
    const hasError = typeof globalContext.isFetchingNeededData === 'string' && globalContext.isFetchingNeededData;
    if (globalContext.filePostError) {
      setPhase(3);
    }
    if (phase === 1 && globalContext.isFetchingNeededData !== true && !globalContext.filePostError) {
      if (!hasError) {
        setPhase(2);
      } else {
        setPhase(3);
        setErrorMsg(hasError);
      }
    }
  }, [globalContext, phase]);

  useEffect(() => { // eslint-disable-line
    return () => {
      updateContext({ filePostError: false });
    };
  }, [updateContext]);

  return (
    <StepWrapper step="load" className={className}>
      <Heading icon={<CSVUploadIcon />} title={titlesAndDesc.title} desc={titlesAndDesc.desc} />
      <motion.div layout style={{ marginBottom: '200px' }}>
        <AnimatePresence>
          {phase === 0 && (
            <PhaseOneUpload onDrop={onDrop} />
          )}
          {phase !== 0 && (
            <PhaseTwoLoad
              result={phase === 1 ? 'loading' : (phase === 2 ? 'success' : 'fail')} // eslint-disable-line
              errorText={errorMsg}
              fileName={globalContext.file?.name || globalContext.filePostError || ''}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </StepWrapper>
  );
}

export default observer(LoadStep);
