import _ from 'lodash';
import {
  useCallback, useState, createContext, useEffect,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';

export const FoldersContext = createContext({});

export default function useFolders(communityId, apiName) {
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [hiddenFolders, setHiddenFolders] = useState([]);
  const [entitiesByFolder, setEntitiesByFolder] = useState({});
  const [folders, setFolders] = useState([]);
  const foldersQuery = useQuery([communityId, 'folders', apiName], () => NetworkManager.getFolders(communityId, apiName), {
    staleTime: Infinity,
    retry: 2,
  });

  const updateEntity = useMutation((d) => NetworkManager.updateEntity(communityId, apiName, d), {
    onSuccess: () => {
      foldersQuery.refetch();
    },
  });

  const updateFolder = useMutation((d) => NetworkManager.updateFolder(communityId, apiName, d.id, d), {
    onSuccess: () => foldersQuery.refetch(),
  });

  const deleteFolder = useMutation((d) => NetworkManager.deleteFolder(communityId, apiName, d.id), {
    onSuccess: () => foldersQuery.refetch(),
  });

  const createFolder = useMutation((d) => NetworkManager.createFolder(communityId, apiName, d), {
    onSuccess: () => foldersQuery.refetch(),
  });

  const toggleFolderExpansion = useCallback((key) => {
    if (key.includes('folder')) {
      setExpandedFolders((cur) => {
        if (cur.includes(key)) {
          return cur.filter((f) => f !== key);
        }
        return [...cur, key];
      });
    }
  }, []);

  const toggleFolderHide = useCallback((key) => {
    if (key.includes('folder')) {
      setHiddenFolders((cur) => {
        if (cur.includes(key)) {
          return cur.filter((f) => f !== key);
        }
        return [...cur, key];
      });
    }
  }, []);

  const onFoldersChangeHook = useCallback((oldFolders, newFolders) => {
    // Nothing atm. Preparation for A/C
  }, []);
  const onEntitiesChangeHook = useCallback((folderId, oldEntities, newEntities) => {
    if (oldEntities.length !== newEntities.length) { // folder has changed
      const curDiff = _.difference(newEntities, oldEntities);
      if (newEntities.length > oldEntities.length) {
        // Entity dropped into this folder ID
        const newItem = curDiff[0];
        updateEntity.mutate({ entity_id: newItem.id, folder_id: folderId.toString() !== '-1' ? folderId : null });
      }
    }
  }, []); // eslint-disable-line

  const updateLocallyEntitiesInFolder = useCallback((folderId, entities) => {
    const final = {
      ...entitiesByFolder,
      [folderId]: entities,
    };
    setEntitiesByFolder(final);
    onEntitiesChangeHook(folderId, entitiesByFolder[folderId], final[folderId]);
  }, [entitiesByFolder, onEntitiesChangeHook]);
  const updateLocallyFolders = useCallback((newFolders) => {
    setFolders(folders);
    onFoldersChangeHook(folders, newFolders);
  }, [onFoldersChangeHook, folders]);

  useEffect(() => {
    // on load, take expanded folders keys from localstorage
    try {
      const expanded = window.localStorage.getItem(`__expanded_folders_${apiName}`);
      const hidden = window.localStorage.getItem(`__hidden_folders_${apiName}`);
      if (expanded) setExpandedFolders(JSON.parse(expanded));
      else setExpandedFolders(['folder--1']);
      if (hidden) setHiddenFolders(JSON.parse(hidden));
    } catch (e) {
      // do nothing
    }
  }, []); // eslint-disable-line 

  useEffect(() => {
    try {
      window.localStorage.setItem(`__expanded_folders_${apiName}`, JSON.stringify(expandedFolders));
    } catch (e) {
      // do nothing
    }
  }, [expandedFolders]); // eslint-disable-line 

  useEffect(() => {
    try {
      window.localStorage.setItem(`__hidden_folders_${apiName}`, JSON.stringify(hiddenFolders));
    } catch (e) {
      // do nothing
    }
  }, [hiddenFolders]); // eslint-disable-line 
  return {
    foldersQuery,
    updateHandler: (d) => updateEntity.mutate(d),
    toggleFolderHide,
    hiddenFolders,
    expandedFolders,
    createFolder,
    toggleFolderExpansion,
    deleteFolder,
    updateFolder,
    folders,
    setFolders,
    entitiesByFolder,
    updateLocallyEntitiesInFolder,
    updateLocallyFolders,
    setEntitiesByFolder,
  };
}
