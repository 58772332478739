import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import CRUDModal from '../crudModal';

export default function FinalizePart() {
  const { values } = useFormikContext();
  const [modal, setModal] = useState(false);
  const isDisabled = useMemo(() => {
    if (!values.trigger || !values.action) return true;
    return false;
  }, [values]);
  return (
    <div className="flow-part finalize">
      <CRUDModal isShown={modal} onClose={() => setModal(false)} borderedIcon />
      <div className="fbox">
        <Button className="lnc-blue-btn" disabled={isDisabled} onClick={() => setModal(true)}>
          {values.metadata.state === 'UNFINISHED' ? 'Save' : 'Update'}
          {' '}
          playbook
        </Button>
      </div>
    </div>
  );
}
