import { useQuery } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';
import { useCommunityContext } from '../../../Utils/contexts/community';

export default function useSalesforceObjects() {
  const { communityId } = useCommunityContext();
  const objects = useQuery(['workflows', 'utils', 'salesforce', 'objects'], () => NetworkManager.getWorkflowsSalesforceDetails(communityId), {
    staleTime: 1000 * 60,
    retry: 1,
  });
  return {
    objects,
  };
}

export function useSalesforceFields(object) {
  const { communityId } = useCommunityContext();
  const fields = useQuery(['workflows', 'utils', 'salesforce', 'objects', object], () => NetworkManager.getWorkflowsSalesforceDetails(communityId, object), {
    enabled: !!object,
    staleTime: 1000 * 60,
    retry: 1,
  });
  return {
    fields,
  };
}
