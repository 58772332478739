import useTopicsQueries from '../../../Utils/community/topics/useTopicsQueries';
import TopicsLinesHeader from './header';
import TopicLine from './item';

export default function TopicsList() {
  const { topics } = useTopicsQueries();
  return (
    <div className="topics-list">
      <div className="topics-list_meta">
        {topics?.data?.length}
        {' '}
        topics
      </div>
      <TopicsLinesHeader />
      {topics.data && topics.data.map((p) => (
        <TopicLine
          key={p.id}
          topicId={p.id}
          name={p.topic}
          createdDate={p.created_time}
          status={p.is_active === 1 ? 'active' : 'inactive'}
        />
      ))}
    </div>
  );
}
