import { observer } from 'mobx-react';
import { useMemo } from 'react';

import handler from '../state/superFilter.class';

import { ReactComponent as PropertyIcon } from '../../../Assets/Images/icons/superfilter-property.svg';
import { ReactComponent as AggregationIcon } from '../../../Assets/Images/icons/superfilter-aggregation.svg';
import { ReactComponent as ContentIcon } from '../../../Assets/Images/icons/superfilter-content.svg';
import { ReactComponent as CogIcon } from '../../../Assets/Images/icons/cat-ico-cog-fill.svg';
import { useCallback } from 'react';

function AdderButtons() {
  const text = useCallback((len) => {
    if (len === 0) return 'What\'s on your mind?';
    if (len === 1) return 'Interesting choice! add more filters, discover new things';
    return 'Find more stuff';
  }, []); // eslint-disable-line
  return (
    <div className="adders">
      <div className="adders-line">
        {text(handler.lines.length)}
      </div>
      <div className="adders-bottom">
        Create elaborate queries to get accurate, contextual data from your user-base. <a>Show me how</a>
      </div>
      <div className="adders-buttons">
        <div className="adders-buttons_btn" onClick={() => handler.addNewLine('aggregation', true)}>
          <AggregationIcon />
          <span>User activity increased in...</span>
        </div>
        <div className="adders-buttons_btn" onClick={() => handler.addNewLine('content', true)}>
          <ContentIcon />
          <span>User talked about...</span>
        </div>
        <div className="adders-buttons_btn" onClick={() => handler.addNewLine('property', true)}>
          <PropertyIcon />
          <span>User has a property...</span>
        </div>
        <div className="adders-buttons_btn custom">
          <CogIcon />
          <span>Custom</span>
          <div className="custom-dropdown">
            <div className="custom-dropdown_item" onClick={() => handler.addNewLine('aggregation')}>
              <AggregationIcon />
              <span>Product activity</span>
            </div>
            <div className="custom-dropdown_item" onClick={() => handler.addNewLine('content')}>
              <ContentIcon />
              <span>Contextual data</span>
            </div>
            <div className="custom-dropdown_item" onClick={() => handler.addNewLine('property')}>
              <PropertyIcon />
              <span>User property</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(AdderButtons);
