import { AnimatePresence, motion } from 'framer-motion';
import Modal from './modal';
import './styles.scss';

export default function LNCModal({
  isShown, onClose, className, children,
}) {
  return (
    <AnimatePresence>
      {isShown && (
        <motion.div
          initial={{ opacity: 0, pointerEvents: 'none' }}
          animate={{ opacity: 1, pointerEvents: 'auto' }}
          exit={{ opacity: 0, pointerEvents: 'none' }}
          className="lnc-modal_overlay"
          key="modal_overlay"
          onClick={onClose}
        />
      )}
      {isShown && (
        <Modal className={className} onClose={onClose} key="lnc-modal">
          {children}
        </Modal>
      )}
    </AnimatePresence>
  );
}
