import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import SearchBox from '../../../../Search';

const Actions = ({ onSearch }) => {
  const history = useHistory();
  return (
    <div className="choose-queries-actions">
      <Button className="lnc-blue-btn" onClick={() => history.push('/dashboard/queries')}>Create new query</Button>
      <SearchBox name="queries" onSearch={onSearch} />
    </div>
  );
};

export default Actions;
