import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo } from 'react';
import Field from './field';

import edit from '../../../Assets/Images/icons/superfilter-edit.svg';
import trash from '../../../Assets/Images/icons/superfilter-trash.svg';
import apply from '../../../Assets/Images/icons/superfilter-apply.svg';

import { ReactComponent as PropertyIcon } from '../../../Assets/Images/icons/superfilter-property.svg';
import { ReactComponent as AggregationIcon } from '../../../Assets/Images/icons/superfilter-aggregation.svg';
import { ReactComponent as ContentIcon } from '../../../Assets/Images/icons/superfilter-content.svg';

const FilterLine = observer(({ filter, index }) => {
  const Icon = useMemo(() => {
    switch (filter.type) {
      case 'property':
        return PropertyIcon;
      case 'aggregation':
        return AggregationIcon;
      case 'content':
        return ContentIcon;
      default:
        return ContentIcon;
    }
  }, [filter.type]);
  const hasError = (fieldKey) => {
    if (filter.status[0] !== null && filter.status[0] === fieldKey) return filter.status[1];
    return false;
  };

  const getValueByKey = (key) => filter.fields[key];
  const setValueByKey = (key, value) => {
    filter.updateValueByKey(key, value);
  };
  return (
    <motion.div
      className={`filterline ${filter.editMode ? 'edit' : ''} ${filter.autoFilled ? 'filterline-autofilled' : ''}`}
      layout
      initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
      animate={{ height: index === 0 ? '120px' : '110px', opacity: 1, overflow: 'visible' }}
      exit={{ height: 0, opacity: 0, overflow: 'hidden' }}
    >
      <div className="filterline-field filterline-icon">
        <label />
        <Icon />
      </div>
      {filter.schema.fields.map((f) => {
        if (f.showWhen) {
          if (f.showWhen(filter.fields) === false) return null;
        }
        return (
          <Field
            key={`${f.key}-${filter.id}`}
            error={hasError(f.key)}
            editMode={filter.editMode}
            fieldKey={f.key}
            value={filter.fields[f.key]}
            setValueByKey={setValueByKey}
            onChange={(v) => filter.updateValueByKey(f.key, v)}
            getValueByKey={getValueByKey}
            schema={f}
          />
        );
      })}
      <div className="filterline-field filterline-buttons">
        <div className="filterline-buttons_trash" onClick={filter.delete}>
          <img src={trash} width="24" height="24" alt="Delete" />
        </div>
        {!filter.editMode && (
          <div className="filterline-buttons_edit" onClick={filter.enableEditMode}>
            <img src={edit} width="24" height="24" alt="Edit" />
          </div>
        )}
        {filter.editMode && (
          <div className="filterline-buttons_apply" onClick={filter.save}>
            <img src={apply} width="34" height="34" alt="Apply" />
          </div>
        )}
      </div>
    </motion.div>
  );
});

export default FilterLine;
