import { AnimatePresence, motion, animate } from 'framer-motion';
import {
  useCallback, useEffect, useRef,
} from 'react';
import { PopupButton } from 'react-calendly';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import usePersistentDisclosure from '../../Utils/usePersistentDisclosure';
import MeetingButton from './comps/meeting';
import QuickActions from './comps/quickActions';
import nlogo from '../../Assets/Images/icons/nlogo2.png';
import SideView from './sideView';
import Indicator from './sideView/indicator';
import WidgetsWrapper from './comps/widgetsWrapper';
import './styles/index.scss';
import dalInstance from '../../Data/Dal';
import useDisclosure from '../../Utils/useDisclosure';
import MFAModal from '../../Components/MFA/modal';
import ErrorBoundary from '../../Components/ErrorBoundary/errorBoundary';

function HomeScreen() {
  const ref = useRef();
  const { isOpen, onToggle } = usePersistentDisclosure('sideview', true);
  const { isOpen: mfaOpen, onToggle: onMfaToggle } = useDisclosure();

  const handleMeetingClick = useCallback(() => {
    const cal = document.querySelector('.calendly-button');
    cal.click();
  }, []);

  useEffect(() => {
    const con = animate(1, [1, 1.3, 1], {
      duration: 3,
      repeat: Infinity,
      onUpdate: (v) => {
        try {
          ref.current.style.filter = `brightness(${v})`;
        } catch (e) {
          // do nothing
        }
      },
    });
    return con.stop;
  }, [ref]);
  return (
    <div
      className="homescreen"
    >
      <ErrorBoundary componentName="HomeScreen">
        <Indicator isShown={!isOpen} toggleSideView={onToggle} />
        <div className="homescreen-main">
          <div className="inner">
            <div className="homescreen-main_banner">
              <div className="banner-inner">
                <motion.div
                  ref={ref}
                  className="banner_bg"
                >
                  <img src={nlogo} width="600" height="698" alt="lnc" />
                </motion.div>
                <div className="homescreen-main_banner_title">Hi there!</div>
                <div className="homescreen-main_banner_desc">
                  <div className="paragraph">
                    If you are seeing this message,
                    it probably means you care a lot about your audience. just to let you know, our experts are here to get you jump started.
                  </div>
                  <MeetingButton onClick={handleMeetingClick} />
                </div>
              </div>
            </div>
            <QuickActions />
            <WidgetsWrapper />
            <div style={{ position: 'absolute', visibility: 'hidden' }}>
              <PopupButton
                className="calendly-button"
                iframeTitle="Calendly Scheduling Page"
                pageSettings={{
                  backgroundColor: 'ffffff',
                  hideEventTypeDetails: false,
                  hideGdprBanner: true,
                  hideLandingPageDetails: false,
                  primaryColor: '3b82f4',
                  textColor: '4d5055',
                }}
                prefill={{
                  date: new Date(),
                  email: toJS(dalInstance.user).email,
                  firstName: toJS(dalInstance.user).first_name,
                  lastName: toJS(dalInstance.user).family_name,
                  name: toJS(dalInstance.user).first_name + ' ' + toJS(dalInstance.user).family_name, // eslint-disable-line
                }}
                rootElement={document.querySelector('#root')}
                styles={{}}
                text="Click here to schedule!"
                url="https://calendly.com/avihuye/let-s-talk-1"
              />
            </div>
          </div>
        </div>
        <AnimatePresence>
          {mfaOpen && (
            <MFAModal email={toJS(dalInstance.user)?.email} onClose={onMfaToggle} />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {isOpen && (
            <SideView toggleSideView={onToggle} mfaOpen={mfaOpen} onMfaToggle={onMfaToggle} />
          )}
        </AnimatePresence>
      </ErrorBoundary>
    </div>
  );
}

export default observer(HomeScreen);
