import { Button } from 'antd';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import LNCModal from '..';
import { ReactComponent as AlertIcon } from '../../../Assets/Images/icons/dashboards/alert.svg';
import './styles.scss';

export default function DeleteModal({
  isShown, onClose, title, desc, deleteMutation, onDelete, isLoading, entityId,
}) {
  const handleDeleteClick = useCallback(() => {
    if (onDelete) onDelete();
    else {
      deleteMutation.mutate({ id: entityId }, {
        onSettled: () => {
          onClose();
        },
      });
    }
  }, [deleteMutation, entityId, onDelete, onClose]);
  return createPortal(
    (
      <LNCModal className="delete-modal" isShown={isShown} onClose={onClose}>
        <div className="delete-modal_content">
          <AlertIcon />
          <div className="delete-modal_content_title">
            {title}
          </div>
          <div className="delete-modal_content_desc">
            {desc}
          </div>
        </div>
        <div className="delete-modal_footer">
          <Button className="btn_back" onClick={onClose}>Cancel</Button>
          <Button className="btn_apply" onClick={handleDeleteClick} loading={deleteMutation?.isLoading || isLoading}>Confirm</Button>
        </div>
      </LNCModal>
    ), document.querySelector('body'),
  );
}
