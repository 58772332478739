import * as Yup from 'yup';

const phoneReg = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const dateReg = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
const boolValues = ['yes', 'no', '0', '1', 'true', 'false'];
// names are in congestion to backend
export default {
  email: Yup.string().email('Invalid email format').typeError('Invalid email format'),
  origin_id: Yup.string('ID must be a string'),
  name: Yup.string('Invalid name format'),
  first_name: Yup.string('Invalid name format'),
  last_name: Yup.string('Invalid name format'),
  phone: Yup.string().matches(phoneReg, 'Phone number is not valid'),
  home_phone: Yup.string().matches(phoneReg, 'Phone number is not valid'),
  created_date: Yup.string().matches(dateReg, 'Invalid date format, use YYYY-MM-DD').typeError('Invalid date format, use YYYY-MM-DD'),
  birthdate: Yup.string().matches(dateReg, 'Invalid date format, use YYYY-MM-DD').typeError('Invalid date format, use YYYY-MM-DD'),
  type: Yup.string(),
  company: Yup.string(),
  account_id: Yup.string(),
  department: Yup.string(),
  country: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  street: Yup.string(),
  address: Yup.string(),
  title: Yup.string(),
  _string: Yup.string(),
  _date: Yup.string().matches(dateReg, 'Invalid date format, use YYYY-MM-DD'),
  _int: Yup.number().typeError('Must be a number'),
  _bool: Yup.string().test('is boolean', 'Invalid boolean format', (value) => boolValues.includes(value.toString().toLowerCase())).typeError('Must be true or false'),
};
