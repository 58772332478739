import { useMemo } from 'react';
import ErrorText from '../../../../../../Components/ErrorBoundary/errorText';
import dalInstance from '../../../../../../Data/Dal';
import useHistory from '../../../hooks/useHistory';
import Activity from '../activity';
import ActivitiesLoader from '../loader';

export default function HistoryView({ contactId, isFullViewForced }) {
  const { history } = useHistory(contactId);
  const finalActivites = useMemo(() => {
    if (history?.data) {
      return history.data.map((x) => {
        if (x.instance_type === 'segment_history') {
          return {
            ...x,
            segment: {
              ...x.segment,
              textColor: dalInstance.getSetColorByBgColor(x.segment.bgColor).textColor,
            },
          };
        }
        return x;
      });
    }
    return [];
  }, [history]);
  return (
    <div className="activities">
      <div className="inner-view-tab">
        {history.isLoading ? (
          <ActivitiesLoader />
        ) : (
          <>
            {history.isError ? (
              <ErrorText style={{ width: '100%', backgroundColor: 'transparent', height: '100%' }} />
            ) : (
              !history.isLoading && history.data && (
              <>
                {history.data?.length === 0 ? <div style={{ textAlign: 'center ', paddingTop: '10px' }}>No activities could be found</div> : (
                  finalActivites.map((act, ind) => (
                    <Activity contactId={contactId} act={act} ind={ind} isFullViewForced={isFullViewForced} key={act._id} />
                  ))
                )}

              </>
              )
            )}

          </>
        )}
      </div>
    </div>
  );
}
