import { Select } from 'antd';
import { useMemo } from 'react';
import { ReactComponent as SelectDown } from '../../../../Assets/Images/icons/select-down.svg';

import useTagsQuery from '../../../../Utils/community/tags/useTagsQuery';

const { Option } = Select;

export const TagsDisplayValue = ({ value }) => {
  const { tags: { data: tagsData } } = useTagsQuery();
  const tag = useMemo(() => tagsData.find((t) => t.id === value), [tagsData, value]);
  return (
    <div className="value_tags">
      <div className="ant-select-selection-item">
        <span className="ant-select-selection-item-content">{tag?.label}</span>
      </div>
    </div>
  );
};

export const TagsEditMode = ({ value, onChange }) => {
  const { tags: { data: tagsData } } = useTagsQuery();
  return (
    <Select
      placeholder="Select a tag"
      suffixIcon={<SelectDown />}
      value={value}
      onChange={onChange}
    >
      {tagsData.map((tag) => (
        <Option className="superfilter-option" key={tag.id} value={tag.id}>{tag.label}</Option>
      ))}
    </Select>
  );
};
