/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { runInAction } from 'mobx';

import emptyStateIcon from '../../../Assets/Images/redesign/no-date-match-ico.svg';

import './styles.scss';
import Dal from '../../../Data/Dal';

import BottomBoxLoader from '../../../Components/Loaders/bottomBoxLoader';

class CrmProcessingStateView extends Component {
  importClicked() {
    runInAction(() => {
      Dal.selectedMenuIndex = '3';
    });
  }

  render() {
    return (
      <div className="dashboard-loading-state-content">
        <BottomBoxLoader isShown notify position="absolute" cssOverrides={{ top: '75px', bottom: 'auto' }} />
        <Col style={{ marginTop: '80px' }}>
          <Row>
            <img
              src={emptyStateIcon}
              alt="empty"
              style={{ position: 'relative', display: 'block', margin: '0 auto' }}
            />
          </Row>
          <Row
            className="dashboard-empty-state-title"
            style={{ marginTop: '30px' }}
          >
            Here you will be able to find all your contacts,
            <br />
            wherever they are.
          </Row>
          <Row style={{ marginTop: '30px' }} className="dashboard-empty-state-body">
            <span>You can always </span>
            <Link
              to="/dashboard/integrations"
              onClick={() => this.importClicked()}
            >
              <a
                className="dashboard-empty-state-body"
                style={{ color: '#0084ff', cursor: 'pointer', margin: '0 6px' }}
              >
                {' '}
                connect
                {' '}
              </a>
            </Link>
            {' '}
            more data sources in the meantime.
          </Row>
        </Col>
      </div>
    );
  }
}

export default CrmProcessingStateView;
