import totangoIcon from '../../../../Assets/Images/icons/totango-icon.png';
import churnzeroIcon from '../../../../Assets/Images/icons/repo-icon-crunzero.png';
import assignIcon from '../../../../Assets/Images/icons/automation/assign.svg';
import tagIcon from '../../../../Assets/Images/icons/automation/tag.svg';

import marketoIcon from '../../../../Assets/Images/icons/repo-icon-marketo.svg';
import mailchimpIcon from '../../../../Assets/Images/icons/repo-icon-mailchimp.svg';
import kustomerIcon from '../../../../Assets/Images/icons/kustomer-icon.svg';
import salesforceIcon from '../../../../Assets/Images/icons/repo-icon-salesforce.svg';
import slackWorkspaceNotifyReactor from './reactors/slack.workspace.notify';
import salesforceAccountCreateInteractionReactor from './reactors/salesforce.account.create_interaction';
import salesforceAccountEditContactSentimentReactor from './reactors/salesforce.account.edit_contact_sentiments';
import salesforceAccountCreateOpportunityReactor from './reactors/salesforce.account.create_opportunity';

const reactors = {
  'slack.workspace.notify': slackWorkspaceNotifyReactor,
  'salesforce.account.create_interaction': salesforceAccountCreateInteractionReactor,
  'salesforce.account.edit_contact_sentiments': salesforceAccountEditContactSentimentReactor,
  'salesforce.account.create_opportunity': salesforceAccountCreateOpportunityReactor,
  'kustomer.workspace.tag_field': {
    id: 2002,
    service_name: 'kustomer',
    service_type: 'workspace',
    action: 'tag_field',
    acceptedMetadata: [],
    templates: {
      Display: ({ isExample, metadata }) => (
        <span>
          add a
          {' '}
          <span className="highlight">
            Tag
          </span>
          {' '}
          to
          {' '}
          <span className="highlight">
            Field
          </span>
          {' '}
          on
          {' '}
          <span className="highlight flex-align platform">
            <img src={kustomerIcon} width="16" height="16" alt="slack" />
            Kustomer
          </span>
        </span>
      ),
    },
  },
  'totango.task.create': {
    id: 1002,
    service_name: 'email',
    service_type: 'message',
    action: 'notify',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span className="display">
          <img src={totangoIcon} width="30" height="30" alt="totango" />
          Create a task on Totango
        </span>
      ),
    },
  },
  'churnzero.task.create': {
    id: 10021,
    service_name: 'email',
    service_type: 'message',
    action: 'notify',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span className="display">
          <img src={churnzeroIcon} width="30" height="30" alt="churnzero" />
          Create a task on Churnzero
        </span>
      ),
    },
  },
  'loudnclear.workspace.assign': {
    id: 1003,
    service_name: 'loudnclear',
    service_type: 'workspace',
    action: 'assign',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span>
          assign a
          {' '}
          <span className="highlight flex-align platform">
            <img src={assignIcon} width="24" height="24" alt="slack" />
            team member
          </span>
        </span>
      ),
    },
  },
  'loudnclear.workspace.addtag': {
    id: 1004,
    service_name: 'loudnclear',
    service_type: 'workspace',
    action: 'addtag',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span>
          add a
          {' '}
          <span className="highlight flex-align platform">
            <img src={tagIcon} width="24" height="24" alt="slack" />
            TAG
          </span>
        </span>
      ),
    },
  },
  'marketo.campaign.trigger': {
    id: 1005,
    service_name: 'marketo',
    service_type: 'campaign',
    action: 'trigger',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span className="display">
          <img src={marketoIcon} width="30" height="30" alt="Marketo" />
          Trigger a Marketo campaign
        </span>
      ),
    },
  },
  'mailchimp.campaign.trigger': {
    id: 1006,
    service_name: 'mailchimp',
    service_type: 'campaign',
    action: 'trigger',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span className="display">
          <img src={mailchimpIcon} width="16" height="16" alt="slack" />
          Trigger a Mailchimp campaign
        </span>
      ),
    },
  },
  'salesforce.account.update_cfield': {
    id: 1007,
    service_name: 'salesforce',
    service_type: 'account',
    action: 'update_cfield',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span className="display">
          <img src={salesforceIcon} width="30" height="30" alt="salesforce" />
          Update contact field in Salesfroce
        </span>
      ),
    },
  },
  'salesforce.account.update_afield': {
    id: 1008,
    service_name: 'salesforce',
    service_type: 'account',
    action: 'update_afield',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span className="display">
          <img src={salesforceIcon} width="30" height="30" alt="salesforce" />
          Update account field in Salesfroce
        </span>
      ),
    },
  },
  'salesforce.account.assign_task': {
    id: 1009,
    service_name: 'salesforce',
    service_type: 'account',
    action: 'update_afield',
    acceptedMetadata: [],
    templates: {
      Display: () => (
        <span className="display">
          <img src={salesforceIcon} width="30" height="30" alt="salesforce" />
          Assign a task in Salesfroce
        </span>
      ),
    },
  },
};

export default reactors;
