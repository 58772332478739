import { makeAutoObservable, runInAction, toJS } from 'mobx';
import dalInstance from '../../../Data/Dal';
import FilterLine from './filterLine.class';
import systemFields from '../../../Data/fields/_systemFieldsSchema';
import { COMBINED_FIELDS } from '../_config/PropertyFilter';
import { OPERATOR_QUANT_NUMBER_OPTIONS } from '../_config/aggregationFilters/_config';

const defaultValues = {
  property: {
    operator: 'exact match',
  },
  content: {
    has: 'interactions_content',
  },
  aggregation: {
    has: 'actions_count',
    operator: 'increased',
  },
};

class SuperFilter {
  constructor() {
    this.type = 'user';
    this.operator = 'and';
    this.lines = [];
    this.finalData = null;
    makeAutoObservable(this);
  }

  updateOperator = (operator) => {
    this.operator = operator;
  };

  updateType = (type) => {
    this.type = type;
  };

  reset = () => {
    this.lines = [];
    this.finalData = null;
  };

  getDefaults = (type) => {
    const defaultsDraft = { ...defaultValues[type] };
    const hasIntegration = dalInstance.integrations.find((int) => int.connected);
    if (typeof defaultsDraft?.platform !== 'undefined' && hasIntegration) {
      defaultsDraft.platform = dalInstance.integrations.find((int) => int.connected).assetTag;
    }
    return defaultsDraft;
  };

  populateByBackendData = (data) => {
    this.reset();
    this.finalData = data;
    this.operator = data.operator;
    const transformers = {
      platform: ({ value }) => {
        if (['csv', 's3'].includes(value.toLowerCase())) return 'csv';
        return value;
      },
      field: ({ value }) => {
        if (typeof value === 'object') return value?.label || value;
        return value;
      },
      quantification: ({ fields, value: val }) => { // receives value in proper case
        if (fields?.operator) {
          if (fields.operator === 'increased') return parseFloat(val - 1).toFixed(2) * 100;
          if (fields.operator === 'decreased') return parseFloat(1 - val).toFixed(2) * 100;
        }
        if (OPERATOR_QUANT_NUMBER_OPTIONS.includes(fields.operator)) {
          // If there's a threshold field, set it to be quantification.
          // It will be later changed  to "threshold" on submit
          if (fields.threshold) return fields.threshold;
          return val;
        }
        return val;
      },
    };
    const transformType = (type) => type === 'content_aggregation' ? 'content' : type; // eslint-disable-line
    const handleLine = (line) => Object.keys(line).reduce((acc, cur) => ({
      ...acc,
      [cur]: transformers[cur] ? transformers[cur]({ value: line[cur], fields: line }) : line[cur],
    }), {});
    data.filters.forEach((line) => {
      const ln = new FilterLine(transformType(line.type), this, handleLine(line));
      ln.save();
      this.lines.push(ln);
    });
  };

  addNewLine = async (type, defaults) => {
    // if defaults is true, than auto-generate defaults
    // if defaults is object, then the data is coming from backend (i.e. clicking on segment)
    const saveStatus = await this.saveAll();
    const defaultsParam = (() => {
      if (typeof defaults === 'object') return defaults;
      if (typeof defaults === 'boolean' && defaults === true) return this.getDefaults(type);
      return {};
    })();
    if (saveStatus) {
      runInAction(() => {
        this.lines.push(new FilterLine(type, this, defaultsParam));
      });
    }
  };

  removeLine = (lineId) => {
    this.lines = this.lines.filter((line) => line.id !== lineId);
  };

  saveAll = async () => {
    let allSaved = true;
    for (let line of this.lines) { // eslint-disable-line
      const saved = await line.save(); // eslint-disable-line
      if (!saved) {
        allSaved = false;
        break;
      }
    }
    return allSaved;
  };

  apply = () => {
    const DONT_SEND_FIELDS = ['pre_of_none', 'pre_of_percentage', 'pre_of_number'];
    const transformers = {
      has: ({ value }) => value,
      quantification: ({ fields, value: val }) => { // receives value in proper case
        if (fields?.operator) {
          // Percentage handling
          if (fields.operator === 'increased') return val / 100 + 1;
          if (fields.operator === 'decreased') return 1 - val / 100;

          // Number (/threshold) handling
          if (OPERATOR_QUANT_NUMBER_OPTIONS.includes(fields.operator)) {
            // If its a regular number, no need for transform
            if (!Number.isNaN(Number(val))) {
              return val;
            }
            // In case of threshold, set qunatiifcation to 1
            // THe threshold field is already handled through the qunatificationTemplate
            return '1';
          }
        }
        return val;
      },
      field: ({ value }) => {
        // Check if it's a custom field
        if (COMBINED_FIELDS.find((fi) => fi.key === value)) {
          return value.toLowerCase();
        }
        return dalInstance.customFields.find((c) => c.label === value);
      },

      /* platform: ({ value: val }) => {
        const found = dalInstance.integrations.find((int) => int.assetTag === val);
        if (found) return found.targetTag;
        return val;
      }, */
      value: ({ value: val, fields, type }) => {
        if (type === 'property' && fields.field === 'platform' && val.toLowerCase() === 'csv') return 's3';
        return val;
      },
    };
    const transformFieldValue = (fieldKey, value) => typeof value === 'string' ? value.toLowerCase() : value; // eslint-disable-line
    const transformType = (type, fields) => {
      if (type === 'content' && fields?.has === 'interactions_count') return 'content_aggregation';
      return type;
    };
    const generateFilterSchema = (type, fields) => ({
      type: transformType(type, fields),
      ...Object.keys(fields).reduce((acc, field) => {
        if (!DONT_SEND_FIELDS.includes(field)) {
          if (fields[field] || (Array.isArray(fields[field]) && fields[field].length !== 0)) {
            return {
              ...acc,
              [field]: transformers[field] ? transformers[field]({ fields, value: fields[field], type }) : transformFieldValue(field, fields[field]),
            };
          }
        }
        return acc;
      }, {}),
    });
    const backendData = {
      type: 'contact',
      operator: this.operator.toLowerCase(),
      filters: this.lines.map((line) => generateFilterSchema(line.type, line.fields)),
    };
    this.finalData = backendData.filters.length !== 0 ? backendData : null;
    return this.finalData;
  };
}

const superFilter = new SuperFilter();
export default superFilter;
