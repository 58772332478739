/* eslint-disable arrow-body-style */
/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react';
import Thread from './thread';
import ThreadAI from './ai';

const loadingThread = {
  id: '1',
  origin_time: '2023-04-04 04:46:10',
  origin_id: '1643112703416758274',
  user_name: <div className="isSkeleton">from</div>,
  asset_id: 592,
  link: '',
  text: '',
  likes: 0,
  sentiment: 'NEGATIVE',
  isOrigin: true,
  children: [],
};

export default function ThreadsList({
  isLoading, originalInteraction, markedBy, list = [],
}) {
  const parsedList = useMemo(() => {
    // const mapper = (t) => {
    //   const thisDict = dictionaries[dictId];
    //   const colors = platformColorsByBg[thisDict.color];
    //   const slices = keywordsSlicer({
    //     fullText: t.text,
    //     markedByIds: { [dictId]: markedBy },
    //     dictionaryKeywords: { [dictId]: thisDict.keywords.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}) },
    //     shouldCut: false,
    //   });
    //   const highlightedText = slices.map((slice, ind) => {
    //     if (typeof slice === 'string') return <span key={`${slice}-${ind}`}>{slice}</span>;
    //     return <span key={`${slice.keyword}-${ind}`} className="keyword-highlight" style={{ color: colors.textColor, backgroundColor: colors.bgColor }}>{slice.keyword}</span>;
    //   });

    //   return {
    //     ...t,
    //     highlightedText,
    //     subthreads: t.subthreads.map(mapper),
    //   };
    // };
    return list;
  }, [list]); // [dictId, dictionaries, markedBy, list]);
  return (
    <div className="inner">
      {originalInteraction && !isLoading && <ThreadAI interaction={originalInteraction} />}
      {isLoading ? (
        <Thread {...loadingThread} isLoader />
      ) : (
        parsedList.map((t) => (
          <Thread key={t.id} {...t} isFirst />
        ))
      )}
    </div>
  );
}
