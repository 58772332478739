import { AnimatePresence, motion } from 'framer-motion';
import AutomationModal from './modal';
import './styles.scss';

export default function AutomationsCenter({ isShown, onClose }) {
  return (
    <div className="automation">
      <AnimatePresence>
        {isShown && (
          <motion.div
            key="automation-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="automation-overlay"
            onClick={onClose}
          />
        )}
        {isShown && (
          <AutomationModal />
        )}
      </AnimatePresence>
    </div>
  );
}
