import { ReactComponent as UserIcon } from '../../../Assets/Images/icons/cat-ico-user.svg';
import { ReactComponent as CaseIcon, ReactComponent as TitleIcon } from '../../../Assets/Images/icons/cat-ico-case.svg';
import { ReactComponent as TalkIcon } from '../../../Assets/Images/icons/cat-ico-sets.svg';
import { ReactComponent as ActionIcon } from '../../../Assets/Images/icons/dashboards/action-icon.svg';
import { ReactComponent as SourcesIcon } from '../../../Assets/Images/icons/cat-ico_source.svg';
import { ReactComponent as SourcesTimeIcon } from '../../../Assets/Images/icons/source_time.svg';
import { ReactComponent as SegmentIcon } from '../../../Assets/Images/icons/revamp/cat-ico-segment.svg';
import { ReactComponent as SegmentTimeIcon } from '../../../Assets/Images/icons/segment_time.svg';

import { ReactComponent as SentimentTimeIcon } from '../../../Assets/Images/icons/sentiment_time.svg';
import { ReactComponent as SentimentIcon } from '../../../Assets/Images/icons/revamp/cat-ico-sentiment (1).svg';
import { ReactComponent as TagIcon } from '../../../Assets/Images/icons/cat-ico-tag.svg';
import { ReactComponent as CogICon } from '../../../Assets/Images/icons/cat-ico-cog.svg';
import { ReactComponent as EmployeeIcon } from '../../../Assets/Images/icons/revamp/cat-ico-assigned-employee.svg';
import { ReactComponent as TimeIcon } from '../../../Assets/Images/icons/revamp/cat-ico-updated.svg';
import { ReactComponent as CountIcon } from '../../../Assets/Images/icons/dashboards/count.svg';

export default {
  measureY: {
    contacts: UserIcon,
    accounts: CaseIcon,
    interactions: TalkIcon,
    actions: ActionIcon,
    sources: SourcesIcon,
  },
  dimension: {
    segment: SegmentIcon,
    segment_time: SegmentTimeIcon,
    sentiment: SentimentIcon,
    sentiment_time: SentimentTimeIcon,
    platform: ActionIcon,
    source: SourcesIcon,
    source_time: SourcesTimeIcon,
    tag: TagIcon,
    title: TitleIcon,
    dictionary: TalkIcon,
    custom_field: CogICon,
    assigned_employee: EmployeeIcon,
    time: TimeIcon,
    action_name: ActionIcon,
  },
  measureFunc: {
    count: CountIcon,
    word_count: CountIcon,
  },
};
