/* eslint-disable react/destructuring-assignment */
import { motion } from 'framer-motion';

export default function StepWrapper(props) {
  return (
    <motion.div
      key={props.step}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4, type: 'tween', ease: 'easeOut' }}
      {...props}
    />
  );
}
