import { useCallback, useContext } from 'react';
import { ReactComponent as CalendarIcon } from '../../../../Assets/Images/icons/dashboards/icon-calendar.svg';
import DropdownMenu from '../../../DropdownMenu';
import GhostButton from '../../../Buttons/ghostButton';
import { WidgetContext } from '../../hooks/useWidget';
import { BoardContext } from '../../hooks/useBoard';

const TimeIntervalMenu = ({ widgetId }) => {
  const { updateWidgetMutation } = useContext(BoardContext);
  const { widgetQuery: { data } } = useContext(WidgetContext);
  const mutate = useCallback((time) => {
    updateWidgetMutation.mutate({ id: widgetId, timespan: time });
  }, [updateWidgetMutation, widgetId]);
  const menu = [
    {
      key: 'daily',
      label: 'Daily',
      isSelected: data.config.timespan === 'daily',
      onClick: () => mutate('daily'),
    },
    {
      key: 'weekly',
      label: 'Weekly',
      isSelected: data.config.timespan === 'weekly',
      onClick: () => mutate('weekly'),
    },
    {
      key: 'monthly',
      label: 'Monthly',
      isSelected: data.config.timespan === 'monthly',
      onClick: () => mutate('monthly'),
    },
    {
      key: 'quarterly',
      label: 'Quarterly',
      isSelected: data.config.timespan === 'quarterly',
      onClick: () => mutate('quarterly'),
    },
    {
      key: 'yearly',
      label: 'Yearly',
      isSelected: data.config.timespan === 'yearly',
      onClick: () => mutate('yearly'),
    },
  ];
  return (
    <DropdownMenu
      button={<GhostButton styles={{ padding: '0 2px', marginRight: '5px', height: '30px' }} icon={<CalendarIcon />} />}
      items={menu}
    />
  );
};

export default TimeIntervalMenu;
