export default function QueryIcon({ color }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4587 6.66659H3.95866C3.70866 6.66659 3.54199 6.49992 3.54199 6.24992V3.74992C3.54199 3.49992 3.70866 3.33325 3.95866 3.33325H11.4587C11.7087 3.33325 11.8753 3.49992 11.8753 3.74992V6.24992C11.8753 6.41659 11.7087 6.66659 11.4587 6.66659Z" stroke={color} className="strokehere" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.4587 14.5833H3.95866C3.70866 14.5833 3.54199 14.4167 3.54199 14.1667V11.6667C3.54199 11.4167 3.70866 11.25 3.95866 11.25H11.4587C11.7087 11.25 11.8753 11.4167 11.8753 11.6667V14.1667C11.8753 14.3333 11.7087 14.5833 11.4587 14.5833Z" stroke={color} className="strokehere" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.5417 15.1249L14.625 13.2083C14.4583 13.0416 14.4583 12.7916 14.625 12.6249L16.5417 10.7083C16.7083 10.5416 16.9583 10.5416 17.125 10.7083L19.0417 12.6249C19.2083 12.7916 19.2083 13.0416 19.0417 13.2083L17.125 15.1249C17.0417 15.2916 16.7083 15.2916 16.5417 15.1249Z" stroke={color} className="strokehere" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.625 1.25V12.5C0.625 12.75 0.791667 12.9167 1.04167 12.9167H3.125" stroke={color} className="strokehere" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.625 5H3.54167" stroke={color} className="strokehere" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.875 5H16.4583C16.7083 5 16.875 5.16667 16.875 5.41667V10.56" stroke={color} className="strokehere" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.875 12.9167H14.5" stroke={color} className="strokehere" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.875 15.2217V18.3333C16.875 18.5833 17.0417 18.75 17.2917 18.75H19.375" stroke={color} className="strokehere" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}