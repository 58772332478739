const widgetSettings = ({ isPercentage, tooltipDisabled }) => ({
  colors: ['#26a0fc', '#00c784', '#f7b32b', '#ff7793', '#6d848e', '#5a84c8', '#a79d83', '#907ab2', '#6ca87f', '#c25382', '#68b7d4', '#afafaf', '#ff4d4d'],
  title: {
    text: undefined,
  },
  labels: {
    style: {
      fontFamily: 'Poppins',
    },
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      colorByPoint: false,
      borderRadius: 4,
    },
    bar: {
      stacking: 'normal',
      colorByPoint: true,
      borderRadius: 4,
    },
    pie: {
      dataLabels: {
        enabled: false,
      },
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
    style: {
      fontFamily: 'Poppins',
    },
  },
  tooltip: {
    borderWidth: 1,
    borderColor: '#dadada',
    borderRadius: 8,
    backgroundColor: 'white',
    useHTML: true,
    style: {
      fontFamily: 'Poppins',
    },
    shadow: {
      color: '#000',
      opacity: 0.05,
    },
    enabled: !tooltipDisabled,
    ...({
      formatter() {
        return `<div class="widget-tooltip" style="z-index: 5">
          <div class="widget-tooltip_title">${this.x || this.point.name}</div>
          <div class="widget-tooltip_line">
            <div class="widget-tooltip_line_circle" style="background-color: ${this.point.color}"></div>
            <div class="widget-tooltip_line_label">${this.series.name}</div>
            <div class="widget-tooltip_line_info">${this.y}${isPercentage ? '%' : ''}</div>
          </div>
        </div>`;
      },
    }),
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '10px',
        color: '#3a434a',
        fontWeight: '500',
        fontFamily: 'Poppins',
      },
    },
  },
  yAxis: {
    gridLineColor: '#f0f0f0',
    title: {
      text: undefined,
    },
    labels: {
      style: {
        color: '#a3a3a3',
        fontSize: '10px',
        fontFamily: 'Poppins',
      },
    },
  },
});

export default widgetSettings;
