import { ReactComponent as Churn } from '../../../../Assets/Images/icons/enrich-churn-risk.svg';
import { ReactComponent as Upsell } from '../../../../Assets/Images/icons/enrich-upsell.svg';

const enriches = [
  {
    key: 'churn',
    title: 'Churn Risk',
    desc: 'Identify users who will likely decrease their activity',
    icon: <Churn />,
    subs: [
      { key: 'severe_frustration_with_the_product', label: 'Severe Frustration with the Product' },
      { key: 'poor_customer_service', label: 'Poor Customer Service' },
      { key: 'competitive_threats', label: 'Competitive Threats' },
      { key: 'statements_of_moving_away_from_the_product', label: 'Statements of moving away from the product' },
      { key: 'rage', label: 'Rage' },
    ],
  },
  {
    key: 'upsell',
    title: 'Upsell Opportunity',
    desc: 'Identify users with good odds to make a purchase',
    icon: <Upsell />,
    subs: [
      { key: 'requests_for_a_trial_or_free_version', label: 'Requests for a trial or free version' },
      { key: 'statements_of_willingness_to_buy', label: 'Statements of willingness to buy' },
      { key: 'inquiries_about_the_pricing', label: 'Inquiries about the pricing' },
      { key: 'requests_to_increase_the_number_of_seats_or_licenses_in_the_product', label: 'Requests to increase the number of seats or licenses in the product' },
      { key: 'willingness_to_upgrade_or_expand_the_existing_plan', label: 'Willingness to Upgrade or Expand the Existing Plan' },
    ],
  },
];

export const enrichesByCom = {
  4887: [
    enriches[0],
    {
      key: 'upsell',
      title: 'Topics',
      desc: 'Identify users that discussed certain topics',
      icon: <Upsell />,
      subs: [
        { key: 'hosting', label: 'hosting' },
        { key: 'migration', label: 'migration' },
        { key: 'downtime', label: 'downtime' },
        { key: 'outage', label: 'outage' },
        { key: 'onboarding', label: 'onboarding' },
        { key: 'licensing', label: 'licensing' },
        { key: 'activation', label: 'activation' },
        { key: 'fatal errors', label: 'fatal errors' },
      ],
    },
  ],
  default: enriches,
};

export default enriches;
