import React from 'react';
import { observer } from 'mobx-react';
import {
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  Modal, Space, Button, Divider,
} from 'antd';
import Dal from '../../Data/Dal';
import IntercomConnectImage from '../../Assets/Images/redesign/ico-integrate-with-intercom.svg';

import '../Facebook/Pages/styles.scss';
import './styles.scss';

class IntercomModal extends React.Component {
  intercomConnected = false;

  constructor() {
    super();

    makeObservable(this, {
      intercomConnected: observable,
    });
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const intercom = params.get('intercom');
    if (intercom) {
      runInAction(() => { this.intercomConnected = true; });
    }
  }

  render() {
    return (
      <Modal
        visible={this.props.isVisible}
        closable
        onCancel={this.props.closeModalWithoutFetching}
        className="integrationModal"
        wrapClassName="integrationModalWrapper"
        footer={null}
      >
        <>
          <img
            src={IntercomConnectImage}
            alt=""
            width={window.screen.width < 1500 ? 154 : 271}
          />
          <p className="pagesHeader">
            {!this.intercomConnected
              ? 'Intercom integration'
              : 'Workspace connected'}
          </p>
          <p className="pagesSubtitle">
            {!this.intercomConnected
              ? 'Ways to use LoudnClear & Slack'
              : 'LoudnClear is enabled to your Intercom workspace.'}
          </p>
          {!this.intercomConnected ? (
            <>
              <Divider />
              <ul className="textList">
                <li>Gain insights related to Intercom conversation content.</li>
                <li id="secParagraph">
                  View Intercom interactions on your LoudnClear unified members
                  list.
                  {' '}
                </li>
              </ul>
              <p className="Simpoco-automaticall">
                No worries, LoudnClear will automatically fetch all the
                historical Intercom information as well!
              </p>
              <Divider />
              <a
                href={`https://app.intercom.com/oauth?client_id=${
                  process.env.REACT_APP_INTERCOM_API_KEY
                }&state=${Dal.getCurrentCommunityId()}&redirect_uri=${
                  process.env.REACT_APP_INTERCOM_REDIRECT_URI
                }`}
              >
                <img
                  src="https://static.intercomassets.com/assets/oauth/primary-7edb2ebce84c088063f4b86049747c3a.png"
                  srcSet="https://static.intercomassets.com/assets/oauth/primary-7edb2ebce84c088063f4b86049747c3a.png 1x, https://static.intercomassets.com/assets/oauth/primary@2x-0d69ca2141dfdfa0535634610be80994.png 2x, https://static.intercomassets.com/assets/oauth/primary@3x-788ed3c44d63a6aec3927285e920f542.png 3x"
                  alt=""
                />
              </a>
            </>
          ) : (
            <Space direction="vertical" style={{ marginTop: 40 }}>
              <Button
                className="getInsigntsBtn twitter"
                onClick={this.props.closeModalWithoutFetching}
              >
                Get insights
              </Button>
            </Space>
          )}
        </>
      </Modal>
    );
  }
}

export default observer(IntercomModal);
