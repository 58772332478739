export default function VideoModal({ video, setClosed }) {
  return (
    <>
      <div
        className={`video_modal ${video ? 'active' : ''}`}
      >
        <div className="inner">
          <div className="video_placeholder">
            {video && (
              <iframe
                src={`${video}?autoplay=1&loop=1`}
                title="video"
                width="800"
                height="448"
                autoPlay
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
          <div className="close" role="button" tabIndex="0" onClick={setClosed}>
            Close
          </div>
        </div>
      </div>
      <div className={`video_modal_overlay ${video ? 'active' : ''}`} onClick={setClosed} />
    </>
  );
}
