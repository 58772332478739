// Defines the timerange capable charts

const timeranges = {
  interactions: {
    count: {
      sentiment: true,
      dictionary: true,
      segment: true,
      source: true,
    },
    word_count: {
      source: true,
      sentiment: true,
    },
    post_count: {
      sentiment: true,
    },
    comment_count: {
      sentiment: true,
    },
  },
  actions: {
    count: {
      action_name: true,
      segment: true,
    },
  },
};

export default timeranges;
