import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { observer } from 'mobx-react';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { toJS } from 'mobx';
import dalInstance from '../../../../../Data/Dal';
import { useSettingsContext } from '../../../../../Utils/community/useSettings';
import useUsageChart from '../../hooks/useUsageChart';
import DrawerHandler from '../../state/handler';
import chartSettings from './_settings';
import { ReactComponent as BarIcon } from '../../../../../Assets/Images/icons/dashboards/column-ico.svg';
import MiniSpinner from '../common/miniSpinner';
import { ReactComponent as ActionIcon } from '../../../../../Assets/Images/icons/custom-action.svg';
import useOneMutation from '../../../../../Utils/useOneMutation';
import UsageSelect from './usageSelect';
import IntervalMenu from './intervalMenu';
import Switch from '../../../../../Components/Switch';

const Message = ({ text }) => (
  <div className="usage-text">
    {text}
  </div>
);

// const loadingCat = Array(12).fill(null).map((d, ind) => moment().subtract(12, 'month').add(ind, 'month').format('YYYY-MM'));
function UsageChart() {
  const { data, updateMutation } = useSettingsContext();
  const { mutation: usageMutation } = useOneMutation(updateMutation);
  const { mutation: threshMutation } = useOneMutation(updateMutation);
  const { mutation: intervalMutation } = useOneMutation(updateMutation);
  const isLoadingDrawer = DrawerHandler.isLoading.userDetails;
  const usageField = useMemo(() => data['drawer.usage_field'], [data]);
  const usageFieldId = useMemo(() => toJS(dalInstance.customFields).find((f) => f.label === usageField)?.id, [usageField]);
  const thresholdField = useMemo(() => data['drawer.usage_field_threshold'], [data]);
  const intervalField = useMemo(() => data['drawer.usage_field_interval'], [data]);
  const usageOptions = useMemo(() => toJS(dalInstance.customFields).filter((c) => c.data_type === 'actions' && c.field_type === 'int'), []);
  const thresholdOptions = useMemo(() => toJS(dalInstance.customFields).filter((c) => c.data_type === 'contacts' && c.field_type === 'int'), []);
  const [shouldSeeThresh, setSeeThresh] = useState(!!thresholdField);
  const { resultsQuery } = useUsageChart(dalInstance.communities[0].id, DrawerHandler.id, usageFieldId, thresholdField, intervalField);
  const [options, setOptions] = useState(chartSettings);
  const isUpdating = useMemo(() => (
    threshMutation.isLoading
    || usageMutation.isLoading
    || intervalMutation.isLoading
    || resultsQuery.isFetching
  ), [intervalMutation, usageMutation, threshMutation, resultsQuery]);
  const handleThresholdTick = useCallback((v) => {
    if (!v && !!thresholdField) {
      threshMutation.mutate({ 'drawer.usage_field_threshold': null });
    }
    setSeeThresh(v);
  }, [thresholdField]); // eslint-disable-line
  useEffect(() => {
    if (resultsQuery.data) {
      setOptions(_.merge({
        chart: {
          width: 500,
        },
        xAxis: {
          categories: resultsQuery.data.xAxis, // Object.values(resultsQuery.data.time).map((x) => x.format),
        },
        series: [
          {
            name: usageField,
            data: resultsQuery.data.yAxis, // Object.values(resultsQuery.data.time).map((x) => Number(parseFloat(x.count).toFixed(2))),
            color: '#00C784',
          },
          ...(thresholdField ? [{
            name: thresholdField,
            data: Array(12).fill(resultsQuery.data.threshold),
            color: '#EF5350',
            marker: {
              enabled: false,
            },
            type: 'spline',
          }] : []),
        ],
      }, chartSettings));
    }
  }, [resultsQuery.data, thresholdField, usageField]);
  return (
    <>
      {!usageField ? (
        <div className="empty-usage">
          <BarIcon />
          <Message text={(
            <>
              Visualize actions performed by this contact,
              by selecting a custom field
              {' '}
            </>
          )}
          />
          <UsageSelect placeholder="Select a field" values={usageOptions} usageMutation={usageMutation} onChange={(v) => usageMutation.mutate({ 'drawer.usage_field': v })} />
        </div>
      ) : (
        <div className={`theUsage ${isUpdating || isLoadingDrawer ? 'is-updating' : ''}`}>
          {isUpdating && <div className="theUsage-loader"><MiniSpinner /></div>}
          <div className="theUsage-config">
            <div className="theUsage-config_left">
              <UsageSelect
                value={usageField}
                name="Usage field"
                placeholder="Usage"
                usageMutation={usageMutation}
                values={usageOptions}
                icon={<ActionIcon />}
                onChange={(v) => usageMutation.mutate({ 'drawer.usage_field': v })}
              />
              {shouldSeeThresh && (
                <UsageSelect
                  value={thresholdField}
                  name="Threshold field"
                  placeholder="Threshold"
                  usageMutation={usageMutation}
                  values={thresholdOptions}
                  icon={<ActionIcon />}
                  onChange={(v) => usageMutation.mutate({ 'drawer.usage_field_threshold': v })}
                />
              )}
            </div>
            <div className="theUsage-config_right">
              <Switch isChecked={shouldSeeThresh} onChange={(v) => handleThresholdTick(v)} label="Threshold" />
              <IntervalMenu onChange={(v) => usageMutation.mutate({ 'drawer.usage_field_interval': v })} value={intervalField} />
            </div>
            {/* <Checkbox checked={!!shouldSeeThresh} onChange={handleThresholdTick}>Add a threshold line</Checkbox>
            {shouldSeeThresh && (
              <ThresholdSelect
                placeholder={thresholdField ? undefined : 'Select...'}
                value={thresholdField ?? undefined}
                threshMutation={threshMutation}
                onChange={(v) => threshMutation.mutate({ 'drawer.usage_field_threshold': v })}
              />
            )} */}
          </div>
          <div className="theUsage-chart">
            {resultsQuery?.data?.status === 'NOT_APPLICABLE' ? (
              <Message text="The specified action wasn't found for this contact" />
            ) : (
              <>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
              </>
            )}
          </div>

        </div>
      )}

    </>
  );
}

export default observer(UsageChart);
