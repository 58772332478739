import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { AnimatePresence } from 'framer-motion';
import useSalesforceUtils from '../../../hooks/useSalesforceUtils';
import salesforceIcon from '../../../../../Assets/Images/icons/repo-icon-salesforce.svg';
import Dal from '../../../../../Data/Dal';
import AdditionalPortal from '../../../common/additionalPortal';
import Select from '../../../common/select';
import MiniSpinner from '../../../../../Screens/Crm/Drawer/comps/common/miniSpinner';
import SalesforceWorkflowMapping from './additional/salesforceMapping';
import AdditionalMetadataWrapper from './additional/_additionalWrapper';

const { Option } = Select;

const salesforceAccountCreateOpportunityReactor = {
  id: 4,
  service_name: 'salesforce',
  service_type: 'account',
  action: 'create_opportunity',
  validationSchema: Yup.object({
    object: Yup.string().required('Please select'),
    mapping: Yup.object({
      contact: Yup.mixed().test('is-valid', 'Contact relation is required', (v) => !!v),
      account: Yup.mixed().test('is-valid', 'Account relation is required', (v) => !!v),
      assigned_employee: Yup.mixed().test('is-valid', 'Employee relation is required', (v) => !!v),
      segment_url: Yup.mixed().test('is-valid', 'Segment URL field is required', (v) => !!v),
      drawer_url: Yup.mixed().test('is-valid', 'Contact URL field is required', (v) => !!v),
    }).required(),
  }),
  templates: {
    ShortDisplay: observer(() => (
      <span className="display">
        <img src={salesforceIcon} width="30" height="30" alt="salesforce" />
        Create a Salesforce record
      </span>
    )),
    Edit: observer(() => {
      const { objects } = useSalesforceUtils();
      const { values, setFieldValue, touched } = useFormikContext();
      const { reactor: { metadata }, id } = values;
      const handleChange = useCallback((object) => {
        // const tar = targets.find((t) => t.id === targetId);
        setFieldValue('reactor.metadata', {
          object,
        });
      }, [setFieldValue]);
      return (
        <>
          <span>
            <span className="highlight then">Then</span>
            {' '}
            Create an instance of
            {' '}
            <Select
              field="reactor.metadata.object"
              isTouched={touched?.reactor?.metadata?.object}
              value={metadata?.object || null}
              disabled={objects.isLoading}
              placeholder={(
                objects.isLoading ? (
                  <>
                    Loading
                    {' '}
                    <MiniSpinner />
                  </>
                ) : ('Select')
              )}
              onChange={handleChange}
            >
              {objects.data && objects.data.map((tar) => (
                <Option key={tar} value={tar}>
                  {tar}
                </Option>
              ))}
            </Select>
            {' '}
            on
            {' '}
            <span className="highlight flex-align platform">
              <img src={salesforceIcon} width="16" height="16" alt="slack" />
              Salesforce
            </span>
          </span>

          <AnimatePresence>
            {metadata?.object && (
              <AdditionalPortal id={id || 'edit'}>
                <AdditionalMetadataWrapper Component={<SalesforceWorkflowMapping />} />
              </AdditionalPortal>
            )}
          </AnimatePresence>

        </>
      );
    }),
  },
};

export default salesforceAccountCreateOpportunityReactor;
