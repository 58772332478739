import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { LayoutGroup, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import QueryIcon from '../../../../../Assets/Images/icons/query-icon';
import { ReactComponent as JumpTo } from '../../../../../Assets/Images/icons/jumpto-ico.svg';
import BlueModal from '../../../../Modal/blue';
import useQueriesQueries from '../../../../Queries/hooks/useQueriesQueries';
import { platformColorsByBg } from '../../../../../Data/colors';
import Actions from './topActions';

const skeletons = Array(8).fill(null).map((x, ind) => ({ id: ind, skeleton: true }));

export default function ChooseQueryModal({ isShown, onClose, onChoose }) {
  const { queries } = useQueriesQueries();
  const [search, setSearch] = useState('');
  const list = useMemo(() => {
    if (queries.isLoading) return skeletons;
    if (queries.data) {
      if (search) {
        return queries.data.filter((f) => f.name.toLowerCase().includes(search.toLowerCase()));
      }
      return queries.data;
    }
    return [];
  }, [queries, search]);
  return (
    <BlueModal
      className="choose-queries"
      isShown={isShown}
      onClose={onClose}
      title="Choose query"
      actions={<Actions onSearch={setSearch} />}
    >
      <LayoutGroup>
        {list.map((q) => (
          <motion.div layout="position" key={q.id} layoutId={`query-choose-${q.id}`} onClick={() => onChoose(q.id)} className={`query ${q.skeleton ? 'skeleton' : ''}`}>
            {!q.skeleton && (
              <>
                <div className="query-icon" style={{ background: q.color }}>
                  <QueryIcon color={platformColorsByBg[q.color].textColor} />
                </div>
                <div className="query-name">{q.name}</div>
                <Link className="query-jump" to={`/dashboard/queries/${q.id}`} target="_blank"><JumpTo /></Link>
                <div className="query-action">
                  <Button className="lnc-lightblue-btn">Select query</Button>
                </div>
              </>
            )}
          </motion.div>
        ))}
      </LayoutGroup>
    </BlueModal>
  );
}
