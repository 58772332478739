import { ReactComponent as BoardIcon } from '../../../Assets/Images/icons/dashboards/board-icon.svg';
import DictIcon from '../../../Assets/Images/icons/new-dict-icon';
import QueryIcon from '../../../Assets/Images/icons/query-icon';

const entitiesIcons = {
  dashboard: <BoardIcon stroke="#000" fill="#000" />,
  dictionary: <DictIcon color="#000" />,
  query: <QueryIcon color="#000" />,
};

export default entitiesIcons;
