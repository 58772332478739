import { Input } from 'antd';
import { useEffect, useMemo } from 'react';
import Select from '../../Select';
import filterOptions, { filterOptionsByKey } from './_options';
import trash from '../../../Assets/Images/icons/superfilter-trash.svg';

export default function FilterLine({
  update, property, condition, value, lineKey, error, remove,
}) {
  const schema = useMemo(() => filterOptionsByKey[property], [property]);
  useEffect(() => {
    if (!schema.conditions.includes('contains') && condition === 'contains') {
      update('condition', 'exact match');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, condition]);
  useEffect(() => {
    update('value', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);
  return (
    <div className="dict-filter_line">
      <div className="dict-filter_line_picker">
        <Select value={property} onChange={(v) => update('property', v)}>
          {filterOptions.map((f) => (
            <Select.Option key={f.key} value={f.key}>{f.label}</Select.Option>
          ))}
        </Select>
      </div>
      <div className="dict-filter_line_condition">
        <Select value={condition} onChange={(v) => update('condition', v)}>
          <Select.Option value="exact match">Exact Match</Select.Option>
          {schema.conditions.includes('contains') && <Select.Option value="contains">Contains</Select.Option>}
        </Select>
      </div>
      <div className="dict-filter_line_value">
        {schema.Template ? (
          <schema.Template error={error} value={value} onChange={(e) => update('value', e)} />
        ) : (
          <Input className={error ? 'has-error' : ''} value={value} onChange={(e) => update('value', e.target.value)} />
        )}
      </div>
      {lineKey !== 'base' && (
        <div onClick={remove} className="dict-filter_line_trash">
          <img src={trash} width="20" height="20" alt="" />
        </div>
      )}
    </div>
  );
}
