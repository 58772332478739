import * as Yup from 'yup';

const fieldsSchema = [
  {
    label: 'Company name *',
    defaultValue: '',
    placeholder: 'Enter workspace or company name',
    key: 'companyName',
  },
];

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
});

const companyDetailsSchemas = {
  fieldsSchema,
  validationSchema,
};
export default companyDetailsSchemas;
