import SkeletonLine from '../../Skeletons/line';

export default function EntitySidebarLoaders() {
  return (
    <div className="entity-sidebar_loaders">
      <SkeletonLine style={{ height: '40px' }} />
      <SkeletonLine style={{ height: '40px' }} />
      <SkeletonLine style={{ height: '40px' }} />
      <SkeletonLine style={{ height: '40px' }} />
    </div>
  );
}
