import ReactGA from 'react-ga';
import { Auth } from 'aws-amplify';
import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

export const analytics = Analytics({
  app: 'simpoco',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_KEY,
    }),
  ],
});

export const initGA = () => {
  Auth.currentSession()
    .then((data) => {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
        gaOptions: {
          userId: data.accessToken.payload.username,
        },
      });
    })
    .catch((err) => {
      console.log(err);
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
        gaOptions: {
          userId: 'Visitor',
        },
      });
    });
};

export const PageView = (url) => {
  ReactGA.pageview(url);

  analytics.page({
    url,
  });
};

export const Event = (category, name, label) => {
  ReactGA.event({
    category,
    action: name,
    label,
  });
};

export const Track = async (category, name, payload) => {
  const defaultPayload = {
    name,
  };
  const combinedPayload = { ...defaultPayload, ...payload };
  await analytics.track(category, combinedPayload);
};
