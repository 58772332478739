import React from 'react';

import { observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';

import { Button, Divider, Space } from 'antd';
import './styles.scss';

import selectedIcon from '../../Assets/Images/icons/checkmark-circ-selected.svg';
import deselectedIcon from '../../Assets/Images/icons/checkmark-circ-deselected.svg';
import slackIcon from '../../Assets/Images/icons/repo-icon-slack-white.svg';

const WorkflowsBotScopes = 'channels:read,groups:read,im:read,im:write,mpim:read,mpim:write,users.profile:read,users:read,chat:write,team:read';
const WorkflowsUserScopes = 'channels:read,groups:read,channels:write,groups:write,users:read';
const ContentUserScopes = 'channels:read,channels:history,team:read,users:read,users.profile:read';
const AllScopesMerged = 'channels:read,groups:read,im:write,mpim:write,users.profile:read,users:read,chat:write,team:read&user_scope=channels:read,groups:read,mpim:read,im:read,channels:write,groups:write,users:read,channels:history,team:read,users.profile:read';

class AddToSlack extends React.Component {
  selectedValues = { workflows: false, content: true };

  constructor() {
    super();

    makeObservable(this, {
      selectedValues: observable,
    });
  }

  onChange(e) {
    const selected = this.selectedValues;
    selected[e.target.value] = !selected[e.target.value];
    runInAction(() => { this.selectedValues = selected; });
  }

  onChangeNew(val) {
    const selected = this.selectedValues;
    selected[val] = !selected[val];
    runInAction(() => { this.selectedValues = selected; });
  }

  combineScopes() {
    let scopes = '';
    if (
      this.selectedValues.workflows === true
      && this.selectedValues.content === true
    ) {
      scopes = AllScopesMerged;
    } else if (this.selectedValues.workflows === true) {
      scopes = `${WorkflowsBotScopes}&user_scope=${WorkflowsUserScopes}`;
    } else if (this.selectedValues.content === true) {
      scopes = `&user_scope=${ContentUserScopes}`;
    }
    return scopes;
  }

  getMode() {
    let mode = '';
    if (
      this.selectedValues.workflows === true
      && this.selectedValues.content === true
    ) {
      mode = 'all';
    } else if (this.selectedValues.workflows === true) {
      mode = 'workflows';
    } else {
      mode = 'content';
    }
    return mode;
  }

  getTexts() {
    switch (this.getMode()) {
      case 'workflows':
        return {
          header:
            'Notify members or channels in your Slack workspace when something relevant comes-up.',
          subheader:
            'Create Workflows that involve Slack notifications / alerts.',
          text: 'LoudnClear will automatically fetch all your Slack members and channels.',
        };
      case 'content':
        return {
          header: 'Sync Slack content for your public channels.',
          subheader: 'Connect yout workspace to involve keyword alerts.',
          text: 'LoudnClear will automatically fetch all your Slack workspace info and channels.',
        };
      default:
        return {
          header: 'Sync Slack content for your public channels.',
          subheader:
            'Create Workflows that involve Slack notifications / alerts.',
          text: 'LoudnClear will automatically fetch all your Slack team info, members and channels.',
        };
    }
  }

  isButtonEnabled() {
    for (const [mode, isChecked] of Object.entries(this.selectedValues)) { // eslint-disable-line
      if (isChecked === true) {
        return true;
      }
    }
    return false;
  }

  render() {
    return (
      <>
        <div className="slack-chooser">
          <div
            className={`slack-chooser_item ${this.selectedValues.content ? 'selected' : ''
              }`}
            onClick={() => this.onChangeNew('content')}
          >
            <div className="slack-chooser_item_check">
              <img
                src={
                  this.selectedValues.content ? selectedIcon : deselectedIcon
                }
                alt="Select"
                width="24"
                height="24"
              />
            </div>
            <div className="slack-chooser_item_content">
              <div className="slack-chooser_item_content_title">
                Get feed content
              </div>
              <div className="slack-chooser_item_content_desc">
                <div>
                  <div className="dot" />
                  <div>Sync Slack content for your public channels</div>
                </div>
                <div>
                  <div className="dot" />
                  <div>Connect your workspace to trigger keywords alerts</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`slack-chooser_item ${this.selectedValues.workflows ? 'selected' : ''
              }`}
            onClick={() => this.onChangeNew('workflows')}
          >
            <div className="slack-chooser_item_check">
              <img
                src={
                  this.selectedValues.workflows
                    ? selectedIcon
                    : deselectedIcon
                }
                alt="Select"
                width="24"
                height="24"
              />
            </div>
            <div className="slack-chooser_item_content">
              <div className="slack-chooser_item_content_title">
                Alert Members
              </div>
              <div className="slack-chooser_item_content_desc">
                <div>
                  <div className="dot" />
                  <div>
                    Notify members or channels in your Slack workspace when
                    something relevant comes-up.
                  </div>
                </div>
                <div>
                  <div className="dot" />
                  <div>
                    Create Workflows that involve Slack notifications / alerts.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slack-note">
          LoudnClear will automatically fetch all your Slack members and
          channels.
        </div>
        <Divider />
        <Space direction="vertical" size={window.screen.width < 1500 ? 10 : 30}>
          <a
            className="slack-btn-integrate"
            style={{
              cursor: this.isButtonEnabled() ? 'pointer' : 'not-allowed',
            }}
            disabled={!this.isButtonEnabled()}
            href={`https://slack.com/oauth/v2/authorize?scope=${this.combineScopes()}&redirect_uri=https://${window.location.hostname
              }${window.location.port ? `:${window.location.port}` : ''
              }/slack&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID
              }&state=${this.getMode()}`}
          >
            <img
              src={slackIcon}
              width="30"
              height="30"
              alt="connect with slack"
            />
            Connect with Slack
          </a>
          <Button
            type="link"
            className="closePageModalBtn"
            onClick={this.props.closeModalWithoutFetching}
          >
            Close
          </Button>
        </Space>
      </>
    );
  }
}

export default observer(AddToSlack);
