/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from 'antd';

import emptyStateIcon from '../../Assets/Images/icons/boarding-ico-2.svg';
import WatchIcon from '../../Assets/Images/icons/icon-20-x-20-ic-20-play-circle.svg';
import './styles.scss';

function IntegrationDetailsEmptyStateView() {
  return (
    <div className="dashboard-loading-state-content">
      <Col>
        <Row>
          <img
            style={{ margin: '0 auto', marginTop: '0' }}
            alt=""
            src={emptyStateIcon}
          />
        </Row>
        <Row>
          <span className="empty-title">
            Integrate with at least one platforms
          </span>
        </Row>
        <Row className="integration-empty-state-title">
          to enhance your monitoring capabilities and discover new
          opportunities
        </Row>
      </Col>
    </div>
  );
}
export default IntegrationDetailsEmptyStateView;
