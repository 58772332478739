import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import ActivityWidget from './activityWidget';
import SegmentsWidget from './segments';
import dalInstance from '../../../Data/Dal';

function WidgetsWrapper() {
  const segs = toJS(dalInstance.segments.segments);
  return (
    <div className={`widgets ${segs?.length === 0 ? 'nosegments' : ''}`}>
      <div className="widgets-section_activity">
        <ActivityWidget />
      </div>
      {segs?.length > 0 && (
        <div className="widgets-section_segments">
          <SegmentsWidget />
        </div>
      )}
    </div>
  );
}

export default observer(WidgetsWrapper);
