import { useQuery } from 'react-query';
import NetworkManager from '../../Network/NetworkManager';
import { useCommunityContext } from '../contexts/community';

export default function useLastUpdate() {
  const { communityId } = useCommunityContext();
  const communityLastUpdate = useQuery(['community', 'lastUpdated'], () => NetworkManager.communityLastUpdate(communityId), {
    enabled: !!communityId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 60, // 60 minutes stale time
  });

  return {
    communityLastUpdate,
  };
}
