import { Tooltip } from 'antd';
import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import copyIcon from '../../../../../../Assets/Images/icons/copy-icon.svg';
import flagIcon from '../../../../../../Assets/Images/icons/flag-ico.png';
import { ReactComponent as PinIcon } from '../../../../../../Assets/Images/icons/pin-ico.svg';
import defaultIntegrations from '../../../../../../Data/integrations';

export default function DetailsFieldLine({
  field, ind, currentLength, handlePinClick, allCustomFields,
}) {
  const copyInput = useRef();
  const [hasCopied, setCopied] = useState('');
  const [hover, setHover] = useState(false);
  const originPlatformIcon = useMemo(() => {
    const thisField = allCustomFields.find((f) => f.label === field.label);
    if (!thisField) return null;
    return defaultIntegrations.find((a) => a.targetTag === (thisField.service_name === 's3' ? 'csv' : thisField.service_name))?.image;
  }, [allCustomFields, field]);
  const handleCopyClick = useCallback((dt) => {
    copyInput.current.value = dt.value;
    copyInput.current.select();
    copyInput.current.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyInput.current.value);
    setCopied(dt.label);
    setTimeout(() => {
      setCopied('');
    }, 50);
  }, [copyInput]);
  const isFlag = useMemo(() => typeof field.value === 'object', [field]);
  return (
    <>
      <input type="text" style={{ position: 'absolute', visibility: 'hidden' }} className="copy-input" ref={copyInput} />

      <motion.div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className={`collapse-userdetails_list_item ${field.pinned !== false ? 'is-pinned' : ''}`}
        initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
        animate={{
          height: '30px', overflow: 'visible', opacity: 1,
        }}
        exit={{ height: '0px', opacity: 0, delay: (currentLength - ind) * 0.5 }}
        transition={{ ease: 'easeOut', duration: 0.4 }}
        key={field.label}
        layoutId={field.label}
      >
        {/* eslint-disable-line */}
        <Tooltip
          overlayStyle={{ pointerEvents: 'none' }}
          mouseEnterDelay={0.4}
          color="white"
          title={(
            <div className="general-tooltip">
              <div className="general-tooltip_title">
                {field.label}
              </div>
              {isFlag ? (
                field.value.map((v) => (
                  <div className="general-tooltip_value">
                    -
                    {'  '}
                    {v}
                  </div>
                ))
              ) : (
                <div className="general-tooltip_value">{field.value}</div>
              )}
            </div>
          )}
        >
          <div className="hover_utility_tooltip" />
        </Tooltip>
        <div className="key">
          {isFlag && (
            <Tooltip
              color="white"
              overlayInnerStyle={{ width: 'max-content' }}
              title={<span style={{ color: '#3d3d3d', whiteSpace: 'nowrap' }}>We found multiple values for this field</span>}
            >
              <motion.div
                className="custom-field_flag"
              >
                <img src={flagIcon} width="16" height="16" alt="" />
              </motion.div>

            </Tooltip>
          )}
          <span>
            {field.label}
          </span>
          :
        </div>
        <div className="val">

          {isFlag ? field.value[0] : field.value}
        </div>
        {originPlatformIcon && (
          <motion.div
            className="origin-platform"
            animate={hover ? { opacity: 1, y: '-50%', x: '0px' } : { opacity: 0, y: '-50%', x: '30px' }}
            transition={{ type: 'tween', duration: 0.2 }}
            style={isFlag ? { left: '-38px', zIndex: 1 } : { zIndex: 1 }}
          >
            <img src={originPlatformIcon} width="18" height="18" alt="" />
          </motion.div>
        )}
        <div className="copy" onClick={() => handleCopyClick(field)}>
          <img src={copyIcon} width="20" height="20" alt="Copy" />
        </div>
        <div className="pin" onClick={() => handlePinClick(field)}>
          <PinIcon />
        </div>
        <AnimatePresence>
          {hasCopied === field.label && (
            <motion.div
              initial={{ opacity: 1, y: '3px' }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, y: '-30px' }}
              transition={{ type: 'linear', duration: 0.5 }}
              className="copy-indicator"
            >
              Copied
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
}
