import { useCallback, useEffect, useState } from 'react';

export default function usePersistentDisclosure(key, def) {
  const [isOpen, setOpen] = useState(def ?? false);
  useEffect(() => {
    // first load
    try {
      const saved = window.localStorage.getItem(`__${key}_disclosure`);
      if (saved === null) {
        window.localStorage.setItem(`__${key}_disclosure`, def.toString() ?? 'false');
      } else {
        setOpen(saved === 'true');
      }
    } catch (err) {
      // do nothing
    }
  }, [key, def]);

  const handleOpen = useCallback(() => {
    setOpen(true);
    window.localStorage.setItem(`__${key}_disclosure`, 'true');
  }, [key]);

  const handleClose = useCallback(() => {
    setOpen(false);
    window.localStorage.setItem(`__${key}_disclosure`, 'false');
  }, [key]);

  const handleToggle = useCallback(() => {
    if (isOpen) handleClose();
    else handleOpen();
  }, [isOpen, handleOpen, handleClose]);

  return {
    isOpen,
    onOpen: handleOpen,
    onClose: handleClose,
    onToggle: handleToggle,
  };
}
