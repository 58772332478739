import { useMemo } from 'react';

export default function PbStatus({ current }) {
  const colors = useMemo(() => {
    if (current === 'active') return { bg: '#C8E6C9', text: '#388E3C' };
    if (current === 'inactive') return { bg: '#F5F5F5', text: '#757575' };
    return { bg: '#EF9A9A', text: '#D32F2F' };
  }, [current]);
  return (
    <div className="pb-status" style={{ background: colors.bg, color: colors.text }}>
      {`${current.charAt(0).toUpperCase()}${current.slice(1)}`}
    </div>
  );
}
