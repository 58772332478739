/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useField, useFormikContext } from 'formik';
import { Input } from 'antd';
import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import toast from 'react-hot-toast';
import CreateModal from '../../../../Modal/create/createModal';
import { platformColorsByBg } from '../../../../../Data/colors';
import ColorPicker from '../../../../ColorPicker';
import PlaybookIcon from '../../../../../Assets/Images/icons/playbooks/playbook-icon';
import usePlaybooksMutations from '../../../hooks/usePlaybooksMutations';

const validationSchema = object({
  name: string().required('Playbook name is required').matches(/^[a-zA-Z \d]+$/g, 'Playbook name can only contain letters and numbers'),
});

export default function CRUDModal({ onClose, isShown, borderedIcon }) {
  const { values } = useFormikContext();
  const { color, metadata } = values;
  const { update, create } = usePlaybooksMutations();
  const { playbookId } = useParams();
  const history = useHistory();
  // // const { queryId } = useParams();
  const [field] = useField('name');

  const onSuccess = useCallback(() => {
    onClose();
    history.push('/dashboard/playbooks/all');
  }, [onClose, history]);

  const handleSubmit = useCallback(async () => {
    try {
      await validationSchema.validate(values);
      if (values.metadata.state === 'UNFINISHED') {
        await create.mutateAsync(values, { onSuccess });
      } else {
        await update.mutateAsync(
          {
            ...values,
            id: playbookId,
          },
          { onSuccess },
        );
      }
    } catch (e) {
      if (e.name === 'ValidationError') {
        return toast.error(e.message);
      }
      throw e;
    }
  }, [values, playbookId, onSuccess, create, update]);

  return (
    <CreateModal
      isShown={isShown}
      onApply={handleSubmit}
      isLoading={update.isLoading || create.isLoading}
      onClose={onClose}
      className="pb-modal"
      desc="Playbook will start running automatically after you save it."
      title={metadata.state === 'UNFINISHED' ? 'Save playbook' : 'Update playbook'}
      icon={(
        <div
          className="crud-modal_icon"
          style={
            borderedIcon
              ? {
                border: `1px solid ${platformColorsByBg[color].textColor}`,
                backgroundColor: 'transparent',
              }
              : {
                backgroundColor: color || '#99d2ff',
              }
          }
        >
          <PlaybookIcon color={color ? platformColorsByBg[color].textColor : '#076cbe'} />
        </div>
      )}
    >
      <div className="crud-modal">
        <div className="crud-modal_field">
          <label>Playbook name</label>
          <div>
            <Input className="lnc-input" {...field} type="text" />
          </div>
        </div>
        <div className="crud-modal_field pb-picker">
          <label>Playbook color</label>
          <div>
            <ColorPicker field="color" />
          </div>
        </div>
      </div>
    </CreateModal>
  );
}
