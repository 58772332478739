import { Field } from 'formik';
import { Input } from 'antd';

export default function FormField({ schema }) {
  return (
    <Field name={schema.key}>
      {({ field, form }) => (
        <div
          className={`form-control ${
            form.errors[schema.key] && form.touched[schema.key] ? 'invalid' : ''
          }`}
        >
          <label htmlFor={schema.key}>{schema.label}</label>
          {schema.Template ? (
            <schema.Template field={field} schema={schema} />
          ) : (
            <Input
              {...field}
              type={schema?.type || 'text'}
              id={schema.key}
              placeholder={schema?.placeholder}
            />
          )}
          {form.touched[schema.key] && (
            <div className="form-control_error">{form.errors[schema.key]}</div>
          )}
        </div>
      )}
    </Field>
  );
}
