import { Form, Formik, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import usePlaybooksMutations from '../../../Components/Playbooks/hooks/usePlaybooksMutations';
import usePlaybooksQueries from '../../../Components/Playbooks/hooks/usePlaybooksQueries';

const ValuesHandler = () => {
  const { setValues } = useFormikContext();
  const { playbookId } = useParams();
  const { playbooks } = usePlaybooksQueries();
  useEffect(() => {
    if (playbookId && playbooks.data) {
      const q = playbooks.data.find((qu) => qu.id === Number(playbookId));
      if (q) {
        setValues({
          trigger: q.trigger,
          action: {
            ...q.action,
            _isComplete: true,
          },
          name: q.name,
          color: q.color,
          metadata: {
            state: 'EXISTING',
          },
        });
      }
    } else if (playbookId === undefined) {
      setValues({
        trigger: null,
        action: null,
        name: '',
        color: '#dbe9ff',
        metadata: {
          state: 'UNFINISHED',
        },
      });
    }
  }, [playbookId, playbooks.data]);
  return (
    <></>
  );
};

export default function PlaybooksFormikWrapper({ children }) {
  return (
    <Formik
      initialValues={{
        trigger: null,
        action: null,
        name: '',
        color: '#dbe9ff',
        metadata: {
          state: 'UNFINISHED',
        },
      }}
    >
      <Form>
        {children}
        <ValuesHandler />
      </Form>
    </Formik>
  );
}
