import { AnimatePresence } from 'framer-motion';
import { useContext } from 'react';
import ListLoader from '../../Skeletons/listLoader';
import TabWrapper from '../common/tabWrapper';
import { AutomationsContext } from '../hooks/useAutomations';
import AutomationRule from '../rule';

export default function AutomationsTab() {
  const { workflowsQuery: { isLoading, data } } = useContext(AutomationsContext);
  return (
    <TabWrapper tabKey="list" className="inner automation-list">
      {isLoading && (
        <ListLoader
          amount={3}
          boxProps={{
            style: {
              backgroundColor: 'white',
              height: '100px',
              marginBottom: '10px',
            },
          }}
        />
      )}
      <AnimatePresence>
        {!isLoading && data && (
          data.map((r) => (
            <AutomationRule rule={r} key={r.id} />
          ))
        )}
      </AnimatePresence>
    </TabWrapper>
  );
}
