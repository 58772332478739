import { Tooltip } from 'antd';
import { useMemo } from 'react';
import arrow from '../../../../../../Assets/Images/icons/arrow_right_alt.png';

const generateArrowLines = (fieldKey, added, removed) => {
  const loops = Math.max(added.length, removed.length);
  const final = [];
  for (let i = 0; i < loops; i++) {
    final.push({
      key: `fieldKey-${fieldKey}-${i}`,
      from: removed?.[i] || 'N/A',
      to: added?.[i] || 'N/A',
    });
  }
  return final;
};

export default function HistoryInnerContent({ act }) {
  const lines = useMemo(() => generateArrowLines(act.label, act.added, act.removed), [act]);
  return (
    <div className="uup-changes">
      {lines.map((line) => (
        <div className="uup-changes_line" key={line.key}>
          <div className={line.from === 'N/A' ? 'italic' : ''}>
            <Tooltip title={<span style={{ color: 'black' }}>{line.from}</span>} color="white">
              {line.from}
            </Tooltip>
          </div>
          <div><img src={arrow} width="20" height="20" alt="" /></div>
          <div className={line.to === 'N/A' ? 'italic' : ''}>
            <Tooltip title={<span style={{ color: 'black' }}>{line.to}</span>} color="white">
              {line.to}
            </Tooltip>
          </div>
        </div>
      ))}
    </div>
  );
}
