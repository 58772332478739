import { Auth } from 'aws-amplify';
import moment from 'moment';
import tzipiUrlBuilder from '../config/tzipiUrlTemplate';
import config from '../config/config.json';
import sequenceRetrier from '../Utils/funcs/retrier';

const axios = require('axios').default;

const CSV_MAPPER_NO_SERVER = false;

class NetworkManager {
  service;
  jwtToken;

  constructor() {
    if (!NetworkManager.instance) {
      NetworkManager.instance = this;
    }
    this.errorDebouncer = {
      timeout: 2000,
      isCurrentlyDebounced: false,
    };
    this.service = axios.create({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
      },
    });

    return NetworkManager.instance;
  }

  async getToken() {
    const session = await Auth.currentSession();
    this.jwtToken = session.getAccessToken().getJwtToken();
    this.service.defaults.headers = {
      Authorization: this.jwtToken,
      'Access-Control-Allow-Origin': '*',
    };
  }

  async getUser() {
    try {
      await this.getToken();
      const response = await this.service.get(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.user.user,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getCommunities() {
    try {
      await this.getToken();
      const response = await this.service.get(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.user.community,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async deleteDictionary(communityId, dictionaryId) {
    try {
      await this.getToken();
      const response = await this.service.delete(
        tzipiUrlBuilder(config.TzipiURLs.dictionaries.dictionary, { communityId, dictionaryId }),
      );
      return {
        data: response.data,
        id: dictionaryId,
      };
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getSettings(communityId, entity) {
    try {
      await this.getToken();
      const response = await this.service.get(
        tzipiUrlBuilder(config.TzipiURLs[entity].settings, { communityId }),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async updateSetting(communityId, entity, keyValue) {
    try {
      await this.getToken();
      const response = await this.service.put(
        tzipiUrlBuilder(config.TzipiURLs[entity].settings, { communityId }),
        keyValue,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getCommunityUsers() {
    try {
      await this.getToken();
      const response = await this.service.get(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.user.community,
      );
      return response.data[0].members;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getFeedDictionaries(communityId) {
    try {
      await this.getToken();
      const response = await this.service.get(
        tzipiUrlBuilder(config.TzipiURLs.dictionaries.dictionaries, { communityId }),
      );
      return response.data.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async updateQuery(communityId, queryId, { filters, color, name }) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.query, { communityId, queryId })}`;
      const response = await this.service.put(url, { filters, color, name });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async deleteQuery(communityId, queryId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.query, { communityId, queryId })}`;
      const response = await this.service.delete(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async createNewQuery(communityId, { filters, color, name }) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.queries, { communityId })}`;
      const response = await this.service.post(url, { filters, color, name });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getCommunityTopics(communityId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.community.topics, { communityId })}`;
      const response = await this.service.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  async createTopic(communityId, topic) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.community.topics, { communityId })}`;
      const response = await this.service.post(url, { topic });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  async deleteTopic(communityId, topicId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.community.topic, { communityId, topicId })}`;
      const response = await this.service.delete(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  async deletePlaybook(communityId, playbookId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.playbooks.playbook, {
        communityId,
        playbookId,
      })}`;
      const response = await this.service.delete(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  async testPlaybook(communityId, playbookId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.playbooks.playbookTest, {
        communityId,
        playbookId,
      })}`;
      const response = await this.service.post(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async updatePlaybook(communityId, playbookId, {
    action, trigger, color, name, active,
  }) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.playbooks.playbook, {
        communityId,
        playbookId,
      })}`;
      const response = await this.service.put(url, {
        action,
        trigger,
        color,
        name,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async updatePlaybookStatus(communityId, playbookId, newActiveState) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.playbooks.playbook, {
        communityId,
        playbookId,
      })}/status`;
      const response = await this.service.put(url, {
        active: newActiveState,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async createNewPlaybook(communityId, {
    action, trigger, color, name,
  }) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.playbooks.playbooks, { communityId })}`;
      const response = await this.service.post(url, {
        action,
        trigger,
        color,
        name,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getAllPlaybooks(communityId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.playbooks.playbooks, { communityId })}`;
      const response = await this.service.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
  async getAllPlaybooksStats(communityId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.playbooks.stats, { communityId })}`;
      const response = await this.service.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getAllQueries(communityId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.queries, { communityId })}`;
      const response = await this.service.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getQueryInteraction(communityId, interactionId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.interaction, { communityId, interactionId })}`;
      const response = await this.service.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async updateQueryEmails(communityId, queryId, payload) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.emails, { communityId, queryId })}`;
      const response = await this.service.put(url, payload);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getQueryEmails(communityId, { queryId }) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.emails, { communityId, queryId })}`;
      const response = await this.service.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getWidgetsForQuery(communityId, { filters }) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.widgets, { communityId })}`;
      const response = await this.service.post(url, { filters });
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  async getMentions(communityId, { filters, recordsPerPage, page = 1 }) {
    try {
      if (!filters.length) return { data: [] };
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.mentions, {
        communityId,
      })}?page=${page}`;
      const response = await this.service.post(url, { filters, recordsPerPage });
      return response.data;
    } catch (error) {
      console.error(error);
      return { total: 0, data: [] };
    }
  }

  async getMatches(communityId, { dictionaryId, page = 1, filter = null }) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.dictionaries.dictionary, {
        communityId,
        dictionaryId,
      })}?page=${page}`;
      const response = await (filter ? this.service.post(url, filter) : this.service.get(url));
      return response.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getDictionaryWidgets(communityId, { dictionaryId }) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.dictionaries.widgets, {
        communityId,
        dictionaryId,
      })}`;
      const response = await this.service.get(url);
      return response.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getInteractionsThread(communityId, interactionId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.queries.interaction, {
        communityId,
        interactionId,
      })}`;
      const response = await this.service.get(url);
      return response.data.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getAssetsForCommunity(communityId) {
    try {
      await this.getToken();
      const response = await this.service.get(
        `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.assets
        }?community_id=${communityId}`,
      );
      const staticFiles = await this.service.get(
        `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.files
        }?community_id=${communityId}`,
      );
      return {
        accounts: response.data,
        files: staticFiles.data,
      };
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async createNewUser(role) {
    try {
      await this.getToken();
      const result = await this.service.put(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.user.user,
        {
          role,
        },
      );
      return result.data;
    } catch (error) {
      console.log('Failed to create user: ', error);
    }
  }

  async installAppToPage(pageAccessToken, pageID) {
    try {
      await this.service.post(
        `https://graph.facebook.com/${pageID}/subscribed_apps?subscribed_fields=feed,messages&access_token=${pageAccessToken}`,
      );
    } catch (error) {
      console.log('Failed to install app to page : ', error);
    }
  }

  async createNewAssets(assets, communityId) {
    try {
      await this.getToken();
      if (assets.length > 0) {
        const assetType = assets[0].type;
        if (assetType === 'page' || assetType === 'messenger') {
          assets.forEach((page) => this.installAppToPage(page.page_token, page.asset_id));
        }
      }
      this.service.defaults.headers = {
        Authorization: this.jwtToken,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      };
      const result = await this.service.put(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.assets,
        assets,
      );
      return result;
    } catch (error) {
      this.sendTemplatedErrorToSlack(
        'INTEGRATION_ERROR',
        `Failed to connect ${assets?.[0]?.service_name}`,
        {
          communityId,
          NOVAK:
            typeof error?.response?.data === 'object'
              ? JSON.stringify(error?.response?.data)
              : error?.response?.data,
        },
      );
      console.log('Failed to put new assets: ', error);
      return error;
    }
  }

  async createNewAssetsWithTzipi(communityId, asset) {
    try {
      await this.getToken();
      this.service.defaults.headers = {
        Authorization: this.jwtToken,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      };
      const result = await this.service.post(
        tzipiUrlBuilder(config.TzipiURLs.assets.assets, { communityId }),
        asset,
      );
      return result;
    } catch (error) {
      console.log('Failed to put new assets: ', error);
      return error;
    }
  }

  async searchTwitterUser(username) {
    try {
      const result = await this.service.put(
        `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.integrations.twitter
        }?username=${username}`,
      );
      if (result.status === 200) {
        return result.data;
      }
    } catch (e) {
      return null;
    }
    return null;
  }
  async updateMemberName(originID, name) {
    await this.getToken();
    this.service.defaults.headers = {
      Authorization: this.jwtToken,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    const body = {
      origin_id: originID,
      name,
    };
    const result = await this.service.put(
      process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.tasks.member,
      body,
    );
    return result;
  }

  async createNewChannels(channels) {
    try {
      await this.getToken();
      this.service.defaults.headers = {
        Authorization: this.jwtToken,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      };
      const result = await this.service.post(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.channels,
        channels,
      );
      return result;
    } catch (error) {
      console.log('Failed to put new channels: ', error);
      return error;
    }
  }

  async sendFilesToNovak(files, communityID) {
    try {
      await this.getToken();
      this.service.defaults.headers = {
        Authorization: this.jwtToken,
        'content-type': 'multipart/form-data',
      };
      const formData = new FormData();
      files.forEach((file) => formData.append(file.name, file));
      files.forEach((file) => formData.append(file.name, file.type));
      const response = await this.service.post(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.files,
        formData,
        { params: { community_id: communityID } },
      );
      return response;
    } catch (error) {
      console.error('Failed to send files: ', error);
    }
  }

  async reportMergeIssue(problem, description, member, communityID) {
    await this.getToken();
    this.service.defaults.headers = {
      Authorization: this.jwtToken,
    };
    const body = {
      community_id: communityID,
      problem: problem ?? '',
      description: description ?? '',
      member,
    };
    const result = await this.service.post(
      process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.report,
      body,
    );
    return result;
  }

  async createNewCommunity(name, type) {
    try {
      await this.getToken();
      const user = await this.getUser();
      this.service.defaults.headers = {
        Authorization: this.jwtToken,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      };
      const body = {
        name,
        type,
        id: user.id,
      };
      const result = await this.service.put(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.user.community,
        body,
      );
      return result;
    } catch (error) {
      console.log('Failed to create user: ', error);
    }
  }

  async attachUserToCommunity(email) {
    try {
      await this.getToken();
      const response = await this.service.post(tzipiUrlBuilder(config.TzipiURLs.user.join), {
        email,
      });
      return response.data;
    } catch (error) {
      console.log('Failed to create user: ', error);
      return false;
    }
  }

  formatKeywords = (keywords) => ({
    new_words: keywords.filter((k) => k._new).map((k) => k.keyword),
    remove_ids: keywords.filter((k) => k._remove).map((k) => k.id),
  });

  async createDictionary(communityId, {
    name, keywords, color, enrich, emotions,
  }) {
    const body = {
      name,
      keywords,
      color,
      enrich,
      emotions,
    };
    await this.getToken();
    const response = await this.service.post(
      tzipiUrlBuilder(config.TzipiURLs.dictionaries.dictionaries, { communityId }),
      body,
    );
    return response.data;
  }

  async updateDictionary(communityId, dictionaryId, {
    name, keywords, color, enrich, emotions,
  }) {
    const body = {
      name,
      keywords,
      color,
      enrich,
      emotions,
    };
    await this.getToken();
    const response = await this.service.put(
      tzipiUrlBuilder(config.TzipiURLs.dictionaries.dictionary, { communityId, dictionaryId }),
      body,
    );
    return response.data;
  }

  async getTaskDetails(taskID) {
    await this.getToken();
    const response = await this.service.get(
      `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.feeds.tasksDetails
      }?task_id=${taskID}`,
    );
    return response.data;
  }

  async getTargets(communityId) {
    if (communityId === undefined) {
      return undefined;
    }

    try {
      await this.getToken();
      const response = await this.service.get(
        `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.workflows.targets
        }?community_id=${communityId}`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getWorkflows(communityId, dictionaryId) {
    if (communityId === undefined || communityId === dictionaryId) {
      return undefined;
    }

    try {
      await this.getToken();
      const response = await this.service.get(
        `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.workflows.workflows
        }?community_id=${communityId}&dictionary_id=${dictionaryId}`,
      );
      const workflows = response.data;
      if (workflows.length > 0) {
        // setup object to send expected format
        workflows.forEach((workflow) => {
          workflow.filter = workflow.filter_data;
          workflow.reactors.forEach((reactor) => {
            reactor.action = reactor.reactor_action;
            reactor.target_ids = reactor.targets.map((x) => x.id);
          });
        });
      }
      return workflows;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async sendTargetNotification(communityId, eventId, assetID, notificationData, dataPoint) {
    if (communityId === undefined || eventId === undefined) {
      return undefined;
    }

    const body = {
      community_id: communityId,
      event_id: eventId,
      asset_id: assetID,
      notification_data: notificationData,
    };

    try {
      await this.getToken();
      const response = await this.service.post(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.feeds.notify,
        body,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async updateSentiment(origin, emotion) {
    await this.getToken();
    const body = {
      origin_id: origin,
      emotion,
    };
    const response = await this.service.put(
      process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.feeds.sentiment,
      body,
    );
    return response;
  }

  async attachWorkflow(dictionaryId, workflowId, targets) {
    if (dictionaryId === undefined || workflowId === undefined || targets === undefined) {
      return undefined;
    }

    const body = {
      dictionary_id: dictionaryId,
      workflow_id: workflowId,
      target_ids: targets,
    };

    try {
      await this.getToken();
      const response = await this.service.put(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.workflows.workflows,
        body,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async detachWorkflow(dictionaryId, workflowId) {
    if (dictionaryId === undefined || workflowId === undefined) {
      return undefined;
    }

    const body = {
      dictionary_id: dictionaryId,
      workflow_id: workflowId,
    };

    try {
      await this.getToken();
      const response = await this.service.delete(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.workflows.workflows,
        body,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async updateDictionaryHistory(dictId, userId) {
    try {
      await this.getToken();
      const body = {
        dictionary_id: dictId,
        user_id: userId,
        timestamp: Math.floor(Date.now() / 1000),
      };
      await this.service.put(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.feeds.dictionaryUserHistory,
        body,
      );
    } catch (error) {
      console.error(error);
    }
  }

  async inviteUser(communityId, email) {
    if (communityId === undefined || email === undefined) {
      return undefined;
    }

    const body = {
      email,
    };

    try {
      await this.getToken();
      const response = await this.service.post(
        tzipiUrlBuilder(config.TzipiURLs.community.invite, { communityId }),
        body,
      );
      return response.data;
    } catch (error) {
      if (
        error?.response?.data?.code === 'ALREADY_INVITED'
        || error?.response?.data?.error === 'LimitError'
      ) {
        return error.response.data.message;
      }
      return false;
    }
  }

  async changeTaskReadStatus(taskID, isUnread, shouldMarkAll, dictionaryID) {
    const body = {
      task_id: taskID,
      is_unread: isUnread,
      should_mark_all: shouldMarkAll,
      dictionary_id: dictionaryID,
    };
    try {
      await this.getToken();
      const resp = await this.service.post(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.feeds.tasks,
        body,
      );
      return resp.status;
    } catch (error) {
      console.error(error);
    }
  }

  async updateTask(taskId, assignee, status) {
    if (taskId === undefined) {
      return false;
    }

    // no change in status
    if (assignee === undefined && status === undefined) {
      return true;
    }

    const body = {
      id: taskId,
    };

    if (assignee !== undefined) {
      body.assignee = assignee;
    }
    if (status !== undefined) {
      body.status = status;
    }

    try {
      await this.getToken();
      const response = await this.service.put(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.feeds.tasks,
        body,
      );
      return response.data === 'Task updated successfully';
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getCrm(scrollId, sorting) {
    try {
      await this.getToken();
      let url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.crm;
      const paramaters = {};
      if (scrollId) paramaters.scroll_id = scrollId;
      if (sorting.sortBy) paramaters.sort_by = sorting.sortBy;
      if (sorting.sortDir) paramaters.sort_dir = sorting.sortDir;
      const params = new URLSearchParams(paramaters);
      if (params.toString()) {
        url = url.concat(`?${params.toString()}`);
      }
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getCrm: ', error);
    }
  }

  async getCrmWithFilter(theFilter, scrollId, sorting) {
    try {
      const body = {
        ...theFilter,
        scroll_id: scrollId,
      };
      await this.getToken();
      let url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.filters;
      const paramaters = {};
      if (sorting.sortBy) paramaters.sort_by = sorting.sortBy;
      if (sorting.sortDir) paramaters.sort_dir = sorting.sortDir;
      const params = new URLSearchParams(paramaters);
      if (params.toString()) {
        url = url.concat(`?${params.toString()}`);
      }
      const result = await this.service.post(url, body);
      return result.data;
    } catch (error) {
      console.log('Failed to getCrm: ', error);
    }
  }

  async getCrmWithSegment(segmentId, scrollId, sorting) {
    try {
      if (segmentId === undefined) {
        return false;
      }
      const paramaters = {
        segment_id: segmentId,
      };
      if (scrollId) paramaters.scroll_id = scrollId;
      if (sorting.sortBy) paramaters.sort_by = sorting.sortBy;
      if (sorting.sortDir) paramaters.sort_dir = sorting.sortDir;
      const params = new URLSearchParams(paramaters);
      await this.getToken();
      let url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.segment;
      if (params.toString()) {
        url = url.concat(`?${params.toString()}`);
      }

      // console.log("LOG getCrmWithSegment " + url)

      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getCrm: ', error);
    }
  }

  async getCrmWithCategory(categoryName, scrollId) {
    try {
      if (categoryName === undefined) {
        return false;
      }

      const params = `?name=${categoryName}`;
      await this.getToken();
      let url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.category + params;
      if (scrollId !== undefined) {
        url = url.concat(`&scroll_id=${scrollId}`);
      }

      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getCrm: ', error);
    }
  }

  async exportCrm(communityId, data, fileName, aElement) {
    try {
      await this.getToken();
      const url = tzipiUrlBuilder(config.TzipiURLs.crm.export, { communityId });

      // const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.export;
      const response = await this.service.post(url, data);
      aElement.href = window.URL.createObjectURL(new Blob([response.data]));
      aElement.setAttribute('download', fileName); // or any other extension
      if (response.status === 204) {
        return 'No data to export';
      }
      return true;
    } catch (error) {
      console.log('Failed to getCrm: ', error);
      return 'No data available';
    }
  }

  startContactUsageData = async (communityId, contactId) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.crm.usage, { communityId, contactId });
    const response = await this.service.post(url);
    return response.data;
  };

  getContactUsageData = async (communityId, contactId, fieldId, interval) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.crm.usage, { communityId, contactId });
    const response = await this.service.get(
      `${url}?contactId=${contactId}&fieldId=${fieldId}&interval=${interval}`,
    );
    if (response.data.status === 'NOT_FINISHED') throw new Error('Query not finished');
    return response.data;
  };

  getAllWorkflows = async (communityId) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.workflows.workflows, { communityId });
    const response = await this.service.get(url);
    return response.data;
  };

  createWorkflow = async (communityId, workflow) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.workflows.workflows, { communityId });
    const response = await this.service.post(url, workflow);
    return response.data;
  };

  updateWorkflow = async (communityId, workflow) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.workflows.workflow, {
      communityId,
      workflowId: workflow.id,
    });
    const response = await this.service.put(url, workflow);
    return response.data;
  };

  deleteWorkflow = async (communityId, workflowId) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.workflows.workflow, { communityId, workflowId });
    const response = await this.service.delete(url);
    return response.data;
  };

  getWorkflowsSalesforceDetails = async (communityId, object) => {
    await this.getToken();
    const basePath = object
      ? config.TzipiURLs.workflows.utils.salesforce.fields
      : config.TzipiURLs.workflows.utils.salesforce.objects;
    const url = tzipiUrlBuilder(basePath, { communityId, object });
    const response = await this.service.get(url);
    return response.data;
  };

  getZendeskFields = async (communityId) => {
    await this.getToken();
    const basePath = config.TzipiURLs.workflows.utils.zendesk;
    const url = tzipiUrlBuilder(basePath, { communityId });
    const response = await this.service.get(url);
    return response.data;
  };

  async getCrmFilterSources() {
    try {
      await this.getToken();
      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.filterSources;
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getCrm: ', error);
      return [];
    }
  }

  async updateUserLabels(memberId, labels, tags = null, community_id = null) {
    try {
      if (memberId === undefined) {
        return false;
      }

      await this.getToken();
      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.editMembersLabel;
      const result = await this.service.post(url, {
        member_id: memberId,
        labels,
        tag_ids: tags,
        community_id,
      });
      return result.status === 200;
    } catch (error) {
      console.log('Failed to getCrm: ', error);
      return false;
    }
  }

  async updateCommunityTags(communityID, tag) {
    try {
      await this.getToken();
      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.user.tag;
      const result = await this.service.post(url, {
        tag,
        community_id: communityID,
      });
      return result.data;
    } catch (error) {
      return false;
    }
  }

  async insertCommunityTag(communityId, tagLabel) {
    try {
      await this.getToken();
      const url = tzipiUrlBuilder(config.TzipiURLs.community.tags, { communityId });
      const result = await this.service.post(url, {
        label: tagLabel,
      });
      return result.data;
    } catch (error) {
      return false;
    }
  }

  async deleteCommunityTag(communityId, tagId) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.community.tags, { communityId })}/${tagId}`;
      const result = await this.service.delete(url);
      return result.data;
    } catch (error) {
      return false;
    }
  }

  async getCommunityTags(communityId) {
    try {
      await this.getToken();
      const url = tzipiUrlBuilder(config.TzipiURLs.community.tags, { communityId });
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      return false;
    }
  }

  async updateContactTags(communityId, contactId, action, payload) {
    try {
      await this.getToken();
      const url = `${tzipiUrlBuilder(config.TzipiURLs.crm.contact.tags, {
        communityId,
        contactId,
      })}`;
      const result = await this.service.put(url, {
        action,
        payload,
      });
      return result.data;
    } catch (error) {
      return false;
    }
  }

  async getFeedMatchesWithSuperFilter(dictId, since, until, filters, operator, paging) {
    try {
      if (dictId === undefined || filters === undefined || operator === undefined) {
        return false;
      }

      let params = `?dictionary_id=${dictId}`;
      params = `${params}&since=${since.unix()}`;
      params = `${params}&until=${until.unix()}`;

      const body = {
        filter: {
          operator,
          filters,
        },
      };

      await this.getToken();

      let url;
      if (paging === undefined) {
        url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.feeds.superFilter + params;
      } else {
        url = process.env.REACT_APP_NOVAK_BASE_URI + paging.substring(7, paging.length);
      }
      const result = await this.service.post(url, body);
      return result.data;
    } catch (error) {
      console.log('Failed to getFeedMatchesWithSuperFilter: ', error);
      return undefined;
    }
  }

  async getTasksDashboard(communityId, dictId, since, until) {
    try {
      if (dictId === undefined || communityId === undefined) {
        return false;
      }

      let params = `?dictionary_id=${dictId}`;
      params = `${params}&community_id=${communityId}`;
      params = `${params}&since=${since.unix()}`;
      params = `${params}&until=${until.unix()}`;

      await this.getToken();

      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.feeds.tasksDashboard + params;
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getTasksDashboard: ', error);
      return undefined;
    }
  }

  async getConnectedChannel(communityId) {
    try {
      if (communityId === undefined) {
        return false;
      }

      await this.getToken();
      const url = tzipiUrlBuilder(config.TzipiURLs.community.connectedChannels, { communityId });
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getConnectedChannel: ', error);
      return undefined;
    }
  }

  async getSentiments(communityId, since, until) {
    try {
      if (communityId === undefined) {
        return false;
      }

      let params = `?community_id=${communityId}`;
      params = `${params}&since=${since.unix()}`;
      params = `${params}&until=${until.unix()}`;

      await this.getToken();

      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.home.sentiments + params;
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getSentiments: ', error);
      return undefined;
    }
  }

  getFolders = async (communityId, entity) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.folders.folders, { communityId, entity });
    const response = await this.service.get(url);
    return response.data;
  };

  createFolder = async (communityId, entity, data) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.folders.folders, { communityId, entity });
    const response = await this.service.post(url, data);
    return response.data;
  };

  updateEntity = async (communityId, entity, data) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.folders.updateEntity, { communityId, entity });
    const response = await this.service.post(url, data);
    return response.data;
  };

  updateFolder = async (communityId, entity, folderId, data) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.folders.updateFolder, {
      communityId,
      entity,
      folderId,
    });
    const response = await this.service.put(url, data);
    return response.data;
  };
  deleteFolder = async (communityId, entity, folderId) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.folders.updateFolder, {
      communityId,
      entity,
      folderId,
    });
    const response = await this.service.delete(url);
    return response.data;
  };

  getAllDashboards = async (communityId) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.dashboards.dashboards, { communityId });
    const response = await this.service.get(url);
    return response.data;
  };

  createBoard = async (communityId, data) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.dashboards.dashboards, { communityId });
    const response = await this.service.post(url, data);
    return response.data;
  };

  updateBoard = async (communityId, boardId, data) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.dashboards.dashboard, { communityId, boardId });
    const response = await this.service.put(url, data);
    return response.data;
  };

  deleteBoard = async (communityId, boardId) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.dashboards.dashboard, { communityId, boardId });
    const response = await this.service.delete(url);
    return response.data;
  };

  getBoardWidgets = async (communityId, boardId) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.dashboards.widgets, { communityId, boardId });
    const response = await this.service.get(url);
    return response.data;
  };

  getBoardWidget = async (communityId, boardId, segmentBoard, widgetId) => {
    await this.getToken();
    const url = `${tzipiUrlBuilder(config.TzipiURLs.dashboards.widgets, {
      communityId,
      boardId,
    })}/${widgetId}${segmentBoard ? `?segment_id=${segmentBoard}` : ''}`;
    const response = await this.service.get(url);
    return response.data;
  };

  createWidgetForBoard = async (communityId, boardId, widgetConfig, segmentBoard) => {
    await this.getToken();
    const url = `${tzipiUrlBuilder(config.TzipiURLs.dashboards.widgets, { communityId, boardId })}${segmentBoard ? `?segment_id=${segmentBoard}` : ''
      }`;
    const response = await this.service.post(url, { config: widgetConfig });
    return response.data;
  };

  updateWidgetForBoard = async (communityId, boardId, widgetConfigWithId) => {
    await this.getToken();
    const url = `${tzipiUrlBuilder(config.TzipiURLs.dashboards.widget, {
      communityId,
      boardId,
      widgetId: widgetConfigWithId.id,
    })}`;
    const response = await this.service.put(url, { config: widgetConfigWithId });
    return response.data;
  };

  deleteWidget = async (communityId, boardId, widgetId) => {
    await this.getToken();
    const url = `${tzipiUrlBuilder(config.TzipiURLs.dashboards.widget, {
      communityId,
      boardId,
      widgetId,
    })}`;
    const response = await this.service.delete(url);
    return response.data;
  };

  updateLayoutForBoard = async (communityId, boardId, layout) => {
    await this.getToken();
    const url = tzipiUrlBuilder(config.TzipiURLs.dashboards.dashboardLayout, {
      communityId,
      boardId,
    });
    const response = await this.service.post(url, layout);
    return response.data;
  };

  translateText = async (text) => {
    await this.getToken();
    const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.translate;
    const response = await this.service.post(url, { text });
    return response.data;
  };

  verifyInvitationByEmail = async (email) => {
    // await this.getToken();
    const url = `${tzipiUrlBuilder(config.TzipiURLs.utils.verifyInvite)}?email=${email}`;
    const response = await this.service.get(url);
    return response.data;
  };

  async getStoredMapping(data_type) {
    const url = `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.storedMapping
      }?data_type=${data_type}`;
    if (!CSV_MAPPER_NO_SERVER) {
      await this.getToken();
      const result = await this.service.get(url);
      return result.data;
    }
    return [];
  }
  async getAllCustomFields() {
    const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.customfields;
    if (!CSV_MAPPER_NO_SERVER) {
      await this.getToken();
      const result = await this.service.get(url);
      return result.data;
    }
    return [];
  }

  async uploadCsvFile(file) {
    if (!CSV_MAPPER_NO_SERVER) {
      await this.getToken();
      this.service.defaults.headers = {
        Authorization: this.jwtToken,
        'content-type': 'multipart/form-data',
      };
      const formData = new FormData();
      formData.append(file.name, file);
      const response = await this.service.post(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.files,
        formData,
      );
      return response.data;
    }
    return '';
  }

  async mapCsvFile(data_type, asset_id, mapping) {
    if (!CSV_MAPPER_NO_SERVER) {
      await this.getToken();
      const response = await this.service.post(
        `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.assets.saveMapping
        }?data_type=${data_type}&asset_id=${asset_id}`,
        mapping,
      );
      return response;
    }
  }

  async sendTemplatedErrorToSlack(rawType, rawTitle, rawPayload) {
    const type = `:bangbang: *${rawType}*`;
    const payload = Object.keys(rawPayload).map((key) => `\n*${key}:* ${rawPayload[key]}`);
    await this.sendErrorToSlack(`${type}: ${rawTitle} (${process.env.REACT_APP_ENV})\n${payload}`);
    return true;
  }

  async sendErrorToSlack(text) {
    if (process.env.REACT_APP_DEV === 'true') return false;
    if (this.errorDebouncer.isCurrentlyDebounced) return false;
    try {
      this.errorDebouncer.isCurrentlyDebounced = true;
      await axios.post(
        config.Webhooks.errors,
        {
          text,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      setTimeout(
        () => (this.errorDebouncer.isCurrentlyDebounced = false),
        this.errorDebouncer.timeout,
      ); // eslint-disable-line
    } catch (e) {
      console.log('Failed to send message to slack');
      this.errorDebouncer.isCurrentlyDebounced = false;
    }
    return true;
  }

  async getTopMembers(communityId, members_type) {
    try {
      if (communityId === undefined || members_type === undefined) {
        return false;
      }

      let params = `?community_id=${communityId}`;
      params = `${params}&members_type=${members_type}`;

      await this.getToken();

      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.home.topMembers + params;
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getTopMembers: ', error);
      return undefined;
    }
  }

  async getAttention(communityId) {
    try {
      if (communityId === undefined) {
        return false;
      }

      const params = `?community_id=${communityId}`;
      await this.getToken();

      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.home.attention + params;
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getAttention: ', error);
      return undefined;
    }
  }

  async getMemberDetails(memberId, segmentId) {
    try {
      await this.getToken();
      const url = [
        process.env.REACT_APP_NOVAK_BASE_URI,
        config.NovakURLs.crm.memberDetails,
        `?member_id=${memberId}`,
      ];
      if (segmentId) url.push(`&segment_id=${segmentId}`);
      const result = await this.service.get(url.join(''));
      return result.data;
    } catch (e) {
      console.log('failed to fetch user details'); // eslint-disable-line
    }
    return {};
  }

  async getMemberHistory(communityId, contactId) {
    try {
      await this.getToken();

      const url = tzipiUrlBuilder(config.TzipiURLs.crm.contact.history, { communityId, contactId });
      const result = await this.service.get(`${url}`);
      return result.data;
    } catch (e) {
      this.sendTemplatedErrorToSlack('API_ERROR', 'Failed to fetch user history', {
        communityId,
        contactId,
        message: e?.message,
        body: e?.response?.data,
      });
      // console.log('failed to fetch user activity'); // eslint-disable-line
      throw new Error('Cannot fetch user activity');
    }
  }

  async getMemberActivity(communityId, contactId, page) {
    try {
      await this.getToken();

      const url = tzipiUrlBuilder(config.TzipiURLs.crm.contact.activity, {
        communityId,
        contactId,
      });
      const result = await this.service.get(`${url}?page=${page}`);
      return result.data;
    } catch (e) {
      this.sendTemplatedErrorToSlack('API_ERROR', 'Failed to fetch user activity', {
        communityId,
        contactId,
        message: e?.message,
        body: e?.response?.data,
      });
      // console.log('failed to fetch user activity'); // eslint-disable-line
      throw new Error('Cannot fetch user activity');
    }
  }

  async getMemberSentiment(memberId) {
    try {
      await this.getToken();

      const url = `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.memberSentiment
        }?member_id=${memberId} `;
      const result = await this.service.get(url);
      return result.data;
    } catch (e) {
      console.log('failed to fetch user sentiment'); // eslint-disable-line
    }
    return {};
  }

  async getMemberInfo(member_id, segment_id, categoryName) {
    try {
      if (member_id === undefined) {
        return false;
      }

      let params = `?member_id=${member_id} `;
      if (segment_id !== undefined) {
        params = `${params}&segment_id=${segment_id}`;
      }
      if (categoryName !== undefined) {
        params = `${params}&category_name=${categoryName} `;
      }
      await this.getToken();

      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.member + params;
      const result = await this.service.get(url);
      return result.data;
    } catch (error) {
      console.log('Failed to getAttention: ', error);
      return undefined;
    }
  }

  async getCrmSegments() {
    try {
      await this.getToken();

      const url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.segment;
      const result = await this.service.get(url);
      if (result.data.segments !== undefined) {
        result.data.segments?.forEach((segment) => {
          segment.filter = JSON.parse(segment.filter);
        });
      }
      if (result.data.categories !== undefined) {
        result.data.categories = result.data.categories.filter(
          (x) => x.name === 'advocate' || x.name === 'spammer' || x.name === 'requires_attention',
        );
      }
      return result.data;
    } catch (error) {
      console.log('Failed to getCrmSegments: ', error);
      return undefined;
    }
  }

  async createSegment(name, color, superFilter, segmentId) {
    if (name === undefined || color === undefined || superFilter === null) {
      return undefined;
    }
    try {
      const body = {
        segment_name: name,
        color,
        filter: superFilter,
      };

      await this.getToken();

      let url = process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.segment;
      if (segmentId !== undefined) {
        url += `?segment_id=${segmentId} `;
      }
      const result = await this.service.post(url, body);
      return result;
    } catch (error) {
      console.log('Failed to createSegment: ', error);
    }
  }

  async deleteSegment(segmentId) {
    if (segmentId === undefined) {
      return undefined;
    }

    try {
      await this.getToken();
      const response = await this.service.delete(
        `${process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.segment
        }?segment_id=${segmentId} `,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async changeCrmReadStatus(segmentId, categoryName) {
    let body;
    if (segmentId !== undefined) {
      body = {
        type: 'segment',
        value: segmentId,
      };
    } else if (categoryName !== undefined) {
      body = {
        type: 'category',
        value: categoryName,
      };
    } else {
      body = {
        type: 'all_members',
      };
    }
    try {
      await this.getToken();
      const resp = await this.service.post(
        process.env.REACT_APP_NOVAK_BASE_URI + config.NovakURLs.crm.marking,
        body,
      );
      return resp.status;
    } catch (error) {
      console.error(error);
    }
  }

  async communityLastUpdate(communityId) {
    try {
      await this.getToken();
      const response = await this.service.get(
        tzipiUrlBuilder(config.TzipiURLs.community.lastUpdated, { communityId }),
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  async getSegmentHistory(communityId, segmentId, scrollId) {
    try {
      await this.getToken();
      const response = await this.service.get(
        `${tzipiUrlBuilder(config.TzipiURLs.crm.segments.history, {
          communityId,
          segmentId,
        })}?scroll_id=${scrollId}`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
  async markSegmentAsUnread(communityId, segmentId) {
    try {
      await this.getToken();
      await this.service.post(
        `${tzipiUrlBuilder(config.TzipiURLs.crm.segments.unread, { communityId, segmentId })}`,
      );
      return true;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}

export const NetworkManagerClass = NetworkManager;
const instance = new NetworkManager();

export default instance;
