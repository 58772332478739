import PlaybooksAllHeader from '../../../Components/Playbooks/all/header';
import PlaybooksList from '../../../Components/Playbooks/all/list';

export default function PlaybooksAll() {
  return (
    <div className="playbooks-page playbooks-page-all">
      <PlaybooksAllHeader />
      <PlaybooksList />
      <div style={{ height: '5000px' }} />
    </div>
  );
}
