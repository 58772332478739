import GridLayout from 'react-grid-layout';
import useMeasure from 'react-use-measure';

import {
  useCallback,
  useContext,
  useMemo,
} from 'react';
import gridRules from '../_data/gridRules';
import WidgetWrapper from '../widgets/wrapper';
import Widget from '../widgets/widget';

import '../styles/grid.scss';
import '../styles/resizable.scss';
import { BoardContext } from '../hooks/useBoard';
import { ReactComponent as EmptyIcon } from '../../../Assets/Images/icons/dashboards/empty-widgets.svg';
import ErrorBoundary from '../../ErrorBoundary/errorBoundary';

export default function Grid() {
  const {
    editMode: isEditting, thisBoard, setDraft, setEdittorModal, draft: { layout },
  } = useContext(BoardContext);
  const [ref, bounds] = useMeasure();
  const isEmpty = useMemo(() => !thisBoard || !thisBoard?.layout || thisBoard.layout?.length === 0, [thisBoard]);
  const onLayoutChange = useCallback((v) => {
    if (isEditting) {
      setDraft((cur) => ({
        ...cur,
        layout: v,
      }));
    }
  }, [setDraft, isEditting]);
  return (
    <div className={`board-grid ${isEmpty ? 'empty' : ''}`}>
      <div className="inner" ref={ref}>
        {isEmpty && (
          <div className="board-grid_empty">
            <EmptyIcon />
            <div className="desc">
              <span style={{ fontWeight: 600 }}>Add widgets</span>
              {' '}
              representing interesting data from your segments
            </div>
            <div onClick={() => setEdittorModal(true)} className="add-widget">
              Add widget
            </div>
          </div>
        )}
        {thisBoard && !isEmpty && (
          <GridLayout
            className="layout"
            layout={layout}
            onLayoutChange={onLayoutChange}
            width={bounds?.width || 0}
            isDraggable={isEditting}
            isResizable={isEditting}
            draggableHandle=".drag-handle"
            // resizeHandles={['s', 'w', 'e', 'n']}
            {...gridRules}
          >
            {layout && layout.map((l) => (
              <WidgetWrapper thisBoard={thisBoard} id={l.i} key={l.i.toString()}>
                <ErrorBoundary key={l.i} componentName={`WidgetWrapper (B: ${thisBoard?.id}, W: ${l.i})`}>
                  <Widget w={l.w} h={l.h} id={l.i} gridWidth={bounds.width} />
                </ErrorBoundary>
              </WidgetWrapper>
            ))}
          </GridLayout>
        )}
      </div>
    </div>
  );
}
