import { useFormikContext } from 'formik';
import { motion } from 'framer-motion';

const Switch = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div onClick={() => setFieldValue('isEnabled', !values.isEnabled)} className={`switch ${values.isEnabled ? 'on' : 'off'}`}>
      <motion.div
        className="switch-indicator"
        animate={{ x: values.isEnabled ? '20px' : '1px', y: '-50%' }}
      />
    </div>
  );
};

const ReportSwitch = () => {
  const { values } = useFormikContext();
  return (
    <div className="email-modal_form_switch">
      <Switch />
      <span>
        Timed report is
        {' '}
        <span className={`active-text ${values?.isEnabled ? 'enabled' : 'disabled'}`}>
          {values.isEnabled ? 'active' : 'inactive'}
        </span>
      </span>
    </div>
  );
};

export default ReportSwitch;
