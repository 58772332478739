import { useMemo } from 'react';
import { useQuery } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';
import { useCommunityContext } from '../../contexts/community';

export default function useTagsQuery() {
  const { communityId: commId } = useCommunityContext();
  const tags = useQuery([commId, 'tags'], () => NetworkManager.getCommunityTags(commId), {
    enabled: !!commId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 60, // 60 minutes stale time
  });
  const tagsById = useMemo(() => {
    if (tags?.data && Array.isArray(tags.data)) {
      return tags.data.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
    }
    return {};
  }, [tags]);
  return {
    tags,
    tagsById,
  };
}
