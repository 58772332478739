import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Select from '../../Select';
import { useCommunityContext } from '../../../Utils/contexts/community';
import defaultIntegrations from '../../../Data/integrations';

const filterOptions = [
  {
    key: 'name',
    label: 'Name',
    conditions: ['exact match', 'contains'],
  },
  {
    key: 'email',
    label: 'Email',
    conditions: ['exact match', 'contains'],
  },
  {
    key: 'phone',
    label: 'Phone',
    conditions: ['exact match', 'contains'],
  },
  {
    key: 'source',
    label: 'Source',
    conditions: ['exact match'],
    Template: ({ error, value, onChange }) => {
      const { assets } = useCommunityContext();
      const findImageBySourcePlatform = (intId) => {
        const found = defaultIntegrations.find((x) => x.id === intId);
        if (found) return <img width="20" height="20" alt="platform" src={found.image} />;
        return null;
      };
      return (
        <Select placeholder="Select a source" className={error ? 'has-error' : ''} value={value} onChange={onChange}>
          {Object.values(assets).map((opt) => (
            <Select.Option key={`sedict-${opt.id}`} value={opt.id}>
              {findImageBySourcePlatform(opt.integration_id)}
              {opt.name}
            </Select.Option>
          ))}
        </Select>
      );
    },
  },
  {
    key: 'platform',
    label: 'Platform',
    conditions: ['exact match'],
    Template: ({ error, value, onChange }) => {
      const { assets } = useCommunityContext();
      const platforms = useMemo(() => Object.values(assets).reduce((acc, cur) => {
        if (acc[cur.service_name + cur.type]) return acc;
        const int = defaultIntegrations.find((x) => x.id === cur.integration_id);
        acc[cur.service_name + cur.type] = {
          service_name: cur.service_name,
          service_type: cur.service_type,
          assetTag: int.assetTag,
          title: int.title,
          image: int.image,
        };
        return acc;
      }, {}), [assets]);
      return (
        <Select placeholder="Select a platform" className={error ? 'has-error' : ''} value={value} onChange={onChange}>
          {Object.values(platforms).map((opt) => (
            <Select.Option key={`sedict-${opt.assetTag}`} value={opt.service_name}>
              <img width="20" height="20" alt="platform" src={opt.image} />
              {opt.title}
            </Select.Option>
          ))}
        </Select>
      );
    },
  },
  {
    key: 'keywords',
    label: 'Keywords',
    conditions: ['exact match'],
    Template: ({ error, value, onChange }) => {
      const { dictId } = useParams();
      const { dictionaries } = useCommunityContext();
      const keywords = useMemo(() => dictionaries[dictId].keywords, [dictionaries, dictId]);
      return (
        <Select mode="multiple" className={error ? 'has-error' : ''} maxTagCount="responsive" allowClear value={value || []} onChange={onChange}>
          {keywords.map((opt) => (
            <Select.Option key={`sedict-${opt.keyword}`} value={opt.id}>
              {opt.keyword}
            </Select.Option>
          ))}
        </Select>
      );
    },
  },
  {
    key: 'sentiment',
    label: 'Sentiment',
    conditions: ['exact match'],
    Template: ({ error, value, onChange }) => (
      <Select placeholder="Select a sentiment" className={error ? 'has-error' : ''} value={value} onChange={onChange}>
        <Select.Option key="sedicts-1" value={1}>
          Negative
        </Select.Option>
        <Select.Option key="sedicts-2" value={2}>
          Neutral
        </Select.Option>
        <Select.Option key="sedicts-3" value={3}>
          Positive
        </Select.Option>
      </Select>
    ),
  },
];

export const filterOptionsByKey = filterOptions.reduce((acc, cur) => {
  acc[cur.key] = cur;
  return acc;
}, {});

export default filterOptions;
