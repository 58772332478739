import AggregationFilterSchema from './AggregationFilter';
import ContentFilterSchema from './ContentFilter';
import PropertyFilterSchema from './PropertyFilter';

const schemas = {
  property: PropertyFilterSchema,
  aggregation: AggregationFilterSchema,
  content: ContentFilterSchema,
};

export default schemas;
