/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button, Dropdown, Input, Menu,
} from 'antd';
import { useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import * as Yup from 'yup';
import chevron from '../../../../Assets/Images/icons/menuchevron-chevron-down.svg';
import infoIco from '../../../../Assets/Images/icons/info-sml.svg';
import platformColors, { platformColorsByBg } from '../../../../Data/colors';
import KeywordChip from '../../common/chip';
import checkChecked from '../../../../Assets/Images/icons/check_circle_checked.png';
import checkUnchecked from '../../../../Assets/Images/icons/check_circle.png';

import negative from '../../../../Assets/Images/icons/sentiment/negative.png';
import neutral from '../../../../Assets/Images/icons/sentiment/neutral.png';
import positive from '../../../../Assets/Images/icons/sentiment/positive.png';
import EnrichPicker from './enrichPicker';
import { enrichesByCom } from './_enriches';
import { useCommunityContext } from '../../../../Utils/contexts/community';

function Editable({
  onSelect, onFocus, onBlur, onHasValue,
}) {
  const [value, setValue] = useState('');
  const keyDownHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onSelect(value);
        setValue('');
      }
    },
    [value, onSelect],
  );
  useEffect(() => {
    onHasValue(value?.length !== 0);
  }, [value]); // eslint-disable-line
  return (
    <input
      className="keywords-editable"
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={keyDownHandler}
    />
  );
}

const fieldsSchema = [
  {
    label: 'Name',
    defaultValue: '',
    placeholder: '',
    side: 'left',
    key: 'name',
    Template: ({ field, meta }) => {
      const { setFieldValue } = useFormikContext();
      return (
        <Input value={field.value} className={meta?.error && meta?.touched ? 'error' : ''} onChange={(e) => setFieldValue('name', e.target.value)} />

      );
    },
  },
  {
    label: 'Colors',
    defaultValue: '',
    placeholder: '',
    side: 'left',
    key: 'color',
    Template: ({ field, meta }) => {
      const { setFieldValue, values } = useFormikContext();
      return (
        <div className={`color-picker ${meta?.error && meta?.touched ? 'error' : ''}`} tabIndex="0" role="button">
          <div className="color-picker_chooser">
            <Dropdown
              overlay={(
                <Menu>
                  {platformColors.map((color) => (
                    <Menu.Item onClick={() => setFieldValue('color', color.bgColor)} key={color.name}>
                      <Button
                        className="set-text-color"
                        type="text"
                        style={{
                          width: '120px',
                          background: color.bgColor,
                          color: color.textColor,
                          fontWeight: 700,
                          borderRadius: '4px',
                        }}
                      >
                        {color.name}
                      </Button>
                    </Menu.Item>
                  ))}
                </Menu>
              )}
              placement="bottomCenter"
              arrow
            >
              <Button
                className="set-text-color"
                type="text"
                style={{
                  width: '120px',
                  height: '45px',
                  fontWeight: 700,
                  color: platformColorsByBg[field.value].textColor,
                  background: field.value,
                  borderRadius: '4px',
                }}
              >
                {platformColorsByBg[field.value].name}
              </Button>
            </Dropdown>
          </div>
          <div className="color-picker_chevron">
            <img src={chevron} width="16" height="16" alt="" />
          </div>
          <div className="color-picker_info">
            <img src={infoIco} width="18" height="18" alt="" />
            <div>
              This is how your
              {' '}
              <span style={{ background: values.color, color: platformColorsByBg[values.color] }}>Keyword highlight</span>
              {' '}
              would appear on your feeds.
            </div>
          </div>
        </div>
      );
    },
  },
  {
    label: 'Keywords',
    defaultValue: [],
    placeholder: '',
    side: 'left',
    key: 'keywords',
    Template: ({ field, meta }) => {
      const { setFieldValue, values } = useFormikContext();
      const [inputHasValue, setInputHasValue] = useState(false);
      const mainBox = useRef();

      const removeWord = useCallback(
        (word) => {
          word
            && setFieldValue('keywords', [
              ...new Set([...field.value.filter((w) => w !== word)]),
            ]);
        },
        [field, setFieldValue],
      );

      const addWord = useCallback(
        (word) => {
          word
            && setFieldValue('keywords', [...new Set([...field.value, word])]);
        },
        [field, setFieldValue],
      );

      const focusEditable = useCallback(() => {
        try {
          document.querySelector('.keywords-editable').focus();
        } catch (e) { } // eslint-disable-line
      }, []);

      const editableOnFocus = useCallback(() => {
        mainBox.current.classList.add('focused');
      }, [mainBox]);

      const editableOnBlur = useCallback(() => {
        mainBox.current.classList.remove('focused');
      }, [mainBox]);

      return (
        <div
          className={`ant-input keywords ${meta?.error && meta?.touched ? 'error' : ''}`}
          ref={mainBox}
          onClick={focusEditable}
        >
          {field?.value?.length === 0 && !inputHasValue && (
            <div className="keywords-empty">
              {/* empty */}
            </div>
          )}
          <AnimatePresence>
            {values.keywords
              && values.keywords.map((tag) => (
                <KeywordChip label={tag} key={tag} onRemove={removeWord} id="new" colors={platformColorsByBg[values.color]} />
              ))}
          </AnimatePresence>
          <Editable
            onSelect={addWord}
            onHasValue={setInputHasValue}
            onFocus={editableOnFocus}
            onBlur={editableOnBlur}
          />
          <div className="btn-contain">
            <Button
              className="keywords-add"
            >
              + Add Keyword
            </Button>
          </div>
        </div>
      );
    },
  },
  {
    key: 'divider',
    label: '',
    side: 'right',
    Template: () => (
      <div className="dict-new-divider">
        <span>AI powered content enrichment</span>
        <span />
      </div>
    ),
  },
  {
    key: 'enrich',
    label: '',
    side: 'right',
    Template: ({ field: { value } }) => {
      const { setFieldValue } = useFormikContext();
      const { communityId } = useCommunityContext();
      const handleSelect = useCallback((v) => {
        if (typeof value[v] !== 'undefined') setFieldValue('enrich', {});
        else setFieldValue('enrich', { [v]: [] });
      }, [value]);
      return (
        <motion.div
          initial={{ height: 0, overflow: 'hidden' }}
          animate={{ height: 'auto', overflow: 'hidden' }}
          exit={{ height: 0 }}
          className="dict-checker_picker"
        >

          {(enrichesByCom?.[communityId] || enrichesByCom.default).map((e) => (
            <EnrichPicker data={e} key={e.key} handleSelect={handleSelect} />
          ))}

        </motion.div>
      );
    },
  },
  {
    key: 'emotions',
    label: '',
    side: 'right',
    Template: ({ field: { value } }) => {
      const { setFieldValue } = useFormikContext();
      const [expanded, setExpanded] = useState(Array.isArray(value) && value.length !== 0);
      const handleExpand = useCallback(() => {
        if ((Array.isArray(value) && value.length !== 0) || expanded) {
          // mean its expanded and value should be dismissed OR its just expanded with no value
          setFieldValue('emotions', null);
          setExpanded(false);
        } else {
          setExpanded(true);
        }
      }, [value, expanded]);
      const handleSelect = useCallback((v) => {
        if ((value || []).includes(v)) {
          setFieldValue('emotions', value.filter((x) => x !== v));
        } else {
          setFieldValue('emotions', [...(value || []), v]);
        }
      }, [value]);
      return (
        <>
          <div className="dict-checker" onClick={handleExpand}>
            <div className="dict-checker_check">
              <img src={expanded ? checkChecked : checkUnchecked} width="20" height="20" alt="check" />
            </div>
            <div className="dict-checker_info">
              <div className="dict-checker_info_title">Emotions detector</div>
              <div className="dict-checker_info_desc">Select the tone of voice you would like to discover</div>
            </div>
          </div>
          <AnimatePresence>
            {expanded && (
              <motion.div
                initial={{ height: 0, overflow: 'hidden' }}
                animate={{ height: 'auto', overflow: 'hidden' }}
                exit={{ height: 0 }}
                className="dict-checker_picker flex"
              >
                <div className={`dict-checker_picker_item item-emotion ${value?.includes(1) ? 'selected' : ''}`} onClick={() => handleSelect(1)}>
                  <div className="dict-checker_picker_item_icon">
                    <img src={negative} width="40" height="40" alt="" />
                  </div>
                </div>
                <div className={`dict-checker_picker_item item-emotion ${value?.includes(2) ? 'selected' : ''}`} onClick={() => handleSelect(2)}>
                  <div className="dict-checker_picker_item_icon">
                    <img src={neutral} width="40" height="40" alt="" />
                  </div>
                </div>
                <div className={`dict-checker_picker_item item-emotion ${value?.includes(3) ? 'selected' : ''}`} onClick={() => handleSelect(3)}>
                  <div className="dict-checker_picker_item_icon">
                    <img src={positive} width="40" height="40" alt="" />
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      );
    },
  },
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  color: Yup.string().required('Required'),
  keywords: Yup.array().notRequired(),
  enrich: Yup.object().nullable(),
  emotions: Yup.array().nullable(),
});

const formSchema = {
  fieldsSchema,
  validationSchema,
};
export default formSchema;
