import moment from 'moment';
import SkeletonLine from '../../../../Skeletons/line';

const widgetSkeletons = {
  source: {
    series: [{
      name: 'Source',
      size: '100%',
      innerSize: '73%',
      enableMouseTracking: false,
      data: Array(4).fill(null).map((_, ind) => ({
        name: `skeleton-${ind}`,
        displayName: <SkeletonLine style={{ width: '100px', height: '16px' }} />,
        color: '#c6ced2',
        y: 20,
        displayY: <SkeletonLine style={{ width: '25px', height: '16px' }} />,
      })),
    }],
  },
  sentiment: {
    series: [{
      name: 'Sentiment',
      size: '100%',
      innerSize: '73%',
      enableMouseTracking: false,
      data: [{
        name: 'Neutral',
        displayName: <SkeletonLine style={{ width: '50px', marginBottom: '5px', height: '16px' }} />,
        color: '#C7CED3',
        y: 50,
        displayY: <SkeletonLine style={{ width: '25px', height: '16px' }} />,
      },
      {
        name: 'Negative',
        displayName: <SkeletonLine style={{ width: '50px', marginBottom: '5px', height: '16px' }} />,
        color: '#C7CED3',
        y: 50,
        displayY: <SkeletonLine style={{ width: '25px', height: '16px' }} />,
      },
      {
        name: 'Positive',
        displayName: <SkeletonLine style={{ width: '50px', marginBottom: '5px', height: '16px' }} />,
        color: '#C7CED3',
        y: 50,
        displayY: <SkeletonLine style={{ width: '25px', height: '16px' }} />,
      }],
    }],
  },
  time: {
    series: [{
      name: 'Interactions count',
      data: [50, 100, 20, 35, 200, 150, 15, 115, 90, 190, 110, 40],
      color: '#C7CED3',
    }],
    categories: Array(12).fill(null).map((_, ind) => moment().subtract(ind, 'months').format('MMM YY')),
  },
  aicategories: {
    series: [{
      name: 'Count by categories',
      data: [50, 100, 20, 35, 200, 150, 15, 115, 90, 190, 110, 40],
      color: '#C7CED3',
    }],
    categories: Array(12).fill(null).map((_, ind) => `Category #${ind + 1}`),
  },
  keywords: {
    series: Array(5).fill(null).map((_, ind) => ({
      id: `skeleton-${ind}-keyword`,
      keyword: <SkeletonLine style={{ width: '25px', height: '14px' }} />,
      width: 100 - (25 * ind),
      count: <SkeletonLine style={{ width: '20px', height: '13px' }} />,
    })),
  },
};

export default widgetSkeletons;
