// list of widgets

import { runInAction, toJS } from 'mobx';
import {
  useEffect, createContext, useState, useCallback,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AnalyticsService } from '../../../config/analytics';
import dalInstance from '../../../Data/Dal';
import NetworkManager from '../../../Network/NetworkManager';

export const BoardContext = createContext({});

export default function useBoard(communityId, boardId, segmentBoard = null, onBoardCreated) { // eslint-disable-line
  const queryClient = useQueryClient();
  const [editMode, setEditMode] = useState(false);
  const [edittorModal, setEdittorModal] = useState(null);
  const [thisBoard, setThisBoard] = useState(null);

  const [draft, setDraft] = useState({
    layout: thisBoard?.layout,
  });
  // const widgetsQuery = useQuery(['board', boardId, 'widgets'], () => NetworkManager.getBoardWidgets(communityId, boardId), {
  //   enabled: false,
  //   staleTime: 1000 * 60 * 15, // 15 minutes stale time
  // });

  const refreshThisBoard = useCallback(() => {
    const b = toJS(dalInstance.boards).find((c) => c.id.toString() === boardId?.toString());
    setThisBoard(b);
    setDraft({
      layout: b?.layout,
    });
  }, [boardId]);

  const updateLayout = useMutation((layout) => NetworkManager.updateLayoutForBoard(communityId, boardId, layout || draft.layout), {
    onSuccess: (d) => {
      runInAction(() => {
        const all = toJS(dalInstance.boards);
        const b = all.find((c) => c.id.toString() === boardId?.toString());
        b.layout = d;
        dalInstance.boards = all;
        refreshThisBoard();
      });
    },
  });

  const createWidgetMutation = useMutation((d) => NetworkManager.createWidgetForBoard(communityId, boardId, d, segmentBoard), {
    onSuccess: (data) => {
      runInAction(() => { // eslint-disable-line
        const all = toJS(dalInstance.boards);
        if (data.board) { // If it's true, that means a board has been created before creating this widget (for segments board)
          all.push({
            ...data.board,
            layout: [{ i: data.config.id.toString(), ...data.layout }],
            segmentId: segmentBoard,
          });
          dalInstance.boards = all;
          if (onBoardCreated && segmentBoard) {
            onBoardCreated(data.board.id);
          }
          return true;
        }
        const b = all.find((c) => c.id.toString() === boardId?.toString());
        const newLayout = [
          { ...(data.layout || {}), i: data.config.id.toString() },
          ...b.layout.map((l) => ({
            ...l,
            y: l.y + 5,
          })),
        ];
        b.layout = newLayout;
        // b.layout.push({ ...(data.layout || {}), i: data.config.id.toString() });
        dalInstance.boards = all;
        setDraft({ layout: b.layout });
        setThisBoard(b);
        AnalyticsService.generalDashboardWidgetCreated(b.name);
        updateLayout.mutate(b.layout);
      });
      setEdittorModal(null);
    },
  });

  const updateWidgetMutation = useMutation((d) => NetworkManager.updateWidgetForBoard(communityId, boardId, d), {
    onSuccess: (data) => {
      queryClient.refetchQueries(['board', Number(boardId), 'widgets', String(data.config.id)], { exact: false });
      setEdittorModal(null);
    },
  });

  useEffect(() => {
    refreshThisBoard();
  }, [boardId]); // eslint-disable-line
  return {
    // widgetsQuery,
    thisBoard,
    isSegmentBoard: !!segmentBoard,
    updateLayout,
    draft,
    setDraft,
    editMode,
    setEditMode,
    edittorModal,
    setEdittorModal,
    refreshThisBoard,
    createWidgetMutation,
    updateWidgetMutation,
  };
}
