import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import Collapser from '../collapser';
import PlaybookLine from '../../common/playbookLine';
import PlaybookLinesHeader from '../../common/playbookLine/header';
import usePlaybooksQueries from '../../hooks/usePlaybooksQueries';
import { ReactComponent as PbsIcon } from '../../../../Assets/Images/icons/playbooks/nav-unit-playbook.svg';

const RECENT_PLAYBOOKS_LIMIT = 10;

export default function RecentPlaybooks() {
  const { playbooks } = usePlaybooksQueries();
  const history = useHistory();
  const playbooksNotShown = useMemo(() => {
    if (playbooks.data) return playbooks.data.length - RECENT_PLAYBOOKS_LIMIT;
    return 0;
  }, [playbooks.data]);
  const playbooksList = useMemo(() => {
    if (playbooks.data) return playbooks.data.slice(0, RECENT_PLAYBOOKS_LIMIT);
    return [];
  }, [playbooks.data]);
  return (
    <div className="recent-pb">
      <Collapser
        title="Recent Playbooks"
        defaultOpen
        action={(
          <Button className="lnc-lightblue-btn" onClick={(e) => { e.stopPropagation(); history.push('/dashboard/playbooks/all'); }}>
            <PbsIcon />
            All playbooks
          </Button>
        )}
      >
        <PlaybookLinesHeader />
        {playbooksList.map((p) => (
          <PlaybookLine
            key={p.id}
            pbId={p.id}
            name={p.name}
            color={p.color}
            createdDate={p.created_time}
            runs={p.runs}
            actionId={p.action.possible_action_id}
            status={p.active === 1 ? 'active' : 'inactive'}
          />
        ))}
        {playbooksNotShown > 0 && (
          <div className="pb-more">
            Showing
            {' '}
            {playbooksList.length}
            {' out of '}
            {playbooks.data.length}
            {' '}
            playbooks
          </div>
        )}
      </Collapser>
    </div>
  );
}
