/* eslint-disable arrow-body-style */
import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import zendeskIcon from '../../../../../../../../Assets/Images/redesign/repo-icon-zendesk.svg';
import useZendeskFields from './additional/zendesk/useZendeskFields';
import LNCSelect from '../../../../../../../Select/LNCSelect';

const zendeskAccountUpdateCustomField = {
  id: 3,
  service_name: 'zendesk',
  service_type: 'account',
  action: 'update_custom_field',
  icon: <img src={zendeskIcon} width="24" height="24" alt="slack" />,
  validationSchema: Yup.object({
    value: Yup.string().required('Please select a value'),
    asset_id: Yup.string().required('Please select an asset'),
    field_id: Yup.number().required('Please select a field'),
  }),
  initialMetadata: {
    value: '',
    asset_id: '',
    field_id: '',
  },
  text: 'Update a field on Zendesk',
  templates: {
    ShortDisplay: () => {
      return (
        <span className="display">
          <img src={zendeskIcon} width="30" height="30" alt="slack" />
          Update a field on Zendesk
        </span>
      );
    },
    Display: () => {
      const { values: { action: { metadata } } } = useFormikContext();
      const { fields } = useZendeskFields();
      const target = useMemo(() => fields?.data?.find((f) => f.id === metadata.field_id), [fields.data, metadata]);
      return (
        <span className="autotext">
          Add
          {' '}
          <span className="highlight">
            {`${metadata.value}`}
          </span>
          {' '}
          to
          {' '}
          {target?.title}
          on Zendesk ticket
        </span>
      );
    },
    Edit: () => {
      const { fields } = useZendeskFields();
      const { values: { action: { metadata } }, setFieldValue } = useFormikContext();
      const fieldsList = useMemo(() => {
        if (fields.data) {
          return fields.data.map((f) => ({
            key: f.id,
            textLabel: f.title,
            label: f.title,
            assetId: f.asset_id,
          }));
        }
        return [];
      }, [fields.data]);
      const onChangeTags = useCallback((tags) => {
        setFieldValue('action.metadata.value', tags.join(','));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      const onChooseField = useCallback((f) => {
        setFieldValue('action.metadata.field_id', f);
        setFieldValue('action.metadata.asset_id', fieldsList.find((a) => a.key === f).assetId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fieldsList]);
      const displayVal = useMemo(() => {
        if (metadata.value) return metadata.value.split(',');
        return [];
      }, [metadata.value]);
      return (
        <span className="automation-edit-container">
          <span className="highlight then">Then</span>
          {' '}
          add
          {' '}
          <LNCSelect
            value={displayVal}
            onSelect={onChangeTags}
            placeholder="Type a tag"
            items={[]}
            isMulti="variable"
          />
          {' '}
          to field
          {' '}
          <LNCSelect
            value={metadata?.field_id}
            onSelect={onChooseField}
            isSearch
            placeholder="Select a field"
            searchPlaceholder="Find a field"
            items={fieldsList}
          />
          {' '}
          on
          {' '}
          <span className="highlight flex-align platform">
            <img src={zendeskIcon} width="16" height="16" alt="slack" />
            Zendesk ticket
          </span>
        </span>
      );
    },
  },
};

export default zendeskAccountUpdateCustomField;
