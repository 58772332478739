import {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import * as Yup from 'yup';
import { motion, AnimatePresence } from 'framer-motion';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import IconsTemplate from '../common/iconChain';
import AutomationText from '../common/textLine';
import Spinner from '../../Skeletons/spinner';
import reactors from '../_data/templates/reactors';
import triggers from '../_data/templates/triggers';
import Invocation from './invocation';

import { ReactComponent as Rabbit } from '../../../Assets/Images/icons/automation/rabbit.svg';
import { ReactComponent as Trash } from '../../../Assets/Images/icons/automation/trash.svg';
import { ReactComponent as Check } from '../../../Assets/Images/icons/automation/autocheck.svg';

import { ReactComponent as EditTrash } from '../../../Assets/Images/icons/automation/edit-trash.svg';
import { ReactComponent as EditPencil } from '../../../Assets/Images/icons/automation/edit-pencil.svg';
import Switch from './switch';
import { AutomationsContext } from '../hooks/useAutomations';
import RuleActions from './actions';

const getTemplateDetails = (type, obj) => {
  if (type === 'trigger') {
    const tri = Object.values(triggers).find((t) => obj.triggerTemplateId === t.id);
    return {
      abbr: `${tri.relation}.${tri.entity}.${tri.action}`,
      ...tri,
    };
  }
  const rea = Object.values(reactors).find((t) => obj.reactorTemplateId === t.id);
  return {
    abbr: `${rea.service_name}.${rea.service_type}.${rea.action}`,
    ...rea,
  };
};

export default function AutomationRule({
  rule, isNew, onCreate, defaultIsEdit, onClose, onTest,
}) {
  const [editMode, setEditMode] = useState(defaultIsEdit ?? false);
  const [isDeleting, setDeleting] = useState(false);
  const { createWorkflowMutation, deleteWorkflowMutation, updateWorkflowMutation } = useContext(AutomationsContext);
  const [isAfterEdit, setAfterEdit] = useState(false);
  const possibleTrigger = useMemo(() => getTemplateDetails('trigger', rule.trigger), [rule]);
  const possibleReactor = useMemo(() => getTemplateDetails('reactor', rule.reactor), [rule]);

  const mainClassName = useMemo(() => {
    const l = ['rule'];
    editMode && l.push('rule-edit');
    isAfterEdit && l.push('rule-after-edit');
    isDeleting && l.push('rule-is-deleting');
    isNew && l.push('rule-new');
    !rule?.isEnabled && l.push('rule-is-disabled');
    rule?.placeholder && l.push('rule-is-placeholder');
    return l.join(' ');
  }, [editMode, isNew, isAfterEdit, isDeleting, rule]);

  const validationSchema = useMemo(() => Yup.object().shape({
    trigger: Yup.object().shape({
      metadata: triggers[possibleTrigger.abbr].validationSchema,
    }),
    reactor: Yup.object().shape({
      metadata: reactors[possibleReactor.abbr].validationSchema,
    }),
  }), [possibleTrigger, possibleReactor]);

  const handleDelete = useCallback(() => {
    if (isNew) {
      onClose();
    } else {
      setDeleting(true);
      deleteWorkflowMutation.mutate(rule.id);
    }
  }, [rule, onClose, isNew, deleteWorkflowMutation]);

  useEffect(() => {
    if (!editMode) {
      setAfterEdit(true);
      const ti = setTimeout(() => {
        setAfterEdit(false);
      }, 500);
      return () => clearTimeout(ti);
    }
    return () => null;
  }, [editMode]);
  return (
    <motion.div
      className={mainClassName}
      key={rule.id}
      layout
    >
      {rule.placeholder && <rule.placeholder />}
      <Formik
        validationSchema={validationSchema}
        // validateOnBlur={false}
        // validateOnChange={false}
        initialValues={{
          id: rule?.id,
          isEnabled: isNew ? true : rule.isEnabled,
          trigger: {
            id: rule?.trigger?.id,
            triggerTemplateId: triggers[possibleTrigger.abbr].id,
            metadata: rule.trigger.metadata,
          },
          reactor: {
            id: rule?.reactor?.id,
            reactorTemplateId: reactors[possibleReactor.abbr].id,
            metadata: rule.reactor.metadata,
          },
        }}
        onSubmit={(values, actions) => {
          if (isNew) {
            createWorkflowMutation.mutate(values, {
              onSuccess: () => {
                if (values.isTest) {
                  onTest();
                  actions.setSubmitting(false);
                } else {
                  onCreate();
                }
              },
            });
          } else {
            const finalChanges = (() => {
              const f = {};
              if (rule.isEnabled !== values.isEnabled) f.isEnabled = values.isEnabled;
              if (!_.isEqual(rule.trigger.metadata, values.trigger.metadata)) f.trigger = values.trigger;
              if (!_.isEqual(rule.reactor.metadata, values.reactor.metadata)) f.reactor = values.reactor;
              f.id = values.id;
              return f;
            })();
            updateWorkflowMutation.mutate(finalChanges, {
              onSuccess: () => {
                actions.setSubmitting(false);
                setEditMode(false);
              },
            });
          }
        }}
      >
        {({
          submitForm, isSubmitting,
        }) => (
          <Form>
            <motion.div
              className="rule-header"
              layout="position"
              initial={{ minHeight: '100px' }}
              animate={{ minHeight: editMode ? '79px' : '100px' }}
            >
              <motion.div animate={{ flexBasis: editMode ? '160px' : '200px' }} className="rule-header_icons">
                <IconsTemplate triggerAbbr={possibleTrigger.abbr} reactorAbbr={possibleReactor.abbr} />
              </motion.div>
              <div className="rule-header_title">
                {editMode ? (
                  <div className="autotext">
                    {isNew ? 'New rule' : 'Edit rule'}
                  </div>
                ) : (
                  <AutomationText
                    triggerAbbr={possibleTrigger.abbr}
                    reactorAbbr={possibleReactor.abbr}
                    triggerMetadata={rule.trigger.metadata}
                    reactorMetadata={rule.reactor.metadata}
                  />
                )}
              </div>
              <div className="rule-header_actions">
                {!isNew && (
                  <div className="rule-header_actions_edit">
                    <div className="actions_edit_item" onClick={handleDelete}>
                      {isDeleting ? (
                        <Spinner style={{ borderColor: '#0084ff' }} />
                      ) : (
                        <EditTrash />
                      )}
                    </div>

                    <div onClick={() => setEditMode(true)} className="actions_edit_item">
                      <EditPencil />
                    </div>

                  </div>
                )}
                {!isNew && (
                  <div className="rule-header_actions_history">
                    <Invocation timestamps={rule.invocations.timestamps} total={rule.invocations.total} />
                  </div>
                )}
                {!isNew && (
                  <div className="rule-header_actions_status">
                    {editMode ? (
                      <Switch />
                    ) : (
                      <div className={`smalltag ${rule.isEnabled ? 'enabled' : 'disabled'}`} onClick={() => setEditMode(!editMode)}>
                        {rule.isEnabled ? 'ACTIVE' : 'DISABLED'}
                      </div>
                    )}
                  </div>
                )}
                <AnimatePresence>
                  {editMode && (
                    <motion.div
                      className="actions"
                      transition={{ type: 'tween', duration: 0.3 }}
                      initial={{ width: 0, overflow: 'hidden' }}
                      animate={{ width: 'auto', overflow: 'hidden' }}
                      exit={{ width: 0, overflow: 'hidden' }}
                    >
                      {/* {!isNew && (
                        <div className="action-item">
                          <Rabbit />
                        </div>
                      )} */}
                      <div onClick={handleDelete} className={`action-item ${isDeleting ? 'hoverDisabled' : ''}`}>
                        {isDeleting ? (
                          <Spinner style={{ borderColor: '#0084ff' }} />
                        ) : (
                          <Trash />
                        )}
                      </div>
                      {!isNew && (
                        <div className={`action-item action-item_check ${isSubmitting ? 'isSubmitting' : ''}`} onClick={submitForm}>
                          {isSubmitting ? (
                            <Spinner
                              style={{
                                borderColor: 'white',
                                zIndex: 2,
                              }}
                            />
                          ) : (
                            <Check />
                          )}
                        </div>
                      )}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
            <AnimatePresence>
              {editMode && (
                <motion.div
                  className="rule-content"
                  initial={{ overflow: 'hidden', opacity: 0, height: 0 }}
                  animate={{
                    height: 'auto', opacity: 1, overflow: 'visible',
                  }}
                  exit={{
                    height: 0, opacity: 0, overflow: 'hidden',
                  }}
                >
                  <div className="rule-content_inner">
                    <AutomationText
                      triggerAbbr={possibleTrigger.abbr}
                      reactorAbbr={possibleReactor.abbr}
                      isEditMode
                    />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div id={`additional_portal_${rule.id || 'edit'}`} />
            {isNew && (
              <RuleActions />
            )}
          </Form>
        )}
      </Formik>
    </motion.div>
  );
}
