import { runInAction, toJS } from 'mobx';
import {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AnalyticsService } from '../../../config/analytics';
import dalInstance from '../../../Data/Dal';
import NetworkManager from '../../../Network/NetworkManager';

export const DashboardsContext = createContext(null);

export default function useDashboards(commId, current) {
  const [selectedBoard, setBoard] = useState(null);
  const queryClient = useQueryClient();
  // const [allBoards, setAllBoards] = useState(current);
  const match = useRouteMatch('/dashboard/boards/:boardId');
  const history = useHistory();
  const thisBoard = useMemo(() => current?.find((d) => d.id.toString() === selectedBoard?.toString()), [current, selectedBoard]);

  useEffect(() => {
    if (match && match?.params?.boardId) setBoard(match.params.boardId);
    else setBoard(null);
  }, [match]);

  const createMutation = useMutation((d) => NetworkManager.createBoard(commId, d), {
    onSuccess: (d) => {
      // setAllBoards((cur) => [...cur, d]);
      runInAction(() => {
        const brds = toJS(dalInstance.boards);
        brds.push(d);
        dalInstance.boards = brds;
      });
      history.push(`/dashboard/boards/${d.id}`);
      AnalyticsService.generalDashboardCreated(d.name);
      queryClient.refetchQueries(['folders', 'boards']);
    },
  });

  const updateMutation = useMutation((d) => NetworkManager.updateBoard(commId, d.id, d), {
    onSuccess: (data) => {
      runInAction(() => {
        const all = toJS(dalInstance.boards);
        const b = all.find((bo) => bo.id.toString() === data.id.toString());
        Object.assign(b, {
          ...data,
          id: b.id,
        });
        dalInstance.boards = all;
      });
    },
  });

  const deleteMutation = useMutation((d) => NetworkManager.deleteBoard(commId, d.id), {
    onSuccess: (data) => {
      runInAction(() => {
        const all = toJS(dalInstance.boards);
        dalInstance.boards = all.filter((bo) => bo.id.toString() !== data.deleted.toString());
        if (thisBoard.id.toString() === data.deleted.toString()) {
          if (all[0]) {
            history.push(`/dashboard/boards/${all[0].id}`);
          }
        }
      });
    },
  });

  return {
    data: current || [],
    thisBoard,
    createMutation,
    selectedBoard,
    updateMutation,
    deleteMutation,
    setBoard,
  };
}
