import { useQuery } from 'react-query';
import { useCommunityContext } from '../../../Utils/contexts/community';
import NetworkManager from '../../../Network/NetworkManager';

export default function useInteractionQueries(interactionId) {
  const { communityId } = useCommunityContext();
  const interaction = useQuery([communityId, 'interactions', interactionId], () => NetworkManager.getQueryInteraction(communityId, interactionId), {
    enabled: !!communityId,
    staleTime: Infinity,
  });

  return {
    interaction,
  };
}
