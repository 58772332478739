import plus from '../../../Assets/Images/icons/hero-add.svg';
import play from '../../../Assets/Images/icons/icon-20-x-20-ic-20-play-circle.svg';
import { ReactComponent as Tubes } from '../../../Assets/Images/icons/hero-bg.svg';

export default function AutomationHero() {
  return (
    <div className="hero">
      <div className="hero-images">
        <Tubes />
        <Tubes />
      </div>
      <div className="hero-content">
        <div className="hero-title">
          <img src={plus} width="34" height="34" alt="" />
          <span>Add new automation rule</span>
        </div>
        <div className="hero-desc">
          When
          {' '}
          <span>Something</span>
          {' '}
          happens do something
          {' '}
          <span>Great</span>
        </div>
        <div className="hero-learn">
          <img src={play} width="20" height="20" alt="" />
          <span>Learn more</span>
        </div>
      </div>
    </div>
  );
}
