import { observer } from 'mobx-react';
import moment from 'moment';
import { forwardRef, useMemo } from 'react';
import dalInstance from '../../../Data/Dal';
import useWidget, { WidgetContext } from '../hooks/useWidget';
import WidgetHeader from './header';

const WidgetWrapper = forwardRef((props, ref) => {
  const widget = useWidget(dalInstance.communities?.[0]?.id, props.thisBoard?.id, props.thisBoard?.segmentId, props.id);
  const { widgetQuery: { data } } = widget;
  const timerange = useMemo(() => {
    if (!data || !data?.config || !data?.config.timerange) return '';
    return `${moment().subtract(data.config.timerange, 'days').format('Do MMM')} - ${moment().format('Do MMM YYYY')}`;
  }, [data]);
  return (
    <div ref={ref} {...({ ...props, thisBoard: undefined })}>
      <WidgetContext.Provider value={widget}>
        <div className="widget-wrapper">
          <WidgetHeader />
          <div className="widget-wrapper_content">
            {timerange ? <div className="metadata">{timerange}</div> : <div style={{ height: '15px' }} />}
            {props.children}
          </div>
        </div>
      </WidgetContext.Provider>
    </div>
  );
});

export default observer(WidgetWrapper);
