import { Col } from 'antd';
import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import lastUpdatedIcon from '../../../Assets/Images/icons/ui-icon-processing-processing-green.svg';
import MiniSpinner from '../Drawer/comps/common/miniSpinner';
import useLastUpdate from '../../../Utils/community/useLastUpdate';

export default function LastUpdated() {
  const [isHover, setHover] = useState(false);
  // const community = useContext(CommunityContext);
  const { communityLastUpdate } = useLastUpdate();

  return (
    <>
      {(communityLastUpdate.isLoading || !!communityLastUpdate.data) && (
        <Col onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
          <div>{communityLastUpdate.isLoading ? <MiniSpinner /> : <img src={lastUpdatedIcon} width="25" height="25" alt="" />}</div>
          <AnimatePresence>
            {isHover && (
              <motion.span
                className="lastupdated-text"
                style={{ marginLeft: '5px', lineHeight: 1, whiteSpace: 'nowrap' }}
                exit={{ width: 0, opacity: 0, overflow: 'hidden' }}
                animate={{ width: 'auto', opacity: 1, overflow: 'hidden' }}
                initial={{ width: 0, opacity: 0, overflow: 'hidden' }}
                transition={{ type: 'linear', duration: 0.4 }}
              >
                {communityLastUpdate?.data ? `Last updated ${communityLastUpdate.data}` : ''}
              </motion.span>
            )}
          </AnimatePresence>
        </Col>
      )}
    </>
  );
}
