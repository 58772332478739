import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import QueryIcon from '../../../Assets/Images/icons/query-icon';
import EntitySidebar from '../../../Components/EntitySidebar';
import { platformColorsByBg } from '../../../Data/colors';
import { useCommunityContext } from '../../../Utils/contexts/community';
import kFormatter from '../../../Utils/funcs/kFormatter';
import useQueriesQueries from '../../../Components/Queries/hooks/useQueriesQueries';
import useQueriesMutations from '../../../Components/Queries/hooks/useQueriesMutations';
import ErrorBoundary from '../../../Components/ErrorBoundary/errorBoundary';

export default function QueriesSidebar() {
  const { communityId } = useCommunityContext();
  const history = useHistory();
  const { queries } = useQueriesQueries();
  const { deleteQuery } = useQueriesMutations();
  const sidebarList = useMemo(() => {
    if (queries.data) {
      return queries.data.map((q) => ({
        id: q.id,
        title: q.name,
        info: (
          <div className="info_counts">
            <span>{kFormatter(q.count)}</span>
          </div>
        ),
        icon: (
          <div className="query-icon-wrapper">
            <QueryIcon color={platformColorsByBg?.[q.color]?.textColor} />
          </div>
        ),
        onClick: () => {
          history.push(`/dashboard/queries/${q.id}`);
        },
        onEditClick: () => {
          history.push(`/dashboard/queries/${q.id}/edit`);
        },
      }));
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries.data]);
  const selected = useMemo(() => {
    const reg = /queries\/(\d+)/g;
    const matches = reg.exec(history.location.pathname);
    if (matches) {
      return matches?.[1] || undefined;
    } return undefined;
  }, [history.location]);
  return (
    <>
      <ErrorBoundary dimensions={[300, '100%']} componentName="EntitySidebar-Queries">
        <EntitySidebar
          list={sidebarList}
          isEntitiesLoading={queries.isLoading}
          onCreateClick={() => history.push('/dashboard/queries/new')}
          // customEntity={ }
          entity={{ singularName: 'query', pluralName: 'queries', apiName: 'queries' }}
          communityId={communityId}
          deleteMutation={deleteQuery}
          shouldShowDeleteConfirm={{ title: 'Delete query?', desc: 'Playbooks based on this query will be deleted as well' }}
          selected={selected}
          injectHeader={<div style={{ height: '10px' }} />}
          // onCreateClick={() => setBoardModal(true)}
          title="Queries"
        />
      </ErrorBoundary>
    </>
  );
}
