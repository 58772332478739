import { Layout } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';
import DictionariesSidebar from '../../Components/Dictionaries/layout/sidebar/wrapper';
import { useCommunityContext } from '../../Utils/contexts/community';
import '../../Components/Dictionaries/style/index.scss';
import DictionariesModal from '../../Components/Dictionaries/new/modal';
import DictionaryPage from '../../Components/Dictionaries/layout/dictionary';
import { DictionaryContext } from '../../Components/Dictionaries/context/dictionary.context';

// import ErrorBoundary from '../../Components/ErrorBoundary';

export default function DictionaryView() {
  const match = useRouteMatch('/dashboard/data/:dictId');
  const [dictModal, setDictModal] = useState(null);
  const [superFilterVisible, setSuperFilterVisible] = useState(false);
  const { dictionaries } = useCommunityContext();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    if (!match) {
      const dictIds = Object.keys(dictionaries);
      if (dictIds.length > 0) history.push(`${url}/${dictIds[0]}`);
    }
  }, [match]); // eslint-disable-line
  const contextValues = useMemo(() => ({
    setDictModal, superFilterVisible, setSuperFilterVisible, dictId: match?.params?.dictId,
  }), [superFilterVisible, match]);
  return (
    <DictionaryContext.Provider value={contextValues}>
      <Layout style={{ flexDirection: 'row' }}>
        <AnimatePresence>
          {dictModal && <DictionariesModal onClose={() => setDictModal(null)} initialValues={dictModal} />}
        </AnimatePresence>
        <DictionariesSidebar selected={match?.params?.dictId} />
        <Switch>
          <Route exact path={path}>
            empty
          </Route>
          <Route path={`${path}/:dictId`}>
            <DictionaryPage />
          </Route>
        </Switch>
      </Layout>
    </DictionaryContext.Provider>
  );
}
