import { useMutation } from 'react-query';
import dalInstance from '../../../Data/Dal';
import NetworkManager from '../../../Network/NetworkManager';
import { useCommunityContext } from '../../contexts/community';

export default function useDictionaryMutations() {
  const { communityId } = useCommunityContext();

  const create = useMutation((d) => NetworkManager.createDictionary(communityId, d));

  const update = useMutation((d) => NetworkManager.updateDictionary(communityId, d.id, d), {
    onSuccess: (d, diff) => {
      dalInstance.fetchDictionaries(diff);
    },
  });
  const deleteDictionary = useMutation(({ id }) => NetworkManager.deleteDictionary(communityId, id), {
    onSuccess: ({ id }) => {
      dalInstance.hideDictionaryForDelete(id);
    },
  });
  return {
    update,
    create,
    deleteDictionary,
  };
}
