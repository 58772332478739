/* eslint-disable camelcase */
import _ from 'lodash';
import moment from 'moment';
import widgetSettings from './widgetSettings';

// eslint-disable-next-line
const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum erat ac justo sollicitudin, quis lacinia ligula fringilla. Pellentesque hendrerit, nisi vitae posuere condimentum, lectus urna accumsan libero, rutrum commodo mi lacus pretium erat. Phasellus pretium ultrices mi sed semper. Praesent ut tristique magna. Donec nisl tellus, sagittis ut tempus sit amet, consectetur eget erat. Sed ornare gravida lacinia. Curabitur iaculis metus purus, eget pretium est laoreet ut. Quisque tristique augue ac eros malesuada, vitae facilisis mauris sollicitudin. Mauris ac molestie nulla, vitae facilisis quam. Curabitur placerat ornare sem, in mattis purus posuere eget. Praesent non condimentum odio. Nunc aliquet, odio nec auctor congue, sapien justo dictum massa, nec fermentum massa sapien non tellus. Praesent luctus eros et nunc pretium hendrerit. In consequat et eros nec interdum. Ut neque dui, maximus id elit ac, consequat pretium tellus. Nullam vel accumsan lorem.';

const lines = lorem.split(/[,\. ]+/g);
const loremData = lines.reduce((arr, word) => {
  let obj = arr.find((v) => v.name === word);
  if (obj) {
    obj.weight += 1;
  } else {
    obj = {
      name: word,
      weight: 1,
    };
    arr.push(obj);
  }
  return arr;
}, []);

const dimensionOptions = {
  segment: ['Segment 1', 'Segment 2', 'Segment 3', 'Segment 4', 'Segment 5'],
  sentiment: ['Happy', 'Neutral', 'Sad'],
  platform: ['Facebook Group', 'Slack workspace', 'Salesforce', 'Zendesk'],
  tag: ['Happy customer', 'At risk', 'Premium user'],
  source: ['Source #1', 'Source #2', 'Source #3', 'Source #4', 'Source #5'],
  dictionary: ['My dictionary #1', 'My dictionary #2', 'My dictionary #3', 'My dictionary #4'],
  custom_field: ['Returning customer', 'VIP Customer', 'Needs an upgrade'],
  custom_field_time: ['Jun 14', 'May 14', 'Apr 14', 'Mar 14'],
  assigned_employee: ['John', 'Bridgett', 'Carol', 'Michael'],
  title: ['CTO', 'CEO', 'Developer', 'Customer Success'],
  time: ['Jun 14', 'May 14', 'Apr 14', 'Mar 14'],
  sentiment_time: ['Jun 14', 'May 14', 'Apr 14', 'Mar 14'],
  source_time: ['Jun 14', 'May 14', 'Apr 14', 'Mar 14'],
  segment_time: ['Jun 14', 'May 14', 'Apr 14', 'Mar 14'],
  action_name: ['Logged in', 'Upgraded to premium', 'Filled a ticket'],
};

const timespans = {
  daily: Array(30).fill(null).map((d, ind) => moment().subtract(ind, 'days').format('Do MMM')),
  weekly: Array(16).fill(null).map((d, ind) => moment().subtract(ind, 'weeks').format('Do MMM')),
  monthly: Array(12).fill(null).map((d, ind) => moment().subtract(ind, 'months').format('Do MMM')),
  quarterly: Array(4).fill(null).map((d, ind) => `Q${moment().subtract(ind * 3, 'months').quarter()} ${moment().subtract(ind * 3, 'months').format('YYYY')}`),
  yearly: Array(4).fill(null).map((d, ind) => moment().subtract(ind, 'years').format('YYYY')),
};

const measureOptions = ['contacts', 'accounts', 'interactions', 'actions', 'sources'].reduce((acc, cur) => ({
  ...acc,
  [cur]: {
    count: ({ totalLength }) => Array(totalLength).fill(null).map(() => Math.floor(Math.random() * 1000)),
    sum: ({ totalLength }) => Array(totalLength).fill(null).map(() => Math.floor(Math.random() * 1000)),
  },
}), {});

const dataMapper = ({ type, measureY }, options) => (c, ind) => ({
  name: c,
  y: measureOptions[measureY].count({ totalLength: options.xAxis.categories.length })[ind],
});

const seriesMapper = (values, options) => {
  if (values.type === 'wordcloud') {
    return [{
      name: 'Occurances',
      type: 'wordcloud',
      data: loremData,
    }];
  }
  return [{
    name: 'Count',
    data: options.xAxis.categories.map(dataMapper(values, options)),
  }];
};

const previewMaker = (values) => {
  const {
    type, measureY, measureFunc, dimension, timespan, metadata,
  } = values;
  if (!type || !measureY || !dimension) return null;
  const options = {
    chart: {
      type: type === 'wordcloud' ? 'pie' : type,
    },
  };
  if (type !== 'wordcloud') {
    options.xAxis = {
      categories: (timespan ? timespans[timespan] : dimensionOptions[dimension]),
    };
  }
  options.series = seriesMapper(values, options);
  return _.merge(options, widgetSettings({ w: 724, gridWidth: 12 }));
};

export default previewMaker;
