import { useState } from 'react';
import QuickActionsCategories from './categories';
import QuickActionsExamples from './examples';

export default function QuickActions({ setPhase }) {
  const [category, setCategory] = useState('all');
  return (
    <div className="quick-actions">
      <div className="quick-actions_title">Quick Actions</div>
      <QuickActionsCategories category={category} setCategory={setCategory} />
      <QuickActionsExamples onChoose={() => setPhase(2)} category={category} />
    </div>
  );
}
