import PlaybooksEditorFlow from '../../../Components/Playbooks/new/flow';
import PlaybooksNewHeader from '../../../Components/Playbooks/new/header';

export default function PlaybooksEdit() {
  return (
    <div className="playbooks-page playbooks-page-edit">
      <PlaybooksNewHeader />
      <PlaybooksEditorFlow />
    </div>
  );
}
