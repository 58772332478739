import { observer } from 'mobx-react';
import salesforceIcon from '../../../../../Assets/Images/icons/repo-icon-salesforce.svg';

const salesforceAccountEditContactSentimentReactor = {
  id: 3,
  service_name: 'salesforce',
  service_type: 'account',
  action: 'edit_contact_sentiments',
  acceptedMetadata: [],
  validationSchema: null,
  templates: {
    ShortDisplay: observer(() => (
      <span>
        update contact
        {' '}
        <span className="highlight">
          Sentiment
        </span>
        {' '}
        on
        {' '}
        <span className="highlight flex-align platform">
          <img src={salesforceIcon} width="16" height="16" alt="salesforce" />
          Salesforce
        </span>
      </span>
    )),
    Edit: observer(() => (
      <span>
        <span className="highlight then">Then</span>
        {' '}
        update contact
        {' '}
        <span className="highlight">
          Sentiment
        </span>
        {' '}
        on
        {' '}
        <span className="highlight flex-align platform">
          <img src={salesforceIcon} width="16" height="16" alt="salesforce" />
          Salesforce
        </span>
      </span>
    )),
  },
};

export default salesforceAccountEditContactSentimentReactor;
