import { motion } from 'framer-motion';

export default function AdditionalMetadataWrapper({ Component }) {
  return (
    <motion.div
      initial={{ height: 0, overflow: 'hidden' }}
      animate={{ height: 'auto' }}
      className="rule-additional"
    >
      <div className="rule-additional_inner">
        {Component}
      </div>
    </motion.div>
  );
}
