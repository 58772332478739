import PlaybookLine from '../common/playbookLine';
import PlaybookLinesHeader from '../common/playbookLine/header';
import usePlaybooksQueries from '../hooks/usePlaybooksQueries';

export default function PlaybooksList() {
  const { playbooks } = usePlaybooksQueries();
  return (
    <div className="playbooks-list">
      <PlaybookLinesHeader />
      {playbooks.data && playbooks.data.map((p) => (
        <PlaybookLine
          key={p.id}
          pbId={p.id}
          name={p.name}
          color={p.color}
          createdDate={p.created_time}
          runs={p.runs}
          actionId={p.action.possible_action_id}
          status={p.active === 1 ? 'active' : 'inactive'}
        />
      ))}
    </div>
  );
}
