import { ReactComponent as PieImage } from '../../../Assets/Images/icons/dashboards/Donut-chart-big-ico.svg';
import { ReactComponent as BarImage } from '../../../Assets/Images/icons/dashboards/Horizontal-Bar-chart-big-ico.svg';
import { ReactComponent as ColumnImage } from '../../../Assets/Images/icons/dashboards/Vertical-Bar-chart-big-ico.svg';
import { ReactComponent as SColumnImage } from '../../../Assets/Images/icons/dashboards/Vertical-stacked-Bar-chart-big-ico.svg';
import { ReactComponent as TagImage } from '../../../Assets/Images/icons/dashboards/Tag-cloud-chart-big-ico.svg';

import { ReactComponent as PieIcon } from '../../../Assets/Images/icons/dashboards/pie-ico.svg';
import { ReactComponent as BarIcon } from '../../../Assets/Images/icons/dashboards/bar-ico.svg';
import { ReactComponent as ColumnIcon } from '../../../Assets/Images/icons/dashboards/column-ico.svg';
import { ReactComponent as SColumnIcon } from '../../../Assets/Images/icons/dashboards/scolumn-ico.svg';
import { ReactComponent as TagIcon } from '../../../Assets/Images/icons/dashboards/word-ico.svg';

const chartTypes = [
  {
    type: 'pie',
    text: 'Pie Chart',
    image: <PieImage />,
    icon: <PieIcon />,
  },
  {
    type: 'bar',
    text: 'Bar Chart',
    image: <BarImage />,
    icon: <BarIcon />,
  },
  {
    type: 'column',
    text: 'Column Chart',
    image: <ColumnImage />,
    icon: <ColumnIcon />,
  },
  {
    type: 'stackedcolumn',
    text: 'Stacked Column Chart',
    disabled: true,
    image: <SColumnImage />,
    icon: <SColumnIcon />,
  },
  {
    type: 'wordcloud',
    text: 'Word Cloud',
    image: <TagImage />,
    icon: <TagIcon />,
  },
];

export default chartTypes;
