import { Button } from 'antd';
import { motion } from 'framer-motion';
import { toJS } from 'mobx';
import { useMemo } from 'react';
import moment from 'moment';
import QuickActions from '../quickActions';
import dalInstance from '../../../../Data/Dal';

export default function QueryNewInitialPhase({ setPhase }) {
  const userName = toJS(dalInstance).user?.first_name ?? '';
  const greeting = useMemo(() => {
    const hour = moment().hour();
    if (hour >= 6 && hour < 12) return 'Good morning';
    if (hour >= 12 && hour < 15) return 'Good afternoon';
    if (hour >= 15 && hour < 20) return 'Good evening';
    return 'Good night';
  }, []);
  return (
    <div className="queries-view_inner phase-1">
      <div className="welcome-title">
        {greeting}
        ,
        {' '}
        {userName}
      </div>
      <motion.div className="queries-header" layoutId="queries-header" layout="position">
        <motion.div className="queries-header_icon" layoutId="queries-header_icon">
          <div className="queries-header_icon_inner" />
        </motion.div>
        <motion.div
          className="queries-header_text"
          layout="position"
          layoutId="queries-header_text"
        >
          <div>Create new query</div>
          <div>
            Discover customer
            {' '}
            <span className="text-bold">insights</span>
            , take the right
            {' '}
            <span className="text-bold">actions</span>
            .
          </div>
        </motion.div>
        <div className="queries-header_buttons">
          <Button className="lnc-blue-btn" onClick={() => setPhase(2)}>
            Create new
          </Button>
        </div>
      </motion.div>
      <QuickActions setPhase={setPhase} />
    </div>
  );
}
