import './styles.scss';

export default function GhostButton({
  onClick, icon, text, styles,
}) {
  return (
    <div className="ghost-btn" style={styles} onClick={onClick}>
      <div className="ghost-btn_icon">
        {icon}
      </div>
      {text && (
        <div className="ghost-btn_text">
          {text}
        </div>
      )}
    </div>
  );
}
