import useDictionaryQueries from '../../../Utils/community/dictionaries/useDictionaryQueries';
import DictionaryWidgetKeywords from './types/keyword';
import DictionaryWidgetSentiment from './types/sentiment';
import DictionaryWidgetSource from './types/source';
import DictionaryWidgetTime from './types/time';

export default function WidgetsPage({ dictId }) {
  const { widgets } = useDictionaryQueries(dictId);
  return (
    <div className="dictionary-widgets">
      <div>
        <DictionaryWidgetSource query={widgets} />
        <DictionaryWidgetSentiment query={widgets} />
      </div>
      <div>
        <DictionaryWidgetKeywords query={widgets} />
        <DictionaryWidgetTime query={widgets} />
      </div>
    </div>
  );
}
