import { motion } from 'framer-motion';
import { QRCodeSVG } from 'qrcode.react';
import { Field, useFormikContext } from 'formik';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Button, Input, Select } from 'antd';
import countryCodes from '../../../Data/raw/countryCodes.json';
import AmplifyManager from '../../../Network/AmplifyManager';

import verifyEmailSchemas from '../../OnboardingNew/steps/verify/_fieldsSchema';

const { Option } = Select;

export default function VerifyStep({ context, email }) {
  const { errors } = useFormikContext();
  const [values, setValues] = useState({
    country: 'IL',
    phone: '',
    totpUrl: null,
    totpCode: '',
  });
  const [showCode, setShowCode] = useState(false);
  const [innerStep, setInnerStep] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const phoneNumber = useMemo(() => {
    if (values.country && values.phone) {
      const c = countryCodes.find((con) => con.key === values.country);
      return `+${c.code}${values.phone}`;
    }
    return '';
  }, [values]);
  const onPhoneSubmit = useCallback(async () => {
    setLoading(true);
    await AmplifyManager.addAttributes({ phone_number: phoneNumber });
    await AmplifyManager.setMFA('SMS');
    setInnerStep(1);
  }, [phoneNumber]);

  const setupTOTP = useCallback(async () => {
    const code = await AmplifyManager.setupTOTP();
    // await AmplifyManager.setMFA('TOTP');
    const str = `otpauth://totp/${email}?secret=${code}&issuer=LoudNClear`;
    setValues((cur) => ({ ...cur, totpUrl: str, totpCode: code }));
  }, [email]);

  useEffect(() => {
    if (context.selected === 'TOTP') {
      setupTOTP();
    }
  }, [context.selected, setupTOTP]);
  return (
    <motion.div
      key="verify-step"
      initial={{ opacity: 0, x: '100%' }}
      animate={{
        opacity: 1, x: '0%', transition: { type: 'tween', duration: 0.25 },
      }}
      exit={{ opacity: 0, x: '-100%', transition: { type: 'tween', duration: 0.25 } }}
      className="mfa-modal_step verify-step"
    >
      {context.selected === 'SMS' ? (
        <div>
          <div className="smalltitle">Enter your phone number to continue</div>
          <div className="verify-step_phonegroup">
            <Select dropdownMatchSelectWidth={200} dropdownClassName="country-dropdown" className="country-select" value={values.country} onChange={(v) => setValues({ ...values, country: v })}>
              {countryCodes.map((c) => (
                <Option key={c.key} className="country-option" value={c.key}>
                  <div>
                    <img src={`https://flagcdn.com/16x12/${c.key.toLowerCase()}.png`} width="16" height="12" alt={c.key} />
                  </div>
                  <div>
                    {c.name}
                  </div>
                  <div>
                    {`(+${c.code})`}
                  </div>
                </Option>
              ))}
            </Select>
            <Input value={values.phone} onChange={(e) => setValues({ ...values, phone: e.target.value })} />
          </div>
          <Button onClick={onPhoneSubmit}>Continue</Button>
          {isLoading && (<div>LOADING</div>)}
          {innerStep === 1 && (
            <div style={{ marginBottom: '15px' }}>
              Verification code sent to
              {' '}
              {phoneNumber}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="smalltitle">
            You&apos;ll need to install a two-factor authentication application on your smartphone/tablet such as
            {' '}
            <a href="https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345" rel="noreferrer" target="_blank">Google Authenticator</a>
            {' '}
            (Android / iPhone / BlackBerry) or
            {' '}
            <a href="https://www.microsoft.com/en-us/security/mobile-authenticator-app" rel="noreferrer" target="_blank">Microsoft Authenticator</a>
            {' '}
            (Windows Phone)
          </div>
          <div className="steps-container">
            <div className="steps-container_step">
              <div>1. Configure the app</div>
              <p>
                Open your two-factor authentication application and add
                your LoudNClear account by scanning the QR code.
              </p>
              <div className="totp-qr">
                <div className="totp-qr_image">
                  {values.totpUrl && (
                    <QRCodeSVG value={values.totpUrl} />
                  )}
                </div>
                <div className="totp-qr_manual">
                  If you can&apos;t use a QR code,
                  {' '}
                  <span onClick={() => setShowCode(true)}>enter this text code</span>
                </div>
                <div className="totp-qr_code">
                  {showCode && values.totpCode}
                </div>
              </div>
            </div>
            <div className="steps-container_step">
              <div>2. Enter the 6-digit code</div>
              <div className="pin-inputs">
                {verifyEmailSchemas.fieldsSchema.map((f) => (
                  <Field name={f.key}>
                    {({ field }) => (
                      <f.Template key={f.key} field={field} schema={f} />
                    )}
                  </Field>
                ))}
              </div>
            </div>
          </div>

          <div className="pin-inputs_error">
            {errors?.pin}
          </div>
        </div>
      )}
    </motion.div>
  );
}
