/* eslint-disable jsx-a11y/alt-text */
import LNCSelect from '../../../../Select/LNCSelect';
import PartWrapper from '../common/partWrapper';

export default function FieldPart({ name, value, availableFields, onSelect }) {
  return (
    <PartWrapper partName="field" name={name}>
      <LNCSelect
        value={value}
        onSelect={onSelect}
        isSearch
        placeholder="Select field"
        searchPlaceholder="Find a field"
        items={availableFields}
      />
    </PartWrapper>
  );
}
