import GhostButton from '../../../../../Components/Buttons/ghostButton';
import DropdownMenu from '../../../../../Components/DropdownMenu';
import { ReactComponent as CalendarIcon } from '../../../../../Assets/Images/icons/date-small.svg';

const IntervalMenu = ({ value, onChange }) => {
  const menu = [
    {
      key: 'weekly',
      label: 'Weekly',
      isSelected: value === 'weekly',
      onClick: () => onChange('weekly'),
    },
    {
      key: 'monthly',
      label: 'Monthly',
      isSelected: value === 'monthly',
      onClick: () => onChange('monthly'),
    },
  ];
  return (
    <DropdownMenu
      button={<GhostButton styles={{ padding: '0 2px', height: '30px' }} icon={<CalendarIcon />} />}
      items={menu}
    />
  );
};

export default IntervalMenu;
