import { AnimatePresence, motion } from 'framer-motion';
import './style.scss';
import { ReactComponent as BackIcon } from '../../../Assets/Images/icons/back.svg';

export default function BlueModal({
  isShown,
  onClose,
  className,
  title,
  actions,
  children,
}) {
  return (
    <>
      <AnimatePresence>
        {isShown && (
          <motion.div
            className={`blue-modal ${className || ''}`}
            initial={{ y: '100%', opacity: 1, x: '-50%' }}
            animate={{ y: '0%', x: '-50%', transition: { delay: 0.3, type: 'tween', duration: 0.5 } }}
            exit={{
              y: '100%', opacity: 0, x: '-50%', transition: { type: 'tween', duration: 0.4 },
            }}
          >
            <div className="blue-modal-header">
              <div className="blue-modal-header_icon" onClick={onClose}><BackIcon /></div>
              <div className="blue-modal-header_title">{title}</div>
              <div className="blue-modal-header_actions">{actions}</div>
            </div>
            <div className="blue-modal-content">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isShown && (
          <motion.div
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="blue-modal-overlay"
          />
        )}
      </AnimatePresence>
    </>
  );
}
