import { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import MiniSpinner from '../common/miniSpinner';
import Collapse from './collapse';
import dictsIcon from '../../../../../Assets/Images/icons/top-dictionaries.png';
import useDrawerContext from '../../context/DrawerContext';
import infoIcon from '../../../../../Assets/Images/icons/info-sml.svg';

import useActivitiesFilterContext from '../../context/ActivitiesFilterContext';
import { useCommunityContext } from '../../../../../Utils/contexts/community';
import { platformColorsByBg } from '../../../../../Data/colors';

function SubContent({ isExpanded, count, isLoading }) {
  if (isLoading) {
    return (
      <MiniSpinner />
    );
  }
  if (!isExpanded) {
    return <>{count}</>;
  }
  return <></>;
}

function DictionariesCollapse() {
  const [isExpanded, setExpanded] = useState(false);
  const { activities: { data, isLoading } } = useDrawerContext();
  const { toggleDictionary, filterDictionariesById } = useActivitiesFilterContext();
  const { dictionaries } = useCommunityContext();
  const topDictionaries = useMemo(() => {
    if (data && !isLoading) {
      return data.reduce((acc, cur) => {
        if (cur.instance_type === 'interaction' && cur.dictionaries) {
          Object.keys(cur.dictionaries).forEach((dictCurId) => {
            if (!acc[dictCurId] && typeof dictionaries[dictCurId] !== 'undefined') {
              acc[dictCurId] = {
                ...dictionaries[dictCurId],
                textColor: platformColorsByBg[dictionaries[dictCurId].color].textColor,
              };
            }
          });
        }
        return acc;
      }, {});
    }
    return {};
  }, [data, isLoading, dictionaries]);
  const hasAnyFilter = useMemo(() => Object.keys(filterDictionariesById).length > 0, [filterDictionariesById]);
  return (
    <Collapse
      icon={dictsIcon}
      onExpandChange={setExpanded}
      title="Top dictionaries"
      subContent={<SubContent count={Object.keys(topDictionaries).length} isExpanded={isExpanded} isLoading={isLoading} />}
    >
      <div className="collapse-dictionaries">
        <div className="collapse-keywords_info">
          <img src={infoIcon} width="14" height="14" alt="" />
          Tap a dictionary to find relevant activities
        </div>
        <div className="collapse-dictionaries_items">
          {Object.values(topDictionaries).map((dict, ind) => (
            <motion.div
              className="collapse-dictionaries_item"
              style={{ backgroundColor: dict.color, color: dict.textColor }}
              onClick={() => toggleDictionary(dict)}
              whileHover={{ scale: 1.1, transition: { duration: 0.25 } }}
              whileTap={{ scale: 0.9, transition: { duration: 0.15 } }}
              key={dict.id}
              initial={{ scale: 0, opacity: 0 }}
              animate={{
                scale: 1,
                opacity: 1,
                filter: `grayscale(${hasAnyFilter ? (filterDictionariesById?.[dict.id] ? 0 : 1) : 0})`, // eslint-disable-line
                transition: { delay: (ind / 100).toFixed(2) },
              }}
              exit={{ opacity: 0, scale: 0, transition: { delay: (ind / 100).toFixed(2) * -1 } }}
            >
              <div>{dict.name}</div>
            </motion.div>
          ))}
        </div>
      </div>
    </Collapse>
  );
}

export default DictionariesCollapse;
