/* eslint-disable no-nested-ternary */
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import segmentIcon from '../../../../../Assets/Images/icons/segments-icon.svg';
import SkeletonLine from '../../../../../Components/Skeletons/line';
import dalInstance from '../../../../../Data/Dal';
import DrawerHandler from '../../state/handler';
import MiniSpinner from '../common/miniSpinner';
import Collapse from './collapse';

function SegmentsCollapse() {
  const [isExpanded, setExpanded] = useState(false);
  const getSegment = useCallback((id) => {
    const seg = toJS(dalInstance.getSegmentById(Number(id)));
    if (!seg) {
      return (<></>);
    }
    const bgColor = seg.color;
    const foundSet = dalInstance.setsColors.find((c) => c.bgColor === bgColor);
    const txtColor = foundSet?.textColor || '#fff';
    return (
      <div key={seg.id} className="segment" style={{ backgroundColor: bgColor, color: txtColor }}>
        <span>{seg.name}</span>
      </div>
    );
  }, []);
  if (!DrawerHandler.id) return <></>;
  return (
    <Collapse
      icon={segmentIcon}
      onExpandChange={setExpanded}
      title="Segments"
      subContent={DrawerHandler.isLoading.userDetails ? <MiniSpinner /> : (!isExpanded ? <>{DrawerHandler.data.userDetails?.segments?.length || 0}</> : <></>)}
    >
      <div className="collapse-segments">
        {(DrawerHandler.isLoading.userDetails || !DrawerHandler.data.userDetails?.segments) ? (
          <SkeletonLine style={{ width: '100px', height: '20px' }} />
        ) : (
          DrawerHandler.data.userDetails.segments.map((seg) => (
            getSegment(seg)
          ))
        )}
      </div>
    </Collapse>
  );
}

export default observer(SegmentsCollapse);
