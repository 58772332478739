import React from 'react';

import { observer } from 'mobx-react';
import './styles.scss';

class Stepper extends React.Component {
  render() {
    return (
      <div className="integration-connect-stepper-wrapper">
        <div className="integration-connect-stepper-step">
          <div className="integration-connect-stepper-circle completed">
            <p className="integration-connect-stepper-number completed">1</p>
          </div>
          <p className="integration-connect-stepper-label">Connect</p>
        </div>
        <div className="integration-connect-stepper-line-wrapper">
          <div className="integration-connect-stepper-line" />
        </div>
        <div className="integration-connect-stepper-step">
          <div
            className={
              this.props.step >= 2
                ? 'integration-connect-stepper-circle completed'
                : 'integration-connect-stepper-circle'
            }
          >
            <p
              className={
                this.props.step >= 2
                  ? 'integration-connect-stepper-number completed'
                  : 'integration-connect-stepper-number'
              }
            >
              2
            </p>
          </div>
          <p className="integration-connect-stepper-label">Add feed</p>
        </div>
        <div className="integration-connect-stepper-line-wrapper">
          <div className="integration-connect-stepper-line" />
        </div>
        <div className="integration-connect-stepper-step">
          <div
            className={
              this.props.step >= 3
                ? 'integration-connect-stepper-circle completed'
                : 'integration-connect-stepper-circle'
            }
          >
            <p
              className={
                this.props.step >= 3
                  ? 'integration-connect-stepper-number completed'
                  : 'integration-connect-stepper-number'
              }
            >
              3
            </p>
          </div>
          <p className="integration-connect-stepper-label">Done</p>
        </div>
      </div>
    );
  }
}

export default observer(Stepper);
