import { ReactComponent as Vari1 } from '../../../../Assets/Images/icons/playbooks/info-box-vari-1.svg';
import { ReactComponent as Vari2 } from '../../../../Assets/Images/icons/playbooks/info-box-vari-2.svg';
import { ReactComponent as Vari3 } from '../../../../Assets/Images/icons/playbooks/info-box-vari-3.svg';
import { ReactComponent as Vari4 } from '../../../../Assets/Images/icons/playbooks/info-box-vari-4.svg';

const imagesByVariation = {
  1: <Vari1 />,
  2: <Vari2 />,
  3: <Vari3 />,
  4: <Vari4 />,
};

// const formatter = new Intl.NumberFormat();

export default function InfoBox({ count, title, variation }) {
  return (
    <div className={`info-box vari-${variation}`}>
      <div className="info-box_image">{imagesByVariation[variation]}</div>
      <div className="info-box_count">{count}</div>
      <div className="info-box_title">{title}</div>
    </div>
  );
}
