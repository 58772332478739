import { AnimatePresence, motion } from 'framer-motion';
import { Form, Formik } from 'formik';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Button } from 'antd';
import { BoardContext } from '../hooks/useBoard';
import { ReactComponent as CloseIcon } from '../../../Assets/Images/icons/dashboards/cat-ico_x-close.svg';
import './styles.scss';
import ChooseStep from './steps/choose';

import ChartStep from './steps/chart';
import widgetSchemas from '../_data/widgetSchemas';
import metadataSchemasByDimension from '../_data/metadataSchemas';
import timeranges from '../_data/options/timeranges';

export default function WidgetEdittor() {
  const {
    edittorModal, setEdittorModal, createWidgetMutation, updateWidgetMutation, isSegmentBoard,
  } = useContext(BoardContext);
  const [step, setStep] = useState(0);

  const backClickHandler = useCallback(() => {
    if (step === 0 || typeof edittorModal === 'object') setEdittorModal(null);
    setStep(0);
  }, [edittorModal, step]); // eslint-disable-line

  useEffect(() => {
    if (!edittorModal) setStep(0);
    else if (typeof edittorModal === 'object') setStep(1);
    // console.log('r', edittorModal);
  }, [edittorModal]);
  return (
    <AnimatePresence>
      {!!edittorModal && (
        <motion.div
          className="board-edittor_overlay"
          onClick={() => setEdittorModal(false)}
          key="modal-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />
      )}
      {!!edittorModal && (
        <motion.div
          key="modal"
          initial={{ y: '100%', x: '-50%', opacity: 0 }}
          animate={{ y: '-50%', x: '-50%', opacity: 1 }}
          exit={{ y: '-100%', x: '-50%', opacity: 0 }}
          transition={{ type: 'linear', duration: 0.5 }}
          className="board-edittor"
        >
          <Formik
            initialValues={typeof edittorModal === 'object' ? {
              type: edittorModal.type,
              name: edittorModal.name,
              desc: edittorModal.desc,
              measureY: edittorModal.measureY,
              measureFunc: edittorModal.measureFunc,
              dimension: edittorModal.dimension,
              timespan: edittorModal.timespan,
              timerange: edittorModal.timerange,
              metadata: edittorModal.metadata ?? {},
            } : {
              type: null,
              name: null,
              desc: null,
              measureY: null,
              measureFunc: 'count',
              dimension: null,
              timespan: null,
              timerange: null,
              metadata: {},
            }}
            validationSchema={widgetSchemas.validationSchema}
            onSubmit={(values, actions) => { // eslint-disable-line
              // Check if this is a timerange capable chart
              const timerangeCapable = !!timeranges?.[values.measureY]?.[values.measureFunc]?.[values.dimension];

              const metadatas = metadataSchemasByDimension?.[values.measureY]?.[values.measureFunc]?.[values.dimension];
              // Validate metadata
              if (metadatas) {
                const allValid = (() => {
                  let hasErrors = false;
                  metadatas.forEach((meta) => {
                    try {
                      meta.validation.validateSync(values.metadata[meta.key.split('metadata.')[1]]);
                    } catch (e) {
                      actions.setFieldError(meta.key, e.message);
                      hasErrors = true;
                    }
                  });
                  return !hasErrors;
                })();
                if (!allValid) return null;
              }

              if (typeof edittorModal === 'object') { // that means this is an update
                const sendData = { ...values };

                sendData.timerange = timerangeCapable ? (edittorModal.timerange || 7) : null;

                if (Object.keys(sendData).length !== 0) {
                  updateWidgetMutation.mutate({
                    id: edittorModal.id,
                    ...sendData,
                  });
                } else {
                  setEdittorModal(null);
                }
              } else {
                const sendData = { ...values };
                if (timerangeCapable) {
                  sendData.timerange = 7;
                }
                createWidgetMutation.mutate(sendData);
              }
            }}
          >
            {({ submitForm }) => (
              <Form>
                <div className="board-edittor_header">
                  {typeof edittorModal === 'object' ? 'Edit chart' : 'New chart'}
                  <div className="board-edittor_header_close" onClick={() => setEdittorModal(null)}>
                    <CloseIcon />
                  </div>
                </div>
                {step === 0 ? (
                  <ChooseStep setStep={setStep} />
                ) : (
                  <ChartStep isSegmentBoard={isSegmentBoard} />
                )}
                <div className="board-edittor_footer">
                  {/* <div className="board-edittor_footer_reset">Reset</div> */}
                  <div className="board-edittor_footer_buttons">
                    <Button className="btn_back" onClick={backClickHandler}>Back</Button>
                    <Button className="btn_apply" loading={createWidgetMutation.isLoading || updateWidgetMutation.isLoading} onClick={submitForm}>Apply</Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
