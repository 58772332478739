/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */

import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import reactors from '../_data/templates/reactors';
import useQueriesQueries from '../../../../../Queries/hooks/useQueriesQueries';
import QueryIcon from '../../../../../../Assets/Images/icons/query-icon';
import { platformColorsByBg } from '../../../../../../Data/colors';

// import reactors from '../_data/templates/reactors';

export const TriggerText = () => {
  const { queries } = useQueriesQueries();
  const { values: { trigger } } = useFormikContext();
  const selectedQuery = useMemo(() => (queries?.data || []).find((f) => f.id === trigger), [queries.data, trigger]);
  return (
    <span className="autotext">
      <span className="highlight">When</span>
      {' '}
      an interaction is added to
      {' '}
      <span className="highlight query" style={{ backgroundColor: selectedQuery?.color, color: platformColorsByBg?.[selectedQuery?.color]?.textColor }}>
        <QueryIcon color={platformColorsByBg?.[selectedQuery?.color]?.textColor} />
        <span>{selectedQuery?.name}</span>
      </span>
      {' '}
    </span>
  );
};

const AutomationText = ({
  reactorAbbr, reactorMetadata, isExample, isEditMode, isFullDisplay,
}) => {
  const Reactor = reactors[reactorAbbr].templates[isEditMode ? 'Edit' : (isFullDisplay ? 'Display' : 'ShortDisplay')];
  return (
    <>
      <span className="autotext">
        <Reactor isExample={isExample} metadata={reactorMetadata} />
      </span>
    </>
  );
};

export default AutomationText;
