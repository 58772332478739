import React from 'react';

const generalUserIcon = ({ fill, width = 25, height = 25 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.706 7.689A3.697 3.697 0 0 1 12 11.378a3.697 3.697 0 0 1-3.706-3.69A3.697 3.697 0 0 1 12 4a3.697 3.697 0 0 1 3.706 3.689zm4.253 11.492A1.478 1.478 0 0 1 18.524 21H5.493a1.48 1.48 0 0 1-1.452-1.819A8.16 8.16 0 0 1 12 12.874a8.16 8.16 0 0 1 7.959 6.307z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

export default generalUserIcon;
