import { motion } from 'framer-motion';
import ChipClose from './chipClose';

export default function KeywordChip({
  id, label, colors, onRemove,
}) {
  const isEditable = !!id;
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className="keyword-chip"
      style={{ backgroundColor: colors.bgColor, color: colors.textColor }}
    >
      {isEditable && (
        <div onClick={() => onRemove(label)} className="keyword-chip_close">
          <ChipClose color={colors.textColor} />
        </div>
      )}
      <span>{label}</span>
    </motion.div>
  );
}
