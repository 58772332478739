import { useQuery } from 'react-query';
import { useCommunityContext } from '../../../../../../../../../../Utils/contexts/community';
import NetworkManager from '../../../../../../../../../../Network/NetworkManager';

export default function useZendeskFields() {
  const { communityId } = useCommunityContext();
  const fields = useQuery(['zendesk', 'fields'], () => NetworkManager.getZendeskFields(communityId), {
    staleTime: 1000 * 60,
    retry: 1,
  });
  return {
    fields,
  };
}
