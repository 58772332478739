import { useQuery } from 'react-query';
import { useMemo } from 'react';
import NetworkManager from '../../Network/NetworkManager';
import { useCommunityContext } from '../contexts/community';

export default function useTargets() {
  const { communityId } = useCommunityContext();
  const targetsQuery = useQuery([communityId, 'targets'], () => NetworkManager.getTargets(communityId), {
    enabled: !!communityId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 60, // 60 minutes stale time
  });

  const allTargets = useMemo(() => {
    if (targetsQuery.data) {
      return Object.keys(targetsQuery.data).reduce((acc, cur) => [...acc, ...targetsQuery.data[cur].map((x) => ({ ...x, asset_id: cur }))], []);
    }
    return [];
  }, [targetsQuery]);

  return {
    targetsQuery,
    allTargets,
    data: targetsQuery?.data || [],
    isLoading: targetsQuery.isLoading,
  };
}
