import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import useOutsideClick from '../../../Utils/useOutsideClick';
import './styles.scss';

import { ReactComponent as SearchIcon } from '../../../Assets/Images/icons/search.svg';
import SelectItem from './item';
import LNCSelectValue from './value';
import chevDown from '../../../Assets/Images/icons/select-chev-down.svg';

// const itemSchema = {
//   key: 'item-key',
//   label: 'Username',
//   icon: <>he</>,
//   group: 'User Properties',
// };
export default function LNCSelect({
  value,
  onSelect,
  placeholder,
  isSearch,
  searchPlaceholder,
  className,
  wrapperClassName,
  unscrollable,
  isDisabled,
  isMulti,
  items,
  valueKey = 'key',
  // debug,
}) {
  const [isOpen, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedItem, setSelectedItem] = useState('');
  const menuRef = useRef();
  const inputRef = useRef();

  useOutsideClick(menuRef, (e) => {
    e.stopPropagation();
    setOpen(false);
  });

  const getValueKeys = useCallback(() => {
    if (isMulti) {
      return value.map((v) => v?.key ?? v);
    }
    return value?.key ?? value;
  }, [isMulti, value]);

  useEffect(() => {
    setSelectedItem(getValueKeys());
  }, [value, getValueKeys]);

  useEffect(() => {
    if (inputRef.current) {
      setSearch('');
      inputRef.current.focus();
    }
  }, [isOpen]);

  const reducedSearchList = useMemo(() => {
    if (search) {
      return items.filter((i) => (i.textLabel || i.label).toLowerCase().includes(search.toLowerCase()));
    }
    return items;
  }, [items, search]);

  const byGroup = useMemo(
    () => reducedSearchList.reduce(
      (acc, cur) => {
        if (cur.group) return { ...acc, [cur.group]: [...(acc?.[cur.group] || []), cur] };
        return {
          ...acc,
          unassigned: [...acc.unassigned, cur],
        };
      },
      { unassigned: [] },
    ),
    [reducedSearchList],
  );

  const getValueSchema = useCallback(
    (selectedKeys) => {
      if (!isMulti) return items.find((i) => i.key === selectedKeys);
      // in case we are creating items on the flight i.e keywords
      if (!items.length && Array.isArray(selectedKeys)) {
        return selectedKeys.map((key) => ({ key, value: key }));
      }
      return items.filter((i) => selectedKeys.includes(i.key));
    },
    [items, isMulti],
  );

  const wrapperClasses = useMemo(() => {
    const classes = ['lnc-select-wrapper'];
    if (wrapperClassName) classes.push(wrapperClassName);
    if (unscrollable) classes.push('unscrollable');
    if (isDisabled) classes.push('disabled');
    if (isMulti) classes.push('multi');
    if (isOpen) classes.push('active');
    return classes.join(' ');
  }, [wrapperClassName, unscrollable, isDisabled, isMulti, isOpen]);

  const handleSelect = useCallback(
    (itemKey) => {
      if (isMulti) {
        const selectedKeys = selectedItem.includes(itemKey)
          ? selectedItem.filter((f) => f !== itemKey)
          : [...selectedItem, itemKey];

        const selectedSchemas = getValueSchema(selectedKeys).reduce(
          (acc, schema) => {
            acc.value.push(schema?.[valueKey]);
            acc.option.push(schema);
            return acc;
          },
          { value: [], option: [] },
        );

        setSelectedItem(selectedKeys);
        onSelect(selectedSchemas.value, selectedSchemas.option);
      } else {
        const item = getValueSchema(itemKey);
        setSelectedItem(item.key);
        onSelect(item?.[valueKey], item);
        setOpen(false);
      }
    },
    [isMulti, selectedItem, onSelect, getValueSchema, valueKey],
  );

  const isSelected = (itemKey) => selectedItem === itemKey || (Array.isArray(selectedItem) && selectedItem.includes(itemKey));

  const openSelectList = () => (isMulti !== 'variable' ? setOpen(true) : false);

  return (
    <div className={wrapperClasses}>
      <div onClick={openSelectList} className={`lnc-select ${className ?? ''}`}>
        <LNCSelectValue
          handleSelect={handleSelect}
          value={selectedItem}
          valueSchema={getValueSchema(selectedItem)}
          isMulti={isMulti}
          placeholder={placeholder}
        />
        {!isMulti && <img className="chevron" src={chevDown} width="24" height="24" alt="" />}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={menuRef}
            animate={{ opacity: 1, y: '100%' }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
            initial={{ opacity: 0, y: '150%' }}
            className="lnc-select-menu"
          >
            {isSearch && (
              <div className="lnc-select-menu-search">
                {searchPlaceholder && !search && (
                  <div className="lnc-select-menu-search_placeholder">{searchPlaceholder}</div>
                )}
                <input
                  tabIndex={0}
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  ref={inputRef}
                />
                <div className="lnc-select-menu-search_icon">
                  <SearchIcon />
                </div>
              </div>
            )}
            <div className="lnc-select-menu_items">
              {Object.entries(byGroup).map((groupAndItems) => (
                <motion.div
                  // layoutId={`group-${groupAndItems[0]}`}
                  // layoutScroll
                  // layout="position"
                  className=""
                  key={groupAndItems[0]}
                >
                  {groupAndItems[0] !== 'unassigned' && (
                    <div className="group">{groupAndItems[0]}</div>
                  )}
                  {groupAndItems[1].map((item) => (
                    <SelectItem
                      isSelected={isSelected(item.key)}
                      onClick={handleSelect}
                      key={item.key}
                      itemKey={item.key}
                      {...item}
                    />
                  ))}
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
