import Icon from '../../../../Assets/Images/icons/not-found-user.png';

export default function EmptyDrawer() {
  return (
    <div className="empty-drawer">
      <img src={Icon} alt="" width="80" height="80" />
      <div className="empty-drawer_title">Missing user data</div>
      <div className="empty-drawer_desc">User is missing or has been removed.</div>
    </div>
  );
}
