import { Layout } from 'antd';
import '../../Components/Topics/style.scss';
import TopicsHeader from '../../Components/Topics/header';
import TopicsList from '../../Components/Topics/list';

export default function TopicsScreen() {
  return (
    <Layout className="topics-screen" style={{ flexDirection: 'row' }}>
      <div className="topics-screen_container">
        <TopicsHeader />
        <TopicsList />
      </div>
    </Layout>
  );
}
