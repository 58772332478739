import { useCallback, useState } from 'react';
import checkCircle from '../../../../../../Assets/Images/icons/check_circle.png';
import checkCircleChecked from '../../../../../../Assets/Images/icons/check_circle_checked.png';
import { ReactComponent as TrashIcon } from '../../../../../../Assets/Images/icons/trash.svg';
import { AnalyticsService } from '../../../../../../config/analytics';
import useOneMutation from '../../../../../../Utils/useOneMutation';
import DrawerHandler from '../../../state/handler';
import MiniSpinner from '../../common/miniSpinner';

export default function TagLine({
  removeCommunityTag, updateTag, setDeleteConfirm, handleNewTag, isSelected, id, label, isLoading,
}) {
  const { mutation } = useOneMutation(removeCommunityTag);
  const { mutation: updateMutation } = useOneMutation(updateTag);
  const [innerState, setInnerState] = useState('');
  const handleCompleteRemoval = useCallback((e) => {
    e.stopPropagation();
    setDeleteConfirm({
      title: 'Are you sure?',
      desc: (
        <>
          This action will remove the tag
          {' '}
          <span style={{ fontWeight: '600' }}>{label}</span>
          {' '}
          from this workspace and from all assigned members.
          <div>This action is irreversible.</div>
        </>
      ),
      callback: () => {
        AnalyticsService.removeTag(label);
        setInnerState('REMOVING');
        mutation.mutate(id, {
          onSuccess: () => {
            DrawerHandler.updateTagLocally('REMOVE', { id, label });
            setDeleteConfirm(null);
          },
        });
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, label]);
  const handleClick = useCallback(() => {
    if (id !== 'NEW') {
      if (isSelected) AnalyticsService.unassignTag(label);
      else AnalyticsService.assignTag(label);

      updateMutation.mutate({
        action: isSelected ? 'REMOVE' : 'ADD',
        payload: {
          id,
          label,
        },
      });
    } else {
      handleNewTag();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, label, id]);
  return (
    <div
      className={`tag-line ${isSelected ? 'tag-line-selected' : ''}`}
      onClick={handleClick}
      style={innerState === 'REMOVING' ? { opacity: 0.6 } : {}}
    >
      <div className="tag-line_select">
        {(isLoading || updateMutation.isLoading) ? (
          <MiniSpinner size={14} />
        ) : (
          <img src={!isSelected ? checkCircle : checkCircleChecked} width="20" height="20" alt="" />
        )}
      </div>
      <div className="tag-line_tagwrapper">
        {id === 'NEW' && (
          <>
            <span className="new">Add new </span>
            {' '}

          </>
        )}
        <div className="tag-line_tag">{label}</div>
      </div>
      <div className="tag-line_actionwrapper">
        {id !== 'NEW' && (
          <div onClick={handleCompleteRemoval} className="tag-line_action">
            {mutation.isLoading || innerState === 'REMOVING' ? (
              <MiniSpinner size={14} />
            ) : (
              <TrashIcon />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
