import {
  makeObservable,
  observable,
} from 'mobx';

class TwitterDal {
  token = null;
  secret = null;
  channels = []; // null
  step = 1;
  filterInputValue = '';

  constructor() {
    if (!TwitterDal.instance) {
      TwitterDal.instance = this;
    }

    makeObservable(this, {
      token: observable,
      secret: observable,
      channels: observable,
      step: observable,
      filterInputValue: observable,
    });

    return TwitterDal.instance;
  }
}

const twitterDalInstance = new TwitterDal();

export default twitterDalInstance;
