import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from 'antd';
import { useMemo } from 'react';
import moment from 'moment';
import LNCModal from '../../../../Modal';
import icon from '../../../../../Assets/Images/icons/report-schdule.svg';
import useQueryEmailsQueries from '../../../hooks/useQueryEmailsQueries';
import ReportSwitch from './comps/switch';
import EmailFrequency from './comps/frequency';
import EmailTimePicker from './comps/timePicker';
import EmailPicker from './comps/emailPicker';
import useQueryEmailsMutations from '../../../hooks/useQueryEmailsMutations';

export default function EmailModal({ queryId, isOpen, onClose }) {
  const { emails } = useQueryEmailsQueries(Number(queryId));
  const { update } = useQueryEmailsMutations(Number(queryId));
  const utcDiff = useMemo(() => moment('18:00', 'H:mm').diff(moment.utc('18:00', 'H:mm'), 'hours'), []);
  if (!emails.data) return <></>;
  return (
    <LNCModal className="email-modal" isShown={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          isEnabled: emails.data?.doesExist ? emails.data.isEnabled : true,
          interval: emails.data?.interval || 'daily',
          hour: typeof emails.data?.hour !== 'undefined' ? String(emails.data.hour - utcDiff) : '12', // Parsing from UTC to local time
          recipients: emails.data?.recipients || [],
        }}
        validationSchema={Yup.object({
          isEnabled: Yup.bool(),
          interval: Yup.string().required('Frequency is required'),
          hour: Yup.string().required('Hour is required'),
          recipients: Yup.array().when('isEnabled', {
            is: true,
            then: (schema) => schema.min(1, 'Recipients is required').required('Recipients is required'),
            otherwiser: (schema) => schema,
          }),
        })}
        onSubmit={(values, actions) => {
          update.mutate({
            ...values,
            hour: String(Number(values.hour) + utcDiff), // saving as UTC
          }, {
            onSettled: () => {
              actions.setSubmitting(false);
            },
            onSuccess: () => {
              onClose();
            },
          });
        }}
      >
        {({
          submitForm, errors, isSubmitting,
        }) => (
          <Form>
            <div className="container">
              <div className="email-modal_icon">
                <img src={icon} width="40" height="40" alt="" />
              </div>
              <div className="email-modal_title">
                Schedule report
              </div>
              <div className="email-modal_desc">
                Send timed report for this query
              </div>
              <div className="email-modal_form">
                <ReportSwitch />
                <div className="email-modal_form_flex">
                  <EmailFrequency />
                  <EmailTimePicker utcDiff={utcDiff} />
                </div>
                <EmailPicker />
              </div>
              <div className="email-modal_errors">
                {Object.values(errors).map((e) => (
                  <div key={e}>
                    {e}
                  </div>
                ))}
              </div>
              <div className="email-modal_footer">
                <Button className="btn_back" onClick={onClose}>Cancel</Button>
                <Button className="lnc-blue-btn" loading={isSubmitting} onClick={submitForm}>Apply</Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </LNCModal>
  );
}
