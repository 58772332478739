import { useFormikContext } from 'formik';
import useQueryQueries from '../hooks/useQueryQueries';
import WidgetSource from './widgets/types/source';
import WidgetSentiment from './widgets/types/sentiment';
import WidgetTime from './widgets/types/time';
import WidgetAICategories from './widgets/types/category';
import WidgetTopics from './widgets/types/topic';

export default function ReportsView() {
  const { values: { filters } } = useFormikContext();
  const { widgets } = useQueryQueries({ filters });
  return (
    <div className="relative-box">
      <div className="absolute-box reports-box">
        <div className="query-widgets">
          <div>
            <WidgetTime query={widgets} />

          </div>
          <div>
            <WidgetSource query={widgets} />
            <WidgetSentiment query={widgets} />
          </div>
          <div>
            <WidgetTopics query={widgets} />
            <WidgetAICategories query={widgets} />
          </div>

        </div>
      </div>
    </div>
  );
}
