/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';

import moment from 'moment';
import { toJS } from 'mobx';
import { Tooltip } from 'antd';
import dalInstance from '../../../../../Data/Dal';
import HistoryInnerContent from './history/innerContent';
import segmentIconAdded from '../../../../../Assets/Images/icons/segments-added.png';
import segmentIconRemoved from '../../../../../Assets/Images/icons/segments-removed.png';
import tagIconAdded from '../../../../../Assets/Images/icons/tags-added-new.png';
import tagIconRemoved from '../../../../../Assets/Images/icons/tags-removed.png';
import uupChangeIcon from '../../../../../Assets/Images/icons/cell-change.png';
import churnIco from '../../../../../Assets/Images/icons/dictionaries/churn-indic.svg';
import upsellIco from '../../../../../Assets/Images/icons/dictionaries/upsell-indic.svg';

import { ReactComponent as IconExpand } from '../../../../../Assets/Images/icons/icon-expand-fill.svg';
import { ReactComponent as Neutral } from '../../../../../Assets/Images/icons/revamp/sentiment-neutral-heavy.svg';
import { ReactComponent as Negative } from '../../../../../Assets/Images/icons/revamp/sentiment-negative-heavy.svg';
import { ReactComponent as Positive } from '../../../../../Assets/Images/icons/revamp/sentiment-positive-heavy.svg';
import collapseIcon from '../../../../../Assets/Images/icons/drawer-collapse-icon.svg';
import useTranslate from '../../../../../Utils/useTranslate';
import keywordsSlicer from '../../../../../Components/Dictionaries/utils/keywordSlicer';
import defaultIntegrations from '../../../../../Data/integrations';
import { AnalyticsService } from '../../../../../config/analytics';
import ActivityTitle from './comps/activityTitle';
import { useCommunityContext } from '../../../../../Utils/contexts/community';
import { platformColorsByBg } from '../../../../../Data/colors';

const sentiments = {
  POSITIVE: <Positive />,
  NEUTRAL: <Neutral />,
  NEGATIVE: <Negative />,
};

function Activity({
  act, updateOriginPlatformLink, isFullViewForced, contactId,
  isHighlighted, onHighlightPresent,
}) {
  const { text, Button, viewState } = useTranslate('original', act.text);
  const { dictionaries } = useCommunityContext();
  const [full, setFull] = useState(isHighlighted);
  const elmRef = useRef();
  const [isHover, setHover] = useState(false);
  const asset = useMemo(() => !['segment_history', 'tag_history', 'uup_changes'].includes(act.instance_type) && (dalInstance.assets.accounts.find((a) => a.id.toString() === act.asset_id.toString())), [act, dalInstance.assets]); // eslint-disable-line
  const assetChannels = useMemo(() => {
    if (['segment_history', 'tag_history', 'uup_changes'].includes(act.instance_type)) return {};
    const relevantAssets = dalInstance.assets.accounts.filter((a) => a.id.toString() === act.asset_id.toString());
    return relevantAssets.reduce((acc, cur) => {
      if (cur?.channel) {
        return {
          ...acc,
          [cur.id]: cur.channel,
        };
      }
      return acc;
    }, {});
  }, [act]);
  const externalLink = useMemo(() => {
    if (['segment_history', 'tag_history', 'uup_changes'].includes(act.instance_type)) return '';
    if (!asset) return '';
    const integration = defaultIntegrations.find((int) => int.assetTag === asset.service_name + asset.type);
    if (!integration || !integration.hasActivityLink) return null;
    if (typeof integration.hasActivityLink === 'function') return integration.hasActivityLink({ asset, activity: toJS(act) });
    return toJS(act).link;
  }, [act, asset]);
  const handleLinkClickAnalytics = useCallback(() => {
    const segName = toJS(dalInstance.selectedCrmSegmentItem)?.name || 'all';
    AnalyticsService.crmLinkToOriginalPlatform(segName, asset.service_name + asset.type);
  }, [asset]);
  const isCollapsable = useMemo(() => {
    if (act.instance_type === 'uup_changes') return true;
    if (act.custom_data && act.instance_type === 'action' && Object.keys(act.custom_data).length !== 0) return true;
    if (act.text) return true;
    return false;
  }, [act]);
  const customData = useMemo(() => {
    if (act.instance_type !== 'action') return [];
    if (act.custom_data && Object.keys(act.custom_data).length !== 0) {
      return Object.keys(act.custom_data).map((item) => ({
        label: item,
        value: act.custom_data[item],
      }));
    }
    return [];
  }, [act]);
  const activityIcon = useMemo(() => {
    if (act.instance_type === 'segment_history') return act.status === 'ENTER' ? segmentIconAdded : segmentIconRemoved;
    if (act.instance_type === 'tag_history') return act.status === 'ASSIGN' ? tagIconAdded : tagIconRemoved;
    if (act.instance_type === 'uup_changes') return uupChangeIcon;
    if (!asset) return '';
    return dalInstance.getIntegrationByAssetTag(asset.service_name + asset.type).image;
  }, [act, asset]);
  const lineColor = useMemo(() => {
    if (act.instance_type === 'segment_history') return act.segment.bgColor;
    if (asset) return dalInstance.getIntegrationByTargetTag(asset.service_name).accentColor;
    return '#7F909E';
  }, [act, asset]);

  const displayText = useMemo(() => {
    if (viewState === 'original' && act.instance_type === 'interaction') {
      const slices = keywordsSlicer({
        fullText: act.text,
        markedByIds: act.dictionaries,
        dictionaryKeywords: Object.keys(act.dictionaries || {}).reduce((acc, dictId) => {
          if (!dictionaries?.[dictId]) return acc;
          return {
            ...acc,
            [dictId]: dictionaries[dictId].keywords.reduce((acc2, cur) => ({ ...acc2, [cur.id]: cur }), {}),
          };
        }, {}),
        shouldCut: false,
      });
      const highlightedText = slices.map((slice, ind) => {
        if (typeof slice === 'string' || !slice) return <span key={`${slice}-${ind}`}>{slice}</span>;
        const colors = {
          bgColor: dictionaries[slice?.dictId]?.color,
          textColor: platformColorsByBg[dictionaries[slice?.dictId]?.color]?.textColor,
        };
        if (!colors.bgColor) {
          return <span key={`${slice.keyword}-${ind}`} className="keyword-highlight">{slice.keyword}</span>;
        }
        return <span key={`${slice.keyword}-${ind}`} className="keyword-highlight" style={{ color: colors.textColor, backgroundColor: colors.bgColor }}>{slice.keyword}</span>;
      });
      return highlightedText;
    }
    return text;
  }, [viewState, act.text, text, dictionaries, act.instance_type, act.dictionaries]);
  const aiEnricher = useMemo(() => {
    if (act.instance_type === 'interaction') {
      const indics = [];
      if (act.is_upsell_opportunity) indics.push('upsell');
      if (act.is_churn_risk) indics.push('churn');
      return indics.length > 0 ? indics : null;
    }
    return null;
  }, [act]);

  useEffect(() => {
    if (isFullViewForced !== full && !isHighlighted) setFull(isFullViewForced);
  }, [isFullViewForced]); // eslint-disable-line
  useEffect(() => {
    if (asset && externalLink && act.instance_type === 'contact') {
      updateOriginPlatformLink(asset.service_name, asset.type, {
        link: externalLink,
        name: act.user_name,
        email: act.user_email,
      });
    }
  }, [externalLink, asset, act, contactId]); // eslint-disable-line

  useEffect(() => {
    if (isHighlighted && elmRef.current) {
      onHighlightPresent(elmRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHighlighted, elmRef]);

  if (!asset && !['segment_history', 'tag_history', 'uup_changes'].includes(act.instance_type)) return <></>;
  return (
    <motion.div
      onClick={() => setFull(!full)}
      className={`activities-item ${!isCollapsable ? 'no-text' : ''}`}
      key={act.id}
      ref={elmRef}
    // layout={act.instance_type === 'tag_history' ? undefined : 'position'}
    >
      <div
        className="activities-item_line"
        style={{ backgroundColor: lineColor }}
      />
      <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className="activities-item_header">
        <div className="activities-item_header_img">
          <img src={activityIcon} width="30" height="30" alt={asset?.service_nam || 'history'} />
          {act.sentiment && (
            <div className={`activities-item_header_img_sentiment sentiment--${act.sentiment}`}>
              {sentiments[act.sentiment] ?? <Neutral />}
            </div>
          )}
        </div>
        <div className="activities-item_header_title">
          <ActivityTitle act={act} asset={asset} assetChannels={assetChannels} />
        </div>
        <div className="activities-item_header_date">
          {moment(act.origin_time).format(
            'MMM DD YYYY, HH:mm',
          )}
        </div>
        <AnimatePresence>
          {isHover && !!externalLink && (
            <motion.div
              initial={{ width: 0, x: '10px', opacity: 0 }}
              animate={{ width: '25px', x: 0, opacity: 1 }}
              exit={{ width: 0, x: '10px', opacity: 0 }}
              className="activities-item_header_share"
              onClick={handleLinkClickAnalytics}
            >
              <Tooltip color="white" title={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{`See on ${asset.service_name.charAt(0).toUpperCase() + asset.service_name.slice(1)}`}</span>}>
                <a href={externalLink} target="_blank" className="activities-item_header_share_a" rel="noreferrer">
                  <IconExpand />
                </a>
              </Tooltip>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {(full || isHover) && !!isCollapsable && (
          <motion.div
            className="activities-item_content"
            initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
            animate={{ height: isHover && !full ? '10px' : 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <div className="inner">
              {isHover && !full && (<img src={collapseIcon} width="22" alt="22" height="22" className="content-chevron" />)}

              {act.instance_type === 'uup_changes' ? (
                <HistoryInnerContent act={act} />
              ) : (
                <>
                  {customData.length !== 0 && (
                    <div className="activities-item_content_custom">
                      {customData.map((cu) => (
                        <div className="item" key={cu.label}>
                          <div>{`${cu.label}:`}</div>
                          <div>{cu.value}</div>
                        </div>
                      ))}
                    </div>
                  )}
                  <pre style={{ width: '100%', overflow: 'hidden', whiteSpace: 'pre-line' }}>
                    {displayText}
                  </pre>
                </>
              )}
            </div>
            {text && (
              <span style={{
                display: 'block',
                marginBottom: '3px',
                marginLeft: '16px',
              }}
              >
                <Button />
              </span>
            )}
            {aiEnricher && aiEnricher.length > 0 && (
              <div className="ai-indication">
                {aiEnricher.map((x) => (
                  <Tooltip key={x} color="white" title={<span className="white-tooltip">{x === 'churn' ? 'Churn risk' : 'Upsell opportunity'}</span>}>
                    <img key={x} src={x === 'churn' ? churnIco : upsellIco} alt="" width="24" height="24" />
                  </Tooltip>
                ))}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default observer(Activity);
