export const mapToList = (objects, foldersAndEntities, unfolderedName) => { // eslint-disable-line
  // prepare folders for
  const finalFolders = [];
  const finalEntitiesByFolder = {};
  const { folders, entities } = foldersAndEntities;
  finalFolders.push({
    id: -1,
    title: unfolderedName,
    isFolder: true,
    isDefaultFolder: true,
    key: 'folder--1',
    expanded: true,
    selected: false,
    chosen: false,
  });
  finalEntitiesByFolder['-1'] = [];
  folders.forEach((folder) => {
    finalFolders.push({
      ...folder,
      title: folder.name,
      isFolder: true,
      key: `folder-${folder.id}`,
      expanded: true,
      selected: false,
      chosen: false,
    });
    finalEntitiesByFolder[folder.id.toString()] = [];
  });
  objects.forEach((item) => {
    const folderAndAfter = (() => {
      const foundEntity = entities.find((a) => a.entity_id === item.id);
      if (foundEntity) return [foundEntity?.folder_id?.toString() ?? '-1', foundEntity.after];
      return ['-1', null];
    })();
    finalEntitiesByFolder[folderAndAfter[0]].push({
      ...item,
      after: folderAndAfter[1],
      folderId: item.folder_id,
      key: `entity-${item.id}`,
      selected: false,
      chosen: false,
    });
  });
  return [finalFolders, finalEntitiesByFolder];
};
