/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from 'antd';

import emptyStateIcon from '../../../Assets/Images/redesign/no-filter-match-ico.svg';
import './styles.scss';

function CrmFilterEmptyStateView({ callback }) {
  return (
    <div className="dashboard-loading-state-content">
      <Col>
        <Row>
          <img
            src={emptyStateIcon}
            alt="empty"
            style={{ position: 'relative', top: 0, left: 50 }}
          />
        </Row>
        <Row
          className="dashboard-empty-state-title"
          style={{ marginTop: '40px' }}
        >
          No matching results
        </Row>
        <Row
          onClick={callback}
          style={{ marginTop: '25px' }}
          className="dashboard-empty-state-body"
        >
          <a
            className="dashboard-empty-state-body"
            style={{ color: '#0084ff', cursor: 'pointer' }}
          >
            Try a different filter option
          </a>
        </Row>
      </Col>
    </div>
  );
}

export default CrmFilterEmptyStateView;
