import React from 'react';

import { observer } from 'mobx-react';
import {
  Modal, Button, Select, Space, Divider,
} from 'antd';
import { makeObservable, observable, runInAction } from 'mobx';

import { Redirect } from 'react-router-dom';
import AddToSlack from './addToSlack';
import Dal from '../../Data/Dal';
import SlackDal from './slackDal';
import slackConnectImage from '../../Assets/Images/redesign/ico-integrate-with-slack.svg';
import slackMatch from '../../Assets/Images/redesign/ico-integration-succes-with-slack.svg';

import '../Facebook/Pages/styles.scss';

const { Option } = Select;

class SlackModal extends React.Component {
  shouldRefresh = false;
  channels = [];
  channelsAdded = false;

  constructor() {
    super();

    makeObservable(this, {
      shouldRefresh: observable,
      channels: observable,
      channelsAdded: observable,
    });
  }

  async getInsignts() {
    await Dal.getAssets();
    this.props.onIntegrationSuccess();
    this.props.slackConnected();
    runInAction(() => { this.shouldRefresh = true; });
  }

  getOptions() {
    const options = [];
    SlackDal.channels.forEach((channel) => options.push(
      <Option key={channel.id} className="slack-channel-option">
        {channel.name}
      </Option>,
    ));
    return options;
  }

  setChannels(selectedChannelIDS) {
    runInAction(() => { this.channels = selectedChannelIDS; });
  }

  async sendChannelsAssets() {
    const channelsDataObjects = [];
    this.channels.forEach((channelID) => {
      const channel = SlackDal.channels.find((ch) => ch.id === channelID);
      channelsDataObjects.push({
        asset_id: SlackDal.storedAssetID,
        channel_id: channelID,
        name: channel.name,
      });
    });
    await Dal.createNewChannels(channelsDataObjects);
    runInAction(() => { this.channelsAdded = true; });
  }

  getContent() {
    const params = new URLSearchParams(window.location.search);
    if (params.get('error') !== null) {
      return (
        <>
          <p className="pagesHeader">Encountered an error</p>
          <p className="pagesSubtitle">
            We have encountered an error trying to connect your Slack to LoudNClear. Please try again later.
          </p>
        </>
      );
    }
    if (params.get('slack') !== null) {
      if (SlackDal.scopesMode === 'all' || SlackDal.scopesMode === 'content') {
        if (SlackDal.channels !== undefined && SlackDal.channels.length > 0) {
          return this.channelsAdded ? (
            <>
              <p className="pagesHeader">Channels connected</p>
              <p className="pagesSubtitle">
                LoudNClear is enabled to your workspace.
              </p>
              <Space direction="vertical" style={{ marginTop: 40 }}>
                <Button
                  className="getInsigntsBtn slack"
                  onClick={this.getInsignts.bind(this)}
                >
                  Get insights
                </Button>
              </Space>
            </>
          ) : (
            <>
              <div>
                <p className="pagesHeader">Please choose channels:</p>
                <Select
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())}
                  mode="multiple"
                  onChange={this.setChannels.bind(this)}
                  className="slack-channel-select"
                  data-step="slack-channel-select"
                >
                  {this.getOptions()}
                </Select>
              </div>
              <Button
                data-step="getInsigntsBtn slack"
                className="getInsigntsBtn slack"
                style={{ marginTop: 40 }}
                onClick={this.sendChannelsAssets.bind(this)}
              >
                Send channels
              </Button>
            </>
          );
        }
      } else {
        return (
          <>
            <p className="pagesHeader">Workspace connected</p>
            <p className="pagesSubtitle">
              LoudnClear is now connected to your Slack workspace.
            </p>
            <Space direction="vertical" style={{ marginTop: 40 }}>
              <Button
                className="getInsigntsBtn twitter"
                onClick={this.getInsignts.bind(this)}
              >
                Get insights
              </Button>
            </Space>
          </>
        );
      }
    } else {
      return (
        <>
          <p className="pagesHeader">Slack integration</p>
          <Divider />
          <div className="slack-note">
            You can choose connecting your feed, alert members, or both
          </div>
          <AddToSlack
            pagesConnected={this.pagesConnected}
            closeModalWithoutFetching={this.props.closeModalWithoutFetching}
          />
        </>
      );
    }
  }

  render() {
    return this.shouldRefresh ? (
      <Redirect to="/dashboard/integrations" />
    ) : (
      <Modal
        closable
        onCancel={this.props.closeModalWithoutFetching}
        visible={this.props.isVisible}
        wrapClassName={
          this.props.isRedirectedFromSlack
            ? 'integrationModalWrapper slack'
            : 'integrationModalWrapper'
        }
        footer={null}
      >
        <img
          src={
            this.props.isRedirectedFromSlack ? slackMatch : slackConnectImage
          }
          alt=""
          width={window.screen.width < 1500 ? 154 : 271}
        />
        {this.getContent()}
      </Modal>
    );
  }
}

export default observer(SlackModal);
