import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { Input } from 'antd';
import { useEffect, useLayoutEffect } from 'react';

/* WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING WARNING

This component is also used in MFA (both "set up" and "login verify" modal)
*/

function PinTemplate({ field, schema }) {
  const { submitForm, setFieldValue } = useFormikContext();
  const current = parseInt(field.name[4], 10);
  const focusHandler = (event) => {
    event.target.setSelectionRange(0, event.target.value.length);
  };
  const changeHandler = (event) => {
    setFieldValue(`pin[${current}]`, event.target.value.toString());
  };
  const onKeyDown = ({ keyCode }) => {
    if (keyCode === 8 && current !== 0) {
      setFieldValue(`pin[${current}]`, '');
      document.querySelector(`input[name="pin[${current - 1}]"]`).focus();
    }
  };
  useLayoutEffect(() => {
    if (field?.value?.length > 1 && field?.name === 'pin[0]') {
      // pasted
      for (let i = 0; i < 6; i++) {
        setFieldValue(`pin[${i}]`, field.value[i]);
      }
    } else if (field?.value?.length === 1 && current !== 5) {
      // jumps to next input
      document.querySelector(`input[name="pin[${current + 1}]"]`).focus();
    }
  }, [field.value, field.name, current, setFieldValue]);

  useEffect(() => {
    if (current === 5 && field?.value?.length !== 0) {
      submitForm();
    }
  }, [field.value, current, submitForm]);
  return (
    <Input
      {...field}
      onChange={changeHandler}
      onKeyDown={onKeyDown}
      onFocus={focusHandler}
      type="text"
    />
  );
}

const fieldsSchema = [
  {
    label: null,
    defaultValue: '',
    key: 'pin[0]',
    Template: PinTemplate,
  },
  {
    label: null,
    defaultValue: '',
    key: 'pin[1]',
    Template: PinTemplate,
  },
  {
    label: null,
    defaultValue: '',
    key: 'pin[2]',
    Template: PinTemplate,
  },
  {
    label: null,
    defaultValue: '',
    key: 'pin[3]',
    Template: PinTemplate,
  },
  {
    label: null,
    defaultValue: '',
    key: 'pin[4]',
    Template: PinTemplate,
  },
  {
    label: null,
    defaultValue: '',
    key: 'pin[5]',
    Template: PinTemplate,
  },
];

const verifyEmailSchemas = {
  fieldsSchema,
  validationSchema: Yup.object().shape({
    companyName: Yup.string().required('Required'),
  }),
};
export default verifyEmailSchemas;
