import React from 'react';

import { Divider, Button } from 'antd';

import { FacebookProvider, LoginButton } from 'react-facebook';

export default class ConnectGroup extends React.Component {
  render() {
    return (
      <div>
        <ul className="textList">
          <li>
            Gain insights related to content in your group (Posts and Comments)
          </li>
          <li id="secParagraph">
            View members related data (this will be relevant only to LoudnClear
            app opt-in members)
          </li>
        </ul>
        <p className="Simpoco-automaticall">
          LoudnClear will automatically fetch all posts and comments in your
          community feed including historical information!
        </p>
        <Divider className="facebookDivider" />
        <FacebookProvider
          appId={process.env.REACT_APP_AUTHORIZED_FACEBOOK_APP_ID}
        >
          <LoginButton
            className="facebookConnectButton"
            scope="public_profile,email,groups_access_member_info"
            onCompleted={this.props.facebookLoginCompleted}
            onError={(error) => console.error('Login with Facebook failed: ', error)}
          >
            <span>Connect with Facebook</span>
          </LoginButton>
        </FacebookProvider>
        <div>
          <Button
            type="text"
            className="closeFacebookModalBtn"
            onClick={this.props.closeModalWithoutFetching}
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
}
