/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/jsx-no-bind */

import {
  Input, Layout, Button, Col, Space, Row, message, Tooltip,
} from 'antd';
import React, {
  useEffect, useState, useMemo, useCallback, useRef,
} from 'react';
import { observe, runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import _, { random } from 'underscore';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import Dal from '../../Data/Dal';
import GeneralUserMultiIcon from '../../Assets/Images/redesign/generalMultiUserIcon';
import './styles.scss';
import 'react-virtualized/styles.css';
import CRMViewTable from './CrmTable/CrmViewTable';
import searchIcon from '../../Assets/Images/redesign/general-search-search-ico-active.svg';
import createSegmentIcon from '../../Assets/Images/redesign/illustration-contacts-white.svg';
import workflowIcon from '../../Assets/Images/icons/revamp/cat-ico-automate.svg';
import revertIcon from '../../Assets/Images/redesign/general-revert-revert-ico-up.svg';
import NetworkManager from '../../Network/NetworkManager';
import CrmEmptySegmentView from './CrmEmptyState/CrmEmptySegmentView';
import CrmProcessingStateView from './CrmEmptyState/CrmProcessingStateView';
import CrmQuickActionView from './CrmQuickActionView';
import ReportModal from '../../Components/CrmTable/ReportModal/reportModal';
import CrmFilterEmptyStateView from './CrmEmptyState/CrmFilterEmptyStateView';
import CrmSegmentEditView from './CrmSider/CrmSegmentEditView';
import CrmViewModeView from './CrmViewModeView';
import SuperFilter from '../../Components/SuperFilter/wrapper';
import CrmDrawer from './Drawer/index';
import SuperFilterHandler from '../../Components/SuperFilter/state/superFilter.class';
import CRMExport from '../../Components/Export';
import CrmBoardGateway from './CrmBoardGateway';
import AutomationsCenter from '../../Components/Automations';
import EntitySidebar from '../../Components/EntitySidebar';
import CrmSegmentItemView from './CrmSider/CrmSegmentItemView';
import useSettings, { SettingsContext } from '../../Utils/community/useSettings';
import ErrorBoundary from '../../Components/ErrorBoundary/errorBoundary';
import { AnalyticsService } from '../../config/analytics';
import LastUpdated from './LastUpdated';
import useCRM, { CRMContext } from '../../Utils/community/useCRM';
import CRMHistoryView from './CrmHistoryView';
import DrawerHandler from './Drawer/state/handler';
import SegmentIcon from '../../Assets/Images/icons/segmentIcon';
import { platformColorsByBg } from '../../Data/colors';

const { Header, Content, Sider } = Layout;

const equal = require('fast-deep-equal');

function kFormatter(num) {
  return Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num);
}

function CrmView() {
  const queryClient = useQueryClient();
  const settingsHook = useSettings(Dal.communities?.[0]?.id, 'crm');
  const { push: pushHistory } = useHistory();
  const location = useLocation();
  const [crmData, setCrmData] = useState([]);
  const [mode, setMode] = useState(0);
  const [localFilterCrmData, setLocalFilterCrmData] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [scrollId, setScrollId] = useState(undefined);
  const callInProgress = useRef();
  const [emptyState, setEmptyState] = useState(false);
  const [processingContactsState, setProcessingContactsState] = useState(false);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);
  const [emptyMode, setEmptyMode] = useState(null);
  const [sorting, setSorting] = useState({
    sortBy: 'latest_activity_time',
    sortDir: 'desc',
  });
  const actionsRef = useRef();

  const [newSuperFilter, setNewSuperFilter] = useState(null);

  const [superFilter, setSuperFilter] = useState(undefined);
  const [superFilterOperator, setSuperFilterOperator] = useState(undefined);
  const [superFilterVisible, setSuperFilterVisible] = useState(false);
  const [superFilterKeyValue, setSuperFilterKeyValue] = useState(random(1, 1000));

  const [dalSelectedSegment, setDalSelectedSegment] = useState(undefined);
  const [dalSegments, setDalSegments] = useState(toJS(Dal.segments.segments));
  const [exportModal, setExportModal] = useState(false);

  const [colors, setColors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [editSegmentLoading, setEditSegmentLoading] = useState(false);
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [crmSegmentEditViewVisible, setCrmSegmentEditViewVisible] = useState({
    visible: false,
    callback: null,
  });
  const [isEditMode, setEditMode] = useState(false);
  const [automationModal, setAutomationModal] = useState(false);
  const crmUtils = useCRM(mode, dalSelectedSegment?.id);
  const markRowAsRead = useCallback((id) => {
    const draft = [...crmData];
    const found = crmData.find((c) => c.id === id);
    if (found) {
      found.is_read = true;
      setCrmData(draft);
    }
  }, [crmData]);

  const handleSegmentChangeUrl = useCallback((segmentId) => {
    const basePath = '/dashboard/crm';
    pushHistory(`${basePath}${segmentId ? `/${segmentId}` : ''}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const processResponse = useCallback((type, response, scrollIdParam, callback) => {
    // second call for pagination may return empty response
    if (!type.includes(callInProgress.current)) return;
    setLoading(false);
    // TODO: next line find better solution for that big if statement
    // eslint-disable-next-line
    if (response.members.length === 0) setHasReachedEnd(true);
    if (initialLoad === false && scrollIdParam && (response === undefined || (response.scroll_id === undefined || response.scroll_id === null) && response.members?.length === 0)) {
      if (callback !== undefined) {
        callback();
      }
      setCrmData((cur) => cur.filter((c) => !c.loading));
      callInProgress.current = false;
      return;
    }

    if (response === undefined
      || (response !== undefined && response.total_members_count === 0)) {
      setProcessingContactsState(false);
      setEmptyMode(1);
      setEmptyState(true);
      if (callback !== undefined) {
        callback();
      }
      callInProgress.current = false;
      return;
    }
    if (response.members.length === 0 && !scrollIdParam) {
      setProcessingContactsState(true);
      setEmptyMode(2);
      callInProgress.current = false;
      return;
    }
    setEmptyMode(null);
    setEmptyState(false);
    setScrollId(response.scroll_id);

    setInitialLoad(false);
    setCrmData((current) => (scrollIdParam ? current.concat(response.members) : response.members).filter((c) => !c.loading));

    if (callback !== undefined) {
      callback();
    }
    callInProgress.current = false;
  }, [initialLoad, callInProgress]);

  const shouldShowUpdateSegment = useCallback(() => {
    if (dalSelectedSegment === undefined || dalSelectedSegment.id === undefined) {
      return false;
    }
    const segmentFilters = dalSelectedSegment?.filter?.filters;
    const segmentOperator = dalSelectedSegment?.filter?.operator;
    const sameFilters = segmentFilters !== undefined && newSuperFilter && equal(segmentFilters, newSuperFilter.filters);
    const sameOperator = segmentOperator !== undefined && newSuperFilter && equal(segmentOperator, newSuperFilter.operator);

    return !sameFilters || !sameOperator;
  }, [newSuperFilter, dalSelectedSegment]);

  const fetchCrm = useCallback((scrollIdForPaging, callback) => {
    // if (location.search !== '' && scrollId !== undefined) return null;
    const desiredCall = (() => {
      if (dalSelectedSegment !== undefined && dalSelectedSegment.id === undefined && newSuperFilter) return 'filter';
      if (dalSelectedSegment !== undefined && !shouldShowUpdateSegment()) return `segment-${dalSelectedSegment.id}`;
      if (newSuperFilter) return 'filter';
      return 'all';
    })();
    if (callInProgress.current && callInProgress.current.includes(desiredCall)) {
      return;
    }
    setEmptyMode(null);
    if (scrollIdForPaging === undefined || initialLoad === true) {
      const mockData = [
        {
          key: '1', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '2', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '3', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '4', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '5', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '6', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '7', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '8', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '9', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '10', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '11', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '12', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '13', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '14', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '15', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
        {
          key: '16', loading: true, names: [], emails: [], phones: [], descriptions: [],
        },
      ];
      setInitialLoad(true);
      setCrmData(mockData);
      setProcessingContactsState(false);
    }

    if (dalSelectedSegment !== undefined && dalSelectedSegment.id === undefined && newSuperFilter) {
      // CATEGORY AND SUPER FILTER!
      /* const draftFilter = [
        ...(superFilter || []),
      ];
      if (dalSelectedSegment !== undefined && dalSelectedSegment.id === undefined) { // category
        draftFilter.push({
          field: 'label',
          condition: 'Exact Match',
          value: dalSelectedSegment.name,
        });
      } */
      callInProgress.current = 'filter';
      NetworkManager.getCrmWithFilter(newSuperFilter, scrollIdForPaging, sorting).then((response) => {
        processResponse('filter', response, scrollIdForPaging, callback);
      });
    } else if (dalSelectedSegment !== undefined && dalSelectedSegment.id === undefined) { // category
      setLoading(dalSelectedSegment.name);
      callInProgress.current = 'category';
      NetworkManager.getCrmWithCategory(dalSelectedSegment.name, scrollIdForPaging, sorting).then((response) => {
        processResponse('category', response, scrollIdForPaging, callback);
      });
      /* NetworkManager.getCrmWithCategory(Dal.selectedCrmSegmentItem.name, scrollIdForPaging).then(response => {
          processResponse(response, callback)
      }) */
    } else if (dalSelectedSegment !== undefined && !shouldShowUpdateSegment()) { // unaltered segment filter
      setLoading(dalSelectedSegment.id);
      callInProgress.current = `segment-${dalSelectedSegment.id}`;
      NetworkManager.getCrmWithSegment(dalSelectedSegment.id, scrollIdForPaging, sorting).then((response) => {
        processResponse(`segment-${dalSelectedSegment.id}`, response, scrollIdForPaging, callback);
      });
    } else if (newSuperFilter) {
      callInProgress.current = 'filter';
      NetworkManager.getCrmWithFilter(newSuperFilter, scrollIdForPaging, sorting).then((response) => {
        processResponse('filter', response, scrollIdForPaging, callback);
      });
    } else {
      setLoading('all');
      callInProgress.current = 'all';
      NetworkManager.getCrm(scrollIdForPaging, sorting).then((response) => {
        processResponse('all', response, scrollIdForPaging, callback);
      });
    }
  }, [callInProgress, scrollId, initialLoad, newSuperFilter, sorting, processResponse, shouldShowUpdateSegment, dalSelectedSegment, location]);

  useEffect(() => {
    if (Dal.hasConnectedIntegrations()) {
      NetworkManager.getCrmFilterSources().then((x) => {
        x.platforms = x.platforms.map((platform) => ({ id: platform, name: platform, key: `${platform}-${Math.random()}` })); // eslint-disable-line
        Dal.superFilterSources = x;
      });
      // fetchCrm();
    } else {
      setEmptyState(true);
    }
  }, [superFilter]); // eslint-disable-line

  function searchMemberUpdated(obj) {
    const term = obj.target.value;
    setSearchTerm(term);
    if (term === '') {
      setSearchTerm(undefined);
      return;
    }
    setLocalFilterCrmData(crmData.filter((x) => {
      if (x.names !== undefined) {
        if (x.names.filter((y) => y.includes(term)).length > 0) {
          return true;
        }
      }
      if (x.emails !== undefined) {
        if (x.emails.filter((y) => y.includes(term)).length > 0) {
          return true;
        }
      }
      if (x.descriptions !== undefined) {
        if (x.descriptions.filter((y) => y.includes(term)).length > 0) {
          return true;
        }
      }
      return false;
    }));
  }

  function showSuperFilter() {
    setSuperFilterVisible(true);
  }

  const getEmptyStateView = useMemo(() => {
    const segmentFilters = dalSelectedSegment?.filter?.filters;
    const segmentOperator = dalSelectedSegment?.filter?.operator;
    const sameFilters = segmentFilters !== undefined && newSuperFilter && equal(segmentFilters, newSuperFilter.filters);
    const sameOperator = segmentOperator !== undefined && newSuperFilter && equal(segmentOperator, newSuperFilter.operator);
    if (dalSelectedSegment === undefined && newSuperFilter === null) {
      return <CrmProcessingStateView />;
    }
    if (dalSelectedSegment !== undefined && dalSelectedSegment.id === undefined && newSuperFilter === null) {
      // category
      return <CrmEmptySegmentView canEdit cta={showSuperFilter} />;
    }
    if (dalSelectedSegment !== undefined && sameFilters && sameOperator) {
      // segment has nothing
      return <CrmEmptySegmentView canEdit cta={showSuperFilter} />;
    }
    if (newSuperFilter !== null) {
      return <CrmFilterEmptyStateView callback={showSuperFilter} />;
    }
    return <CrmEmptySegmentView canEdit cta={showSuperFilter} />;
  }, [newSuperFilter, dalSelectedSegment]);

  function resetSuperFilter() {
    SuperFilterHandler.reset();
    setNewSuperFilter(null);
    setSuperFilterVisible(false);
    setScrollId(undefined);
    setCrmData([]);
    setInitialLoad(false);
    setSuperFilterOperator('AND');
    setSuperFilter(undefined);
    setSuperFilterKeyValue(random(1, 1000));
    Dal.selectedCrmSegmentItem = undefined;
  }

  const getDataSource = useCallback(() => (searchTerm === undefined ? crmData : localFilterCrmData), [searchTerm, crmData, localFilterCrmData]);

  const isRowLoaded = useCallback(({ index }) => !!getDataSource()[index], [getDataSource]);

  const loadMoreRows = useCallback(() => new Promise((resolve, reject) => {
    if (scrollId && !callInProgress && !hasReachedEnd) {
      setCrmData((cur) => [
        ...cur,
        ...Array(10).fill(null).map((_, ind) => ({
          key: cur.length + ind, loading: true, names: [], emails: [], phones: [], descriptions: [],
        })),
      ]);
    }
    try {
      if (!hasReachedEnd) {
        fetchCrm(scrollId, () => {
          resolve();
        });
      }
    } catch (err) {
      reject(err);
    }
  }), [scrollId, fetchCrm, hasReachedEnd, callInProgress]);

  const userDetailsUpdated = (user) => {
    if (user === undefined) {
      return;
    }
    const index = crmData.findIndex((x) => x.id === user.id);
    if (index >= 0) {
      crmData[index] = user;
    }

    if (searchTerm !== undefined) {
      const searchModeIndex = localFilterCrmData.findIndex((x) => x.id === user.id);
      if (searchModeIndex >= 0) {
        localFilterCrmData[searchModeIndex] = user;
      }
    }
  };

  function collapseSiderClicked() {
    setSiderCollapsed(!siderCollapsed);
  }

  const segmentClicked = useCallback((segmentId, categoryName, colorsParam) => {
    if (!!segmentId && dalSelectedSegment?.id === segmentId) return null;
    if (!segmentId) setMode(0);
    setColors(colorsParam);
    runInAction(() => {
      if (segmentId !== undefined) {
        Dal.selectedCrmSegmentItem = Dal.getSegmentById(segmentId);
        const obj = toJS(Dal.selectedCrmSegmentItem.filter);
        // setNewSuperFilter(obj);
        SuperFilterHandler.populateByBackendData(obj);
        setNewSuperFilter(obj);
        // fetchCrm();
      } else if (categoryName !== undefined) {
        resetSuperFilter();
        Dal.selectedCrmSegmentItem = Dal.getSegmentCategoryByName(categoryName);
        // fetchCrm();
      } else {
        if (newSuperFilter !== null) {
          resetSuperFilter(); // complete reset and refetch crm
        } else {
          SuperFilterHandler.reset();
          setNewSuperFilter(null);
          fetchCrm();
        }
        Dal.selectedCrmSegmentItem = undefined;
      }
    });
  }, [setNewSuperFilter, dalSelectedSegment, newSuperFilter]);

  function addSegmentClicked(callback) {
    runInAction(() => {
      Dal.crmSegmentItemToEdit = undefined; // TODO: ELI fill in super filter params
      setCrmSegmentEditViewVisible({
        visible: true,
        callback: callback || null,
      });
    });
  }

  function applySuperFilterAfterCreation(filters) {
    SuperFilterHandler.populateByBackendData(filters);
    setNewSuperFilter(filters);
  }

  const addSegmentFromExport = useCallback(() => {
    setExportModal(false);
    addSegmentClicked(() => {
      setExportModal(true);
    });
  }, []);

  function revertToOriginalSegmentFilterClicked() {
    handleSegmentChangeUrl(Dal.selectedCrmSegmentItem.id);
  }

  async function updateSegmentClicked(callback) {
    const segment = Dal.selectedCrmSegmentItem;
    setEditSegmentLoading(true);
    // console.log("Start update segment filter " + JSON.stringify(segment.filter.filters) + " to " + JSON.stringify(superFilter))
    await NetworkManager.createSegment(segment.name, segment.color, newSuperFilter, segment.id);
    await Dal.fetchSegments();
    setEditSegmentLoading(false);
    AnalyticsService.segmentUpdate(segment.name);
    runInAction(() => {
      Dal.selectedCrmSegmentItem = Dal.getSegmentById(segment.id);
      if (callback) callback();
      // console.log("Done update segment filter " + JSON.stringify(segment.filter.filters) + " to " + JSON.stringify(superFilter))
      message.success('Done!');
    });
  }

  function editSegmentClicked(segmentId) {
    // ("22222 " + JSON.stringify( toJS(Dal.crmSegmentItemToEdit)) )
    runInAction(() => {
      Dal.crmSegmentItemToEdit = Dal.getSegmentById(segmentId);
      setCrmSegmentEditViewVisible({ visible: true, callback: null });
    });
  }

  async function markAllTasks() {
    let segmentId;
    let categoryName;
    if (Dal.selectedCrmSegmentItem !== undefined) {
      if (Dal.selectedCrmSegmentItem.id !== undefined) { // segment
        segmentId = Dal.selectedCrmSegmentItem.id;
      } else { // category
        categoryName = Dal.selectedCrmSegmentItem.name;
      }
    }
    await NetworkManager.changeCrmReadStatus(segmentId, categoryName);
    // await Dal.fetchSegments();
    Dal.markAllReadOnCurrentSegment();
    // fetchCrm();
    setCrmData((cur) => cur.map((x) => ({ ...x, is_read: true })));
  }
  async function markAllAsUnread() {
    if (dalSelectedSegment.id) {
      const result = await Dal.markSegmentAsUnread(dalSelectedSegment.id);
      if (result) setCrmData((cur) => cur.map((x) => ({ ...x, is_read: false })));
    }
  }

  function getSegmentName() {
    if (Dal.selectedCrmSegmentItem !== undefined) {
      if (Dal.selectedCrmSegmentItem.id !== undefined) {
        return Dal.selectedCrmSegmentItem.name;
      }
      return Dal.getSegmentCategoryById(Dal.selectedCrmSegmentItem.name).name;
    }
    return 'All';
  }

  function shouldShowCreateSegment() {
    return Dal.selectedCrmSegmentItem === undefined && newSuperFilter !== null;
  }

  function getSegmentForEdit() {
    if (Dal.crmSegmentItemToEdit !== undefined) {
      return Dal.crmSegmentItemToEdit;
    }

    if (shouldShowCreateSegment()) {
      const segment = {
        filter: {
          operator: superFilterOperator,
          filters: superFilter,
        },
      };
      return segment;
    }

    if (shouldShowUpdateSegment()) { // user tapped "new segment"
      const segment = {
        name: Dal.selectedCrmSegmentItem.name,
        color: Dal.selectedCrmSegmentItem.color,
        filter: {
          operator: superFilterOperator,
          filters: superFilter,
        },
      };
      return segment;
    }
    return {};
  }

  function getSegmentForEditKey() {
    return random(1, 1000);
  }

  useEffect(() => {
    // reset super filter
    if (dalSelectedSegment === undefined) {
      resetSuperFilter();
    }

    // send event
    if (dalSelectedSegment && dalSelectedSegment?.name) {
      AnalyticsService.segmentView(dalSelectedSegment.name);
    }
  }, [dalSelectedSegment]);

  useEffect(() => {
    fetchCrm();
    setHasReachedEnd(false);
    // setMode(0);
  }, [dalSelectedSegment, sorting, newSuperFilter]); // eslint-disable-line

  useEffect(() => {
    if (mode === 1) {
      // analytics, view dashboard segment
      AnalyticsService.segmentDashboardView(dalSelectedSegment?.name);
    }
  }, [mode, dalSelectedSegment]); // eslint-disable-line

  const mainContent = useMemo(() => {
    if (emptyMode && mode === 0) {
      return getEmptyStateView;
    }
    if (mode === 1) {
      return (
        <CrmBoardGateway actionsRef={actionsRef} dalSelectedSegment={dalSelectedSegment} />
      );
    }
    if (mode === 2) {
      return (
        <ErrorBoundary componentName="CRMHistoryView">
          <CRMHistoryView
            sorting={sorting}
            setSorting={setSorting}
            superFilter={newSuperFilter}
            colors={colors}
          />
        </ErrorBoundary>
      );
    }
    return (
      <ErrorBoundary componentName="CRMViewTable">
        <CRMViewTable
          isRowLoaded={isRowLoaded}
          markRowAsRead={markRowAsRead}
          loadMoreRows={loadMoreRows}
          crmData={crmData}
          colors={colors}
          isSegment={!!dalSelectedSegment}
          sorting={sorting}
          setSorting={setSorting}
          localFilterCrmData={localFilterCrmData}
          superFilter={newSuperFilter}
          searchTerm={searchTerm}
        />
      </ErrorBoundary>
    );
  }, [
    emptyMode,
    getEmptyStateView,
    dalSelectedSegment,
    mode,
    markRowAsRead,
    newSuperFilter,
    crmData,
    sorting,
    isRowLoaded,
    colors,
    localFilterCrmData,
    searchTerm,
    loadMoreRows,
  ]);

  const deleteSegmentMutation = useMutation((d) => NetworkManager.deleteSegment(d.id), {
    onSuccess: () => {
      queryClient.refetchQueries(['folders', 'crm/segments']);
    },
  });

  useEffect(() => {
    const segMapper = (seg) => ({
      ...seg,
      id: seg.id,
      desc: null,
      info: (
        <div className="info_counts">
          <span>{String(kFormatter(seg.unreadCount)).toUpperCase()}</span>
        </div>
      ),
      title: (
        <Tooltip
          color="white"
          overlayStyle={{ pointerEvents: 'none', maxWidth: '400px' }}
          overlayInnerStyle={{ padding: '8px' }}
          mouseEnterDelay={0.3}
          title={(
            <div style={{ color: '#3a434a', fontSize: '14px', lineHeight: '18px' }}>
              <div style={{ display: 'flex', alignItems: 'center', fontWeight: '600' }}>
                <span style={{ flexShrink: 0 }}><SegmentIcon fill={platformColorsByBg[seg.color].textColor} /></span>
                {' '}
                <span style={{ paddingLeft: '7px', position: 'relative', transform: 'translateY(2px)' }}>{seg.name}</span>
              </div>
              <div style={{ fontSize: '12px', marginTop: '5px' }}>
                <div>
                  <span style={{ fontWeight: '500' }}>Unread:</span>
                  {' '}
                  <span>{seg.unreadCount}</span>
                </div>
                <div>
                  <span style={{ fontWeight: '500' }}>Total:</span>
                  {' '}
                  <span>{seg.count}</span>
                </div>
              </div>
            </div>
          )}
        >
          <span style={{ fontWeight: seg.hasUpdate === 1 ? '600' : 'inherit' }}>{seg.name}</span>
        </Tooltip>
      ),
      icon: <SegmentIcon fill={platformColorsByBg[seg.color].textColor} />,
      onClick: () => {
        handleSegmentChangeUrl(seg.id);
      },
      onEditClick: () => {
        editSegmentClicked(seg.id);
      },
    });
    const unsub = observe(Dal, 'selectedCrmSegmentItem', (c) => {
      const v = toJS(c.newValue);
      const old = toJS(c.oldValue);
      if (v?.id !== old?.id) {
        setDalSelectedSegment(v);
      }
    });
    setDalSegments(toJS(Dal.segments.segments).map(segMapper));
    const unsub2 = observe(Dal, 'segments', (c) => {
      // console.log('changed', c);
      const newVal = toJS(c.newValue);
      if (newVal && newVal?.segments) {
        setDalSegments(newVal.segments.map(segMapper));
      }
    });
    return () => {
      unsub();
      unsub2();
    };
  }, []);

  useEffect(() => {

  }, []);
  useEffect(() => {
    const reg = /\/dashboard\/crm\/(\d+)/g;
    const segId = reg.exec(location.pathname)?.[1];
    const params = new URLSearchParams(location.search);
    // Handle segment opening
    if (segId && !!dalSegments.find((x) => x.id === Number(segId))) {
      segmentClicked(Number(segId), undefined, {});
    } else {
      setSuperFilter(undefined);
      runInAction(() => {
        setInitialLoad(true);
        Dal.showCrmUserView = false;
        Dal.selectedCrmSegmentItem = undefined;
      });
    }

    // Handle drawer opening
    if (params.get('contactId')) {
      DrawerHandler.openDrawer({ id: params.get('contactId') });
    } else {
      DrawerHandler.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dalSegments]);
  const sidebarHeader = useMemo(() => (
    <CrmSegmentItemView
      cardClicked={() => handleSegmentChangeUrl(null)}
      id={undefined}
      title="All"
      minified={false}
      subtitle={Dal.segments?.allMembers?.count}
      backgroundColor={
        dalSelectedSegment === undefined ? '#0084ff' : '#e5f3ff'
      }
      count={Dal.segments?.allMembers?.unreadCount}
      isLoading={isLoading === 'all'}
      mainColor="white"
      mainTextColor="#0084ff"
      showFooterSection
      titleTextColor={dalSelectedSegment === undefined ? 'white' : '#3a434a'}
      subtitleTextColor={
        dalSelectedSegment === undefined ? 'white' : '#7f909e'
      }
      footerTextColor={dalSelectedSegment === undefined ? 'white' : '#6b9ff4'}
      hasUpdate={Dal.segments?.allMembers?.hasUpdate}
      iconColor={dalSelectedSegment === undefined ? 'white' : '#113b63'}
    />
  ), [dalSelectedSegment, isLoading]);
  return (
    <div style={{ backgroundColor: 'white' }}>
      <CRMContext.Provider value={crmUtils}>
        <SettingsContext.Provider value={settingsHook}>
          <Layout style={{ flexDirection: 'row' }}>
            <ErrorBoundary dimensions={[300, 'auto']} componentName="EntitySidebar-CRM">
              <EntitySidebar
                list={dalSegments}
                entity={{ singularName: 'segment', pluralName: 'segments', apiName: 'crm/segments' }}
                communityId={toJS(Dal.communities)?.[0]?.id}
                deleteMutation={deleteSegmentMutation}
                shouldShowDeleteConfirm={{ title: 'Delete segment?', desc: 'All the team members you shared this segment with will lose their access' }}
                selected={dalSelectedSegment?.id ?? undefined}
                injectHeader={sidebarHeader}
                // onCreateClick={() => setBoardModal(true)}
                title="Segments"
              />
            </ErrorBoundary>
            {/* <Sider trigger={null} collapsedWidth="5px" collapsed={siderCollapsed} width="300px">
          <CrmSider
            isVisable
            selectedId={Dal.selectedCrmSegmentItem?.id}
            isLoading={isLoading}
            isCollapsed={siderCollapsed}
            onEditModeChange={setEditMode}
            addClicked={addSegmentClicked}
            cardClicked={segmentClicked}
            fetchCrm={fetchCrm}
            editSegmentClicked={editSegmentClicked}
            collapseSetsListViewClicked={collapseSiderClicked}
          />
        </Sider> */}
            <Layout style={{ position: 'relative', maxHeight: '100vh' }}>
              <div className={`editmode-overlay ${isEditMode ? 'active' : ''}`} />
              <Header
                style={{
                  height: '110px', paddingRight: '20px', background: 'white', position: 'relative', minWidth: '1000px',
                }}
              >
                <Row
                  style={{ height: '60px', alignItems: 'center', lineHeight: 1 }}
                >
                  <Col flex="1">
                    <span className="header-title" style={{}}>{getSegmentName()}</span>
                  </Col>
                  <LastUpdated />
                  <Col>
                    <Space size={20}>
                      <div className="hover-link-box" onClick={() => setAutomationModal(true)}>
                        <img src={workflowIcon} width="30" height="30" alt="" />
                        <span
                          className="workflow-integration-style"
                          style={{ marginLeft: '6px' }}
                        >
                          Automate
                        </span>
                      </div>
                    </Space>
                  </Col>

                </Row>
                <Row justify="flex-start" align="middle" style={{ height: '40px', position: 'relative', top: mode === 1 ? '6px' : 0 }}>
                  <Col flex="none" style={{ marginLeft: -16 }}>
                    <CrmViewModeView selectedSegment={dalSelectedSegment} selectedMode={mode} onModeChange={setMode} style={{ float: 'left', marginLeft: '10px' }} />
                  </Col>
                  <Col flex="auto">
                    <div style={{ float: 'right' }} ref={actionsRef} />
                    {mode === 2 && ( // this prevents UI jump
                      <div style={{
                        float: 'right', height: '64px', marginRight: '6px', marginTop: '-13px',
                      }}
                      />
                    )}
                    {mode === 0 && (
                      <>
                        <div style={{ float: 'right', marginRight: '6px', marginTop: '-13px' }}>
                          <Input
                            disabled={emptyState}
                            className="search-input"
                            style={{ height: '39px' }}
                            placeholder="Search"
                            allowClear
                            onChange={searchMemberUpdated}
                            suffix={<img alt="search" src={searchIcon} />}
                          />
                        </div>
                        <div style={{ float: 'right', marginRight: '6px' }}>
                          <CrmQuickActionView isActive={exportModal} handleOpen={() => setExportModal(true)} disabled={emptyState} />
                        </div>
                        <div style={{ float: 'right', marginRight: '6px' }}>
                          <ErrorBoundary componentName="CRMSuperFilter">
                            <SuperFilter isSuperFilterActive={!!newSuperFilter} isShown={superFilterVisible} setShown={setSuperFilterVisible} onFinish={setNewSuperFilter} />
                          </ErrorBoundary>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Header>
              <Content
                style={{
                  flexGrow: 1, display: 'flex', flexDirection: 'column',
                }}
                className="headerSeparator crmPage"
              >
                <ErrorBoundary componentName="CRMExport">
                  <CRMExport
                    isShown={exportModal}
                    isSolelyFilter={shouldShowCreateSegment()}
                    addSegmentFromExport={addSegmentFromExport}
                    onClose={() => setExportModal(false)}
                  />
                </ErrorBoundary>
                <AutomationsCenter isShown={automationModal} onClose={() => setAutomationModal(false)} />
                {mode === 0 && (
                  <>
                    <div style={{
                      height: '40px', paddingTop: '15px', paddingLeft: '20px', position: 'relative', display: 'flex', alignItems: 'center', zIndex: 2,
                    }}
                    >
                      <div className="unread-matches-data-container">
                        <p className="unread-matches-data-text">
                          {' '}
                          {Dal.getSegmentTotalItemsCount()}
                          {' '}
                          Users /
                          {' '}
                          {Dal.getSegmentUnreadItemsCount()}
                          {' '}
                          New
                        </p>
                        <p className="mark-all-read-text" role="button" tabIndex="0" onKeyDown={markAllTasks} onClick={markAllTasks}>Mark all as read</p>
                        <p
                          className="mark-all-read-text"
                          role="button"
                          tabIndex="0"
                          onKeyDown={markAllAsUnread}
                          onClick={markAllAsUnread}
                          style={{
                            width: '30px', height: '30px', position: 'absolute', top: 0, left: -15,
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                {mainContent}
                <ReportModal visible={Dal.showReportModal} />
                <CrmDrawer />
                <CrmSegmentEditView
                  idKey={getSegmentForEditKey()}
                  segment={getSegmentForEdit()}
                  visible={crmSegmentEditViewVisible.visible}
                  callback={crmSegmentEditViewVisible.callback}
                  closeClicked={(segments) => {
                    setCrmSegmentEditViewVisible({ visible: false, callback: null });
                    if (segments !== undefined) {
                      fetchCrm();
                    }
                  }}
                />
                {
                  !shouldShowCreateSegment() ? null
                    : (
                      <Button
                        className="crm-create-segment-button"
                        type="primary"
                        icon={<img alt="create segment" src={createSegmentIcon} style={{ marginRight: 10 }} />}
                        size="large"
                        onClick={() => addSegmentClicked(() => applySuperFilterAfterCreation(newSuperFilter))}
                        data-step="crm-create-segment-button"
                      >
                        Create segment
                      </Button>
                    )
                }
                {
                  !shouldShowUpdateSegment() ? null
                    : (
                      <span className="crm-update-segment-button-container">
                        <Space size={20}>
                          <Button
                            className="crm-revert-segment-button"
                            type="primary"
                            icon={<img alt="revert" src={revertIcon} />}
                            size="large"
                            onClick={revertToOriginalSegmentFilterClicked}
                          />
                          <Button
                            className="crm-new-segment-button"
                            type="primary"
                            size="large"
                            onClick={() => addSegmentClicked(() => applySuperFilterAfterCreation(newSuperFilter))}
                            data-step="crm-new-segment-button"
                          >
                            New Segment
                          </Button>
                          <Button
                            className="crm-save-segment-button"
                            loading={editSegmentLoading}
                            type="primary"
                            size="large"
                            onClick={() => updateSegmentClicked(() => applySuperFilterAfterCreation(newSuperFilter))}
                          >
                            Save existing
                          </Button>
                        </Space>
                      </span>
                    )
                }
              </Content>

            </Layout>

          </Layout>
        </SettingsContext.Provider>
      </CRMContext.Provider>
    </div>
  );
}

export default observer(CrmView);
