import * as Yup from 'yup';
import logo from '../../Assets/Images/pendo-lnc.png';
import connected from '../../Assets/Images/icons/ico-integration-succes-with-pendo.png';

const fieldsSchema = [
  {
    label: 'Friendly name',
    defaultValue: '',
    placeholder: 'Enter a name so you can recognize this source across LoudNClear platform',
    key: 'name',
  },
  {
    label: 'Token',
    defaultValue: '',
    placeholder: 'Enter the token for your Pendo workspace',
    key: 'token',
  },
];

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  token: Yup.string().required('Token is required'),
});

const PendoWorkspaceSchema = {
  config: {
    name: 'Pendo',
    image: logo,
    connectedImage: connected,
    info: [
      'Collect CSAT and NPS scores',
      'View feedback and reactions on surveys',
    ],
  },
  fieldsSchema,
  validationSchema,
};
export default PendoWorkspaceSchema;
