/* eslint-disable camelcase */
import {
  useMemo, useEffect, useState, useCallback, useLayoutEffect,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import Heading from '../../common/heading';
import FieldLine from './components/fieldLine';
import dataTypesSchema from './_data/_dataTypeSchema';
import validationsSchema from '../../../../Data/fields/_validations';
import systemFields from '../../../../Data/fields/_systemFieldsSchema';
import hiddenFields from '../../../../Assets/Images/icons/hidden-fields.svg';
import Dal from '../../../../Data/Dal';

import firstFieldIco from '../../../../Assets/Images/icons/match-ico.svg';
import cogIco from '../../../../Assets/Images/icons/cat-ico-cog.svg';
import StepWrapper from '../../common/stepWrapper';
import MapperDivider from './components/divider';
import { Button } from 'antd';

const uid = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

/* FIELDLINE SCHEMA
  {
    id: 124323,
    source_field: null || string,
    dest_field: null || DEST_FIELD,
    status: 0 - none, 1 - success, STRING - error,
    mandatory: true,
  }
*/

/*
DEST_FIELD SCHEMA
{
  key: string,
  label: string,
  field_type: string,
  icon: img,
  isCustom: bool,
  canStillEdit: bool,
  validation: YupValidationSchema
}
*/

/*
- date format
- limit file size
- limit column text size

*/

const customFieldToLocalSchema = (cu) => ({
  key: cu.label,
  label: cu.label,
  field_type: cu.field_type,
  icon: cogIco,
  isCustom: true,
  validation: validationsSchema[`_${cu.field_type}`],
  _ASSIGN_TO: cu?._ASSIGN_TO ?? undefined,
});

function MapperStep({ globalContext, updateContext }) {
  const { csvData, listType } = globalContext;
  const [map, setMap] = useState([]);
  const [dalCustomFields, setDalCustomFields] = useState([]);
  const [initted, setInitted] = useState(false);
  const [seenFirstFieldScreen, setSeenFirstFieldScreen] = useState(false);

  const columns = useMemo(() => csvData?.[0] || [], [csvData]);
  const mandatoryFields = useMemo(() => map.filter((m) => m.mandatory), [map]);
  const optionalFields = useMemo(() => map.filter((m) => !m.mandatory), [map]);
  const columnsOptions = useMemo(() => columns.map((s) => ({ label: s, isAlreadySelected: false })), [columns]);
  const typeSchema = useMemo(() => dataTypesSchema[listType], [listType]);
  const allFieldsVisible = useMemo(() => !!map.find((f) => f.mandatory && f.source_field && f.status === 1), [map]);
  const sampleData = useMemo(() => {
    const final = {};
    if (csvData?.[0]) {
      const headerRow = csvData[0];
      headerRow.forEach((colName, ind) => {
        final[colName] = csvData.slice(1, 6).map((row) => row[ind]);
      });
    }
    return final;
  }, [csvData]);
  const allFieldsWithCustom = useMemo(() => [
    ...typeSchema.fields.mandatory,
    ...typeSchema.fields.optional,
    ...dalCustomFields,
  ], [typeSchema, dalCustomFields]);
  const fieldsListWithCustom = useMemo(() => [
    ...typeSchema.fields.optional,
    ...dalCustomFields,
  ], [dalCustomFields, typeSchema]);

  const shouldShowFirstFieldScreen = useMemo(() => {
    if (allFieldsVisible && !seenFirstFieldScreen) return true;
    return false;
  }, [allFieldsVisible, seenFirstFieldScreen]);

  const runValidation = useCallback((source_field, dest_field) => {
    if (dest_field && dest_field.key !== 'NEW_CUSTOM') {
      if (source_field && dest_field) {
        if (dest_field.validation === null) {
          return 1;
        }

        const fieldObject = (() => {
          if (systemFields[dest_field?.key]) return systemFields[dest_field.key];
          return dalCustomFields.find((cf) => cf.key === dest_field.key);
        })();

        // if is connector field, and has empty lines in CSV
        if (fieldObject.connector && sampleData[source_field].filter((d) => typeof d === 'undefined').length !== 0) {
          return 'Connector fields mustn\'t have empty lines in CSV';
        }
        // if field is optional and same data has empty lines:
        if (!fieldObject.connector && sampleData[source_field].filter((d) => typeof d === 'undefined').length !== 0) {
          return 1;
        }
        try {
          const res = fieldObject.validation.validateSync(sampleData[source_field][0]);
          if (res) return 1;
        } catch (e) {
          return e.message;
        }
      }
    }
    return 0;
  }, [dalCustomFields, sampleData]);

  const updateLineById = useCallback((id, src, dst) => {
    // newSrc - string
    // newDst - string (key of field)

    setMap((current) => {
      let draft = JSON.parse(JSON.stringify(current));
      const thisLine = draft.find((line) => line.id === id);
      if (!thisLine) return current;
      const newSrc = src;
      const newDst = (() => {
        if (dst) {
          if (dst === 'NEW_CUSTOM') {
            return {
              key: 'NEW_CUSTOM',
              label: 'Custom Field',
              field_type: 'string',
              icon: cogIco,
              isCustom: true,
              canStillEdit: true,
              validation: null,
            };
          }
          return allFieldsWithCustom.find((f) => f.key === dst);
        }
        return dst;
      })();
      // Check if line is mandatory
      if (thisLine.mandatory) {
        // if source exists and setting to empty, add new line to optional fields
        // check if source has changed
        // check if current source doesnt exist on optional fields, if not, add it
        if (thisLine.source_field !== newSrc) { // src field has chagned
          // there's a line with the new source:
          const lineWithThisSource = draft.find((d) => (d.source_field === newSrc));
          if (lineWithThisSource) {
            if (lineWithThisSource.mandatory) {
              lineWithThisSource.status = 0;
              lineWithThisSource.source_field = null;
            } else {
              draft = draft.filter((d) => d.id !== lineWithThisSource.id);
            }
          }
          if (thisLine.source_field) { // is changing source_field and has value
            const prevSourceExists = draft.find((d) => d.id !== thisLine.id && d.source_field === thisLine.source_field);
            // if the CURRENT source does not appear on the list (apart from this fieldLine), add it to optional
            if (!prevSourceExists) {
              draft = [
                {
                  id: uid(),
                  source_field: thisLine.source_field,
                  dest_field: null,
                  mandatory: false,
                },
                ...draft,
              ];
            }
          }
        }
      } else {
        // line is not mandatory
        // check if dest_field has chagned, and if its already exist - clear previous one
        if (dst) { // eslint-disable-line
          const lineWithThisDest = draft.find((d) => !d.mandatory && d.dest_field?.key === newDst?.key);
          if (lineWithThisDest) {
            lineWithThisDest.status = 0;
            lineWithThisDest.dest_field = null;
          }
        }
      }
      const found = draft.find((line) => line.id === id);
      found.source_field = newSrc;
      found.dest_field = newDst;

      // Validation:
      found.status = runValidation(found.source_field, found.dest_field);

      return draft;
    });
  }, [allFieldsWithCustom, runValidation]);

  const createCustomField = useCallback((lineId, field) => {
    if (dalCustomFields.find((ct) => ct.label === field.label) || typeof systemFields[field.label] !== 'undefined') {
      return 'Already exists';
    }
    const customField = {
      id: uid(),
      ...field,
      _ASSIGN_TO: lineId, // temporary field, because of react renders.
    };
    runInAction(() => {
      Dal.customFields = [...Dal.customFields, customField];
      setDalCustomFields((cur) => ([...cur, customFieldToLocalSchema(customField)]));
    });
    return true;
  }, [dalCustomFields]);

  useLayoutEffect(() => {
    const shouldAssignSomething = dalCustomFields.find((cf) => cf._ASSIGN_TO);
    if (shouldAssignSomething) {
      const line = map.find((l) => l.id === shouldAssignSomething._ASSIGN_TO);
      updateLineById(line.id, line.source_field, shouldAssignSomething.key);

      // remove the _assign_to
      setDalCustomFields((cur) => {
        const draft = [...cur];
        const found = draft.find((cf) => cf.key === shouldAssignSomething.key);
        found._ASSIGN_TO = undefined;
        return draft;
      });
    }
  }, [dalCustomFields, map, updateLineById]);

  useLayoutEffect(() => { // Init
    const maps = [
      ...typeSchema.fields.mandatory.map((field) => ({
        id: uid(),
        source_field: null,
        dest_field: field,
        status: 0,
        mandatory: true,
      })),
      ...columns.map((col) => ({
        id: uid(),
        source_field: col,
        dest_field: null,
        status: 0,
        mandatory: false,
      })),
    ];
    setMap(maps);

    // set custom fields state to match Dal
    setDalCustomFields(toJS(Dal.customFields).map(customFieldToLocalSchema));
  }, [typeSchema, columns, globalContext.storedMapping]);

  useLayoutEffect(() => {
    // Assigning stored mappings
    if (!initted && map.length !== 0) {
      const findStoredSourceByDest = (dest) => {
        const found = globalContext.storedMapping.filter((m) => m.dst_field_name === dest);
        const theLoop = (ind) => { // We can have multiple dst_fields, we need to find a relevant src_field
          if (found[ind] && !columns.includes(found[ind].src_field_name)) return theLoop(ind + 1);
          if (found[ind]) return found[ind].src_field_name;
          return found[ind + 1] ? theLoop(ind + 1) : null;
        };
        return found.length > 0 ? theLoop(0) : null;
      };
      const findStoredDestBySource = (src) => {
        const found = globalContext.storedMapping.filter((m) => m.src_field_name === src);
        const theLoop = (ind) => {
          if (found[ind]) return found[ind].dst_field_name;
          return found[ind + 1] ? theLoop(ind + 1) : null;
        };
        return found.length > 0 ? theLoop(0) : null;
      };
      map.forEach((mapLine) => {
        if (mapLine.source_field === null) {
          if (mapLine.dest_field && mapLine.dest_field.key) {
            const storedSrc = findStoredSourceByDest(mapLine.dest_field.key);
            if (storedSrc) {
              updateLineById(mapLine.id, storedSrc, mapLine.dest_field.key);
            }
          }
        } else if (mapLine.dest_field === null) {
          if (mapLine.source_field) {
            const storedDst = findStoredDestBySource(mapLine.source_field);
            if (storedDst) {
              updateLineById(mapLine.id, mapLine.source_field, storedDst);
            }
          }
        }
      });
      setInitted(true);
    }
  }, [map, updateLineById, globalContext.storedMapping, columns, initted]);

  useEffect(() => {
    updateContext({ mapping: allFieldsVisible ? map : [] });
  }, [allFieldsVisible, map, updateContext]);
  return (
    <StepWrapper step="mapper" className="step-component step-map">
      <Heading title="Map the fields on your CSV to the relevant field in LoudNClear" overrides={{ margin: '8px auto -8px auto' }} desc="" />
      {/*
      <div className="step-map_header">
        <div className="inner">
          <div>Fetched fields</div>
          <div />
          <div>System fields</div>
        </div>
      </div>
      */}
      <div className={`mapper-container ${shouldShowFirstFieldScreen ? 'blockScroll' : ''}`}>
        <AnimatePresence>
          {shouldShowFirstFieldScreen && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="mapper-container_first">
              <div>
                <img src={firstFieldIco} width="80" height="80" alt="First field" />
                <div className="mapper-container_first_title">
                  Great! You just mapped your first connector field!
                </div>
                <div className="mapper-container_first_desc">
                  You can map additional connector fields or start mapping other contact information to your workspace.
                  Can't find the field you are looking for? No worries! you can create as many custom fields as you want.
                </div>
                <Button onClick={() => setSeenFirstFieldScreen(true)}>Got it</Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="mapper">
          <MapperDivider
            label={(
              <>
                Map
                {' '}
                <span>at least one</span>
                {' '}
                of these connector fields
              </>
            )}
          />
          <div className="mapper-fields">
            <div className="mapper-fields_header">
              <div>Fields from your csv file</div>
              <div />
              <div>Fields in LoudNClear</div>
            </div>
            {mandatoryFields.map((f, ind) => (
              <FieldLine
                key={f.id}
                lineIndex={ind}
                totalLines={mandatoryFields.length}
                source_field={f.source_field}
                dest_field={f.dest_field}
                status={f.status}
                columns={columnsOptions}
                sampleData={sampleData}
                fields={[f.dest_field]}
                onUpdateLine={(src, dst) => updateLineById(f.id, src, dst)}
                unChangeable="dest"
              />
            ))}
          </div>
          <MapperDivider
            label={(
              <>
                All fields
              </>
            )}
          />
          <div className="mapper-fields-container">
            <AnimatePresence>
              {!allFieldsVisible && (
                <motion.div
                  className="mapper-fields_optional"
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <img src={hiddenFields} width="158" height="60" alt="" />
                  <div>
                    All the fields in your CSV are here. Before you map them all, please map at least one of the connector fields.
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
            <div className={`mapper-fields ${!allFieldsVisible ? 'mapper-fields-hidden' : ''}`}>
              <div className="mapper-fields_header">
                <div>Fields from your csv file</div>
                <div />
                <div>Fields in LoudNClear</div>
              </div>
              <AnimatePresence>
                {optionalFields.map((f, ind) => (
                  <FieldLine
                    key={f.id}
                    lineIndex={ind}
                    totalLines={optionalFields.length}
                    source_field={f.source_field}
                    dest_field={f.dest_field}
                    status={f.status}
                    columns={columnsOptions}
                    sampleData={sampleData}
                    fields={fieldsListWithCustom}
                    createCustomField={(field) => createCustomField(f.id, field)}
                    onUpdateLine={(src, dst) => updateLineById(f.id, src, dst)}
                    unChangeable="source"
                  />
                ))}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </StepWrapper>
  );
}

export default observer(MapperStep);
