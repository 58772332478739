import { useMutation } from 'react-query';
import { useCommunityContext } from '../../contexts/community';
import NetworkManager from '../../../Network/NetworkManager';
import useTopicsQueries from './useTopicsQueries';

export default function useTopicsMutations() {
  const { communityId } = useCommunityContext();
  const { topics } = useTopicsQueries();
  const create = useMutation((d) => NetworkManager.createTopic(communityId, d.topic), {
    onSuccess: () => {
      topics.refetch();
    },
  });
  const deleteTopic = useMutation((d) => NetworkManager.deleteTopic(communityId, d.id), {
    onSuccess: () => {
      topics.refetch();
    },
  });
  return { create, deleteTopic };
}
