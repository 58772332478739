import segmentContactAddTrigger from './triggers/segment.contact.add';
import segmentAccountAddTrigger from './triggers/segment.account.add';
import contactInteractionAddTrigger from './triggers/contact.interaction.add';
import dictionaryInteractionMatchTrigger from './triggers/dictionary.interaction.match';

const triggers = {
  'segment.contact.add': segmentContactAddTrigger,
  'segment.account.add': segmentAccountAddTrigger,
  'contact.interaction.add': contactInteractionAddTrigger,
  'dictionary.interaction.match': dictionaryInteractionMatchTrigger,
};

export const findTriggerById = (id) => Object.values(triggers).find((t) => t.id === id);

export default triggers;
