import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import LNCSelect from '../../../../../Select/LNCSelect';

export default function EmailFrequency() {
  const { values: { interval }, setFieldValue } = useFormikContext();
  const onSelect = useCallback((newVal) => {
    setFieldValue('interval', newVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="email-group">
      <label>Frequency</label>
      <div className="email-group_field">
        <LNCSelect
          value={interval}
          onSelect={onSelect}
          placeholder="Select"
          items={[{ key: 'weekly', label: 'Weekly' }, { key: 'daily', label: 'Daily' }]}
        />
      </div>
    </div>
  );
}
