import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import keywordIcon from '../../../../../Assets/Images/icons/cat-ico-sets.svg';
import infoIcon from '../../../../../Assets/Images/icons/info-sml.svg';
import useActivitiesFilterContext from '../../context/ActivitiesFilterContext';
import DrawerHandler from '../../state/handler';
import MiniSpinner from '../common/miniSpinner';
import Collapse from './collapse';

function SubContent({ isExpanded, count, isLoading }) {
  if (isLoading) {
    return (
      <MiniSpinner />
    );
  }
  if (!isExpanded) {
    return <>{count}</>;
  }
  return <></>;
}

function KeywordsCollapse() {
  const [isExpanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const { toggleKeyword, filterWordsByLabel } = useActivitiesFilterContext();
  const keywords = useMemo(() => {
    if (DrawerHandler.data.userDetails?.top_keywords) {
      return DrawerHandler.remapKeywordsToLocalSchema();
    }
    return [];
  }, [DrawerHandler.data.userDetails]); // eslint-disable-line
  const keywordsDisplay = useMemo(() => {
    if (!showMore) return keywords.slice(0, 20);
    return keywords;
  }, [showMore, keywords]);
  const hasFilterWords = useMemo(() => Object.keys(filterWordsByLabel).length !== 0, [filterWordsByLabel]);
  if (!DrawerHandler.userData) return <></>;
  return (
    <Collapse
      icon={keywordIcon}
      onExpandChange={setExpanded}
      title="Top keywords"
      subContent={<SubContent count={keywords.length} isExpanded={isExpanded} isLoading={DrawerHandler.isLoading.userDetails} />}
    >
      <div className="collapse-keywords">
        <div className="collapse-keywords_info">
          <img src={infoIcon} width="14" height="14" alt="" />
          Tap a keyword to find relevant activities
        </div>
        {DrawerHandler.isLoading.userDetails ? (
          <>
            <div className="collapse-keywords_item isSkeleton">
              <div>word</div>
            </div>
            <div className="collapse-keywords_item isSkeleton">
              <div>word</div>
            </div>
          </>
        ) : (
          <AnimatePresence>
            {keywordsDisplay.map((k, ind) => (
              <motion.div
                className="collapse-keywords_item"
                style={{ backgroundColor: k.color.bgColor, color: k.color.textColor }}
                onClick={() => toggleKeyword({ label: k.label, bgColor: k.color.bgColor, textColor: k.color.textColor })}
                whileHover={{ scale: 1.1, transition: { duration: 0.25 } }}
                whileTap={{ scale: 0.9, transition: { duration: 0.15 } }}
                key={k.id}
                initial={{ scale: 0, opacity: 0 }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  filter: `grayscale(${hasFilterWords ? (filterWordsByLabel?.[k.label] ? 0 : 1) : 0})`, // eslint-disable-line
                  transition: { delay: (ind / 100).toFixed(2) },
                }}
                exit={{ opacity: 0, scale: 0, transition: { delay: (ind / 100).toFixed(2) * -1 } }}
              >
                <div>{k.label}</div>
              </motion.div>
            ))}
          </AnimatePresence>
        )}
      </div>
      <AnimatePresence initial={false}>
        {!DrawerHandler.isLoading.userDetails && keywords.length > 20 && (
          <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto' }} className="collapse-keywords_showmore" onClick={() => setShowMore(!showMore)}>
            {showMore ? '- Show less' : '+ Show more'}
          </motion.div>
        )}
      </AnimatePresence>
    </Collapse>
  );
}

export default observer(KeywordsCollapse);
