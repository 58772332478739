import { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import slackIcon from '../../../../../Assets/Images/redesign/repo-icon-slack.svg';
import Dal from '../../../../../Data/Dal';
import Select from '../../../common/select';

const { Option } = Select;

const slackWorkspaceNotifyReactor = {
  id: 1,
  service_name: 'slack',
  service_type: 'workspace',
  action: 'notify',
  acceptedMetadata: ['target_id'],
  removeRetro: true,
  validationSchema: Yup.object({
    internal_target_id: Yup.number().required('Required'),
    target_id: Yup.string().required(),
    target_type: Yup.string().oneOf(['user', 'channel']).required(),
  }),
  templates: {
    Display: observer(({ isExample, metadata }) => {
      const targets = useMemo(() => Object.values(toJS(Dal.targets)).flat(), []);
      const targetText = useMemo(() => {
        if (isExample) return 'Someone';
        const thisTarget = targets.find((t) => t.id === metadata.internal_target_id);
        if (!thisTarget) return 'ERROR';
        return `${thisTarget.type === 'channel' ? '#' : '@'}${thisTarget?.name}`;
      }, [isExample, metadata, targets]);
      return (
        <span className="display">
          <img src={slackIcon} width="30" height="30" alt="slack" />
          Notify user on Slack
        </span>
      );
    }),
    Edit: observer(() => {
      const { values, setFieldValue } = useFormikContext();
      const { reactor: { metadata } } = values;
      const targets = useMemo(() => Object.values(toJS(Dal.targets)).flat(), []);
      const handleChange = useCallback((targetId) => {
        const tar = targets.find((t) => t.id === targetId);
        setFieldValue('reactor.metadata', {
          internal_target_id: targetId,
          target_type: tar.type,
          target_id: tar.metadata.id,
        });
      }, [targets, setFieldValue]);
      return (
        <span className="automation-edit-container">
          <span className="highlight then">Then</span>
          {' '}
          notify
          {' '}
          <Select
            filterOption={(input, option) => (option.children?.[1] || '').toLowerCase().indexOf(input.toLowerCase()) >= 0}
            field="reactor.metadata.internal_target_id"
            value={metadata?.internal_target_id || null}
            placeholder="Select"
            onChange={handleChange}
          >
            {targets.map((tar) => (
              <Option key={tar.id} value={tar.id}>
                {tar.type === 'channel' ? '#' : '@'}
                {tar.name}
              </Option>
            ))}
          </Select>
          {' '}
          on
          {' '}
          <span className="highlight flex-align platform">
            <img src={slackIcon} width="16" height="16" alt="slack" />
            Slack
          </span>
        </span>
      );
    }),
  },
};

export default slackWorkspaceNotifyReactor;
