import { Select } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import * as Yup from 'yup';
import Dal from '../../../Data/Dal';
import PlatformTemplate, { PlatformDisplayValue } from './commonTemplates/platform';
import TimespanTemplate, { TimespanDisplayValue } from './commonTemplates/timespan';
import { ReactComponent as SelectDown } from '../../../Assets/Images/icons/select-down.svg';

const { Option, OptGroup } = Select;
const OPERATOR_QUANT_PERCENTAGE_OPTIONS = ['increased', 'decreased'];
const OPERATOR_QUANT_NUMBER_OPTIONS = ['at least', 'less than', 'equals'];

const ContentFilterSchema = {
  validationSchema: Yup.object({
    platform: Yup.mixed().required('Platform is required'),
    has: Yup.mixed().required('Required'),
    dictionary_ids: Yup.mixed().when('has', {
      is: (val) => val === 'interactions_content',
      then: () => Yup.array().min(1, 'Select a dictionary').required('Select dictionaries'),
      otherwise: (schema) => schema,
    }),
    operator: Yup.mixed().when('has', {
      is: (val) => val === 'interactions_count',
      then: () => Yup.string().required('Operator is required'),
      otherwise: (schema) => schema,
    }),
    quantification: Yup.mixed().when('has', {
      is: (val) => val === 'interactions_count',
      then: () => Yup.string().required('Required'),
      otherwise: (schema) => schema,
    }),
    timespan: Yup.mixed().required('Time is required'),
  }),
  fields: [
    {
      label: 'User on',
      key: 'platform',
      defaultValue: null,
      type: 'select',
      styles: {
        flexBasis: '180px',
      },
      Template: (props) => (
        <PlatformTemplate
          {...props}
          exclude={[
            'adobeanalyticsworkspace',
            'pendoworkspace',
            'walmartmytech',
            'walmartnps',
            'walmartnpsvdi',
            'walmartfeedbacks',
            'walmartnpshats',
            'amplitudeworkspace',
            'firebaseworkspace',
            'snowflakeworkspace',
          ]}
        />
      ),
      DisplayValue: PlatformDisplayValue,
    },
    {
      label: 'Has',
      key: 'has',
      defaultValue: null,
      DisplayValue: ({ value }) => {
        if (value === 'interactions_count') return 'Interactions count';
        if (value === 'interactions_content') return 'Interactions content';
        return value;
      },
      Template: observer(({ value, onChange }) => (
        <Select placeholder="Select type" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
          <Option className="superfilter-option" value="interactions_content">Interaction content</Option>
          <Option className="superfilter-option" value="interactions_count">Interactions count</Option>
        </Select>
      )),
    },
    {
      label: 'Dictionaries',
      key: 'dictionary_ids',
      showWhen: (keysValues) => keysValues?.has === 'interactions_content',
      defaultValue: [],
      styles: {
        flexBasis: '200px',
      },
      DisplayValue: observer(({ value }) => {
        if (value.length === 0) return value;
        const dic = Dal.dictionaries.find((dict) => dict.id === value[0]);
        if (dic) {
          return (
            <div className="dictionaries_tags">
              <div className="ant-select-selection-item">
                <span className="ant-select-selection-item-content">{dic.name}</span>
              </div>
              {value.length > 1 && (
                <div className="ant-select-selection-item">
                  <span className="ant-select-selection-item-content">
                    {`+${value.length - 1}`}
                  </span>
                </div>
              )}
            </div>
          );
        }
        return value;
      }),
      Template: observer(({ value, onChange }) => (
        <Select
          dropdownMatchSelectWidth={false}
          placeholder="Select dictionary"
          suffixIcon={<SelectDown />}
          value={value}
          onChange={onChange}
          mode="multiple"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {toJS(Dal.dictionaries).map((dic) => (
            <Option className="superfilter-option" key={dic.id} value={dic.id}>{dic.name}</Option>
          ))}
        </Select>
      )),
    },
    {
      label: 'Operator',
      defaultValue: null,
      key: 'operator',
      showWhen: (keysValues) => keysValues?.has === 'interactions_count',
      DisplayValue: ({ value: opt }) => opt.charAt(0).toUpperCase() + opt.slice(1),
      Template: observer(({ value, onChange }) => (
        <Select dropdownMatchSelectWidth={false} placeholder="Select an operator" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
          {OPERATOR_QUANT_NUMBER_OPTIONS.map((opt) => (
            <Option className="superfilter-option" key={opt} value={opt}>{opt.charAt(0).toUpperCase() + opt.slice(1)}</Option>
          ))}
          {OPERATOR_QUANT_PERCENTAGE_OPTIONS.map((opt) => (
            <Option className="superfilter-option" key={opt} value={opt}>{opt.charAt(0).toUpperCase() + opt.slice(1)}</Option>
          ))}
        </Select>
      )),
    },
    {
      label: 'of',
      defaultValue: null,
      key: 'quantification',
      showWhen: (keysValues) => keysValues?.has === 'interactions_count',
      styles: {
        flexBasis: '50px',
      },
      DisplayValue: ({ value, getValueByKey }) => {
        const op = getValueByKey('operator');
        if (OPERATOR_QUANT_PERCENTAGE_OPTIONS.includes(op)) {
          return `${value}%`;
        }
        return value;
      },
    },
    {
      label: '',
      defaultValue: null,
      key: 'pre_of_none',
      styles: {
        flexBasis: '15px',
      },
      showWhen: (keysValues) => keysValues?.has === 'interactions_count' && !keysValues?.operator,
      Template: () => <div className="prefix_number" />,
    },
    {
      label: '',
      defaultValue: '%',
      key: 'pre_of_percentage',
      className: 'field-prefix',
      styles: {
        flexBasis: '15px',
      },
      DisplayValue: () => <></>,
      showWhen: (keysValues) => keysValues?.has === 'interactions_count' && OPERATOR_QUANT_PERCENTAGE_OPTIONS.includes(keysValues?.operator),
      Template: () => <div className="prefix_number">%</div>,
    },
    {
      label: '',
      defaultValue: '#',
      key: 'pre_of_number',
      className: 'field-prefix',
      styles: {
        flexBasis: '15px',
      },
      DisplayValue: () => <></>,
      showWhen: (keysValues) => keysValues?.has === 'interactions_count' && OPERATOR_QUANT_NUMBER_OPTIONS.includes(keysValues?.operator),
      Template: () => <div className="prefix_number">#</div>,
    },
    {
      label: 'In the',
      key: 'timespan',
      defaultValue: null,
      styles: {
        flexBasis: '160px',
      },
      Template: TimespanTemplate,
      DisplayValue: TimespanDisplayValue,
    },
  ],
};

export default ContentFilterSchema;
