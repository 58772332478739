import { createContext, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import NetworkManager from '../../Network/NetworkManager';

export const SettingsContext = createContext({});

export default function useSettings(communityId, entity) {
  const settingsQuery = useQuery(['settings', entity], () => NetworkManager.getSettings(communityId, entity), {
    enabled: !!communityId,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 15, // 15 minutes stale time
  });

  const updateMutation = useMutation((d) => NetworkManager.updateSetting(communityId, entity, d), {
    onSuccess: () => settingsQuery.refetch(),
  });
  return {
    settingsQuery,
    data: settingsQuery?.data || {},
    updateMutation,
  };
}

export function useSettingsContext() {
  const context = useContext(SettingsContext);
  return context;
}
