import { _quickActionsByCategory } from './_data';

export default function QuickActionsCategories({ category, setCategory }) {
  return (
    <div className="quick-actions_categories">
      <div onClick={() => setCategory('all')} className={`item ${category === 'all' ? 'selected' : ''}`}>
        ALL
      </div>
      {Object.keys(_quickActionsByCategory).map((cat) => (
        <div onClick={() => setCategory(cat)} className={`item ${category === cat ? 'selected' : ''}`} key={cat}>
          {cat}
        </div>
      ))}
    </div>
  );
}
