export default function TopicsLinesHeader() {
  return (
    <div className="to-grid to-header">
      <div>Name</div>
      <div className="to-col-created">Created</div>
      <div>
        Status
      </div>
    </div>
  );
}
