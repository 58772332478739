/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import MiniSpinner from '../../../Skeletons/spinner';
import WorkspaceImg from '../../../../Assets/Images/icons/illustration-workspace.svg';
import useInvite from '../../hooks/useInvite';

export default function InviteScreen() {
  const {
    setFieldValue, values, setFieldError, setErrors,
  } = useFormikContext();
  const { validation } = useInvite(values?.email);
  const [commName, setComName] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailReg = /invited_email=([^&]*)/g;
    const emailResults = [...window.location.search.matchAll(emailReg)];
    setComName(params.get('community_name'));
    if (emailResults[0] && emailResults[0][1]) {
      setFieldValue('email', emailResults[0][1]);
    }
    setFieldError('email', 'LOADING_VERIFY_INVITE');
  }, []); // eslint-disable-line
  useEffect(() => {
    if (validation.status === 'error') {
      setFieldError('email', 'Email is not invited');
    } else {
      setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation.status]);
  return (
    <>
      <div className="invite">
        <p style={{ color: '#3a434a', marginBottom: '14px' }}>
          Welcome,
          {' '}
          <strong>{values?.email?.split('@')[0]}</strong>
        </p>
        <div className="workspace-box">
          {validation.isLoading ? (
            <div><MiniSpinner /></div>
          ) : (!validation.error ? (
            <>
              <div className="workspace-box_circle">
                <img src={WorkspaceImg} width="40" height="40" alt="Workspace" />
              </div>
              <div className="workspace-box_desc">
                <div className="headline">
                  Joining workspace
                </div>
                <div className="workspace-name">
                  {commName}
                </div>
              </div>
            </>
          ) : (
            <div className="form-control_error bigger">
              Your invitation seems to have been expired. Please contact your account manager.
            </div>
          ))}
        </div>
        <div className="invite-signin">
          Already have an account?
          {' '}
          <Link to="/signin">Sign in</Link>
        </div>
      </div>
    </>
  );
}
