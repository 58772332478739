import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import {
  useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import dalInstance from '../../../Data/Dal';
import useDictionaryMutations from '../../../Utils/community/dictionaries/useDictionaryMutations';
import ErrorBoundary from '../../ErrorBoundary/errorBoundary';
import ExamplesTab from './examples/tab';
import FormTab from './form/tab';
import fieldSchema from './form/_fields';
import { findKeywordsDiff, keywordsTransform } from './_utils';

function DictionariesModal({ initialValues, onClose }) {
  const { create, update } = useDictionaryMutations();
  const history = useHistory();
  const isUpdate = useMemo(() => typeof initialValues === 'object' && Object.keys(initialValues).length > 0, [initialValues]);
  const [step, setStep] = useState(isUpdate ? 1 : 0);
  const initted = useRef();
  const handleSubmit = useCallback((values, actions) => {
    if (!isUpdate) {
      create.mutate(values, {
        onSuccess: async (d) => {
          await dalInstance.fetchDictionaries();
          actions.setSubmitting(false);
          history.push(`/dashboard/data/${d.data}`);
          onClose();
        },
        onError: () => {
          actions.setSubmitting(false);
        },
      });
    } else {
      update.mutate({
        id: initialValues.id,
        ...values,
        emotions: values.emotions || [],
        keywords: findKeywordsDiff(initialValues.keywords, values.keywords),
      }, {
        onSuccess: onClose,
        onSettled: () => {
          actions.setSubmitting(false);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);
  useEffect(() => {
    initted.current = true;
    return () => {
      setStep(0);
    };
  }, []);
  useLayoutEffect(() => {
    if (isUpdate) setStep(1);
    return () => {
      setStep(0);
    };
  }, [isUpdate]);
  return (
    <>
      <motion.div
        className="dict-modal-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
      />
      <motion.div
        key="dict-modal"
        initial={{ y: '100%', opacity: 1, x: '-50%' }}
        animate={{ y: '0%', x: '-50%', transition: { delay: 0.3, type: 'tween', duration: 0.5 } }}
        exit={{
          y: '100%', opacity: 0, x: '-50%', transition: { type: 'tween', duration: 0.4 },
        }}
        className="dict-modal"
      >
        <ErrorBoundary componentName="DictionaryModal">
          <div className="dict-modal_header">
            <div className="dict-modal_header_title">
              {isUpdate ? 'Update a dictionary' : 'Create a new dictionary'}
            </div>
            <div className="dict-modal_header_desc">
              Create elaborate queries to get accurate, contextual data from your user-base.
            </div>
          </div>
          <Formik
            initialValues={{
              name: initialValues?.name || '',
              color: initialValues?.color || '#99d2ff',
              keywords: keywordsTransform(initialValues?.keywords || []),
              abbr: initialValues?.abbr || false,
              enrich: initialValues?.enrich || {},
              emotions: initialValues?.emotions || [],
            }}
            onSubmit={handleSubmit}
            validationSchema={fieldSchema.validationSchema}
          >

            <div className="dict-modal_main">
              <Form>
                <div className="dict-modal_main_content" style={step === 1 ? { backgroundColor: 'white' } : {}}>
                  {step === 0 && <ExamplesTab initted={initted.current} setStep={setStep} />}
                  {step === 1 && <FormTab isUpdate={isUpdate} onClose={onClose} setStep={setStep} />}
                </div>
              </Form>
            </div>

          </Formik>
        </ErrorBoundary>
      </motion.div>
    </>
  );
}

export default DictionariesModal;
