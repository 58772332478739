import { ReactComponent as EmailIcon } from '../../../../../Assets/Images/icons/cat-ico-email.svg';
import { ReactComponent as UserIcon } from '../../../../../Assets/Images/icons/cat-ico-user.svg';
import { ReactComponent as InteractionTimeIcon } from '../../../../../Assets/Images/icons/filters/interaction_time.svg';
import { ReactComponent as EmployeeIcon } from '../../../../../Assets/Images/icons/cat-ico-case.svg';
import { ReactComponent as CategoryIcon } from '../../../../../Assets/Images/icons/filters/category.svg';
import { ReactComponent as TopicIcon } from '../../../../../Assets/Images/icons/filters/topic.svg';
import { ReactComponent as SentimentIcon } from '../../../../../Assets/Images/icons/filters/sentiment.svg';
import { ReactComponent as PlatformIcon, ReactComponent as SourceIcon } from '../../../../../Assets/Images/icons/filters/source.svg';
import { ReactComponent as KeywordsIcon } from '../../../../../Assets/Images/icons/filters/keywords.svg';
import { ReactComponent as StringIcon } from '../../../../../Assets/Images/icons/filters/string.svg';
import { ReactComponent as IntIcon } from '../../../../../Assets/Images/icons/filters/number.svg';
import { ReactComponent as DateIcon } from '../../../../../Assets/Images/icons/filters/date.svg';
import { ReactComponent as PhoneIcon } from '../../../../../Assets/Images/icons/cat-ico-phone.svg';
// import tagIco from '../../../Assets/Images/icons/cat-ico-tag.svg';
// import globeIco from '../../../Assets/Images/icons/cat-ico-globe.svg';
const _fields = [
  {
    key: 'interaction_time',
    label: 'Interaction Time',
    icon: <InteractionTimeIcon />,
    type: 'date',
  },
  {
    key: 'category',
    label: 'Category',
    icon: <CategoryIcon />,
    type: 'string[]',
  },
  {
    key: 'topic',
    label: 'Topic',
    icon: <TopicIcon />,
    type: 'string[]',
  },
  {
    key: 'sentiment',
    label: 'Sentiment',
    icon: <SentimentIcon />,
    type: 'string[]',
  },
  {
    key: 'service_name',
    label: 'Platform',
    icon: <PlatformIcon />,
    type: 'exact_string',
  },
  {
    key: 'source',
    label: 'Source',
    icon: <SourceIcon />,
    type: 'exact_string',
  },
  {
    key: 'keywords',
    label: 'Keywords',
    icon: <KeywordsIcon />,
    type: 'string[]',
  },
  {
    key: 'name',
    label: 'Name',
    icon: <UserIcon />,
    type: 'string',
    group: 'User Properties',
  },
  {
    key: 'email',
    label: 'Email',
    icon: <EmailIcon />,
    type: 'string',
    group: 'User Properties',
  },
  {
    key: 'phone',
    label: 'Phone',
    icon: <PhoneIcon />,
    type: 'string',
    group: 'User Properties',
  },
  {
    key: 'assigned_employee',
    label: 'Account Owner',
    icon: <EmployeeIcon />,
    type: 'string',
    group: 'User Properties',
  },
];

export const _fieldsByKey = _fields.reduce((acc, cur) => ({
  ...acc,
  [cur.key]: cur,
}), {});

export const iconsByType = {
  string: <StringIcon />,
  int: <IntIcon />,
  date: <DateIcon />,
};
export default _fields;
