import { motion } from 'framer-motion';
import Progress from './progress';
import MFANote from '../comps/mfa';
import closeIcon from '../../../Assets/Images/icons/header-hamburger-close.svg';
import thumbnail from '../../../Assets/Images/icons/vid-placeholder.png';
import dalInstance from '../../../Data/Dal';
import { observer } from 'mobx-react';

function SideView({ toggleSideView, onMfaToggle }) {
  return (
    <motion.div
      className="homescreen-sideview"
      initial={{ width: '0', overflow: 'hidden' }}
      animate={{ width: '370px' }}
      exit={{ width: '0' }}
    >
      <div onClick={toggleSideView} className="homescreen-sideview_close">
        <img src={closeIcon} width="24" height="24" alt="Close" />
      </div>
      {dalInstance?.cognitoUser?.preferredMFA !== 'SOFTWARE_TOKEN_MFA' && <MFANote onMfaToggle={onMfaToggle} />}
      <Progress />
      {/* <div className="getstarted">
        <div className="section-title">
          Getting started
        </div>
        <div className="getstarted-thumb">
          <img src={thumbnail} width="280" height="180" alt="vvideo" />
        </div>
  </div> */}
    </motion.div>
  );
}

export default observer(SideView);
