import { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import BlueModal from '../../../../Modal/blue';
import ExamplesView from './examplesView';
import AutomateView from './automateView';

export default function ChooseAutomationModal({ isShown, onClose }) {
  const { values, setFieldValue } = useFormikContext();
  const [phase, setPhase] = useState(values?.action ? 2 : 1);
  const handleComplete = useCallback(() => {
    setFieldValue('action._isComplete', true);
    onClose();
  }, [onClose, setFieldValue]);
  useEffect(() => {
    if (!values.action) setPhase(1);
  }, [values]);
  useEffect(() => {
    setPhase(values.action ? 2 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);
  return (
    <BlueModal
      className="choose-automation"
      isShown={isShown}
      onClose={onClose}
      title="Choose automation"
    >
      {phase === 1 ? (
        <ExamplesView setPhase={setPhase} />
      ) : (
        <AutomateView setPhase={setPhase} onComplete={handleComplete} />
      )}
    </BlueModal>
  );
}
