/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useMemo } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { observer } from 'mobx-react';

import './styles.scss';

import exportIcon from '../../Assets/Images/redesign/ui-icon-download-download-purple.svg';
import { ReactComponent as MenuIconDown } from '../../Assets/Images/icons/cat-ico-actions.svg';
import { ReactComponent as CSVIcon } from '../../Assets/Images/icons/cat-ico-table.svg';
import NetworkManager from '../../Network/NetworkManager';

async function handleMenuClick(e) {
  if (e.key === 'item_0') {
    await NetworkManager.exportCrm();
  }
}


function CrmQuickActionView({ disabled, handleOpen, isActive }) {
  const menu = useMemo(() => (
    <Menu className="general-dropdown">
      <Menu.Item onClick={handleOpen}>
        <div className="dropdown-item_img">
          <CSVIcon />
        </div>
        <div className="dropdown-item_text">
          Export as CSV
        </div>
      </Menu.Item>
    </Menu>
  ), []);
  return (
    <Dropdown
      disabled={disabled}
      overlay={menu}
      placement="bottomCenter"
    >
      <div
        className={`general-button ${isActive ? 'active' : ''}`}
        role="button"
        tabIndex="0"
        data-step="super-filter-button"
      >
        {' '}
        {/* for selenium automation */}
        <div className="general-button_img">
          <MenuIconDown />
        </div>
        <span>Actions</span>
      </div>
    </Dropdown>
  );
}

export default observer(CrmQuickActionView);
