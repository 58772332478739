/* eslint-disable jsx-a11y/label-has-associated-control */
import { useField } from 'formik';
import BoxMultiPicker from '../../../../../BoxMultiPicker';

export default function EmailPicker() {
  const [field] = useField('recipients');
  return (
    <div className="email-group">
      <label>Recipients</label>
      <div className="email-group_field">
        <BoxMultiPicker placeholder="" btnText="Add email" field={field} fieldName="recipients" />
      </div>
    </div>
  );
}
