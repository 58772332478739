import { Tooltip } from 'antd';

const getActivityActionTitle = (platformName) => {
  switch (platformName) {
    case 'email':
      return ['Sent email'];
    case 'twitter':
      return ['Posted on Twitter', 'Engaged on'];
    case 'facebook':
      return ['Posted on Facebook', 'Engaged on'];
    case 'zendesk':
      return ['Opened a ticket in Zendesk', 'Message on Zendesk'];
    case 'intercom':
      return ['Message on Intercom', 'Message on Intercom'];
    case 's3':
      return ['Added from file', 'Added from file'];
    case 'reddit':
      return ['Posted on Reddit', 'Posted on Reddit'];
    case 'salesforce':
      return ['Appeared in Salesforce', 'Appeared in Salesforce'];
    case 'slack':
      return ['Appeared on Slack', 'Appeared on Slack'];
    case 'mixpanel':
      return ['Product action', 'Product action'];
    case 'segment':
      return ['Product action', 'Product action'];
    default:
      return ['Appeared on', 'Appeared on'];
  }
};

const prefix = {
  twitter: '@',
  facebook: '@',
  zendesk: '#',
  slack: '#',
};

export default function ActivityTitle({ act, asset, assetChannels }) {
  if (act.instance_type === 'uup_changes') {
    return (
      <span>
        <span className="activity-acting-user">
          {act.label}
        </span>
        changed
      </span>
    );
  }
  if (act.instance_type === 'tag_history') {
    const txt = (
      <span>
        <span className="activity-acting-user">
          {act.user.user_name}
        </span>
        {act.status === 'ASSIGN' ? 'Assigned tag' : 'Removed tag'}
      </span>
    );
    const seg = (
      <Tooltip
        color="white"
        overlayStyle={{ pointerEvents: 'none', maxWidth: '800px' }}
        title={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{act.tag.tag_name}</span>}
      >
        <span
          className="activity-tag"
        // style={{ backgroundColor: act.segment.bgColor, color: act.segment.textColor }}
        >
          <span>{act.tag.tag_name}</span>
        </span>
      </Tooltip>
    );
    return (
      <>
        {txt}
        {seg}
      </>
    );
  }
  if (act.instance_type === 'segment_history') {
    const txt = act.status === 'ENTER' ? 'Added to' : 'Removed from';
    const seg = (
      <Tooltip
        color="white"
        overlayStyle={{ pointerEvents: 'none', maxWidth: '800px' }}
        title={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{act.segment.name}</span>}
      >
        <span
          className="activity-segment"
          style={{ backgroundColor: act.segment.bgColor, color: act.segment.textColor }}
        >
          <span>{act.segment.name}</span>
        </span>
      </Tooltip>
    );
    return (
      <>
        {txt}
        {seg}
      </>
    );
  }
  if (asset && asset.name) {
    return (
      <span>
        {getActivityActionTitle(asset.service_name)[1]}
        <span className="emphasize">
          {asset.service_name === 'slack' ? (
            <>
              #
              {assetChannels?.[act.channel_id]?.channel_name || 'channel'}
            </>
          ) : (
            <>
              {prefix?.[asset.service_name]}
              {act.type ? act.type : asset.name}
            </>
          )}
        </span>
      </span>
    );
  }
  return (
    <span>
      {getActivityActionTitle(asset?.service_name)[0]}
    </span>
  );
}
