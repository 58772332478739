import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import chartTypes from '../../_data/chartTypes';

export default function ChooseStep({ setStep }) {
  const { setFieldValue } = useFormikContext();
  const clickHandler = useCallback((v) => {
    setFieldValue('type', v);
    setStep(1);
  }, [setFieldValue, setStep]);
  return (
    <div className="board-edittor_step step-choose">
      <div className="minititle">Add a new chart</div>
      <div className="minisub">Select chart type</div>
      <div className="step-choose_buttons">
        {chartTypes.map((opt) => (
          <div key={opt.type} onClick={() => clickHandler(opt.type)} className="item" style={opt.disabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
            <div className="item_image">{opt.image}</div>
            <div className="item_text">{opt.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
