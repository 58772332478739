import { Input } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useMemo, useLayoutEffect, useState,
} from 'react';
import { observer } from 'mobx-react';
import useDebounce from '../../../Utils/useDebounce';

function Field({
  fieldKey, schema, value, onChange, error, editMode, getValueByKey, setValueByKey,
}) {
  const [isErrorVisible, setErrorVisible] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);
  const debouncedError = useDebounce(error, 400);

  const classes = useMemo(() => {
    const list = ['filterline-field', `field--${fieldKey}`];
    schema.className && list.push(schema.className);
    error && list.push('error');
    return list.join(' ');
  }, [schema, error, fieldKey]);

  const handleMouseEnter = () => {
    typeof error === 'string' && setErrorVisible((cur) => (cur === 1 ? 1 : true));
    setMouseIn(true);
  };

  const handleMouseLeave = () => {
    typeof error === 'string' && setErrorVisible((cur) => (cur === 1 ? 1 : false));
    setMouseIn(false);
  };

  const ValueComponent = useMemo(() => {
    if (editMode) {
      if (schema.Template) {
        return <schema.Template key={schema.key} setValueByKey={setValueByKey} getValueByKey={getValueByKey} value={value} onChange={onChange} />;
      }
      return <Input key={schema.key} type="text" value={value} onChange={(e) => onChange(e.target.value)} />;
    }
    return (
      <span className="currentValue">
        {schema.DisplayValue ? <schema.DisplayValue value={value} getValueByKey={getValueByKey} /> : value}
      </span>
    );
  }, [schema, editMode, onChange, value, getValueByKey, setValueByKey]);

  useLayoutEffect(() => {
    if (typeof error === 'string') {
      setErrorVisible(1);
      setTimeout(() => {
        setErrorVisible((cur) => cur === true ? true : false); // eslint-disable-line
      }, 2000);
    } else {
      setErrorVisible(false);
    }
  }, [error]);
  return (
    <div
      className={classes}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ ...schema?.styles }}
    >
      <AnimatePresence>
        {isErrorVisible && (
          <motion.div
            key={`${fieldKey}-error`}
            className="filterline-field_error"
            initial={{ opacity: 0, y: '50%', x: '-50%' }}
            animate={{ opacity: 1, y: '-100%', x: '-50%' }}
            exit={{ opacity: 0 }}
          >
            {typeof debouncedError === 'string' ? debouncedError : error}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {(editMode || (!editMode && mouseIn)) && (
          <motion.label
            initial={{
              opacity: 0, width: 0, x: '0px', y: '1px', overflow: 'hidden',
            }}
            animate={{
              opacity: 1, width: 'auto', x: !editMode ? '-5px' : '0px', y: '1px', overflow: 'hidden',
            }}
            exit={{
              opacity: 0, width: 0, x: '0px', y: '1px', overflow: 'hidden',
            }}
            transition={{ type: 'linear', ease: 'easeOut', duration: 0.3 }}
          >
            {schema.label}
          </motion.label>
        )}
      </AnimatePresence>
      {ValueComponent}
    </div>
  );
}

export default observer(Field);
