import { motion } from 'framer-motion';
import { ReactComponent as ListMode } from '../../../../../Assets/Images/icons/revamp/cat-ico-list.svg';
import { ReactComponent as DashboardMode } from '../../../../../Assets/Images/icons/revamp/cat-ico-dashboard.svg';

export default function ViewModes({ view, setView }) {
  return (
    <div className="dictionary-modes">
      <div onClick={() => setView(0)} className={`dictionary-modes_mode ${view === 0 ? 'selected' : ''}`}>
        {view === 0 && <motion.div layoutId="mode_bg" className="dictionary-modes_mode_bg" />}
        <ListMode />
      </div>
      <div onClick={() => setView(1)} className={`dictionary-modes_mode ${view === 1 ? 'selected' : ''}`}>
        {view === 1 && <motion.div layoutId="mode_bg" className="dictionary-modes_mode_bg" />}
        <DashboardMode />
      </div>
    </div>
  );
}
