import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect } from 'react';
import { ReactComponent as ToastIco } from '../../../Assets/Images/icons/automation/toast-success.svg';

export default function Toast({
  isShown, status, text, duration, onClose,
}) {
  useEffect(() => {
    let t = null;
    if (isShown) {
      t = setTimeout(onClose, (duration || 9) * 1000);
    }
    return () => t ? clearTimeout(t) : null;
  }, [isShown, onClose, duration]);
  return (
    <AnimatePresence>
      {isShown && (
        <motion.div
          className={`at-toast at-toast-${status}`}
          initial={{ y: '-100%', x: '-50%', opacity: 0 }}
          animate={{
            y: '0%', x: '-50%', opacity: 1, pointerEvents: 'auto',
          }}
          exit={{
            y: '-150%', x: '-50%', opacity: 0, pointerEvents: 'none',
          }}
        >
          <div className="at-toast_icon">
            <ToastIco />
          </div>
          <div className="at-toast_title">
            {text}
          </div>
          <div className="at-toast_dismiss" onClick={onClose}>
            Dismiss
          </div>
          <motion.div
            className="at-toast_bar"
          >
            <motion.div
              className="at-toast_bar_percentage"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              exit={{ scaleX: 0 }}
              transition={{ type: 'linear', duration: duration || 9 }}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
