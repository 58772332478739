import moment from 'moment';
import totalIco from '../../../Assets/Images/icons/automation/automate-total.svg';
import lastIco from '../../../Assets/Images/icons/automation/automate-recent.svg';

export default function Invocation({ timestamps, total }) {
  return (
    <div className="invocation">
      <div className="invocation-total invocation-line">
        <img src={totalIco} width="20" height="20" alt="" />
        <span>
          {total}
          {' '}
          times
        </span>
      </div>
      {timestamps.length !== 0 && (
        <div className="invocation-last invocation-line">
          <img src={lastIco} width="20" height="20" alt="" />
          <span>
            {moment(timestamps[0]).format('MMM DD YYYY')}
          </span>
        </div>
      )}
    </div>
  );
}
