// import { ReactComponent as HomeLogo } from '../../Assets/Images/icons/sidebar/ico-dashboard-paua.svg';
// import { ReactComponent as SetsLOgo } from '../../Assets/Images/icons/sidebar/ico-sets-paua.svg';
// import { ReactComponent as BoardsLogo } from '../../Assets/Images/icons/ico-boards-paua.svg';
// import { ReactComponent as CRMLOgo } from '../../Assets/Images/icons/sidebar/ico-crm-paua.svg';
import { ReactComponent as IntLogo } from '../../Assets/Images/icons/sidebar/ico-integration-paua.svg';
import { ReactComponent as TopicsLogo } from '../../Assets/Images/icons/topics-icon.svg';
import { ReactComponent as LogoutLogo } from '../../Assets/Images/icons/sidebar/ico-logout-paua.svg';
import { ReactComponent as QueryLogo } from '../../Assets/Images/icons/query-icon.svg';
import { ReactComponent as InviteLogo } from '../../Assets/Images/icons/sidebar/ico-invite-paua.svg';
import { ReactComponent as PbsIcon } from '../../Assets/Images/icons/playbooks/nav-unit-playbook.svg';

const schema = [
  // {
  //   path: '/dashboard/home',
  //   label: 'Home',
  //   Icon: HomeLogo,
  // },
  {
    path: '/dashboard/queries',
    label: 'Queries',
    Icon: QueryLogo,
  },
  {
    path: '/dashboard/playbooks',
    label: 'Playbooks',
    Icon: PbsIcon,
  },
  {
    path: '/dashboard/integrations',
    label: 'Integrations',
    Icon: IntLogo,
  },
  {
    label: 'Topics',
    Icon: TopicsLogo,
    path: '/dashboard/topics',
    isBottom: true,
  },
  {
    label: 'Invite',
    Icon: InviteLogo,
    action: 'invite',
    isBottom: true,
  },
  {
    label: 'Logout',
    Icon: LogoutLogo,
    action: 'logout',
    isBottom: true,
  },
];

export default schema;
