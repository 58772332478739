import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import Dal from '../../Data/Dal';

const SidebarItem = ({
  path, label, Icon, isSelected, onMouseEnter,
}) => (
  <Link
    to={path}
    className={`sidebar-item ${isSelected ? 'selected' : ''}`}
    onMouseEnter={onMouseEnter}
    data-step={path}
  >
    <div className="sidebar-item_inner">
      <Icon />
    </div>
  </Link>
);

export const SidebarActionItem = observer(
  ({
    label, history, Icon, onMouseEnter, Template, action,
  }) => {
    const actionMapper = {
      invite: () => {
        runInAction(() => {
          Dal.showInviteUser = 1;
        });
      },
      logout: () => {
        Modal.confirm({
          centered: true,
          title: 'Confirm logout',
          okText: 'Log out',
          cancelText: 'Cancel',
          onOk: () => {
            Dal.signOut().finally(() => {
              history.push('/signin');
            });
          },
        });
      },
    };
    return (
      <>
        {Template ? (
          <Template />
        ) : (
          <div
            className={`sidebar-item sidebar-item__${label}`}
            onMouseEnter={onMouseEnter}
            onClick={actionMapper[action]}
            data-step={`sidebar-item__${label}`}
          >
            <div className="sidebar-item_inner">
              <Icon />
            </div>
          </div>
        )}
      </>
    );
  },
);

export default SidebarItem;
