import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';
import App from './App';

import './index.css';

ReactDOM.render(
  <div id="wrapper">
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </div>,
  document.getElementById('root'),
);

serviceWorker.unregister();
