import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { ReactComponent as Collapse } from '../../../../Assets/Images/icons/playbooks/chev-container.svg';

export default function Collapser({
  title, defaultOpen, action, children,
}) {
  const [isOpen, setOpen] = useState(!!defaultOpen);
  return (
    <div className={`collapser ${isOpen ? 'open' : ''}`}>
      <div className="collapser-box" onClick={() => setOpen(!isOpen)}>
        <div className="collapser-box_icon"><Collapse /></div>
        <div className="collapser-box_title">{title}</div>
        <div className="collapser-box_action">{action}</div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, overflow: 'hidden' }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
