const getUserSubreddits = async (token) => {
  const subreddits = [];
  const headers = { Authorization: `Bearer ${token}` };
  const options = {
    method: 'GET',
    headers,
  };
  let url = 'https://oauth.reddit.com/subreddits/mine.json';
  let shouldFetch = true;
  let after = null;
  while (shouldFetch) {
    if (after) {
      url = `${url}?after=${after}`;
    }
    const response = await fetch(url, options);
    const data = await response.json();

    const subredditsResults = data.data.children;
    subredditsResults.forEach((s) => {
      let icon = s.data.community_icon;
      if (icon === '') {
        icon = s.data.icon_img;
      }
      if (icon === '' && s.data.header_img !== null) {
        icon = s.data.header_img;
      }
      if (icon.includes('&amp;')) {
        icon = icon.replaceAll('amp;', '');
      }

      subreddits.push({
        id: s.data.name,
        name: s.data.display_name,
        picture: icon,
        followers_count: s.data.subscribers,
        tag: s.data.user_is_moderator ? 'Admin' : null,
      });
    });
    if (data.data && data.data.after) {
      after = data.data.after;
    }
    shouldFetch = after === true;
  }
  return subreddits;
};

export { getUserSubreddits };
