import { motion } from 'framer-motion';

export default function TabWrapper({ children, tabKey, ...rest }) {
  return (
    <motion.div
      key={tabKey}
      {...rest}
    >
      {children}
    </motion.div>
  );
}
