/* eslint-disable max-len */
import { useCallback, useMemo, useState } from 'react';
import DropdownMenu from '../../DropdownMenu';
import { ReactComponent as TrashIcon } from '../../../Assets/Images/icons/dashboards/trash.svg';
import { ReactComponent as Dots } from '../../../Assets/Images/icons/playbooks/dots.svg';
import useTopicsMutations from '../../../Utils/community/topics/useTopicsMutations';
import DeleteModal from '../../Modal/delete/deleteModal';

export default function TopicAction({ topicId }) {
  const { deleteTopic } = useTopicsMutations();
  const [showDelete, setShowDelete] = useState(false);
  const handleDeleteClick = useCallback(() => {
    setShowDelete(true);
  }, []);

  const items = useMemo(
    () => [
      {
        key: 'Remove',
        label: 'Delete topic',
        danger: true,
        icon: <TrashIcon />,
        onClick: handleDeleteClick,
      },
    ],
    [],
  );
  return (
    <>
      <DropdownMenu
        onVisibleChange={() => console.log('c')}
        button={<div className="dot-btn"><Dots /></div>}
        items={items}
      />
      {showDelete && (
        <DeleteModal
          title="Are you sure?"
          desc="Disabling this topic will turn off monitoring for this topic in your workspace. New customer interactions related to it won't be tracked, but you can still create queries using the disabled topic."
          isShown={showDelete}
          onClose={() => setShowDelete(false)}
          entityId={topicId}
          deleteMutation={deleteTopic}
        />
      )}
    </>
  );
}
