export default function FormHeader({
  img, className, title, desc,
}) {
  return (
    <div
      className={`${className} onboard-header`}
      style={{ marginBottom: '20px' }}
    >
      <img src={img} alt={title} width="80" height="80" />
      <div className="text">
        <div className="text-title">{title}</div>
        <div className="text-desc">{desc}</div>
      </div>
    </div>
  );
}
