import iconsMapping from '../iconsMapping';

const measureOptions = [
  {
    key: 'contacts',
    label: 'Contacts',
    icon: iconsMapping.measureY.contacts,
  },
  {
    key: 'accounts',
    label: 'Accounts',
    icon: iconsMapping.measureY.accounts,
  },
  {
    key: 'interactions',
    label: 'Interactions',
    icon: iconsMapping.measureY.interactions,
  },
  {
    key: 'actions',
    label: 'Actions',
    icon: iconsMapping.measureY.actions,
  },
  {
    key: 'sources',
    label: 'Sources',
    icon: iconsMapping.measureY.sources,
  },
];

export default measureOptions;
