import { observer } from 'mobx-react';
import {
  useEffect, useCallback, useMemo,
} from 'react';
import dalInstance from '../../../Data/Dal';
import WidgetEdittor from '../edittor/modal';
import Grid from '../grid';
import useBoard, { BoardContext } from '../hooks/useBoard';
import '../styles/board.scss';
import { BoardActionsPortal } from './actions';
import SaveChanges from './editSaveModal';

function Board({
  boardId, actionsNodeRef, segmentBoard, onEditModeChange, onBoardCreated,
}) {
  const board = useBoard(dalInstance.communities?.[0]?.id, boardId, segmentBoard, onBoardCreated);
  const {
    editMode, setEditMode, updateLayout, setDraft, thisBoard,
  } = board;

  const classes = useMemo(() => {
    const l = ['board'];
    if (editMode) l.push('board-edit');
    if (segmentBoard) l.push('board-segment');
    return l.join(' ');
  }, [editMode, segmentBoard]);

  const handleEditSave = useCallback(() => {
    updateLayout.mutate('', {
      onSuccess: () => {
        setEditMode(false);
      },
    });
  }, [setEditMode, updateLayout]);

  const handleCancelEdit = useCallback(() => {
    setEditMode(false);
    setDraft((cur) => ({ ...cur, layout: thisBoard.layout }));
  }, [setDraft, setEditMode, thisBoard]);

  useEffect(() => {
    onEditModeChange(editMode);
  }, [editMode]); // eslint-disable-line
  return (
    <BoardContext.Provider value={board}>
      <WidgetEdittor />
      {editMode && <SaveChanges onCancel={handleCancelEdit} onSave={handleEditSave} isLoading={updateLayout.isLoading} />}
      <div className={classes}>
        <BoardActionsPortal node={actionsNodeRef} />
        <Grid />
      </div>
    </BoardContext.Provider>
  );
}

export default observer(Board);
