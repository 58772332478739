import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { BrowserRouter as Router } from 'react-router-dom';
import dalInstance from './Data/Dal';
import ReactRouter from './Router/router';
import useCommunity from './Utils/community/useCommunity';
import { CommunityContext } from './Utils/contexts/community';

const Main = ({ isLoadingUser }) => {
  const community = useCommunity({
    communityId: dalInstance.getCurrentCommunityId(),
    assets: toJS(dalInstance?.assets) || [],
    dictionaries: toJS(dalInstance?.dictionaries) || [],
    customFields: toJS(dalInstance?.customFields) || [],
  }); // This will refresh the entire CommunityContext when these Dal variables are changed
  return (
    <CommunityContext.Provider value={community}>
      <Router>
        <ReactRouter isLoadingUser={isLoadingUser} />
      </Router>
    </CommunityContext.Provider>
  );
};
export default observer(Main);
