import { createPortal } from 'react-dom';

export default function ActivityFilterPortal({ children, to }) {
  if (to) {
    return (
      createPortal(
        children,
        to,
      )
    );
  }
  return <></>;
}
