import React from 'react';

const checkMarkIcon = ({ fill, width = 18, height = 18 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m7.473 10.772 6.19-6.272L15 5.856 7.473 13.5 3 8.981 4.354 7.61z"
      fill={fill}
      fillRule="evenodd"
    />
  </svg>
);

export default checkMarkIcon;
