/* eslint-disable no-return-assign */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import dalInstance from '../../Data/Dal';
import NetworkManager from '../../Network/NetworkManager';
import ErrorText from './errorText';

const logError = ({
  error, info, component, communityName, communityId, user, additionalInfo,
}) => {
  NetworkManager.sendErrorToSlack(`❌ *UI ERROR (${process.env.REACT_APP_ENV})*\n
    *Component:* ${component}\n
    *Community:* ${communityName} (${communityId})\n
    *User*: ${user} \n
    ${Object.keys(additionalInfo || {}).reduce((acc, cur) => acc += `*${cur}*: ${typeof additionalInfo[cur] === 'object' ? JSON.stringify(additionalInfo[cur], null, 2) : additionalInfo[cur]}\n`, '')}
    *Error:* ${error}\n
    ${info?.componentStack}`);
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError({
      error,
      info: errorInfo,
      communityName: toJS(dalInstance.communities)[0].name,
      communityId: toJS(dalInstance.communities)[0].id,
      user: toJS(dalInstance.user)?.email,
      additionalInfo: this.props?.additionalInfo || {},
      component: this.props.componentName || 'NULL',
    });
  }

  render() {
    if (this.state.hasError) {
      const style = (() => {
        const obj = {};
        const px = (v) => Number.isInteger(v) ? `${v}px` : v;
        if (this.props.dimensions) {
          if (this.props.dimensions[0]) obj.width = px(this.props.dimensions[0]);
          if (this.props.dimensions[1]) obj.height = px(this.props.dimensions[1]);
        }
        return obj;
      })();
      // You can render any custom fallback UI
      return (
        <ErrorText style={style} />
      );
    }

    return this.props?.Component || this.props.children;
  }
}

export default observer(ErrorBoundary);
