import HubspotAccount from './hubspotworkspace';
import MixpanelProjectSchema from './mixpanelproject';
import PendoWorkspaceSchema from './pendoworkspace';

const assetsSchemaMatch = {
  pendoworkspace: PendoWorkspaceSchema,
  hubspotaccount: HubspotAccount,
  mixpanelproject: MixpanelProjectSchema,
};

export default assetsSchemaMatch;
