import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import './styles/index.scss';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';
import SelectStep from './steps/select';
import dalInstance from '../../Data/Dal';
import ProcessStep from './steps/process';
import CreateSegmentView from './views/createSegment';

const steps = {
  0: SelectStep,
  1: ProcessStep,
  filter: CreateSegmentView,
};

function CRMExport({
  isShown, isSolelyFilter, addSegmentFromExport, onClose,
}) {
  const [phase, setPhase] = useState(0);
  const [data, setData] = useState({
    mappings: {
      segments: [],
      fields: [],
    },
    withHistory: false,
    fileName: 'crm-export.csv',
    activeSelect: null,
  });
  const CurrentComp = useMemo(() => {
    if (isSolelyFilter) return steps.filter;
    return steps[phase];
  }, [phase, isSolelyFilter]);

  const overlayClickHandler = useCallback(() => {
    if (data.activeSelect) {
      setData((cur) => ({
        ...cur,
        activeSelect: null,
      }));
    } else {
      onClose();
    }
  }, [data, onClose]);

  useEffect(() => {
    const cu = toJS(dalInstance.customFields);
    if (cu.length === 0) {
      dalInstance.getAllCustomFields();
    }
  }, []);
  useEffect(() => {
    setPhase(0);
    const applyData = {
      mappings: {
        segments: [],
        fields: [],
      },
      fileName: 'crm-export.csv',
      activeSelect: null,
    };
    const selectedSegment = toJS(dalInstance.selectedCrmSegmentItem);
    if (selectedSegment && selectedSegment.id) {
      applyData.mappings.segments.push(selectedSegment.id);
    }
    setData(applyData);
  }, [isShown]);
  useEffect(() => {
    setData((cur) => ({
      ...cur,
      fileName: (() => {
        const segNames = cur.mappings.segments.reduce((acc, curr) => {
          const seg = dalInstance.getSegmentById(curr);
          if (seg) return [...acc, seg.name];
          return acc;
        }, []);
        return `${segNames.join(',')}${segNames.length === 0 ? 'All_' : '_'}${moment().format('DD-MM-YY')}.csv`;
      })(),
    }));
  }, [data.mappings]);
  return (
    <div className="export">
      <AnimatePresence>
        {isShown && (
          <motion.div
            key="export-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="export-overlay"
            onClick={overlayClickHandler}
          />
        )}
        {isShown && (
          <motion.div
            key="export-con"
            initial={{ opacity: 0, y: '50%', x: '-50%' }}
            animate={{
              opacity: 1, y: '0%', x: '-50%', transition: { type: 'tween', duration: 0.25, delay: 0.2 },
            }}
            exit={{ opacity: 0, x: '-50%' }}
            className="export-modal"
          >
            <div className="export-modal_content">
              <AnimatePresence>
                <CurrentComp data={data} addSegmentFromExport={addSegmentFromExport} setData={setData} />
              </AnimatePresence>
            </div>
            <div className="export-modal_footer">
              <Button className="btn_back" style={{ marginRight: phase === 0 && !isSolelyFilter ? '15px' : '0px' }} onClick={onClose}>Back</Button>
              {phase === 0 && !isSolelyFilter && <Button className="btn_next" onClick={() => setPhase(1)}>Export</Button>}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default observer(CRMExport);
