/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './styles.scss';
import { Tooltip } from 'antd';

import dashboardMode1 from '../../Assets/Images/redesign/dashboard-mode-3.svg';
import dashboardMode2 from '../../Assets/Images/redesign/ui-icon-charts-charts-grey.svg';
import dashboardMode3 from '../../Assets/Images/redesign/general-history-ui-history-ico-gray.svg';

import { ReactComponent as ListMode } from '../../Assets/Images/icons/revamp/cat-ico-list.svg';
import { ReactComponent as DashboardMode } from '../../Assets/Images/icons/revamp/cat-ico-dashboard.svg';
import { ReactComponent as HistoryMode } from '../../Assets/Images/icons/revamp/cat-ico-recent.svg';

import dashboardMode1Selected from '../../Assets/Images/redesign/dashboard-mode-3-selected.svg';

class CrmViewModeView extends Component {
  componentDidMount() { }

  imageClick = (mode) => {
    this.props.onModeChange(mode);
  };

  generateClassName = (ind) => `dashboard-options_item ${this.props.selectedMode === ind ? 'selected' : ''}`;

  render() {
    return (
      <div className="dashboard-options" style={{ height: '40px' }}>
        <div className={this.generateClassName(0)}>
          <Tooltip
            color="white"
            title={<span className="tooltip-body">CRM view</span>}
            placement="top"
          >
            <ListMode onClick={() => this.imageClick(0)} />
          </Tooltip>
        </div>
        <div style={this.props.selectedSegment?.id ? {} : { opacity: 0.5, pointerEvents: 'none' }} className={this.generateClassName(1)}>
          <Tooltip
            color="white"
            title={<span className="tooltip-body">Dashboard view</span>}
            placement="top"
          >
            <DashboardMode onClick={() => this.imageClick(1)} />
          </Tooltip>
        </div>
        <div style={this.props.selectedSegment?.id ? {} : { opacity: 0.5, pointerEvents: 'none' }} className={this.generateClassName(2)}>
          <Tooltip
            color="white"
            title={<span className="tooltip-body">History</span>}
            placement="top"
          >
            <HistoryMode onClick={() => this.imageClick(2)} />
          </Tooltip>
        </div>
      </div>
    );
  }
}

//
export default observer(CrmViewModeView);
