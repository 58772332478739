import { useParams } from 'react-router-dom';
import Ico from '../../../../../Assets/Images/icons/calend-ico.svg';
import useQueryEmailsQueries from '../../../hooks/useQueryEmailsQueries';

export default function EmailText() {
  const { queryId } = useParams();
  const { emails } = useQueryEmailsQueries(Number(queryId));
  if (emails.data && emails.data.isEnabled) {
    return (
      <div className="email-report-text">
        <img src={Ico} width="16" height="16" alt="" />
        Report scheduled
      </div>
    );
  }
  return <></>;
}
