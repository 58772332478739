import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useRef } from 'react';
import { ReactComponent as CloseIco } from '../../../Assets/Images/icons/ui-x-ico-active.svg';

const MultiItem = ({ itemKey, label, handleSelect }) => (
  <motion.div
    key={itemKey}
    className="item-multi"
    animate={{ scale: 1 }}
    exit={{ scale: 0 }}
    initial={{ scale: 0 }}
  >
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleSelect(itemKey);
      }}
      className="item-multi-icon"
    >
      <CloseIco />
    </span>
    <span>{label}</span>
  </motion.div>
);

export default function LNCSelectValue({
  value, valueSchema, isMulti, placeholder, handleSelect,
}) {
  const ref = useRef();

  const handleMultiVariableKey = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSelect(e.target.value);
        ref.current.value = '';
      }
    },
    [handleSelect],
  );
  if (isMulti === 'variable' && Array.isArray(value)) {
    return (
      <div
        className="selected-item multi-wrapper"
        onClick={(e) => {
          e.stopPropagation();
          ref.current.focus();
        }}
      >
        <AnimatePresence>
          {value?.map((vs) => (
            <MultiItem itemKey={vs} key={vs} label={vs} handleSelect={handleSelect} />
          ))}
        </AnimatePresence>
        <input
          ref={ref}
          onKeyDown={handleMultiVariableKey}
          placeholder={placeholder}
          className="multi-variable-input"
          type="text"
        />
      </div>
    );
  }
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return <div className="placeholder">{placeholder}</div>;
  }
  if (isMulti && valueSchema) {
    return (
      <div className="selected-item multi-wrapper">
        <AnimatePresence>
          {valueSchema?.map((vs) => (
            <MultiItem itemKey={vs.key} key={vs.key} label={vs.label} handleSelect={handleSelect} />
          ))}
        </AnimatePresence>
      </div>
    );
  }
  return (
    <div className="selected-item">
      {valueSchema?.icon && <span className="icon">{valueSchema.icon}</span>}
      <span>{valueSchema?.label}</span>
    </div>
  );
}
