import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import assetsSchemaMatch from '../../Data/assets/_match';
import MiniSpinner from '../Crm/Drawer/comps/common/miniSpinner';

export default function AfterIntegration() {
  const params = useParams();
  const location = useLocation();
  useEffect(() => {
    if (params?.service && location?.search) {
      const assetSchema = assetsSchemaMatch[params.service];
      const search = new URLSearchParams(location.search);
      const extracted = Object.keys(assetSchema.oauth.extractors.params).reduce((acc, cur) => {
        acc[assetSchema.oauth.extractors.params[cur]] = search.get(cur);
        return acc;
      }, {});
      window.opener.postMessage({
        [params.service]: extracted,
      }, '*');
    }
  }, [params, location]);
  return (
    <div style={{
      width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', display: 'flex',
    }}
    >
      <MiniSpinner size="50px" />
    </div>
  );
}
