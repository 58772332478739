import { useMemo } from 'react';
import GhostButton from '../../Buttons/ghostButton';
import { ReactComponent as MoreIcon } from '../../../Assets/Images/icons/entity-filter.svg';
import DropdownMenu from '../../DropdownMenu';

export default function EntitySidebarToggles({ setShowHidden, showHidden }) {
  const menu = useMemo(() => [
    {
      key: 'show_hidden',
      label: 'Show hidden folders',
      icon: <MoreIcon />,
      onClick: (e) => { setShowHidden(!showHidden); if (e) { e.stopPropagation(); } },
      toggle: {
        // onChange: () => setShowHidden(!showHidden),
        isChecked: showHidden,
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [showHidden]);
  return (
    <>
      <DropdownMenu
        button={(
          <div>
            <GhostButton icon={<MoreIcon />} />
          </div>
        )}
        items={menu}
      />

    </>
  );
}
