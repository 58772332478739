import { useInfiniteQuery, useQuery } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';
import { useCommunityContext } from '../../contexts/community';
import dalInstance from '../../../Data/Dal';

export default function useDictionaryQueries(dictId, filter, thread) {
  const { communityId } = useCommunityContext();
  const matches = useInfiniteQuery(['dictionary', 'matches', dictId, filter], ({ pageParam = 0 }) => NetworkManager.getMatches(communityId, { filter, dictionaryId: dictId, page: pageParam + 1 }), {
    staleTime: 1000 * 30,
    enabled: !!dictId,
    retry: 7,
    retryDelay: 3000 * 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: (d) => {
      if (d?.pages?.[0]?.data?.length === 0) return 1000 * 15;
      return false;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.length < 100) return undefined;
      return pages.length;
    },
    onSuccess: (resp) => {
      dalInstance.updateDictionaryCount(Number(dictId), resp.pages?.[0]?.total);
    },
  });

  const threadQuery = useQuery(
    ['dictionary', 'thread', dictId, thread],
    () => NetworkManager.getInteractionsThread(communityId, {
      dictionaryId: dictId, originId: thread.origin_id, parentId: thread.parent_id, originTime: thread.origin_time,
    }),
    {
      enabled: !!thread,
      staleTime: 1000 * 60 * 15,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const widgets = useQuery(['dictionary', 'widgets', dictId], () => NetworkManager.getDictionaryWidgets(communityId, { dictionaryId: dictId }), {
    staleTime: 1000 * 60 * 60,
    enabled: !!dictId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return {
    widgets,
    matches,
    threadQuery,
  };
}
