import { motion } from 'framer-motion';
import { useCallback } from 'react';
import { ReactComponent as NextSVG } from '../../../Assets/Images/icons/nextarrow.svg';
import AutoIcon from '../../../Assets/Images/icons/auto-icon.svg';
import SmsIcon from '../../../Assets/Images/icons/sms-icon.svg';

export default function ChooseStep({ setContext, setPhase }) {
  const onSelect = useCallback((type) => { // SMS or TOTP
    setContext({
      selected: type,
    });
    setPhase(1);
  }, [setContext, setPhase]);
  return (
    <motion.div
      key="choose-step"
      initial={{ opacity: 0, x: '100%' }}
      animate={{
        opacity: 1, x: '0%', transition: { type: 'tween', duration: 0.25 },
      }}
      exit={{ opacity: 0, x: '-100%', transition: { type: 'tween', duration: 0.25 } }}
      className="mfa-modal_step choose-step"
    >
      <div className="choose-step_desc">
        Choose your preferred authentication method
      </div>
      <div className="choose-step_selectors">
        <div className="choose-item" onClick={() => onSelect('TOTP')}>
          <div className="choose-item_img">
            <img src={AutoIcon} width="18" height="30" alt="" />
          </div>
          <div className="choose-item_desc">
            <div>Authentication app</div>
            <div>Get codes from an app (such as Google Authenticator)</div>
          </div>
          <div className="choose-item_arrow">
            <NextSVG />
          </div>
        </div>
        <div className="choose-item" onClick={() => onSelect('SMS')}>
          <div className="choose-item_img">
            <img src={SmsIcon} width="30" height="30" alt="" />
          </div>
          <div className="choose-item_desc">
            <div>Text message (SMS)</div>
            <div>Receive a unique code each time you log in</div>
          </div>
          <div className="choose-item_arrow">
            <NextSVG />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
