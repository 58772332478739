import { ReactComponent as SearchIcon } from '../../../Assets/Images/icons/search.svg';

export default function EntitySidebarSearch({ name, onSearch }) {
  return (
    <div className="entity-sidebar_search">
      <div className="input-wrapper">
        <input type="text" onChange={(e) => onSearch(e.target.value)} placeholder={`Search ${name}`} />
        <div className="input-wrapper_icon"><SearchIcon /></div>
      </div>
    </div>
  );
}
