/* eslint-disable import/prefer-default-export */
export const keywordsTransform = (keywords) => {
  if (keywords.length > 0) {
    if (typeof keywords[0] === 'object') return keywords.map((k) => k.keyword);
  }
  return keywords;
};

export const findKeywordsDiff = (previous, current) => {
  const prevSimple = previous.reduce((acc, cur) => {
    acc[cur.keyword] = cur.id;
    return acc;
  }, {});
  // Find removed:
  const removed = previous.reduce((acc, cur) => {
    const word = cur.keyword;
    if (!current.includes(word)) acc.push(cur.id);
    return acc;
  }, []);
  // Find added:
  const added = current.reduce((acc, cur) => {
    if (!prevSimple[cur]) acc.push(cur);
    return acc;
  }, []);

  return {
    new_words: added,
    removed_ids: removed,
  };
};
