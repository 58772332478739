/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react';
import stepsConfig from '../steps/_context';
import checkmark from '../../../Assets/Images/icons/checkmark-csv.svg';

export default function StepIndicator({ step }) {
  const getVariant = useCallback((stepId) => {
    if (step === stepId) return 'selected';
    if (stepId < step) return 'passed';
    return 'normal';
  }, [step]);
  return (
    <div className="steps">
      {stepsConfig.map((st, ind) => (
        <Step key={`${step.id}==${ind}`} label={st.label} id={st.id} variant={getVariant(st.id)} />
      ))}
    </div>
  );
}

function Step({ label, id, variant }) {
  return (
    <div className={`step ${variant}`}>
      <div className="step-id">
        {variant === 'passed' ? (
          <img src={checkmark} width="24" height="24" alt="" />
        ) : (
          id + 1
        )}
      </div>
      <div className="step-label">{label}</div>
    </div>
  );
}
