import { makeObservable, observable } from 'mobx';

class SlackDal {
  scopesMode = undefined;
  channels = undefined;
  storedAssetID = undefined;

  constructor() {
    if (!SlackDal.instance) {
      SlackDal.instance = this;
    }

    makeObservable(this, {
      channels: observable,
      scopesMode: observable,
      storedAssetID: observable,
    });
  }
}

const slackDalInstance = new SlackDal();

export default slackDalInstance;
