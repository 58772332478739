import { Button } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useEffect, useState, useLayoutEffect } from 'react';
import dalInstance from '../../Data/Dal';

import AdderButtons from './comps/adderButtons';
import FilterLine from './comps/filterLine';
import handler from './state/superFilter.class';

import superHeader from '../../Assets/Images/icons/super-ico.svg';
import reset from '../../Assets/Images/icons/super-reset.svg';
import accountIcon from '../../Assets/Images/icons/super-case.svg';
import userIcon from '../../Assets/Images/icons/super-user.svg';

import './styles/style.scss';
import { AnalyticsService } from '../../config/analytics';

const phasesAnims = {
  variants: {
    initial: (dir) => ({ opacity: 0, x: dir > 0 ? '100%' : '-100%', y: '0%' }),
    enter: { opacity: 1, x: '0%' },
    exit: (dir) => ({ opacity: 0, x: dir > 0 ? '-100%' : '100%' }),
  },
  transition: { ease: 'easeInOut', duration: 0.5 },
};
const filterTypes = [
  {
    key: 'account',
    icon: accountIcon,
    title: 'Account data',
    desc: 'Elaboration on account data query types',
  },
  {
    key: 'contact',
    icon: userIcon,
    title: 'User data',
    desc: 'Elaboration on user data query types',
  },
];

function SuperFilter({ onClose, onFinish, onReset }) {
  const [phase, setPhase] = useState(1);
  const [animDirection, setAnimDirection] = useState(1);
  const handleType = (type) => {
    handler.updateType(type);
    setPhase(1);
  };
  const handleApply = () => {
    handler.saveAll().then((status) => {
      AnalyticsService.superFilterRun();
      if (status) {
        onFinish(handler.apply());
      }
    });
  };
  useLayoutEffect(() => {
    setAnimDirection(phase === 1 ? -1 : 1);
  }, [phase]);
  useEffect(() => {
    dalInstance.getAllCustomFields();
  }, []);
  return (
    <motion.div
      className="super-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="super-container_header">
        <div className="super-container_header_img">
          <img src={superHeader} width="80" height="80" alt="super filter" />
        </div>
        <div className="super-container_header_info">
          <span>What would you like to discover today?</span>
          <span>
            Create elaborate queries to get accurate, contextual data from your user-base.
            {' '}
            <a>Show me how</a>
          </span>
        </div>
      </div>
      <div className="super-container_inner">

        <AnimatePresence>
          {phase === 0 ? (
            <motion.div
              key="type-selector"
              className="super-container-type"
              custom={animDirection}
              variants={phasesAnims.variants}
              transition={phasesAnims.transition}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <span>I would like to learn about</span>
              <div className="super-container-type_buttons">
                {filterTypes.map((flt) => (
                  <div key={flt.key} onClick={() => handleType(flt.key)} className={`super-button ${handler.type === flt.key ? 'selected' : ''}`}>
                    <div className="super-button_img">
                      <img src={flt.icon} width="60" height="60" alt={flt.title} />
                    </div>
                    <div className="super-button_desc">
                      <div>{flt.title}</div>
                      <div>{flt.desc}</div>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="lines-filter"
              className="super-container_lines"
              custom={animDirection}
              variants={phasesAnims.variants}
              transition={phasesAnims.transition}
              initial="initial"
              animate="enter"
              exit="exit"
            >
              <AnimatePresence>
                {handler.lines.slice(0, 1).map((filter) => (
                  <FilterLine key={`${filter.id}-first`} filter={filter} index={0} />
                ))}
                {handler.lines.length > 1 && (
                  <motion.div
                    className="super-andor"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div className={`super-andor_and ${handler.operator === 'and' ? 'selected' : ''}`} onClick={() => handler.updateOperator('and')}>And</div>
                    <div className={`super-andor_or ${handler.operator === 'or' ? 'selected' : ''}`} onClick={() => handler.updateOperator('or')}>Or</div>
                  </motion.div>
                )}
                {handler.lines.slice(1).map((filter, ind) => (
                  <FilterLine key={filter.id} filter={filter} index={ind} />
                ))}
              </AnimatePresence>
              <AdderButtons />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="super-container_footer">
        <div className="super-container_footer_reset" onClick={() => { handler.reset(); onReset(); }}>
          <img src={reset} width="20" height="20" alt="Reset" />
          <span>Reset</span>
        </div>
        <div className="super-container_footer_buttons">
          <Button className={`super_back ${phase === 0 ? 'disabled' : ''}`} onClick={onClose}>Back</Button>
          <Button className={`super_next ${handler.lines.length === 0 ? 'disabled' : ''}`} onClick={handleApply}>Apply</Button>
        </div>
      </div>
    </motion.div>
  );
}

export default observer(SuperFilter);
