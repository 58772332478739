import Amplify, { Auth } from 'aws-amplify';
import { runInAction } from 'mobx';
import Dal from '../Data/Dal';
import NetworkManager from './NetworkManager';

const signInResultEnum = { success: 0, error: 1, changePassword: 2, mfa: 3 };
Object.freeze(signInResultEnum);

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
});

class AmplifyManager {
  constructor() {
    if (!AmplifyManager.instance) {
      AmplifyManager.instance = this;
    }

    return AmplifyManager.instance;
  }

  async getCognitoUser() {
    return Auth.currentUserPoolUser();
  }

  async reAssignCognitoUser() {
    const user = await this.getCognitoUser();
    runInAction(() => {
      Dal.cognitoUser = user;
    });
  }

  async addAttributes(obj) {
    const user = await this.getCognitoUser();
    const status = await Auth.updateUserAttributes(user, {
      ...user.attributes,
      ...obj,
    });
    return status;
  }

  async setMFA(mfa) {
    const user = await this.getCognitoUser();
    return Auth.setPreferredMFA(user, mfa);
  }

  async verifyTotpSetup(code) {
    try {
      const user = await this.getCognitoUser();
      await Auth.verifyTotpToken(user, code);
      await Auth.setPreferredMFA(user, 'TOTP');
      await this.reAssignCognitoUser();
      return true;
    } catch {
      return false;
    }
  }
  async setupTOTP() {
    const user = await this.getCognitoUser();
    return Auth.setupTOTP(user);
  }

  async confirmSignIn(cognitoUser, code, type) {
    try {
      await Auth.confirmSignIn(cognitoUser, code, type);
      NetworkManager.amplifySession = await Auth.currentSession();
      await this.reAssignCognitoUser();
      return { status: signInResultEnum.success };
    } catch (error) {
      console.log('error signing in', error);
      return { status: signInResultEnum.error, error };
    }
  }

  async signIn(email, password) {
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return { status: signInResultEnum.changePassword };
      }
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        return { status: signInResultEnum.mfa, user };
      }
      NetworkManager.amplifySession = await Auth.currentSession();
      return { status: signInResultEnum.success };
    } catch (error) {
      console.log('error signing in', error);
      return { status: signInResultEnum.error, error };
    }
  }

  async confirmSignUp(username, code) {
    try {
      await Auth.confirmSignUp(username, code);
      return true;
    } catch (error) {
      console.log('error confirming sign up', error);
      return false;
    }
  }

  async signUp(username, password, email) {
    try {
      const user = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name: username.split(' ')[0],
          family_name:
            username.split(' ').length > 1 ? username.split(' ')[1] : '',
        },
      });
      return user;
    } catch (error) {
      throw error;
    }
  }

  resendPasscode(username) {
    Auth.resendSignUp(username);
  }
}

const instance = new AmplifyManager();
Object.freeze(instance);

export default instance;
