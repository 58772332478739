import { Layout } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../Assets/Images/new-logo.svg';
import schemaRaw from './_schema';
import SidebarItem, { SidebarActionItem } from './item';
import './styles.scss';

const { Sider } = Layout;

const HOVER_SPACE = 68;

export default function Sidebar() {
  const routeItems = useMemo(() => schemaRaw.filter((s) => !s.action && !s.isBottom), []);
  const bottomRouteItems = useMemo(() => schemaRaw.filter((s) => !s.action && s.isBottom), []);
  const actionItems = useMemo(() => schemaRaw.filter((s) => s.action), []);

  return (
    <Sider
      width="70px"
      className="main-sidebar"
    >
      <div className="main-sidebar_inner">
        <div className="main-sidebar_logo">
          {/* <Link to="/dashboard/queries"> */}
          <Logo />
          {/* </Link> */}
        </div>
        <ItemsWrapper schema={routeItems} Comp={SidebarItem} />
        <div className="spacer" />
        <ItemsWrapper schema={bottomRouteItems} Comp={SidebarItem} />
        <ItemsWrapper schema={actionItems} Comp={SidebarActionItem} />
      </div>
    </Sider>
  );
}

const ItemsWrapper = ({ schema, Comp }) => {
  const Item = Comp;
  const [hoverRouteIndex, setHoverRouteIndex] = useState(0);
  const [isMouseInside, setMouseInside] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const mouseEnterRoute = useCallback((ind) => {
    setHoverRouteIndex(ind);
    setMouseInside(true);
  }, []);

  const getHoverBlockPos = useMemo(() => hoverRouteIndex * HOVER_SPACE + hoverRouteIndex, [hoverRouteIndex]);

  const getHoverTooltip = useMemo(() => schema?.[hoverRouteIndex]?.label || '', [hoverRouteIndex, schema]);
  return (
    <div className="main-sidebar_items" onMouseLeave={() => setMouseInside(false)}>
      <motion.div
        className="hover-block"
        initial={{ opacity: 0 }}
        animate={{
          y: `${getHoverBlockPos}px`,
          x: '-50%',
          opacity: isMouseInside ? 1 : 0,
        }}
        transition={{ type: 'linear', duration: 0.3 }}
      />
      <motion.div
        className="hover-tooltip"
        initial={{ opacity: 0 }}
        animate={{
          y: `${getHoverBlockPos}px`,
          opacity: isMouseInside ? 1 : 0,
        }}
        transition={{ type: 'linear', duration: 0.3 }}
      >
        {getHoverTooltip}
      </motion.div>
      {schema.map((route, ind) => (
        <Item
          key={`${route.path}-${ind}`} // eslint-disable-line
          isSelected={location.pathname.includes(route.path)}
          onMouseEnter={() => mouseEnterRoute(ind)}
          history={history}
          {...route}
        />
      ))}
    </div>
  );
};
