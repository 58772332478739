import { AnimatePresence, motion } from 'framer-motion';
import { memo, useCallback } from 'react';
import SetsThreadDrawer from './main';
import './styles/index.scss';

function SetsThreadView({
  interactionId, onClose,
}) {
  return (
    <div>
      <AnimatePresence>
        {!!interactionId && (
          <motion.div
            key="threadwrapper"
            className="threadview-wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
          />
        )}
        {!!interactionId && (
          <SetsThreadDrawer onClose={onClose} interactionId={interactionId} />
        )}
      </AnimatePresence>
    </div>
  );
}

export default memo(SetsThreadView);
