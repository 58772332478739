import React from 'react';

import { Image, Button } from 'antd';

import errorImage from '../../../Assets/Images/connection-error-ico.svg';

export default class ErrorState extends React.Component {
  render() {
    return (
      <>
        <Image
          width={80}
          src={errorImage}
          style={{ marginTop: 49 }}
          preview={false}
        />
        <p className="failedToFetchGroups">Can’t fetch your Facebook groups</p>
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '20px',
          }}
        >
          <p className="tryToReconnect">
            Please try to reconnect or make sure LoudNClear app is properly
            installed in your group pages.
          </p>
        </div>
        <Button type="text" className="tryToReconnect learnMore">
          Learn more
        </Button>
      </>
    );
  }
}
