/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import { useFormikContext } from 'formik';
import { useCallback, useState, useEffect } from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { ReactComponent as AddIco } from '../../../../Assets/Images/icons/actions.svg';
import FilterLine from './line';
import { iconsByType, _fieldsByKey } from './config/_types';
import useCustomFields from '../../../../Utils/community/useCustomFields';
import defaultIntegrations from '../../../../Data/integrations';
import { operators, operatorsNames } from './config/_operators';

const appendOperatorsToFields = (fields) => Object.values(fields).reduce((acc, field) => {
  acc[field.key] = {
    ...field,
    operators: operators[field.type].map((operator) => ({
      key: operator,
      label: operatorsNames[operator],
    })),
  };
  return acc;
}, {});

export default function FiltersSection() {
  const {
    values,
    setFieldError,
    setFieldValue,
    submitForm,
  } = useFormikContext();
  console.log(values);
  const { filters } = values;
  const { data: customFields } = useCustomFields();
  const [allFields, setAllFields] = useState(appendOperatorsToFields(_fieldsByKey));
  const [activeFields, setActiveFields] = useState(new Set());
  const { queryId } = useParams();

  const getCustomFields = (fields) => fields
    .sort((a, b) => a.service_name.localeCompare(b.service_name))
    .reduce((acc, { label, service_name, field_type }) => {
      const serviceName = service_name === 's3' ? 'csv' : service_name;
      const integration = defaultIntegrations.find((df) => df.targetTag === serviceName);
      acc[label] = {
        key: label,
        textLabel: label,
        [service_name]: integration,
        label: (
          <div className="label-cf">
            <span>{`${integration.shortTitle} ▸`}</span>
            <span>{label}</span>
          </div>
        ),
        isCustom: true,
        type: field_type,
        icon: iconsByType?.[field_type],
        group: 'Custom', // integrations[f.service_name].shortTitle,
      };
      return acc;
    }, {});

  useEffect(() => {
    if (!customFields?.length) return;
    const customFieldsWithOperators = appendOperatorsToFields(getCustomFields(customFields));
    setAllFields((fields) => ({ ...fields, ...customFieldsWithOperators }));
  }, [customFields]);

  useEffect(() => {
    const selectedFields = filters.map((filter) => filter.field);
    setActiveFields(new Set(selectedFields));
  }, [filters]);

  useEffect(() => {
    if (queryId) {
      setFieldValue('metadata.isEdited', true);
    }
  }, [queryId, setFieldValue]);

  const addNewFilter = useCallback(() => {
    setFieldValue('filters', [...filters, { field: '', operator: '', value: '' }]);
    setFieldValue('metadata.isEdited', true);
  }, [filters, setFieldValue]);

  const removeFilterAtIndex = useCallback(
    (ind) => {
      setFieldError(`filters${ind}`, '');
      setFieldValue(
        'filters',
        filters.filter((_, index) => index !== ind),
      );
      setFieldValue('metadata.isEdited', true);
    },
    [filters, setFieldError, setFieldValue],
  );

  return (
    <div className="filters">
      <div className="filters-title">Start building your query, add a new condition</div>
      {filters.map((filter, ind) => (
        <FilterLine
          key={ind}
          ind={ind}
          activeFields={activeFields}
          fields={allFields}
          onFilterRemoved={removeFilterAtIndex}
          filter={filter}
        />
      ))}
      <div className="hover-link-box filters-add " onClick={addNewFilter}>
        <AddIco />
        <span>Add condition</span>
      </div>
      <div className="filters-run">
        <Button className="lnc-blue-btn" disabled={filters.length === 0} onClick={submitForm}>
          Run Query
        </Button>
      </div>
    </div>
  );
}
