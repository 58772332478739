import {
  useRef, useEffect, useMemo, useContext,
} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import widgetSettings from '../_data/widgetSettings';
import { WidgetContext } from '../hooks/useWidget';
import { ReactComponent as EmptyIcon } from '../../../Assets/Images/icons/dashboards/widget-empty.svg';

import Spinner from '../../Skeletons/spinner';
import seriesTransformer from '../_data/chartTransformers';

const Loader = () => (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spinner /></div>);
const Empty = () => (
  <div className="widget-empty">
    <EmptyIcon />
    <span>
      Oops, this data is not available at the moment.
      <br />
      No worries, our CS will reach out back to you in no time 🙂

    </span>
  </div>
);

export default function Widget({
  w, h, gridWidth, id,
}) {
  const ref = useRef();
  const {
    widgetQuery: {
      data, isError, isFetching,
    },
  } = useContext(WidgetContext);

  const isWidgetEmpty = useMemo((() => {
    if (isFetching) return null;
    if (isError) return true;
    if (data && data.chart.xAxis.categories.length === 0) return true;
    return false;
  }), [isError, isFetching, data]);
  useEffect(() => {
    if (ref.current) ref.current.reflow();
  }, [h]);
  const finalOptions = useMemo(() => {
    if (data && id && !isFetching) {
      const base = _.merge({
        ...data.chart,
        series: seriesTransformer(data.chart.series),
      }, widgetSettings({ w, gridWidth, isSharedTooltip: !!data.chart?.plotOptions?.column?.stacking }));
      if (data.config.dimension === 'time' || data.config.dimension.includes('_time')) {
        return {
          ...base,
          colors: ['#26a0fc'],
        };
      }
      return base;
    }
    return null;
  }, [id, data, w, gridWidth, isFetching]);
  return (
    <div className="theWidgetWrap">
      {isFetching && (
        <Loader />
      )}
      {!isFetching && isWidgetEmpty === true && (
        <Empty />
      )}
      {!isWidgetEmpty && finalOptions && !isFetching && (
        <HighchartsReact
          highcharts={Highcharts}
          options={finalOptions}
          callback={(v) => ref.current = v} // eslint-disable-line
        />
      )}
    </div>
  );
}
