import * as Yup from 'yup';
import logo from '../../Assets/Images/icons/ico-integrate-with-mixpanel.png';
import connected from '../../Assets/Images/icons/ico-integration-succes-with-mixpanel.png';

const fieldsSchema = [
  {
    label: 'Friendly name',
    defaultValue: '',
    placeholder: 'Enter a name so you can recognize this source across LoudNClear platform',
    key: 'name',
  },
  {
    label: 'Service Account',
    defaultValue: '',
    placeholder: 'Enter the service account username',
    key: 'serviceAccount',
  },
  {
    label: 'Service Account Secret',
    defaultValue: '',
    placeholder: 'Enter the secret for this service account',
    key: 'secret',
  },
  {
    label: 'Project ID',
    defaultValue: '',
    placeholder: 'Enter the project ID you wish to integrate with LoudNClear',
    key: 'projectId',
  },
];

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  serviceAccount: Yup.string().required('Service account is required'),
  secret: Yup.string().required('Token secret is required'),
  projectId: Yup.string().required('Project ID is required'),
});

const MixpanelProjectSchema = {
  config: {
    name: 'Mixpanel',
    image: logo,
    connectedImage: connected,
    info: [
      'Fetch insights & users from Mixpanel directly into LoudNClear',
    ],
  },
  fieldsSchema,
  validationSchema,
};
export default MixpanelProjectSchema;
