import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { Button } from 'antd';
import UserDetailsSider from './siderViews/userDetails';
import CompanySider from './siderViews/company';
import WorkspaceSider from './siderViews/workspace';
import ReferralsSider from './siderViews/referrals';
import IntegrationSider from './siderViews/integrations';

const elements = {
  '-1': UserDetailsSider,
  0: UserDetailsSider, // user details
  1: UserDetailsSider, // verify step
  2: CompanySider,
  3: WorkspaceSider,
  4: ReferralsSider,
  5: IntegrationSider,
};

function OnboardingSider({ step }) {
  const Comp = elements[step];
  return (
    <div className="sider">
      <div className="sider-header">
        {step === 0 && (
          <>
            <div>Already have an account?</div>
            <div>
              <Link to="/signin">
                <Button>Log in</Button>
              </Link>
            </div>
          </>
        )}
      </div>
      <div className="sider-content">
        <div id="onboarding-portal">
          <Comp />
        </div>
      </div>
    </div>
  );
}

export const SiderPortal = ({ children }) => {
  const mount = document.getElementById('onboarding-portal');
  const el = document.createElement('div');

  useEffect(() => { // eslint-disable-line consistent-return
    if (mount) {
      mount.appendChild(el);
      return () => mount.removeChild(el);
    }
  }, [el, mount]);

  return createPortal(children, el);
};

export default OnboardingSider;
