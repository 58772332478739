import { useQuery } from 'react-query';
import { useCommunityContext } from '../../../Utils/contexts/community';
import NetworkManager from '../../../Network/NetworkManager';

export default function usePlaybooksQueries() {
  const { communityId } = useCommunityContext();
  const playbooks = useQuery([communityId, 'playbooks'], () => NetworkManager.getAllPlaybooks(communityId), {
    enabled: !!communityId,
    staleTime: Infinity,
  });
  const playbooksStats = useQuery([communityId, 'playbooks', 'stats'], () => NetworkManager.getAllPlaybooksStats(communityId), {
    enabled: !!communityId,
    staleTime: Infinity,
  });

  return {
    playbooks,
    playbooksStats,
  };
}
