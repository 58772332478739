import { useState } from 'react';
import OnboardingLayout from './layout';
import OnboardingIndex from './main';

import './styles.scss';

function OnboardingContainer() {
  const [step, setStep] = useState(0);
  return (
    <OnboardingLayout step={step}>
      <OnboardingIndex step={step} setStep={setStep} />
    </OnboardingLayout>
  );
}

export default OnboardingContainer;
