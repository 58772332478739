import { Popover } from 'antd';
import { useState } from 'react';
import { useCommunityContext } from '../../../../../Utils/contexts/community';
import editIco from '../../../../../Assets/Images/icons/cat-ico-edit.svg';
import newIco from '../../../../../Assets/Images/icons/new-dict.png';
import workflowIcon from '../../../../../Assets/Images/icons/revamp/cat-ico-automate.svg';

import ViewModes from './viewModes';
import { ReactComponent as FilterIcon } from '../../../../../Assets/Images/redesign/ui-icon-filter-filter-purple.svg';
import DictionarySuperFilter from '../../../filter';
import useDictionaryContext from '../../../context/dictionary.context';
import AutomationsCenter from '../../../../Automations';

export default function DictionaryHeading({
  dictId, view, setView, filter, setSuperFilter,
}) {
  const { dictionaries } = useCommunityContext();
  const [automationModal, setAutomationModal] = useState(false);
  const { superFilterVisible, setSuperFilterVisible, setDictModal } = useDictionaryContext();
  return (
    <>
      <AutomationsCenter isShown={automationModal} onClose={() => setAutomationModal(false)} />
      <div className="dictionary-heading_top">
        <div className="dictionary-heading_title">{dictionaries[dictId].name}</div>
        <div className="dictionary-heading_top_buttons">
          <div className="hover-link-box" onClick={() => setDictModal(dictionaries[dictId])}>
            <img src={editIco} width="25" height="25" alt="" />
            Edit Dictionary
          </div>
          <div className="hover-link-box" onClick={() => setAutomationModal(true)}>
            <img src={workflowIcon} width="30" height="30" alt="" />
            <span
              className="workflow-integration-style"
              style={{ marginLeft: '6px' }}
            >
              Automate
            </span>
          </div>
        </div>
      </div>
      <div className="dictionary-heading_bottom">
        <ViewModes view={view} setView={setView} />
        <div className="dictionary-heading_bottom_btns">
          <div onClick={() => setDictModal({})} className="hover-link-box inverted filter-btn">
            <img src={newIco} width="30" height="30" alt="" />
            New Dictionary
          </div>
          <Popover
            trigger="click"
            arrow={false}
            placement="bottom"
            content={<DictionarySuperFilter setSuperFilter={setSuperFilter} currentActiveFilter={filter} onClose={() => setSuperFilterVisible(false)} />}
            visible={superFilterVisible}
            onVisibleChange={setSuperFilterVisible}
          >
            <div
              className={`hover-link-box filter-btn ${filter ? 'active' : ''}`}
            >
              <FilterIcon />
              Super Filter
            </div>
          </Popover>
        </div>
      </div>
    </>
  );
}
