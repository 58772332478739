import { motion } from 'framer-motion';
import secureIco from '../../../Assets/Images/icons/secure-ico.svg';

export default function SuccessStep({ setContext, setPhase }) {
  return (
    <motion.div
      key="success-step"
      initial={{ opacity: 0, x: '100%' }}
      animate={{
        opacity: 1, x: '0%', transition: { type: 'tween', duration: 0.25 },
      }}
      exit={{ opacity: 0, x: '-100%', transition: { type: 'tween', duration: 0.25 } }}
      className="mfa-modal_step success-step"
    >
      <img src={secureIco} width="80" height="80" alt="" />
      <div className="minititle">Success!</div>
      <div>Your account is now more secured</div>
    </motion.div>
  );
}
