import React, { Component } from 'react';
import {
  Modal,
  Button,
  Divider,
  Space,
  message,
  Form,
  Input,
  Dropdown,
  Menu,
} from 'antd';
import {
  makeObservable, toJS, observable, runInAction,
} from 'mobx';
import { observer } from 'mobx-react';

import SuperFilterHandler from '../../../Components/SuperFilter/state/superFilter.class';
import './styles.scss';

import icon from '../../../Assets/Images/redesign/crm-modal-edit-icon.svg';
import Dal from '../../../Data/Dal';
import GeneralUserIcon from '../../../Assets/Images/redesign/generalUserIcon';
import { capitalizeFirstLetter } from '../../../Data/StringUtils';
import NetworkManager from '../../../Network/NetworkManager';
import chevron from '../../../Assets/Images/redesign/menuchevron-chevron-down-grey.svg';
import { AnalyticsService } from '../../../config/analytics';

class CrmSegmentEditView extends Component {
  ctaLoading = false;

  setColor = Dal.setsColors[0];
  headerTitle = 'New Segment';
  key = -1;
  // segmentName = "";

  constructor(props) {
    super(props);
    this.state = {
      segmentName: '',
    };
    makeObservable(this, {
      ctaLoading: observable,
      setColor: observable,
      headerTitle: observable,
      // segmentName: observable,
    });

    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (this.props?.segment?.name !== prevProps?.segment?.name) {
      this.setup(() => {
        this.forceUpdate();
      });
    }
  }

  setup(callback) {
    runInAction(() => {
      if (this.props.segment !== undefined) {
        if (this.props.segment.name !== undefined) {
          this.setState({ segmentName: this.props.segment.name });
          // this.segmentName = this.props.segment.name;
        }
        this.headerTitle = this.props.segment.id !== undefined ? 'Edit Segment' : 'New Segment';
        const newSetColor = Dal.getSetColorByBgColor(this.props.segment.color);
        this.setColor.bgColor = newSetColor.bgColor;
        this.setColor.textColor = newSetColor.textColor;
        this.setColor.name = newSetColor.name;
      }
    });
    if (callback) {
      callback();
    }
  }

  async ctaClicked() {
    runInAction(() => {
      this.ctaLoading = true;
    });

    const response = await NetworkManager.createSegment(
      this.state.segmentName,
      this.setColor.bgColor,
      toJS(SuperFilterHandler.finalData) || this.props.segment.filter,
      this.props.segment?.id,
    );
    if (response === false) {
      message.error('Error. Try again later');
    }

    const newSegmentId = response.data;
    const segments = await Dal.fetchSegments();

    message.success('Done!');
    if (!this.props?.segment?.id) {
      AnalyticsService.segmentCreate(this.state.segmentName);
    }
    runInAction(() => {
      if (!this.props.segment?.id) Dal.selectedCrmSegmentItem = Dal.getSegmentById(newSegmentId);
      this.ctaLoading = false;
      if (typeof this.props.callback === 'function') this.props.callback();
      this.closeClicked(segments);
    });
  }

  closeClicked(segments) {
    runInAction(() => {
      Dal.crmSegmentItemToEdit = undefined;
      this.props.closeClicked(segments);
    });
  }

  segmentNameHandler(event) {
    this.setState({ segmentName: event.target.value });
  }

  handleColorMenuClick = (e) => {
    runInAction(() => {
      this.setColor = Dal.setsColors.find((x) => x.name === e.key);
      this.forceUpdate();
    });
  };

  colorsMenu = (
    <Menu onClick={this.handleColorMenuClick} style={{ paddingBottom: 10 }}>
      {Dal.setsColors.map((color) => (
        <Menu.Item key={color.name}>
          <Button
            className="set-text-color"
            type="text"
            style={{
              width: '150px',
              background: color.bgColor,
              color: color.textColor,
              borderRadius: '4px',
            }}
          >
            {color.name}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  );

  getFilterValue(filter) {
    if (filter.field === 'source') {
      const tag = Dal.superFilterSources.sources.find(
        (x) => x.id === filter.value,
      );
      return `${tag.name}`;
    }

    return filter.value;
  }

  render() {
    return (
      <Modal
        key={this.props?.key}
        style={{ overflow: 'hidden', borderRadius: 6 }}
        visible={this.props.visible}
        wrapClassName="modal-background"
        title={(
          <div style={{ position: 'relative' }}>
            <img
              style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 20,
              }}
              alt="icon"
              src={icon}
            />
            <p
              className="crm-segment-title crm-segment-title-add"
              style={{ marginTop: 10 }}
            >
              {this.headerTitle}
            </p>
          </div>
        )}
        centered
        width={620}
        footer={null}
        onCancel={this.closeClicked.bind(this)}
      >
        <div
          key={this.props?.idKey}
          style={{
            marginLeft: 70, marginTop: 35, marginBottom: 30, paddingRight: 70,
          }}
        >
          <p className="setColorLabel">Segment Name</p>
          <Form.Item>
            <Input
              key={this.props.idKey}
              placeholder="Enter segment name"
              className="setNameInput"
              defaultValue={this.props.segment?.name}
              onChange={this.segmentNameHandler.bind(this)}
            />
          </Form.Item>

          <p className="setColorLabel">Segment color</p>
          <div
            className="crm-segment-color-wrapper"
            style={{ display: 'flex' }}
          >
            <Dropdown
              overlay={this.colorsMenu}
              placement="bottomCenter"
              arrow
              align={{ offset: [25, 10] }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Button
                  className="set-text-color"
                  type="text"
                  style={{
                    width: '120px',
                    height: '40px',
                    color: this.setColor.textColor,
                    background: this.setColor.bgColor,
                    borderRadius: '4px',
                  }}
                >
                  {this.setColor.name}
                </Button>
                <span style={{ display: 'flex', marginLeft: 20 }}>
                  <img
                    alt=""
                    style={{ width: 24, height: 24, marginRight: 15 }}
                    src={chevron}
                  />
                  <GeneralUserIcon fill={this.setColor.bgColor} />
                </span>
              </div>
            </Dropdown>

          </div>

          {/* <p
            className="setColorLabel"
            style={{ marginTop: 30, marginBottom: 20 }}
          >
            Filter by
          </p>
          <div className="crm-segment-filtersby">
            {this.props.segment?.filter?.filters?.map((item) => (
              <div
                key={item.field}
                className="crm-segment-filterby-container"
                style={{ height: 30 }}
              >
                <p className="crm-segment-filterby crm-segment-filterby-title">
                  {`${capitalizeFirstLetter(
                    item.field,
                  )}:`}
                </p>
                <p className="crm-segment-filterby crm-segment-filterby-value">
                  {`${capitalizeFirstLetter(
                    this.getFilterValue(item),
                  )}`}
                </p>
              </div>
            ))}
          </div> */}
        </div>

        <Divider />

        <div style={{ overflow: 'hidden', margin: 20, paddingBottom: 20 }}>
          <div align="center">
            <Space size={15}>
              <Button
                onClick={this.closeClicked.bind(this, undefined)}
                className="crm-segment-delete-cancel"
                style={{ width: '120px', height: '40px' }}
              >
                Back
              </Button>
              <Button
                onClick={this.ctaClicked.bind(this)}
                loading={this.ctaLoading}
                className="crm-segment-add-cta"
                data-step="crm-segment-add-update-button"
                style={{
                  width: '120px',
                  height: '40px',
                  opacity: this.isCtaDisabled ? 0.2 : 1,
                }}
              >
                {this.props.segment?.id > 0 ? 'Update' : 'Add'}
              </Button>
            </Space>
          </div>
        </div>
      </Modal>
    );
  }
}

export default observer(CrmSegmentEditView);
