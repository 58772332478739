import { useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import HistoryView from './history';
import collapseAll from '../../../../../Assets/Images/icons/cat-ico-collapse.svg';

import Activities from './index';

export default function ActivityAndHistoryWrapper({ contactId, highlightActivityId }) {
  const [selected, setSelected] = useState(0);
  const [isFullViewForced, setFullView] = useState(false);
  const portalRef = useRef();
  return (
    <div className="activity-history-wrapper">
      <div className="tab-selector">
        <div className="tabs">
          <div className={`tabs-tab ${selected === 0 ? 'selected' : ''}`} onClick={() => setSelected(0)}>
            <div>Activity</div>
            {selected === 0 && <motion.div className="underline" layoutId="underline" />}
          </div>
          <div className={`tabs-tab ${selected === 1 ? 'selected' : ''}`} onClick={() => setSelected(1)}>
            <div>Changes</div>
            {selected === 1 && <motion.div className="underline" layoutId="underline" />}
          </div>
        </div>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={`tab-portal-${selected}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            id="activities-portal"
          >
            <div ref={portalRef} />
          </motion.div>
        </AnimatePresence>
        <div className="toggle-collapse">
          <img src={collapseAll} onClick={() => setFullView(!isFullViewForced)} width="30" height="30" alt="Toggle collapse" />
        </div>
      </div>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={`tab-${selected}`}
          className="view-tab"
          initial={{ x: 0, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 0, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {selected === 0 ? (
            <Activities highlightActivityId={highlightActivityId} isFullViewForced={isFullViewForced} filterPortalRef={portalRef} contactId={contactId} />
          ) : (
            <HistoryView isFullViewForced={isFullViewForced} filterPortalRef={portalRef} contactId={contactId} />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
