import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import bannerImg from '../../../../Assets/Images/new-dict-banner.png';
import { platformColorsByBg } from '../../../../Data/colors';
import ExampleBox from './box';
import examples from './_data.json';

export default function ExamplesTab({ setStep, initted }) {
  const { setValues } = useFormikContext();
  const handleClick = useCallback((ex) => {
    setValues({
      color: ex?.color || '#99d2ff',
      keywords: ex?.keywords || [],
      abbr: ex?.abbr || false,
      enrich: ex?.enrich || {},
      sentiment: ex?.sentiment || null,
    });
    setStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="inner examples-tab">
      <div className="container">
        <div className="examples-tab_banner" onClick={() => handleClick({})}>
          <img src={bannerImg} width="1120" height="180" alt="" />
          <div className="examples-tab_banner_content">
            <div>Add a new dictionary</div>
            <div>Create a customized dictionary to intercept relevant interactions</div>
          </div>
        </div>
        <div className="examples-tab_minititle">
          Jump start your workflows with pre-defined sets.
        </div>
        <div className="examples-tab_examples">
          {examples.map((ex) => (
            <ExampleBox
              ind={ex.key}
              initted={initted}
              key={ex.key}
              keywords={ex.keywords}
              title={ex.title}
              colors={platformColorsByBg[ex.color]}
              onClick={() => handleClick(ex)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
