import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import dalInstance from '../../../Data/Dal';
import useDisclosure from '../../../Utils/useDisclosure';
import AddButton from '../common/addButton';
import IconsTemplate from '../common/iconChain';
import TabWrapper from '../common/tabWrapper';
import AutomationText from '../common/textLine';
import AutomationHero from '../layout/hero';
import Toast from '../layout/toast';
import RulePlaceholderSlack from '../placeholders/slack';
import Placeholders from '../placeholders/_placeholderMatch';
import AutomationRule from '../rule';
import examples from '../_data/examples';
import reactors from '../_data/templates/reactors';
import triggers from '../_data/templates/triggers';

function ExamplesTab({ onSelect }) {
  const [isCreating, setCreating] = useState(false);
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [toast, setToast] = useState(null);
  const handleSelect = (ex) => {
    const placeholder = (() => {
      const int = dalInstance.integrations.find((i) => i.targetTag === reactors[ex.reactor].service_name && i.connected);
      if (!int) return Placeholders[reactors[ex.reactor].service_name];
      return null;
    })();
    setCreating({
      trigger: {
        triggerTemplateId: triggers[ex.trigger].id,
        metadata: {},
      },
      reactor: {
        reactorTemplateId: reactors[ex.reactor].id,
        metadata: {},
      },
      placeholder,
    });
  };

  const handleOnCreationSuccess = useCallback(() => {
    setToast({ status: 'success', text: 'Automation rule created successfully' });
    setCreating(false);
  }, []);
  const handleOnTestSuccess = useCallback(() => {
    setToast({ status: 'success', text: 'Test has been triggered successfully' });
  }, []);
  return (
    <TabWrapper tabKey="ex" className="inner">
      <Toast isShown={!!toast} status={toast?.status} duration={5} text={toast?.text} onClose={() => setToast(null)} />
      {isCreating ? (
        <AutomationRule rule={isCreating} onClose={() => setCreating(false)} onTest={handleOnTestSuccess} onCreate={handleOnCreationSuccess} isNew defaultIsEdit />
      ) : (
        <AutomationHero />
      )}
      <div className="subtitle examples-sub">COMMON ACTIONS</div>
      <div className="automation-examples" style={{ opacity: isCreating ? 0.5 : 1 }}>
        {examples.map((ex) => (
          <div className="exbox" key={ex.id} onClick={() => !ex.isPremium && handleSelect(ex)}>
            {/* onClick={() => !ex.isPremium && handleSelect(ex)} */}
            <div className="exbox-text">
              <AutomationText triggerAbbr={ex.trigger} reactorAbbr={ex.reactor} isExample />
            </div>
            <div className="exbox-bottom">
              <IconsTemplate triggerAbbr={ex.trigger} reactorAbbr={ex.reactor} />
              <AddButton isPremium={ex.isPremium} />
            </div>
          </div>
        ))}
      </div>
    </TabWrapper>
  );
}

export default observer(ExamplesTab);
