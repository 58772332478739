import gridRules from './gridRules';

const sharedTooltip = {
  shared: true,
  formatter() {
    return `<div class="widget-tooltip">
              <div class="widget-tooltip_title">${this.x}</div>
              ${this.points.reduce((s, point) => `${s}
              <div class="widget-tooltip_line">
                <div class="widget-tooltip_line_circle" style="background-color: ${point.color}"></div>
                <div class="widget-tooltip_line_label">${point.series.name}</div>
                <div class="widget-tooltip_line_info">${point.y}</div>
              </div>`, '')}
            </div>`;
  },
};

const widgetSettings = ({ w, h, gridWidth, isSharedTooltip }) => ({ // eslint-disable-line
  colors: ['#26a0fc', '#00c784', '#f7b32b', '#ff7793', '#6d848e', '#5a84c8', '#a79d83', '#907ab2', '#6ca87f', '#c25382', '#68b7d4', '#afafaf', '#ff4d4d'],
  title: {
    text: undefined,
  },
  chart: {
    width: (w * (gridWidth / gridRules.cols)) - 44,
    // height: h * (gridRules.rowHeight),
  },
  labels: {
    style: {
      fontFamily: 'Poppins',
    },
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      colorByPoint: true,
      borderRadius: 4,
    },
    bar: {
      stacking: 'normal',
      colorByPoint: true,
      borderRadius: 4,
    },
    wordcloud: {
      colors: ['#b0c4d6', '#7f909e'],
      minFontSize: 4,
      maxFontSize: 30,
      rotation: {
        from: 0, to: 0,
      },
      style: {
        fontFamily: 'Poppins',
        fontWeight: '400',
      },
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
    style: {
      fontFamily: 'Poppins',
    },
  },
  tooltip: {
    borderWidth: 1,
    borderColor: '#dadada',
    borderRadius: 8,
    backgroundColor: 'white',
    useHTML: true,
    style: {
      fontFamily: 'Poppins',
    },
    shadow: {
      color: '#000',
      opacity: 0.05,
    },
    ...(isSharedTooltip ? sharedTooltip : {
      formatter() {
        return `<div class="widget-tooltip">
          <div class="widget-tooltip_title">${this.x || this.point.name}</div>
          <div class="widget-tooltip_line">
            <div class="widget-tooltip_line_circle" style="background-color: ${this.point.color}"></div>
            <div class="widget-tooltip_line_label">${this.series.name}</div>
            <div class="widget-tooltip_line_info">${this.y}</div>
          </div>
        </div>`;
      },
    }),
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '10px',
        color: '#3a434a',
        fontWeight: '500',
        fontFamily: 'Poppins',
      },
    },
  },
  yAxis: {
    gridLineColor: '#f0f0f0',
    title: {
      text: undefined,
    },
    labels: {
      style: {
        color: '#a3a3a3',
        fontSize: '10px',
        fontFamily: 'Poppins',
      },
    },
  },
});

export default widgetSettings;
