import { AnimatePresence, motion } from 'framer-motion';
import {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { ReactSortable } from 'react-sortablejs';
import EntityItem from './item';
import { ReactComponent as CloseIcon } from '../../Assets/Images/icons/sidebar-pull.svg';

import useDisclosure from '../../Utils/useDisclosure';
import './style.scss';

import DeleteModal from '../Modal/delete/deleteModal';
import useFolders, { FoldersContext } from './dnd/useFolders';
import { mapToList } from './dnd/useDndHelpers';
import FolderItem from './comps/folder';
import Add from './comps/add';
import entitiesIcons from './_data/entitiesIcons';
import EntitySidebarToggles from './comps/toggles';
import EntitySidebarSearch from './comps/search';
import EntitySidebarLoaders from './comps/loaders';

export default function EntitySidebar({
  list, selected, title, onCreateClick, defaultCollapsed, shouldShowDeleteConfirm, deleteMutation,
  entity, communityId, injectHeader, isEntitiesLoading,
}) {
  if (!communityId) throw new Error('No community ID');
  const {
    foldersQuery, expandedFolders, toggleFolderExpansion, updateFolder, createFolder, deleteFolder, hiddenFolders,
    entitiesByFolder, folders, setEntitiesByFolder, updateLocallyEntitiesInFolder, setFolders, toggleFolderHide,
  } = useFolders(communityId, entity.apiName);
  const { isOpen, onToggle, onOpen } = useDisclosure(defaultCollapsed ? false : true); // eslint-disable-line
  const [deleteModal, setDeleteModal] = useState(shouldShowDeleteConfirm ? false : null);
  const [showHidden, setShowHidden] = useState(false);
  const [searchVal, setSearch] = useState('');

  useEffect(() => {
    if (!defaultCollapsed) onOpen();
  }, [defaultCollapsed]); // eslint-disable-line

  const handleDeletePress = useCallback((id) => {
    if (shouldShowDeleteConfirm) {
      setDeleteModal(id);
    }
  }, [shouldShowDeleteConfirm]);

  /** SPECIAL CASE FOR SEGMENTS */
  useEffect(() => {
    if (entity.singularName === 'segment') foldersQuery.refetch();
  }, [list]);

  const handleNewFolder = useCallback(() => {
    setFolders((cur) => [
      ...cur,
      {
        id: 'NEW',
        key: 'folder-new',
        title: 'New Folder',
        isFolder: true,
        isNew: true,
      },
    ]);
  }, []); // eslint-disable-line

  const searchedList = useMemo(() => {
    if (searchVal) {
      return list.filter((l) => l.title.toLowerCase().includes(searchVal.toLowerCase()));
    }
    if (list) return list;
    return [];
  }, [searchVal, list]);

  const initalList = useMemo(() => {
    if (searchedList && foldersQuery.data) {
      const arr = mapToList(searchedList, foldersQuery.data, `My ${entity.pluralName}`);
      return arr;
    }
    return [[], {}];
  }, [foldersQuery.data, searchedList, entity.pluralName]);

  // console.log(entitiesByFolder, folders);
  const currentFolderDisplay = useMemo(() => folders.filter((f) => {
    if (searchVal && entitiesByFolder?.[f.id]) return entitiesByFolder[f.id].length !== 0;
    if (!showHidden) return !hiddenFolders.includes(f.key);
    return true;
  }), [folders, hiddenFolders, searchVal, entitiesByFolder, showHidden]);

  const finalExpandedFolders = useMemo(() => {
    const final = new Set([...expandedFolders]);
    if (selected && Array.isArray(foldersQuery.data?.entities)) {
      const selectedEntity = foldersQuery.data.entities.find((e) => e.entity_id === selected);
      if (selectedEntity) {
        final.add(`folder-${selectedEntity.folder_id || -1}`);
      }
    }
    return [...final];
  }, [selected, expandedFolders, foldersQuery.data]);

  const isLoading = useMemo(() => isEntitiesLoading || foldersQuery.isLoading, [foldersQuery.isLoading, isEntitiesLoading]);
  useEffect(() => {
    setFolders(initalList[0]);
    setEntitiesByFolder(initalList[1]);
  }, [initalList]); // eslint-disable-line
  return (
    <div className={`entity-sidebar entity-sidebar_${entity.singularName}`}>
      <div className={`entity-sidebar_collapser ${!isOpen ? 'rotate' : ''}`} onClick={onToggle}>
        <CloseIcon />
      </div>
      {shouldShowDeleteConfirm && (
        <DeleteModal
          title={shouldShowDeleteConfirm.title}
          desc={shouldShowDeleteConfirm.desc}
          entityId={deleteModal}
          isShown={deleteModal}
          deleteMutation={deleteMutation}
          onClose={() => setDeleteModal(false)}
        />
      )}
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className="inner"
            initial={{ width: '0px', whiteSpace: 'nowrap', overflow: 'hidden' }}
            animate={{ width: '300px', whiteSpace: 'normal', overflow: 'hidden' }}
            exit={{ width: '0px', whiteSpace: 'nowrap', overflow: 'hidden' }}
            transition={{ type: 'tween', duration: 0.4 }}
          >
            <div className="inner_inner">
              <div className="entity-sidebar_header">
                <div className="entity-sidebar_header_title">{title}</div>
                <div className="entity-sidebar_header_actions">
                  {/* {(isLoading || foldersQuery.isFetching) && <Spinner />} */}
                  <div className="entity-sidebar_header_actions_item">
                    <EntitySidebarToggles setShowHidden={setShowHidden} showHidden={showHidden} />
                  </div>
                </div>
              </div>
              {injectHeader && (
                <div className="entity-sidebar_injected">
                  {injectHeader}
                </div>
              )}
              <div className="entity-sidebar_actions">
                <Add
                  entityName={entity.singularName}
                  entityIcon={entitiesIcons[entity.singularName]}
                  handleNewEntity={onCreateClick}
                  handleNewFolder={handleNewFolder}
                />
                <div className="divider" />
              </div>
              <EntitySidebarSearch onSearch={setSearch} name={entity.pluralName} />
              <FoldersContext.Provider value={{ // eslint-disable-line
                expandedFolders: finalExpandedFolders,
                updateFolder,
                createFolder,
                deleteFolder,
                selected,
                toggleFolderHide,
              }}
              >
                <div className="main-list">
                  {isLoading && <EntitySidebarLoaders />}
                  {!isLoading && currentFolderDisplay.map((item) => (
                    <div key={item.id}>
                      <FolderItem
                        id={item.id}
                        isNew={item.isNew}
                        isEmpty={entitiesByFolder?.[item.id] && entitiesByFolder[item.id].length === 0}
                        folderKey={item.key}
                        title={item.title}
                        isHidden={hiddenFolders.includes(item.key)}
                        onClick={() => toggleFolderExpansion(item.key)}
                      />
                      {item.id !== 'NEW' && expandedFolders.includes(item.key) && entitiesByFolder[item.id].length === 0 && (
                        <div className="folder-empty">This folder is empty</div>
                      )}
                      {item.id !== 'NEW' && (
                        <ReactSortable
                          group="folder"
                          animation={200}
                          delayOnTouchStart
                          delay={2}
                          list={entitiesByFolder[item.id]}
                          setList={(l) => updateLocallyEntitiesInFolder(item.id, l)}
                        >
                          {(finalExpandedFolders.includes(item.key)) && entitiesByFolder[item.id].map((item2) => (
                            <div key={item2.id}>
                              <EntityItem onDelete={handleDeletePress} {...item2} />
                            </div>
                          ))}
                        </ReactSortable>
                      )}

                    </div>
                  ))}
                </div>
              </FoldersContext.Provider>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
