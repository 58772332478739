import React from 'react';

import { makeObservable, observable, runInAction } from 'mobx';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import {
  Card, Divider, Input, Button, Tooltip,
} from 'antd';
import { Auth } from 'aws-amplify';

import './styles.scss';
import icon from '../../../Assets/Images/icons/illustration-pwd.svg';

class ResetPasswordModal extends React.Component {
  step = 1;
  email = '';
  code = '';
  password = '';
  passwordRepeat = '';
  redirectToLogin = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      step: observable,
      email: observable,
      code: observable,
      password: observable,
      passwordRepeat: observable,
      redirectToLogin: observable,
    });
    this.handleSendCodeClick = this.handleSendCodeClick.bind(this);
    this.getTooltipText = this.getTooltipText.bind(this);
    this.handleConfirmPasswordClick = this.handleConfirmPasswordClick.bind(this);
    this.returnToLogin = this.returnToLogin.bind(this);
  }

  async handleSendCodeClick(event) {
    event.preventDefault();

    try {
      await Auth.forgotPassword(this.email);
      runInAction(() => this.step++);
    } catch (error) {
      alert('Please check again the email');
    }
  }

  async handleConfirmPasswordClick(event) {
    event.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(this.email, this.code, this.password);
      runInAction(() => this.step++);
    } catch (error) {
      alert(error);
    }
  }

  getTooltipText() {
    if (
      this.code.length === 0
      || this.password.length === 0
      || this.passwordRepeat.length === 0
    ) {
      return 'Please fill all the fields';
    }
    if (this.password !== this.passwordRepeat) {
      return 'The passwords are not equal';
    }
    if (!this.isPasswordValid(this.password)) {
      return 'The password must include caplital letters, numbers and have at least 8 characters';
    }
    return null;
  }

  isButtonEnabled() {
    return this.isPasswordValid(this.password) && this.isFormValid();
  }

  isPasswordValid(password) {
    const hasNumbers = /\d/.test(password);
    const hasOnlyLowerCase = password === password.toLowerCase();
    return hasNumbers && !hasOnlyLowerCase && password.length > 7;
  }

  isFormValid() {
    return (
      this.code.length > 0
      && this.password.length > 0
      && this.password === this.passwordRepeat
    );
  }

  returnToLogin() {
    runInAction(() => { this.redirectToLogin = true; });
  }

  async resendCode(event) {
    event.preventDefault();

    try {
      await Auth.forgotPassword(this.email);
    } catch (error) {
      alert('Please check again the email');
    }
  }

  renderContentForStep() {
    switch (this.step) {
      case 1:
        return (
          <>
            <p className="reset-password-header">Reset password</p>
            <p className="reset-password-subtitle">
              Please provide the email address you registered with
            </p>
            <Divider />
            <div
              style={{ width: 300, display: 'inline-block', textAlign: 'left' }}
            >
              <p className="reset-password-email-label">Email adress</p>
              <Input
                placeholder="Your email"
                bordered={false}
                className="reset-password-input-email"
                onChange={(e) => runInAction(() => { this.email = e.target.value; })}
              />
            </div>
            <Button
              className="reset-password-send-email-btn"
              onClick={this.handleSendCodeClick}
            >
              Send
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <p className="reset-password-header">
              Check your email for pass code
            </p>
            <p className="reset-password-subtitle">
              Didn’t get a verification code?
              {' '}
              <span
                role="button"
                tabIndex="0"
                className="reset-password-subtitle resend"
                onClick={this.resendCode.bind(this)}
              >
                Resend pass code
              </span>
            </p>
            <Divider />
            <div
              style={{ width: 300, display: 'inline-block', textAlign: 'left' }}
            >
              <p className="reset-password-email-label">Pass code</p>
              <Input
                placeholder="Enter the code"
                value={this.code}
                bordered={false}
                className="reset-password-input-email"
                onChange={(e) => runInAction(() => { this.code = e.target.value; })}
              />
              <p className="reset-password-email-label password">
                New password
              </p>
              <Input.Password
                placeholder="Enter new password"
                bordered={false}
                className="reset-password-input-password"
                onChange={(e) => runInAction(() => { this.password = e.target.value; })}
              />
              <p className="reset-password-email-label password">
                Repeat the password
              </p>
              <Input.Password
                placeholder="Repeat new password"
                bordered={false}
                className="reset-password-input-password"
                onChange={(e) => runInAction(() => { this.passwordRepeat = e.target.value; })}
              />
            </div>
            <Tooltip
              title={this.getTooltipText}
              className="password-hint-tooltip"
            >
              <Button
                className="reset-password-send-email-btn"
                disabled={!this.isButtonEnabled()}
                onClick={this.handleConfirmPasswordClick}
              >
                Let&apos;s go!
              </Button>
            </Tooltip>
          </>
        );
      case 3:
        return (
          <>
            <p className="reset-password-header">
              Your password has been reset
            </p>
            <p className="reset-password-subtitle">You can login now</p>
            <Button
              className="reset-password-send-email-btn"
              onClick={this.returnToLogin}
            >
              Login
            </Button>
          </>
        );
      default:
        return <></>;
    }
  }

  render() {
    return this.redirectToLogin ? (
      <Redirect to="signin" />
    ) : (
      <div
        className="loginViewWrapper"
        style={{
          position: 'absolute',
          backgroundImage:
            'linear-gradient(241deg, rgb(0, 216, 255), rgb(98, 54, 255), rgb(98, 54, 255), rgb(98, 54, 255))',
          width: '100vw',
          height: '100vh',
        }}
      >
        <Card className="reset-password-card">
          <div className="reset-password-content-wrapper">
            <div className="reset-password-icon-wrapper">
              <img
                src={icon}
                alt=""
                width={40}
                height={40}
                style={{ verticalAlign: 'middle' }}
              />
            </div>
          </div>
          {this.renderContentForStep()}
          <div className="reset-password-footer">
            <p
              className="reset-password-footer text"
              onClick={this.returnToLogin.bind(this)}
            >
              Return to login
            </p>
          </div>
        </Card>
      </div>
    );
  }
}

export default observer(ResetPasswordModal);
