/* eslint-disable react/no-array-index-key */
import { observer } from 'mobx-react';
import {
  useMemo, useCallback, useRef, useEffect, useState,
} from 'react';
import { toJS } from 'mobx';
import DrawerHandler from '../../state/handler';
import Activity from './activity';

import ActivitiesFilter from './filter';
import dalInstance from '../../../../../Data/Dal';
import ErrorText from '../../../../../Components/ErrorBoundary/errorText';
import ActivitiesLoader from './loader';
import useDrawerContext from '../../context/DrawerContext';
import useActivitiesFilterContext from '../../context/ActivitiesFilterContext';
import KeywordsFilterDisplay from './keywordsFilterDisplay';
import ActivityFilterPortal from './comps/filterPortal';
import MiniSpinner from '../common/miniSpinner';

function Activities({
  contactId, filterPortalRef, isFullViewForced,
  highlightActivityId,
}) {
  const [highlightInfo, setHighlightInfo] = useState({ done: false });
  const scroller = useRef();
  const {
    activities: {
      data, hasNextPage, isLoading, fetchNextPage,
      isFetching, isError, updateOriginPlatformLink,
    },
  } = useDrawerContext();
  const {
    filterWords, platforms, setPlatforms, filterDictionariesById,
  } = useActivitiesFilterContext();

  const finalActivites = useMemo(() => {
    if (data) {
      return data.map((x) => {
        if (x.instance_type === 'segment_history') {
          return {
            ...x,
            segment: {
              ...x.segment,
              textColor: dalInstance.getSetColorByBgColor(x.segment.bgColor).textColor,
            },
          };
        }
        return x;
      });
    }
    return [];
  }, [data]);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 300
          && !isFetching && hasNextPage
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, hasNextPage],
  );

  const filterFn = useMemo(() => { // for platforms
    const filters = [];
    const platformFilter = (() => {
      if (platforms.length === 0) return () => true;
      const assets = toJS(dalInstance.assets.accounts);
      const filteredAssets = assets.filter((a) => platforms.includes(`${a.service_name}${a.type}`));
      return (itemm) => {
        if (itemm.instance_type === 'segment_history' && platforms.includes('segment_history')) {
          return true;
        }
        return !!filteredAssets.find((f) => Number(f.id) === Number(itemm.asset_id));
      };
    })();
    filters.push(platformFilter);
    if (filterWords.length > 0) {
      const words = filterWords.map((w) => w.label);
      const wordsFilter = (act) => words.reduce((acc, cur) => {
        if (!acc && act?.text) {
          return act.text.toLowerCase().includes(cur.toLowerCase());
        }
        return false;
      }, false);
      filters.push(wordsFilter);
    }
    if (Object.keys(filterDictionariesById).length > 0) {
      const dictsIds = Object.keys(filterDictionariesById);
      const dictionariesFilter = (act) => {
        if (act.instance_type !== 'interaction') return false;
        const thisDictsIds = Object.keys(act?.dictionaries);
        if (dictsIds.find((dId) => thisDictsIds.includes(dId))) return true;
        return false;
      };
      filters.push(dictionariesFilter);
    }

    return (act) => filters.reduce((acc, cur) => {
      if (cur(act) && acc !== false) return true;
      return false;
    }, true);
  }, [platforms, filterWords, filterDictionariesById]);

  useEffect(() => {
    if (highlightActivityId && finalActivites.length > 0) {
      if (!highlightInfo.done && hasNextPage) {
        fetchNextPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightActivityId, hasNextPage, highlightInfo, finalActivites]);

  const scrollToHighlighted = useCallback((el) => {
    setHighlightInfo({ done: true });
    setTimeout(() => {
      scroller.current.scrollTop = (el.offsetTop - scroller.current.offsetTop - 40);
    }, 0);
  }, [scroller]);
  if (!DrawerHandler.id) return <></>;
  return (
    <div className="activities">
      <ActivityFilterPortal to={filterPortalRef?.current}>
        <div className="activities-title">
          <div>
            {!isLoading && data && (
              <ActivitiesFilter filters={platforms} setFilters={setPlatforms} />
            )}
          </div>
        </div>
      </ActivityFilterPortal>
      <KeywordsFilterDisplay />
      {highlightActivityId && highlightInfo.done === false && (
        <div className="activities-waiter">
          <MiniSpinner />
        </div>
      )}
      {isLoading ? (
        <ActivitiesLoader />
      ) : (
        <>
          {isError ? (
            <ErrorText style={{ width: '100%', backgroundColor: 'transparent', height: '100%' }} />
          ) : (
            !isLoading && data && (
              <>
                {data?.length === 0 ? <div style={{ textAlign: 'center ', paddingTop: '10px' }}>No activities could be found</div> : (
                  <div
                    onScroll={(e) => fetchMoreOnBottomReached(e.target)}
                    className="inner-view-tab"
                    ref={scroller}
                  >
                    {finalActivites.filter(filterFn).map((act, ind) => (
                      <Activity
                        contactId={contactId}
                        isHighlighted={highlightActivityId === act.id}
                        onHighlightPresent={scrollToHighlighted}
                        updateOriginPlatformLink={updateOriginPlatformLink}
                        act={act}
                        ind={ind}
                        isFullViewForced={isFullViewForced}
                        key={act._id}
                      />
                    ))}
                  </div>

                )}

              </>
            )
          )}

        </>
      )}
    </div>
  );
}

export default observer(Activities);
