import React, { Component } from 'react';

import { observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';
import {
  Modal, Select, Input, Divider, Button, Space,
} from 'antd';
import Dal from '../../../Data/Dal';

import issueIcon from '../../../Assets/Images/redesign/report-issue-ico-circ.svg';
import submitIcon from '../../../Assets/Images/redesign/post-submit-success.svg';

import './styles.scss';
import DrawerHandler from '../../../Screens/Crm/Drawer/state/handler';

const { Option } = Select;
const { TextArea } = Input;

class ReportModal extends Component {
  problem = null;
  description = null;
  hasFinished = false;
  constructor(props) {
    super(props);
    makeObservable(this, {
      problem: observable,
      description: observable,
      hasFinished: observable,
    });
    this.closeModal = this.closeModal.bind(this);
    this.submitModal = this.submitModal.bind(this);
    this.sendIssue = this.sendIssue.bind(this);
  }

  closeModal() {
    runInAction(
      () => {
        this.problem = null;
        this.description = null;
        Dal.showReportModal = false;
      },
    );
  }

  async sendIssue() {
    const resp = await Dal.reportBadMergeIssue(
      this.problem,
      this.description,
      DrawerHandler.userData,
    );
    if (resp.status === 200) {
      runInAction(() => { this.hasFinished = true; });
    }
  }

  submitModal() {
    runInAction(
      () => { this.problem = null; },
      (this.description = null),
      (this.hasFinished = false),
      (Dal.showReportModal = false),
    );
  }

  render() {
    const { visible } = this.props;
    return (
      <Modal
        visible={visible}
        closable={false}
        maskClosable
        onCancel={this.closeModal}
        keyboard
        maskStyle={{ backgroundColor: 'rgba(229, 243, 255, 0.8)' }}
        className="crm-report-modal"
        width={620}
        footer={null}
        zIndex={9999}
      >
        <div style={{ textAlign: 'center' }}>
          <img
            src={this.hasFinished ? submitIcon : issueIcon}
            alt="icon"
            style={{ marginTop: 40 }}
          />
        </div>
        {this.hasFinished ? (
          <>
            <p className="crm-report-issue-text-submit">Thanks!</p>
            <p className="crm-report-issue-text-submit-small">We are on it.</p>
            <Divider style={{ marginTop: 30 }} />
            <div style={{ textAlign: 'center' }}>
              <Button
                className="crm-report-submit-btn"
                onClick={this.submitModal}
              >
                Close
              </Button>
            </div>
          </>
        ) : (
          <>
            <p className="crm-report-issue-text">Report an issue</p>
            <p className="crm-report-issue-text black">
              Issue with this contact? Please let us know
            </p>
            <div className="crm-report-issue-select-wrapper">
              <p className="crm-report-issue-select-label">
                What seems to be the problem?
              </p>
              <Select
                placeholder="-select-"
                value={this.problem ?? null}
                dropdownStyle={{ zIndex: 10000 }}
                onChange={(value) => runInAction(() => { this.problem = value; })}
                bordered={false}
                className="crm-report-select"
              >
                <Option value="The match is incorrect">
                  The match is incorrect
                </Option>
                <Option value="This member should have been matched">
                  This member should have been matched
                </Option>
              </Select>
              <p className="crm-report-issue-select-label two">
                Describe your issue
              </p>
              <TextArea
                rows={5}
                className="crm-report-issue-textarea"
                value={this.description ?? ''}
                onChange={(e) => runInAction(() => { this.description = e.target.value; })}
              />
            </div>
            <Divider />
            <div className="crm-report-issue-btns-wrapper">
              <Space direction="horizontal">
                <Button
                  className="crm-report-back-btn"
                  onClick={this.closeModal}
                >
                  Back
                </Button>
                <Button
                  className="crm-report-send-btn"
                  onClick={this.sendIssue}
                >
                  Send
                </Button>
              </Space>
            </div>
          </>
        )}
      </Modal>
    );
  }
}

export default observer(ReportModal);
