import React from 'react';
import { observer } from 'mobx-react';
import {
  Modal, Button, Input, Form, Space, Divider,
} from 'antd';
import { makeObservable, observable, runInAction } from 'mobx';
import Dal from '../../Data/Dal';
import zendeskConnectImage from '../../Assets/Images/redesign/ico-integrate-with-zendesk.svg';
import errorIcon from '../../Assets/Images/redesign/connection-error-ico.svg';

import '../Facebook/Pages/styles.scss';
import './styles.scss';

class ZendeskModal extends React.Component {
  currentStep = 0;
  subdomain = '';
  email = '';
  token = '';
  areBadCredentials = false;

  constructor() {
    super();

    makeObservable(this, {
      currentStep: observable,
      subdomain: observable,
      email: observable,
      token: observable,
      areBadCredentials: observable,
    });
  }

  async createAsset() {
    const resp = await Dal.createNewAssets([
      {
        token: this.token,
        link: this.email,
        name: this.subdomain,
        community_id: Dal.getCurrentCommunityId(),
        service_name: 'zendesk',
        type: 'account',
      },
    ]);
    if (resp.response && resp.response.status === 500) {
      runInAction(() => { this.areBadCredentials = true; });
    } else {
      runInAction(() => this.currentStep++);
    }
  }

  isButtonEnabled() {
    return (
      this.subdomain.length > 0
      && this.email.length > 0
      && this.token.length > 0
    );
  }

  getInsights() {
    this.props.zendeskConnected();
  }

  renderContentForStep() {
    switch (this.currentStep) {
      case 0:
        return (
          <>
            <img
              src={zendeskConnectImage}
              alt=""
              width={window.screen.width < 1500 ? 154 : 271}
            />
            <p className="pagesHeader">Zendesk integration</p>
            <p className="pagesSubtitle">Ways to use Zendesk integration</p>
            <Divider />
            <ul className="textList">
              <li>
                Gain insights related to all tickets in your Zendesk account.
              </li>
            </ul>
            <p className="Simpoco-automaticall">
              LoudnClear will automatically fetch all tickets in your Zendesk
              account (content + creator). This including all historical
              tickets.
            </p>
            <Divider />
            <Button
              onClick={() => runInAction(
                () => this.currentStep++,
                (this.areBadCredentials = false),
              )}
              className="zendeskBtnOne"
            >
              Connect with Zendesk
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <img
              src={zendeskConnectImage}
              alt=""
              width={window.screen.width < 1500 ? 154 : 271}
            />
            <p className="pagesHeader">Zendesk integration</p>
            <p className="pagesSubtitle">
              Please enter your Zendesk account credentials
            </p>
            <Divider />
            <div>
              <div
                style={{ marginTop: 20, margin: 'auto', width: 460 }}
                className="zendeskForm"
              >
                <Form layout="vertical">
                  <Form.Item
                    label="Subdomain"
                    required
                    htmlFor={
                      this.areBadCredentials
                        ? 'zendeskCredsBad'
                        : 'zendeskCreds'
                    }
                  >
                    <Input
                      placeholder="yoursubdomain.zendesk.com"
                      onChange={(e) => runInAction(() => { this.subdomain = e.target.value; })}
                      className={
                        !this.areBadCredentials
                          ? 'zendeskCredsInput'
                          : 'zendeskCredsInput bad'
                      }
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    required
                    htmlFor={
                      this.areBadCredentials
                        ? 'zendeskCredsBad'
                        : 'zendeskCreds'
                    }
                  >
                    <Input
                      placeholder="Enter your Email used with Zendesk"
                      onChange={(e) => runInAction(() => { this.email = e.target.value; })}
                      className={
                        !this.areBadCredentials
                          ? 'zendeskCredsInput'
                          : 'zendeskCredsInput bad'
                      }
                      allowClear
                    />
                  </Form.Item>
                  <Form.Item
                    label="API token"
                    required
                    htmlFor={
                      this.areBadCredentials
                        ? 'zendeskCredsBad'
                        : 'zendeskCreds'
                    }
                  >
                    <Input
                      placeholder="Enter your API token"
                      onChange={(e) => runInAction(() => { this.token = e.target.value; })}
                      className={
                        !this.areBadCredentials
                          ? 'zendeskCredsInput'
                          : 'zendeskCredsInput bad'
                      }
                      allowClear
                    />
                  </Form.Item>
                </Form>
                {this.areBadCredentials ? (
                  <div style={{ display: 'flex', marginTop: 20 }}>
                    <img src={errorIcon} width={40} height={40} alt="" />
                    <p className="zendeskErrorText">
                      One or more of the credential is incorrent. Please try
                      again.
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <Divider />
            <Space direction="horizontal">
              <Button
                onClick={() => runInAction(() => { this.currentStep = 0; })}
                className="zendeskBtn back"
              >
                Back
              </Button>
              <Button
                onClick={this.createAsset.bind(this)}
                disabled={!this.isButtonEnabled()}
                className={
                  this.isButtonEnabled()
                    ? 'zendeskBtn next'
                    : 'zendeskBtn next disabled'
                }
              >
                Connect
              </Button>
            </Space>
          </>
        );
      case 2:
        return (
          <>
            <img
              src={zendeskConnectImage}
              alt=""
              width={window.screen.width < 1500 ? 154 : 271}
            />
            <p className="pagesHeader">Zendesk connected</p>
            <p className="pagesSubtitle">
              LoudnClear is enabled to your Zendesk account.
            </p>
            <Button
              className="zendeskBtnOne"
              style={{ marginTop: 30 }}
              onClick={this.getInsights.bind(this)}
            >
              Get insights
            </Button>
          </>
        );
      default:
    }
  }

  render() {
    return (
      <Modal
        visible={this.props.isVisible}
        closable
        onCancel={this.props.closeModalWithoutFetching}
        className="integrationModal"
        wrapClassName="integrationModalWrapper"
        footer={null}
      >
        {this.renderContentForStep()}
      </Modal>
    );
  }
}

export default observer(ZendeskModal);
