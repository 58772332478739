import { useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import Box from '../../box';
import { ReactComponent as ReactIcon } from '../../../../../Assets/Images/icons/playbooks/sources-custom.svg';
import ChooseAutomationModal from '../automationModal';
import reactors from '../automationModal/_data/templates/reactors';
import ActiveAutomationText from '../automationModal/ui/activeAutomationText';

export default function ReactionSelect() {
  const { values, setFieldValue } = useFormikContext();
  const [modal, setModal] = useState(false);
  const activePayload = useMemo(() => {
    if (values.action) {
      if (values.action?._isComplete) {
        const reactor = Object.values(reactors).find((r) => r.id === values.action.possible_action_id);
        return {
          title: reactor.text,
          desc: <ActiveAutomationText reactor={reactor} />,
          icon: reactor.icon,
          bgColor: '#E5F3FF',
        };
      }
    }
    return null;
  }, [values.action]);
  return (
    <div className="flow-part">
      <ChooseAutomationModal isShown={modal} onClose={() => setModal(false)} />
      <div className="flow-part_title">Reaction</div>
      <Box
        isDisabled={!values.trigger}
        placeholderTitle="Select automation"
        placeholderDesc="Select a platform you would like to integrate to"
        placeholderIcon={<ReactIcon />}
        activePayload={activePayload}
        onClick={() => setModal(true)}
        onEditClick={() => setModal(true)}
        onDeleteClick={(e) => { e.stopPropagation(); setFieldValue('action', null); }}
      />
    </div>
  );
}
