import React from 'react';

const generalInfoIcon = ({ fill = '#6b9ff4', width = 25, height = 25 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="CRM-v2/list-advocates"
        transform="translate(-104.000000, -133.000000)"
        fill={fill}
      >
        <g id="Group-8" transform="translate(70.000000, 0.000000)">
          <g
            id="general-/-info-/-ui-info-ico-gray"
            transform="translate(34.000000, 133.000000)"
          >
            <path
              d="M12,21 C14.3873269,21 16.6757666,20.0518811 18.3643864,18.3643864 C20.0518811,16.6757948 21,14.3872425 21,12 C21,9.61275746 20.0518811,7.32423336 18.3643864,5.63561364 C16.6757948,3.94811891 14.3872425,3 12,3 C9.61275746,3 7.32423336,3.94811891 5.63561364,5.63561364 C3.94811891,7.32420524 3,9.61275746 3,12 C3,14.3872425 3.94811891,16.6757666 5.63561364,18.3643864 C7.32420524,20.0518811 9.61275746,21 12,21 Z M12,5.25004922 C13.7896725,5.25004922 15.5068953,5.96085012 16.7725163,7.22756804 C18.0392343,8.49318908 18.7500352,10.210215 18.7500352,12.0000844 C18.7500352,13.7899538 18.0392343,15.5069797 16.7725163,16.7726007 C15.5068953,18.0393186 13.7898694,18.7501195 12,18.7501195 C10.2101306,18.7501195 8.49310471,18.0393186 7.22748366,16.7726007 C5.96076575,15.5069797 5.24996484,13.7899538 5.24996484,12.0000844 C5.24996484,10.210215 5.96076575,8.49318908 7.22748366,7.22756804 C8.49310471,5.96085012 10.2101306,5.25004922 12,5.25004922 Z M13.1249965,16.7812632 L10.8750035,16.7812632 L10.8750035,11.1562808 L13.1249965,11.1562808 L13.1249965,16.7812632 Z M13.1249965,9.46878604 L10.8750035,9.46878604 L10.8750035,7.21879307 L13.1249965,7.21879307 L13.1249965,9.46878604 Z"
              id="Page-1-Copy"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default generalInfoIcon;
