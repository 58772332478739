import iconsMapping from '../iconsMapping';

export const all = {
  segment: {
    key: 'segment',
    label: 'Segment',
    icon: iconsMapping.dimension.segment,
  },
  // segment_time: { NOT NOW
  //   key: 'segment_time',
  //   label: 'Segment (Time based)',
  //   icon: iconsMapping.dimension.segment_time,
  // },
  sentiment: {
    key: 'sentiment',
    label: 'Sentiment',
    icon: iconsMapping.dimension.sentiment,
  },
  sentiment_time: {
    key: 'sentiment_time',
    label: 'Sentiment (Time based)',
    icon: iconsMapping.dimension.sentiment_time,
  },
  platform: {
    key: 'platform',
    label: 'Platform',
    icon: iconsMapping.dimension.platform,
  },
  tag: {
    key: 'tag',
    label: 'Tag',
    icon: iconsMapping.dimension.tag,
  },
  title: {
    key: 'title',
    label: 'Title',
    icon: iconsMapping.dimension.title,
  },
  source: {
    key: 'source',
    label: 'Source',
    icon: iconsMapping.dimension.source,
  },
  source_time: {
    key: 'source_time',
    label: 'Source (Time based)',
    icon: iconsMapping.dimension.source_time,
  },
  dictionary: {
    key: 'dictionary',
    label: 'Dictionary',
    icon: iconsMapping.dimension.dictionary,
  },
  custom_field: {
    key: 'custom_field',
    label: 'Custom field',
    icon: iconsMapping.dimension.custom_field,
  },
  custom_field_time: {
    key: 'custom_field_time',
    label: 'Time',
    icon: iconsMapping.dimension.custom_field,
  },
  assigned_employee: {
    key: 'assigned_employee',
    label: 'Assigned employee',
    icon: iconsMapping.dimension.assigned_employee,
  },
  time: {
    key: 'time',
    label: 'Time',
    icon: iconsMapping.dimension.time,
  },
  action_name: {
    key: 'action_name',
    label: 'Action name',
    icon: iconsMapping.dimension.action_name,
  },
};

/** INTERFACE dimensionOptions
 * [measure_y]: OPTIONS - either a function or an array
 */

const dimensionOptions = {
  contacts: {
    count: [
      all.segment,
      // all.segment_time,
      all.sentiment,
      all.sentiment_time,
      all.platform,
      all.tag,
      all.title,
      all.source,
      all.dictionary,
      all.custom_field,
      all.assigned_employee,
    ],
    sum: [
      all.custom_field_time,
    ],
  },
  accounts: {
    count: [
      all.segment,
      all.sentiment,
      all.platform,
      all.tag,
      all.source,
      all.dictionary,
      all.custom_field,
      all.assigned_employee,
    ],
  },
  interactions: {
    count: [
      all.sentiment,
      all.dictionary,
      all.segment,
      all.source,
      all.source_time,
      all.time,
    ],
    post_count: [all.sentiment, all.time, all.source_time],
    comment_count: [all.sentiment, all.time, all.source_time],
    word_count: [all.source, all.sentiment],
    sum: [
      all.custom_field_time,
    ],
  },
  actions: {
    count: [
      all.segment,
      all.source_time,
      all.time,
      all.action_name,
    ],
    sum: [
      all.custom_field_time,
    ],
  },
  sources: {
    member_count: [
      all.time,
    ],
  },
};

export default dimensionOptions;
