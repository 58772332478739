import { Button, Tag } from 'antd';
import { useFormikContext } from 'formik';
import { AnimatePresence } from 'framer-motion';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import PlusIcon from '../../Assets/Images/icons/general-plus-ui-plus-ico-up.svg';
import XIcon from '../../Assets/Images/icons/ui-x-ico-active.svg';
import './style.scss';

function Editable({
  onSelect, onFocus, onBlur, onHasValue,
}) {
  const [value, setValue] = useState('');
  const keyDownHandler = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        onSelect(value);
        setValue('');
      }
    },
    [value, onSelect],
  );
  useEffect(() => {
    onHasValue(value?.length !== 0);
  }, [value]); // eslint-disable-line
  return (
    <input
      className="keywords-editable"
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={keyDownHandler}
    />
  );
}

const BoxMultiPicker = ({
  field, placeholder, fieldName, btnText,
}) => {
  const { setFieldValue } = useFormikContext();
  const [inputHasValue, setInputHasValue] = useState(false);
  const mainBox = useRef();

  const removeWord = useCallback(
    (word) => {
      word
        && setFieldValue(fieldName, [
          ...new Set([...field.value.filter((w) => w !== word)]),
        ]);
    },
    [field, setFieldValue],
  );

  const addWord = useCallback(
    (word) => {
      word
        && setFieldValue(fieldName, [...new Set([...field.value, word])]);
    },
    [field, setFieldValue],
  );

  const focusEditable = useCallback(() => {
    try {
      document.querySelector('.keywords-editable').focus();
    } catch (e) { } // eslint-disable-line
  }, []);

  const editableOnFocus = useCallback(() => {
    mainBox.current.classList.add('focused');
  }, [mainBox]);

  const editableOnBlur = useCallback(() => {
    mainBox.current.classList.remove('focused');
  }, [mainBox]);

  return (
    <div
      className="ant-input keywords"
      ref={mainBox}
      onClick={focusEditable}
    >
      {field?.value?.length === 0 && !inputHasValue && (
        <div className="keywords-empty">
          {placeholder}
        </div>
      )}
      <div className="all-keywords">
        <AnimatePresence>
          {field.value
            && field.value.map((tag) => (
              <Tag label={tag} key={tag}>
                <img onClick={() => removeWord(tag)} src={XIcon} width="20" height="20" alt="Close" />
                {tag}
              </Tag>
            ))}
        </AnimatePresence>
        <Editable
          onSelect={addWord}
          onHasValue={setInputHasValue}
          onFocus={editableOnFocus}
          onBlur={editableOnBlur}
        />
      </div>
      <div className="divide" />
      <Button
        className="keywords-add"
        icon={<img src={PlusIcon} width="24" height="24" alt="add" />}
      >
        {btnText}
      </Button>
    </div>
  );
};

export default BoxMultiPicker;
