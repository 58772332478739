import leftSide from '../../../../../Assets/Images/icons/all-fields-arr-1.svg';
import rightSide from '../../../../../Assets/Images/icons/all-fields-arr-2.svg';

export default function MapperDivider({ label }) {
  return (
    <div className="mapper-divider">
      <img src={leftSide} width="235" height="30" alt="Your csv" />
      <div>
        {label}
      </div>
      <img src={rightSide} width="235" height="30" alt="LoudNClear feilds" />
    </div>
  );
}
