import { Tooltip, Radio, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import { motion } from 'framer-motion';
import { Link, useHistory } from 'react-router-dom';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import moment from 'moment';

import empty from '../../../Assets/Images/icons/illustration-contacts.svg';
import { ReactComponent as Matrix6 } from '../../../Assets/Images/icons/linematrix-6.svg';
import { ReactComponent as Matrix5 } from '../../../Assets/Images/icons/linematrix-5.svg';
import { ReactComponent as Matrix4 } from '../../../Assets/Images/icons/linematrix-4.svg';
import { ReactComponent as Matrix3 } from '../../../Assets/Images/icons/linematrix-3.svg';
import { ReactComponent as Matrix2 } from '../../../Assets/Images/icons/linematrix-2.svg';
import { ReactComponent as EmptyMatrix2 } from '../../../Assets/Images/icons/linematrix-2-empty.svg';
import { ReactComponent as EmptyButOneMatrix2 } from '../../../Assets/Images/icons/linematrix-2-oneempty.svg';

import MiniSpinner from '../../Crm/Drawer/comps/common/miniSpinner';
import gotoIcon from '../../../Assets/Images/icons/ui-icon-expand-expand-active.svg';

import othersIcon from '../../../Assets/Images/icons/repo-icon-others-invert.svg';
import conSourceIcon from '../../../Assets/Images/icons/connect-data-source.svg';
import conCsvIcon from '../../../Assets/Images/icons/connect-csv.svg';

import Dal from '../../../Data/Dal';
import NetworkManager from '../../../Network/NetworkManager';

const options = [
  // { label: 'Last 7 days', value: 'week' },
  // { label: 'Last 30 days', value: 'month' },
  { label: 'Total', value: 'all' },
];

const numberFormatter = new Intl.NumberFormat('en', { notation: 'compact' });

// First load -

function ActivityWidget() {
  const [hoverKey, setHoverKey] = useState({
    ind: null,
    key: null,
  });
  const [readyData, setReadyData] = useState([{
    service: 'loading',
    title: 'Loading',
    image: othersIcon,
    color: '#d4d4d4',
    interactions_count: 0,
    members_count: 100,
  }, {
    service: 'loading2',
    title: 'Loading',
    image: othersIcon,
    color: '#d4d4d4',
    interactions_count: 0,
    members_count: 50,
  }]);

  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [totalContacts, setTotalContacts] = useState(0);
  const [filter, setFilter] = useState(options[0].value);
  const fetchDebouncer = useRef({
    elapsed: false,
    callback: null,
  });

  const onMouseEnter = useCallback((service, ind) => {
    setHoverKey({
      ind,
      key: service,
    });
  }, []);

  const onMouseLeave = useCallback(() => {
    setHoverKey({
      ind: null, key: null,
    });
  }, []);

  const transformData = useCallback((data) => {
    const final = [];
    Object.keys(data.by_service_type).forEach((serviceName) => {
      const service = data.by_service_type[serviceName];
      Object.keys(service).forEach((serviceType) => {
        const combinedName = `${serviceName}${serviceType}` === 's3file' ? 'csv' : `${serviceName}${serviceType}`;
        const integ = Dal.defaultIntegrations.find((i) => i.assetTag === combinedName);
        final.push({
          service: combinedName,
          title: integ.shortTitle,
          image: integ.image,
          color: integ.accentColor,
          members_count: service[serviceType].members_count || 0,
          interactions_count: service[serviceType].interactions_count || 0,
        });
      });
    });
    const sorted = final.sort((a, b) => b.members_count - a.members_count);
    const displayed = sorted.slice(0, 5);
    const rest = sorted.slice(5).reduce((acc, cur) => ({
      ...acc,
      service: 'other',
      title: 'Others',
      image: othersIcon,
      color: '#d4d4d4',
      interactions_count: (acc.interactions_count ?? 0) + (cur.interactions_count ?? 0),
      members_count: acc.members_count + cur.members_count,
    }), { interactions_count: 0, members_count: 0 });
    if (!rest?.service) return displayed;
    return [...displayed, rest];
  }, []);

  const fetchData = useCallback(async (since, callback) => {
    const until = moment().add(2, 'd');
    const data = await NetworkManager.getConnectedChannel(
      Dal.getCurrentCommunityId(),
      since,
      until,
    );
    callback(data);
  }, []);

  const generateClassName = useCallback((def, service) => {
    const classes = [def];
    if (hoverKey.key === service) classes.push('hovered');
    else if (hoverKey.key !== null) classes.push('faded');
    return classes.join(' ');
  }, [hoverKey]);

  useEffect(() => {
    const deb = fetchDebouncer.current;
    deb.elapsed = false;
    const days = (() => {
      if (filter === 'week') return 7;
      return filter === 'month' ? 30 : 365;
    })();
    const since = moment().subtract(days, 'd');
    setLoading(true);

    setTimeout(() => {
      deb.elapsed = true;
      if (deb.callback) {
        deb.callback();
      }
    }, 1000);

    const handleSuccess = (d) => {
      const functionality = () => {
        const t = transformData(d);
        setReadyData(t);
        setLoading(false);
        setTotalContacts(t.reduce((acc, cur) => acc + cur.members_count, 0));
      };
      if (deb.elapsed === false) {
        deb.callback = functionality;
      } else {
        functionality();
      }
    };

    fetchData(since, handleSuccess);

    return () => {
      deb.callback = null;
    };
  }, [filter, fetchData, transformData]);

  return (
    <div className="wactivity">
      <Link to="/dashboard/integrations" className="goto-link">
        <span>More integrations</span>
        <img src={gotoIcon} width="20" height="20" alt="Go to" />
      </Link>
      <div className="wactivity-title">
        <div className="section-title">
          Recent activity
          {isLoading && <div className="spinnertitle"><MiniSpinner /></div>}
        </div>
        {/* <Radio.Group
          optionType="button"
          options={options}
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
        /> */}
      </div>
      <div className="wactivity-wrapper">
        <div className="thewidget">
          <div className="thewidget-info">
            <div className="thewidget-info_cols">
              {readyData.length !== 0 && (
                <>
                  <div>Interactions</div>
                  <div>Contacts</div>
                </>
              )}
            </div>
            {readyData.length < 2 && (
              <>
                <div
                  onMouseEnter={() => onMouseEnter('dsource', 0)}
                  onMouseLeave={onMouseLeave}
                  className={generateClassName('thewidget-info_box full', 'dsource')}
                  onClick={() => history.push('/dashboard/integrations')}
                >
                  <div className="thewidget-info_box_image">
                    <img src={conSourceIcon} width="24" height="24" alt="Icon" />
                  </div>
                  <div className="thewidget-info_box_title">
                    Connect data source
                  </div>
                  <div className="thewidget-info_box_inter" />
                  <div className="thewidget-info_box_contacts" />
                </div>
                {readyData.length < 1 && (
                  <div
                    onMouseEnter={() => onMouseEnter('ccsv', 1)}
                    onMouseLeave={onMouseLeave}
                    className={generateClassName('thewidget-info_box full', 'ccsv')}
                    onClick={() => history.push('/dashboard/integrations?importCsv')}
                  >
                    <div className="thewidget-info_box_image">
                      <img src={conCsvIcon} width="24" height="24" alt="Icon" />
                    </div>
                    <div className="thewidget-info_box_title">
                      Upload contacts via CSV
                    </div>
                    <div className="thewidget-info_box_inter" />
                    <div className="thewidget-info_box_contacts" />
                  </div>
                )}
              </>
            )}
            {readyData.map((ser, ind) => (
              <div
                key={ser.service}
                onMouseEnter={() => onMouseEnter(ser.service, ind)}
                onMouseLeave={onMouseLeave}
                className={generateClassName('thewidget-info_box', ser.service)}
              >
                <div className="thewidget-info_box_image">
                  <img src={ser.image} width="24" height="24" alt="Icon" />
                </div>
                <div className="thewidget-info_box_title">
                  {isLoading ? <Skeleton width="80px" active paragraph={false} loading /> : ser.title}
                </div>
                <div className="thewidget-info_box_inter">
                  {isLoading ? <Skeleton width="10px" active paragraph={false} loading /> : numberFormatter.format(ser.interactions_count)}
                </div>
                <div className="thewidget-info_box_contacts">
                  {isLoading ? <Skeleton width="10px" active paragraph={false} loading /> : numberFormatter.format(ser.members_count)}
                </div>
              </div>
            ))}
          </div>
          <Matrix hoverKey={hoverKey} isLoading={isLoading} readyData={readyData} />
          <ContactsBox
            list={readyData}
            isLoading={isLoading}
            generateClassName={generateClassName}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            hoverKey={hoverKey}
            totalContacts={isLoading ? <MiniSpinner size="inherit" /> : totalContacts}
          />
        </div>
      </div>

    </div>
  );
}

const ContactsBox = ({
  list, totalContacts, onMouseEnter, onMouseLeave, generateClassName, hoverKey, isLoading,
}) => {
  const number = useRef();

  const highestContact = useMemo(() => list.reduce((acc, cur) => cur.members_count > acc ? cur.members_count : acc, 0), [list]); // eslint-disable-line
  const bars = useMemo(() => list.map((d) => ({
    service: d.service,
    color: d.color,
    members_count: d.members_count,
    realPercentage: parseInt((d.members_count * 100) / totalContacts, 10) || 0,
    percentage: parseInt((d.members_count * 100) / highestContact, 10) || 0,
  })), [list, totalContacts, highestContact]);
  const loadingBarsHeight = useMemo(() => {
    const barDef = (i) => bars?.[i]?.percentage || 0;
    return [
      [`${barDef(0)}%`, '15%', '65%', `${barDef(0)}%`],
      [`${barDef(1)}%`, '100%', '25%', `${barDef(1)}%`],
      [`${barDef(2)}%`, '22%', '50%', `${barDef(2)}%`],
      [`${barDef(3)}%`, '100%', '50%', `${barDef(3)}%`],
      [`${barDef(4)}%`, '31%', '100%', `${barDef(4)}%`],
      [`${barDef(5)}%`, '75%', '12%', `${barDef(5)}%`],
    ];
  }, [bars]);
  return (
    <div className={`thewidget-box ${hoverKey.ind !== null ? 'onhover' : ''}`}>
      {list.length === 0 ? (
        <div className="empty-contacts">
          <img src={empty} width="32" height="32" alt="no contacts" />
          <span>
            No contacts,
            add at least one
            data source
          </span>
        </div>
      ) : (
        <>
          <div className="thewidget-box_count">
            <div ref={number}>{Number.isInteger(totalContacts) ? numberFormatter.format(totalContacts) : totalContacts}</div>
            <div>Contacts</div>
          </div>
          <div className="thewidget-box_bars">
            {bars.map((b, ind) => (
              <Tooltip key={b.service} visible={hoverKey.key === b.service && !isLoading} color="white" title={<span style={{ color: 'black' }}>{`${b.realPercentage}%`}</span>}>
                <motion.div
                  key={`bar${ind}`} // eslint-disable-line
                  onMouseEnter={() => onMouseEnter(b.service)}
                  onMouseLeave={onMouseLeave}
                  className={generateClassName('bar', b.service)}
                  animate={{
                    height: isLoading ? loadingBarsHeight[ind] : `${b.percentage}%`,
                    transition: {
                      repeatType: isLoading ? 'mirror' : undefined,
                      repeat: isLoading ? Infinity : 0,
                      type: 'tween',
                      duration: isLoading ? 1.8 + (Math.random() * 1) : 0.5,
                      delay: isLoading && bars[0].service !== 'loading' ? Math.random() : 0,
                    },
                  }}
                  style={{ backgroundColor: isLoading ? '#d4d4d4' : b.color, transition: 'background-color 250ms ease' }}
                />
              </Tooltip>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const Matrix = ({ hoverKey, isLoading, readyData }) => {
  const Comp = useMemo(() => {
    if ((isLoading && readyData[0]?.service === 'loading')
      || (readyData.length === 0)) { // first load
      return EmptyMatrix2;
    }
    const mapp = {
      0: Matrix2,
      1: EmptyButOneMatrix2,
      2: Matrix2,
      3: Matrix3,
      4: Matrix4,
      5: Matrix5,
      6: Matrix6,
    };
    return mapp?.[readyData.length] || <></>;
  }, [readyData, isLoading]);
  const classes = useMemo(() => {
    const list = ['thewidget-matrix'];
    if (hoverKey.ind !== null) list.push(`onhover selected-${Math.abs(hoverKey.ind + 1)}`);
    return list.join(' ');
  }, [hoverKey]);
  return (
    <div className={classes}>
      <Comp />
    </div>
  );
};

export default observer(ActivityWidget);
