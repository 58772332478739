/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { memo, useMemo } from 'react';
import { Tooltip } from 'antd';
import keywordsSlicer from '../../utils/keywordSlicer';
import churnIco from '../../../../Assets/Images/icons/dictionaries/churn-indic.svg';
import upsellIco from '../../../../Assets/Images/icons/dictionaries/upsell-indic.svg';

function TextColumn({
  value, markedBy, colors, dictKeywordsById, dictId,
  isUpsell, isChurn,
}) {
  const slices = useMemo(() => keywordsSlicer({
    fullText: value,
    markedByIds: { [dictId]: markedBy },
    dictionaryKeywords: { [dictId]: dictKeywordsById },
  }), [value, markedBy, dictKeywordsById, dictId]);
  const tooltipText = useMemo(() => {
    const joiner = (arr) => (
      <div>
        {arr.map((a) => (
          <div key={a}>
            -
            {' '}
            {a}
          </div>
        ))}
      </div>
    );
    return {
      upsell: Array.isArray(isUpsell) ? joiner(isUpsell) : null,
      churn: Array.isArray(isChurn) ? joiner(isChurn) : null,
    };
  }, [isUpsell, isChurn]);
  const upsellIcon = useMemo(() => {
    if (tooltipText.upsell) {
      return (
        <Tooltip overlayClassName="lnc-tooltip" title={tooltipText.upsell}>
          <img src={upsellIco} alt="" width="24" height="24" />
        </Tooltip>
      );
    }
    return <img src={upsellIco} alt="" width="24" height="24" />;
  }, [tooltipText]);
  const churnIcon = useMemo(() => {
    if (tooltipText.churn) {
      return (
        <Tooltip overlayClassName="lnc-tooltip" title={tooltipText.churn}>
          <img src={churnIco} alt="" width="24" height="24" />
        </Tooltip>
      );
    }
    return <img src={churnIco} alt="" width="24" height="24" />;
  }, [tooltipText]);
  return (
    <div
      className="text_col"
    >
      {(isUpsell || isChurn) && (
        <span className="ai-indication">
          <>
            {isUpsell && upsellIcon}
            {isChurn && churnIcon}
          </>
        </span>
      )}
      {slices.map((slice, ind) => {
        if (typeof slice === 'string') return <span key={`${slice}-${ind}`}>{slice}</span>;
        return <span key={`${slice.keyword}-${ind}`} className="keyword-highlight" style={{ color: colors.textColor, backgroundColor: colors.bgColor }}>{slice.keyword}</span>;
      })}
    </div>
  );
}

export default memo(TextColumn);
