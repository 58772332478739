/* eslint-disable arrow-body-style */
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import salesforceIcon from '../../../../../../../../Assets/Images/redesign/repo-icon-salesforce@3x.jpg';
import LNCSelect from '../../../../../../../Select/LNCSelect';
import useSalesforceObjects from './additional/salesforce/hooks/useSalesforce';
import SalesforceOpportunityMapping from './additional/salesforce/mappingComp';
import { LNCFields } from './additional/salesforce/_data/fields';

const salesforceAccountCreateOpportunity = {
  id: 2,
  service_name: 'salesforce',
  service_type: 'account',
  action: 'create_opportunity',
  icon: <img src={salesforceIcon} width="24" height="24" alt="salesforce" />,
  validationSchema: Yup.object({
    object: Yup.string().required('Please select a SF object'),
    mapping: Yup.object({
      date: Yup.mixed(),
      text: Yup.mixed(),
      ai_categories_topics: Yup.mixed(),
      ai_explanations: Yup.mixed(),
      source: Yup.mixed(),
      subject: Yup.mixed(),
      contact: Yup.mixed(),
      account: Yup.mixed(),
      account_owner: Yup.mixed(),
      account_name: Yup.mixed(),
    }).required(),
  }),
  initialMetadata: {
    object: '',
    asset_id: '',
    mapping: LNCFields.reduce((acc, cur) => {
      if (cur.required) {
        return {
          ...acc,
          [cur.key]: '',
        };
      }
    }, {}),
  },
  text: 'Create an opportunity in Salesforce',
  templates: {
    ShortDisplay: () => {
      return (
        <span className="display">
          <img src={salesforceIcon} width="30" height="30" alt="slack" />
          Create a record of an existing Object on Salesforce
        </span>
      );
    },
    Display: () => {
      const { values: { action: { metadata } } } = useFormikContext();
      return (
        <span className="autotext">
          <span className="highlight">
            Create
            {' '}
            {metadata?.object}
          </span>
          {' '}
          object in Salesforce
        </span>
      );
    },
    Edit: () => {
      const { objects } = useSalesforceObjects();
      const { values: { action: { metadata } }, setFieldValue } = useFormikContext();
      const items = useMemo(() => {
        return (objects.data || []).map((t) => ({
          key: t,
          textLabel: t,
          label: t,
        }));
      }, [objects.data]);
      return (
        <>
          <span className="automation-edit-container">
            <span className="highlight then">Then</span>
            {' '}
            create a
            {' '}
            <LNCSelect
              value={metadata?.object}
              onSelect={(value) => setFieldValue('action.metadata.object', value)}
              isSearch
              placeholder="Select an  object"
              searchPlaceholder="Find an object"
              items={items}
            />
            {' '}
            object
            {' '}
            <span className="highlight flex-align platform">
              <img src={salesforceIcon} width="16" height="16" alt="slack" />
              Salesforce
            </span>
          </span>
        </>
      );
    },
    MetadataEdit: () => {
      const { values: { action: { metadata: { object } } } } = useFormikContext();
      const isActive = useMemo(() => !!object, [object]);
      return (
        <AnimatePresence>
          {isActive && (
            <motion.div
              initial={{ height: 0, overflow: 'hidden' }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
            >
              <SalesforceOpportunityMapping />
            </motion.div>
          )}
        </AnimatePresence>
      );
    },
  },
};

export default salesforceAccountCreateOpportunity;
