// import { useFormikContext } from 'formik';
import { useFormikContext } from 'formik';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import GeneralUserMultiIcon from '../../../Assets/Images/redesign/generalMultiUserIcon';
import dalInstance from '../../../Data/Dal';
import Select from '../../Select';

const metadataSchemas = {
  custom_fields: {
    key: 'metadata.custom_fields',
    validation: Yup.array().required().length(1),
    defaultValue: [],
    // showWhen: ({ values }) => values?.dimension?.includes('custom_field'),
    label: 'Field',
    Template: observer(({ value, onChange, error }) => {
      const { values } = useFormikContext();
      const options = useMemo(() => {
        if (dalInstance.customFields) {
          const cf = toJS(dalInstance.customFields);
          const filters = [];
          if (values.measureFunc === 'sum') {
            filters.push((c) => c.field_type === 'int', (c) => c.data_type === values.measureY);
          }
          return filters.reduce((acc, cur) => acc.filter(cur), cf);
        }
        return [];
      }, [values]);
      useEffect(() => () => {
        onChange(undefined); // null on dismount
      }, []); // eslint-disable-line
      // console.log('v', value, 'o', options);
      return (
        <Select
          value={value?.[0]}
          showSearch
          filterOption={(input, option) => {
            const opt = option?.children?.props?.children?.toLowerCase();
            if (!opt) return false;
            return opt.indexOf(input.toLowerCase()) >= 0;
          }}
          onChange={(v) => onChange([v])}
          isError={error}
        >
          {options.map((t) => (
            <Select.Option key={t.id} value={t.id}>
              <span>{t.label}</span>
            </Select.Option>
          ))}
        </Select>
      );
    }),
  },
  source: {
    key: 'metadata.asset_id',
    validation: Yup.number().notRequired(),
    defaultValue: null,
    // showWhen: ({ values }) => values?.dimension?.includes('custom_field'),
    label: 'Source',
    Template: observer(({ value, onChange, error }) => {
      const options = useMemo(() => {
        if (dalInstance.assets) return toJS(dalInstance.assets.accounts);
        return [];
      }, []);
      const findImage = useCallback((asset) => dalInstance.defaultIntegrations.find((f) => f.assetTag === (asset === 's3file' ? 'csv' : asset))?.image, []);
      useEffect(() => () => {
        onChange(undefined); // null on dismount
      }, []); // eslint-disable-line
      // console.log('v', value, 'o', options);
      return (
        <Select allowClear placeholder="Any" value={value} onChange={(v) => onChange(v)} isError={error}>
          {options.map((t) => (
            <Select.Option key={t.id} value={t.id}>
              <img src={findImage(t.service_name + t.type)} width="22" alt="" height="22" />
              <span>{t.name}</span>
            </Select.Option>
          ))}
        </Select>
      );
    }),
  },
  sentiment: {
    key: 'metadata.emotion_key',
    validation: Yup.number().notRequired(),
    defaultValue: null,
    // showWhen: ({ values }) => values?.dimension?.includes('custom_field'),
    label: 'Sentiment',
    Template: observer(({ value, onChange, error }) => {
      const options = useMemo(() => [{ id: 1, name: 'Negative' }, { id: 2, name: 'Neutral' }, { id: 3, name: 'Positive' }, { id: 4, name: 'Mixed' }], []);
      useEffect(() => () => {
        onChange(undefined); // null on dismount
      }, []); // eslint-disable-line
      // console.log('v', value, 'o', options);
      return (
        <Select allowClear placeholder="Any" value={value} onChange={(v) => onChange(v)} isError={error}>
          {options.map((t) => (
            <Select.Option key={t.id} value={t.id}>
              <span>{t.name}</span>
            </Select.Option>
          ))}
        </Select>
      );
    }),
  },
  segment_id: {
    key: 'metadata.segment_id',
    validation: Yup.number().notRequired(),
    defaultValue: null,
    label: 'Segment',
    Template: observer(({ value, onChange, error }) => {
      useEffect(() => () => {
        onChange(undefined); // null on dismount
      }, []); // eslint-disable-line
      return (
        <Select
          allowClear
          showSearch
          placeholder="Any"
          filterOption={(input, option) => String(option.key).toLowerCase().includes(input.toLowerCase())}
          value={value}
          onChange={(v) => onChange(v)}
          isError={error}
        >
          {toJS(dalInstance.segments).segments.map((opt) => (
            <Select.Option key={`seg-${opt.name}-${opt.id}`} value={opt.id}>
              <GeneralUserMultiIcon fill={opt.color} />
              <span>{opt.name}</span>
            </Select.Option>
          ))}
        </Select>
      );
    }),
  },
  segment_ids: {
    key: 'metadata.segment_ids',
    validation: Yup.array().notRequired(),
    defaultValue: null,
    label: 'Segment',
    Template: observer(({ value, onChange, error }) => {
      useEffect(() => () => {
        onChange(undefined); // null on dismount
      }, []); // eslint-disable-line
      return (
        <Select
          allowClear
          mode="multiple"
          placeholder="Any"
          value={value}
          onChange={(v) => onChange(v)}
          isError={error}
          filterOption={(input, option) => String(option.key).toLowerCase().includes(input.toLowerCase())}
        >
          {toJS(dalInstance.segments).segments.map((opt) => (
            <Select.Option key={`seg-${opt.name}-${opt.id}`} value={opt.id}>
              <GeneralUserMultiIcon fill={opt.color} />
              <span>{opt.name}</span>
            </Select.Option>
          ))}
        </Select>
      );
    }),
  },
};

const metadataSchemasByDimension = {
  contacts: {
    count: {
      title: [metadataSchemas.segment_id],
      sentiment: [metadataSchemas.segment_id],
      sentiment_time: [metadataSchemas.sentiment, metadataSchemas.segment_id],
      platform: [metadataSchemas.segment_id],
      tag: [metadataSchemas.segment_id],
      source: [metadataSchemas.segment_id],
      dictionary: [metadataSchemas.segment_id],
      custom_field: [
        metadataSchemas.segment_id,
        metadataSchemas.custom_fields,
      ],
      assigned_employee: [metadataSchemas.segment_id],
      segment: [metadataSchemas.segment_ids],
    },
    sum: {
      custom_field_time: [
        metadataSchemas.custom_fields,
        metadataSchemas.segment_id,
      ],
    },
  },
  accounts: {
    count: {
      segment: [metadataSchemas.segment_id],
      sentiment: [metadataSchemas.segment_id],
      platform: [metadataSchemas.segment_id],
      tag: [metadataSchemas.segment_id],
      source: [metadataSchemas.segment_id],
      dictionary: [metadataSchemas.segment_id],
      assigned_employee: [metadataSchemas.segment_id],
      custom_field: [
        metadataSchemas.segment_id,
        metadataSchemas.custom_fields,
      ],
    },
  },
  interactions: {
    word_count: {
      source: [
        metadataSchemas.source,
      ],
      sentiment: [
        metadataSchemas.sentiment,
      ],
    },
    sum: {
      custom_field_time: [
        metadataSchemas.custom_fields,
        metadataSchemas.segment_id,
      ],
    },
    post_count: {
      sentiment: [metadataSchemas.segment_id],
      source_time: [metadataSchemas.source, metadataSchemas.segment_id],
      time: [metadataSchemas.source, metadataSchemas.segment_id],
    },
    comment_count: {
      sentiment: [metadataSchemas.segment_id],
      source_time: [metadataSchemas.source, metadataSchemas.segment_id],
      time: [metadataSchemas.source, metadataSchemas.segment_id],
    },
    count: {
      sentiment: [metadataSchemas.segment_id],
      dictionary: [metadataSchemas.segment_id],
      segment: [metadataSchemas.segment_id],
      source: [metadataSchemas.segment_id],
      source_time: [metadataSchemas.source, metadataSchemas.segment_id],
      time: [metadataSchemas.segment_id],
    },
  },
  actions: {
    sum: {
      custom_field_time: [
        metadataSchemas.custom_fields,
        metadataSchemas.segment_id,
      ],
    },
    count: {
      segment: [metadataSchemas.segment_ids],
      time: [metadataSchemas.segment_id],
      source_time: [metadataSchemas.source, metadataSchemas.segment_id],
      action_name: [metadataSchemas.segment_id],
    },
  },
  sources: {
    member_count: {
      time: [metadataSchemas.source, metadataSchemas.segment_id],
    },
  },
};

export default metadataSchemasByDimension;
