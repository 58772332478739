import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useSearchQuery(returnKeys) {
  const { search } = useLocation();

  return useMemo(() => {
    const p = new URLSearchParams(search);
    if (returnKeys) {
      return returnKeys.map((k) => p.get(k));
    }
    return p;
  }, [search, returnKeys]);
}

export default useSearchQuery;
