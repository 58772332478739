import facebookPagesIcon from '../../Assets/Images/redesign/repo-icon-facebook.svg';
import facebookGroupsIcon from '../../Assets/Images/redesign/repo-icon-facebook-group.svg';
import csvIcon from '../../Assets/Images/redesign/repo-icon-csv.svg';
import connectIcon from '../../Assets/Images/redesign/ui-icon-connect-purple.svg';
import selectedConnectIcon from '../../Assets/Images/redesign/ui-icon-connect-connect-green.svg';
import uploadIcon from '../../Assets/Images/icons/icon-20-x-20-ic-20-upload.svg';
import slackIcon from '../../Assets/Images/redesign/repo-icon-slack.svg';
import hubspotIcon from '../../Assets/Images/redesign/repo-icon-hubspot.svg';
import heapIcon from '../../Assets/Images/icons/heapicon.png';
import segmentIcon from '../../Assets/Images/icons/repo-icon-segment.svg';
import mixpanelIcon from '../../Assets/Images/icons/repo-icon-mixpanel.svg';
import googleDriveIcon from '../../Assets/Images/redesign/repo-icon-drive.svg';
import eventBriteIcon from '../../Assets/Images/redesign/repo-icon-eventbrite.svg';
import instaIcon from '../../Assets/Images/redesign/repo-icon-instagram.svg';
import mailchimpIcon from '../../Assets/Images/redesign/repo-icon-mailchimp.svg';
import messengerIcon from '../../Assets/Images/redesign/repo-icon-messenger.svg';
import twitterIcon from '../../Assets/Images/redesign/repo-icon-twitter.svg';
import typeformIcon from '../../Assets/Images/redesign/repo-icon-typeform.svg';
import salesforceIcon from '../../Assets/Images/icons/repo-icon-salesforce.svg';
import zendeskIcon from '../../Assets/Images/icons/repo-icon-zendesk.svg';
import crunIcon from '../../Assets/Images/icons/repo-icon-crunzero.png';
import intercomIcon from '../../Assets/Images/icons/intercom.svg';
import linkedinIcon from '../../Assets/Images/icons/linkedin.png';
import redditIcon from '../../Assets/Images/icons/repo-icon-reddit.svg';
import appsflyericon from '../../Assets/Images/icons/Appsflyer.svg';
import kibanaIcon from '../../Assets/Images/icons/Kibana.svg';
import zohoIcon from '../../Assets/Images/icons/zoho.svg';
import pendoicon from '../../Assets/Images/icons/pendologo.png';
import gongicon from '../../Assets/Images/icons/gonglogo.png';
import adobeAnalyticsLogo from '../../Assets/Images/icons/adobeanalytics.svg';
import mytechLogo from '../../Assets/Images/icons/mytechlogo.svg';
import walmartLogo from '../../Assets/Images/icons/walmartLogo.svg';
import amplitudeLogo from '../../Assets/Images/icons/amplitude.png';
import firebaseLogo from '../../Assets/Images/icons/Firebase.svg';
import snowflakeLogo from '../../Assets/Images/icons/snowflakes.svg';
import discourseLogo from '../../Assets/Images/icons/discourse-icon.svg';
import kustomerLogo from '../../Assets/Images/icons/kustomer-icon.svg';

const defaultIntegrations = [
  {
    id: 1,
    title: 'Facebook Group',
    shortTitle: 'Groups',
    image: facebookGroupsIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assets: [],
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#14a0f9',
    pieInnerColor: '#d1edff',
    assetTag: 'facebookgroup',
    targetTag: 'facebook',
    isPremium: false,
    hasActivityLink: ({ activity, asset }) => {
      const isComment = !!activity.parent_id;
      const baseLink = asset.link.includes('groups') ? asset.link : `https://facebook.com/groups/${asset.link.split('.com/')[1]}`;
      if (!isComment) return `${baseLink}/permalink/${activity.origin_id.split('_')[1]}`;
      return `${baseLink}/permalink/${activity.parent_id.split('_')[1]}/comments/${activity.origin_id}`;
    },
  },
  {
    id: 2,
    title: 'Facebook Page',
    shortTitle: 'Pages',
    image: facebookPagesIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assets: [],
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#14a0f9',
    pieInnerColor: '#d1edff',
    assetTag: 'facebookpage',
    targetTag: 'facebook',
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 3,
    title: 'CSV, Excel',
    shortTitle: 'CSV',
    image: csvIcon,
    ctaTitle: 'Upload',
    ctaImage: uploadIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    accentColor: '#aee7b2',
    bgColor: '#aee7b2',
    textColor: '#339438',
    pieColor: '#3ec067',
    pieInnerColor: '#c0ead3',
    assets: [],
    assetTag: 'csv',
    targetTag: 'csv',
    isPremium: false,
    hasActivityLink: false,
  },
  {
    title: 'Facebook messenger',
    shortTitle: 'Messenger',
    id: 4,
    image: messengerIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assets: [],
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#fb38a0',
    pieInnerColor: '#ffd3f5',
    assetTag: 'facebookmessenger',
    targetTag: 'facebook',
    isPremium: true,
    hasActivityLink: false,
  },
  {
    title: 'Slack workspace',
    shortTitle: 'Slack',
    id: 5,
    image: slackIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assets: [],
    accentColor: '#6d296e',
    bgColor: '#d6caff',
    textColor: '#4821cc',
    pieColor: '#511652',
    pieInnerColor: '#ddd1dd',
    assetTag: 'slackworkspace',
    targetTag: 'slack',
    isPremium: false,
    hasActivityLink: true,
  },
  {
    title: 'Twitter page',
    shortTitle: 'Twitter',
    id: 6,
    image: twitterIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assetTag: 'twitterpage',
    targetTag: 'twitter',
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#1da1f2',
    pieInnerColor: '#d1ebff',
    assets: [],
    isPremium: false,
    hasActivityLink: true,
  },
  {
    title: 'Zendesk account',
    shortTitle: 'Zendesk',
    id: 7,
    image: zendeskIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assetTag: 'zendeskaccount',
    targetTag: 'zendesk',
    accentColor: '#5d5d5d',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#134951',
    pieInnerColor: '#ddeaec',
    assets: [],
    isPremium: true,
    hasActivityLink: true,
  },
  {
    title: 'Intercom workspace',
    shortTitle: 'Intercom',
    id: 8,
    image: intercomIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assetTag: 'intercomworkspace',
    targetTag: 'intercom',
    accentColor: '#1162FB',
    bgColor: '#1162FB80',
    textColor: '#1162FB',
    pieColor: '#286efa',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: true,
  },
  {
    title: 'Reddit subreddit',
    shortTitle: 'Reddit',
    id: 9,
    image: redditIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assetTag: 'redditsubreddit',
    targetTag: 'reddit',
    accentColor: '#ff4500',
    bgColor: '#ff4500',
    pieColor: '#ff6f3a',
    pieInnerColor: '#ffdfd3',
    assets: [],
    isPremium: false,
    hasActivityLink: true,
  },
  {
    title: 'Salesforce account',
    shortTitle: 'Salesforce',
    id: 10,
    image: salesforceIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    accentColor: '#14a0f9',
    assetTag: 'salesforceaccount',
    targetTag: 'salesforce',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: ({ activity, asset }) => {
      const objectId = activity.origin_id || activity.link;
      if (!objectId) return null;
      const lnk = !asset.link ? 'https://emea.salesforce.com' : asset.link;
      return `${lnk}/${objectId}`;
    },
  },
  {
    id: 20,
    title: 'Pendo Workspace',
    shortTitle: 'Pendo',
    image: pendoicon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assetTag: 'pendoworkspace',
    targetTag: 'pendo',
    accentColor: '#ff4876',
    bgColor: '#ff4876',
    textColor: '#000',
    pieColor: '#ff4876',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 13,
    title: 'Hubspot Account',
    shortTitle: 'Hubspot',
    image: hubspotIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assetTag: 'hubspotaccount',
    targetTag: 'hubspot',
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 11,
    title: 'Mixpanel Project',
    shortTitle: 'Mixpanel',
    image: mixpanelIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: true,
    assetTag: 'mixpanelproject',
    targetTag: 'mixpanel',
    accentColor: '#5c3fce',
    bgColor: '#1162FB80',
    textColor: '#1162FB',
    pieColor: '#286efa',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    title: 'Instagram page',
    shortTitle: 'Instagram',
    image: instaIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    title: 'Linkedin Page',
    shortTitle: 'LinkedIn',
    image: linkedinIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    title: 'Google Drive folder',
    shortTitle: 'Drive',
    image: googleDriveIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#ffba00',
    pieInnerColor: '#fff3d1',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    title: 'Mailchimp',
    shortTitle: 'Mailchimp',
    image: mailchimpIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    title: 'Typeform',
    shortTitle: 'Typeform',
    image: typeformIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    title: 'Eventbrite form',
    shortTitle: 'Eventbrite',
    image: eventBriteIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 12,
    title: 'Segment Workspace',
    shortTitle: 'Segment',
    image: segmentIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'segmentworkspace',
    targetTag: 'segment',
    accentColor: '#55b685',
    bgColor: '#1162FB80',
    textColor: '#1162FB',
    pieColor: '#286efa',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 14,
    title: 'Churnzero Workspace',
    shortTitle: 'Churnzero',
    image: crunIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'churnzeroworkspace',
    targetTag: 'churnzero',
    accentColor: '#555555',
    bgColor: '#555555',
    textColor: '#fcfcfc',
    pieColor: '#134951',
    pieInnerColor: '#ddeaec',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 18,
    title: 'Heap Project',
    shortTitle: 'Heap',
    image: heapIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'heapproject',
    targetTag: 'heap',
    accentColor: '#555555',
    bgColor: '#555555',
    textColor: '#fcfcfc',
    pieColor: '#134951',
    pieInnerColor: '#ddeaec',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 16,
    title: 'Zoho CRM',
    shortTitle: 'Zoho',
    image: zohoIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'zohocrm',
    targetTag: 'zoho',
    accentColor: '#0152CB',
    bgColor: '#0152CB',
    textColor: '#fff',
    pieColor: '#286efa',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 17,
    title: 'ElasticSearch Index',
    shortTitle: 'ElasticSearch',
    image: kibanaIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'elasticsearchindex',
    targetTag: 'elasticsearch',
    accentColor: '#00BFB3',
    bgColor: '#00BFB3',
    textColor: '#000',
    pieColor: '#286efa',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 15,
    title: 'AppsFlyer Workspace',
    shortTitle: 'AppsFlyer',
    image: appsflyericon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'appsflyerworkspace',
    targetTag: 'appsflyer',
    accentColor: '#7AD143',
    bgColor: '#7AD143',
    textColor: '#000',
    pieColor: '#286efa',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 19,
    title: 'Gong Workspace',
    shortTitle: 'Gong',
    image: gongicon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'gongworkspace',
    targetTag: 'gong',
    accentColor: '#8039DF',
    bgColor: '#3E0C75',
    textColor: '#fff',
    pieColor: '#3E0C75',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 27,
    title: 'Adobe Analytics',
    shortTitle: 'Adobe Analytics',
    image: adobeAnalyticsLogo,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'adobeanalyticsworkspace',
    targetTag: 'adobeanalytics',
    accentColor: '#f91001',
    bgColor: '#f91001',
    textColor: '#000',
    pieColor: '#f91001',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 21,
    title: 'Walmart Workspace',
    shortTitle: 'Walmart',
    image: walmartLogo,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    isHidden: false,
    assetTag: 'walmartworkspace',
    targetTag: 'walmart',
    accentColor: '#0071ce',
    bgColor: '#0071ce',
    textColor: '#fff',
    pieColor: '#0071ce',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 22,
    title: 'Walmart MyTech',
    shortTitle: 'MyTech',
    image: mytechLogo,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    isHidden: false,
    assetTag: 'walmartmytech',
    targetTag: 'walmart',
    accentColor: '#0071ce',
    bgColor: '#0071ce',
    textColor: '#fff',
    pieColor: '#0071ce',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 23,
    title: 'Walmart NPS',
    shortTitle: 'Walmart NPS',
    image: csvIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    isHidden: false,
    assetTag: 'walmartnps',
    targetTag: 'walmart',
    accentColor: '#0071ce',
    bgColor: '#0071ce',
    textColor: '#fff',
    pieColor: '#0071ce',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 24,
    title: 'Walmart NPSvdi',
    shortTitle: 'Walmart NPSvdi',
    image: csvIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    isHidden: false,
    assetTag: 'walmartnpsvdi',
    targetTag: 'walmart',
    accentColor: '#0071ce',
    bgColor: '#0071ce',
    textColor: '#fff',
    pieColor: '#0071ce',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 25,
    title: 'Walmart Feedbacks',
    shortTitle: 'Walmart Feedbacks',
    image: csvIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    isHidden: false,
    assetTag: 'walmartfeedbacks',
    targetTag: 'walmart',
    accentColor: '#0071ce',
    bgColor: '#0071ce',
    textColor: '#fff',
    pieColor: '#0071ce',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 26,
    title: 'Walmart NPSHaTS',
    shortTitle: 'Walmart NPSHaTS',
    image: csvIcon,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    isHidden: false,
    assetTag: 'walmartnpshats',
    targetTag: 'walmart',
    accentColor: '#0071ce',
    bgColor: '#0071ce',
    textColor: '#fff',
    pieColor: '#0071ce',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 28,
    title: 'Amplitude Workspace',
    shortTitle: 'Amplitude',
    image: amplitudeLogo,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'amplitudeworkspace',
    targetTag: 'amplitude',
    accentColor: '#14a0f9',
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 29,
    title: 'Firebase Workspace',
    shortTitle: 'Firebase',
    image: firebaseLogo,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'firebaseworkspace',
    targetTag: 'firebase',
    accentColor: '#f9a114',
    bgColor: '##ffdf99',
    textColor: '#be8707',
    pieColor: '#e5e5e5',
    pieInnerColor: '#fafafa',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 30,
    title: 'Snowflake Workspace',
    shortTitle: 'Snowflake',
    image: snowflakeLogo,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'snowflakeworkspace',
    targetTag: 'snowflake',
    accentColor: '#0071ce',
    bgColor: '#0071ce',
    textColor: '#fff',
    pieColor: '#0071ce',
    pieInnerColor: '#d9e6ff',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 31,
    title: 'Discourse Forum',
    shortTitle: 'Discourse',
    image: discourseLogo,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'discourseforum',
    targetTag: 'discourse',
    accentColor: '#FFF9AE',
    bgColor: '#FFF9AE',
    textColor: '#fff',
    pieColor: '#FFF9AE',
    pieInnerColor: '#FFF9AE',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
  {
    id: 32,
    title: 'Kustomer Workspace',
    shortTitle: 'Kustomer',
    image: kustomerLogo,
    ctaTitle: 'Connect',
    ctaImage: connectIcon,
    ctaImageSelected: selectedConnectIcon,
    connected: false,
    supportedNow: false,
    assetTag: 'kustomerworkspace',
    targetTag: 'kustomer',
    accentColor: '#FC6661',
    bgColor: '#FC6661',
    textColor: '#000',
    pieColor: '#FC6661',
    pieInnerColor: '#FC6661',
    assets: [],
    isPremium: true,
    hasActivityLink: false,
  },
];

export default defaultIntegrations;
