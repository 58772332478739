import React, { Component } from 'react';
import { Card, Skeleton, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { motion, AnimatePresence } from 'framer-motion';
import './styles.scss';
import { computed, makeObservable } from 'mobx';
import GeneralUserMultiIcon from '../../../Assets/Images/redesign/generalMultiUserIcon';
import GeneralInfoIcon from '../../../Assets/Images/redesign/generalInfoIcon';
import editIcon from '../../../Assets/Images/redesign/ui-icon-edit-edit-active.svg';
import deleteIcon from '../../../Assets/Images/redesign/ui-icon-trash-trash-active-red.svg';
import CheckMarkIcon from '../../../Assets/Images/redesign/checkMarkIcon';

class CrmSegmentItemView extends Component {
  colorSet = undefined;

  constructor(props) {
    super(props);

    makeObservable(this, {
      cardClassname: computed,
    });
  }

  cardClicked() {
    this.props.cardClicked(this.props.id, {
      bgColor: this.props.mainColor,
      textColor: this.props.mainTextColor,
    });
  }

  editClicked() {
    this.props.editClicked(this.props.id);
  }

  deleteClicked() {
    this.props.deleteClicked(this.props.id);
  }

  upClicked() {
    this.props.upClicked(this.props.id);
  }

  downClicked() {
    this.props.downClicked(this.props.id);
  }

  showEditOption() {
    return false;
  }

  title() {
    return this.props.title;
  }

  subtitle() {
    return this.props.subtitle;
  }

  mainColor() {
    return this.props.mainColor ?? 'black';
  }

  iconColor() {
    return this.props.iconColor ?? this.mainColor;
  }

  mainTextColor() {
    return this.props.mainTextColor;
  }

  getIcon() {
    if (this.props.icon !== undefined) {
      return (
        <img className="segment-icon" alt="icon" src={this.props.icon} height="24" />
      );
    }
    return (
      <div className="segment-icon">
        <GeneralUserMultiIcon fill={this.iconColor()} />
      </div>
    );
  }

  getUnreadCount() {
    if (Number(this.props.count) === 0 || !this.props.count) return 0;
    if (this.props.count > 9999) return `${Math.floor(this.props.count / 1000)}K`;
    if (this.props.count > 999) return `${(this.props.count / 1000).toFixed(1)}K`;
    return this.props.count ?? 0; // .
  }

  getBackgroundColor() {
    return this.props.backgroundColor;
  }

  getShowFooterSection() {
    return this.props.showFooterSection;
  }

  getTitleTextColor() {
    return this.props.titleTextColor;
  }

  getSubtitleTextColor() {
    return this.props.subtitleTextColor;
  }

  getFooterTextColor() {
    return this.props.footerTextColor;
  }

  getEditMode() {
    return this.props.editMode;
  }

  getHasUpdate() {
    return this.props.hasUpdate;
  }

  get cardClassname() {
    const classes = ['crm-segment-card'];
    if (this.props.isSelected) classes.push('crm-segment-card-selected');
    if (this.props.isDisabled) classes.push('crm-segment-card-disabled');
    return classes.join(' ');
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div
          key={this.props.id}
          onClick={() => this.cardClicked()}
          style={{
            cursor: 'pointer',
            marginBottom: '10px',
            marginLeft: this.getEditMode() ? 0 : 0, // should be 10 once the resort option uncommented
          }}
        >
          <Card
            key={this.props.id}
            className={this.cardClassname}
            bodyStyle={{
              margin: '-18px',
              padding: '22px',
              backgroundColor: this.getBackgroundColor(),
              marginBottom: this.getShowFooterSection() ? '10xp' : '-15px',
            }}
            bordered={false}
            style={{
              overflow: 'hidden',
              width: '280px',
              borderRadius: '6px',
            }}
          >
            <Skeleton
              active
              paragraph={{ rows: 1, width: '200px' }}
              loading={this.props.loading}
            >
              <div
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '-8px',
                }}
              >
                {this.getIcon()}
                <div style={{ marginLeft: '16px', paddingTop: '5px' }}>
                  <p
                    className="crm-segment-item-title"
                    style={{
                      color: this.getTitleTextColor(),
                      fontWeight: this.getUnreadCount() ? 'bold' : 400,
                      paddingRight: this.getEditMode() ? 30 : 0,
                    }}
                  >
                    {this.title()}
                  </p>
                  <p
                    className="crm-segment-item-subtitle"
                    style={{
                      marginTop: '-15px',
                      color: this.getSubtitleTextColor(),
                    }}
                  >
                    {this.subtitle()}
                  </p>
                </div>
                <AnimatePresence>
                  {!this.getEditMode() && (
                    <motion.div
                      initial={{ opacity: 0, x: '-50px' }}
                      animate={{ opacity: 1, x: '0' }}
                      exit={{ x: '-50px', opacity: 0 }}
                      style={{
                        marginLeft: 'auto',
                        marginTop: '-8px',
                        marginRight: '8px',
                      }}
                    >
                      <Tooltip
                        color="white"
                        title={(
                          <span className="tooltip-body">
                            Total results:
                            {' '}
                            {this.getUnreadCount()}
                          </span>
                        )}
                      >
                        {this.getUnreadCount() ? (
                          <div
                            className="keyword-set-matches-count"
                            style={{
                              backgroundColor: this.mainColor(),
                              color: this.mainTextColor(),
                            }}
                          >
                            {this.props.isLoading ? (
                              <Spinner
                                tagBgColor={this.mainColor()}
                                tagTextColor={this.mainTextColor()}
                              />
                            ) : (
                              <>{this.getUnreadCount()}</>
                            )}
                          </div>
                        ) : (
                          <div
                            className="keyword-set-matches-count"
                            style={{
                              backgroundColor: this.mainColor(),
                              color: this.mainTextColor(),
                            }}
                          >
                            {this.props.isLoading ? (
                              <Spinner
                                tagBgColor={this.mainColor()}
                                tagTextColor={this.mainTextColor()}
                              />
                            ) : (
                              <div style={{ marginTop: 5 }}>
                                <CheckMarkIcon fill={this.mainTextColor()} />
                              </div>
                            )}
                          </div>
                        )}
                      </Tooltip>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <AnimatePresence>
                {!this.props.minified && this.getShowFooterSection() && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, marginTop: '-18px', opacity: 0 }}
                    className={this.getShowFooterSection() ? '' : 'hidden'}
                  >
                    <div
                      style={{
                        width: 'auto',
                        height: '1px',
                        borderBottom: 'dashed 1px #6b9ff4',
                        marginTop: '10px',
                      }}
                    />
                    <div
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        marginTop: '4px',
                        marginBottom: '-2px',
                      }}
                    >
                      <GeneralInfoIcon />
                      <span
                        className="crm-segment-item-main-footer-title"
                        style={{
                          marginTop: '5px',
                          marginLeft: '10px',
                          marginBottom: '9px',
                          color: this.getFooterTextColor(),
                        }}
                      >
                        Filter list, to create a new segments
                      </span>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </Skeleton>
          </Card>
        </div>
        {/* <div className={this.getEditMode() ? "" : "hidden"} style={{position: 'absolute', left: 2, top: 15}}> */}
        {/*    <div className="crm-segment-arrow-up" onClick={() => this.upClicked()}/> */}
        {/*    <div className="crm-segment-arrow-down" style={{marginTop: 15}} onClick={() => this.downClicked()}/> */}
        {/* </div> */}
        <AnimatePresence>
          {this.getEditMode()
            && this.props.deleteClicked
            && this.props.editClicked && (
              <motion.div
                initial={{ x: '100%', opacity: 0 }}
                animate={{ x: '0%', opacity: 1 }}
                exit={{ x: '130%', opacity: 0 }}
                style={{ position: 'absolute', right: 9, top: 6 }}
              >
                <img
                  onClick={() => this.deleteClicked()}
                  style={{
                    float: 'right',
                    marginRight: '0px',
                    marginTop: '5px',
                    cursor: 'pointer',
                  }}
                  alt="delete"
                  src={deleteIcon}
                />
                <img
                  onClick={() => this.editClicked()}
                  style={{
                    float: 'right',
                    marginRight: '15px',
                    marginTop: '5px',
                    cursor: 'pointer',
                  }}
                  alt="edit"
                  src={editIcon}
                />
              </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
}

function Spinner({ tagTextColor, tagBgColor }) {
  return (
    <div className="spinner">
      <div className="spinner__circle" style={{ backgroundColor: tagTextColor }}>
        <div
          className="spinner__circle-gradient"
          style={{
            backgroundColor: tagTextColor,
            backgroundImage: `linear-gradient(0deg,${tagTextColor} 0%,${tagBgColor} 50%)`,
          }}
        />

        <div
          className="spinner__circle-inner"
          style={{ backgroundColor: tagBgColor }}
        />
      </div>
    </div>
  );
}

export default observer(CrmSegmentItemView);
