import moment from 'moment';

const chartSettings = {
  colors: ['#8BD1EB'],
  title: {
    text: undefined,
  },
  chart: {
    type: 'column',
    height: 155,
  },
  labels: {
    style: {
      fontFamily: 'Poppins',
    },
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      borderRadius: 4,
      rotation: 50,
    },
    bar: {
      stacking: 'normal',
      colorByPoint: true,
      // borderRadius: 4,
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
    style: {
      fontFamily: 'Poppins',
    },
  },
  tooltip: {
    borderWidth: 1,
    borderColor: '#dadada',
    borderRadius: 8,
    backgroundColor: 'white',
    useHTML: true,
    style: {
      fontFamily: 'Poppins',
    },
    shadow: {
      color: '#000',
      opacity: 0.05,
    },
    shared: true,
    formatter() {
      return `<div class="widget-tooltip">
                <div class="widget-tooltip_title">${this.x}</div>
                ${this.points.reduce((s, point) => `${s}
                <div class="widget-tooltip_line">
                  <div class="widget-tooltip_line_circle" style="background-color: ${point.color}"></div>
                  <div class="widget-tooltip_line_label">${point.series.name}</div>
                  <div class="widget-tooltip_line_info">${point.y}</div>
                </div>`, '')}
              </div>`;
    },
  },
  xAxis: {
    labels: {
      allowOverlap: true,
      autoRotation: [-45],
      style: {
        fontSize: '10px',
        color: '#3a434a',
        fontWeight: '500',
        fontFamily: 'Poppins',
      },
    },
  },
  yAxis: {
    gridLineColor: '#f0f0f0',
    title: {
      text: undefined,
    },
    labels: {
      style: {
        color: '#a3a3a3',
        fontSize: '10px',
        fontFamily: 'Poppins',
      },
    },
  },
};

export default chartSettings;
