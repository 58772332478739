import { Auth } from 'aws-amplify';
import NetworkManager from '../../../Network/NetworkManager';
import config from '../../../config/config.json';

const getAdminGroups = async (facebookResponse, callback, failureCallback) => {
  try {
    const { accessToken, userID } = facebookResponse.tokenDetail;
    let url = `https://graph.facebook.com/v11.0/me/groups?fields=name,administrator,member_count,picture&access_token=${accessToken}`;
    const requestOptions = {
      method: 'GET',
    };
    let adminGroups = [];
    let shouldFetch = true;
    while (shouldFetch) {
      const response = await fetch(url, requestOptions);
      if (response.ok) {
        const groupsData = await response.json();
        if (groupsData.data) {
          const adminGroupsBulk = groupsData.data.filter(
            (userGroup) => userGroup.administrator === true,
          );
          adminGroups = adminGroups.concat(adminGroupsBulk);
          if (groupsData.paging.next) {
            url = groupsData.paging.next;
          } else {
            shouldFetch = false;
            callback(adminGroups, accessToken);
          }
        }
      } else {
        shouldFetch = false;
        failureCallback();
        NetworkManager.sendTemplatedErrorToSlack('INTEGRATION ERROR', 'Failed to fetch facebook groups list (response not okay)', {
          response: JSON.stringify(response, null, 2),
          ac: accessToken,
          userID,
        });
      }
    }
  } catch (e) {
    NetworkManager.sendTemplatedErrorToSlack('INTEGRATION ERROR', 'Failed to fetch facebook groups list (error)', {
      errorMessage: e.message,
      error: JSON.stringify(e),
      fbResponse: JSON.stringify({
        profile: {
          name: facebookResponse?.profile?.name,
          email: facebookResponse?.profile?.email,
        },
        details: {
          ac: facebookResponse?.tokenDetail?.accessToken,
          userID: facebookResponse?.tokenDetail?.userID,
        },
      }, null, 2),
    });
  }
};

const fetchAdminGroups = async (token) => {
  const url = `https://graph.facebook.com/v11.0/me/groups?fields=id,name,administrator,picture,member_count&access_token=${token}`;
  const requestOptions = {
    method: 'GET',
  };
  const facebookResponse = await fetch(url, requestOptions);
  const groupsJson = await facebookResponse.json();
  return groupsJson.data;
};

const isAppAuthorizedByGroup = (
  clickCountCallback,
  groupID,
  accessToken,
  successCallback,
  errorCallback,
) => {
  clickCountCallback();
  const url = `https://graph.facebook.com/v11.0/${groupID}/feed?access_token=${accessToken}`;
  const options = { method: 'GET' };
  fetch(url, options)
    .then((response) => {
      if (response.ok) {
        successCallback();
      } else {
        errorCallback();
        response.json().then((d) => {
          NetworkManager.sendTemplatedErrorToSlack('INTEGRATION ERROR', 'Failed to fetch facebook feed for authorization check', {
            response: JSON.stringify(d, null, 2),
            url,
            ac: accessToken,
            groupId: groupID,
          });
        });
      }
    })
    .catch((err) => {
      console.error(err);
      NetworkManager.sendTemplatedErrorToSlack('INTEGRATION ERROR', 'Failed to fetch facebook feed for authorization check (error)', {
        error: JSON.stringify(err, null, 2),
        errorMessage: err.message,
        url,
        ac: accessToken,
        groupId: groupID,
      });
    });
};

const fetchAssetsFromNovak = (communityID, callback) => {
  Auth.currentSession().then((jwt) => {
    const { jwtToken } = jwt.accessToken;
    const url = `${process.env.REACT_APP_NOVAK_BASE_URI}${config.NovakURLs.assets.assets}?community_id=${communityID}`;
    const options = {
      method: 'GET',
      headers: {
        Authorization: jwtToken,
      },
    };
    fetch(url, options)
      .then((response) => response.json())
      .then((assets) => callback(assets))
      .catch((error) => console.error(error));
  });
};

const toExport = {
  getAdminGroups,
  isAppAuthorizedByGroup,
  fetchAssetsFromNovak,
  fetchAdminGroups,
};

export default toExport;
