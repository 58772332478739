import { AnimatePresence, motion } from 'framer-motion';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ReactComponent as Logo } from '../../../Assets/Images/new-logo.svg';

const AITag = ({ tag, onClick, isSelected }) => (
  <div className={`ai-tag ai-tag_${tag.type} ${isSelected ? 'selected' : ''}`} onClick={() => onClick(tag)}>
    {tag.label}
  </div>
);

export default function ThreadAI({ interaction }) {
  const [selectedTag, setSelectedTag] = useState(null);
  const tags = useMemo(() => Object.keys(interaction.ai).reduce((acc, type) => [
    ...acc,
    ...Object.keys(interaction.ai[type] || {}).map((key) => ({
      label: key,
      type,
      explanation: interaction.ai[type][key].explanation,
      quote: interaction.ai?.[type]?.[key]?.quote,
      subCategory: interaction.ai?.[type]?.[key]?.subCategory,
    })),
  ], []), [interaction]);
  const onSelectTag = useCallback((tag) => {
    if (selectedTag?.label === tag.label) setSelectedTag(null);
    else setSelectedTag(tag);
  }, [selectedTag]);
  useEffect(() => {
    if (tags.length) setSelectedTag(tags[0]);
  }, [tags]);
  if (tags.length === 0) return <></>;
  return (
    <div className="thread-ai">
      <div className="thread-ai_head">
        <div className="thread-ai_avatar">
          <Logo />
        </div>
        <div className="thread-ai_tags">
          {tags.map((t) => (
            <AITag tag={t} key={t.label} isSelected={selectedTag?.label === t.label} onClick={onSelectTag} />
          ))}
        </div>
      </div>
      <AnimatePresence>
        {selectedTag && (
          <motion.div
            className="thread-ai_explainer"
            initial={{ height: 0 }}
            animate={{
              height: 'auto',
            }}
            exit={{ height: 0 }}
          >
            <motion.div initial={{ scale: 0 }} exit={{ scale: 0 }} animate={{ scale: 1 }} className={`thread-ai_explainer_text ${selectedTag?.type}`}>
              {selectedTag.subCategory && (
                <div className="ai-box is-sub">
                  <div className="ai-box_title">Sub Category</div>
                  <div className="ai-box_text">
                    {selectedTag.subCategory}
                  </div>
                </div>
              )}
              <div className="ai-box">
                <div className="ai-box_title">Summary</div>
                <div className="ai-box_text">
                  {selectedTag.explanation || 'No summary available'}
                </div>
              </div>
              {selectedTag.quote && (
                <div className="ai-box is-quote">
                  <div className="ai-box_title">Quote</div>
                  <div className="ai-box_text is-quote">
                    &quot;
                    {selectedTag.quote}
                    &quot;
                  </div>
                </div>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
