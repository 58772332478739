import { motion } from 'framer-motion';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import QueriesInnerWrapper from '../../queries/resultsWrapper/inner';
import editIco from '../../../../Assets/Images/icons/cat-ico-edit.svg';

import { ReactComponent as BackIcon } from '../../../../Assets/Images/icons/back.svg';
import QueryIcon from '../../../../Assets/Images/icons/query-icon';
import { platformColorsByBg } from '../../../../Data/colors';
import CRUDModal from '../crudModal';
import EmailText from '../header/email/text';
import ActionsButton from '../header/actions/button';

export default function QueryNewResultsPhase({ setPhase }) {
  const [createModalShown, setCreateShown] = useState(false);
  const history = useHistory();
  const { values: { metadata } } = useFormikContext();
  const { queryId } = useParams();
  return (
    <div className="queries-view_inner phase-3">
      <CRUDModal isShown={createModalShown} onClose={() => setCreateShown(false)} />
      <motion.div
        className="queries-header template-1"
        layoutId="queries-header"
      >
        <motion.div onClick={() => !metadata?.isEdited ? history.goBack() : setPhase(2)} className="queries-header_back" layoutId="queries-header_icon">
          <BackIcon />
        </motion.div>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="queries-header_queryicon"
          style={{ background: metadata?.color }}
        >
          <QueryIcon color={platformColorsByBg?.[metadata?.color]?.textColor || 'white'} />
        </motion.div>
        <motion.div className="queries-header_text" layout="position" layoutId="queries-header_text">

          <div>{metadata?.name || 'Unsaved query'}</div>
          {metadata.name && metadata.isEdited && <span className="unsaved">(Unsaved)</span>}
        </motion.div>
        <div className="queries-header_actions">
          <EmailText />
          <div className="hover-link-box" onClick={() => queryId ? history.push(`/dashboard/queries/${queryId}/edit`) : setPhase(2)}>
            <img src={editIco} width="25" height="25" alt="" />
            Edit
          </div>
          {queryId && <ActionsButton />}
          {metadata.isEdited && <Button className="lnc-blue-btn" onClick={() => setCreateShown(true)}>Save Query</Button>}
        </div>
      </motion.div>
      <QueriesInnerWrapper />
    </div>
  );
}
