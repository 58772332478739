import { useContext, useMemo } from 'react';
import { DashboardsContext } from '../../../Components/Boards/hooks/useDashboards';
import Edittable from '../../../Components/Edittable';

export default function WidgetDashboardHeading({ actionsRef, isEditHighlighted }) {
  const { data, selectedBoard, updateMutation } = useContext(DashboardsContext);
  const thisBoard = useMemo(() => {
    if (data && Array.isArray(data) && selectedBoard) {
      return data.find((b) => b.id.toString() === selectedBoard?.toString());
    }
    return null;
  }, [data, selectedBoard]);
  return (
    <div className="dashboards-header">
      <div className="dashboards-header_title">
        <Edittable
          isHighlighted={isEditHighlighted}
          fieldKey="name"
          mutation={updateMutation}
          mutationAdditions={{ id: thisBoard?.id }}
          value={thisBoard?.name || ''}
          onChange={null}
        />
        {/* <span>{thisBoard?.name}</span> */}
      </div>
      <div className="dashboards-header_bottom">
        <div className="dashboards-header_bottom_desc">
          <Edittable
            isHighlighted={isEditHighlighted}
            fieldKey="desc"
            mutation={updateMutation}
            mutationAdditions={{ id: thisBoard?.id }}
            value={thisBoard?.desc || 'This is my consolidated view of the most recent updates (short description)'}
            onChange={null}
          />
        </div>
        <div ref={actionsRef} />
      </div>
    </div>
  );
}
