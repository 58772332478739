import { Select } from 'antd';
import { toJS } from 'mobx';
import { useMemo } from 'react';
import { observer } from 'mobx-react';
import dalInstance from '../../../../../Data/Dal';
import segmentChange from '../../../../../Assets/Images/icons/segments-change.png';
import { ReactComponent as Chev } from '../../../../../Assets/Images/icons/menuchevron-chevron-down.svg';
import { ReactComponent as CloseIcon } from '../../../../../Assets/Images/icons/dashboards/cat-ico_x-close.svg';

function ActivitiesFilter({ filters, setFilters }) {
  const integrations = useMemo(() => toJS(dalInstance.integrations).filter((i) => i.connected).map((int) => ({
    ...int,
    isFiltered: !!filters.find((f) => f === (int.assetTag === 'csv' ? 's3file' : int.assetTag)),
  })), [filters]);
  const displayValue = useMemo(() => {
    if (filters.length === 0) {
      return (
        (
          <div className="filter-button">
            <Chev />
            <span>ALL PLATFORMS</span>
          </div>
        )
      );
    }
    return (
      <div className="filter-button active">
        <Chev />
        <span>
          {filters.length}
          {' '}
          SELECTED
        </span>
      </div>
    );
  }, [filters]);
  const handleFilter = (tag) => {
    if (tag === 'CLEAR') return setFilters([]);
    const t = tag === 'csv' ? 's3file' : tag;
    const found = filters.find((f) => f === t);
    if (found) setFilters(filters.filter((f) => f !== t));
    else setFilters([...filters, t]);
  };
  return (
    <div className="activities-filter">
      <Select
        dropdownMatchSelectWidth={false}
        dropdownClassName="activities-filter_dropdown"
        onChange={handleFilter}
        value={displayValue}
      >
        {filters.length > 0 && (
          <Select.Option className="superfilter-option" value="CLEAR">
            <CloseIcon />
            <span>clear</span>
          </Select.Option>
        )}
        {integrations.map((int) => (
          <Select.Option className={`superfilter-option ${int.isFiltered ? ' is-selected' : ''}`} key={int.assetTag} value={int.assetTag === 'csv' ? 's3file' : int.assetTag}>
            <img src={int.image} alt="" />
            <span>{int.title}</span>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default observer(ActivitiesFilter);
