import { motion } from 'framer-motion';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import PlaybooksHeader from '../common/header';

export default function PlaybooksHomeHeader() {
  const history = useHistory();
  return (
    <PlaybooksHeader>
      <motion.div className="playbooks-header_icon" layoutId="playbooks-header_icon">
        <div className="playbooks-header_icon_inner" />
      </motion.div>
      <motion.div className="playbooks-header_text" layout="position" layoutId="playbooks-header_text">
        <div>Create new playbook</div>
        <div>Transform any interaction that matches a query into a meaningful customer experience.</div>
      </motion.div>
      <div className="playbooks-header_buttons">
        <Button className="lnc-blue-btn" onClick={() => history.push('/dashboard/playbooks/new')}>Create new</Button>
      </div>
    </PlaybooksHeader>
  );
}
