import { useMutation, useQueryClient } from 'react-query';
import { useCommunityContext } from '../../../Utils/contexts/community';
import NetworkManager from '../../../Network/NetworkManager';

export default function useQueriesMutations() {
  const { communityId } = useCommunityContext();
  const client = useQueryClient();
  const refetchQueryOnSuccess = {
    onSuccess: () => {
      client.invalidateQueries([communityId, 'queries'], { exact: true });
    },
  };
  const create = useMutation((d) => NetworkManager.createNewQuery(communityId, d), refetchQueryOnSuccess);
  const update = useMutation((d) => NetworkManager.updateQuery(communityId, d.id, d), refetchQueryOnSuccess);
  const deleteQuery = useMutation((d) => NetworkManager.deleteQuery(communityId, d.id), refetchQueryOnSuccess);
  return {
    create,
    update,
    deleteQuery,
  };
}
