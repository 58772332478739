import { useMutation, useQueryClient } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';
import { useCommunityContext } from '../../../Utils/contexts/community';

export default function useQueryEmailsMutations(queryId) {
  const queryClient = useQueryClient();
  const { communityId } = useCommunityContext();
  const update = useMutation((d) => NetworkManager.updateQueryEmails(communityId, queryId, d), {
    onSuccess: () => {
      queryClient.refetchQueries(['queries', queryId, 'emails']);
    },
  });
  return {
    update,
  };
}
