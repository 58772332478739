import { observer } from 'mobx-react';
import { Tooltip } from 'antd';
import { useMemo } from 'react';
import dalInstance from '../../../../../../Data/Dal';

function PlatformDetails({ sources, platformLinks, isLoading }) {
  const list = useMemo(() => {
    if (sources && Array.isArray(sources)) {
      const bySources = sources.reduce((acc, x) => {
        const links = platformLinks?.[x.platform_name + x.platform_type];
        if (links) {
          links.forEach((lnk) => {
            acc.push(({
              service: x.platform_name + x.platform_type,
              image: dalInstance.getIntegrationByAssetTag(x.platform_name + x.platform_type).image,
              linkDetails: lnk,
            }));
          });
        } else {
          acc.push(({
            service: x.platform_name + x.platform_type,
            image: dalInstance.getIntegrationByAssetTag(x.platform_name + x.platform_type).image,
            linkDetails: null,
          }));
        }
        return acc;
      }, []);
      return bySources;
    }
    return [];
  }, [sources, platformLinks]);

  return (
    <div className="collapse-userdetails_sources">
      {isLoading ? (
        Array(3).fill(null).map((_, ind) => (
          <div
            key={ind}
            className="isSkeleton"
            style={{
              width: '24px', marginRight: '15px', height: '24px', borderRadius: '50%',
            }}
          />
        ))
      ) : (
        list.map((src) => (
          <div
            className="collapse-userdetails_sources_img"
          >
            {src.linkDetails ? (
              <a
                key={src.linkDetails.link}
                href={src.linkDetails.link}
                target="_blank"
                rel="noreferrer"
              >
                <Tooltip
                  color="white"
                  title={(
                    <span style={{ color: '#3d3d3d' }}>
                      <div style={{ fontWeight: '500' }}>{src.linkDetails?.name}</div>
                      {src.linkDetails?.email ? ` (${src.linkDetails.email})` : ''}
                    </span>
                  )}
                >
                  <img
                    key={src.service}
                    src={src.image}
                    alt={src.service}
                    width="24"
                    height="24"
                  />
                </Tooltip>
              </a>
            ) : (
              <img
                key={src.service}
                src={src.image}
                alt={src.service}
                width="24"
                height="24"
              />
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default observer(PlatformDetails);
