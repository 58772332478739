import * as Yup from 'yup';
import { Input } from 'antd';
import {
  useContext, useState, useCallback, useEffect,
} from 'react';
import { useFormikContext } from 'formik';
import OnboardingContext from '../../context/onboardingContext';

import selectedIcon from '../../../../Assets/Images/icons/checkmark-circ-selected.svg';
import deselectedIcon from '../../../../Assets/Images/icons/checkmark-circ-deselected.svg';

const goalsDefault = [
  {
    id: 1,
    label: 'Explore growth opportunities',
  },
  {
    id: 2,
    label: 'Increase conversions',
  },
  {
    id: 3,
    label: 'Reduce churn',
  },
  {
    id: 4,
    label: 'Increase engagement',
  },
  {
    id: 5,
    label: 'Save time & reduce manual work',
  },
  {
    id: 6,
    label: 'Other',
  },
];

const fieldsSchema = [
  {
    label: 'Name your workspace *',
    defaultValue: '',
    placeholder: '',
    key: 'workspaceName',
    Template: ({ field, schema }) => {
      const [globalContext, updateContext] = useContext(OnboardingContext);
      return (
        <Input
          {...field}
          onChange={(e) => updateContext('companyDetails', { companyName: e.target.value })}
          value={globalContext.companyDetails?.companyName}
        />
      );
    },
  },
  {
    label: 'Share your goals',
    defaultValue: '',
    placeholder: '',
    key: 'goals',
    Template: ({ field, schema }) => {
      const [selectedGoals, setSelectedGoals] = useState([]);
      const [other, setOther] = useState(false);
      const { setFieldValue } = useFormikContext();
      const clickGoalHandler = useCallback(
        (id) => {
          if (selectedGoals.find((g) => g.id === id)) {
            setSelectedGoals(selectedGoals.filter((g) => g.id !== id));
            if (id === 6) {
              setOther(false);
            }
          } else {
            setSelectedGoals([
              ...selectedGoals,
              goalsDefault.find((g) => g.id === id),
            ]);
            if (id === 6) {
              // other
              setOther('');
            }
          }
        },
        [selectedGoals],
      );

      const isSelected = useCallback(
        (id) => !!selectedGoals.find((g) => g.id === id),
        [selectedGoals],
      );

      useEffect(() => {
        const finalStr = selectedGoals
          .map((g) => {
            if (g.id === 6) return `Other: ${other}`;
            return g?.label;
          })
          .join(',');
        setFieldValue('goals', finalStr);
      }, [selectedGoals, other, setFieldValue]);
      return (
        <div>
          <div style={{ backgroundColor: '#0084ff', height: '2px' }} />
          <div className="goals">
            {goalsDefault.map((goal) => (
              <div
                key={goal.id}
                tabIndex="0"
                role="button"
                onClick={() => clickGoalHandler(goal.id)}
                className={`goals-item ${isSelected(goal.id) && 'selected'}`}
              >
                <div className="checkmark">
                  <img
                    src={isSelected(goal.id) ? selectedIcon : deselectedIcon}
                    alt="Select goal"
                    width="24"
                    height="24"
                  />
                </div>
                <div className="label">{goal.label}</div>
              </div>
            ))}
            {other !== false && (
              <div style={{ marginTop: '10px' }}>
                <Input
                  value={other}
                  onChange={(e) => setOther(e.target.value)}
                  placeholder="Describe your goals"
                />
              </div>
            )}
          </div>
        </div>
      );
    },
  },
];

const validationSchema = Yup.object().shape({
  // workspaceName: Yup.string().required('Required'),
});

const workspaceDetailsSchemas = {
  fieldsSchema,
  validationSchema,
};
export default workspaceDetailsSchemas;
