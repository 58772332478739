import { useFormikContext } from 'formik';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { useMemo } from 'react';
import GeneralUserMultiIcon from '../../../../../../Assets/Images/redesign/generalMultiUserIcon';
import Dal from '../../../../../../Data/Dal';
import Select from '../../../../common/select';

const { Option } = Select;

const segmentCommonTemplate = {
  validationSchema: Yup.object({
    segment_id: Yup.number().required('Required'),
  }),
  Display: observer(({ isExample, metadata }) => {
    const segment = useMemo(() => {
      if (isExample) return '';
      const found = Dal.getSegmentById(metadata.segment_id);
      if (found) return found.name;
      return 'ERROR';
    }, [metadata, isExample]);
    return (
      <span className="highlight">
        {isExample ? (
          'a Segment'
        ) : (
          segment
        )}
      </span>
    );
  }),
  Edit: observer(() => {
    const { values, setFieldValue, touched } = useFormikContext();
    const { trigger: { metadata } } = values;
    return (
      <span>
        <Select
          field="trigger.metadata.segment_id"
          isTouched={touched?.trigger?.metadata?.segment_id}
          filterOption={(input, option) => String(option.key).toLowerCase().includes(input.toLowerCase())}
          value={metadata?.segment_id ?? null}
          placeholder="Select"
          onChange={(e) => setFieldValue('trigger.metadata.segment_id', e)}
        >
          {toJS(Dal.segments.segments).map((seg) => (
            <Option key={`${seg.id}-${seg.name}`} value={seg.id}>
              <GeneralUserMultiIcon fill={seg.color} />
              {seg.name}
            </Option>
          ))}
        </Select>
      </span>
    );
  }),
};

export default segmentCommonTemplate;
