import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import Dal from '../../../Data/Dal';
import GeneralUserMultiIcon from '../../../Assets/Images/redesign/generalMultiUserIcon';
import { ReactComponent as Checkmark } from '../../../Assets/Images/icons/list-item-checkmark-fill.svg';
import gotoIcon from '../../../Assets/Images/icons/ui-icon-expand-expand-active.svg';

const numberFormatterK = new Intl.NumberFormat('en', { notation: 'compact' });
const numberFormatter = new Intl.NumberFormat('en');

const BOX_SPACE = 70;

function SegmentsWidget() {
  const [hoverPos, setHoverPos] = useState({
    enabled: false,
    pos: 0,
  });
  const handleMouseEnter = useCallback((ind) => {
    setHoverPos((cur) => ({ ...cur, enabled: true, pos: BOX_SPACE * ind }));
  }, []);
  const handleMouseLeave = useCallback(() => {
    setHoverPos((cur) => ({ ...cur, enabled: false }));
  }, []);
  const segments = useMemo(() => toJS(Dal.segments.segments)
    .sort((a, b) => b.count - a.count)
    .slice(0, 3)
    .map((s) => ({ ...s, darkerColor: Dal.setsColors.find((c) => c.bgColor === s.color).textColor })), [Dal.segments.segments]); // eslint-disable-line
  return (
    <div className="wsegments">
      <div className="section-title">Top active segments</div>
      <div className="wsegments-box">
        <motion.div transition={{ type: 'tween', duration: 0.3, ease: 'easeOut' }} animate={{ y: `${hoverPos.pos + 10}px`, opacity: hoverPos.enabled ? 1 : 0 }} className="wsegments-box_hover" />
        <AnimatePresence>
          {segments.map((sg, ind) => (
            <Item key={sg.id} ind={ind} handleMouseLeave={handleMouseLeave} handleMouseEnter={handleMouseEnter} {...sg} />
          ))}
        </AnimatePresence>
      </div>
      <Link to="/dashboard/crm" className="goto-link">
        <span>My segments</span>
        <img src={gotoIcon} width="20" height="20" alt="Go to" />
      </Link>
    </div>
  );
}

const Item = ({
  name, count, color, darkerColor, hasUpdate, unreadCount, ind, handleMouseEnter, handleMouseLeave,
}) => {
  const allRead = Number(unreadCount) === 0;
  return (
    <motion.div
      initial={{ opacity: 0, y: '50px' }}
      animate={{ opacity: 1, y: '0px', transition: { delay: 0.2 + ind / 10 } }}
      onMouseEnter={() => handleMouseEnter(ind)}
      onMouseLeave={handleMouseLeave}
      className={`wsegments-box_item ${allRead ? 'all-read' : ''}`}
    >
      <div className="wsegments-box_item_inner">
        <div className="wsegments-box_item_inner_icon">
          <GeneralUserMultiIcon fill={color} />
        </div>
        <div className="wsegments-box_item_inner_desc">
          <div>{name}</div>
          <div>{numberFormatterK.format(count)}</div>
        </div>
        <div className="wsegments-box_item_inner_num" style={{ color: darkerColor, fill: darkerColor }}>
          {hasUpdate !== 0 && <div className="has-update" />}
          {allRead ? (
            <Checkmark />
          ) : (numberFormatter.format(parseInt(unreadCount, 10)))}
        </div>
      </div>
    </motion.div>
  );
};

export default observer(SegmentsWidget);
