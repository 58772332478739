import { AnimatePresence, motion } from 'framer-motion';
import {
  useCallback, useContext, useMemo, useState, forwardRef,
} from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
// import { ReactComponent as EditIcon } from '../../Assets/Images/icons/cat-ico-edit (1).svg';
import { ReactComponent as TrashIcon } from '../../Assets/Images/icons/dashboards/trash.svg';
import { ReactComponent as EditIcon } from '../../Assets/Images/icons/dashboards/edit-widget.svg';

import DropdownMenu from '../DropdownMenu';
import { FoldersContext } from './dnd/useFolders';

const MoreMenu = ({ handleEditClick, handleDeleteClick, onVisibleChange }) => {
  const menu = [
    {
      key: 'edit',
      label: 'Edit',
      icon: <EditIcon />,
      onClick: handleEditClick,
    },
    {
      key: 'div1',
      divider: true,
    },
    {
      key: 'Remove',
      label: 'Remove',
      danger: true,
      icon: <TrashIcon />,
      onClick: handleDeleteClick,
    },
  ];
  return (
    <DropdownMenu
      onVisibleChange={onVisibleChange}
      button={(
        <div className="entity-item_actions_action dots">
          <HiDotsHorizontal />
        </div>
      )}
      items={menu}
    />
  );
};

const EntityItem = forwardRef((props, ref) => {
  const {
    icon, title, className, onClick, desc, id, info, onEditClick, onDelete, bottomContent,
  } = props;
  const [isHover, setHover] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const { selected } = useContext(FoldersContext);
  const isSelected = useMemo(() => selected ? selected.toString() === id.toString() : false, [selected, id]);
  const handleEditClick = useCallback((e) => {
    e.domEvent.stopPropagation();
    setMenuActive(false);
    onEditClick();
  }, [onEditClick]);

  const handleDeleteClick = useCallback(async (e) => {
    e.domEvent.stopPropagation();
    setMenuActive(false);
    onDelete(id);
  }, [onDelete, id]);

  return (
    <div
      className={`entity-item ${isSelected ? 'selected ' : ''}${className || ''}${menuActive ? 'active' : ''}${bottomContent ? 'with-bottom' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <div className="entity-item_icon">
        {icon}
      </div>
      <div className="entity-item_main">
        <div className="entity-item_main_title">{title}</div>
        <div className="entity-item_main_desc">{desc}</div>
      </div>
      {info && (
        <div className="entity-item_info">
          {info}
        </div>
      )}
      <AnimatePresence>
        {(isHover || menuActive) && (
          <motion.div
            className="entity-item_actions"
            transition={{ type: 'tween', duration: 0.17 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={(e) => e.stopPropagation()}
          >
            <MoreMenu handleEditClick={handleEditClick} onVisibleChange={setMenuActive} handleDeleteClick={handleDeleteClick} />
            {/* <div onClick={handleEditClick} className="entity-item_actions_action">
              <EditIcon />
            </div>
            <div onClick={handleDeleteClick} className="entity-item_actions_action trash">
              <TrashIcon />
            </div> */}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isSelected && bottomContent && (
          <motion.div
            className="entity-item_bottom"
            transition={{ type: 'tween', duration: 0.17 }}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            onClick={(e) => e.stopPropagation()}
          >
            hello
            <div>
              world
            </div>
            <div>
              hey
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});
export default EntityItem;
