/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { platformColorsByBg } from '../../../Data/colors';
import useDictionaryQueries from '../../../Utils/community/dictionaries/useDictionaryQueries';
import { useCommunityContext } from '../../../Utils/contexts/community';
import StylizedTable from '../../LNCTable/stylizedTable';
import BottomBoxLoader from '../../Loaders/bottomBoxLoader';
import SkeletonLine from '../../Skeletons/line';
import CreatedByColumn from './columns/createdBy';
import SourceColumn from './columns/source';
import TextColumn from './columns/text';
import ToneColumn from './columns/tone';
import EmptyFilterResultState from '../emptyStates/emptyFilter';
import EmptyDictResultState from '../emptyStates/emptyDict';
import SetsThreadView from '../../ThreadView/index';
import kFormatter from '../../../Utils/funcs/kFormatter';

const withSkeleton = (fn) => (info) => {
  const isLoading = info?.cell?.row?.original?.isLoading;
  if (isLoading) return <SkeletonLine style={{ height: '15px' }} />;
  return fn(info);
};

export default function DictionaryTable({ dictId, filter }) {
  const [thread, setThread] = useState(null);
  const { matches, threadQuery } = useDictionaryQueries(dictId, filter, thread);
  const { dictionaries } = useCommunityContext();
  const colors = useMemo(() => platformColorsByBg[dictionaries[dictId].color], [dictId, dictionaries]);
  const dictKeywordsById = useMemo(() => dictionaries[dictId].keywords.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {}), [dictId, dictionaries]);
  const isNewDict = useMemo(() => Math.abs(moment.utc().diff(moment.utc(dictionaries[dictId].created_time), 'minutes')) < 5, [dictionaries, dictId]);
  const isRecentlyUpdated = useMemo(() => dictionaries[dictId]?.last_updated && Math.abs(moment.utc().diff(moment.utc(dictionaries[dictId]?.last_updated), 'minutes')) < 2, [dictionaries, dictId]);
  const skeletons = useMemo(() => Array(12).fill(null).map((_, ind) => ({
    id: `loading-${ind}`,
    isLoading: true,
    origin_time: '',
    asset_id: '',
    emotion: null,
    origin_data: {
      message: '',
      from: {
        name: '',
      },
    },
  })), []);
  const columns = useMemo(() => [
    {
      accessorKey: 'origin_time',
      header: 'Date',
      id: 'date',
      size: 130,
      cell: withSkeleton((info) => moment(info.getValue()).format('MMM DD, YYYY')),
    },
    {
      accessorFn: (row) => row.user_name || 'Member',
      id: 'createdby',
      header: 'Created By',
      size: 230,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: withSkeleton((info) => <CreatedByColumn value={info.getValue()} />),
    },
    {
      accessorKey: 'asset_id',
      id: 'source',
      header: 'Source',
      size: 200,
      cell: withSkeleton((info) => <SourceColumn value={info.getValue()} />),
    },
    {
      accessorFn: (row) => row.text,
      id: 'origin_message',
      header: 'Original Content',
      size: 550,
      cell: withSkeleton((info) => {
        const val = info.getValue();
        return (
          <TextColumn
            value={val}
            isUpsell={info.row.original.is_upsell_opportunity ? info.row.original.categories_upsell_opportunity : false}
            isChurn={info.row.original.is_churn_risk ? info.row.original.categories_churn_risk : false}
            dictId={dictId}
            colors={colors}
            dictKeywordsById={dictKeywordsById}
            markedBy={info.row.original?.marked_by_keywords || []}
          />
        );
      }),
    },
    {
      accessorFn: (row) => row?.sentiment || 'NEUTRAL',
      id: 'tone',
      header: 'Tone',
      size: 50,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: withSkeleton((info) => <ToneColumn value={info.getValue()} />),
    },
  ], [dictKeywordsById, dictId, colors]);
  const currentState = useMemo(() => {
    if (!matches.isLoading && !isNewDict && matches.data?.pages?.[0]?.total === 0) {
      if (filter) {
        return 'empty-filter';
      }
      return 'empty-dict';
    }
    return 'table';
  }, [matches, isNewDict, filter]);
  const currentComp = useMemo(() => {
    if (currentState !== 'table') {
      if (currentState === 'empty-filter') {
        return <EmptyFilterResultState />;
      }
      return <EmptyDictResultState />;
    }
    return (
      <StylizedTable
        shouldShowSkeletonOnEmptyResult={isNewDict}
        columns={columns}
        skeletons={skeletons}
        infiniteQuery={matches}
        setThread={setThread}
      />
    );
  }, [currentState, columns, skeletons, isNewDict, matches]);
  const bottomLoader = useMemo(() => {
    if (!matches.isLoading && isNewDict && matches.data?.pages?.[0]?.total === 0) {
      return {
        notify: true,
        cssOverrides: { position: 'absolute', left: '50%', top: '100px' },
      };
    }
    if (isRecentlyUpdated) {
      return {
        title: 'Update in progress',
        text: 'We will periodically refetch new results in the background',
        cssOverrides: {
          position: 'absolute', top: 'auto', left: '50%', bottom: '-100px',
        },
      };
    }
    return null;
  }, [matches, isNewDict, isRecentlyUpdated]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isRecentlyUpdated) {
      // Periodically fetching the matches
      const inter = setInterval(() => matches.refetch(), 10000);
      return () => clearInterval(inter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecentlyUpdated]);
  return (
    <>
      {currentState === 'table' && (
        <div className="dictionary-metadata">
          {kFormatter(matches.data?.pages?.[0]?.total || 0)}
          {' '}
          matches
        </div>
      )}
      <SetsThreadView isVisible={!!thread} selectedMatch={thread} threadQuery={threadQuery} setThread={setThread} />
      <div className="dictionary-table-wrapper">
        {currentComp}
        {bottomLoader && (
          <BottomBoxLoader isShown {...bottomLoader} />
        )}
      </div>

    </>
  );
}
