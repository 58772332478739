const churnCategories = [
  {
    key: 'churn.Severe Frustration with the Product',
    label: 'Severe Frustration with the Product',
  },
  { key: 'churn.Poor Customer Service', label: 'Poor Customer Service' },
  { key: 'churn.Competitive Threats', label: 'Competitive Threats' },
  {
    key: 'churn.Statements of moving away from the product',
    label: 'Statements of moving away from the product',
  },
  { key: 'churn.Rage', label: 'Rage' },
];
const upsellCategories = [
  {
    key: 'upsell.Requests for a trial or free version',
    label: 'Requests for a trial or free version',
  },
  { key: 'upsell.Statements of willingness to buy', label: 'Statements of willingness to buy' },
  { key: 'upsell.Inquiries about the pricing', label: 'Inquiries about the pricing' },
  {
    key: 'upsell.Requests to increase the number of seats or licenses in the product',
    label: 'Requests to increase the number of seats or licenses in the product',
  },
  {
    key: 'upsell.Willingness to Upgrade or Expand the Existing Plan',
    label: 'Willingness to Upgrade or Expand the Existing Plan',
  },
];

const productFeedbackCategories = [
  {
    key: 'product_feedback.Customer Confusion',
    label: 'Customer Confusion',
  },
  {
    key: 'product_feedback.Product Frustration',
    label: 'Product Frustration',
  },
  {
    key: 'product_feedback.Feature Approval',
    label: 'Feature Approval',
  },
];

const companySpecificCategories = [
  {
    key: 'company_specific.Data Coverage',
    label: 'Data Coverage',
  },
  {
    key: 'company_specific.Data Accuracy',
    label: 'Data Accuracy',
  },
];

export const categorySelectOptions = [
  ...churnCategories.map((cat) => ({ ...cat, group: 'Churn' })),
  ...upsellCategories.map((cat) => ({ ...cat, group: 'Upsell' })),
  ...productFeedbackCategories.map((cat) => ({ ...cat, group: 'Product Feedback' })),
  ...companySpecificCategories.map((cat) => ({ ...cat, group: 'Company Specific' })),
];
