import Dal from '../../Data/Dal';

/**
 *
 * @param {function} callback - callback function incase user IS PREMIUM
 * @returns {fuction} !!!!!IMPROTANT!!!! This function returns a function! because "premiumMiddlewareCheck" is used mainly by onClick events
 */
const premiumMiddlewareCheck = (callback) => (...props) => {
  if (Dal.isCommunityPremium()) return callback(...props);
  window.__premiumModal.show();
};

export default premiumMiddlewareCheck;
