import { useEffect, useMemo, useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import LNCSelect from '../../../../Select/LNCSelect';
import { useCommunityContext } from '../../../../../Utils/contexts/community';
import defaultIntegrations from '../../../../../Data/integrations';
import { categorySelectOptions } from './_categoryOptions';
import useTopicsQueries from '../../../../../Utils/community/topics/useTopicsQueries';

export const _defValueByType = {
  'string[]': [],
};

const RenderDatePicker = ({ onSelect, operator, value = '' }) => {
  const isRangePicker = operator === 'within';
  const DateComponent = isRangePicker ? DatePicker.RangePicker : DatePicker;
  let dateTimeValue = value;
  if (Array.isArray(value)) {
    dateTimeValue = value.map((date) => moment(date));
  } else if (value !== '') {
    dateTimeValue = moment(value);
  }
  return (
    <DateComponent
      disabled={!operator}
      className="lnc-input"
      defaultValue={moment()}
      value={dateTimeValue}
      disabledDate={(date) => moment(date).isAfter(moment())}
      popupClassName="datepicker-picker"
      onChange={(_, dateString) => onSelect(dateString, { key: dateString })}
    />
  );
};

const _values = {
  /*
  [FIELD]: {
    [OPERATOR]: { }
  }

  FALLBACKS: [FIELD] -> _default_[FIELD_TYPE] -> _default
  FALLBACKS: [OPERATOR] -> _any
  */
  _default: {
    _any: {
      Template: ({ onSelect, value: { value = '', operator } }) => {
        const [inputValue, setInputValue] = useState(value);
        useEffect(() => {
          setInputValue(value);
        }, [value]);
        return (
          <input
            className={`lnc-input ${operator ? '' : 'disabled'}`}
            placeholder="Select"
            value={inputValue}
            onBlur={() => onSelect(inputValue)}
            onChange={(e) => setInputValue(e.target.value)}
          />
        );
      },
    },
  },
  _default_date: {
    _any: {
      Template: ({ onSelect, value: { value, operator } }) => (
        <RenderDatePicker value={value} onSelect={onSelect} operator={operator} />
      ),
    },
  },
  service_name: {
    is: {
      Template: ({ onSelect, value: { value, operator } }) => {
        const [fixedValue, setFixedValue] = useState({ key: '' });
        const { assets } = useCommunityContext();
        const platforms = useMemo(
          () => Object.values(assets)
            .reduce((acc, cur) => {
              if (acc.includes(cur.integration_id)) return acc;
              return [...acc, cur.integration_id];
            }, [])
            .map((intId) => {
              const int = defaultIntegrations.find((i) => i.id === intId);
              return {
                key: int.assetTag,
                label: int.title,
                icon: <img src={int.image} width="22" height="22" alt="" />,
                assetName: int.targetTag,
              };
            }),
          [assets],
        );

        // hack to match valuse as in db to the option value
        // e,g in db: zendesk , in option key: zendeskaccount
        // we cannot change it to zendesk cuz we might have other options with the same key
        // we also trying to compare the value to the platform key, to deal with the new code (as it should be)
        useEffect(() => {
          setFixedValue(platforms.find((platform) => platform.assetName === value || platform.key === value));
        }, [value, platforms]);

        return (
          <LNCSelect
            value={fixedValue?.key ?? ''}
            onSelect={onSelect}
            placeholder="Select a platform"
            isSearch
            searchPlaceholder="Find a platform"
            isDisabled={!operator}
            items={platforms}
            valueKey="assetName"
          />
        );
      },
    },
  },
  source: {
    is: {
      Template: ({ onSelect, value: { value, operator } }) => {
        const { assets } = useCommunityContext();
        const sources = useMemo(
          () => Object.values(assets)
            .sort((a, b) => a.integration_id - b.integration_id)
            .map((asset) => {
              const int = defaultIntegrations.find((i) => i.id === asset.integration_id);
              return {
                key: asset.id,
                label: asset.name,
                icon: <img src={int.image} width="22" height="22" alt="" />,
              };
            }),
          [assets],
        );
        return (
          <LNCSelect
            value={value}
            onSelect={onSelect}
            placeholder="Select a source"
            isSearch
            searchPlaceholder="Find a source"
            isDisabled={!operator}
            items={sources}
          />
        );
      },
    },
  },
  sentiment: {
    in: {
      Template: ({ onSelect, value: { value, operator } }) => {
        const sentiments = useMemo(
          () => [
            { key: 'Negative', label: 'Negative' },
            { key: 'Neutral', label: 'Neutral' },
            { key: 'Positive', label: 'Positive' },
          ],
          [],
        );
        return (
          <LNCSelect
            value={value}
            onSelect={onSelect}
            placeholder="Select a sentiment"
            isDisabled={!operator}
            items={sentiments}
            isMulti
          />
        );
      },
    },
  },
  keywords: {
    in: {
      Template: ({ onSelect, value: { value, operator } }) => (
        <LNCSelect
          value={value}
          onSelect={onSelect}
          placeholder="Type a keyword"
          isDisabled={!operator}
          items={[]}
          isMulti="variable"
        />
      ),
    },
  },
  topic: {
    in: {
      Template: ({ onSelect, value: { value = [], operator } }) => {
        const { topics: { data: topics = [] } } = useTopicsQueries();
        const options = useMemo(() => topics.map((t) => ({ key: t.topic, label: t.topic })), [topics]);
        return (
          <LNCSelect
            value={value}
            onSelect={onSelect}
            placeholder="Select topics"
            isDisabled={!operator}
            items={options}
            isSearch
            valueKey="key"
            isMulti
          />
        );
      },
    },
  },
  category: {
    in: {
      Template: ({ onSelect, value: { value, operator } }) => {
        const { communityId } = useCommunityContext();
        const categories = useMemo(() => {
          if (communityId !== 4897) {
            return categorySelectOptions.filter((f) => f.group !== 'Company Specific');
          }
          return categorySelectOptions;
        }, [communityId]);
        return (
          <LNCSelect
            value={value}
            onSelect={onSelect}
            placeholder="Select categories"
            isDisabled={!operator}
            items={categories}
            valueKey="key"
            isSearch
            isMulti
          />
        );
      },
    },
  },
};

export default _values;
