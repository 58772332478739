import { Button } from 'antd';
import { useCallback, useState } from 'react';
import FilterLine from './line';
import reset from '../../../Assets/Images/icons/super-reset.svg';

const uid = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

export default function DictionarySuperFilter({ onClose, setSuperFilter }) {
  const [lines, setLines] = useState([
    {
      key: 'base',
      property: 'name',
      condition: 'exact match',
      value: '',
    },
  ]);
  const [operator, setOperator] = useState('or');
  // const [errors, setErrors] = useState({});
  const updateLineByKey = useCallback((lineKey, key, value) => {
    setLines((cur) => {
      const curr = JSON.parse(JSON.stringify(cur));
      const line = curr.find((l) => l.key === lineKey);
      line[key] = value;
      if (line.error && key !== 'error') delete line.error;
      return curr;
    });
  }, []);
  const removeLineByKey = useCallback((lineKey) => {
    setLines((cur) => {
      const curr = JSON.parse(JSON.stringify(cur));
      return curr.filter((l) => l.key !== lineKey);
    });
  }, []);
  const addNewLine = useCallback(() => {
    setLines((cur) => [
      ...cur,
      {
        key: uid(),
        property: 'name',
        condition: 'exact match',
        value: '',
      },
    ]);
  }, []);
  const apply = useCallback(() => {
    const payload = {
      operator: lines.length === 1 ? 'and' : operator,
      filters: lines,
    };
    const errors = payload.filters.reduce((acc, line) => {
      if (!line.value) {
        updateLineByKey(line.key, 'error', 'Value is required');
        return {
          ...acc,
          [line.key]: 'Value is required',
        };
      }
      return acc;
    }, {});
    const hasError = Object.keys(errors).length !== 0;
    if (!hasError) {
      setSuperFilter(payload);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator, lines]);
  const handleReset = useCallback(() => {
    setLines([
      {
        key: 'base',
        property: 'name',
        condition: 'exact match',
        value: '',
      },
    ]);
    setSuperFilter(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dict-filter">
      <div className="dict-filter_line">
        <div className="dict-filter_line_picker">Property</div>
        <div className="dict-filter_line_condition">Condition</div>
        <div className="dict-filter_line_value">Value</div>
      </div>
      {lines.slice(0, 1).map((line) => (
        <FilterLine
          update={(k, v) => updateLineByKey(line.key, k, v)}
          key={line.key}
          lineKey={line.key}
          property={line.property}
          condition={line.condition}
          value={line.value}
          error={line.error}
          remove={() => removeLineByKey(line.key)}
        />
      ))}
      {lines.length > 1 && (
        <div
          className="super-andor"
        >
          <div className={`super-andor_and ${operator === 'and' ? 'selected' : ''}`} onClick={() => setOperator('and')}>And</div>
          <div className={`super-andor_or ${operator === 'or' ? 'selected' : ''}`} onClick={() => setOperator('or')}>Or</div>
        </div>
      )}
      {lines.slice(1).map((line) => (
        <FilterLine
          update={(k, v) => updateLineByKey(line.key, k, v)}
          key={line.key}
          error={line.error}
          lineKey={line.key}
          property={line.property}
          condition={line.condition}
          value={line.value}
          remove={() => removeLineByKey(line.key)}
        />
      ))}
      <div>
        <Button className="btn_new" onClick={addNewLine}>
          New
        </Button>
      </div>
      <div className="dict-filter_footer">
        <div className="dict-filter_footer_reset" onClick={handleReset}>
          <img src={reset} width="20" height="20" alt="Reset" />
          <span>Reset</span>
        </div>
        <Button className="btn_back" onClick={onClose}>Back</Button>
        <Button className="btn_next" onClick={apply}>Apply</Button>
      </div>
    </div>
  );
}
