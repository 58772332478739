import { Select as AntSelect } from 'antd';
import { forwardRef } from 'react';
import { ReactComponent as Suffix } from '../../Assets/Images/icons/menusuffix.svg';
import { ReactComponent as CloseIcon } from '../../Assets/Images/icons/dashboards/cat-ico_x-close.svg';

import './styles.scss';

const Select = forwardRef((props, ref) => (
  <span className="at-select-wrapper">
    <AntSelect
      ref={ref}
      suffixIcon={<Suffix />}
      clearIcon={<CloseIcon />}
      dropdownMatchSelectWidth={false}
      mode={props.mode ?? undefined}
      value={props.value}
      showSearch={props.showSearch}
      onChange={props.onChange}
      allowClear={props.allowClear}
      bordered={props.bordered || true}
      placeholder={props.placeholder}
      filterOption={props.filterOption}
      maxTagCount={props.maxTagCount}
      open={props.open}
      getPopupContainer={props.getPopupContainer}
      style={props.style}
      className={`at-select ${props.className ? props.className : ''} ${props.isError ? 'at-select-error' : ''}`}
      dropdownClassName="at-select-dropdown"
    >
      {props.children}
    </AntSelect>
  </span>
));

Select.Option = forwardRef((props, ref) => (
  <AntSelect.Option
    ref={ref}
    {...props}
    className={`at-select_option ${props.className ? props.className : ''}`}
  >
    {props.children}
  </AntSelect.Option>
));

export default Select;
