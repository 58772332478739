import { motion } from 'framer-motion';
import { useState } from 'react';
import { observer } from 'mobx-react';
import AutomationHeader from './layout/header';
import AutomationSidebar from './layout/sidebar';
import ExamplesTab from './tabs/examplesTab';
import AutomationsTab from './tabs/automationsTab';
import useAutomations, { AutomationsContext } from './hooks/useAutomations';
import dalInstance from '../../Data/Dal';
import ErrorBoundary from '../ErrorBoundary/errorBoundary';

function AutomationModal() {
  const [tab, setTab] = useState(0);
  const automations = useAutomations(dalInstance.communities[0].id);
  return (
    <motion.div
      key="automation-modal"
      initial={{ y: '100%', opacity: 1, x: '-50%' }}
      animate={{ y: '0%', x: '-50%', transition: { delay: 0.3, type: 'tween', duration: 0.5 } }}
      exit={{
        y: '100%', opacity: 0, x: '-50%', transition: { type: 'tween', duration: 0.4 },
      }}
      className="automation-modal"
    >
      <ErrorBoundary componentName="AutomationModal">
        <AutomationsContext.Provider value={automations}>
          <AutomationHeader selectedTab={tab} setTab={setTab} />
          <div className="automation-modal_main">
            <AutomationSidebar />
            <div className="automation-modal_main_content">
              {tab === 0 ? (
                <ExamplesTab />
              ) : (
                <AutomationsTab />
              )}
            </div>
          </div>
        </AutomationsContext.Provider>
      </ErrorBoundary>
    </motion.div>
  );
}

export default observer(AutomationModal);
