import { motion } from 'framer-motion';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../../Assets/Images/icons/back.svg';

import FiltersSection from '../filters';

export default function QueryNewNewPhase({ setPhase }) {
  const { values: { metadata } } = useFormikContext();
  const { queryId } = useParams();
  const history = useHistory();
  const handleBackClick = useCallback(() => {
    if (queryId) {
      history.push(`/dashboard/queries/${queryId}`);
    } else {
      setPhase(metadata?.state === 'UNFINISHED' ? 1 : 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, queryId]);
  return (
    <div className="queries-view_inner phase-2">
      <motion.div
        className="queries-header"
        layoutId="queries-header"
      >
        <motion.div onClick={handleBackClick} className="queries-header_back" layoutId="queries-header_icon">
          <BackIcon />
        </motion.div>
        <motion.div className="queries-header_text" layout="position" layoutId="queries-header_text">
          <div>{metadata?.name || 'New query'}</div>
        </motion.div>
      </motion.div>
      <FiltersSection />

    </div>
  );
}
