export default function PlaybookLinesHeader() {
  return (
    <div className="pb-grid pb-header">
      <div>Name</div>
      <div>Platforms</div>
      <div>Created</div>
      <div># Runs</div>
      <div>
        Status
      </div>
    </div>
  );
}
