import { observer } from 'mobx-react';
import fa from '../../../Assets/Images/icons/2-fa-ico.svg';

function MFANote({ onMfaToggle }) {
  return (
    <div className="mfa-wrapper">
      <div className="mfa-note" onClick={onMfaToggle}>
        <div><img src={fa} width="30" height="30" alt="" /></div>
        <div className="mfa-note_desc">
          <div>Secure your account</div>
          <div>Activate two factor authentication</div>
        </div>
      </div>
    </div>
  );
}

export default observer(MFANote);
