import { memo } from 'react';
import firstImg from '../../../../Assets/Images/onboarding_sider/0/3-d-ico-1@3x.png';
import secondImg from '../../../../Assets/Images/onboarding_sider/0/3-d-ico-2@3x.png';
import thirdImg from '../../../../Assets/Images/onboarding_sider/0/3-d-ico-3@3x.png';

const UserDetailsSider = memo(
  () => (
    <div className="userdetails">
      <div className="item">
        <div className="icon">
          <img
            src={firstImg}
            width="94"
            height="87"
            alt="Define the features and functionalities you want to target referrals for."
          />
        </div>
        <div className="text sidertext">
          <span>Define</span>
          {' '}
          the features and functionalities you want to
          target referrals for.
        </div>
      </div>
      <div className="item">
        <div className="icon">
          <img
            src={secondImg}
            width="94"
            height="87"
            alt="Connect social media channels or any other engagement tool"
          />
        </div>
        <div className="text sidertext">
          <span>Connect</span>
          {' '}
          social media channels or any other engagement
          tool
        </div>
      </div>
      <div className="item">
        <div className="icon">
          <img
            src={thirdImg}
            width="94"
            height="87"
            alt="Find the best referrals opportunities and keep growing!"
          />
        </div>
        <div className="text sidertext">
          <span>Find</span>
          {' '}
          the best referrals opportunities and keep growing!
        </div>
      </div>
    </div>
  ),
  () => true,
);

export default UserDetailsSider;
