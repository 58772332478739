/* eslint-disable no-useless-escape */
import React from 'react';
import { Button, message, Input } from 'antd';
import { makeObservable, observable, runInAction } from 'mobx';

import { observer } from 'mobx-react';
import NetworkManager from '../../Network/NetworkManager';
import Dal from '../../Data/Dal';

import './styles.scss';
import MiniSpinner from '../../Screens/Crm/Drawer/comps/common/miniSpinner';

class SearchBarInvite extends React.Component {
  email = '';
  isButtonDisabled = true;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    makeObservable(this, {
      email: observable,
      isButtonDisabled: observable,
    });
  }

  onEmailInputChange = (e) => {
    runInAction(() => {
      this.email = e.target.value;
    });
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    runInAction(() => { this.isButtonDisabled = !re.test(this.email); });
  };

  async ctaClicked() {
    runInAction(() => {
      this.ctaLoading = true;
    });

    this.setState({ isLoading: true });

    const response = await NetworkManager.inviteUser(
      Dal.getCurrentCommunityId(),
      this.email,
    );
    if (typeof response === 'string' || response === false) {
      message.error(typeof response === 'string' ? response : 'Error. Try again later');
    } else {
      message.success('Done! the invitation is valid for 24 hours');
    }
    this.setState({ isLoading: false });

    runInAction(() => {
      this.ctaLoading = false;
      Dal.showInviteUser = 0;
    });
  }

  render() {
    return (
      <div style={{ marginLeft: '20px' }}>
        <span>
          <Input
            value={this.email_invite}
            onChange={this.onEmailInputChange}
            placeholder="Invite New User"
            className="searchBarInput"
          />

          <Button
            onClick={this.ctaClicked.bind(this, this.email_invite)}
            loading={this.state.isLoading}
            className="cta-button"
            disabled={this.isButtonDisabled}
          >
            {/* {this.ctaLoading && <MiniSpinner />} */}
            {' '}
            <span className="searchBarButtonText"> Invite </span>
            {' '}
          </Button>
        </span>
      </div>
    );
  }
}
export default observer(SearchBarInvite);
