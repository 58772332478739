import { Select } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { motion } from 'framer-motion';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Dal from '../../../Data/Dal';
import cog from '../../../Assets/Images/icons/cat-ico-cog.svg';

import assIcon from '../../../Assets/Images/icons/revamp/cat-ico-assigned-employee.svg';
import segmentsIcon from '../../../Assets/Images/icons/revamp/cat-ico-segment.svg';
import sourceIcon from '../../../Assets/Images/icons/revamp/cat-ico-source.svg';
import recentIcon from '../../../Assets/Images/icons/revamp/cat-ico-recent (3).svg';
import updatedIcon from '../../../Assets/Images/icons/revamp/cat-ico-updated.svg';
import tagIco from '../../../Assets/Images/icons/cat-ico-tag.svg';

import { ReactComponent as MenuChevron } from '../../../Assets/Images/icons/menuchevron-chevron-down.svg';
import { ReactComponent as XIcon } from '../../../Assets/Images/icons/general-x-icon-vari.svg';
import GeneralUserMultiIcon from '../../../Assets/Images/redesign/generalMultiUserIcon';
import systemFields from '../../../Data/fields/_systemFieldsSchema';
import checkCircle from '../../../Assets/Images/icons/check_circle.png';
import checkCircleChecked from '../../../Assets/Images/icons/check_circle_checked.png';

const { Option } = Select;

const Tag = (mode) => ({ label, onClose, value }) => {
  const colors = useMemo(() => { // eslint-disable-line
    if (mode === 'segment') {
      const segment = toJS(Dal.segments.segments).find((s) => s.id === value);
      const { textColor } = Dal.getSetColorByBgColor(segment.color);
      return {
        bgColor: segment.color,
        textColor,
      };
    }
    return {
      bgColor: '#e5f3ff',
      textColor: '#5294ff',
    };
  }, [value]);
  const clickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };
  return (
    <div
      className="select-tag"
      key={`${mode}-${value}`}
      style={{
        fill: colors.textColor,
        backgroundColor: colors.bgColor,
        color: colors.textColor,
      }}
    >
      <div className="select-tag_close">
        <XIcon onClick={clickHandler} />
      </div>
      <span>{label[1]}</span>
    </div>
  );
};

function SelectStep({ data, setData }) {
  const handleSelectVisibility = (e, type, status) => {
    e.stopPropagation();
    setData((cur) => ({
      ...cur,
      activeSelect: (() => {
        if (status) return type;
        return null;
      })(),
    }));
  };

  const onCloseAll = useCallback((e) => {
    if (e.target.classList.contains('step-select_content')) {
      if (data.activeSelect) {
        setData((cur) => ({
          ...cur,
          activeSelect: null,
        }));
      }
    }
  }, [data, setData]);

  const fields = useMemo(() => {
    const final = {};
    const sf = ['name', 'email', 'phone', 'address', 'title'];
    const extra = {
      tags: {
        key: 'tags',
        label: 'Tags',
        icon: tagIco,
      },
      segments: {
        key: 'segments',
        label: 'Segments',
        icon: segmentsIcon,
      },
      sources: {
        key: 'sources',
        label: 'Sources',
        icon: sourceIcon,
      },
      last_activity: {
        key: 'last_activity',
        label: 'Last activity',
        icon: recentIcon,
      },
      updated_at: {
        key: 'updated_at',
        label: 'Updated at',
        icon: updatedIcon,
      },
      assigned_employee: {
        key: 'assigned_employee',
        label: 'Assigned employee',
        icon: assIcon,
      },
    };
    sf.forEach((s) => {
      final[s] = systemFields[s];
    });

    return {
      ...final,
      ...extra,
    };
  }, []);
  return (
    <motion.div
      className="step-select"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="step-select_header">
        <div>Export as CSV</div>
        <div>You can select specific segments, fields, or all of them</div>
      </div>
      <div className="step-select_content" onClick={onCloseAll}>
        <div className="step-select_content_field">
          <div className="minititle">
            Segments
          </div>
          <Select
            open={data.activeSelect === 'segments'}
            maxTagCount="responsive"
            onClick={(e) => handleSelectVisibility(e, 'segments', true)}
            onBlur={(e) => handleSelectVisibility(e, 'segments', false)}
            className="export-select export-select-segments"
            tagRender={Tag('segment')}
            dropdownClassName="export-dropdown"
            mode="multiple"
            allowClear
            showArrow
            suffixIcon={<MenuChevron />}
            placeholder="All"
            value={data.mappings.segments}
            onChange={(v) => setData({ ...data, mappings: { ...data.mappings, segments: v } })}
          >
            {toJS(Dal.segments).segments.map((opt) => (
              <Option className="select-option select-option-segment" onClick={(e) => e.stopPropagation()} key={`seg-${opt.id}`} value={opt.id}>
                <GeneralUserMultiIcon fill={opt.color} />
                {opt.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="step-select_content_field">
          <div className="minititle">
            Fields
          </div>
          <Select
            maxTagCount="responsive"
            open={data.activeSelect === 'fields'}
            onClick={(e) => handleSelectVisibility(e, 'fields', true)}
            onBlur={(e) => handleSelectVisibility(e, 'fields', false)}
            className="export-select"
            tagRender={Tag('fields')}
            dropdownClassName="export-dropdown"
            mode="multiple"
            allowClear
            showArrow
            suffixIcon={<MenuChevron />}
            placeholder="All"
            value={data.mappings.fields}
            onChange={(v) => setData({ ...data, mappings: { ...data.mappings, fields: v } })}
          >
            {Object.keys(fields).map((sf) => (
              <Option className="select-option" key={`sf-${fields[sf].key}`} value={`sf__${sf}`}>
                <img src={fields[sf].icon} width="30" height="30" alt="" />
                <span style={{ marginLeft: '-3px' }}>{fields[sf].label}</span>
              </Option>
            ))}
            {toJS(Dal.customFields).map((cu) => (
              <Option className="select-option" key={`cu-${cu.id}`} value={`cu__${cu.label}`}>
                <img src={cog} width="30" height="30" alt="" />
                <span style={{ marginLeft: '-3px' }}>{cu.label}</span>
              </Option>
            ))}
          </Select>
        </div>
        <div className="step-select_content_field">
          <div onClick={() => setData((cur) => ({ ...cur, withHistory: !cur.withHistory }))} className="check-select">
            <img src={!data.withHistory ? checkCircle : checkCircleChecked} alt="check" width="22" height="22" />
            <span>Include segment history</span>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default observer(SelectStep);
