import { useFormikContext } from 'formik';
import { Button, Dropdown, Menu } from 'antd';
import { useMemo } from 'react';
import chevron from '../../Assets/Images/icons/menuchevron-chevron-down.svg';
import infoIco from '../../Assets/Images/icons/info-sml.svg';

import platformColors, { platformColorsByBg } from '../../Data/colors';

export default function ColorPicker({ field }) {
  const { setFieldValue, values } = useFormikContext();
  const relevantField = useMemo(() => values?.[field], [values, field]);
  const colorMenu = useMemo(
    () => (
      <Menu>
        {platformColors.map((color) => (
          <Menu.Item onClick={() => setFieldValue(field, color.bgColor)} key={color.name}>
            <Button
              className="set-text-color"
              type="text"
              style={{
                width: '120px',
                background: color.bgColor,
                color: color.textColor,
                fontWeight: 700,
                borderRadius: '4px',
              }}>
              {color.name}
            </Button>
          </Menu.Item>
        ))}
      </Menu>
    ),
    [field, setFieldValue],
  );

  return (
    <div className="color-picker" tabIndex="0" role="button">
      <div className="color-picker_chooser">
        <Dropdown overlay={colorMenu} placement="bottomCenter" arrow>
          <div className="color-picker_selectedWrapper">
            <Button
              className="set-text-color"
              type="text"
              style={{
                width: '120px',
                height: '45px',
                fontWeight: 700,
                color: platformColorsByBg[relevantField].textColor,
                background: relevantField,
                borderRadius: '4px',
              }}>
              {platformColorsByBg[relevantField].name}
            </Button>
            <div className="color-picker_chevron">
              <img src={chevron} width="16" height="16" alt="" />
            </div>
          </div>
        </Dropdown>
      </div>

      <div className="color-picker_info">
        <img src={infoIco} width="18" height="18" alt="" />
        <div>
          This is how your{' '}
          <span style={{ background: relevantField, color: platformColorsByBg[relevantField] }}>
            Keyword highlight
          </span>{' '}
          would appear on your feeds.
        </div>
      </div>
    </div>
  );
}
