import React from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Spin } from 'antd';
import { makeObservable, observable, runInAction } from 'mobx';
import SlackDal from './slackDal';
import Dal from '../../Data/Dal';

class Slack extends React.Component {
  shouldShowLoader = false;
  shouldRedirect = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      shouldShowLoader: observable,
      shouldRedirect: observable,
    });
    this.checkForCode();
  }

  async checkForCode() {
    console.log('[SLACK][checkForCode] 1/2, entered');
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const code = params.get('code');
    if (code) {
      const state = params.get('state');
      console.log('[SLACK][checkForCode] 2/2 Received code, ', code, ' scopesMode: ', state);
      runInAction(() => { SlackDal.scopesMode = state; });
      await this.exchangeCode(code, state);
    }
  }

  async exchangeCode(code, state) {
    console.log('[SLACK][exchangeCode] 1/6 entered');
    runInAction(() => { this.shouldShowLoader = true; });
    await Dal.fetchUser();
    console.log('[SLACK][exchangeCode] 2/6 fetchUser success');
    const community = Dal.communities[0].id;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    let redirect_uri;
    if (window.location.port) {
      redirect_uri = `https://${window.location.hostname}:${window.location.port}`;
    } else {
      redirect_uri = `https://${window.location.hostname}`;
    }

    const url = `https://slack.com/api/oauth.v2.access?code=${code}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&client_secret=${process.env.REACT_APP_SLACK_CLIENT_SECRET}&redirect_uri=${redirect_uri}/slack`;
    const response = await fetch(url, options);
    const resp = await response.json();
    console.log('[SLACK][exchangeCode] 3/6 response from slack', resp);
    const assets = [];

    const workflowAsset = {
      asset_id: resp.team.id,
      service_name: 'slack',
      type: 'workspace',
      token: {
        bot_token: resp.access_token,
        user_token: {
          user_id: resp.authed_user.id,
          token: resp.authed_user.access_token,
        },
      },
      token_secret: null,
      community_id: community,
      should_pull_content: false,
    };
    const contentAsset = {
      asset_id: resp.team.id,
      service_name: 'slack',
      type: 'workspace',
      token: {
        user_token: {
          user_id: resp.authed_user.id,
          token: resp.authed_user.access_token,
        },
      },
      token_secret: null,
      community_id: community,
      should_pull_content: true,
    };

    let channels = [];

    switch (state) {
      case 'all':
        assets.push(workflowAsset, contentAsset);
        channels = await this.fetchWorkspaceChannels(
          resp.authed_user.access_token,
        );
        runInAction(() => { SlackDal.channels = channels; });
        break;
      case 'workflows':
        assets.push(workflowAsset);
        break;
      case 'content':
        assets.push(contentAsset);
        channels = await this.fetchWorkspaceChannels(
          resp.authed_user.access_token,
        );
        runInAction(() => { SlackDal.channels = channels; });
        break;
      default:
        break;
    }
    console.log('[SLACK][exchangeCode] 4/6 Assets to create:', assets);
    const lastAssetID = await Dal.createNewAssets(assets, null, community);
    if (lastAssetID instanceof Error) {
      const errorMsg = lastAssetID?.response?.data?.error || 'general_error';
      console.log('[SLACK][ERROR]', errorMsg);
      runInAction(() => { this.shouldRedirect = errorMsg; });
      return false;
    }
    console.log('[SLACK][exchangeCode] 5/6 createNewAssets success');

    runInAction(() => { SlackDal.storedAssetID = lastAssetID.data; });
    runInAction(() => { this.shouldRedirect = true; });
    console.log('[SLACK][exchangeCode] 6/6 SHOULD REDIRECT TO SUCCESS');
  }

  async fetchWorkspaceChannels(token) {
    console.log('[SLACK][fetchWorkspaceChannels] 1/2, entered ');
    let allChannels = [];
    let shouldFetch = true;
    let cursor = null;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    while (shouldFetch) {
      let url = `https://slack.com/api/conversations.list?token=${token}`;
      if (cursor) {
        url = `https://slack.com/api/conversations.list?token=${token}&cursor=${cursor}`;
      }
      const response = await fetch(url, options);
      const resp = await response.json();
      allChannels = allChannels.concat(resp.channels);
      console.log('[SLACK][fetchWorkspaceChannels] 2/2, fetched channels ', resp, ' all channels:', allChannels);
      if (resp?.response_metadata && typeof resp?.response_metadata?.next_cursor !== 'undefined') {
        cursor = resp.response_metadata.next_cursor;
      } else if (typeof resp?.response_metadata?.next_cursor === 'undefined') {
        cursor = null;
      }
      shouldFetch = cursor !== null && cursor !== undefined && cursor !== '';
    }
    return allChannels;
  }
  render() {
    return this.shouldRedirect ? (
      <Redirect
        to={`dashboard/integrations?slack=true${typeof this.shouldRedirect === 'string' ? `&error=${this.shouldRedirect}` : ''}`}
      />
    ) : this.shouldShowLoader ? (
      <div className="loading-state-content">
        <Spin />
      </div>
    ) : null;
  }
}

export default observer(Slack);
