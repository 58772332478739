import { useContext, useMemo } from 'react';
import { motion } from 'framer-motion';
import { Tooltip } from 'antd';
import { ReactComponent as NewAutomation } from '../../../Assets/Images/icons/cat-ico-automate-new.svg';
import { ReactComponent as Automation } from '../../../Assets/Images/icons/cat-ico-automate-vari.svg';
import Spinner from '../../Skeletons/spinner';
import { AutomationsContext } from '../hooks/useAutomations';

export default function AutomationHeader({ selectedTab, setTab }) {
  const { workflowsQuery: { isLoading, data } } = useContext(AutomationsContext);
  const totalEnabled = useMemo(() => {
    if (data) {
      return data.reduce((acc, cur) => {
        if (cur.isEnabled) return acc + 1;
        return acc;
      }, 0);
    }
    return 0;
  }, [data]);
  return (
    <div className="automation-modal_header">
      <div className="automation-modal_header_title">
        Automation Center
      </div>
      <div className="automation-modal_header_actions">
        <div className="header-tabs">
          <div className={`header-tabs_item ${selectedTab === 0 ? 'selected' : ''}`} onClick={() => setTab(0)}>
            <NewAutomation />
            <span>New Automation Rule</span>
          </div>
          <Tooltip title={<span style={{ color: 'black', fontFamily: 'Poppins' }}>No automations to display</span>} color="white" trigger={data?.length === 0 ? 'hover' : 'none'}>
            <div className={`header-tabs_item ${selectedTab === 1 ? 'selected' : ''} ${data?.length === 0 ? 'disabled' : ''}`} onClick={() => data?.length !== 0 && setTab(1)}>

              <Automation />
              <span>My Automation Rules</span>

            </div>
          </Tooltip>
        </div>
      </div>
      <div className="automation-modal_header_count">
        <motion.div
          className="counter"
          animate={{ width: isLoading || data?.length === 0 ? '30px' : '70px' }}
        >
          {/* <div className="item">0</div> */}
          {isLoading ? (
            <div className="item">
              <Spinner
                style={{
                  borderColor: '#3b82f4',
                }}
              />
            </div>
          ) : (
            <>
              {data.length !== 0 && (
                <>
                  <div className="item bolder">{totalEnabled}</div>
                  <div className="item">|</div>
                </>
              )}
              <div className="item">{data.length}</div>
            </>
          )}
        </motion.div>
      </div>
    </div>
  );
}
