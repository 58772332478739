import { motion } from 'framer-motion';
import './style.scss';

export default function Switch({ isChecked, label, onChange }) {
  return (
    <div className="small-check" onClick={() => onChange(!isChecked)}>
      <div
        className="small-check_theCheck"
        style={{ justifyContent: isChecked ? 'flex-end' : 'flex-start' }}
      >
        <motion.div layout style={isChecked ? { backgroundColor: '#65BB6A' } : {}} className="small-check_theCheck_bullet" />
      </div>
      <div className="small-check_label">{label}</div>
    </div>
  );
}
