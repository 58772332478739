import React from 'react';

const generalUserMultiIcon = ({ fill, width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    data-noAutofill="true"
  >
    <g fill={fill}>
      <path
        d="M9.5 12c1.374 0 2.63.513 3.594 1.361A5.167 5.167 0 0 1 15 13c2.761 0 5 2.149 5 4.8 0 .663-.56 1.2-1.25 1.2H5.375C4.615 19 4 18.373 4 17.6 4 14.507 6.462 12 9.5 12zM9 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm7 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"
        fill={fill}
        fillRule="evenodd"
      />
    </g>
  </svg>
);

export default generalUserMultiIcon;
