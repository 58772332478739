import { useContext } from 'react';
import { createPortal } from 'react-dom';
import GhostButton from '../../Buttons/ghostButton';
import { BoardContext } from '../hooks/useBoard';
import './actions.scss';

import { ReactComponent as BoardEditIcon } from '../../../Assets/Images/icons/dashboards/board-edit.svg';
import { ReactComponent as BoardNewIcon } from '../../../Assets/Images/icons/dashboards/board-new.svg';
import { ReactComponent as BoardDownIcon } from '../../../Assets/Images/icons/dashboards/board-download.svg';
import { ReactComponent as BoardShareIcon } from '../../../Assets/Images/icons/dashboards/board-share.svg';

export default function BoardActions() {
  const { setEditMode, setEdittorModal } = useContext(BoardContext);
  return (
    <div className="board-actions">
      <GhostButton text="Edit" icon={<BoardEditIcon />} onClick={() => setEditMode((cur) => !cur)} />
      <GhostButton text="New" icon={<BoardNewIcon />} onClick={() => setEdittorModal(true)} />
      {/* <GhostButton text="Download" icon={<BoardDownIcon />} /> */}
      {/* <GhostButton text="Share" icon={<BoardShareIcon />} /> */}
    </div>
  );
}

export const BoardActionsPortal = ({ node, ...rest }) => {
  if (!node) return null;
  return createPortal(
    <BoardActions {...rest} />,
    node,
  );
};
