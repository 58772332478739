/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import {
  Modal,
  Button,
  Divider,
  List,
  Space,
} from 'antd';
import { withRouter } from 'react-router-dom';
import {
  runInAction,
} from 'mobx';
import { observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import Dal from '../../../Data/Dal';

import './styles.scss';
import AvatarView from '../../../Components/AvatarView/AvatarView';
import SearchBarInvite from '../../../Components/SearchBarInvite';

class InviteUserView extends Component {
  closeClicked() {
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: '',
      isActive: true,
    });
    runInAction(() => {
      Dal.showInviteUser = 0;
    });
  }

  members() {
    const { communityUsers } = Dal;
    return communityUsers;
  }

  render() {
    return this.props.visible ? (
      <div style={{ overflowY: 'hidden' }}>
        <Modal
          style={{ overflowY: 'hidden' }}
          visible={this.props.visible}
          wrapClassName="modal-background"
          title={(
            <div>
              <p className="set-title">Manage team</p>
              <p className="set-subtitle">
                Add team-mates who can be assigned to this set
              </p>
            </div>
          )}
          centered
          footer={null}
          onCancel={this.closeClicked.bind(this)}
        >
          <div style={{ overflowY: 'hidden' }}>
            <p
              className="section-title"
              style={{ marginLeft: '20px', marginTop: '10px' }}
            >
              Invite new user with Email address
            </p>

            <SearchBarInvite dataSource={this.members()} />

            <Divider style={{ color: '#d8d8d8', marginTop: 20 }} />
            <div
              id="scrollableDiv"
              style={{
                overflowY: 'auto',
                width: '470px',
                height: '200px',
              }}
            >
              <InfiniteScroll initialLoad={false} loadMore={() => null} useWindow={false}>
                <div style={{ marginLeft: '30px' }}>
                  <List
                    itemLayout="vertical"
                    bordered={false}
                    dataSource={this.members()}
                    renderItem={(item) => (
                      <List.Item>
                        <div className="name">
                          <AvatarView
                            name={`${item.first_name} ${item.family_name}`}
                            loading={false}
                          />
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
              </InfiniteScroll>
            </div>

            <Divider className="mngteam-divider" style={{ color: '#d8d8d8' }} />
            <div align="center">
              <Space size={30}>
                <Button
                  onClick={this.closeClicked.bind(this)}
                  className="cancel-button"
                  style={{
                    width: '180px',
                    height: '50px',
                    marginBottom: '10px',
                    borderRadius: '4px',
                  }}
                >
                  Cancel
                </Button>
              </Space>
            </div>
          </div>
        </Modal>
      </div>
    ) : null;
  }
}

export default withRouter(observer(InviteUserView));
