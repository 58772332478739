import { motion } from 'framer-motion';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { ReactComponent as BackIcon } from '../../../Assets/Images/icons/back.svg';

import PlaybooksHeader from '../common/header';
import PlaybookIcon from '../../../Assets/Images/icons/playbooks/playbook-icon';
import { platformColorsByBg } from '../../../Data/colors';

export default function PlaybooksNewHeader() {
  const history = useHistory();
  const { values } = useFormikContext();
  return (
    <PlaybooksHeader>
      <motion.div onClick={() => history.goBack()} className="playbooks-header_back" layoutId="playbooks-header_icon">
        <BackIcon />
      </motion.div>
      {values.metadata.state === 'EXISTING' && (
        <div className="playbooks-header_icon" layout="position" layoutId="playbooks-header_icon">
          <div className="playbooks-header_icon_inner" style={{ borderColor: platformColorsByBg[values.color].textColor }}>
            <PlaybookIcon color={platformColorsByBg[values.color].textColor} />
          </div>
        </div>
      )}
      <motion.div className="playbooks-header_text" layout="position" layoutId="playbooks-header_text">
        <div>
          {values.metadata.state === 'UNFINISHED' ? 'New Playbook' : values.name}
        </div>
      </motion.div>
    </PlaybooksHeader>
  );
}
