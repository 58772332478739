// import { useCallback, useState } from 'react';
// import ChooseQueryModal from './queryModal';
import QuerySelectPart from './parts/querySelect';
import ReactionSelect from './parts/reactionSelect';
import FinalizePart from './parts/finalize';

export default function PlaybooksEditorFlow() {
  // const handleTriggerClick = useCallback(() => {
  //   setQueriesModal(true);
  // }, []);
  return (
    <div className="flow">
      <QuerySelectPart />
      <ReactionSelect />
      <FinalizePart />
    </div>
  );
}
