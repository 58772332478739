/* eslint-disable no-console */
import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import hotjarProvider from './hotjarProvider';

/* Initialize analytics */
const analytics = Analytics({
  app: 'loudnclear',
  version: 100,
  plugins: [
    hotjarProvider(),
    mixpanelPlugin({
      token: '70b137b3b07610804624955f10cfca37',
    }),
  ],
});

const trackWithCatch = (eName, payload) => {
  try {
    if (process.env.REACT_APP_ENV === 'prod') {
      analytics.track(eName, payload);
    }
    if (process.env.REACT_APP_DEV === 'true') {
      console.log('[AnalyticsService] ', eName, payload);
    }
  } catch (e) {
    console.log(`Failed analytics ${eName}`);
  }
};

export const AnalyticsService = {
  generalDashboardView: (dashboardName) => trackWithCatch('general_dashboard_view', { dashboardName }),
  generalDashboardCreated: (dashboardName) => trackWithCatch('general_dashboard_create', { dashboardName }),
  generalDashboardWidgetCreated: (dashboardName) => trackWithCatch('general_dashboard_widget_create', { dashboardName }),
  segmentView: (segmentName) => trackWithCatch('segment_view', { segmentName }),
  segmentUpdate: (segmentName) => trackWithCatch('segment_update', { segmentName }),
  crmDrawerView: (segmentName) => trackWithCatch('crm_drawer_view', { segmentName }),
  crmLinkToOriginalPlatform: (segmentName, platform) => trackWithCatch('crm_link_to_original_platform', { segmentName, platform }),
  segmentCreate: (segmentName) => trackWithCatch('segment_create', { segmentName }),
  segmentDashboardView: (segmentName) => trackWithCatch('segment_dashboard_view', { segmentName }),
  superFilterRun: () => trackWithCatch('super_filter_run'),
  setView: (setName) => trackWithCatch('set_view', { setName }),
  setUpdate: (setName) => trackWithCatch('set_update', { setName }),
  setCreate: (setName) => trackWithCatch('set_create', { setName }),
  setDrawerView: (setName) => trackWithCatch('set_drawer_view', { setName }),
  setLinkToOriginalPlatform: (setName, platform) => trackWithCatch('set_link_to_original_platform', { setName, platform }),
  enterWorkspace: () => trackWithCatch('enter_workspace'),
  createTag: (tag) => trackWithCatch('create_tag', { tag }),
  removeTag: (tag) => trackWithCatch('remove_tag', { tag }),
  assignTag: (tag) => trackWithCatch('assign_tag', { tag }),
  unassignTag: (tag) => trackWithCatch('unassign_tag', { tag }),
  outerLink: (source, link) => trackWithCatch('outer_link', { link, source }),
};

export default analytics;
