import { useMemo } from 'react';

const transformArrayIntoObjectById = (arr) => arr.reduce((acc, cur) => ({
  ...acc,
  [cur.id]: cur,
}), {});

const useCommunity = ({
  communityId,
  assets: rawAssets,
  dictionaries: rawDictionaries,
  customFields: rawCF,
}) => {
  const assets = useMemo(() => transformArrayIntoObjectById(Array.isArray(rawAssets?.accounts) ? rawAssets.accounts : []), [rawAssets]);
  const dictionaries = useMemo(() => transformArrayIntoObjectById(Array.isArray(rawDictionaries) ? rawDictionaries : []), [rawDictionaries]);
  const customFields = useMemo(() => transformArrayIntoObjectById(Array.isArray(rawCF) ? rawCF : []), [rawCF]);
  return {
    communityId,
    assets,
    dictionaries,
    customFields,
    id: communityId,
  };
};

export default useCommunity;
