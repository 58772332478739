import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useFormikContext } from 'formik';
import { Button } from 'antd';
import AutomationText, { TriggerText } from './ui/automationText';
import reactionExamples from './_data/examples';
import reactors from './_data/templates/reactors';
import { useCommunityContext } from '../../../../../Utils/contexts/community';

export default function AutomateView({ setPhase, onComplete }) {
  const { assets } = useCommunityContext();
  const { values, setFieldValue } = useFormikContext();
  const [error, setError] = useState('');
  const relevantExample = useMemo(() => reactionExamples.find((f) => f.id === values?.action?.possible_action_id), [values]);
  const relevantReaction = useMemo(() => reactors?.[relevantExample?.reactor], [relevantExample]);
  const reactionAsset = useMemo(() => Object.values(assets).find((asset) => asset.service_name === relevantReaction.service_name), [assets, relevantReaction]);
  const handleBack = useCallback(() => {
    setPhase(1);
  }, [setPhase]);
  const handleDone = useCallback(() => {
    setFieldValue('action.metadata.asset_id', reactionAsset.id);
    try {
      relevantReaction.validationSchema.validateSync(values.action.metadata);
      setError('');
      onComplete();
    } catch (e) {
      setError(e.message);
    }
  }, [values, relevantReaction, onComplete]);
  useEffect(() => {
    setError('');
  }, [values.action]);
  return (
    <div className="automate-wrapper">
      <div className="automate">
        <div className="automate-header">
          <AutomationText reactorAbbr={relevantExample.reactor} isExample />
        </div>
        <div className="automate-content">
          <TriggerText />
          {' '}
          <AutomationText reactorAbbr={relevantExample.reactor} isEditMode />
          {relevantReaction.templates.MetadataEdit && (
            <relevantReaction.templates.MetadataEdit />
          )}
          {error && <div className="error-text">{error}</div>}
        </div>
        <div className="automate-footer">
          <div className="buttons">
            <Button className="lnc-gray-btn" onClick={handleBack}>Back</Button>
            <Button className="lnc-blue-btn" onClick={handleDone}>Add to playbook</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
