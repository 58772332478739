/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Card } from 'antd';

import './styles.scss';

class IntegrationCardView extends Component {
  cardStyle(props) {
    if (!props.supportedNow) {
      return 'integration-card-container-bg';
    }
    if (props.connected) {
      if (props.isCardSelected) {
        return 'integration-card-container-bg connected selected';
      }
      return 'integration-card-container-bg connected';
    }
    return 'integration-card-container-bg notConnected';
  }

  render() {
    return (
      <div
        onClick={() => this.props.onClick(this.props)}
        role="button"
        tabIndex="0"
        className={this.cardStyle(this.props)}
        style={
          this.props.supportedNow
            ? { cursor: 'pointer' }
            : { cursor: 'not-allowed', pointerEvents: 'none' }
        }
      >
        <Card
          bordered={false}
          bodyStyle={{ padding: 0, position: 'relative' }}
          className="regularCard"
        >
          {!this.props.supportedNow ? (
            <div className="soon">
              <p className="soonTitle">SOON!</p>
            </div>
          ) : null}
          <div className="cardElements">
            <img
              className="integration-card-image"
              src={this.props.image}
              alt=""
            />
            <p className="integration-card-title">{this.props.title}</p>
            <div
              className={
                this.props.supportedNow
                  ? 'integration-card-cta-container'
                  : 'hidden'
              }
            >
              <img
                className="integration-card-cta-one"
                src={
                  this.props.connected
                    ? this.props.ctaImageSelected
                    : this.props.ctaImage
                }
                alt=""
              />
              <p
                className={
                  this.props.connected
                    ? 'integration-card-cta-two selected'
                    : 'integration-card-cta-two'
                }
              >
                {this.props.ctaTitle}
              </p>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default IntegrationCardView;
