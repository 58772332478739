const platformColors = [
  {
    bgColor: '#d6caff',
    textColor: '#4821cc',
    name: 'PURPLE',
  },
  {
    bgColor: '#dbe9ff',
    textColor: '#5382b0',
    name: 'BLUE',
  },
  {
    bgColor: '#99d2ff',
    textColor: '#076cbe',
    name: 'BLUE 2',
  },
  {
    bgColor: '#bad8e6',
    textColor: '#518097',
    name: 'BLUE GRAY',
  },
  {
    bgColor: '#cab4ac',
    textColor: '#695048',
    name: 'BROWN',
  },
  {
    bgColor: '#96dde6',
    textColor: '#028a9b',
    name: 'CYAN',
  },
  {
    bgColor: '#ffd89f',
    textColor: '#d88000',
    name: 'ORANGE',
  },
  {
    bgColor: '#ff9d7e',
    textColor: '#c84e27',
    name: 'DEEP ORANGE',
  },
  {
    bgColor: '#aee7b2',
    textColor: '#339438',
    name: 'GREEN',
  },
  {
    bgColor: '#e6e6e6',
    textColor: '#7a7a7a',
    name: 'GRAY',
  },
  {
    bgColor: '#dbe67a',
    textColor: '#7b8612',
    name: 'LIME',
  },
  {
    bgColor: '#ffa4c3',
    textColor: '#cb2b61',
    name: 'PINK',
  },
  {
    bgColor: '#f09695',
    textColor: '#cb2421',
    name: 'RED',
  },
];

export const platformColorsByBg = platformColors.reduce((acc, cur) => {
  acc[cur.bgColor] = cur;
  return acc;
}, {});

export const getRandomColors = () => platformColors[Math.floor(Math.random() * platformColors.length)];

export default platformColors;
