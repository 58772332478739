import { HiDotsHorizontal } from 'react-icons/hi';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DropdownMenu from '../../../../DropdownMenu';
import Ico from '../../../../../Assets/Images/icons/automate-ico.svg';
import { ReactComponent as EmailIco } from '../../../../../Assets/Images/icons/email-schedule.svg';
import EmailModal from '../email/modal';

export default function ActionsButton() {
  const { queryId } = useParams();
  const [emailModal, setEmailModal] = useState(false);
  const menu = [
    {
      key: 'email',
      label: 'Schedule report',
      icon: <EmailIco />,
      onClick: () => setEmailModal(true),
    },
  ];
  return (
    <>
      <DropdownMenu
        button={(
          <div className="hover-link-box">
            <img src={Ico} width="25" height="25" alt="" />
            Actions
          </div>
        )}
        items={menu}
      />
      <div><EmailModal queryId={queryId} isOpen={emailModal} onClose={() => setEmailModal(false)} /></div>
    </>
  );
}
