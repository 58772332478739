import hubspotIcon from '../../../../../../../Assets/Images/redesign/repo-icon-hubspot.svg';
import churnzeroIcon from '../../../../../../../Assets/Images/icons/repo-icon-crunzero.png';
import assignIcon from '../../../../../../../Assets/Images/icons/automation/assign.svg';
import tagIcon from '../../../../../../../Assets/Images/icons/automation/tag.svg';

import marketoIcon from '../../../../../../../Assets/Images/icons/repo-icon-marketo.svg';
import mailchimpIcon from '../../../../../../../Assets/Images/icons/repo-icon-mailchimp.svg';
import salesforceIcon from '../../../../../../../Assets/Images/icons/repo-icon-salesforce.svg';
import slackWorkspaceNotifyReactor from './reactors/slack.workspace.notify';
import salesforceAccountCreateOpportunityReactor from './reactors/salesforce.account.create_opportunity';
import zendeskAccountUpdateCustomField from './reactors/zendesk.account.update_custom_field';
// import salesforceAccountCreateOpportunityReactor from './reactors/salesforce.account.create_opportunity';

const reactors = {
  'slack.workspace.notify': slackWorkspaceNotifyReactor,
  // 'salesforce.account.create_interaction': salesforceAccountCreateInteractionReactor,
  // 'salesforce.account.edit_contact_sentiments': salesforceAccountEditContactSentimentReactor,
  'salesforce.account.create_opportunity': salesforceAccountCreateOpportunityReactor,
  'zendesk.account.update_custom_field': zendeskAccountUpdateCustomField,
  'hubspot.workspace.create': {
    id: 1002,
    service_name: 'email',
    service_type: 'message',
    action: 'notify',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={hubspotIcon} width="30" height="30" alt="hubspot" />
          Create a record of existing Object on Hubspot
        </span>
      ),
    },
  },
  'salesforce.account.update': {
    id: 10021,
    service_name: 'email',
    service_type: 'message',
    action: 'notify',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={salesforceIcon} width="30" height="30" alt="salesforce" />
          Update a field of an exisitng Object on Salesforce
        </span>
      ),
    },
  },
  'hubspot.workspace.update': {
    id: 1003,
    service_name: 'loudnclear',
    service_type: 'workspace',
    action: 'assign',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={hubspotIcon} width="24" height="24" alt="hubspot" />
          Update a field of an exisitng Object on Hubspot
        </span>
      ),
    },
  },
  'loudnclear.workspace.email': {
    id: 1004,
    service_name: 'loudnclear',
    service_type: 'workspace',
    action: 'addtag',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={tagIcon} width="24" height="24" alt="email" />
          Send a contextual email to customer
        </span>
      ),
    },
  },
  'marketo.campaign.trigger': {
    id: 1005,
    service_name: 'marketo',
    service_type: 'campaign',
    action: 'trigger',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={marketoIcon} width="30" height="30" alt="Marketo" />
          Trigger a Marketo campaign
        </span>
      ),
    },
  },
  'mailchimp.campaign.trigger': {
    id: 1006,
    service_name: 'mailchimp',
    service_type: 'campaign',
    action: 'trigger',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={mailchimpIcon} width="16" height="16" alt="slack" />
          Trigger a Mailchimp campaign
        </span>
      ),
    },
  },
  'salesforce.account.update_cfield': {
    id: 1007,
    service_name: 'salesforce',
    service_type: 'account',
    action: 'update_cfield',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={salesforceIcon} width="30" height="30" alt="salesforce" />
          Update contact field in Salesfroce
        </span>
      ),
    },
  },
  'salesforce.account.update_afield': {
    id: 1008,
    service_name: 'salesforce',
    service_type: 'account',
    action: 'update_afield',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={salesforceIcon} width="30" height="30" alt="salesforce" />
          Update account field in Salesfroce
        </span>
      ),
    },
  },
  'salesforce.account.assign_task': {
    id: 1009,
    service_name: 'salesforce',
    service_type: 'account',
    action: 'update_afield',
    acceptedMetadata: [],
    templates: {
      ShortDisplay: () => (
        <span className="display">
          <img src={salesforceIcon} width="30" height="30" alt="salesforce" />
          Assign a task in Salesfroce
        </span>
      ),
    },
  },
};

export const reactorsById = Object.values(reactors).reduce((acc, cur) => ({
  ...acc,
  [cur.id]: cur,
}), {});

export default reactors;
