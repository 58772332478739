import WelcomeContainer from '../../Dashboard/Welcome/welcomeContainer';

const QuickActions = () => (
  <div className="homescreen-main_quick">
    <div className="section-title">Quick actions</div>
    <WelcomeContainer minified />
  </div>
);

export default QuickActions;
