import { AnimatePresence, motion } from 'framer-motion';

const ErrorTooltip = ({ isShown, text }) => (
  <AnimatePresence>
    {isShown && (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="at-tooltip at-tooltip-error">
        {text}
      </motion.div>
    )}
  </AnimatePresence>
);

export default ErrorTooltip;
