import { ReactComponent as SearchIcon } from '../../Assets/Images/icons/search.svg';
import './style.scss';

export default function SearchBox({ name, onSearch }) {
  return (
    <div className="searchbox">
      <div className="input-wrapper">
        <input type="text" onChange={(e) => onSearch(e.target.value)} placeholder={`Search ${name}`} />
        <div className="input-wrapper_icon"><SearchIcon /></div>
      </div>
    </div>
  );
}
