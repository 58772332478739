import iconsMapping from '../iconsMapping';

export const all = {
  count: {
    icon: iconsMapping.measureFunc.count,
    key: 'count',
    label: 'Total count',
  },
  sum: {
    icon: iconsMapping.measureFunc.count,
    key: 'sum',
    label: 'Sum',
  },
  word_count: {
    icon: iconsMapping.measureFunc.count,
    key: 'word_count',
    label: 'Word count',
  },
  post_count: {
    icon: iconsMapping.measureFunc.count,
    key: 'post_count',
    label: 'Post count',
  },
  comment_count: {
    icon: iconsMapping.measureFunc.count,
    key: 'comment_count',
    label: 'Comment count',
  },
  member_count: {
    icon: iconsMapping.measureFunc.count,
    key: 'member_count',
    label: 'Member count',
  },
};

const measureFuncs = {
  contacts: [all.count, all.sum],
  accounts: [all.count],
  interactions: [all.count, all.word_count, all.post_count, all.comment_count, all.sum],
  actions: [all.count, all.sum],
  sources: [all.member_count],
};

export default measureFuncs;
