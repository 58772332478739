import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import useQueriesQueries from '../../hooks/useQueriesQueries';

export default function FormikUpdater({ currentPhase, path }) {
  const { queries } = useQueriesQueries();
  const formikContext = useFormikContext();
  const { setValues, values: { metadata, filters } } = formikContext;
  const { queryId } = useParams();
  useEffect(() => {
    window.formik = formikContext;
    if (queries.data && currentPhase !== 1) {
      let query;
      if (queryId) {
        query = queries.data.find((qu) => qu.id === Number(queryId));
        if (query) {
          query = { ...query, state: 'EXISTING', isEdited: false };
        }
      } else {
        query = {
          filter: filters, name: metadata.name, color: metadata.color, isEdited: metadata.isEdited, state: metadata.state,
        };
      }
      if (query) {
        setValues({
          filters: query.filter,
          metadata: {
            initted: false, ...query,
          },
        });
      }
    } else if (!queryId) {
      // go to initial example phase and reset the creation modal
      setValues({ filters: [], metadata: { state: 'UNFINISHED', color: '#99d2ff' } });
    }
  }, [queryId, currentPhase, queries.data]);
  useEffect(() => {
    if (path.includes('/queries/new')) setValues({ filters: [], metadata: { state: 'UNFINISHED', color: '#99d2ff' } });
  }, [path]);
  return (
    <></>
  );
}
