import React, { Component } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import { ReactQueryDevtools } from 'react-query/devtools';
import { runInAction } from 'mobx';
import { Auth } from 'aws-amplify';
import { Toaster } from 'react-hot-toast';
import { initGA } from './Components/Tracking/tracking';
import { ReactComponent as SentimentNeg } from './Assets/Images/icons/revamp/cat-ico-sentiment-3.svg';
import './styles/css/antd.css';
import './styles/global.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Dal from './Data/Dal';
import Main from './Main';

const queryClient = new QueryClient();

const toasterConfig = {
  className: 'lnc-toast',
  error: { className: 'lnc-toast lnc-toast_error' },
  success: { className: 'lnc-toast lnc-toast_success' },
  loading: { className: 'lnc-toast lnc-toast_loading' },
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingUser: true,
    };
  }
  async componentDidMount() {
    try {
      await Auth.currentSession();
      const cognitoUser = await Auth.currentUserPoolUser();
      runInAction(() => {
        Dal.isLoggedIn = true;
        Dal.cognitoUser = cognitoUser;
      });
    } catch (e) {
      if (e === 'No current user') {
        runInAction(() => {
          Dal.isLoggedIn = false;
        });
      }
    }
    this.setState({ isLoadingUser: false });
  }

  render() {
    initGA();
    const { isLoadingUser } = this.state;
    return (
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          renderEmpty={() => (
            <div className="select-empty">
              <span>Nothing to see here</span>
              <SentimentNeg />
            </div>
          )}
        >
          <ReactQueryDevtools initialIsOpen={false} />
          <Main isLoadingUser={isLoadingUser} />
          <Toaster toastOptions={toasterConfig} />
        </ConfigProvider>
      </QueryClientProvider>
    );
  }
}
export default App;
