import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import LNCSelect from '../../../../../Select/LNCSelect';

export default function EmailTimePicker({ utcDiff }) {
  const { values: { hour }, setFieldValue } = useFormikContext();
  const onSelect = useCallback((newVal) => {
    setFieldValue('hour', newVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const values = useMemo(() => Array(24).fill(null).map((x, ind) => ({
    key: String(ind),
    label: ind < 9 ? `0${ind}:00` : `${ind}:00`,
  })), []);
  return (
    <div className="email-group">
      <label>At</label>
      <div className="email-group_field">
        <LNCSelect
          value={hour}
          onSelect={onSelect}
          placeholder="Select"
          items={values}
        />
      </div>
    </div>
  );
}
