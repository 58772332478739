import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { observe, toJS } from 'mobx';
import { Route, Redirect, Switch } from 'react-router-dom';
import DashboardContainer from '../Screens/Home/HomeContainer';
import LoginView from '../Screens/Login/LoginView';
import Onboarding from '../Components/OnboardingNew/container';
import TwitterCredentialsFetch from '../Integrations/Twitter/twitterCredentialsFetch';
import Slack from '../Integrations/Slack/slack';
import ResetPasswordView from '../Screens/Login/ResetPassword/resetPasswordModal';
import Dal from '../Data/Dal';
import AfterIntegration from '../Screens/AfterIntegration';
import { AnalyticsService } from '../config/analytics';
import useQueriesQueries from '../Components/Queries/hooks/useQueriesQueries';
import usePlaybooksQueries from '../Components/Playbooks/hooks/usePlaybooksQueries';

function ReactRouter({ isLoadingUser }) {
  /* PREFETCHES: */
  useQueriesQueries();
  usePlaybooksQueries();

  useEffect(() => {
    window.__isLNC = false;
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'prod') { // && process.env.REACT_APP_ENV !== 'dev') {
      const identifyToOR = (identifier) => {
        window.__isLNC = identifier.includes('loudnclear.ai') || identifier.includes('simpoco.io');
        const inter = setInterval(() => {
          if (window.OpenReplay) {
            window.OpenReplay.setUserID(identifier);
            clearInterval(inter);
          }
        }, 5000);
      };
      const disposer = observe(Dal, 'user', (c) => {
        const user = toJS(c.newValue);
        if (user && user.email) {
          identifyToOR(`${user.email}`);
        }
      });
      return disposer;
    }
    return () => null; // eslint-disable-line
  }, []);

  useEffect(() => {
    if (!isLoadingUser) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('source')) {
        AnalyticsService.outerLink(params.get('source'), window.location.href);
      }
    }
  }, [isLoadingUser]);

  if (isLoadingUser) {
    return (
      <></>
    );
  }
  return (
    <>
      {Dal.isLoggedIn ? (
        <Switch>
          {/* im changing for InviteComponentSwitcher */}
          {/* <Route exact path="/onboarding/join" component={LoginFromInviteView} /> */}

          {/* <Route exact path="/onboarding-info" component={OnboardingInfo} /> */}
          {/* <Route exact path="/onboarding-payment" component={OnboardingPayment} /> */}
          {/* <Route exact path="/home" component={Home} /> */}
          {/* <Route exact path="/integration" component={FbIntegration} /> */}
          {/* <Route exact path="/facebook/optin" component={FacebookOptInScreen} />; */}
          <Route exact path="/twitter" component={TwitterCredentialsFetch} />
          <Route path="/dashboard" component={DashboardContainer} />
          <Route path="/slack" component={Slack} />
          <Route path="/reset" component={ResetPasswordView} />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/afterIntegration/:service" component={AfterIntegration} />
          <Route path="/">
            <Redirect to="/dashboard/queries" />
          </Route>
          <Route path="/dashboard/home">
            <Redirect to="/dashboard/queries" />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/twitter" component={TwitterCredentialsFetch} />
          <Route path="/reset" component={ResetPasswordView} />
          <Route path="/signin" component={LoginView} />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/afterIntegration/:service" component={AfterIntegration} />
          <Route path="/">
            <Redirect to="/signin" />
          </Route>
        </Switch>
      )}

    </>
  );
}

export default observer(ReactRouter);
