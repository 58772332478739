const CUT_FROM_BEGINNING = 40;
const CUT_FROM_END = 40;

const keywordsSlicer = ({
  fullText, markedByIds, dictionaryKeywords, shouldCut = true,
}) => {
  const relevantKeywords = Object.keys(markedByIds).reduce((acc, dictId) => {
    if (dictionaryKeywords[dictId]) {
      markedByIds[dictId].forEach((kwId) => {
        if (dictionaryKeywords[dictId][kwId] && fullText.toLowerCase().indexOf(dictionaryKeywords[dictId][kwId].keyword.toLowerCase()) !== -1) {
          acc.push({ ...dictionaryKeywords[dictId][kwId], dictId: Number(dictId) });
        }
      });
    }
    return acc;
  }, []);
  const positions = (() => {
    if (relevantKeywords.length !== 0) {
      const basePositions = {
        start: fullText.toLowerCase().indexOf(relevantKeywords[0].keyword.toLowerCase()),
        end: fullText.toLowerCase().indexOf(relevantKeywords[0].keyword.toLowerCase()) + relevantKeywords[0].keyword.length,
      };
      const baseSlicedText = shouldCut ? fullText.slice(
        Math.max(0, basePositions.start - CUT_FROM_BEGINNING),
        basePositions.end + CUT_FROM_END,
      ) : fullText;
      return {
        text: baseSlicedText,
        isBeginning: shouldCut ? Math.max(0, basePositions.start - CUT_FROM_BEGINNING) === 0 : true,
        isEnd: shouldCut ? basePositions.end + CUT_FROM_END >= fullText.length : true,
        highlights: relevantKeywords.reduce((acc, cur) => {
          const startPos = baseSlicedText.toLowerCase().indexOf(cur.keyword.toLowerCase());
          if (startPos !== -1) {
            return [...acc, { dictId: cur.dictId, pos: [startPos, startPos + cur.keyword.length] }];
          }
          return acc;
        }, []).sort((a, b) => a.pos[0] - b.pos[0]),
      };
    }
    return {
      text: shouldCut ? `${fullText.slice(0, 80)}${fullText.length > 80 ? '...' : ''}` : fullText,
      isBeginning: true,
      isEnd: shouldCut ? fullText.length <= 80 : true,
      highlights: [],
    };
  })();

  const slices = (() => {
    if (positions.highlights.length > 0) {
      return positions.highlights.reduce((acc, cur, ind) => {
        const toPush = [];
        if (ind === 0) {
          if (!positions.isBeginning) toPush.push('...'); // that means the sentence isn't the beginning of the compelte value
        }
        // Push the sentence before the word
        toPush.push(positions.text.slice(positions.highlights?.[ind - 1] ? positions.highlights[ind - 1].pos[1] : 0, cur.pos[0]));

        // Push the word
        toPush.push({ keyword: positions.text.slice(cur.pos[0], cur.pos[1]), dictId: cur.dictId });

        if (ind === positions.highlights.length - 1) {
          // Push the sentence after the last word:
          const sentence = positions.text.slice(cur.pos[1]);
          if (sentence) toPush.push(sentence);
          if (!positions.isEnd) toPush.push('...'); // that means the sentence isn't the end of the complete value
        }
        return [...acc, ...toPush];
      }, []);
    }
    return [positions.text];
  })();

  return slices;
};

export default keywordsSlicer;
