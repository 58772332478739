import { Select as AntSelect } from 'antd';
import { useFormikContext } from 'formik';
import { forwardRef, useMemo } from 'react';
import _ from 'underscore';
import { ReactComponent as Suffix } from '../../../Assets/Images/icons/automation/menusuffix.svg';
import ErrorTooltip from './errorTooltip';

const has = (obj, str) => {
  const steps = str.split('.');
  return steps.reduce((acc, cur) => acc?.[cur], obj);
};

const Select = forwardRef((props, ref) => {
  const { errors, values } = useFormikContext();
  const hasError = useMemo(() => values.hasSubmitted && has(errors, props.field), [props.field, errors, values]);
  return (
    <span className="at-select-wrapper">
      <ErrorTooltip isShown={hasError} text={hasError} />
      <AntSelect
        ref={ref}
        suffixIcon={<Suffix />}
        showSearch
        dropdownMatchSelectWidth={false}
        {...props}
        className={`at-select ${props.className ? props.className : ''}`}
        dropdownClassName="at-select-dropdown"
      >
        {props.children}
      </AntSelect>
    </span>
  );
});

Select.Option = forwardRef((props, ref) => (
  <AntSelect.Option
    ref={ref}
    {...props}
    className={`at-select_option ${props.className ? props.className : ''}`}
  >
    {props.children}
  </AntSelect.Option>
));

export default Select;
