import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { ReactComponent as PlusCircle } from '../../../../Assets/Images/icons/plus-circ.svg';
import { ReactComponent as Down } from '../../../../Assets/Images/icons/con-down.svg';
import { ReactComponent as Cog } from '../../../../Assets/Images/icons/playbooks/playbooks-cog.svg';
import { ReactComponent as Delete } from '../../../../Assets/Images/icons/playbooks/playbooks-delete.svg';
import useHover from '../../../../Utils/useHover';

export default function Box({
  placeholderTitle,
  placeholderDesc,
  onClick,
  placeholderIcon,
  isDisabled,
  activePayload, // { title, desc, icon, bgColor },
  onEditClick,
  onDeleteClick,
}) {
  const [addVisible, ref] = useHover();
  return (
    <div ref={ref} onClick={() => onClick && !activePayload ? onClick() : null} className={`sbox ${activePayload ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}>
      <div className="sbox-outer" />
      <div className="sbox-arrow">
        <Down />
      </div>
      <div className="sbox-icon" style={activePayload ? { backgroundColor: activePayload.bgColor } : {}}>
        {activePayload ? activePayload.icon : placeholderIcon}
      </div>
      {activePayload ? (
        <div className="sbox-inner">
          <div className="sbox-title">
            <div className="sbox-title_text">{activePayload.title}</div>
            <div className="sbox-title_actions">
              <div onClick={onEditClick}><Cog /></div>
              <div onClick={onDeleteClick}><Delete /></div>
            </div>
          </div>
          <div className="sbox-desc">
            {activePayload.desc}
          </div>
        </div>
      ) : (
        <div className="sbox-inner">
          <div className="sbox-title">{placeholderTitle}</div>
          <div className="sbox-desc">{placeholderDesc}</div>
          <AnimatePresence>
            {addVisible && (
              <motion.div
                className="sbox-add"
                initial={{
                  height: 0, overflow: 'hidden', scale: 0, rotate: 120,
                }}
                animate={{ height: '50px', scale: 1, rotate: 0 }}
                transition={{ duration: 0.4 }}
                exit={{
                  height: 0, overflow: 'hidden', rotate: 120,
                }}
              >
                <PlusCircle />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </div>
  );
}
