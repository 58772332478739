/* eslint-disable no-nested-ternary */
import { Input } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import defaultIntegrations from '../../../../Data/integrations';
import { useCommunityContext } from '../../../../Utils/contexts/community';
import { OPERATOR_QUANT_PERCENTAGE_OPTIONS, OPERATOR_QUANT_NUMBER_OPTIONS } from './_config';

export default function QuantificationTemplate({
  value, onChange, setValueByKey, getValueByKey,
}) {
  const [input, setInput] = useState(value || '');
  const [isFocus, setFocus] = useState(false);
  const { customFields } = useCommunityContext();
  const operator = getValueByKey('operator');
  const isPercentageCapable = useMemo(() => OPERATOR_QUANT_PERCENTAGE_OPTIONS.includes(operator), [operator]);
  const isNumberCapable = useMemo(() => OPERATOR_QUANT_NUMBER_OPTIONS.includes(operator), [operator]);
  const contactFields = useMemo(() => Object.values(customFields).filter((cf) => cf.data_type === 'contacts' && cf.field_type === 'int').map((x) => ({
    ...x,
    icon: defaultIntegrations.find((a) => a.targetTag === (x.service_name === 's3' ? 'csv' : x.service_name))?.image,
  })).filter((cf) => {
    if (input && !Number(input)) {
      return cf.label.toLowerCase().includes(input.toLowerCase());
    }
    return true;
  }), [customFields, input]);
  const showDropdown = useMemo(() => {
    if (contactFields.length === 0) return false;
    if (input !== '' && !Number(input) && isFocus) return true;
    if (input === '' && isNumberCapable && isFocus) return true;
    return false;
  }, [input, isNumberCapable, isFocus, contactFields]);
  const handleFocus = useCallback(() => {
    // if empty, just open the dropdown (when applicable)
    // if not empty and is custom field, select all text and remove
    if (input && !Number(input)) {
      setInput('');
    }
    setFocus(true);
  }, [input]);
  useEffect(() => {
    onChange(input);
    setValueByKey('threshold', Number.isNaN(Number(input)) ? input : null);
  }, [input]);
  return (
    <div className="quant">
      <Input value={input} onFocus={handleFocus} onBlur={() => setFocus(false)} onChange={(e) => setInput(e.target.value)} />
      <span className="quant-quantifier">
        {(!!Number(input) || input === '') ? (
          isPercentageCapable ? '%' : '#'
        ) : (
          ''
        )}
      </span>
      <AnimatePresence>
        {showDropdown && (
          <motion.div
            className="quant-dropdown"
            initial={{ scale: 0.7, y: '100%', opacity: 0 }}
            animate={{ scale: 1, y: '100%', opacity: 1 }}
            exit={{ opacity: 0, y: '100%' }}
          >
            <div className="quant-dropdown_info">
              Enter a fixed number or select a threshold field
            </div>
            <div className="quant-dropdown_list">
              {contactFields.map((x) => (
                <div onClick={() => setInput(x.label)} className="quant-dropdown_list_item" key={x.id}>
                  <img src={x.icon} width="18" height="18" alt="" />
                  {x.label}
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
