/* eslint-disable jsx-a11y/alt-text */
import { useMemo } from 'react';
import _values, { _defValueByType } from '../config/_values';
import PartWrapper from '../common/partWrapper';

export default function ValuePart({
  name,
  onSelect,
  value: { selectedField, selectedOperator, value },
}) {
  const Template = useMemo(() => {
    const byField = _values?.[selectedField?.key]
      || _values?.[`_default_${selectedField?.type}`]
      || _values._default;
    const byOperator = byField?.[selectedOperator] || byField._any;
    return byOperator.Template;
  }, [selectedField, selectedOperator]);
  const valueWithDefault = value || _defValueByType?.[selectedField?.type];
  return (
    <PartWrapper name={name} partName="value">
      <Template onSelect={onSelect} value={{ operator: selectedOperator, selectedField, value: valueWithDefault }} />
    </PartWrapper>
  );
}
