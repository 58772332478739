import SegmentCommonTemplate from './common/segment';

const segmentContactAddTrigger = {
  id: 1, // IDS ARE EQUIVALENT TO SQL
  entity: 'contact',
  action: 'add',
  relation: 'segment',
  acceptedMetadata: ['segment_id'],
  validationSchema: SegmentCommonTemplate.validationSchema,
  templates: {
    Display: ({ isExample, metadata }) => (
      <span className="autotext-inner">
        When a new contact is added to
        {' '}
        <SegmentCommonTemplate.Display isExample={isExample} metadata={metadata} />
      </span>
    ),
    Edit: ({ metadata }) => (
      <span className="autotext-inner trigger automation-edit-container">
        <span className="highlight">When</span>
        {' '}
        a new contact is added to
        {' '}
        <SegmentCommonTemplate.Edit metadata={metadata} />
      </span>
    ),
  },
};

export default segmentContactAddTrigger;
