import { ReactComponent as IconEmptyState } from '../../../../Assets/Images/icons/queries/iconEmptyState.svg';

export default function QueryEmptyState() {
  return (
    <div className="queries-results-wrapper">
      <div className="animation-container">
        <IconEmptyState />
      </div>
      <div className="loading-title">No matching results</div>
      <div className="loading-subtitle">try a different filter option</div>
    </div>
  );
}
