import { memo } from 'react';
import firstImg from '../../../../Assets/Images/onboarding_sider/4/globe.png';

const ReferralsSider = memo(
  () => (
    <div className="referrals">
      <div className="sider-content">
        <img src={firstImg} width="424" height="402" alt="Referrals details" />
        <div className="sidertext">
          Your customers are interacting everywhere, let&apos;s give them the
          attention they are looking for. No code. No hassle.
        </div>
      </div>
    </div>
  ),
  () => true,
);

export default ReferralsSider;
