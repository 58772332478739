import { motion } from 'framer-motion';
import { ReactComponent as ExcelIcon } from '../../../../../Assets/Images/icons/excel.svg';
import VIcon from '../../../../../Assets/Images/icons/csv-v-icon.svg';
import XIcon from '../../../../../Assets/Images/icons/csv-x-icon.svg';

export default function PhaseTwoLoad({ result, fileName, errorText }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="step-upload_loader"
    >
      <div className="step-upload_loader_spinner">
        <div className="theSpinner-outer">
          <div className="theSpinner" />
          <div className="inner">
            {result !== 'loading' && (<img src={result === 'success' ? VIcon : XIcon} width="40" height="40" alt="success" />)}
          </div>
        </div>
      </div>
      <div className="step-upload_loader_name">
        <ExcelIcon />
        <div className="fileName">
          {fileName}
        </div>
        {errorText && (
          <div className="errorText">{errorText}</div>
        )}
      </div>
    </motion.div>
  );
}
