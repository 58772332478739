import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DictionaryTable from '../../table';
import WidgetsPage from '../../widgets';
import DictionaryHeading from './heading';
import ErrorBoundary from '../../../ErrorBoundary/errorBoundary';

export default function DictionaryPage() {
  const { dictId } = useParams();
  const [view, setView] = useState(0); // 0 - table, 1 - widgets
  const [superFilter, setSuperFilter] = useState(null);
  useLayoutEffect(() => {
    setSuperFilter(null);
  }, [dictId]);
  return (
    <div className={`dictionary-page ${view === 0 ? 'page-table' : 'page-widgets'}`}>
      <div className="dictionary-heading">
        <DictionaryHeading
          dictId={dictId}
          view={view}
          setView={setView}
          filter={superFilter}
          setSuperFilter={setSuperFilter}
        />
      </div>
      <div className="dictionary-innerpage">
        {view === 0 ? (
          <ErrorBoundary additionalInfo={{ superFilter, dictId }} componentName="DictionaryTable">
            <DictionaryTable filter={superFilter} dictId={dictId} />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary additionalInfo={{ dictId }} componentName="DictionaryWidgets">
            <WidgetsPage dictId={dictId} />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
}
