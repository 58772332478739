import { makeAutoObservable, runInAction, toJS } from 'mobx';
import Dal from '../../../../Data/Dal';
import NetworkManager from '../../../../Network/NetworkManager';

/* eslint-disable arrow-body-style */
class CrmDrawerHandler {
  constructor() {
    this.userData = null;
    this.id = null;
    this.isShown = false;
    this.activitiesCollapsed = true;
    this.hasAccount = false;
    this.isUserNotFound = false;
    this.isLoading = {
      userDetails: true,
      sentiment: true,
      activities: true,
    };
    this.data = {
      userDetails: {},
      sentiment: {},
      activities: [],
    };
    this.hooks = {};
    makeAutoObservable(this);
  }

  openDrawer = ({ id, segmentId }) => {
    runInAction(() => {
      this.resetLoadings();
      this.resetData();
      this.id = id;
      this.isShown = true;
      this.data.userDetails = {};
      // this.hasAccount = !!(userData?.linked_accounts || []).find((la) => la?.id && la?.name);
      this.fetchUserDetails(segmentId);
      this.fetchSentiment();
    });
  };

  bindHook = (hookName, fn) => {
    this.hooks[hookName] = fn;
  };

  close = () => {
    runInAction(() => {
      this.isShown = false;
      this.hasAccount = false;
      setTimeout(() => {
        this.data.userDetails = {};
      }, 300);
    });
  };

  resetLoadings = () => {
    runInAction(() => {
      this.isLoading = {
        userDetails: true,
        sentiment: true,
        activities: true,
      };
    });
  };

  resetData = () => {
    runInAction(() => {
      this.hasAccount = false;
      this.data = {
        userDetails: {},
        sentiment: {},
        activities: [],
      };
    });
  };

  updateTagLocally = async (action, tagPayload) => {
    runInAction(() => {
      this.data.userDetails.tags = action === 'REMOVE' ? this.data.userDetails.tags.filter((f) => f !== tagPayload.id) : [...this.data.userDetails.tags, tagPayload.id];
      this.hooks.updateRow(this.id, { tags: this.data.userDetails.tags });
    });
  };
  fetchUserDetails = async (segId) => {
    runInAction(() => {
      this.data.userDetails = {};
      this.isUserNotFound = false;
    });
    const details = await NetworkManager.getMemberDetails(this.id, segId);
    runInAction(() => {
      this.data.userDetails = details;
      this.hasAccount = !!(details?.linked_accounts || []).find((la) => la?.id && la?.name);
      this.isLoading.userDetails = false;
      this.isUserNotFound = Object.keys(details || {}).length === 0;
    });
    return this.data.userDetails;
  };

  fetchSentiment = async () => {
    runInAction(() => {
      this.data.sentiment = {
        overall_emotion_name: 'NEUTRAL', // || 'POSITIVE' || 'NEGATIVE',
        overall_emotion_percentage: 0,
        total_interactions: 0,
      };
    });
    const senti = await NetworkManager.getMemberSentiment(this.id);
    runInAction(() => {
      this.data.sentiment = senti;
      this.isLoading.sentiment = false;
    });
    return this.data.sentiment;
  };

  remapKeywordsToLocalSchema = () => {
    return Object.keys(this.data.userDetails.top_keywords || {}).map((k) => ({
      id: k,
      label: Dal.allKeywords[k]?.label || '',
      count: this.data.userDetails.top_keywords[k],
      color: {
        bgColor: Dal.allKeywords[k]?.bgColor || '#000',
        textColor: Dal.allKeywords[k]?.textColor || '#fff',
      },
    })).filter((a) => !!a.label).sort((a, b) => b.count - a.count);
  };

  toggleActivityCollapse = (actId, to) => {
    runInAction(() => {
      const ac = this.data.activities.find((a) => a.id === actId);
      ac.collapsed = to ?? !ac.collapsed;
    });
  };

  toggleAllCollapse = () => {
    runInAction(() => {
      this.data.activities.forEach((item) => this.toggleActivityCollapse(item.id, !this.activitiesCollapsed));
      this.activitiesCollapsed = !this.activitiesCollapsed;
    });
  };
}

const DrawerHandler = new CrmDrawerHandler();

export default DrawerHandler;
