import { useQuery } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';
import { useCommunityContext } from '../../../Utils/contexts/community';

export default function useQueryEmailsQueries(queryId) {
  const { communityId } = useCommunityContext();
  const emails = useQuery(
    ['queries', queryId, 'emails'],
    () => NetworkManager.getQueryEmails(communityId, { queryId }),
    {
      staleTime: 1000 * 60 * 60,
      enabled: !!queryId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  return {
    emails,
  };
}
