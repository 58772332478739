import { makeAutoObservable, runInAction } from 'mobx';
import schemas from '../_config/index';

const uid = () => Date.now().toString(36) + Math.random().toString(36).substr(2);
const sleep = (t) => new Promise((resolve) => setTimeout(resolve, t)); // eslint-disable-line
class FilterLine {
  constructor(type, superFilter, defaults) {
    const schema = schemas[type];
    this.superFilter = superFilter;
    this.fields = schema.fields.reduce((acc, cur) => {
      acc[cur.key] = defaults?.[cur.key] || cur.defaultValue;
      return acc;
    }, {});
    this.type = type;
    this.status = [null, null]; // ['FIELDKEY', 'MSG']
    this.id = uid();
    this.schema = schemas[type];
    this.autoFilled = Object.keys(defaults).length !== 0;
    this.saved = false;
    this.editMode = true;
    makeAutoObservable(this);
  }

  updateValueByKey = (key, value) => {
    this.saved = false;
    this.fields[key] = value;

    // if changing an errored field, set error to null
    if (this.status[0] === key) this.status = [null, null];

    if (this.type === 'property' && key === 'field') {
      // if selected property field changes, reset value
      this.fields.value = null;
    }
  };

  enableEditMode = () => {
    this.superFilter.lines.forEach((line) => {
      line.editMode = false;
    });
    this.editMode = true;
  };

  validate = () => {
    this.schema.validationSchema.validateSync(this.fields);
    // Will throw error if validation fails ^^^^
    return true;
  };

  delete = () => {
    this.superFilter.removeLine(this.id);
  };

  save = async () => {
    // reset status:
    runInAction(() => {
      this.status = [null, null];
    });
    await sleep(1);
    try {
      this.validate();
      runInAction(() => {
        this.saved = true;
        this.editMode = false;
      });
      return true;
    } catch (err) {
      runInAction(() => {
        this.status = [err.path, err.message];
      });
      return false;
    }
  };
}

export default FilterLine;
