import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as TagIcon } from '../../../../../Assets/Images/icons/drawer-tag-edit.svg';
import DrawerHandler from '../../state/handler';
import TagModal from './modal';

function TagButton() {
  const [isShown, setShown] = useState(false);
  const posRef = useRef();
  const [pos, setPos] = useState({ top: 0, left: 0 });
  useEffect(() => {
    if (posRef.current) {
      const b = posRef.current.getBoundingClientRect();
      setPos({
        top: b.top + 25,
        left: b.left + 15,
      });
    }
  }, [isShown]);
  return (
    <>
      <div ref={posRef} onClick={() => setShown(!isShown)} className="tag-button" style={{ position: 'relative' }}>
        <TagIcon />
      </div>
      <TagModal pos={pos} isShown={isShown} onClose={() => setShown(false)} currentSelected={toJS(DrawerHandler.data.userDetails?.tags) || []} />
    </>

  );
}

export default observer(TagButton);
