import {
  createContext, useContext, useMemo,
} from 'react';
import { useInfiniteQuery } from 'react-query';
import NetworkManager from '../../Network/NetworkManager';
import { useCommunityContext } from '../contexts/community';

const loader = Array(10).fill(null).map((_, ind) => ({
  key: ind, loading: true, names: [], emails: [], phones: [], descriptions: [],
}));
export const CRMContext = createContext({});

export default function useCRM(mode, segmentId) { // This should be moved inside to a state when CRM revamp
  const { communityId } = useCommunityContext();
  const crmHistory = useInfiniteQuery(['crm', 'history', segmentId], ({ pageParam = 0 }) => NetworkManager.getSegmentHistory(communityId, segmentId, pageParam), {
    enabled: mode === 2,
    getNextPageParam: (lastPage) => lastPage.scroll_id || false,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 60,
  });

  const combinedHistoryData = useMemo(() => {
    const currentMembers = crmHistory?.data?.pages?.reduce((acc, cur) => [
      ...acc,
      ...cur.members,
    ], []);
    if (crmHistory.isFetchingNextPage) return [...(currentMembers || []), ...loader];

    if (crmHistory.isFetching) return loader;
    return currentMembers || [];
  }, [crmHistory]);

  return {
    crmHistory,
    combinedHistoryData,
  };
}

export const useCRMContext = () => {
  const crm = useContext(CRMContext);
  return crm;
};
