import { useFormikContext } from 'formik';
import { motion } from 'framer-motion';

const Switch = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <div onClick={() => setFieldValue('isEnabled', !values.isEnabled)} className={`switch ${values.isEnabled ? 'on' : 'off'}`}>
      <motion.div
        className="switch-indicator"
        animate={{ x: values.isEnabled ? '85%' : '-5%', y: '-50%' }}
      />
    </div>
  );
};

export default Switch;
