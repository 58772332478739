import { motion } from 'framer-motion';
import { ReactComponent as CloseIco } from '../../Assets/Images/icons/dashboards/cat-ico_x-close.svg';

export default function Modal({ className, onClose, children }) {
  return (
    <motion.div
      initial={{ y: '100%', x: '-50%', opacity: 0 }}
      animate={{ y: '-50%', x: '-50%', opacity: 1 }}
      exit={{ y: '100%', x: '-50%', opacity: 0 }}
      transition={{ type: 'tween', duration: 0.23, ease: 'easeOut' }}
      className={`lnc-modal ${className}`}
    >
      <div className="lnc-modal_close" onClick={onClose}><CloseIco /></div>
      {children}
    </motion.div>
  );
}
