/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable react/jsx-no-bind */

import {
  Skeleton, Tooltip, Tag,
} from 'antd';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import {
  Table, Column, AutoSizer, InfiniteLoader,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { useHistory } from 'react-router-dom';
import AvatarView from '../../../Components/AvatarView/AvatarView';
import { renderTag } from '../../../Components/RecentActivity/TimelineItem/index';
import Dal from '../../../Data/Dal';
import DrawerHandler from '../Drawer/state/handler';
import { AnalyticsService } from '../../../config/analytics';
import waveIcon from '../../../Assets/Images/icons/wave-ico.png';
import useTagsQuery from '../../../Utils/community/tags/useTagsQuery';

const _ = require('underscore');

function sourceElement(source) {
  const assetTag = source.platform_name + source.platform_type;
  return (
    <span key={`${source.platform_name}-${Math.random()}`} style={{ display: 'inline-block' }}>
      <img
        alt=""
        style={{ width: 24, height: 24 }}
        src={Dal.getAccentIconByAssetTag(assetTag)}
      />
    </span>
  );
}

function recentEvents(events) {
  if (events === undefined) {
    return <></>;
  }
  if (events[0]) {
    return renderTag({
      item: events[0],
    });
  }
  return <></>;
}

function getName(names) {
  if (names !== undefined && names.length > 0) {
    return names[0];
  }
  return 'Member';
}

function sourcesChild(sources) {
  if (sources === undefined) {
    return [];
  }
  const elementToShow = 3;
  const child = sources.slice(0, elementToShow).map((source) => sourceElement(source));
  /* if (sources.length > elementToShow) {
    child.push(tagElement(`+${sources.length - elementToShow}`));
  } */
  return child;
}

function getSegments(events) {
  if (events === undefined) {
    return undefined;
  }
  const elementToShow = 1;
  const child = [];
  events.slice(0, elementToShow).forEach((segmentId) => {
    const segment = Dal.getSegmentById(segmentId);
    if (segment !== undefined) {
      const colorObj = Dal.getSetColorByBgColor(segment.color);
      const { textColor } = colorObj;
      const { bgColor } = colorObj;
      child.push((
        <Tag key={`${events.length}-${Math.random()}`} style={{ color: textColor }} className="crm-segment-tag" color={bgColor}>
          {segment.name}
        </Tag>
      ));
    }
  });
  if (events.length > elementToShow) {
    child.push(
      <Tooltip
        getPopupContainer={() => document.querySelector('.def-list-table')}
        className="segments-tooltip"
        color="white"
        title={(
          <div className="segments-tooltip_list">
            {events.slice(elementToShow).map((seg) => {
              const segment = Dal.getSegmentById(seg);
              if (segment !== undefined) {
                const colorObj = Dal.getSetColorByBgColor(segment.color);
                const { textColor } = colorObj;
                const { bgColor } = colorObj;
                return (
                  <Tag style={{ color: textColor }} className="crm-segment-tag" color={bgColor}>
                    {segment.name}
                  </Tag>
                );
              }
              return <></>;
            })}
          </div>
        )}
      >
        <Tag key={`${events.length}-${Math.random()}`} className="plus-tag" color="rgb(219, 233, 255)">
          +
          {events.length - elementToShow}
        </Tag>
      </Tooltip>,
    );
  }
  return child;
}

const SortableLabel = ({
  sortKey, setSorting, sorting, label, isDisabled,
}) => {
  const handleClick = useCallback(() => {
    setSorting((cur) => ({
      sortBy: sortKey,
      sortDir: cur.sortDir === 'asc' || sortKey !== sorting.sortBy ? 'desc' : 'asc',
    }));
  }, [sorting]); // eslint-disable-line
  return (
    <span className="crm-table-sort-label" onClick={!isDisabled && handleClick}>
      <span>{label}</span>
      {!isDisabled && sorting.sortBy === sortKey && (
        <span>
          {sorting.sortDir === 'desc' ? (
            <CaretDownOutlined />
          ) : (
            <CaretUpOutlined />
          )}
        </span>
      )}
    </span>
  );
};

function CRMViewTable({
  isRowLoaded, loadMoreRows, crmData, searchTerm, sorting, setSorting, localFilterCrmData, colors, markRowAsRead,
  superFilter, isHistory, isSegment,
}) {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [crm, setCrm] = useState(crmData || []);
  const history = useHistory();
  const { tagsById } = useTagsQuery();
  useEffect(() => {
    setCrm(crmData);
  }, [crmData]);
  const onRowClick = useCallback((record) => {
    if (!record.rowData?.loading) {
      markRowAsRead(record.rowData.id);
      runInAction(() => {
        Dal.declementReadCountOnCurrentSegment();
        // DrawerHandler.openDrawer(record.rowData, Dal.selectedCrmSegmentItem?.id);
        // console.log(history);
        history.replace(`${history.location.pathname}?contactId=${record.rowData.id}`);
        DrawerHandler.bindHook('updateRow', (rowId, payload) => {
          setCrm((cur) => {
            const c = [...cur];
            const found = c.find((f) => f.id === rowId);
            Object.assign(found, payload);
            return c;
          });
        });
      });
      const segName = toJS(Dal.selectedCrmSegmentItem)?.name || 'all';
      AnalyticsService.crmDrawerView(segName);
      setForceRefresh(() => Math.random());
    }
  }, [markRowAsRead, history]);

  const superFilterCustomFields = useMemo(() => {
    if (superFilter) {
      return superFilter.filters.reduce((acc, cur) => {
        if (cur.type === 'property' && cur.field?.id) {
          return [...acc, cur.field.label];
        }
        return acc;
      }, []);
    }
    return [];
  }, [superFilter]);

  const shouldFlagMemberInaccurate = useCallback((record) => superFilterCustomFields.reduce((acc, cur) => {
    if (acc) return true;
    const variantFieldArray = [...new Set(record.crm_custom_data_variants?.[cur] || [])];
    const customFieldValue = record.custom_data?.[cur];
    if (!variantFieldArray || variantFieldArray?.length === 0 || !customFieldValue) return false;
    const valuesSet = [...new Set([...variantFieldArray, customFieldValue])];
    if (valuesSet.length > 1) return true;
    return false;
  }, false), [superFilterCustomFields]);

  const getRowItem = useCallback((index) => {
    const keyG = (d) => {
      if (d?.id) {
        return `${d.id}-${d.names?.[0]}-${Math.random()}`;
      }
      return `${index}-${Math.random()}`;
    };
    const mapper = (data) => ({ ...data, key: keyG(data) });
    const final = searchTerm === undefined ? mapper(crm[index]) : mapper(localFilterCrmData[index]);
    return final;
  }, [searchTerm, crm, localFilterCrmData]);

  const getRowClassName = useCallback((row) => {
    if (crmData === undefined || row.index >= crmData.length || row.index === -1) {
      return 'table-row table-row-light';
    }
    if ((DrawerHandler.userData && (getRowItem(row.index)?.id === DrawerHandler.userData?.id))) {
      if (crmData[row.index].is_read === false) {
        return 'table-row table-row-in-focus';
      } return 'table-row table-row-in-focus new';
    }
    if (crmData[row.index].is_read === false) {
      return 'table-row table-row-new';
    }

    return 'table-row table-row-light';
  }, [crmData, getRowItem]);
  return (
    <div className="crmViewTable-scroll" style={{ flexGrow: '1', overflowX: window.innerWidth < 1730 ? 'scroll' : 'none', padding: '0 40px' }}>
      <InfiniteLoader
        rowCount={1000000}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        minimumBatchSize={100}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height: h }) => (
              <Table
                style={{ borderWidth: '1px', borderColor: '#aaaaaa' }}
                headerClassName="crm-table-header"
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowClassName={(row) => getRowClassName(row)}
                headerHeight={50}
                width={1360}
                height={h - 72}
                rowHeight={window.innerWidth <= 1366 ? 40 : 50}
                className="def-list-table crm-table"
                onRowClick={onRowClick}
                rowCount={searchTerm === undefined ? crm.length : localFilterCrmData.length}
                rowGetter={({ index }) => getRowItem(index)}
              >
                <Column
                  label="Name"
                  dataKey="names"
                  headerClassName="column-header-text"
                  width={260}
                  minWidth={180}
                  cellRenderer={(cellData) => {
                    const content = (
                      <AvatarView
                        id={cellData?.rowData?.id}
                        name={getName(cellData?.rowData?.names)}
                        isUnread={cellData?.rowData?.is_read === false}
                        hasFlag={shouldFlagMemberInaccurate(cellData?.rowData)}
                        loading={cellData?.rowData?.loading !== undefined}
                      />
                    );
                    return (
                      <Skeleton
                        active
                        paragraph={false}
                        title={{ width: 90 }}
                        loading={cellData?.rowData?.loading !== undefined}
                      >
                        <div className="row-hover-block" style={{ backgroundColor: colors?.textColor || 'rgb(0 132 255)' }} />
                        {cellData?.rowData?.entered_timestamp && (
                          moment().diff(moment(cellData.rowData.entered_timestamp), 'hours') <= 24 && (
                            <div className="row-wave-icon">
                              <img src={waveIcon} width="18" height="18" alt="" />
                            </div>
                          )
                        )}
                        <Tooltip getPopupContainer={() => document.querySelector('.def-list-table')} mouseEnterDelay={0.4} color="white" title={content}>
                          <div style={{ width: '100%' }} className="ellipsis">
                            {content}
                          </div>
                        </Tooltip>
                      </Skeleton>
                    );
                  }}
                />
                <Column
                  label={<SortableLabel sortKey="latest_activity_time" isDisabled={isHistory} label={isHistory ? 'Exit time' : 'Last Activity Time'} sorting={sorting} setSorting={setSorting} />}
                  dataKey="latest_activity_time"
                  headerClassName="column-header-text"
                  className="added_column"
                  width={150}
                  minWidth={140}
                  cellRenderer={(cellData) => (
                    <Skeleton
                      active
                      paragraph={false}
                      title={{ width: 45 }}
                      loading={cellData?.rowData?.loading !== undefined}
                    >
                      <div className="match_list_date_text">
                        {/* eslint-disable-next-line max-len */}
                        {isHistory ? (
                          cellData?.rowData?.exit_timestamp && cellData?.rowData?.exit_timestamp !== 'None' ? moment(cellData?.rowData?.exit_timestamp).format('MMM DD YYYY, HH:mm') : ''
                        ) : (
                          cellData?.rowData?.latest_activity_time && cellData?.rowData?.latest_activity_time !== 'None' ? moment(cellData?.rowData?.latest_activity_time).format('MMM DD YYYY, HH:mm') : ''
                        )}
                      </div>
                    </Skeleton>
                  )}
                />
                {!isHistory && isSegment && (
                  <Column
                    label={<SortableLabel sortKey="entered_timestamp" label="Added date" sorting={sorting} setSorting={setSorting} />}
                    dataKey="entered_timestamp"
                    headerClassName="column-header-text"
                    className="added_column"
                    width={150}
                    minWidth={140}
                    cellRenderer={(cellData) => (
                      <Skeleton
                        active
                        paragraph={false}
                        title={{ width: 45 }}
                        loading={cellData?.rowData?.loading !== undefined}
                      >
                        <div className="match_list_date_text">
                          {/* eslint-disable-next-line max-len */}
                          {cellData?.rowData?.entered_timestamp && cellData?.rowData?.entered_timestamp !== 'None' ? moment(cellData?.rowData?.entered_timestamp).format('MMM DD YYYY, HH:mm') : ''}
                        </div>
                      </Skeleton>
                    )}
                  />
                )}
                <Column
                  label="Platforms"
                  dataKey="sources"
                  headerClassName="column-header-text"
                  width={140}
                  // maxWidth={140}
                  minWidth={140}
                  cellRenderer={(cellData) => (
                    <Skeleton
                      active
                      paragraph={false}
                      title={{ width: 90 }}
                      loading={cellData?.rowData?.loading !== undefined}
                    >
                      <div className="platforms-wrapper">
                        {sourcesChild(cellData?.rowData?.sources)}
                      </div>
                    </Skeleton>
                  )}
                />
                <Column
                  label="Account"
                  dataKey="account"
                  headerClassName="column-header-text"
                  className="segments_column"
                  width={207}
                  cellRenderer={(cellData) => {
                    const content = (
                      cellData?.rowData?.linked_accounts?.[0]?.name ? (
                        <AvatarView
                          id={cellData?.rowData?.linked_accounts?.[0]?.id}
                          name={cellData?.rowData?.linked_accounts?.[0]?.name}
                          isUnread={cellData?.rowData?.is_read === false}
                          loading={cellData?.rowData?.loading !== undefined}
                        />
                      ) : <></>
                    );
                    return (
                      <Skeleton active paragraph={false} title={{ width: 90 }} loading={cellData?.rowData?.loading !== undefined}>
                        {cellData?.rowData?.linked_accounts?.[0] && cellData?.rowData?.linked_accounts?.[0]?.name
                          ? (
                            <Tooltip getPopupContainer={() => document.querySelector('.def-list-table')} mouseEnterDelay={0.4} color="white" title={content}>
                              <div style={{ width: '100%' }} className="ellipsis">
                                {content}
                              </div>
                            </Tooltip>
                          )
                          : null}
                      </Skeleton>
                    );
                  }}
                />
                <Column
                  label="Tags"
                  dataKey="tags"
                  headerClassName="column-header-text"
                  className="recent_events_column"
                  width={207}
                  cellRenderer={(cellData) => (
                    <Skeleton
                      active
                      paragraph={false}
                      title={{ width: 90 }}
                      loading={cellData?.rowData?.loading !== undefined}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {cellData?.rowData?.tags && cellData?.rowData?.tags.filter((t) => !!tagsById?.[t]).slice(0, 2).map((t) => (
                          <Tag className="table-tag">
                            {(tagsById[t]?.label || '').toUpperCase()}
                          </Tag>
                        ))}
                        {cellData?.rowData?.tags && cellData?.rowData?.tags.length > 2 && (
                          <Tag className="table-tag">
                            {`+${cellData.rowData.tags.length - 2}`}
                          </Tag>
                        )}
                      </div>
                    </Skeleton>
                  )}
                />
                <Column
                  label="Segment"
                  dataKey="activity"
                  headerClassName="column-header-text"
                  className="segments_column"
                  width={207}
                  cellRenderer={(cellData) => (
                    <Skeleton
                      active
                      paragraph={false}
                      title={{ width: 90 }}
                      loading={cellData?.rowData?.loading !== undefined}
                    >
                      <div className="segments-flex">
                        {getSegments(cellData?.rowData?.segments?.map((d) => Number(d)), cellData?.rowData?.is_read)}
                      </div>
                    </Skeleton>
                  )}
                />
                <Column
                  label="Assigned employee"
                  dataKey="owner"
                  headerClassName="column-header-text"
                  width={197}
                  cellRenderer={(cellData) => {
                    const content = (
                      cellData?.rowData?.assigned_employees?.[0]?.name
                        ? (
                          <AvatarView
                            id={cellData?.rowData?.assigned_employees?.[0]?.id}
                            name={cellData?.rowData?.assigned_employees?.[0].name}
                            isUnread={cellData?.rowData?.is_read === false}
                            loading={cellData?.rowData?.loading !== undefined}
                          />
                        ) : <></>
                    );
                    return (
                      <Skeleton active paragraph={false} title={{ width: 90 }} loading={cellData?.rowData?.loading !== undefined}>
                        {cellData?.rowData?.assigned_employees?.[0] && cellData?.rowData?.assigned_employees?.[0]?.name
                          ? (
                            <Tooltip getPopupContainer={() => document.querySelector('.def-list-table')} mouseEnterDelay={0.4} color="white" title={content}>
                              <div style={{ width: '100%' }} className="ellipsis">
                                {content}
                              </div>
                            </Tooltip>

                          )
                          : null}
                      </Skeleton>
                    );
                  }}
                />
                <Column
                  label="Recent Events"
                  dataKey="activity"
                  headerClassName="column-header-text"
                  className="recent_events_column"
                  width={257}
                  cellRenderer={(cellData) => (
                    <Skeleton
                      active
                      paragraph={false}
                      title={{ width: 90 }}
                      loading={cellData?.rowData?.loading !== undefined}
                    >
                      <div className="recentEvents">
                        {recentEvents(cellData?.rowData?.activity, cellData?.rowData?.is_read)}
                      </div>
                      <div style={{ display: 'none' }}>{forceRefresh}</div>
                    </Skeleton>
                  )}
                />
              </Table>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </div>
  );
}

export default observer(CRMViewTable);
