const seriesTransformer = (all) => all.map((series) => ({
  ...series,
  data: series.data.map((d) => {
    const final = {};
    if (d.name === 'NEGATIVE') final.color = '#FF4D4D';
    if (d.name === 'NEUTRAL') final.color = '#F7B32A';
    if (d.name === 'POSITIVE') final.color = '#01C784';
    if (d.name === 'MIXED') final.color = '#A0B7BF';
    return {
      ...d,
      ...final,
    };
  }),
}));

export default seriesTransformer;
