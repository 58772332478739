import React from 'react';
import { Redirect } from 'react-router-dom';

import { makeObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import Dal from '../../Data/Dal';
import TwitterDal from './twitterDal';
import config from '../../config/config.json';

class TwitterCredenialsFetch extends React.Component {
  shouldRedirect = false;
  wasAccessDenied = false;

  constructor() {
    super();

    makeObservable(this, {
      shouldRedirect: observable,
      wasAccessDenied: observable,
    });
    this.checkCredentials();
  }

  async checkCredentials() {
    await Dal.refreshCommunities();
    const params = new URLSearchParams(window.location.search);
    const token = params.get('oauth_token');
    const verifier = params.get('oauth_verifier');
    if (token && verifier) {
      const url = `${process.env.REACT_APP_NOVAK_BASE_URI}${
        config.NovakURLs.integrations.twitter
      }?oauth_token=${token}&oauth_verifier=${verifier}&community_id=${Dal.getCurrentCommunityId()}`;
      fetch(url, { method: 'GET' }).then((response) => {
        if (response.ok) {
          response.json().then((data) => this.setChannelsData(data));
        } else {
          alert('Error occurred');
        }
      });
    } else {
      console.log('denied');
      runInAction(() => { this.wasAccessDenied = true; });
      runInAction(() => { this.shouldRedirect = true; });
    }
  }

  setChannelsData(data) {
    const token = data?.token;
    const secret = data?.secret;
    const channels = data?.channels;
    channels.forEach((ch) => (ch.isSelected = false));
    runInAction(
      () => (TwitterDal.token = token),
      (TwitterDal.secret = secret),
      (TwitterDal.channels = channels),
      (TwitterDal.step = 2),
      (this.shouldRedirect = true),
    );
  }

  getURL() {
    return this.wasAccessDenied
      ? '/dashboard/integrations'
      : '/dashboard/integrations?twitter=true';
  }

  render() {
    return this.shouldRedirect ? <Redirect to={this.getURL()} /> : null;
  }
}

export default observer(TwitterCredenialsFetch);
