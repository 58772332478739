import { Modal, Button } from 'antd';
import { useState, useEffect } from 'react';

import './style.scss';
import bg from '../../Assets/Images/Bg/premiummodal.png';
import icon from '../../Assets/Images/icons/premium-ico.svg';
import success from '../../Assets/Images/icons/post-submit-success.svg';

export default function PremiumModal() {
  const [step, setStep] = useState(0);
  const [isShown, setShown] = useState(false);
  const setClosed = () => {
    setShown(false);
  };
  useEffect(() => {
    window.__premiumModal = {};
    window.__premiumModal.show = () => setShown(true);
    window.__premiumModal.hide = () => setShown(false);
  }, []);
  return (
    <Modal
      footer={null}
      visible={isShown}
      closable
      className="premium_modal"
      onCancel={setClosed}
    >
      <div className="inner">
        <div className="main">
          {step === 0 ? (
            <>
              <div className="main-header">
                <div>
                  <img src={icon} width="80" height="80" alt="premium" />
                </div>
                <div className="text">Relationship goals: Go Next Level!</div>
              </div>
              <p>
                The functionality you ask for is not a part of our "Free trial"
                package.
              </p>
              <p>
                We are here to give you the superpowers to control any customer
                interaction the way you want it! Get free consultaion &
                onboarding meeting with our experts :)
              </p>
              <Button
                type="primary"
                id="premium_btn"
                onClick={() => setStep(1)}
              >
                I want to know more!
              </Button>
            </>
          ) : (
            <>
              <div className="completed">
                <img src={success} width="80" height="80" alt="success" />
                <div className="main-header">
                  <div className="text" style={{ paddingLeft: '0 !important' }}>
                    Thanks!
                  </div>
                </div>
                <p>We'll reach out soon.</p>
                <div
                  className="close_btn"
                  onClick={() => {
                    setStep(0);
                    setClosed();
                  }}
                >
                  Close
                </div>
              </div>
            </>
          )}
        </div>
        <div className="img">
          <img src={bg} width="410" height="490" alt="premium feature" />
        </div>
      </div>
    </Modal>
  );
}
