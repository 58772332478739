import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { ReactComponent as EmptyIcon } from '../../../Assets/Images/icons/dashboards/widget-empty.svg';

export default function WidgetWrapper({
  title, widgetData, centerContent, className,
  isEmpty,
  children,
}) {
  return (
    <div className={`dictionary-widgets_widget ${className || ''}`}>
      <div className="dictionary-widgets_widget_title">{title}</div>
      <div className={`dictionary-widgets_widget_main ${isEmpty ? 'empty-state' : ''}`}>

        {isEmpty ? (
          <div className="widget-empty">
            <EmptyIcon />
            <span>This data is not available at the moment</span>
          </div>
        ) : (
          <>
            <div className="thewidget">
              {centerContent && <div className="thewidget-center">{centerContent}</div>}
              {widgetData && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={widgetData}
                />
              )}
            </div>
            <div className="theinfo">
              {children}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
