import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import FormField from '../../common/ui/formField';
import userDetailsSchemas from './_fieldsSchema';

const { fieldsSchema } = userDetailsSchemas;

export default function UserDetails() {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    if (email) {
      setFieldValue('email', email);
    }
  }, []);
  return (
    <>
      <div className="userdetails">
        <p style={{ color: '#3a434a', marginBottom: '14px' }}>
          Let's get started!
        </p>
        {fieldsSchema.map((fld) => (
          <FormField key={fld.key} schema={fld} />
        ))}
        <p className="disclaimer">
          By creating a LoudNClear account, you agree to LoudNClear’s
          {' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://simpoco-privacy-policy-terms-of-use.s3.amazonaws.com/LoudNClear+-+Terms+of+Service_2022_Website.pdf"
          >
            Terms and Conditions
          </a>
          {' '}
          and
          {' '}
          <a
            href="https://simpoco-privacy-policy-terms-of-use.s3.amazonaws.com/LoudNClear+-+Privacy_Policy.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </>
  );
}
