/* eslint-disable jsx-a11y/alt-text */
import LNCSelect from '../../../../Select/LNCSelect';
import PartWrapper from '../common/partWrapper';

export default function OperatorPart({ name, value, onSelect, availableOperators = [] }) {
  return (
    <PartWrapper name={name} partName="operator">
      <LNCSelect
        isDisabled={availableOperators.length === 0}
        value={value}
        onSelect={onSelect}
        placeholder="Select"
        className="filter-operator-select"
        unscrollable
        debug="Operator"
        items={availableOperators}
      />
    </PartWrapper>
  );
}
