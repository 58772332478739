import { useFormikContext } from 'formik';
import { Button, Dropdown, Menu } from 'antd';
import chevron from '../../../../../Assets/Images/icons/menuchevron-chevron-down.svg';
import infoIco from '../../../../../Assets/Images/icons/info-sml.svg';

import platformColors, { platformColorsByBg } from '../../../../../Data/colors';

export default function ColorPicker() {
  const { setFieldValue, values: { metadata } } = useFormikContext();
  return (
    <div className="color-picker" tabIndex="0" role="button">
      <div className="color-picker_chooser">
        <Dropdown
          overlay={(
            <Menu>
              {platformColors.map((color) => (
                <Menu.Item onClick={() => setFieldValue('metadata.color', color.bgColor)} key={color.name}>
                  <Button
                    className="set-text-color"
                    type="text"
                    style={{
                      width: '120px',
                      background: color.bgColor,
                      color: color.textColor,
                      fontWeight: 700,
                      borderRadius: '4px',
                    }}
                  >
                    {color.name}
                  </Button>
                </Menu.Item>
              ))}
            </Menu>
          )}
          placement="bottomCenter"
          arrow
        >
          <Button
            className="set-text-color"
            type="text"
            style={{
              width: '120px',
              height: '45px',
              fontWeight: 700,
              color: platformColorsByBg[metadata.color].textColor,
              background: metadata.color,
              borderRadius: '4px',
            }}
          >
            {platformColorsByBg[metadata.color].name}
          </Button>
        </Dropdown>
      </div>
      <div className="color-picker_chevron">
        <img src={chevron} width="16" height="16" alt="" />
      </div>
      <div className="color-picker_info">
        <img src={infoIco} width="18" height="18" alt="" />
        <div>
          This is how your
          {' '}
          <span style={{ background: metadata.color, color: platformColorsByBg[metadata.color] }}>Keyword highlight</span>
          {' '}
          would appear on your feeds.
        </div>
      </div>
    </div>
  );
}
