import { createContext, useContext } from 'react';

export const ActivitiesFilterContext = createContext({});

export const ActivitiesFilterContextProvider = ({ value, children }) => <ActivitiesFilterContext.Provider value={value}>{children}</ActivitiesFilterContext.Provider>;

const useActivitiesFilterContext = () => {
  const context = useContext(ActivitiesFilterContext);
  return context;
};
export default useActivitiesFilterContext;
