/* eslint-disable quote-props */

export default [
  {
    id: 17,
    trigger: 'segment.contact.add',
    reactor: 'salesforce.account.create_opportunity',
  },
  {
    id: 1,
    trigger: 'segment.contact.add',
    reactor: 'slack.workspace.notify',
  },
  {
    id: 2,
    trigger: 'segment.account.add',
    reactor: 'slack.workspace.notify',
  },
  {
    id: 3,
    trigger: 'dictionary.interaction.match',
    reactor: 'slack.workspace.notify',
  },
  {
    id: 4,
    trigger: 'dictionary.interaction.match',
    reactor: 'kustomer.workspace.tag_field',
  },
  {
    id: 1000,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'totango.task.create',
  },
  {
    id: 1001,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'churnzero.task.create',
  },
  {
    id: 1002,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'loudnclear.workspace.assign',
  },
  {
    id: 1003,
    isPremium: true,
    trigger: 'segment.account.add',
    reactor: 'loudnclear.workspace.assign',
  },
  {
    id: 1004,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'loudnclear.workspace.addtag',
  },
  {
    id: 1005,
    isPremium: true,
    trigger: 'segment.account.add',
    reactor: 'loudnclear.workspace.addtag',
  },
  {
    id: 1006,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'marketo.campaign.trigger',
  },
  {
    id: 1007,
    isPremium: true,
    trigger: 'segment.account.add',
    reactor: 'marketo.campaign.trigger',
  },
  {
    id: 1008,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'mailchimp.campaign.trigger',
  },
  {
    id: 1009,
    isPremium: true,
    trigger: 'segment.account.add',
    reactor: 'mailchimp.campaign.trigger',
  },
  {
    id: 1010,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'salesforce.account.update_cfield',
  },
  {
    id: 1011,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'salesforce.account.update_afield',
  },
  {
    id: 1012,
    isPremium: true,
    trigger: 'segment.contact.add',
    reactor: 'salesforce.account.assign_task',
  },
  {
    id: 1013,
    isPremium: true,
    trigger: 'segment.account.add',
    reactor: 'salesforce.account.assign_task',
  },
];
