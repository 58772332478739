import { Input } from 'antd';
import { Field, Form, Formik } from 'formik';
import addTopicIco from '../../../Assets/Images/icons/add-topic.svg';
import CreateModal from '../../Modal/create/createModal';
import useTopicsMutations from '../../../Utils/community/topics/useTopicsMutations';

export default function TopicCreate({ isShown, onClose }) {
  const { create } = useTopicsMutations();
  return (
    <Formik
      initialValues={{ topic: '' }}
      onSubmit={(values, actions) => {
        create.mutate(values, {
          onSettled: () => {
            actions.setSubmitting(false);
            onClose();
          },
        });
      }}
    >
      {({ submitForm }) => (

        <CreateModal
          isShown={isShown}
          className="create-topic"
          icon={<img src={addTopicIco} width="70" height="70" alt="" />}
          title="Add topic"
          onClose={onClose}
          onApply={submitForm}
          applyText="Create"
          isLoading={create.isLoading}
          desc="After adding this topic to your workspace, it will be listed within an hour and will only affect new interactions. Previous customer conversations about this topic won't be backtracked."
        >
          <Form>
            <div className="crud-modal">
              <Field name="topic">
                {({ field }) => (
                  <div className="crud-modal_field">
                    <label>What's the topic</label>
                    <div>
                      <Input className="lnc-input" {...field} type="text" />
                    </div>

                  </div>
                )}
              </Field>
            </div>
          </Form>
        </CreateModal>
      )}

    </Formik>
  );
}
