import { _fieldsByKey } from '../filters/config/_types';

export const _quickActionsData = [
  {
    id: 1,
    tags: ['customer success', 'support', 'community'],
    title: 'Analyze negative sentiment',
    desc: (
      <>
        Explore the main reasons for
        {' '}
        <span className="text-bold">negative sentiment</span>
        .
      </>
    ),
    prefill: {
      filters: [
        {
          field: 'sentiment',
          operator: 'in',
          value: ['Negative'],
          _field: _fieldsByKey.sentiment,
        },
      ],
    },
  },
  {
    id: 12,
    tags: ['product', 'support'],
    title: 'Explore Poor Service',
    desc: (
      <>
        Explore the main reasons for
        {' '}
        <span className="text-bold">frustration</span>
        {' '}
        from your service.
      </>
    ),
    prefill: {
      filters: [
        {
          field: 'category',
          operator: 'in',
          value: ['churn.Poor Customer Service'],
          _field: _fieldsByKey.category,
        },
      ],
    },
  },
  {
    id: 13,
    tags: ['customer success', 'support', 'community'],
    title: 'Analyze positive sentiment',
    desc: (
      <>
        Explore
        {' '}
        <span className="text-bold">positive engagements</span>
        {' '}
        with your customers.
        .
      </>
    ),
    prefill: {
      filters: [
        {
          field: 'sentiment',
          operator: 'in',
          value: ['Positive'],
          _field: _fieldsByKey.sentiment,
        },
      ],
    },
  },
  {
    id: 6,
    tags: ['product', 'community'],
    title: 'Track potential advocates',
    desc: (
      <>
        Explore
        {' '}
        <span className="text-bold">positive interactions</span>
        {' '}
        with your customers.
        .
      </>
    ),
    prefill: {
      filters: [
        {
          field: 'sentiment',
          operator: 'in',
          value: ['Positive'],
          _field: _fieldsByKey.sentiment,
        },
      ],
    },
  },
  {
    id: 5,
    tags: ['customer success', 'management', 'product'],
    title: 'Explore competitors mentions',
    desc: (
      <>
        Understand what you customers are saying about your competitors
        {' '}
        <span className="text-bold">competitors</span>
        .
      </>
    ),
    prefill: {
      filters: [
        {
          field: 'keywords',
          operator: 'in',
          value: [],
          _field: _fieldsByKey.keywords,
        },
        {
          field: 'category',
          operator: 'in',
          value: [],
          _field: _fieldsByKey.category,
        },
        {
          field: 'topic',
          operator: 'in',
          value: [],
          _field: _fieldsByKey.topic,
        },
      ],
    },
  },
  {
    id: 4,
    tags: ['support', 'product'],
    title: 'Explore feature feedback',
    desc: (
      <>
        Explore and analyze the
        {' '}
        <span className="text-bold">feedbacks and comments</span>
        {' '}
        of a feature or functionality.
        .
      </>
    ),
    prefill: {
      filters: [
        {
          field: 'keywords',
          operator: 'in',
          value: [],
          _field: _fieldsByKey.keywords,
        },
        {
          field: 'topic',
          operator: 'in',
          value: [],
          _field: _fieldsByKey.topic,
        },
      ],
    },
  },
  {
    id: 2,
    tags: ['product', 'support'],
    title: 'Explore Product functionality frustration',
    desc: (
      <>
        Understand the main topics causing
        {' '}
        <span className="text-bold">product frustration</span>
        .
      </>
    ),
    prefill: {
      filters: [
        {
          field: 'category',
          operator: 'in',
          value: ['churn.Severe Frustration with the Product'],
          _field: _fieldsByKey.category,
        },
      ],
    },
  },
  // {
  //   id: 3,
  //   tags: ['customer success', 'management', 'community'],
  //   title: 'Negative sentiment trend',
  //   desc: (
  //     <>
  //       Monitor the trend of
  //       {' '}
  //       <span className="text-bold">negative sentiment interactions</span>
  //       {' '}
  //       with your customers.
  //       .
  //     </>
  //   ),
  //   prefill: {

  //   },
  // },

];

export const _quickActionsByCategory = _quickActionsData.reduce((acc, cur) => {
  cur.tags.forEach((tag) => {
    acc[tag] = [...(acc[tag] || []), cur];
  });
  return acc;
}, {});

export const _quickActionsCategoryColors = {
  'customer success': { bg: '#B2EBF2', text: '#00838F' },
  management: { bg: '#C5CAE9', text: '#283593' },
  support: { bg: '#90CAF9', text: '#1565C0' },
  community: { bg: '#FFAB91', text: '#D84315' },
  product: { bg: '#E1BEE7', text: '#6A1B9A' },
};
