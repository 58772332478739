import { useEffect, useContext } from 'react';
import FormField from '../../common/ui/formField';
import referralsSchemas from './_fieldsSchema';
import StepIcon from '../../../../Assets/Images/icons/onboarding-step-5-ico.svg';
import FormHeader from '../../common/ui/formHeader';
import OnboardingContext from '../../context/onboardingContext';

const { fieldsSchema } = referralsSchemas;

export default function ReferralsDetails() {
  const [_, updateContext] = useContext(OnboardingContext); // eslint-disable-line
  useEffect(() => {
    updateContext('general', {
      title: "Let's build your first set together",
      desc: 'Generate growth opportunities by targeting referrals.',
    });
  }, [updateContext]);
  return (
    <>
      <div className="referrals">
        <FormHeader
          img={StepIcon}
          className="referrals-header"
          title="Locate Referrals"
          desc="Use keywords to locate referrals from your social platfroms and favorite communication channels"
        />
        {fieldsSchema.map((fld) => (
          <FormField key={fld.key} schema={fld} />
        ))}
      </div>
    </>
  );
}
