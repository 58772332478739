import moment from 'moment';
import { motion } from 'framer-motion';
import TopicStatus from './status';
import TopicAction from './action';

export default function TopicLine({
  status, createdDate, name, topicId,
}) {
  return (
    <motion.div layoutId={`topic-${topicId}`} className={`to to-grid ${status === 'inactive' ? 'inactive' : ''}`}>
      <div className="to-col-name">
        {name}
      </div>
      <div className="to-col-created">{moment.utc(createdDate).fromNow()}</div>
      <div className="to-col-status">
        <TopicStatus current={status} />
        {' '}
        <TopicAction status={status} topicId={topicId} />
      </div>

    </motion.div>
  );
}
