/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import {
  Card, Form, Input, Button, Typography,
} from 'antd';
import { observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';

import '../../Assets/styles.scss';
import logo1 from '../../Assets/Images/logo2.svg';
import Dal from '../../Data/Dal';
import lowerFaders from '../../Assets/Images/lower-faders.png';
import MFASms from './MFA/comp';
import './styles.scss';

const { Text, Link } = Typography;
class LoginView extends Component {
  shouldRedirectToReset = false;

  constructor(props) {
    super(props);
    this.state = {
      needConfirm: null, // Or object: { type, isLoading }
      loading: false,
      error: '',
    };
  }

  onForgotPassword() {
    this.props.history.push('/reset');
  }

  onFinish = async (values) => {
    this.setState({ loading: true, error: '' });
    const res = await Dal.signin(values.email, values.password, true);
    if (typeof res !== 'boolean' && res !== true) {
      this.setState({ loading: false });
      if (typeof res === 'object') {
        switch (res.code) {
          case 'UserNotConfirmedException':
            window.sessionStorage.setItem('__onboardingVerification', JSON.stringify({
              email: values.email,
              password: values.password,
            }));
            this.props.history.push('/onboarding');
            break;
          case 'SMS_MFA':
            this.setState({ loading: true });
            this.setState({
              needConfirm: {
                type: 'SMS_MFA',
                user: res.user,
                error: '',
                isLoading: false,
              },
            });
            break;
          case 'SOFTWARE_TOKEN_MFA':
            this.setState({ loading: true });
            this.setState({
              needConfirm: {
                type: 'SOFTWARE_TOKEN_MFA',
                user: res.user,
                error: '',
                isLoading: false,
              },
            });
            break;
          default:
            // do nothing
            break;
        }
        this.setState({ error: res.message });
      }
    }
    // console.log("onFinish isLoggedin " + Dal.isLoggedIn);
  };

  onFinishFailed = () => {
    this.setState({ loading: false });
  };

  onSMSVerifySubmit = async (pin) => {
    this.setState((prev) => ({
      needConfirm: {
        ...prev.needConfirm,
        isLoading: true,
      },
    }));
    const result = await Dal.confirmSignIn(this.state.needConfirm.user, pin, true, this.state.needConfirm.type);
    if (!result) {
      this.setState((prev) => ({
        needConfirm: {
          ...prev.needConfirm,
          error: 'Invalid pin',
        },
      }));
      return false;
    }
    return true;
  };

  render() {
    const { needConfirm } = this.state;
    return Dal.isLoggedIn ? (
      <Redirect to={`/home${window.location.search}`} />
    ) : this.shouldRedirectToReset ? (
      <Redirect to="/reset" />
    ) : (
      <div
        className="loginViewWrapper"
      >
        <img className="lower-faders" src={lowerFaders} alt="" />
        <Card
          className="login-form"
        >
          <div className="center" style={{ marginTop: 30 }}>
            <img width="140" height="63" src={logo1} alt="" />
          </div>
          {this.state.needConfirm ? (
            <MFASms {...needConfirm} goBack={() => this.setState({ needConfirm: null, loading: false })} onSubmit={this.onSMSVerifySubmit} />
          ) : (
            <div className="center" style={{ marginTop: 55 }}>
              <Form
                name="login"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                layout="vertical"
                style={{ marginTop: 30, width: 380 }}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      validateTrigger: 'onCreate',
                      message: 'Please enter email',
                    },
                  ]}
                  label={(
                    <label
                      style={{
                        color: '#01246c',
                        fontSize: 14,
                        fontWeight: '500',
                        margin: 0,
                      }}
                    >
                      Email Address
                    </label>
                  )}
                >
                  <Input size="medium" placeholder="Login with your email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: 'Please enter password' }]}
                  label={(
                    <label
                      style={{
                        color: '#01246c',
                        fontSize: 14,
                        fontWeight: '500',
                        margin: 0,
                      }}
                    >
                      Password
                    </label>
                  )}
                >
                  <Input.Password
                    size="medium"
                    placeholder="Enter your password"
                  />
                </Form.Item>
                <Form.Item style={{ marginTop: -10 }}>
                  <Text
                    style={{ cursor: 'pointer', color: '#0084ff', fontSize: '14px' }}
                    onClick={this.onForgotPassword.bind(this)}
                  >
                    Forgot password?
                  </Text>
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{
                      marginTop: 30,
                      width: 380,
                      height: 50,
                    }}
                    className="login-btn"
                    type="primary"
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    Log-in
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
          <div style={{ marginTop: -10 }}>
            <div style={{ color: 'red', textAlign: 'center', height: 27 }}>
              {this.state.error}
            </div>
            <div style={{ marginTop: 28 }}>
              <div style={{ display: 'block', textAlign: 'center' }}>By logging-in I agree to the</div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link
                  href="https://simpoco-privacy-policy-terms-of-use.s3.amazonaws.com/LoudNClear+-+Terms+of+Service_2022_Website.pdf"
                  target="_blank"
                >
                  Terms and Conditions
                </Link>
                <div style={{ padding: '0 3px' }}>&</div>
                <Link
                  href="https://simpoco-privacy-policy-terms-of-use.s3.amazonaws.com/LoudNClear+-+Privacy_Policy.pdf"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default observer(withRouter(LoginView));
