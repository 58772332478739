import { memo } from 'react';
import firstImg from '../../../../Assets/Images/onboarding_sider/5/integrations2.png';

const IntegrationSider = memo(
  () => (
    <div className="integrations">
      <div className="sider-content">
        <img
          src={firstImg}
          width="800"
          height="623"
          alt="Integration details"
        />
      </div>
    </div>
  ),
  () => true,
);

export default IntegrationSider;
