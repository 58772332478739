import Lottie from 'react-lottie';
import { Button } from 'antd';
import {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import NetworkManager from '../../../Network/NetworkManager';
import doneCheck from '../../../Assets/Images/icons/done-check.svg';
import error from '../../../Assets/Images/icons/illustration-error.svg';

import spinner from '../../../Assets/Images/icons/lnc-spinner.json';
import dalInstance from '../../../Data/Dal';

const loaderAnimation = (file) => ({
  loop: true,
  autoplay: true,
  animationData: file,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});

function ProcessStep({ data }) {
  const [isReady, setReady] = useState(false);
  const file = useRef();
  const infos = useMemo(() => {
    const img = (() => {
      if (typeof isReady === 'string') return <img src={error} width="70" height="70" alt="Error" />;
      if (isReady) return <img src={doneCheck} width="70" height="70" alt="Done" />;
      return (
        <Lottie
          options={loaderAnimation(spinner)}
          height={70}
          width={70}
        />
      );
    })();
    const title = (() => {
      if (typeof isReady === 'string') return <span className="error">Could not export file</span>;
      if (isReady) return 'Your file is ready!';
      return 'Preparing your file';
    })();
    const action = (() => {
      if (typeof isReady === 'string') return <>{isReady}</>;
      if (isReady) return <Button onClick={() => file.current.click()} className="btn_next">Download</Button>;
      return 'This can take up to a minute.';
    })();
    return { img, title, action };
  }, [isReady]);
  useEffect(() => {
    if (file.current) {
      const backendData = {
        file_type: 'csv',
        segment_ids: data.mappings.segments,
        system_fields: data.mappings.fields.filter((v) => v.includes('sf__')).map((v) => v.slice(4)),
        custom_fields: data.mappings.fields.filter((v) => v.includes('cu__')).map((v) => v.slice(4)),
        with_history: data.withHistory || false,
      };
      (async () => {
        const csv = await NetworkManager.exportCrm(dalInstance.communities[0].id, backendData, data.fileName, file.current);
        setReady(csv === true ? true : csv);
      })();
    }
  }, [file, data]);
  return (
    <motion.div
      className="step-process"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <a ref={file} style={{ display: 'none' }} /> {/* eslint-disable-line */}
      <div className="step-process_lottie">
        {infos.img}
      </div>
      <div className="step-process_title">
        {infos.title}
      </div>
      <div className="step-process_desc">
        {infos.action}
      </div>
    </motion.div>
  );
}

export default observer(ProcessStep);
