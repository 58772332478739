import Lottie from 'react-lottie';
import LNCTableLoader from '../../../../Assets/Lotties/LNCTableLoader.json';

const tableLoaderAnimationConfig = {
  loop: true,
  autoplay: true,
  animationData: LNCTableLoader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function QueryLoader() {
  return (
    <div className="queries-results-wrapper">
      <div className="animation-container">
        <Lottie options={tableLoaderAnimationConfig} height={80} width={80} />
      </div>
      <div className="loading-title">Gathering data</div>
      <div className="loading-subtitle">This might take a few seconds</div>
    </div>
  );
}
