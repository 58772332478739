import { memo, useMemo } from 'react';
import defaultIntegrations from '../../../../Data/integrations';
import { useCommunityContext } from '../../../../Utils/contexts/community';

function SourceColumn({ value }) {
  const { assets } = useCommunityContext();
  const relevantAsset = useMemo(() => {
    if (!value) return null;
    const theAsset = assets[value];
    if (!theAsset) return null;
    return {
      name: theAsset.name,
      image: defaultIntegrations.find((x) => x.id === theAsset.integration_id)?.image,
    };
  }, [assets, value]);
  return (
    <div className="source_col">
      {relevantAsset ? (
        <>
          <img src={relevantAsset.image} width="24" height="24" alt="" />
          <span>{relevantAsset.name}</span>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default memo(SourceColumn);
