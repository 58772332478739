import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import useQueriesQueries from '../../../../../Queries/hooks/useQueriesQueries';

export default function ActiveAutomationText({ reactor }) {
  const { values } = useFormikContext();
  const { queries } = useQueriesQueries();
  const selectedQuery = useMemo(() => (queries.data || []).find((f) => f.id === values.trigger), [values, queries.data]);
  return (
    <div className="active-auto-text autotext">
      When interaction meets
      {' '}
      <span className="highlight">{selectedQuery?.name}</span>
      {' '}
      conditions,
      {' '}
      <reactor.templates.Display />
    </div>
  );
}
