const isSmallScreen = () => window.screen.width < 1500;

const authorizeBtn = {
  width: '120px',
  height: '40px',
  marginRight: isSmallScreen() ? '70px' : '142px',
  padding: '10px 27px 10px 28px',
  borderRadius: '4px',
  backgroundColor: '#0084ff',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.02px',
  textAlign: 'center',
  color: '#ffffff',
};

const backBtn = {
  width: '120px',
  height: '40px',
  padding: '10px 43px',
  borderRadius: '4px',
  backgroundColor: '#f8f8f8',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.02px',
  marginTop: '30px',
  marginBottom: '30px',
  textAlign: 'center',
  color: '#3a434a',
  border: 'none',
};

const doneBtn = {
  ...backBtn,
  opacity: 0.5,
  backgroundColor: '#0084ff',
  color: '#ffffff',
};

const doneBtnEnabled = {
  ...doneBtn,
  opacity: 1,
  backgroundColor: '#0084ff',
};

const authorizeAppBtn = {
  width: '260px',
  height: '50px',
  borderRadius: '4px',
  backgroundColor: '#14a0f9',
  fontFamily: 'Poppins',
  fontSize: isSmallScreen() ? '13px' : '14px',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: '-0.02px',
  textAlign: 'center',
  color: '#ffffff',
  border: 'none',
  outline: 'none',
};

const authorizedBtn = {
  ...authorizeBtn,
  backgroundColor: '#65bb6a',
};

const validateAuthorizationBtn = {
  ...authorizeAppBtn,
  backgroundColor: '#0084ff',
  // marginTop: 0,
  opacity: 0.4,
};

const validateAuthorizationEnabled = {
  ...validateAuthorizationBtn,
  backgroundColor: '#0084ff',
  opacity: 1,
};

const validationSuccess = {
  ...validateAuthorizationBtn,
  backgroundColor: '#65bb6a',
  opacity: 1,
};

const addGroup = {
  ...authorizeAppBtn,
  width: 'auto',
  marginLeft: 30,
  marginTop: 20,
  marginBottom: 20,
  height: '50px',
  padding: '15px 27px 15px 28px',
  borderRadius: '4px',
  backgroundColor: '#0084ff',
};

const addGroupSmall = {
  ...addGroup,
  width: '110px',
  height: '40px',
  padding: '0',
  fontSize: '13px',
};

const assetStyle = {
  backgroundColor: 'white',
  height: 80,
  // borderLeft: 'solid 4px #7d8fff',
  // boxShadow: '0 0 4px 0 #82a8e8',
  borderRadius: 4,
};

const assetStyleHover = {
  ...assetStyle,
  // border: 'solid 1px #7d8fff'
};

const assetStyleGreen = {
  ...assetStyle,
  // borderLeft: 'solid 4px #3ec067',
};

const toExport = {
  authorizeBtn,
  backBtn,
  assetStyle,
  assetStyleHover,
  assetStyleGreen,
  doneBtn,
  addGroup,
  addGroupSmall,
  doneBtnEnabled,
  authorizeAppBtn,
  authorizedBtn,
  validateAuthorizationBtn,
  validateAuthorizationEnabled,
  validationSuccess,
};

export default toExport;
