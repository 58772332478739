import { createPortal } from 'react-dom';

export default function AdditionalPortal({ id, children }) {
  return (
    createPortal(
      children,
      document.querySelector(`#additional_portal_${id}`),
    )
  );
}
