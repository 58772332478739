import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import './style.scss';

const selectText = (element) => {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(element);
    range.select(0);
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(element);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export default function Edittable({
  defaultValue, value, isLoading, onControlledChange, isHighlighted, onChange, className, styles, // eslint-disable-line
  fieldKey, mutation, mutationAdditions,
  disableClickEditting,
  isEditting: controlledEditting,
}) {
  const [val, setVal] = useState(value || defaultValue || '');
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const [isEditting, setEditting] = useState(false);

  const isContentEditable = useMemo(() => {
    if (typeof disableClickEditting === 'undefined') return true;
    if (controlledEditting) return true;
    return false;
  }, [disableClickEditting, controlledEditting]);

  const classes = useMemo(() => {
    const list = ['editable'];
    className && list.push(className);
    disableClickEditting && list.push('no-click');
    isEditting && list.push('edit-mode');
    isHighlighted && list.push('highlighted');
    loading && list.push('loading');
    return list.join(' ');
  }, [isEditting, disableClickEditting, isHighlighted, className, loading]);

  useEffect(() => {
    if (typeof value !== 'undefined') setVal(value);
  }, [value]);

  useEffect(() => {
    if (typeof controlledEditting !== 'undefined') {
      setEditting(controlledEditting);
      if (controlledEditting) {
        selectText(ref.current);
      }
    }
  }, [controlledEditting]);

  const handleClick = useCallback(() => {
    if (!isEditting) {
      selectText(ref.current);
      setEditting(true);
    }
  }, [ref, isEditting]);

  const handleSubmit = useCallback(async () => {
    setEditting(false);
    onChange && onChange(ref.current.textContent === defaultValue ? null : ref.current.textContent);

    if ((fieldKey && mutation) && (ref.current.textContent !== (defaultValue || value))) {
      setLoading(true);
      await mutation.mutateAsync({ [fieldKey]: ref.current.textContent, ...(mutationAdditions || {}) });
      setLoading(false);
    }
  }, [ref, onChange, fieldKey, mutation, value, defaultValue, mutationAdditions]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current.blur(); // blur will trigger submit
    }
  }, [ref]);

  const onInput = useCallback((e) => {
    ref.current.textContent = e.target.textContent;
    if (value && onControlledChange) onControlledChange(e.target.textContent);
    if (e.target.setSelectionRange !== undefined) {
      e.target.setSelectionRange(e.target.textContent.length, e.target.textContent.length);
    }
  }, [ref, value, onControlledChange]);

  return (
    <div
      type="text"
      className={classes}
      onClick={disableClickEditting ? () => null : handleClick}

    >
      <span
        ref={ref}
        onBlur={handleSubmit}
        onKeyPress={handleKeyPress}
        onInput={onInput} // eslint-disable-line
        contentEditable={isContentEditable}
        suppressContentEditableWarning
      >
        {val}
      </span>
    </div>
  );
}
