import React from 'react';

import { observer } from 'mobx-react';
import { Modal, Divider } from 'antd';
import {
  makeObservable, observable, computed, runInAction,
} from 'mobx';

import texts from '../Pages/texts';
import ConnectPage from './connectPageForMessenger';
import Dal from '../../../Data/Dal';
import facebookConnectImage from '../../../Assets/Images/redesign/facebook-connect.png';
import facebookMatch from '../../../Assets/Images/redesign/facebook-page-match.svg';
import UserPagesList from '../Pages/userPagesList';

import './styles.scss';

class MessengerModal extends React.Component {
  activeStep = 0;
  pages = null;
  isLoadingAssets = false;

  constructor() {
    super();

    makeObservable(this, {
      activeStep: observable,
      pages: observable,
      isLoadingAssets: observable,
      currentStepTexts: computed,
      currentStepContent: computed,
    });
    this.pagesConnected = this.pagesConnected.bind(this);
    this.getInsignts = this.getInsignts.bind(this);
  }

  get currentStepTexts() {
    return texts[this.activeStep];
  }

  pagesConnected(pages, userToken) {
    const formattedPages = [];
    pages.forEach((page) => formattedPages.push({
      token: userToken,
      token_secret: null,
      page_token: page.access_token,
      name: page.name,
      type: 'messenger',
      service_name: 'facebook',
      picture: page.picture.data.url,
      member_count: page.followers_count,
      asset_id: page.id,
      community_id: Dal.getCurrentCommunityId(),
    }));
    runInAction(() => {
      this.activeStep++;
      this.pages = formattedPages;
    });
  }

  get currentStepContent() {
    switch (this.activeStep) {
      case 0:
        return (
          <ConnectPage
            pagesConnected={this.pagesConnected}
            closeModalWithoutFetching={this.props.closeModalWithoutFetching}
          />
        );
      case 1:
        return (
          <UserPagesList
            pages={this.pages}
            getInsignts={this.getInsignts}
            closeModalWithoutFetching={this.props.closeModalWithoutFetching}
            isLoading={this.isLoadingAssets}
          />
        );
      default:
        return <></>;
    }
  }

  async getInsignts() {
    runInAction(() => { this.isLoadingAssets = true; });
    await Dal.createNewAssets(this.pages, this.props.closeModal);
  }

  render() {
    return (
      <Modal
        visible={this.props.isVisible}
        closable
        onCancel={this.props.closeModalWithoutFetching}
        className={
          this.activeStep === 0
            ? 'integrationModal'
            : 'FacebookPageModal pagesList'
        }
        wrapClassName="integrationModalWrapper"
        footer={null}
      >
        <img
          src={this.activeStep === 0 ? facebookConnectImage : facebookMatch}
          alt=""
          width={window.screen.width < 1500 ? 154 : 271}
        />
        <p className="pagesHeader">{this.currentStepTexts.header}</p>
        <p
          className={
            this.activeStep === 0 ? 'pagesSubtitle' : 'pagesSubtitle step2'
          }
        >
          {this.currentStepTexts.subtitle}
        </p>
        <Divider />
        {this.currentStepContent}
      </Modal>
    );
  }
}

export default observer(MessengerModal);
