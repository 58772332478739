import errorimg from '../../Assets/Images/errorBoundary.svg';

export default function ErrorText({ style }) {
  return (
    <div style={style} className="error-boundary">
      <img src={errorimg} width={60} height={60} alt="" />
      <div className="btitle">It&apos;s not you, It&apos;s us.</div>
      <div>This section has failed to load, we&apos;re working on a solution.</div>
    </div>
  );
}
