import { createContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';

export const AutomationsContext = createContext({});

const useAutomations = (commId) => {
  const queryClient = useQueryClient();
  const workflowsQuery = useQuery(['workflows'], () => NetworkManager.getAllWorkflows(commId), {
    initialData: [],
  });

  const createWorkflowMutation = useMutation((d) => NetworkManager.createWorkflow(commId, d), {
    onSuccess: () => {
      queryClient.refetchQueries(['workflows'], { exact: false });
    },
  });

  const updateWorkflowMutation = useMutation((d) => NetworkManager.updateWorkflow(commId, d), {
    onSuccess: () => {
      queryClient.refetchQueries(['workflows'], { exact: false });
    },
  });

  const deleteWorkflowMutation = useMutation((d) => NetworkManager.deleteWorkflow(commId, d), {
    onSuccess: () => {
      queryClient.refetchQueries(['workflows'], { exact: false });
    },
  });
  return {
    workflowsQuery,
    createWorkflowMutation,
    updateWorkflowMutation,
    deleteWorkflowMutation,
  };
};

export default useAutomations;
