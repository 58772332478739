/* eslint-disable max-len */
export default function ChipClose({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <g fill={color}>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path d="M7.503 4h.994c.096 0 .183.087.183.193V7.31h3.127c.106 0 .193.087.193.193v.994c0 .096-.087.183-.193.183H8.68v3.127c0 .106-.087.193-.183.193h-.994c-.106 0-.193-.087-.193-.193V8.68H4.193C4.087 8.68 4 8.593 4 8.497v-.994c0-.106.087-.193.193-.193H7.31V4.193c0-.106.087-.193.193-.193z" transform="translate(-1231.000000, -137.000000) translate(930.000000, 0.000000) translate(80.000000, 0.000000) translate(1.000000, 85.000000) translate(218.000000, 49.000000) translate(2.000000, 3.000000) translate(8.000000, 8.000000) rotate(45.000000) translate(-8.000000, -8.000000)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}
