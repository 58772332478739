import { createContext, useContext } from 'react';

export const DrawerContext = createContext({});

export const DrawerContextProvider = ({ value, children }) => <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>;

const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  return context;
};
export default useDrawerContext;
