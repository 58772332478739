import { motion } from 'framer-motion';
import { observer } from 'mobx-react';
import DrawerHandler from '../../state/handler';
import chevronLeft from '../../../../../Assets/Images/icons/general-chevron-chevron-left.svg';
import AccountCard from './card';

function AccountView({ onClose }) {
  const accounts = (DrawerHandler.data.userDetails?.linked_accounts || []).filter((a) => a?.id && a?.name); // eslint-disable-line
  return (
    <motion.div
      key="crm-account"
      className="crmDrawer-account"
      initial={{ x: '0%' }}
      animate={{ x: '-100%', transition: { type: 'tween', duration: 0.5, delay: 0 } }}
      exit={{ x: '0%', transition: { type: 'tween', duration: 0.5, delay: 0 } }}
    >
      <div className="crmDrawer-account_heading">
        <img onClick={onClose} src={chevronLeft} width="24" height="24" alt="Close" />
        <span>Accounts Details</span>
      </div>
      {accounts.map((account) => (
        <AccountCard account={account} key={account.id} />
      ))}
      <div className="crmDrawer-account-grow" />
      {typeof avatar !== 'string' && (
        <div className="crmDrawer-account_attr">
          <a href="https://clearbit.com">Logos provided by Clearbit</a>
        </div>
      )}
    </motion.div>
  );
}

export default observer(AccountView);
