import { useState } from 'react';
import { observer } from 'mobx-react';
import { AnimatePresence } from 'framer-motion';
import { Popover } from 'antd';
import SuperFilter from './index';
import superHandler from './state/superFilter.class';
import { ReactComponent as FilterIcon } from '../../Assets/Images/redesign/ui-icon-filter-filter-purple.svg';

function SuperFilterWrapper({
  isSuperFilterActive, isShown, setShown, onFinish,
}) {
  const onClose = () => {
    /* if (isShown) {
      superHandler.reset();
    } */
    setShown(!isShown);
  };
  const handleFinish = (data) => {
    setShown(false);
    onFinish(data);
  };

  const handleReset = () => {
    onFinish(null);
  };
  return (
    <Popover
      content={<SuperFilter onReset={handleReset} onFinish={handleFinish} onClose={onClose} isShown={isShown} />}
      trigger="click"
      className="super-pop"
      visible={isShown}
      onVisibleChange={setShown}
    >
      <div
        className={`general-button ${(isShown || isSuperFilterActive) && 'active'}`}
        role="button"
        tabIndex="0"
        onClick={() => {
          if (isShown) onClose();
          else setShown(true);
        }}
        data-step="super-filter-button"
      >
        {' '}
        {/* for selenium automation */}
        <div className="general-button_img">
          <FilterIcon />
          <div
            className={isSuperFilterActive ? 'red-filter-dot' : 'hidden'}
          />
        </div>
        <span>Super Filter</span>
      </div>
    </Popover>
  );
}

export default observer(SuperFilterWrapper);
