import {
  Button, Divider, Input, Modal,
} from 'antd';
import { Field, useFormik } from 'formik';
import { observer } from 'mobx-react';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import dalInstance from '../../../Data/Dal';
import useAsset from '../../../Utils/community/useAsset';
import '../styles.scss';

function BasicOAuthModal({
  closeModalWithoutFetching, serviceName, serviceType, assetAdded,
}) {
  const { assetSchema, connect } = useAsset({
    communityId: dalInstance.getCurrentCommunityId(),
    assetId: null,
    serviceName,
    serviceType,
  });
  const [globalError, setGlobalError] = useState('');
  const [step, setStep] = useState(0); // 0 - initial, 1 - form (if applicable), 2 - success
  const initals = useMemo(() => assetSchema.fieldsSchema.reduce((acc, field) => ({
    ...acc,
    [field.key]: field.defaultValue,
  }), {}), [assetSchema]);
  const formik = useFormik({
    initialValues: {
      ...initals,
      ...Object.values(assetSchema.oauth.extractors.params).reduce((acc, cur) => ({ ...acc, [cur]: '' }), {}),
    },
    validationSchema: assetSchema.validationSchema,
    onSubmit: (values, actions) => {
      connect.mutate(values, {
        onSettled: async (res) => {
          if (res instanceof Error) {
            actions.setSubmitting(false);
            setGlobalError('One field or more are invalid. Please check back later.');
          } else {
            await assetAdded(true);
            setStep(2);
            actions.setSubmitting(false);
          }
        },
      });
    },
  });
  const oauthWindow = useRef();
  const isApplicableForSteps = useMemo(() => {
    if (assetSchema.fieldsSchema.length === 0) return false;
    return true;
  }, [assetSchema]);
  const openOAuth = useCallback(() => {
    oauthWindow.current = window.open(assetSchema.oauth.popupUrl, '_popup', `popup=true,width=700,height=820,top=${window.innerHeight - 820},left=${window.innerWidth - 700}`);
  }, [assetSchema]);
  const onOAuthComplete = useCallback((d) => {
    oauthWindow.current.close();
    const payload = d[serviceName + serviceType];
    Object.keys(payload).forEach((ke) => {
      formik.setFieldValue(ke, payload[ke]);
    });
    if (isApplicableForSteps) {
      setStep(1);
    } else {
      formik.submitForm();
    }
  }, [formik, isApplicableForSteps, serviceName, serviceType]);
  const handleButtonClick = useCallback(() => {
    if (step === 0) {
      openOAuth();
    } else if (step === 1) {
      formik.submitForm();
    } else {
      closeModalWithoutFetching();
    }
  }, [openOAuth, step, formik, closeModalWithoutFetching]);
  useEffect(() => {
    function eventListener(event) {
      if (event.origin === process.env.REACT_APP_BASE_URL && event?.data[serviceName + serviceType]) {
        onOAuthComplete(event.data);
      }
    }
    window.addEventListener('message', eventListener, false);
    return () => window.removeEventListener('message', eventListener);
  }, [onOAuthComplete, serviceName, serviceType]);
  return (
    <Modal
      visible
      closable
      onCancel={closeModalWithoutFetching}
      className="integrationModal"
      wrapClassName="integrationModalWrapper"
      footer={null}
    >
      <img src={step === 2 ? assetSchema.config.connectedImage : assetSchema.config.image} width="250" alt="" />
      <p className="pagesHeader">
        {step === 2 ? (
          <>
            LoudNCLear is connected to your
            {' '}
            {assetSchema.config.name}
          </>
        ) : (
          <>
            {assetSchema.config.name}
            {' '}
            Integration
          </>
        )}
      </p>
      {step !== 2 && <Divider />}
      {step === 0 && (
        <ul className="textList">
          {assetSchema.config.info.map((i) => (
            <li key={i} style={{ marginBottom: 5 }}>
              {i}
            </li>
          ))}
        </ul>
      )}
      {step === 1 && (
        (
          <div className="int-form">
            {assetSchema.fieldsSchema.map((f) => (
              <div className="int-form_field">
                <label htmlFor={f.key}>{f.label}</label>
                {/* <span>{f.placeholder}</span> */}
                <Input type="text" name={f.key} id={f.key} onChange={formik.handleChange} placeholder={f.placeholder} value={formik.values[f.key]} />
              </div>
            ))}
          </div>
        )
      )}
      {Object.keys(formik.errors).filter((er) => !!formik.touched?.[er]).map((er) => (
        <div className="int-form_errors">
          {formik.errors[er]}
        </div>
      ))}
      {globalError && (
        <div className="int-form_errors">
          {globalError}
        </div>
      )}
      <Divider />
      <Button loading={formik.isSubmitting} className="connectSalesforceBtn pendo" onClick={handleButtonClick}>
        {step === 2 ? 'Get Insights' : (
          <>
            Connect
            with
            {' '}
            {assetSchema.config.name}
          </>
        )}
      </Button>
      <p className="closeSalesforceText" onClick={closeModalWithoutFetching}>
        Close
      </p>
    </Modal>
  );
}

export default observer(BasicOAuthModal);
