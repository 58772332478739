import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useCommunityContext } from '../../../../Utils/contexts/community';
import DictIcon from '../../../../Assets/Images/icons/new-dict-icon';
import EntitySidebar from '../../../EntitySidebar';
import { platformColorsByBg } from '../../../../Data/colors';
import useDictionaryMutations from '../../../../Utils/community/dictionaries/useDictionaryMutations';
import useDictionaryContext from '../../context/dictionary.context';
import kFormatter from '../../../../Utils/funcs/kFormatter';

export default function DictionariesSidebar({ selected }) {
  const { communityId, dictionaries } = useCommunityContext();
  const { deleteDictionary } = useDictionaryMutations();
  const { setDictModal } = useDictionaryContext();
  const history = useHistory();
  const sidebarList = useMemo(() => {
    if (dictionaries) {
      return Object.values(dictionaries).map((d) => ({
        ...d,
        id: d.id,
        desc: null, //  d.private ? 'Private' : 'Shared',
        title: d.name,
        info: (
          <div className="info_counts">
            <span>{kFormatter(d.total_matches)}</span>
          </div>
        ),
        icon: (
          <div className="dict-icon-wrapper">
            <DictIcon color={platformColorsByBg?.[d.color]?.textColor} />
          </div>
        ),
        onClick: () => {
          history.push(`/dashboard/data/${d.id}`);
        },
        onEditClick: () => setDictModal(d),
      }));
    }
    return [];
  }, [dictionaries]); // eslint-disable-line

  const deleteDictionaryMiddleware = useMemo(() => ({
    mutate: (obj, options) => {
      if (Number(selected) === Number(obj.id)) {
        history.push(`/dashboard/data/${Object.values(dictionaries).find((dict) => dict.id !== obj.id)?.id}`);
      }
      deleteDictionary.mutate(obj, options);
    },
    isLoading: deleteDictionary.isLoading,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [deleteDictionary, dictionaries, selected]);
  return (
    <>
      {/* <DictionariesModal /> */}
      <EntitySidebar
        list={sidebarList}
        onCreateClick={() => setDictModal({})}
        // customEntity={ }
        entity={{ singularName: 'dictionary', pluralName: 'dictionaries', apiName: 'dictionaries' }}
        communityId={communityId}
        deleteMutation={deleteDictionaryMiddleware}
        shouldShowDeleteConfirm={{ title: 'Delete dictionary?', desc: 'All the team members you shared this dictionary with will lose their access' }}
        selected={selected ?? undefined}
        injectHeader={<div style={{ height: '10px' }} />}
        // onCreateClick={() => setBoardModal(true)}
        title="Dictionaries"
      />
    </>
  );
}
