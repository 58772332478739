import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

export default function useActivitiesFilter(contactId) {
  const [filterWords, setFilterWords] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [dictionaries, setDictionaries] = useState([]);
  const toggleKeyword = useCallback((keyword) => {
    setFilterWords((cur) => {
      const draft = [...cur];
      const found = draft.find((k) => k.label === keyword.label);
      if (found) {
        return draft.filter((k) => k.label !== keyword.label);
      }
      return [...draft, keyword];
    });
  }, []);

  const toggleDictionary = useCallback((dict) => {
    setDictionaries((cur) => {
      const draft = [...cur];
      const found = draft.find((k) => k.id === dict.id);
      if (found) {
        return draft.filter((k) => k.id !== dict.id);
      }
      return [...draft, {
        id: dict.id, name: dict.name, color: dict.color, textColor: dict.textColor,
      }];
    });
  }, []);

  const filterWordsByLabel = useMemo(() => filterWords.reduce((acc, cur) => ({
    ...acc,
    [cur.label]: true,
  }), {}), [filterWords]);

  const filterDictionariesById = useMemo(() => dictionaries.reduce((acc, cur) => ({
    ...acc,
    [cur.id]: true,
  }), {}), [dictionaries]);

  useEffect(() => {
    setFilterWords([]);
    setPlatforms([]);
    setDictionaries([]);
  }, [contactId]);
  return {
    toggleKeyword,
    filterWords,
    setFilterWords,
    filterWordsByLabel,
    platforms,
    setPlatforms,
    dictionaries,
    filterDictionariesById,
    toggleDictionary,
  };
}
