import { Button, Dropdown, Menu } from 'antd';
import { useField, useFormikContext } from 'formik';
import { ReactComponent as BoardIcon } from '../../../Assets/Images/icons/dashboards/board-icon.svg';
import dalInstance from '../../../Data/Dal';

export default function ColorPicker({ fieldName }) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ name: fieldName || 'color' });
  return (
    <div className="color-picker">
      <div className="color-picker_chooser">
        <Dropdown
          overlay={(
            <Menu>
              {dalInstance.setsColors.map((color) => (
                <Menu.Item onClick={() => setFieldValue('color', color)} key={color.name}>
                  <Button
                    className="set-text-color"
                    type="text"
                    style={{
                      width: '150px',
                      background: color.bgColor,
                      color: color.textColor,
                      borderRadius: '4px',
                    }}
                  >
                    {color.name}
                  </Button>
                </Menu.Item>
              ))}
            </Menu>
          )}
          placement="bottomCenter"
          arrow
        >
          <Button
            className="set-text-color"
            type="text"
            style={{
              width: '150px',
              height: '45px',
              color: field.value.textColor,
              background: field.value.bgColor,
              borderRadius: '4px',
            }}
          >
            {field.value.name}
          </Button>
        </Dropdown>
      </div>
      <div className="color-picker_icon">
        <BoardIcon stroke={field.value.textColor} fill={field.value.textColor} />
      </div>
    </div>
  );
}
