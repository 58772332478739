import React from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import _ from 'underscore';
import {
  EmailCard,
  TwitterCard,
  IntercomCard,
  FileCard,
  ZendeskCard,
} from '../Card';
import FacebookCard from '../Card/FacebookCard';
import RedditCard from '../Card/RedditCard';
import SalesforceCard from '../Card/SalesforceCard';
import SlackCard from '../Card/SlackCard';

export function renderTag({ item, activeKey }) {
  switch (item?.platform_name) {
    case 'email':
      return (
        <Tag color="red">
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Sent Mail
        </Tag>
      );
    case 'twitter':
      return (
        <Tag color="blue">
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Posted on Twitter
        </Tag>
      );
    case 'facebook':
      return (
        <Tag color="blue">
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Posted on Facebook
        </Tag>
      );
    case 'zendesk':
      return (
        <Tag color="gray">
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Opened ticket in Zendesk
        </Tag>
      );
    case 'intercom':
      return (
        <Tag color="blue">
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Intercom conversation
        </Tag>
      );
    case 's3':
      return (
        <Tag color="green">
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Added from file
        </Tag>
      );
    case 'reddit':
      return (
        <Tag style={{ backgroundColor: '#ffdfd3', color: '#ff4500' }}>
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Posted on Reddit
        </Tag>
      );
    case 'salesforce':
      return (
        <Tag style={{ backgroundColor: '#AED9FA', color: '#01246c' }}>
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Appeared in Salesforce
        </Tag>
      );
    case 'slack':
      return (
        <Tag style={{ backgroundColor: '#511652', color: '#FFFFFF' }}>
          {activeKey
            && (activeKey.includes(item.member_instance_id) ? '-' : '+')}
          {' '}
          Appeared on Slack
        </Tag>
      );
    default:
      return <></>;
  }
}

function renderCard({ item, topKeywords }) {
  switch (item?.platform_name) {
    case 'email':
      return <EmailCard item={item} top_keywords={topKeywords} />;
    case 'twitter':
      return <TwitterCard item={item} top_keywords={topKeywords} />;
    case 'facebook':
      return <FacebookCard item={item} top_keywords={topKeywords} />;
    case 'zendesk':
      return <ZendeskCard item={item} top_keywords={topKeywords} />;
    case 'intercom':
      return <IntercomCard item={item} top_keywords={topKeywords} />;
    case 's3':
      return <FileCard item={item} top_keywords={topKeywords} />;
    case 'reddit':
      return <RedditCard item={item} top_keywords={topKeywords} />;
    case 'salesforce':
      return <SalesforceCard item={item} top_keywords={topKeywords} />;
    case 'slack':
      return <SlackCard item={item} top_keywords={topKeywords} />;

    default:
      break;
  }
}

export default function TimelineItem({
  item: Item,
  toggleTimeline,
  activeKey,
  topKeywords,
  loading,
}) {
  return _.values(_.groupBy(Item, 'platform_name')).map((activity) => (
    <div
      key={`${activity[0]?.member_instance_id || Math.random()}`}
      className="timeline-item"
      style={{ visibility: !loading ? 'visible' : 'hidden' }}
    >
      <div onClick={() => toggleTimeline(activity[0]?.member_instance_id)}>
        {renderTag({ item: activity[0], activeKey })}
      </div>
      {!activeKey?.includes(activity[0]?.member_instance_id) && (
        <div className="datetime-wrapper">
          {activity?.map((i, j) => (
            <div key={j} className="datetime">
              {j === 0
                && moment(activity[j]?.instance_time).format(
                  'MMM DD YYYY, HH:mm',
                )}
              {/* {moment(item[j]?.instance_time).format("M.DD.YYYY HH:mm")}{" "} */}
              {/* {item?.length - 1 > j ? "|" : ""} */}
            </div>
          ))}
        </div>
      )}

      <div className="twitter-card-wrapper">
        {activeKey?.includes(activity[0]?.member_instance_id)
          && activity?.map((i, j) => (
            <div key={j}>
              <div className="datetime">
                {moment(activity[j]?.instance_time).format(
                  'MMM DD YYYY, HH:mm',
                )}
              </div>
              {renderCard({ item: i, topKeywords })}
            </div>
          ))}
      </div>
    </div>
  ));
}
