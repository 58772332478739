import contact from '../../../../../../Assets/Images/icons/automation/contact.svg';
import account from '../../../../../../Assets/Images/icons/automation/account.svg';
import segment from '../../../../../../Assets/Images/icons/automation/segment.svg';
import lnc from '../../../../../../Assets/Images/icons/automation/lnc.svg';

import totango from '../../../../../../Assets/Images/icons/totango-icon.png';
import churnzero from '../../../../../../Assets/Images/icons/repo-icon-crunzero.png';
import marketo from '../../../../../../Assets/Images/icons/repo-icon-marketo.svg';
import mailchimp from '../../../../../../Assets/Images/icons/repo-icon-mailchimp.svg';
import salesforce from '../../../../../../Assets/Images/icons/repo-icon-salesforce.svg';
import hubspot from '../../../../../../Assets/Images/redesign/repo-icon-hubspot.svg';
import zendesk from '../../../../../../Assets/Images/redesign/repo-icon-zendesk.svg';

import slackIcon from '../../../../../../Assets/Images/redesign/repo-icon-slack.svg';
import lineArrow from '../../../../../../Assets/Images/icons/line-arrow.svg';
import { ReactComponent as SetsIco } from '../../../../../../Assets/Images/icons/cat-ico-sets-vari.svg';
import QueryIcon from '../../../../../../Assets/Images/icons/query-icon';

const entityIcons = {
  contact: <img src={contact} width="30" height="30" alt="" />,
  account: <img src={account} width="30" height="30" alt="" />,
  interaction: <img src={contact} width="30" height="30" alt="" />,
};

const relationIcons = {
  segment: <img src={segment} width="30" height="30" alt="" />,
  dictionary: <SetsIco />,
  contact: <img src={contact} width="30" height="30" alt="" />,
};

const reactionIcons = {
  slackworkspace: <img src={slackIcon} width="24" height="24" alt="" />,
  totangotask: <img src={totango} width="24" height="24" alt="" />,
  churnzerotask: <img src={churnzero} width="24" height="24" alt="" />,
  loudnclearworkspace: <img src={lnc} width="24" height="24" alt="" />,
  marketocampaign: <img src={marketo} width="24" height="24" alt="" />,
  mailchimpcampaign: <img src={mailchimp} width="24" height="24" alt="" />,
  salesforceaccount: <img src={salesforce} width="24" height="24" alt="" />,
  hubspotworkspace: <img src={hubspot} width="24" height="24" alt="" />,
  zendeskaccount: <img src={zendesk} width="24" height="24" alt="" />,
};
const ReactorIconsTemplate = ({ reactorAbbr }) => {
  const service = reactorAbbr.split('.').slice(0, 2).join('');
  return (
    <div className="icon-chain">
      <QueryIcon color="black" />
      <img src={lineArrow} width="20" height="20" alt="" />
      {reactionIcons[service]}
    </div>
  );
};

export default ReactorIconsTemplate;
