/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { Avatar, Button } from 'antd';

import '../../Integrations/Facebook/Groups/styles.scss';
import tableIcon from '../../Assets/Images/icon-24-x-24-ic-24-view-table.svg';

export default class IntegrationDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.getIntegrationColor = this.getIntegrationColor.bind(this);
  }

  getAssets() {
    const assets = [];
    this.props.assets.forEach((asset) => {
      const a = assets.find((item) => item.id === asset.id);
      if (a === undefined) {
        assets.push(asset);
      }
    });
    // console.log(toJS(assets));
    return assets;
  }

  getBtnLabel(id) {
    switch (id) {
      case 1:
        return 'group';
      case 2:
        return 'page';
      case 3:
        return 'contacts';
      case 4:
        return 'page';
      default:
        return '';
    }
  }

  getAssetTitle(title) {
    if (this.isSmallScreen()) {
      if (title.length > 30) {
        return `${title.slice(0, 30)}...`;
      }
    }
    if (title.length > 50) {
      return `${title.slice(0, 20)}...`;
    }
    return title;
  }

  getIntegrationColor(name) {
    switch (name.toLowerCase()) {
      case 'slack workspace':
        return '#511652';
      case 'reddit subreddit':
        return 'rgb(255 63 24)';
      default:
        return '#55acee';
    }
  }

  isOnSocialAccountsScreen(asset) {
    return asset.integration_id !== 3;
  }

  isSmallScreen() {
    return window.screen.width < 1500;
  }

  render() {
    const integration = this.props.selectedIntegration;
    return (
      <>
        <div style={{ marginLeft: '35px', marginTop: '20px', height: 90 }}>
          <p className="integration-title">
            My
            {integration.title}
            s
          </p>
          <p className="integration-subtitle">
            {this.getAssets().length}
            {' '}
            {integration.title}
            s connected
          </p>
        </div>
        <div className="connected-wrapper">
          {this.getAssets()?.map((asset, ind) => (
            <div key={`${asset.name}-${ind}`} className="connected-list-item"> {/* eslint-disable-line */}
              <div
                className="connected-list-item_border"
                style={{
                  backgroundColor: this.getIntegrationColor(integration.title),
                }}
              />
              <div className="connected-list-item_avatar">
                {this.isOnSocialAccountsScreen(asset) ? (
                  <Avatar shape="square" size={60} src={asset.picture} />
                ) : (
                  <Avatar shape="square" size={20} src={tableIcon} />
                )}
              </div>
              <div className="connected-list-item_info">
                <div className="connected-list-item_info_title">
                  {this.getAssetTitle(asset.name)}
                </div>
                <div className="connected-list-item_info_desc">Connected</div>
              </div>
            </div>
          ))}
          <Button
            className="connected-add-btn"
            onClick={() => this.props.addAsset(integration)}
          >
            Add
            {' '}
            {this.getBtnLabel(integration.id)}
          </Button>
        </div>
      </>
    );
  }
}
