/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import SetsThreadView from '../../ThreadView';
import StylizedTable from '../../LNCTable/stylizedTable';
import SkeletonLine from '../../Skeletons/line';
import AvatarNameColumn from './columns/avatarNameColumn';
import SourceColumn from './columns/source';
import ToneColumn from './columns/tone';
import useQueryQueries from '../hooks/useQueryQueries';
import './queryResultTable.scss';
import Content from './columns/content';
import kFormatter from '../../../Utils/funcs/kFormatter';
import useSearchQuery from '../../../Utils/useSearchQuery';

const withSkeleton = (fn) => (info) => {
  const isLoading = info?.cell?.row?.original?.isLoading;
  if (isLoading) return <SkeletonLine style={{ height: '15px' }} />;
  return fn(info);
};

export default function TableView() {
  const {
    values: { filters },
  } = useFormikContext();
  const [interactionId] = useSearchQuery(['interaction']);
  const history = useHistory();
  const { matches } = useQueryQueries({ filters });
  const totalResults = matches?.data.pages[0]?.total ?? 0;

  const skeletons = useMemo(
    () => Array(12)
      .fill(null)
      .map((_, ind) => ({
        id: `loading-${ind}`,
        isLoading: true,
      })),
    [],
  );
  const columns = useMemo(
    () => [
      {
        accessorKey: 'originTime',
        header: 'Date',
        id: 'date',
        size: 140,
        cell: withSkeleton((info) => moment(info.getValue()).format('MMM DD, YYYY')),
      },
      {
        accessorFn: (row) => row.createdBy || row.email || 'Member',
        id: 'createdBy',
        header: 'Created By',
        size: 210,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: withSkeleton((info) => <AvatarNameColumn className="created-by_col" value={info.getValue()} />),
      },
      {
        accessorFn: (row) => row.linkedAccount || '',
        id: 'linkedAccount',
        header: 'Account',
        size: 210,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: withSkeleton((info) => <AvatarNameColumn className="account_col" value={info.getValue()} />),
      },
      {
        accessorKey: 'assetId',
        id: 'source',
        header: 'Source',
        size: 210,
        cell: withSkeleton((info) => <SourceColumn value={info.getValue()} />),
      },
      {
        accessorFn: (row) => ({ originalContent: row.originalContent, highlightContent: row.highlightContent }),
        accessorKey: 'originalContent',
        id: 'originalContent',
        header: 'Original Content',
        size: 430,
        cell: withSkeleton((info) => <Content ai={info.row.original?.ai} content={info.getValue()} />),
      },
      {
        accessorFn: (row) => row?.tone || 'NEUTRAL',
        id: 'tone',
        header: 'Tone',
        size: 80,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: withSkeleton((info) => <ToneColumn value={info.getValue()} />),
      },
      {
        accessorKey: 'assignedTo',
        id: 'assignedTo',
        header: 'Assigned To',
        size: 200,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: withSkeleton((info) => info.getValue()),
      },
    ],
    [],
  );
  const onRowClick = useCallback((row) => {
    history.replace(`${history.location.pathname}?interaction=${row.original.id}`);
  }, []);
  const onThreadCloseClick = useCallback(() => {
    history.replace(`${history.location.pathname}`);
  }, []);

  return (
    <div className="query-result-table relative-box">
      <SetsThreadView onClose={onThreadCloseClick} interactionId={interactionId} />
      <div className="query-result-table_metadata">
        {kFormatter(totalResults)}
        {' '}
        Interactions
      </div>
      <StylizedTable
        shouldShowSkeletonOnEmptyResult={false}
        columns={columns}
        skeletons={skeletons}
        infiniteQuery={matches}
        onRowClick={onRowClick}
      />
    </div>
  );
}
