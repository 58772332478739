import { createPortal } from 'react-dom';

export default function ClickOverlay({ onClick }) {
  return (
    createPortal(
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 53,
          width: '100%',
          cursor: 'auto',
          height: '100%',
        }}
        onClick={onClick}
      />,
      document.body,
    )
  );
}
