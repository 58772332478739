const firstStepTexts = {
  header: 'Facebook page integration',
  subtitle: 'Ways to use LoudNClear & Facebook page',
};

const secondStepTexts = {
  header: 'Facebook pages connected',
  subtitle:
    'LoudNClear is enabled to the following pages, select the page you would like to connect',
};

const texts = [firstStepTexts, secondStepTexts];

export default texts;
