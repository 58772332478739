/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react';
import Lottie from 'react-lottie';

import { ReactComponent as CloseIcon } from '../../Assets/Images/icons/dashboards/cat-ico_x-close.svg';

import config from '../../config/config.json';
import Dal from '../../Data/Dal';
import './bottomBoxLoader.scss';

import spinner from '../../Assets/Images/icons/lnc-spinner.json';
import spinnerDone from '../../Assets/Images/icons/lnc-notif-anim.json';

const loaderAnimation = (file) => ({
  loop: true,
  autoplay: true,
  animationData: file,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});

function BottomBoxLoader({
  isShown,
  position,
  title,
  text,
  notify,
  cssOverrides,
}) {
  // positions: absolute, fixed, OR NONE;
  const [hidden, setHidden] = useState(false);
  const [stage, setStage] = useState(0);
  const notifyClick = () => {
    setStage(1);
  };

  const makeZapier = () => {
    const body = {
      text: `Someone wants to be notified\n${Dal.user.email}\n${Dal.user.first_name} ${Dal.user.family_name}`,
    };
    fetch(config.Webhooks.get_notified, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  };

  useEffect(() => {
    if (!isShown) setStage(0);
  }, [isShown]);
  const css = useMemo(() => {
    const finalCss = {};
    finalCss.position = position;
    if (position) {
      Object.assign(finalCss, {
        bottom: '50px',
        top: 'auto',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 5,
      });
    }
    return finalCss;
  }, [position]);
  useEffect(() => {
    let timeout = null;
    if (stage === 1) {
      makeZapier();
      timeout = setTimeout(() => {
        setStage(0);
      }, 8000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [stage]);
  return (
    <AnimatePresence>
      {(isShown && !hidden) && (
        <motion.div
          className="loader-bg"
          style={{
            position: 'relative', top: 30, ...css, ...cssOverrides,
          }}
          animate={{ opacity: 1, x: '-50%', y: '0%' }}
          exit={{ opacity: 0, x: '-50%', y: '150%' }}
          initial={{ opacity: 0, x: '-50%', y: '150%' }}
        >
          <div className="inner-loader">
            <div>
              <Lottie
                options={loaderAnimation(stage === 0 ? spinner : spinnerDone)}
                height={70}
                width={70}
              />
            </div>
            <div className="inner-loader_text">
              <div className="title">
                {title || 'We are on it!'}
                <div onClick={() => setHidden(true)}><CloseIcon /></div>
              </div>
              <p>
                {stage === 0
                  ? text
                    || 'In a rush? we will let you know when something comes up.'
                  : 'We will notify you pretty soon.'}
                {notify && stage === 0 && (
                  <a
                    className="getnotified"
                    id="getnotified_btn"
                    onClick={() => notifyClick()}
                  >
                    Get notified
                  </a>
                )}
              </p>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default observer(BottomBoxLoader);
