import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import Spinner from '../Components/Skeletons/spinner';
import dalInstance from '../Data/Dal';
import NetworkManager from '../Network/NetworkManager';

const styles = {
  color: '#3480e5',
  fontWeight: 500,
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

const disabledStyle = {
  pointerEvents: 'none',
  opacity: 0.7,
};
const ButtonMobx = observer(({
  onClick, isDisabled, viewState, translateQuery,
}) => {
  if (!dalInstance.communities[0].name.toLowerCase().includes('airbnb')) {
    return <></>;
  }
  return (
    <div style={{ ...styles, ...(isDisabled ? disabledStyle : {}) }} className="translate-btn" onClick={onClick}>
      {isDisabled ? 'Text is already in English' : (
        viewState === 'original' ? 'See translation' : 'See original'
      )}
      <span style={{ marginLeft: '4px', transform: 'scale(0.85)' }}>{translateQuery.isLoading && <Spinner />}</span>
    </div>
  );
});

export default function useTranslate(startState, baseText) {
  const [viewState, setViewState] = useState(startState || 'original');
  const [isDisabled, setDisabled] = useState(false);
  const translateQuery = useQuery(['translation', baseText], () => NetworkManager.translateText(baseText), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    enabled: viewState === 'translated',
    retry: false,
    onSuccess: (response) => {
      if (response === 'Text is already in english') {
        setDisabled('Text is already in English');
        setViewState('original');
      }
    },
  });

  const text = useMemo(() => {
    if (viewState === 'original') return baseText;
    if (translateQuery.error) return <span style={{ color: '#ff4040' }}>Text cannot be translated</span>;
    if (translateQuery.data && translateQuery.data.translated_text) return translateQuery.data.translated_text;
    return baseText;
  }, [translateQuery.data, translateQuery.error, viewState, baseText]);

  const Button = () => {
    const clickHandler = (e) => {
      e.stopPropagation();
      setViewState((cur) => cur === 'original' ? 'translated' : 'original');
    };
    return (
      <ButtonMobx onClick={clickHandler} isDisabled={isDisabled} translateQuery={translateQuery} viewState={viewState} />
    );
  };

  return {
    viewState,
    text,
    Button,
  };
}
