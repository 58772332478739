import React from 'react';

import { Button, Space, Divider } from 'antd';
import { RedoOutlined, CheckCircleOutlined } from '@ant-design/icons';

import settingVideo from '../../../Assets/Video/Simpoco-FB-groups-integration.mp4';
import Dal from '../../../Data/Dal';

import functions from './functions';
import styles from './buttonsStyles';

export default class AuthorizeApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidateButtonEnabled: false,
      validateClicksCount: 0,
      isValidationSuccessfull: null,
    };
  }

  authorizeApp = () => {
    window.open(
      `https://facebook.com/groups/${this.props.group.id}/edit`,
      '_blank',
    );
    this.setState({ isValidateButtonEnabled: true });
  };

  clickCountCallback = () => {
    this.setState({ validateClicksCount: this.state.validateClicksCount + 1 });
  };

  validateAppSuccessCallback = (feed) => {
    const asset = {
      community_id: this.props.communityID,
      asset_id: this.props.group.id,
      token: this.props.accessToken,
      type: 'group',
      service_name: 'facebook',
      name: this.props.group.name,
      link: `https://facebook.com/groups/${this.props.group.id}`,
      picture: this.props.group.picture.data.url,
      member_count: this.props.group.member_count,
      token_secret: null,
    };
    this.props.onIntegrationSuccess();
    this.setState({ isValidationSuccessfull: true }, async () => {
      await Dal.createNewAssets([asset], this.props.backToAuthorizeCallback);
    });
  };

  validateAppErrorCallback = (feed) => {
    this.setState({ isValidationSuccessfull: false });
  };

  getButtonStyle = () => {
    if (this.state.isValidationSuccessfull) {
      return styles.validationSuccess;
    }
    if (
      this.state.isValidateButtonEnabled
        && this.state.validateClicksCount <= 10
    ) {
      return styles.validateAuthorizationEnabled;
    }

    return styles.validateAuthorizationBtn;
  };

  render() {
    return (
      <div>
        <p className="authorizeAppText">
          Authorize LoudNClear app to
          {' '}
          <b>{this.props.group.name}</b>
          {' '}
          group
        </p>
        <div className="overflowWrapper">
          <Space direction="vertical" size={20}>
            <Button style={styles.authorizeAppBtn} onClick={this.authorizeApp}>
              Authorize app in Facebook
            </Button>
            <Button
              disabled={
                !(this.state.isValidateButtonEnabled
                && this.state.validateClicksCount <= 10)
              }
              onClick={(event) => functions.isAppAuthorizedByGroup(
                this.clickCountCallback,
                this.props.group.id,
                this.props.accessToken,
                this.validateAppSuccessCallback,
                this.validateAppErrorCallback,
              )}
              style={this.getButtonStyle()}
              icon={
                this.state.isValidationSuccessfull ? (
                  <CheckCircleOutlined
                    style={{ marginLeft: -15, width: 20, height: 20 }}
                  />
                ) : (
                  <RedoOutlined
                    style={{ marginLeft: -15, width: 20, height: 20 }}
                  />
                )
              }
            >
              Validate authorization
            </Button>
          </Space>
          {this.state.isValidationSuccessfull === false ? (
            <p className="validationError">
              Can't validate group, please try again
            </p>
          ) : null}
          <p className="toAuthorizeAppText">
            To authorize LoudNClear app in your facebook group, please follow these
            steps, once done, return here to connect more groups
          </p>
          <video
            src={settingVideo}
            className="videoPlaceholder"
            type="video/mp4"
            controls
          />
        </div>
        <Divider className="facebookDivider video" />
        <Button style={styles.backBtn} onClick={this.props.goBack}>
          Back
        </Button>
      </div>
    );
  }
}
