import { Button } from 'antd';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import LNCModal from '..';
import './styles.scss';

export default function CreateModal({
  isShown, onClose, title, desc, onApply, icon, children, isLoading, className,
  applyText = 'Apply',
}) {
  const handleCreate = useCallback(() => {
    if (onApply) onApply();
  }, [onApply]);
  return createPortal(
    (
      <LNCModal className={`create-modal ${className || ''}`} isShown={isShown} onClose={onClose}>
        <div className="create-modal_content">
          <div className="create-modal_content_icon">
            {icon}
          </div>
          <div className="create-modal_content_title">
            {title}
          </div>
          <div className="create-modal_content_desc">
            {desc}
          </div>
          <div className="create-modal_content_body">
            {children}
          </div>
        </div>
        <div className="create-modal_footer">
          <Button className="btn_back" onClick={onClose}>Cancel</Button>
          <Button className="lnc-blue-btn" loading={isLoading} onClick={handleCreate}>{applyText}</Button>
        </div>
      </LNCModal>
    ), document.querySelector('body'),
  );
}
