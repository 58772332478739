import { Button } from 'antd';
import premium from '../../../Assets/Images/icons/automation/star.svg';

export default function AddButton({ isPremium, onClick }) {
  return (
    <Button
      className={`lnc-lightblue-btn automation-add ${isPremium ? 'premium' : ''}`}
    >
      <span>
        {isPremium && <img src={premium} width="24" height="24" alt="" />}
        {isPremium ? 'Coming soon' : 'Add'}
      </span>
    </Button>
  );
}
