import { Input } from 'antd';
import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import ClickOverlay from '../../../../../../Components/Modal/clickOverlay';
import DeleteModal from '../../../../../../Components/Modal/delete/deleteModal';
import { AnalyticsService } from '../../../../../../config/analytics';
import useTagsMutations from '../../../../../../Utils/community/tags/useTagsMutations';
import useTagsQuery from '../../../../../../Utils/community/tags/useTagsQuery';

import useDrawerContext from '../../../context/DrawerContext';
import './style.scss';
import TagLine from './tagLine';

export default function TagModal({
  currentSelected, isShown, pos, onClose,
}) {
  const [search, setSearch] = useState('');
  const [shouldShowDeleteConfirm, setDeleteConfirm] = useState(null);
  const { addCommunityTag, removeCommunityTag } = useTagsMutations();
  const { tags: { data: tagsData } } = useTagsQuery();
  const { updateTag } = useDrawerContext();
  const inputRef = useRef();
  const currentTagsDisplay = useMemo(() => {
    if (search && search.length > 1) {
      return tagsData.filter((f) => f.label.toLowerCase().includes(search.toLowerCase()));
    }
    return tagsData;
  }, [tagsData, search]);
  const shouldShowNewTag = useMemo(() => {
    if (search && search.length > 1) {
      const hasThisTag = currentTagsDisplay.find((f) => f.label.toLowerCase() === search.toLowerCase());
      if (!hasThisTag) {
        return true;
      }
    }
    return false;
  }, [search, currentTagsDisplay]);
  const handleNewTag = useCallback(() => {
    addCommunityTag.mutate(search, {
      onSuccess: (d) => {
        AnalyticsService.createTag(search);
        setSearch('');
        updateTag.mutate({ action: 'ADD', payload: { id: d.id, label: search } });
        AnalyticsService.assignTag(search);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return createPortal(
    <>
      {shouldShowDeleteConfirm && (
        <DeleteModal
          title={shouldShowDeleteConfirm.title}
          desc={shouldShowDeleteConfirm.desc}
          isShown={shouldShowDeleteConfirm}
          onDelete={shouldShowDeleteConfirm?.callback}
          onClose={() => setDeleteConfirm(null)}
          isLoading={removeCommunityTag.isLoading}
        />
      )}
      {isShown && (
        <ClickOverlay onClick={onClose} />
      )}
      {isShown && (
        <div
          className="tag-modal"
          style={pos}
        >
          <div className="tag-modal_inputwrapper">
            <Input
              ref={inputRef}
              placeholder="Search / Add a new tag"
              className="tag-modal_inputwrapper_input"
              bordered={false}
              onChange={(e) => setSearch(e.target.value)}
              onPressEnter={shouldShowNewTag ? handleNewTag : null}
              value={search}
              maxLength={30}
              style={{ flexGrow: 1 }}
            />
          </div>
          <div className="tag-modal_listwrapper">
            {currentTagsDisplay.map((x) => (
              <TagLine
                isSelected={currentSelected.includes(x.id)}
                key={x.id}
                label={x.label}
                id={x.id}
                updateTag={updateTag}
                setDeleteConfirm={setDeleteConfirm}
                removeCommunityTag={removeCommunityTag}
              />
            ))}
            {shouldShowNewTag && (
              <TagLine
                label={search}
                addCommunityTag={addCommunityTag}
                handleNewTag={handleNewTag}
                updateTag={updateTag}
                isLoading={addCommunityTag.isLoading || updateTag.isLoading}
                id="NEW"
              />
            )}
            {tagsData?.length === 0 && !shouldShowNewTag && (
              <div className="tag-modal_placeholder">
                You haven&apos;t created any tags yet.
                <div className="tag-modal_placeholder_link" onClick={() => inputRef.current.focus()}>Create a tag</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>,
    document.body,
  );
}
