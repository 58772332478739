/* eslint-disable consistent-return */
import { HiDotsHorizontal } from 'react-icons/hi';
import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { object, string } from 'yup';
import toast from 'react-hot-toast';
import { ReactComponent as TrashIcon } from '../../../Assets/Images/icons/dashboards/trash.svg';
import { ReactComponent as EditIcon } from '../../../Assets/Images/icons/dashboards/edit-widget.svg';
import { ReactComponent as HideIcon } from '../../../Assets/Images/icons/dashboards/eye-hide.svg';
import { ReactComponent as UnHideIcon } from '../../../Assets/Images/icons/dashboards/eye-full.svg';

import folderOpenIcon from '../../../Assets/Images/icons/folder-open.png';
import folderCloseIcon from '../../../Assets/Images/icons/folder-close.png';

import DropdownMenu from '../../DropdownMenu';
import Edittable from '../../Edittable';
import { FoldersContext } from '../dnd/useFolders';

const IconWrapper = ({ children }) => (
  <div style={{
    display: 'flex', width: '42px', alignItems: 'center', justifyContent: 'center',
  }}
  >
    {children}
  </div>
);

const MoreMenu = ({
  isHidden, handleEditClick, handleHideClick, handleDeleteClick,
}) => {
  const menu = [
    {
      key: 'edit',
      label: 'Rename',
      icon: <EditIcon />,
      onClick: handleEditClick,
    },
    {
      key: 'hide',
      label: isHidden ? 'Unhide' : 'Hide',
      icon: <IconWrapper>{isHidden ? <UnHideIcon /> : <HideIcon />}</IconWrapper>,
      onClick: handleHideClick,
    },
    {
      key: 'div1',
      divider: true,
    },
    {
      key: 'Remove',
      label: 'Remove',
      danger: true,
      icon: <TrashIcon />,
      onClick: handleDeleteClick,
    },
  ];
  return (
    <DropdownMenu
      button={(
        <div className="entity-item_actions_action dots">
          <HiDotsHorizontal />
        </div>
      )}
      items={menu}
    />
  );
};

const validationSchema = object({
  name: string().required('Folder name is required').matches(/^[a-zA-Z \d]+$/g, 'Folder name can only contain letters and numbers'),
});

export default function FolderItem({
  title, isNew, folderKey, onClick, id, isHidden,
}) {
  const [isEditting, setEditting] = useState(!!isNew);
  const {
    expandedFolders, updateFolder, createFolder, deleteFolder, toggleFolderHide,
  } = useContext(FoldersContext);
  const isExpanded = useMemo(() => expandedFolders.includes(folderKey), [expandedFolders, folderKey]);

  const saveHandler = useCallback(async (d) => {
    try {
      setEditting(false);
      await validationSchema.validate({ name: d });
      if (d) {
        if (!isNew) {
          updateFolder.mutate({
            id,
            name: d,
          });
        } else {
          createFolder.mutate({
            name: d || 'New folder',
          });
        }
      }
    } catch (e) {
      setEditting(true);
      if (e.name === 'ValidationError') {
        return toast.error(e.message);
      }
      throw e;
    }
  }, [id, isNew, updateFolder, createFolder]);
  return (
    <div className={`folder-item ${isHidden ? 'is-hidden' : ''}`} onClick={!isEditting ? onClick : undefined}>
      <div className="folder-item_title">
        <span className="chevy">
          <img src={isExpanded ? folderOpenIcon : folderCloseIcon} alt="Folder" width="20" height="20" />
        </span>
        <Edittable
          className="folder_edittable"
          disableClickEditting
          isEditting={isEditting}
          defaultValue={title}
          onChange={saveHandler}
        />
      </div>
      {id !== 'NEW' && id !== -1 && (
        <div onClick={(e) => e.stopPropagation()} className="folder-item_action">
          <MoreMenu isHidden={isHidden} handleHideClick={() => toggleFolderHide(folderKey)} handleEditClick={() => setEditting(true)} handleDeleteClick={() => deleteFolder.mutate({ id })} />
        </div>
      )}
      {isHidden && (
        <div className="folder-item_action_absolute hidden-icon">
          <HideIcon />
        </div>
      )}
    </div>
  );
}
