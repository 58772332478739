import { Layout } from 'antd';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';
import QueryNewView from './views/newView';
import '../../Components/Queries/style.scss';
import QueriesSidebar from './sidebar';

export default function QueriesScreen() {
  const { path } = useRouteMatch();

  return (
    <Layout className="queries-screen" style={{ flexDirection: 'row' }}>
      <QueriesSidebar />
      <Switch>
        <Route exact path={path}>
          <QueryNewView />
        </Route>
        <Route exact path={`${path}/new`}>
          <QueryNewView />
        </Route>
        <Route path={`${path}/:queryId`}>
          <QueryNewView />
        </Route>
        <Route path={`${path}/:queryId/edit`}>
          <QueryNewView />
        </Route>
      </Switch>
    </Layout>
  );
}
