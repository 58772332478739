/* eslint-disable react/no-danger */
import { uniqueId } from 'lodash';
import { useMemo } from 'react';
import upsellIco from '../../../../Assets/Images/icons/dictionaries/upsell-indic.svg';
import churnIco from '../../../../Assets/Images/icons/dictionaries/churn-indic.svg';

const icons = {
  upsell: upsellIco,
  churn: churnIco,
  topics: null,
};

const formatHighlightContent = (text) => {
  if (Array.isArray(text)) {
    // in case the text contains keywords matched (i,e highlightContent)
    // it is sanitized by elastic search, so it's safe to inject it.
    // originalContent is NOT safe and should not be injected!
    return text.map((row) => (
      <span
        className="hightlightRow"
        key={uniqueId('hightlightRow')}
        dangerouslySetInnerHTML={{ __html: row }}
      />
    ));
  }
};

export default function Content({ ai, content }) {
  const { originalContent, highlightContent } = content;
  const aiIcons = useMemo(() => Object.keys(ai).reduce((acc, cur) => {
    if (ai[cur] && icons[cur]) return [...acc, <img className="ai-icon" src={icons[cur]} width="22" height="22" alt={cur} />];
    return acc;
  }, []), [ai]);
  return (
    <div className="originalContent">
      {aiIcons}
      {highlightContent.length ? formatHighlightContent(highlightContent) : originalContent}
    </div>
  );
}
