import { MdFileDownload } from 'react-icons/md';
import { useMemo } from 'react';
import Heading from '../../common/heading';
import { ReactComponent as NextSVG } from '../../../../Assets/Images/icons/nextarrow.svg';

import contactIcon from '../../../../Assets/Images/icons/data-type-ico-contact-list.svg';
import accountIcon from '../../../../Assets/Images/icons/data-type-ico-account-data.svg';
import productIcon from '../../../../Assets/Images/icons/data-type-ico-product-usage.svg';
import surveysIcon from '../../../../Assets/Images/icons/data-type-ico-surveys.svg';
import StepWrapper from '../../common/stepWrapper';

const dataTypes = [
  {
    key: 'contacts',
    title: 'Contact List',
    desc: 'Customer lists, contacts or user lists',
    icon: contactIcon,
  },
  {
    key: 'accounts',
    title: 'Account Data',
    desc: 'Some explanation regarding account data',
    icon: accountIcon,
  },
  {
    key: 'actions',
    title: 'Product Usage',
    desc: 'User lists containing particular events',
    icon: productIcon,
    premium: true,
  },
  {
    key: 'interactions',
    title: 'Surveys',
    desc: 'Lists containing surveys, feedback forms etc.',
    icon: surveysIcon,
    premium: true,
  },
];

export default function DataTypeStep({ globalContext, updateContext, setStep }) {
  const clickHandler = (dataType) => {
    updateContext({ listType: dataType });
    setStep((cur) => cur + 1);
  };

  return (
    <StepWrapper step="datatype" className="step-datatype step-component">
      <Heading overrides={{ marginBottom: '15px' }} icon={<></>} title="Describe your CSV file" />
      <div className="datatypes">
        {dataTypes.map((d) => (
          <DataType
            key={d.key}
            icon={d.icon}
            title={d.title}
            desc={d.desc}
            premium={d.premium}
            isSelected={globalContext.listType === d.key}
            onClick={() => clickHandler(d.key)}
          />
        ))}
      </div>
    </StepWrapper>
  );
}

function DataType({
  icon, title, desc, onClick, isSelected, premium,
}) {
  const classes = useMemo(() => {
    const list = ['datatype'];
    if (isSelected) list.push('selected');
    if (premium) list.push('premium');
    return list.join(' ');
  }, [premium, isSelected]);
  return (
    <div className={classes} onClick={onClick}>
      <div className="datatype-icon">
        <img src={icon} width="30" alt="icon" />
      </div>
      <div className="datatype-info">
        <div className="datatype-info_title">{title}</div>
        <div className="datatype-info_desc">{desc}</div>
      </div>
      <div className="datatype-next">
        <a href="#test" className="exampleFile">
          <span>Example file</span>
          <MdFileDownload />
        </a>
        <NextSVG />
      </div>
    </div>
  );
}
