import React from 'react';

import { observer } from 'mobx-react';
import { Modal, Button, Divider } from 'antd';
import { makeObservable, observable, runInAction } from 'mobx';

import Dal from '../../Data/Dal';
import salesforceConnectImage from '../../Assets/Images/redesign/ico-integrate-with-salesforce.svg';
import salesforceConnectSuccessImage from '../../Assets/Images/redesign/ico-integration-succes-with-salesforce.svg';

import './styles.scss';

class SalesforceModal extends React.Component {
  activeStep = 0;

  constructor() {
    super();

    makeObservable(this, {
      activeStep: observable,
    });
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const salesforce = params.get('salesforce');
    if (salesforce) {
      runInAction(() => { this.activeStep = 1; });
    }
  }

  getAuthCode() {
    const url = `https://login.salesforce.com/services/oauth2/authorize?client_id=${
      process.env.REACT_APP_SALESFORCE_CLIENT_ID
    }&redirect_uri=${
      process.env.REACT_APP_SALESFORCE_REDIRECT_URI
    }&response_type=code&state=${Dal.getCurrentCommunityId()}&prompt=consent`;
    window.location = url;
  }

  connectMoreAccounts() {
    window.history.replaceState({}, '', '/dashboard/integrations');
    runInAction(() => { this.activeStep = 0; });
  }

  render() {
    return (
      <Modal
        visible
        className={
          this.activeStep === 0
            ? 'integrationModal'
            : 'integrationModal salesforce success'
        }
        wrapClassName="integrationModalWrapper"
        closable={false}
        footer={null}
        onCancel={this.props.onClose}
      >
        {this.activeStep === 0 ? (
          <>
            <img src={salesforceConnectImage} alt="" />
            <p className="pagesHeader">Salesforce account integration</p>
            <Divider />
            <ul className="textList">
              <li>
                Gain insights related to content in your group (Posts and
                Comments)
              </li>
              <li id="secParagraph">
                View members related data (this will be relevant only to
                LoudNClear app opt-in members)
              </li>
            </ul>
            <p className="Simpoco-automaticall">
              LoudnClear automaticall will get all the posts and comments in
              your feed. Our team of analysts will analyze thee content and send
              you a weekly report.
            </p>
            <Divider />
            <Button className="connectSalesforceBtn" onClick={this.getAuthCode}>
              Connect with Salesforce
            </Button>
            <p className="closeSalesforceText" onClick={this.props.onClose}>
              Close
            </p>
          </>
        ) : (
          <>
            <img src={salesforceConnectSuccessImage} width={240} alt="" />
            <p className="salesforceSuccessHeader">
              Salesforce account connected
            </p>
            <p className="salesforceSuccessSmall">
              LoudnClear is enabled to your Salesforce account.
            </p>
            <Button
              className="connectSalesforceBtn success"
              onClick={this.props.getSalesforceInsights}
            >
              Get insights
            </Button>
            <p className="closeSalesforceText" onClick={this.props.onClose}>
              Connect more accounts
            </p>
          </>
        )}
      </Modal>
    );
  }
}

export default observer(SalesforceModal);
