/* eslint-disable react-hooks/exhaustive-deps */
import dateIcon from '../../../../../../../../../../../Assets/Images/icons/cat-ico-date.svg';
import segIcon from '../../../../../../../../../../../Assets/Images/icons/revamp/cat-ico-segment.svg';
import emIcon from '../../../../../../../../../../../Assets/Images/icons/revamp/cat-ico-assigned-employee.svg';
import contactIcon from '../../../../../../../../../../../Assets/Images/icons/cat-ico-user.svg';
import accountIcon from '../../../../../../../../../../../Assets/Images/icons/cat-ico-case.svg';
import drawerUrlIcon from '../../../../../../../../../../../Assets/Images/icons/drawer-link.png';
import segmentUrlIcon from '../../../../../../../../../../../Assets/Images/icons/segments-link.png';

export const LNCFields = [
  {
    key: 'contact',
    label: 'Contact',
    icon: contactIcon,
    required: true,
    autoMatch: (fields) => fields.find((f) => f.type === 'Lookup' && f.referenceTo === 'Contact'),
  },
  {
    key: 'account',
    label: 'Account',
    icon: accountIcon,
    required: true,
    autoMatch: (fields) => fields.find((f) => f.type === 'Lookup' && f.referenceTo === 'Account'),
  },
  {
    key: 'account_owner',
    label: 'Employee',
    icon: emIcon,
    required: true,
    autoMatch: (fields) => fields.find((f) => f.type === 'Lookup' && f.referenceTo === 'User'),
  },
  {
    key: 'source',
    label: 'Interaction Link',
    icon: segmentUrlIcon,
    required: true,
  },
  {
    key: 'date',
    label: 'Date',
    icon: dateIcon,
    required: true,
  },
  {
    key: 'account_name',
    label: 'Account name',
    icon: drawerUrlIcon,
    required: true,
  },

  // {
  //   key: 'created_by',
  //   label: 'Created By',
  //   icon: segIcon,
  //   required: false,
  // },
  // {
  //   key: 'indication',
  //   label: 'Indication Name',
  //   icon: segIcon,
  //   required: false,
  // },
  {
    key: 'ai_categories_topics',
    label: 'AI Categories & Topics',
    icon: segIcon,
    required: true,
  },
  {
    key: 'ai_explanations',
    label: 'AI Explanations',
    icon: segIcon,
    required: true,
  },
  {
    key: 'subject',
    label: 'Subject',
    icon: segIcon,
    required: true,
  },
  {
    key: 'text',
    label: 'Text',
    icon: segIcon,
    required: true,
  },
];

export const LNCFieldsByKey = LNCFields.reduce((acc, cur) => { acc[cur.key] = cur; return acc; }, {});
