/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { Tooltip } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import {
  memo,
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import likeIcon from '../../../Assets/Images/icons/bubble-ico-like.svg';
import { ReactComponent as IconCopy } from '../../../Assets/Images/icons/icon-copy-fill.svg';
import { ReactComponent as IconShare } from '../../../Assets/Images/icons/icon-copy-share.svg';
import { ReactComponent as IconExpand } from '../../../Assets/Images/icons/icon-expand-fill.svg';
import chev from '../../../Assets/Images/icons/menuchevron-chevron-down.svg';
import useTranslate from '../../../Utils/useTranslate';
import AvatarView from '../../AvatarView/AvatarView';
import kFormatter from '../../../Utils/funcs/kFormatter';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    transition: {
      duration: 0.2,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: '115%' },
  show: { opacity: 1, y: '0%' },
  exit: { opacity: 0 },
};

function Thread({
  isLoader, isFirst, isLast,
  highlightedText,
  id, origin_time, user_name, link, text, likes, sentiment, isOrigin, subthreads = [],
}) {
  const { text: viewText, Button, viewState } = useTranslate('original', text);
  const [mouseIn, setMouseIn] = useState(false);
  const body = useRef();
  const [hasCopied, setCopied] = useState('');
  const [seeMore, setSeeMore] = useState(null); // NULL - Cannot see more, false - see more disabled, true - ...
  const copyInput = useRef();
  const modifiedLink = useMemo(() => {
    try {
      const convId = link.split('conversation/')[1].split('#')[0];
      const newLink = `https://app.intercom.com/a/inbox/ftfovpmf/inbox/conversation/${convId}`;
      return newLink;
    } catch (e) {
      return link;
    }
  }, [link]);
  const handleCopy = useCallback(() => {
    copyInput.current.value = text;
    copyInput.current.select();
    copyInput.current.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyInput.current.value);
    setCopied(true);
    setTimeout(() => {
      setCopied('');
    }, 1400);
  }, [copyInput, text]);

  useEffect(() => {
    if (body.current) {
      if (body.current.scrollHeight - 10 > 140) {
        // setSeeMore(false); Disabled until threads include comments
      }
    }
  }, [text]);

  return (
    <div className={`thread ${isLoader ? 'thread-loader' : ''} ${isOrigin ? 'thread-origin' : ''} ${isFirst ? 'is-first' : ''} ${isLast ? 'is-last' : ''}`}>
      <input type="text" style={{ position: 'absolute', visibility: 'hidden' }} className="copy-input" ref={copyInput} />
      <div
        className={`thread-inner ${isLoader ? 'thread-loader-inner' : ''} ${isFirst ? 'thread-first-inner' : ''} ${subthreads.length === 0 ? 'sole-thread' : ''}`}
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
      >
        <div className="thread-header">
          <div className="thread-header_avatar">
            {isLoader ? (
              <div className="isSkeleton" style={{ width: '30px', height: '30px', borderRadius: '50%' }} />
            ) : (
              <AvatarView
                id={id}
                loading={false}
                name={user_name || 'Member'}
                size={30}
                hideName
              />
            )}
          </div>
          <div className={`thread-header_name ${isLoader ? 'isSkeleton' : ''}`}>{user_name || 'Member'}</div>
          {!isLoader && mouseIn && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="thread-header_translate" style={{ marginLeft: '7px' }}>
              <Button />
            </motion.div>
          )}
          <div className="thread-header_spacer" />
          <AnimatePresence>
            {(mouseIn || hasCopied) && (
              <motion.div
                className="thread-header_actions"
                variants={container}
                initial="hidden"
                animate="show"
                exit="exit"
              >
                <motion.div style={{ position: 'relative' }} onClick={handleCopy} variants={item}>
                  <AnimatePresence>
                    {hasCopied && (
                      <motion.div
                        initial={{ opacity: 0, x: '-30px', y: '100%' }}
                        animate={{ opacity: 1, y: '0%' }}
                        exit={{ opacity: 0, y: '100%' }}
                        transition={{ type: 'linear', duration: 0.2 }}
                        className="copy-indicator"
                      >
                        Copied
                      </motion.div>
                    )}
                  </AnimatePresence>
                  <Tooltip color="white" title={<span style={{ color: 'black' }}>Copy</span>}>
                    <IconCopy />
                  </Tooltip>
                </motion.div>
                <motion.div variants={item}>
                  <Tooltip color="white" title={<span style={{ color: 'black' }}>Share (coming soon)</span>}>
                    <IconShare />
                  </Tooltip>
                </motion.div>
                <motion.div variants={item} onClick={() => { window.open(modifiedLink, '_blank'); }}>
                  <Tooltip color="white" title={<span style={{ color: 'black' }}>Expand</span>}>
                    <IconExpand />
                  </Tooltip>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="thread-body">
          <motion.div
            ref={body}
            className={`thread-body_message ${isLoader ? 'isSkeleton' : ''} ${seeMore !== null ? (seeMore ? 'expanded' : 'collapsed') : ''}`}
            style={isLoader ? { height: '140px' } : {}}
            animate={isLoader ? { height: '140px' } : {
              height: seeMore !== null ? (seeMore ? 'auto' : '400px') : 'auto',
            }}
          >
            <div className="message-inner">
              <pre>
                {viewState === 'original' ? text : viewText}
              </pre>
              {/* {viewState === 'original' ? highlightedText : viewText} */}
              {seeMore !== null && (
                <span onClick={() => setSeeMore(!seeMore)} className="see-more">
                  <img src={chev} width="16" height="16" alt="" />
                </span>
              )}
            </div>
          </motion.div>
          {!isLoader && (
            <div className="thread-body_footer">
              <div className="thread-body_footer_likes">
                {/* <div>
                  <img src={likeIcon} width="20" height="20" alt="like" />
                  <span>{kFormatter(likes || 0)}</span>
                </div> */}
              </div>
              <div className="thread-body_footer_time">
                {moment(origin_time, 'YYYY-MM-DD HH:mm:ss').fromNow()}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="thread-children">
        {subthreads.map((t, ind) => (
          <Thread key={t.id} isLast={ind === subthreads.length - 1} {...t} />
        ))}
      </div>
    </div>
  );
}

export default memo(Thread);
