import { Layout } from 'antd';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';
import PlaybooksHome from './views/home';
import PlaybooksAll from './views/all';
import PlaybooksViewer from './views/viewer';
import '../../Components/Playbooks/styles.scss';
import PlaybooksEdit from './views/edit';
import PlaybooksFormikWrapper from './wrappers/formik';

export default function PlaybooksScreen() {
  const { path } = useRouteMatch();

  return (
    <Layout className="playbooks-screen" style={{ flexDirection: 'row' }}>
      <div className="playbooks-screen_container">
        <Switch>
          <Route exact path={path}>
            <PlaybooksHome />
          </Route>
          <Route path={`${path}/all`}>
            <PlaybooksAll />
          </Route>
          <Route path={`${path}/new`}>
            <PlaybooksFormikWrapper>
              <PlaybooksEdit />
            </PlaybooksFormikWrapper>
          </Route>
          <Route path={`${path}/:playbookId/edit`}>
            <PlaybooksFormikWrapper>
              <PlaybooksEdit />
            </PlaybooksFormikWrapper>
          </Route>
          <Route path={`${path}/:playbookId`}>
            <PlaybooksViewer />
          </Route>

        </Switch>
      </div>
    </Layout>
  );
}
