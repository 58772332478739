import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { observer } from 'mobx-react';

import { ProgressBar } from './indicator';
import Dal from '../../../Data/Dal';
import checkmark from '../../../Assets/Images/icons/list-item-checkmark.svg';
import { Tooltip } from 'antd';

function Progress() {
  const history = useHistory();
  const steps = [
    {
      title: 'Onboarding complete',
      completed: true,
    },
    {
      title: 'Connect a platform',
      route: '/dashboard/integrations',
      completed: Dal.sources.length !== 0,
    },
    {
      title: 'Create your first set',
      route: '/dashboard/data?createSet',
      completed: Dal.dictionaries.length > 1,
    },
    {
      title: 'Create your first segment',
      route: '/dashboard/crm',
      completed: Dal.segments.segments.length > 0,
    },
    {
      title: 'Create new automation',
      disabled: 'Coming soon',
    },
  ];
  const handleClickItem = useCallback((item) => {
    if (item.route) history.push(item.route);
  }, [history]);
  return (
    <div className="myprogress">
      <div className="myprogress-heading">
        <div className="section-title">
          My Progress
        </div>
        <div className="section-title">
          {steps.filter((s) => s.completed).length}
          {' of '}
          5
        </div>
      </div>
      <div className="myprogress-progress">
        <ProgressBar max={5} current={steps.filter((s) => s.completed).length} />
      </div>
      <div className="myprogress-items">
        <AnimatePresence>
          {steps.map((s, ind) => (
            <Item key={s.title} onClick={() => handleClickItem(s)} ind={ind} route={s.route} disabled={s.disabled} isComplete={s.completed} title={s.title} />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
}

const Item = ({
  isComplete, title, ind, onClick, route, disabled,
}) => (
  <motion.div
    className={`myprogress-items_item ${isComplete ? 'checked' : ''} ${route ? 'hasRoute' : ''}`}
    initial={{ x: '80%', opacity: 0 }}
    animate={{ opacity: 1, x: '0%', transition: { delay: 0.15, duration: 0.4 + (ind / 10) } }}
    exit={{ opacity: 0 }}
    onClick={onClick}
  >
    <div className="myprogress-items_item_circle">
      <img src={checkmark} width="18" height="18" alt="checkmark" />
    </div>
    {disabled ? (

      <div className="myprogress-items_item_text isDisabled">
        <Tooltip title={<span style={{ color: 'black' }}>{disabled}</span>} color="white">
          {title}
        </Tooltip>
      </div>
    ) : (
      <div className="myprogress-items_item_text">
        {title}
      </div>
    )}
  </motion.div>
);

export default observer(Progress);
