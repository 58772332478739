import { Tooltip } from 'antd';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import chevronLeft from '../../../../../Assets/Images/icons/general-chevron-chevron-left.svg';
import xicon from '../../../../../Assets/Images/icons/general-x-icon-vari.svg';

function avatarName(name) { // eslint-disable-line
  if (name.split(' ')[1]) {
    return name.split(' ')[0].charAt(0).toUpperCase() + name.split(' ')[1].charAt(0).toUpperCase();
  }
  return name.charAt(0).toUpperCase();
}

function colorize(str) { // eslint-disable-line
  for (var i = 0, hash = 0; i < str.length; hash = str.charCodeAt(i++) + ((hash << 5) - hash)); // eslint-disable-line
  const color = Math.floor(Math.abs((Math.sin(hash) * 10000) % 1 * 16777216)).toString(16); // eslint-disable-line
  return '#' + Array(6 - color.length + 1).join('0') + color; // eslint-disable-line
}

const transformers = {
  number_of_employees: {
    key: () => 'Employees',
    value: (v) => Number.isInteger(+v) ? Number(v).toLocaleString() : v,
  },
  website: {
    key: () => 'Website',
    value: (v) => {
      const url = (() => {
        if (v.includes('http')) return v;
        return `https://${v}`;
      })();
      return <a href={url} target="_blank" rel="noreferrer" style={{ color: '#0084ff' }}>{v}</a>;
    },
  },
  description: {
    key: () => 'Description',
    value: (v) => v,
  },
};
export default function AccountCard({ account }) {
  const [accountCustoms, setAccountCustoms] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const infoItems = useMemo(() => {
    const exclude = ['created_date', 'custom_data', 'id', 'name'];
    return Object.keys(account).reduce((acc, cur) => {
      if (!exclude.includes(cur)) {
        if (account[cur]) {
          return [...acc, { label: cur, value: account[cur] }];
        }
      }
      return acc;
    }, []);
  }, [account]);
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const url = new URL(account.website.includes('http') ? account.website : `https://${account.website}`);
        await axios.get(`https://logo.clearbit.com/${url.host}`);
        setAvatar(<img src={`https://logo.clearbit.com/${url.host}`} width="70" height="70" alt="logo" />);
      } catch (e) {
        // do nothing
        setAvatar(avatarName(account.name || ''));
      }
    })();
  }, [account]);
  return (
    <div className="crmDrawer-account_card card">

      <div onClick={() => !expanded && setExpanded(true)} className={`card-heading ${expanded ? 'expanded' : ''}`}>
        <div className="card-opener">
          <img src={chevronLeft} width="20" height="20" alt="" />
        </div>
        <div onClick={() => setExpanded(false)} className="card-closer">
          <img src={xicon} width="20" height="20" alt="" />
        </div>
        {/* <div className="card-heading_avatar" style={{ backgroundColor: colorize(account.name || '') }}> */}
        <motion.div layoutId={`${account.id}-avatar`} className="card-heading_avatar" style={{ backgroundColor: 'white' }}>
          {/* {avatarName(account.name || '')} */}
          {avatar}
        </motion.div>
        <motion.div layout="position" layoutId={`${account.id}-name`} className="card-heading_name">
          {account.name}
        </motion.div>
        <motion.div className="card-heading_created">
          Created
          {' '}
          {moment(account.created_date, 'YYYY-MM-DD HH:mm:ss').format('MMM DD YYYY')}
        </motion.div>
      </div>
      <AnimatePresence>
        {expanded && (
          <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto' }} exit={{ height: 0 }}>
            {infoItems && infoItems.length !== 0 && (
              <>
                <div className="card-divider" />
                <div className="card-info">
                  {infoItems.map((item) => {
                    const keyName = transformers[item.label] ? (
                      transformers[item.label].key()
                    ) : (
                      item.label.charAt(0).toUpperCase() + item.label.slice(1)
                    );
                    const keyValue = transformers[item.label] ? (
                      transformers[item.label].value(item.value)
                    ) : (
                      item.value
                    );
                    return (
                      <Tooltip
                        key={item.label}
                        color="white"
                        mouseEnterDelay={0.3}
                        title={(
                          <span style={{ color: 'black' }}>
                            {keyName}
                            :
                            {' '}
                            <span style={{ fontWeight: 600 }}>{keyValue}</span>
                          </span>
                        )}
                      >
                        <div className="card-info_item">
                          <div>
                            {keyName}
                          </div>
                          <div>
                            {keyValue}
                          </div>
                        </div>
                      </Tooltip>
                    );
                  })}
                </div>
              </>
            )}
            {account.custom_data && typeof account.custom_data === 'object' && (
              <>
                <div className="card-divider" />
                {accountCustoms === false && (
                  <div onClick={() => setAccountCustoms(true)} className="account-info-expand">
                    More details
                    <div className="expandBlock">
                      <div className="expandBlock-img"><img src={chevronLeft} width="16" height="16" alt="" /></div>
                    </div>
                  </div>
                )}
                <AnimatePresence>
                  {accountCustoms && (
                    <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto', overflow: 'hidden' }} exit={{ height: 0 }} className="info-wrapper">
                      <div className="card-info">
                        {Object.keys(account.custom_data).length > 7 && (
                          <div className="hoverBlock">
                            {/* <div className="hoverBlock-img"><img src={chevronLeft} width="16" height="16" alt="" /></div> */}
                          </div>
                        )}
                        {Object.keys(account.custom_data).map((ke) => (
                          <Tooltip
                            key={ke}
                            color="white"
                            mouseEnterDelay={0.3}
                            title={(
                              <span style={{ color: 'black' }}>
                                {ke}
                                :
                                {' '}
                                <span style={{ fontWeight: 600 }}>{account.custom_data[ke]}</span>
                              </span>
                            )}
                          >
                            <div className="card-info_item" key={ke}>
                              <div>{ke}</div>
                              <div>{account.custom_data[ke]}</div>
                            </div>
                          </Tooltip>
                        ))}
                        <div onClick={() => setAccountCustoms(false)} className="account-info-expand">
                          Less details
                          <div className="expandBlock">
                            <div className="expandBlock-img"><img src={chevronLeft} width="16" height="16" alt="" /></div>
                          </div>
                        </div>
                      </div>

                    </motion.div>
                  )}
                </AnimatePresence>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
