/* eslint-disable camelcase */
import { useFormikContext } from 'formik';
// import { observer } from 'mobx-react';
import { useContext, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
// import { toJS } from 'mobx';
import Select from '../../Select';
// import Dal from '../../../Data/Dal';
import { BoardContext } from '../hooks/useBoard';
import chartTypes from './chartTypes';
import dimensionOptions from './options/dimension';
import measureFuncs from './options/measure_funcs';
import measureOptions from './options/measure_y';
// import stackbyOptions from './options/stackby';

const orFunction = (obj, params) => {
  if (typeof obj === 'function') {
    return obj(...params);
  }
  return obj;
};

const widgetSchemas = {
  validationSchema: Yup.object({
    type: Yup.string().required('Type is required'),
    measureY: Yup.string().required('Measure is required'),
    dimension: Yup.string().required('Dimension is required'),
    metadata: Yup.object().default({}),
  }),
  fieldsSchema: [
    {
      key: 'type',
      label: 'Chart type',
      Template: ({ value, onChange, error }) => (
        <Select value={value} isError={error} onChange={onChange}>
          {chartTypes.filter((t) => !t.disabled).map((t) => (
            <Select.Option key={t.type} value={t.type}>
              {t.icon}
              <span>{t.text}</span>
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      key: 'measureY',
      label: 'Measure',
      affects: ['dimension', 'metadata'],
      Template: ({ value, onChange, error }) => {
        const { isSegmentBoard } = useContext(BoardContext);
        const { values: { type: chartType } } = useFormikContext();
        const mOptions = useMemo(() => {
          if (isSegmentBoard) return measureOptions.filter((m) => m.key !== 'sources');
          if (chartType === 'wordcloud') return [measureOptions.find((m) => m.key === 'interactions')];
          return measureOptions;
        }, [isSegmentBoard, chartType]);

        useEffect(() => {
          if (chartType === 'wordcloud' && value !== 'interactions') {
            onChange('interactions');
          }
        }, [chartType, value]); // eslint-disable-line
        return (
          <Select value={value} onChange={onChange} isError={error}>
            {mOptions.map((t) => (
              <Select.Option key={t.key} value={t.key}>
                <t.icon />
                <span>{t.label}</span>
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      key: 'measureFunc',
      label: 'Measure function',
      affects: ['dimension', 'metadata'],
      showWhen: ({ values }) => !!values.measureY,
      Template: ({ value, onChange, error }) => {
        const { values } = useFormikContext();
        const fOptions = useMemo(() => {
          if (measureFuncs?.[values.measureY]) {
            if (values.type === 'wordcloud') return measureFuncs[values.measureY].filter((f) => f.key === 'word_count');
            return measureFuncs[values.measureY];
          }
          return [];
        }, [values]);
        useEffect(() => {
          if (fOptions.length !== 0) { // that means measureY was selected
            const found = fOptions.find((f) => f.key === value);
            if (!found) { // means measureY has changed and prev value of measureFunc no longer applicable
              if (fOptions.length === 1) {
                onChange(fOptions[0].key);
              } else {
                onChange(null);
              }
            }
          }
        }, [fOptions, value]); // eslint-disable-line
        if (fOptions.length === 0) return <></>;
        return (
          <Select value={value} onChange={onChange} isError={error}>
            {fOptions.map((t) => (
              <Select.Option key={t.key} value={t.key}>
                <t.icon />
                <span>{t.label}</span>
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      key: 'dimension',
      label: 'By',
      Template: ({ value, onChange, error }) => {
        const { values } = useFormikContext();
        const { measureY, measureFunc } = values;
        const options = useMemo(() => {
          if (dimensionOptions?.[measureY]?.[measureFunc]) {
            return dimensionOptions[measureY][measureFunc];
          }
          return [];
        }, [measureY, measureFunc]);
        useEffect(() => {
          if (!options.find((o) => o.key === value)) {
            onChange(null);
          }
        }, [options]); // eslint-disable-line
        return (
          <Select value={value} onChange={onChange} isError={error}>
            {options.map((t) => (
              <Select.Option key={t.key} value={t.key}>
                {t.icon && <t.icon />}
                <span>{t.label}</span>
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      key: 'timespan',
      showWhen: ({ values }) => values?.dimension === 'time' || values?.dimension?.includes('_time'),
      clearOnClose: true,
      label: 'Interval',
      Template: ({ value, onChange, error }) => {
        useEffect(() => {
          if (!value) onChange('daily');
          return () => onChange(undefined);
        }, []); // eslint-disable-line
        return (
          <Select value={value} placeholder="Choose" isError={error} onChange={onChange}>
            <Select.Option value="daily">Daily</Select.Option>
            <Select.Option value="weekly">Weekly</Select.Option>
            <Select.Option value="monthly">Monthly</Select.Option>
            <Select.Option value="quarterly">Quarterly</Select.Option>
            <Select.Option value="yearly">Yearly</Select.Option>
          </Select>
        );
      },
    },
  ],
};

export default widgetSchemas;
