import moment from 'moment';
import { useMemo } from 'react';
import PlaybookIcon from '../../../../Assets/Images/icons/playbooks/playbook-icon';
import { platformColorsByBg } from '../../../../Data/colors';
import PbAction from './action';
import PbStatus from './status';
import { reactorsById } from '../../new/flow/automationModal/_data/templates/reactors';
import defaultIntegrations from '../../../../Data/integrations';

export default function PlaybookLine({
  name,
  pbId,
  color,
  createdDate,
  runs,
  actionId,
  status,
}) {
  const platform = useMemo(() => {
    const sName = reactorsById?.[actionId]?.service_name;
    if (sName) {
      return defaultIntegrations.find((f) => f.targetTag === sName).image;
    }
    return '';
  }, [actionId]);
  const playbookColor = platformColorsByBg?.[color] ?? platformColorsByBg?.['#e6e6e6'];
  return (
    <div className={`pb pb-grid ${status === 'inactive' ? 'inactive' : ''}`}>
      <div className="pb-col-name">
        <div className="pb-col-icon" style={{ border: `1px ${status === 'inactive' ? 'dashed' : 'solid'} ${playbookColor?.textColor}` }}>
          <PlaybookIcon color={playbookColor?.textColor} />
        </div>
        {name}
      </div>
      <div className="pb-col-platforms">
        {platform && <img src={platform} width="24" height="24" alt="" />}
      </div>
      <div className="pb-col-created">{moment.utc(createdDate).fromNow()}</div>
      <div className="pb-col-runs">
        {runs}
      </div>

      <div className="pb-col-status">
        <PbStatus current={status} />
        {' '}
        <PbAction status={status} pbId={pbId} />
      </div>
    </div>
  );
}
