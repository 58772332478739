import { useQuery } from 'react-query';
import NetworkManager from '../../../../Network/NetworkManager';

export default function useUsageChart(communityId, contactId, fieldId, thresh, interval) {
  const resultsQuery = useQuery(['crm', contactId, 'usage', 'results', fieldId, thresh, interval], () => NetworkManager.getContactUsageData(communityId, contactId, fieldId, interval), {
    retry: 30,
    retryDelay: 1700 * 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!contactId && !!fieldId,
  });

  return { resultsQuery };
}
