import { Button } from 'antd';
import segment from '../../../Assets/Images/icons/segments-ico.svg';

export default function CreateSegmentView({ addSegmentFromExport }) {
  return (
    <div className="segment-view">
      <div className="segment-view_img">
        <img src={segment} width="80" height="80" alt="Create a segment" />
      </div>
      <div className="segment-view_title">
        Create a segment to export
      </div>
      <div className="segment-view_desc">
        <span>Create a segment</span>&nbsp;
        to see future members who meet your query, or revise your&nbsp;
        <span>Super Filter definitions</span>
        &nbsp;
        for wider results.
      </div>
      <div className="segment-view_btn">
        <Button className="btn_next" onClick={addSegmentFromExport}>Create segment and export</Button>
      </div>
    </div>
  );
}
