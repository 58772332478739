import { useMutation, useQueryClient } from 'react-query';
import { useCommunityContext } from '../../../Utils/contexts/community';
import NetworkManager from '../../../Network/NetworkManager';

export default function usePlaybooksMutations() {
  const { communityId } = useCommunityContext();
  const queryClient = useQueryClient();
  const refetchPlaybooksOnSuccess = {
    onSuccess: () => {
      queryClient.refetchQueries([communityId, 'playbooks']);
    },
  };
  const create = useMutation(
    (d) => NetworkManager.createNewPlaybook(communityId, d),
    refetchPlaybooksOnSuccess,
  );
  const update = useMutation(
    (d) => NetworkManager.updatePlaybook(communityId, d.id, d),
    refetchPlaybooksOnSuccess,
  );
  const updateStatus = useMutation(
    (d) => NetworkManager.updatePlaybookStatus(communityId, d.id, d.active),
    refetchPlaybooksOnSuccess,
  );
  const deletePlaybook = useMutation(
    (d) => NetworkManager.deletePlaybook(communityId, d.id),
    refetchPlaybooksOnSuccess,
  );
  const testPlaybook = useMutation(
    (d) => NetworkManager.testPlaybook(communityId, d.id),
  );
  return {
    create,
    update,
    deletePlaybook,
    updateStatus,
    testPlaybook,
  };
}
