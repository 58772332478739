import React, { Component } from 'react';
import { Avatar, Skeleton } from 'antd';

import './styles.scss';
import { observer } from 'mobx-react';
import Dal from '../../Data/Dal';
import { getInitials } from '../../Data/StringUtils';

import flag from '../../Assets/Images/icons/flag-ico.png';

class AvatarView extends Component {
  getName(props) {
    if (props.name === undefined || props.name.length === 0) {
      return 'Member';
    }
    const splitted = props.name.split(' ');
    if (splitted.length > 1) {
      return splitted.map((s) => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
    }
    return `${props.name.charAt(0).toUpperCase() + props.name.slice(1)}`;
  }

  getNameForImage(props) {
    if (props.name === 'Member') {
      return '';
    }
    return props.name;
  }

  getInitials(props) {
    if (props.name === 'Member') {
      return props.id;
    }
    return getInitials(this.getName(props));
  }

  getWidth(props) {
    if (props.size === undefined) {
      return 30;
    }
    return props.size;
  }

  getHeight(props) {
    if (props.size === undefined) {
      return 30;
    }
    return props.size;
  }

  render() {
    return (
      <Skeleton
        paragraph={{ rows: 0, width: 150 }}
        loading={this.props.loading}
      >
        <div className="avatar-container ellipsis">
          <div className="avatar-container_avatar">
            {this.getNameForImage(this.props).length > 0 ? (
              <Avatar
                className="avatar-initial-text"
                size={this.getWidth(this.props)}
                style={{
                  color: 'white',
                  flexShrink: 0,
                  boxSizing: 'content-box',
                  border: '2px solid white',
                  backgroundColor: Dal.getRandomSetColor(
                    this.getInitials(this.props),
                  ),
                }}
              >
                {this.getInitials(this.props)}
              </Avatar>
            ) : (
              <img
                alt=""
                src={Dal.getRandomAvatarImage(this.getInitials(this.props))}
                style={{
                  flexShrink: 0,
                  borderRadius: '50%',
                  border: '2px solid white',
                  boxSizing: 'content-box',
                  width: this.getWidth(this.props),
                  height: this.getHeight(this.props),
                  backgroundColor: Dal.getRandomSetColor(
                    this.getInitials(this.props),
                  ),
                }}
              />
            )}

            {this.props.hasFlag && (
              <img className="avatar_flag" src={flag} width="20" height="20" alt="Inaccurate" />
            )}
          </div>
          {this.props.hideName ? null : (
            <span
              style={{ marginLeft: this.props.hasFlag ? 25 : 15, color: '#0084ff' }}
              className="ellipsis"
            >
              {this.getName(this.props)}
            </span>
          )}
        </div>
      </Skeleton>
    );
  }
}

export default observer(AvatarView);
