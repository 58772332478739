import { useQuery } from 'react-query';
import NetworkManager from '../../../Network/NetworkManager';

export default function useInvite(email) {
  const validation = useQuery(['invite', email], () => NetworkManager.verifyInvitationByEmail(email), {
    enabled: !!email,
    retry: 0,
  });
  return {
    validation,
  };
}
