/* eslint-disable max-len */
import systemFields from '../../../../../Data/fields/_systemFieldsSchema';

const mapToSchemas = (fieldKeys) => fieldKeys.map((key) => systemFields[key]);

const dataTypes = {
  contacts: {
    fields: {
      mandatory: mapToSchemas(['email', 'name', 'last_name', 'origin_id']),
      optional: mapToSchemas(['phone', 'title', 'company', 'account_id', 'home_phone', 'street', 'state', 'city', 'department', 'created_date', 'type', 'birthdate', 'country', 'address']),
    },
    mandatory_count: 1,
  },
  accounts: {
    fields: {
      mandatory: mapToSchemas(['name', 'account_id']),
      optional: mapToSchemas(['phone', 'email', 'first_name', 'last_name', 'title', 'company', 'origin_id', 'home_phone', 'street', 'state', 'city', 'department', 'created_date', 'type', 'birthdate', 'country', 'address']),
    },
    mandatory_count: 1,
  },
};

export default dataTypes;
