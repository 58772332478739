/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React, { Component, useEffect } from 'react';
import { Layout } from 'antd';
import {
  BrowserRouter, Switch, Route, useLocation, withRouter,
} from 'react-router-dom';
import {
  makeObservable, observable, runInAction, toJS,
} from 'mobx';
import { observer } from 'mobx-react';
import Lottie from 'react-lottie';
import Dal from '../../Data/Dal';
import { Track } from '../../Components/Tracking/tracking';
import DictionaryView from '../Dashboard/DictionaryView';
import QueriesScreen from '../Queries';
// import HomeView from '../HomeView';
import CrmView from '../Crm/CrmView';
import Sidebar from '../../Components/Sidebar/index';

import '../../Assets/styles.scss';
import './homeStyles.scss';

import ModalAfterOnboarding from './modalAfterOnboarding';
import PremiumModal from '../../Components/PremiumModal/modal';

import IntegrationsView from '../Integrations/IntegrationsView';

import homeLoaderAnimationData from '../../Assets/Lotties/lnc-loader-posivite.json';
import InviteUserView from '../Dashboard/InviteUser/InviteUserView';
import HomeScreen from '../HomeScreen';
import DashboardsScreen from '../WidgetDashboards';
import ErrorBoundary from '../../Components/ErrorBoundary/errorBoundary';
import PlaybooksScreen from '../Playbooks';
import TopicsScreen from '../Topics';

const homeLoaderAnimation = {
  loop: true,
  autoplay: true,
  animationData: homeLoaderAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const { Content } = Layout;

class HomeContainer extends Component {
  loadingUserData = true;

  constructor(props) {
    super(props);
    this.state = {
      shouldShowWelcomeModal: false,
    };
    makeObservable(this, {
      loadingUserData: observable,
    });
    this.onRouteChange = this.onRouteChange.bind(this);
    Dal.fetchUser().then(() => {
      runInAction(() => {
        this.loadingUserData = false;
      });
    });
  }

  onRouteChange(location) {
    if (location?.search === '?invite') {
      runInAction(() => {
        Dal.showInviteUser = 1;
      });
    }
    let menInd = '0';
    switch (location.pathname) {
      case '/dashboard/home':
        menInd = '0';
        break;
      case '/dashboard/data':
        menInd = '1';
        break;
      case '/dashboard/crm':
        menInd = '2';
        break;
      case '/dashboard/integrations':
        menInd = '3';
        break;
      default:
        menInd = '0';
    }
    runInAction(() => {
      if (toJS(Dal.selectedMenuIndex) !== menInd) {
        Dal.selectedMenuIndex = menInd;
      }
    });
    if (Dal.isAfterOnboarding() && location.pathname !== '/dashboard/integrations') {
      this.setState({ shouldShowWelcomeModal: true });
    } else {
      this.setState({ shouldShowWelcomeModal: false });
    }
  }

  connectTapped() {
    if (Dal.isInInviteFlow) {
      runInAction(() => {
        Dal.isInInviteFlow = 0;
      });
      this.forceUpdate();
    } else {
      this.props.history.push('/dashboard/integrations');
      Track('Onboarding', 'Onboarding_WantToConnect');
    }
  }

  render() {
    return (
      <>
        {this.loadingUserData
          ? (
            <div className="home-spinner-container">
              {/* <Spin className="home-spinner"/> */}
              <div className="home-spinner">
                <Lottie
                  options={homeLoaderAnimation}
                  height={150}
                  width={150}
                />
              </div>
            </div>
          ) : (
            <BrowserRouter>
              <Layout
                style={{
                  height: '100%',
                  position: 'absolute',
                  left: '0px',
                  width: '100%',
                }}
              >
                <RouteDetector onRouteChange={this.onRouteChange} />
                <PremiumModal />
                <ModalAfterOnboarding
                  shouldRenderHelper={this.state.shouldShowWelcomeModal}
                  connectTapped={this.connectTapped.bind(this)}
                />
                <ErrorBoundary componentName="InviteUserView">
                  <InviteUserView visible={Dal.showInviteUser || this.props.location.search === '?invite'} />
                </ErrorBoundary>
                <ErrorBoundary componentName="Sidebar">
                  <Sidebar />
                </ErrorBoundary>
                <Layout>
                  <Content>
                    <Switch>
                      <Route path="/dashboard/home">
                        <HomeScreen />
                      </Route>
                      <Route path="/dashboard/queries">
                        <QueriesScreen />
                      </Route>
                      <Route path="/dashboard/playbooks">
                        <PlaybooksScreen />
                      </Route>
                      <Route path="/dashboard/boards">
                        <DashboardsScreen />
                      </Route>
                      <Route path="/dashboard/data">
                        <DictionaryView />
                      </Route>
                      <Route path="/dashboard/crm">
                        <CrmView />
                      </Route>
                      <Route path="/dashboard/topics">
                        <TopicsScreen />
                      </Route>
                      <Route path="/dashboard/integrations">
                        <IntegrationsView />
                      </Route>
                      <Route path="/dashboard/integrations?slack=true">
                        <IntegrationsView />
                      </Route>
                    </Switch>
                  </Content>
                </Layout>
              </Layout>
            </BrowserRouter>
          )}
      </>
    );
  }
}

function RouteDetector({ onRouteChange }) { // Utility component that detects if "?createSet" is present and redirects
  const location = useLocation();
  useEffect(() => {
    onRouteChange(location);
  }, [location]); // eslint-disable-line
  return (
    <></>
  );
}

export default withRouter(observer(HomeContainer));
