/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useMemo } from 'react';
import MiniSpinner from '../../../../../../Screens/Crm/Drawer/comps/common/miniSpinner';
import lncIcon from '../../../../../../Assets/Images/icons/icon-lnc.png';
import salesforceIcon from '../../../../../../Assets/Images/icons/repo-icon-salesforce.svg';
import dateIcon from '../../../../../../Assets/Images/icons/cat-ico-date.svg';
import segIcon from '../../../../../../Assets/Images/icons/revamp/cat-ico-segment.svg';
import emIcon from '../../../../../../Assets/Images/icons/revamp/cat-ico-assigned-employee.svg';
import contactIcon from '../../../../../../Assets/Images/icons/cat-ico-user.svg';
import accountIcon from '../../../../../../Assets/Images/icons/cat-ico-case.svg';
import drawerUrlIcon from '../../../../../../Assets/Images/icons/drawer-link.png';
import segmentUrlIcon from '../../../../../../Assets/Images/icons/segments-link.png';
import connector from '../../../../../../Assets/Images/icons/connector-arrow.png';
import { ReactComponent as Trash } from '../../../../../../Assets/Images/icons/automation/trash.svg';

import Select from '../../../../../Select';
import { useSalesforceFields } from '../../../../hooks/useSalesforceUtils';
import ErrorTooltip from '../../../../common/errorTooltip';

// "created_time": "..."
const LNCFields = [
  {
    key: 'contact',
    label: 'Contact',
    icon: contactIcon,
    required: true,
    autoMatch: (fields) => fields.find((f) => f.type === 'Lookup' && f.referenceTo === 'Contact'),
  },
  {
    key: 'account',
    label: 'Account',
    icon: accountIcon,
    required: true,
    autoMatch: (fields) => fields.find((f) => f.type === 'Lookup' && f.referenceTo === 'Account'),
  },
  {
    key: 'assigned_employee',
    label: 'Assigned Employee',
    icon: emIcon,
    required: true,
    autoMatch: (fields) => fields.find((f) => f.type === 'Lookup' && f.referenceTo === 'User'),
  },
  {
    key: 'segment_url',
    label: 'Segment URL',
    icon: segmentUrlIcon,
    required: true,
  },
  {
    key: 'drawer_url',
    label: 'Contact Drawer URL',
    icon: drawerUrlIcon,
    required: true,
  },
  {
    key: 'segment_name',
    label: 'Segment Name',
    icon: segIcon,
    required: false,
  },
  {
    key: 'created_time',
    label: 'Created Time',
    icon: dateIcon,
    required: false,
  },
];

const LNCFieldsByKey = LNCFields.reduce((acc, cur) => { acc[cur.key] = cur; return acc; }, {});

const SalesforceFieldsOptions = ({
  fields, updateMapping, mapping, lncFieldKey,
}) => {
  const unselectedFields = useMemo(() => {
    const selected = Object.values(mapping || {});
    return fields.filter((f) => !selected.includes(f.fullName));
  }, [fields, mapping]);
  const currentSelected = useMemo(() => fields.find((f) => f.fullName === mapping?.[lncFieldKey]), [mapping, fields, lncFieldKey]);
  return (
    <Select
      onChange={(v) => updateMapping(lncFieldKey, v)}
      dropdownShouldMatch
      allowClear={!!mapping?.[lncFieldKey]}
      placeholder="Select"
      showSearch
      value={mapping?.[lncFieldKey] || null}
    >
      {currentSelected && (
        <Select.Option value={currentSelected.fullName} className="sf-option-field">
          <div className="sf-option-field_inner">
            <div>{currentSelected.label}</div>
            <div>
              {currentSelected.fullName}
              {' '}
              (
              {currentSelected.type}
              )
            </div>
          </div>
        </Select.Option>
      )}
      {unselectedFields.map((field) => (
        <Select.Option value={field.fullName} className="sf-option-field">
          <div className="sf-option-field_inner">
            <div>{field.label}</div>
            <div>
              {field.fullName}
              {' '}
              (
              {field.type}
              )
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>

  );
};

export default function SalesforceWorkflowMapping() {
  const {
    values, setFieldValue, errors, touched, setFieldTouched,
  } = useFormikContext();
  const { reactor: { metadata } } = values;
  const { mapping } = metadata;
  const { fields: { error, data, isLoading } } = useSalesforceFields(metadata.object);
  const unselectedLNCFields = useMemo(() => {
    const selected = Object.keys(mapping || {});
    return LNCFields.filter((f) => !selected.includes(f.key));
  }, [mapping]);
  const canAddMoreFields = useMemo(() => {
    const lncFieldsMax = unselectedLNCFields.length === 0;
    const sfFieldsMax = Object.values(mapping || {}).length === data?.length;
    if (!lncFieldsMax && !sfFieldsMax) return true;
    return false;
  }, [mapping, data, unselectedLNCFields]);
  const updateMapping = useCallback((key, value) => {
    setFieldTouched(`reactor.metadata.mapping.${key}`);
    setFieldValue(`reactor.metadata.mapping.${key}`, value || null);
  }, []);
  const updateLNCMapping = useCallback((oldKey, newKey, value) => {
    setFieldValue(`reactor.metadata.mapping.${oldKey}`, undefined);
    setFieldValue(`reactor.metadata.mapping.${newKey}`, value || null);
  }, []);

  const removeMapping = useCallback((fieldKey) => {
    setFieldValue(`reactor.metadata.mapping.${fieldKey}`, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewFieldClick = useCallback(() => {
    updateMapping(unselectedLNCFields[0].key, null);
  }, [unselectedLNCFields, updateMapping]);
  useEffect(() => {
    if (data) {
      const final = values?.reactor?.metadata?.mapping || {};
      const required = LNCFields.filter((f) => f.required);
      required.forEach((f) => {
        if (!final?.[f.key]) {
          final[f.key] = f.autoMatch ? f.autoMatch(data)?.fullName || null : null;
        }
      });
      setFieldValue('reactor.metadata.mapping', final);
    } else {
      setFieldValue('reactor.metadata.mapping', values?.reactor?.metadata?.mapping || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      {isLoading && (
        <div className="text-center loader">
          <MiniSpinner />
          <span className="text">Loading fields...</span>
        </div>
      )}
      <AnimatePresence>
        {data && (
          <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto' }} className="mapping-grid">
            <div className="mapping-grid_line">
              <div className="mapping-grid_head">
                <img src={lncIcon} width="24" height="24" alt="LNC" />
                Column on
                {' '}
                <span>LoudNClear</span>
              </div>
              <div className="mapping-grid_head" />
              <div className="mapping-grid_head">
                <img src={salesforceIcon} width="24" height="24" alt="LNC" />
                Column on
                {' '}
                <span>Salesforce</span>
              </div>
            </div>
            {Object.keys(mapping || {}).sort((a, b) => LNCFieldsByKey[b].required - LNCFieldsByKey[a].required).map((lncFieldKey) => (
              <div key={lncFieldKey} className="mapping-grid_line">
                <div className="mapping-grid_item">
                  <div>
                    <Select
                      showSearch
                      value={lncFieldKey}
                      onChange={(v) => updateLNCMapping(lncFieldKey, v, mapping?.[lncFieldKey] || null)}
                      className={`sf-lnc-field ${LNCFieldsByKey[lncFieldKey].required ? 'is-required' : ''}`}
                    >
                      {LNCFieldsByKey[lncFieldKey].required ? (
                        <Select.Option key={lncFieldKey} value={lncFieldKey} className="sf-lnc-field-option">
                          {LNCFieldsByKey[lncFieldKey].icon && <img src={LNCFieldsByKey[lncFieldKey].icon} width="24" height="24" alt="" />}
                          {LNCFieldsByKey[lncFieldKey].label}
                        </Select.Option>
                      ) : (
                        <>
                          <Select.Option key={lncFieldKey} value={lncFieldKey} className="sf-lnc-field-option">
                            {LNCFieldsByKey[lncFieldKey].icon && <img src={LNCFieldsByKey[lncFieldKey].icon} width="24" height="24" alt="" />}
                            {LNCFieldsByKey[lncFieldKey].label}
                          </Select.Option>
                          {unselectedLNCFields.map((field) => (
                            <Select.Option key={field.key} value={field.key} className="sf-lnc-field-option">
                              {field.icon && <img src={field.icon} width="24" height="24" alt="" />}
                              {field.label}
                            </Select.Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </div>
                </div>
                <div className="mapping-grid_item arrow">
                  {touched?.reactor?.metadata?.mapping?.[lncFieldKey] && errors?.reactor?.metadata?.mapping?.[lncFieldKey] && (
                    <ErrorTooltip isShown text={errors?.reactor?.metadata?.mapping?.[lncFieldKey]} />
                  )}
                  <img src={connector} width="128" height="12" alt="" />
                </div>
                <div className="mapping-grid_item">
                  <SalesforceFieldsOptions updateMapping={updateMapping} fields={data} mapping={mapping} lncFieldKey={lncFieldKey} />
                </div>
                <div className="mapping-grid_item remove" onClick={() => removeMapping(lncFieldKey)}>
                  {!LNCFieldsByKey[lncFieldKey].required && <Trash />}
                </div>
              </div>
            ))}
            {canAddMoreFields && (
              <div className="mapping-grid_line">
                <div>
                  <Button onClick={handleNewFieldClick} className="add-field-btn">Add Field</Button>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {error && (
        <div className="text-center error-text">
          {error?.response?.status === 400 ? <>{error.response.data.message}</> : <>We have encountered a general error</>}
        </div>
      )}
    </div>
  );
}
