import { useField } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';

export default function PartWrapper({ name, partName, children }) {
  const [{ value }, { error }, { setError }] = useField(`${name}.${partName}`);
  useEffect(() => {
    if (!value) return;
    setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <div className="filter-part">
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="filter-part_error">
            {error}
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </div>
  );
}
