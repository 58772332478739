import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';
import useActivitiesFilterContext from '../../context/ActivitiesFilterContext';
import { ReactComponent as CloseIcon } from '../../../../../Assets/Images/icons/dashboards/close-vari.svg';

export default function KeywordsFilterDisplay() {
  const {
    filterWords, toggleKeyword, toggleDictionary, dictionaries,
  } = useActivitiesFilterContext();
  const totalItems = useMemo(() => [
    ...filterWords.map((kw) => ({
      id: kw.id,
      bgColor: kw.bgColor,
      textColor: kw.textColor,
      label: kw.label,
      type: 'word',
    })),
    ...dictionaries.map((d) => ({
      id: d.id,
      bgColor: d.color,
      textColor: d.textColor,
      label: d.name,
      type: 'dictionary',
    })),
  ], [filterWords, dictionaries]);
  return (
    <AnimatePresence>
      {totalItems.length > 0 && (
        <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto' }} exit={{ height: 0, overflow: 'hidden' }} className="activities-keywords">
          {/* <div className="activities-keywords_filter">Keywords Filter:</div> */}
          <AnimatePresence>
            {totalItems.map((wod) => (
              <motion.div
                className="activities-keywords_item"
                style={{ backgroundColor: wod.bgColor, color: wod.textColor }}
                onClick={() => wod.type === 'word' ? toggleKeyword(wod) : toggleDictionary(wod)}
                key={wod.label}
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ opacity: 0, scale: 0 }}
              >
                <CloseIcon stroke={wod.textColor} />
                <span>{wod.label}</span>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
