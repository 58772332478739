import { useMemo } from 'react';
import RecentPlaybooks from '../../../Components/Playbooks/home/collapses/recentPlaybooks';
import PlaybooksHomeHeader from '../../../Components/Playbooks/home/header';
import InfoBox from '../../../Components/Playbooks/home/infoBox';
import usePlaybooksQueries from '../../../Components/Playbooks/hooks/usePlaybooksQueries';

const numFormat = Intl.NumberFormat();
export default function PlaybooksHome() {
  const { playbooks, playbooksStats } = usePlaybooksQueries();
  const infoGraphics = useMemo(() => {
    const final = {
      total: 0, totalAction: 0, hours: 0, last24hours: 0,
    };
    if (playbooksStats.data && playbooks.data) {
      final.total = numFormat.format(playbooks.data.length);
      final.totalAction = numFormat.format(playbooksStats.data.total);
      final.hours = numFormat.format(playbooksStats.data.last30days / 2);
      final.last24hours = numFormat.format(playbooksStats.data.last1day);
    }
    return final;
  }, [playbooksStats.data, playbooks.data]);
  return (
    <div className="playbooks-page playbooks-page-home">
      <div className="title">Automate your way to exceptional customer service.</div>
      <div className="info-boxes">
        <InfoBox count={infoGraphics?.hours} title="Hours saved in the last 30 days" variation={1} />
        <InfoBox count={infoGraphics?.total} title="Playbooks" variation={2} />
        <InfoBox count={infoGraphics?.totalAction} title="Total action" variation={3} />
        <InfoBox count={infoGraphics?.last24hours} title="Actions in the last 24 hours" variation={4} />
      </div>
      <PlaybooksHomeHeader />
      <RecentPlaybooks />
    </div>
  );
}
