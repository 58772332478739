export default function hotjarProvider() {
  return {
    name: 'hotjar-plugin',
    // initialize: ({ config }) => {
    //   // load provider script to page
    // },
    // page: ({ payload }) => {
    //   // call provider specific page tracking
    // },
    track: ({ payload }) => {
      // call provider specific event tracking
      try {
        window.hj('event', payload.event);
      } catch (e) {
        console.log('failed hotjar track'); // eslint-disable-line
      }
    },
    identify: ({ payload }) => {
      // call provider specific user identify method
      try {
        window.hj('identify', payload.userId, {
          ...payload.traits,
        });
      } catch (e) {
        console.log('failed hotjar identify'); // eslint-disable-line no-console
      }
    },
    loaded: () => true,
  };
}
