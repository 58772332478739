/* eslint-disable arrow-body-style */
import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import * as Yup from 'yup';
import slackIcon from '../../../../../../../../Assets/Images/redesign/repo-icon-slack.svg';
import useTargets from '../../../../../../../../Utils/community/useTargets';
import LNCSelect from '../../../../../../../Select/LNCSelect';

const slackWorkspaceNotifyReactor = {
  id: 1,
  service_name: 'slack',
  service_type: 'workspace',
  action: 'notify',
  acceptedMetadata: ['target_id'],
  removeRetro: true,
  icon: <img src={slackIcon} width="24" height="24" alt="slack" />,
  validationSchema: Yup.object({
    internal_target_id: Yup.number().required('Please select a target'),
    target_id: Yup.string().required('Please select a target'),
    target_type: Yup.string().oneOf(['user', 'channel']).required('Please select a target'),
  }),
  initialMetadata: {
    internal_target_id: null,
    target_id: '',
    target_type: '',
  },
  text: 'Notify user / channel on Slack',
  templates: {
    ShortDisplay: () => {
      return (
        <span className="display">
          <img src={slackIcon} width="30" height="30" alt="slack" />
          Notify user / channel on Slack
        </span>
      );
    },
    Display: () => {
      const { values: { action: { metadata } } } = useFormikContext();
      const { allTargets } = useTargets();
      const target = useMemo(() => allTargets.find((f) => f.id === metadata.internal_target_id), [metadata, allTargets]);
      return (
        <span className="autotext">
          <span className="highlight">
            Notify
          </span>
          {' '}
          {`${metadata.target_type === 'user' ? '@' : '#'}${target?.name}`}
          {' '}
          on Slack
        </span>
      );
    },
    Edit: () => {
      const { allTargets } = useTargets();
      const { values: { action: { metadata } }, setFieldValue } = useFormikContext();
      // const targets = useMemo(() => Object.values(toJS(Dal.targets)).flat(), []);
      // const handleChange = useCallback((targetId) => {
      //   const tar = targets.find((t) => t.id === targetId);
      //   setFieldValue('reactor.metadata', {
      //     internal_target_id: targetId,
      //     target_type: tar.type,
      //     target_id: tar.metadata.id,
      //   });
      // }, [targets, setFieldValue]);
      const targets = useMemo(() => {
        return allTargets.map((t) => ({
          key: t.id,
          textLabel: t.name,
          label: (
            <div className="label-cf">
              <span>{t.type === 'user' ? '@' : '#'}</span>
              <span>{t.name}</span>
            </div>),
          group: t.type === 'user' ? 'Users' : 'Channels',
        })).sort((a) => a.group === 'User' ? 1 : -1);
      }, [allTargets]);
      const onChooseTarget = useCallback((internalId) => {
        const t = allTargets.find((b) => b.id === internalId);
        setFieldValue('action.metadata', {
          internal_target_id: internalId,
          target_id: t.metadata.id,
          target_type: t.type,
          asset_id: t.asset_id,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [allTargets]);
      return (
        <span className="automation-edit-container">
          <span className="highlight then">Then</span>
          {' '}
          notify
          {' '}
          <LNCSelect
            value={metadata?.internal_target_id}
            onSelect={onChooseTarget}
            isSearch
            placeholder="Select a target"
            searchPlaceholder="Find a target"
            items={targets}
          />
          {' '}
          on
          {' '}
          <span className="highlight flex-align platform">
            <img src={slackIcon} width="16" height="16" alt="slack" />
            Slack
          </span>
        </span>
      );
    },
  },
};

export default slackWorkspaceNotifyReactor;
