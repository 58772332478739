import { useCallback, useState } from 'react';

export default function useOneMutation(originMutation) {
  const [isLoading, setLoading] = useState(false);
  const onMutateAsync = useCallback(async (...v) => {
    setLoading(true);
    await originMutation.mutateAsync(...v);
    setLoading(false);
  }, [originMutation]);
  const onMutate = useCallback(async (values, options) => {
    setLoading(true);
    originMutation.mutate(values, {
      ...(options || {}),
      onSettled: () => setLoading(false),
    });
  }, [originMutation]);
  return {
    mutateAsync: onMutateAsync,
    mutation: {
      mutateAsync: onMutateAsync,
      mutate: onMutate,
      isLoading,
    },
    isLoading,
  };
}
