import './styles.scss';
import { ReactComponent as InfoIcon } from '../../../Assets/Images/icons/info-sml.svg';
import { useCRMContext } from '../../../Utils/community/useCRM';
import CrmViewTable from '../CrmTable/CrmViewTable';
import fallbackIcon from '../../../Assets/Images/icons/icon-placer.png';

export default function CRMHistoryView({
  sorting, setSorting, newSuperFilter,
  colors,
}) {
  const { crmHistory, combinedHistoryData } = useCRMContext();
  const { fetchNextPage, hasNextPage, isFetchingNextPage } = crmHistory;
  return (
    <div className="crm-history">
      <div className="crm-history-header">
        <InfoIcon />
        History view
      </div>
      {combinedHistoryData?.length === 0 && !crmHistory.isFetching && (
        <div className="crm-history-fallback">
          <img src={fallbackIcon} width="80" height="80" alt="No history" />
          <div className="crm-history-fallback_title">No history to show, yet</div>
          <div className="crm-history-fallback_desc">The pages of history will be written here soon</div>
        </div>
      )}
      {(combinedHistoryData?.length !== 0 || crmHistory.isFetching) && (
        <div className="crm-history-table-wrapper">
          <CrmViewTable
            isRowLoaded={({ index }) => !!combinedHistoryData?.[index]}
            markRowAsRead={() => null}
            loadMoreRows={() => hasNextPage && !isFetchingNextPage && fetchNextPage()}
            crmData={combinedHistoryData || []}
            colors={colors}
            sorting={sorting}
            setSorting={setSorting}
            isHistory
            // localFilterCrmData={localFilterCrmData}
            superFilter={newSuperFilter}
            localFilterCrmData={null}
            searchTerm={undefined}
          />
        </div>
      )}
    </div>
  );
}
