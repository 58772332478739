import React from 'react';

import {
  List, Avatar, Divider, Button, Space,
} from 'antd';
import checkbox from '../../../Assets/Images/redesign/checkmark-circ-selected.svg';

export default function UserPagesList(props) {
  return (
    <>
      <List
        itemLayout="horizontal"
        style={{ marginLeft: 60, width: 400 }}
        dataSource={props.pages}
        renderItem={(page) => (
          <List.Item extra={<img src={checkbox} width={24} alt="" />}>
            <List.Item.Meta
              className="pageData"
              avatar={(
                <Avatar
                  shape="square"
                  src={page.picture}
                  className="pageAvatar"
                />
              )}
              title={page.name}
              description={`${page.member_count} followers`}
            />
          </List.Item>
        )}
      />
      <Divider />
      <Space direction="vertical">
        <Button
          className="getInsigntsBtn"
          loading={props.isLoading}
          onClick={props.getInsignts}
        >
          Get insights
        </Button>
        <Button
          type="link"
          className="doLaterBtn"
          onClick={props.closeModalWithoutFetching}
        >
          I’ll do this later
        </Button>
      </Space>
    </>
  );
}
