import {
  lazy,
  useState,
  useCallback,
  useMemo,
  Suspense,
  useEffect,
} from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { Divider, Button, Typography } from 'antd';
import { observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';

import softAsync from '../../Utils/softAsync';

import userDetailsSchemas from './steps/userDetails/_fieldsSchema';
import companyDetailsSchemas from './steps/company/_fieldsSchema';
import workspaceDetailsSchemas from './steps/workspace/_fieldsSchema';
import referralsDetailsSchemas from './steps/referrals/_fieldsSchema';

import Dal from '../../Data/Dal';
import NetworkManager from '../../Network/NetworkManager';
import OnboardingContext from './context/onboardingContext';
import ReferralsDetails from './steps/referrals';

import UserDetails from './steps/userDetails/index';

import config from '../../config/config.json';
import InviteScreen from './steps/invite';

const { Title } = Typography;

// const UserDetails = lazy(() => import('./steps/userDetails/index.js'));
const VerifyEmail = lazy(() => import('./steps/verify/index'));
const CompanyDetails = lazy(() => import('./steps/company/index'));
const WorkspaceDetails = lazy(() => import('./steps/workspace/index'));
const IntegrationsStep = lazy(() => import('./steps/integrations/index'));

const generateInitials = (schema) => {
  const final = {};
  schema.forEach((field) => {
    final[field.key] = field.defaultValue;
  });
  return final;
};

const steps = {
  '-1': {
    Comp: InviteScreen,
    initials: {},
    validationSchema: null,
  },
  0: {
    Comp: UserDetails,
    initials: generateInitials(userDetailsSchemas.fieldsSchema),
    validationSchema: userDetailsSchemas.validationSchema,
  },
  1: {
    Comp: VerifyEmail,
    initials: {
      pin: ['', '', '', '', '', ''],
    },
    validationSchema: null,
  },
  2: {
    Comp: CompanyDetails,
    initials: generateInitials(companyDetailsSchemas.fieldsSchema),
    validationSchema: companyDetailsSchemas.validationSchema,
  },
  3: {
    Comp: WorkspaceDetails,
    initials: generateInitials(workspaceDetailsSchemas.fieldsSchema),
    validationSchema: workspaceDetailsSchemas.validationSchema,
  },
  4: {
    Comp: ReferralsDetails,
    initials: generateInitials(referralsDetailsSchemas.fieldsSchema),
    validationSchema: referralsDetailsSchemas.validationSchema,
  },
  5: {
    Comp: IntegrationsStep,
    initials: {},
    validationSchema: null,
  },
};

const notifySlack = (name, email) => {
  if (email.includes('loudnclear.ai')) return null;
  if (process.env.REACT_APP_ENV !== 'prod') return null;
  return fetch(config.Webhooks.onboarding_notify, {
    method: 'POST',
    body: JSON.stringify({
      text: `Started onboarding (PLATFORM)\n${name}\n${email}`,
    }),
  });
};

function OnboardingIndex({ step: currentStep, setStep }) {
  const [globalContext, setGlobalContext] = useState({});
  const [initted, setInitted] = useState(false);
  const history = useHistory();
  const { Comp: CurrentComp, initials, validationSchema } = steps[currentStep];

  const updateContext = useCallback((step, keysValues) => {
    setGlobalContext((current) => ({
      ...current,
      [step]: {
        ...current?.[step],
        ...keysValues,
      },
    }));
  }, []);

  useEffect(() => { // dataLayer events
    const events = {
      0: 'S1_Start',
      1: 'S2_Verification',
      2: 'S3_Company',
      3: 'S4_Workspace',
      4: 'S5_Keywords',
    };
    window.dataLayer = window.dataLayer || [];
    if (events[currentStep]) {
      window.dataLayer.push({
        event: events[currentStep],
      });
    }
  }, [currentStep]);

  useEffect(() => {
    // This effect triggers only on first load, and checks whether the user is in the onboarding process and assigns the current step for the user.
    if (!initted && history.location && updateContext && setStep) {
      window.setStep = (st) => {
        setStep(st);
      };

      if (history.location.pathname === '/onboarding/join') {
        const info = history.location.search.slice(1).split('&');
        const mappedInfo = info.reduce((acc, cur) => {
          const [key, val] = cur.split('=');
          acc[key] = val;
          return acc;
        }, {});
        window.sessionStorage.setItem('__onboardingInvite', JSON.stringify(mappedInfo));
        setStep(-1);
      }

      if (window.sessionStorage && window.sessionStorage.getItem('__onboardingVerification')) {
        // User has to continue onboarding verification
        const details = JSON.parse(window.sessionStorage.getItem('__onboardingVerification'));
        updateContext('userDetails', details);
        setStep(1); // move to verification page
      }

      Dal.fetchUser().then((user) => {
        // console.log('islogged', Dal.isLoggedIn);
        if (user) {
          updateContext('userDetails', {
            fullname: `${user.first_name} ${user.family_name}`,
            email: user.email,
          });
          if (!user.noCommunities) {
            history.push('/home');
          } else {
            setStep(2); // Since no community, redirect to create one
          }
          // console.log('is logged in', user);
        }
      }).finally(() => {
        setInitted(true);
      });
    }
  }, [updateContext, setStep, history, initted]);

  const submitHandlers = useMemo(
    () => ({
      '-1': async () => {
        setStep(0);
        return true;
      },
      /**
       *
       *
       * USER DETAILS
       *
       *
       */
      0: async (values, actions) => {
        // eslint-disable-next-line
        const [user, userError] = await softAsync(
          Dal.signUp(values.fullname, values.password, values.email),
        );
        if (userError) {
          const { code } = userError;
          // eslint-disable-next-line default-case
          switch (code) {
            case 'UsernameExistsException':
              actions.setFieldError(
                'email', (
                <>
                  Email already exists,
                  {' '}
                  <a href="/signin">Sign in</a>
                </>
              ),
              );
              updateContext('userDetails', {
                email: values.email,
                password: values.password,
                fullname: values.fullname,
              });
              actions.setSubmitting(false);
              // setStep((cur) => cur + 1);
              break;
          }
        } else {
          // User signup complete
          updateContext('userDetails', {
            email: values.email,
            password: values.password,
            fullname: values.fullname,
            role: values?.role,
          });
          try {
            notifySlack(values.fullname, values.email);
          } catch (e) {
            // nothing
          }
          // actions.resetForm();
          actions.setSubmitting(false);
          setStep((cur) => cur + 1);
        }
        return true;
      },
      /**
       *
       *
       * VERIFY STEP
       *
       *
       */
      1: async (values, actions) => {
        if (!values.pin) return false;
        updateContext('verifyEmail', {
          pinStatus: undefined, // removes previous errors
        });
        const pin = values.pin.join('');
        const { email, password } = globalContext.userDetails;
        const res = await Dal.confirmSignUp(email, pin);
        if (!res) {
          updateContext('verifyEmail', {
            pinStatus: 'failed',
          });
        } else {
          await Dal.signin(email, password, true);
          await NetworkManager.createNewUser(values?.role || '');
          if (window.sessionStorage.getItem('__onboardingInvite')) {
            // const inviteDetails = JSON.parse(window.sessionStorage.getItem('__onboardingInvite'));
            const r = await NetworkManager.attachUserToCommunity(email);
            if (r !== false) {
              window.sessionStorage.removeItem('__onboardingInvite');
              history.push('/home');
            }
          } else {
            setStep((cur) => cur + 1);
          }
        }
        actions.setSubmitting(false);
        return null;
      },
      /**
       *
       *
       * CREATE COMPANY STEP
       *
       *
       */
      2: async (values) => {
        updateContext('companyDetails', {
          companyName: values.companyName,
        });
        setStep((cur) => cur + 1);
      },
      /**
       *
       *
       * WORKSPACE STEP
       *
       *
       */
      3: async (values, actions) => {
        const { data } = await NetworkManager.createNewCommunity(
          globalContext.companyDetails.companyName,
          `${values.goals}`,
        );
        await Dal.fetchUser();
        updateContext('resolved', {
          community_id: data.community_id,
        });
        actions.setSubmitting(false);
        // setStep((cur) => cur + 1);
        history.push('/home');
      },
    }),
    [globalContext, updateContext, setStep] // eslint-disable-line
  );

  const currentSubmitHandler = useMemo(() => submitHandlers[currentStep], [submitHandlers, currentStep]);

  const isTooltipDisabled = useMemo(() => {
    if (currentStep === 5) {
      if (
        globalContext?.integrations
        && globalContext.integrations.integrated.length === 0
      ) {
        return false;
      }
    }
    return true;
  }, [globalContext, currentStep]);

  const isButtonDisabled = useMemo(
    () => !isTooltipDisabled,
    [isTooltipDisabled],
  );

  const ProviderValue = useMemo(() => [globalContext, updateContext], [globalContext, updateContext]);

  return (
    <OnboardingContext.Provider value={ProviderValue}>
      <Formik
        initialValues={initials}
        validationSchema={validationSchema}
        onSubmit={currentSubmitHandler}
      >
        {(props) => (
          <Form>
            <div className="inner-container">
              <div className="inner-header">
                <Title>
                  {globalContext?.general?.title || 'Welcome to LoudNClear!'}
                </Title>
                <p>{globalContext?.general?.desc}</p>
              </div>
              <Divider plain />
              <div className="thecontent">
                <Suspense fallback={<></>}>
                  <CurrentComp />
                </Suspense>
              </div>
              <Divider plain />
              <div className="inner-footer">
                <Indicator step={currentStep} maxSteps={6} />
                <ReactTooltip disable={isTooltipDisabled} id="submit-btn">
                  Connect at least one channel
                </ReactTooltip>
                <div className="inner-footer_buttons">
                  {currentStep >= 5 && (
                    <Button
                      id="onboarding-back"
                      onClick={() => setStep((cur) => cur - 1)}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    data-tip
                    data-for="submit-btn"
                    loading={props.isSubmitting}
                    className={
                      (isButtonDisabled || !props.isValid) && 'disabled'
                    }
                    id="onboarding-submit"
                    htmlType="submit"
                    onClick={(e) => (isButtonDisabled || !props.isValid) && e.preventDefault()}
                  >
                    {currentStep === 5 ? 'Done' : 'Next'}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </OnboardingContext.Provider>
  );
}

function Indicator({ step, maxSteps }) {
  return (
    <div className="indicator">
      <div
        className="indicator-thingy"
        style={{ width: `${((step + 1) / maxSteps) * 100}%` }}
      />
    </div>
  );
}

export default observer(OnboardingIndex);
