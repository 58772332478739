import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import './style.scss';

const container = {
  hidden: {},
  hide: {},
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      // when: 'afterChildren',
    },
  },
};

const item = {
  hidden: { scale: 1, opacity: 0, y: '100px' },
  hide: { opacity: 0, scale: 1, y: '0px' },
  show: {
    opacity: 1,
    scale: 1,
    y: '0px',
  },
};

export default function ListLoader({ amount, boxProps, boxChildren }) {
  const [loaderVariant, setLoader] = useState('hidden');
  useEffect(() => {
    const nextAnim = (() => {
      if (loaderVariant === 'hidden') return { anim: 'show', time: 250 };
      if (loaderVariant === 'hide') return { anim: 'hidden', time: 250 };
      return { anim: 'hide', time: 3000 };
    })();

    const inter = setTimeout(() => {
      setLoader(nextAnim.anim); // eslint-disable-line
    }, nextAnim.time);
    return () => clearTimeout(inter);
  }, [loaderVariant]);

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate={loaderVariant}
    >
      {Array(amount).fill(null).map((_, ind) => (
        <motion.div
          key={ind} // eslint-disable-line
          {...boxProps}
          className={`isSkeleton ${boxProps?.className ? boxProps.className : ''}`}
          variants={item}
          transition={{ type: 'tween', ease: 'easeOut', duration: loaderVariant === 'show' ? 0.5 : 0.35 }}
        >
          {boxChildren}
        </motion.div>
      ))}

    </motion.div>
  );
}
