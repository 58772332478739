import { useFormikContext } from 'formik';
import { useCommunityContext } from '../../../../../Utils/contexts/community';
import Select from '../../../common/select';
import GeneralUserMultiIcon from '../../../../../Assets/Images/redesign/generalMultiUserIcon';

const { Option } = Select;

const dictionaryInteractionMatchTrigger = {
  id: 4, // IDS ARE EQUIVALENT TO SQL
  entity: 'interaction',
  action: 'match',
  relation: 'dictionary',
  acceptedMetadata: [],
  validationSchema: null,
  templates: {
    Display: ({ metadata, isExample }) => {
      const { dictionaries } = useCommunityContext();
      return (
        <span>
          When an interaction is matched on
          {' '}
          <span className="highlight">
            {isExample ? 'Dictionary' : <>{dictionaries?.[metadata.dictionary_id]?.name}</>}
          </span>
        </span>
      );
    },
    Edit: () => {
      const { values, setFieldValue, touched } = useFormikContext();
      const { dictionaries } = useCommunityContext();
      const { trigger: { metadata } } = values;
      return (
        <span>
          <span className="highlight">When</span>
          {' '}
          an interaction is matched on
          {' '}
          <Select
            field="trigger.metadata.dictionary_id"
            isTouched={touched?.trigger?.metadata?.dictionary_id}
            filterOption={(input, option) => String(option.key).toLowerCase().includes(input.toLowerCase())}
            value={metadata?.dictionary_id ?? null}
            placeholder="Select"
            onChange={(e) => setFieldValue('trigger.metadata.dictionary_id', e)}
          >
            {Object.values(dictionaries).map((seg) => (
              <Option key={`${seg.id}-${seg.name}`} value={seg.id}>
                <GeneralUserMultiIcon fill={seg.color} />
                {seg.name}
              </Option>
            ))}
          </Select>
        </span>
      );
    },
  },
};

export default dictionaryInteractionMatchTrigger;
