import validations from './_validations';
import emailIco from '../../Assets/Images/icons/cat-ico-email.svg';
import userIco from '../../Assets/Images/icons/cat-ico-user.svg';
import addressIco from '../../Assets/Images/icons/cat-ico-address.svg';
import caseIco from '../../Assets/Images/icons/cat-ico-case.svg';
import dateIco from '../../Assets/Images/icons/cat-ico-date.svg';
import phoneIco from '../../Assets/Images/icons/cat-ico-phone.svg';
import tagIco from '../../Assets/Images/icons/cat-ico-tag.svg';
import globeIco from '../../Assets/Images/icons/cat-ico-globe.svg';

const systemFields = {
  email: {
    key: 'email',
    label: 'Email',
    validation: validations.email,
    icon: emailIco,
    connector: true,
  },
  origin_id: {
    key: 'origin_id',
    label: 'User ID',
    icon: userIco,
    validation: validations.origin_id,
    connector: true,
  },
  name: {
    key: 'name',
    label: 'Name / First name',
    icon: userIco,
    validation: validations.name,
    connector: true,
  },
  first_name: {
    key: 'first_name',
    label: 'First name',
    icon: userIco,
    validation: validations.first_name,
    connector: true,
  },
  last_name: {
    key: 'last_name',
    label: 'Last name',
    icon: userIco,
    validation: validations.last_name,
    connector: true,
  },
  phone: {
    key: 'phone',
    label: 'Phone',
    icon: phoneIco,
    validation: validations.phone,
  },
  home_phone: {
    key: 'home_phone',
    label: 'Home Phone',
    icon: phoneIco,
    validation: validations.home_phone,
  },
  created_date: {
    key: 'created_date',
    label: 'Created date',
    icon: dateIco,
    validation: validations.created_date,
  },
  birthdate: {
    key: 'birthdate',
    label: 'Birth date',
    icon: dateIco,
    validation: validations.birthdate,
  },
  type: {
    key: 'type',
    label: 'Tier / Type',
    icon: tagIco,
    validation: validations.type,
  },
  company: {
    key: 'company',
    label: 'Company',
    icon: caseIco,
    validation: validations.company,
    connector: true,
  },
  account_id: {
    key: 'account_id',
    label: 'Account ID',
    icon: userIco,
    validation: validations.account_id,
    connector: true,
  },
  department: {
    key: 'department',
    label: 'Department',
    icon: caseIco,
    validation: validations.department,
  },
  country: {
    key: 'country',
    label: 'Country',
    icon: globeIco,
    validation: validations.country,
  },
  city: {
    key: 'city',
    label: 'City',
    icon: addressIco,
    validation: validations.city,
  },
  state: {
    key: 'state',
    label: 'State',
    icon: addressIco,
    validation: validations.state,
  },
  street: {
    key: 'street',
    label: 'Street',
    icon: addressIco,
    validation: validations.street,
  },
  address: {
    key: 'address',
    label: 'Address',
    icon: addressIco,
    validation: validations.address,
  },
  title: {
    key: 'title',
    label: 'Title',
    icon: caseIco,
    validation: validations.title,
  },
};

export default systemFields;
