const reactionExamples = [
  // {
  //   id: 17,
  //   reactor: 'salesforce.account.create_opportunity',
  // },
  {
    id: 1,
    reactor: 'slack.workspace.notify',
  },
  {
    id: 2,
    reactor: 'salesforce.account.create_opportunity',
  },
  {
    id: 3,
    reactor: 'zendesk.account.update_custom_field',
  },
  {
    id: 1000,
    isPremium: true,
    reactor: 'hubspot.workspace.create',
  },
  {
    id: 1001,
    isPremium: true,
    reactor: 'salesforce.account.update',
  },
  {
    id: 1006,
    isPremium: true,
    reactor: 'hubspot.workspace.update',
  },
  {
    id: 1008,
    isPremium: true,
    reactor: 'loudnclear.workspace.email',
  },
];

export default reactionExamples;
