import { Button } from 'antd';
import { useState } from 'react';
import tagIco from '../../../Assets/Images/icons/tag.svg';
import TopicCreate from '../create';

export default function TopicsHeader() {
  const [createModal, setCreateModal] = useState(false);
  return (
    <div className="topics-header">
      {createModal && <TopicCreate isShown={createModal} onClose={() => setCreateModal(false)} />}
      <div className="topics-header_icon" layout="position">
        <div className="topics-header_icon_inner">
          <img src={tagIco} width="30" height="30" alt="" />
        </div>
      </div>
      <div className="topics-header_text" layout="position">
        <div>
          My Topics
        </div>
      </div>
      <div className="gapper" />
      <Button onClick={() => setCreateModal(true)} className="lnc-blue-btn">Create topic</Button>
    </div>
  );
}
