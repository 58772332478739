import { useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from 'antd';
import { useSalesforceFields } from './hooks/useSalesforce';
import { LNCFields, LNCFieldsByKey } from './_data/fields';
import MiniSpinner from '../../../../../../../../../Loaders/miniSpinner';
import Select from '../../../../../../../../../Select';
import lncIcon from '../../../../../../../../../../Assets/Images/icons/icon-lnc.png';
import connector from '../../../../../../../../../../Assets/Images/icons/connector-arrow.png';
import { ReactComponent as Trash } from '../../../../../../../../../../Assets/Images/icons/automation/trash.svg';

import salesforceIcon from '../../../../../../../../../../Assets/Images/icons/repo-icon-salesforce.svg';

const ErrorTooltip = ({ isShown, text }) => (
  <AnimatePresence>
    {isShown && (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="at-tooltip at-tooltip-error">
        {text}
      </motion.div>
    )}
  </AnimatePresence>
);

const SalesforceFieldsOptions = ({
  fields, updateMapping, mapping, lncFieldKey,
}) => {
  const unselectedFields = useMemo(() => {
    const selected = Object.values(mapping || {});
    return fields.filter((f) => !selected.includes(f.fullName));
  }, [fields, mapping]);
  const currentSelected = useMemo(() => fields.find((f) => f.fullName === mapping?.[lncFieldKey]), [mapping, fields, lncFieldKey]);
  return (
    <Select
      onChange={(v) => updateMapping(lncFieldKey, v)}
      dropdownShouldMatch
      allowClear={!!mapping?.[lncFieldKey]}
      placeholder="Select"
      showSearch
      value={mapping?.[lncFieldKey] || null}
    >
      {currentSelected && (
        <Select.Option value={currentSelected.fullName} className="sf-option-field">
          <div className="sf-option-field_inner">
            <div>{currentSelected.label}</div>
            <div>
              {currentSelected.fullName}
              {' '}
              (
              {currentSelected.type}
              )
            </div>
          </div>
        </Select.Option>
      )}
      {unselectedFields.map((field) => (
        <Select.Option value={field.fullName} key={field.fullName} className="sf-option-field">
          <div className="sf-option-field_inner">
            <div>{field.label}</div>
            <div>
              {field.fullName}
              {' '}
              (
              {field.type}
              )
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>

  );
};

export default function SalesforceWorkflowMapping() {
  const {
    values, setFieldValue, errors, touched, setFieldTouched,
  } = useFormikContext();
  const { action: { metadata } } = values;
  const { mapping } = metadata;
  const { fields: { error, data, isLoading } } = useSalesforceFields(metadata.object);
  const unselectedLNCFields = useMemo(() => {
    const selected = Object.keys(mapping || {});
    return LNCFields.filter((f) => !selected.includes(f.key));
  }, [mapping]);
  const canAddMoreFields = useMemo(() => {
    const lncFieldsMax = unselectedLNCFields.length === 0;
    const sfFieldsMax = Object.values(mapping || {}).length === data?.length;
    if (!lncFieldsMax && !sfFieldsMax) return true;
    return false;
  }, [mapping, data, unselectedLNCFields]);
  const updateMapping = useCallback((key, value) => {
    setFieldTouched(`action.metadata.mapping.${key}`);
    setFieldValue(`action.metadata.mapping.${key}`, value || null);
  }, []);
  const updateLNCMapping = useCallback((oldKey, newKey, value) => {
    setFieldValue(`action.metadata.mapping.${oldKey}`, undefined);
    setFieldValue(`action.metadata.mapping.${newKey}`, value || null);
  }, []);

  const removeMapping = useCallback((fieldKey) => {
    setFieldValue(`action.metadata.mapping.${fieldKey}`, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewFieldClick = useCallback(() => {
    updateMapping(unselectedLNCFields[0].key, null);
  }, [unselectedLNCFields, updateMapping]);
  useEffect(() => {
    if (data) {
      const final = values?.action?.metadata?.mapping || {};
      const required = LNCFields.filter((f) => f.required);
      required.forEach((f) => {
        if (!final?.[f.key]) {
          final[f.key] = f.autoMatch ? f.autoMatch(data)?.fullName || null : null;
        }
      });
      setFieldValue('action.metadata.mapping', final);
    } else {
      setFieldValue('action.metadata.mapping', values?.action?.metadata?.mapping || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      {isLoading && (
        <div className="text-center loader">
          <MiniSpinner />
          <span className="text">Loading fields...</span>
        </div>
      )}
      <AnimatePresence>
        {data && (
          <motion.div initial={{ height: 0, overflow: 'hidden' }} animate={{ height: 'auto' }} className="mapping-grid">
            <div className="mapping-grid_line">
              <div className="mapping-grid_head">
                <img src={lncIcon} width="24" height="24" alt="LNC" />
                Column on
                {' '}
                <span>LoudNClear</span>
              </div>
              <div className="mapping-grid_head" />
              <div className="mapping-grid_head">
                <img src={salesforceIcon} width="24" height="24" alt="LNC" />
                Column on
                {' '}
                <span>Salesforce</span>
              </div>
            </div>
            {Object.keys(mapping || {}).sort((a, b) => LNCFieldsByKey[b].required - LNCFieldsByKey[a].required).map((lncFieldKey) => (
              <div key={lncFieldKey} className="mapping-grid_line">
                <div className="mapping-grid_item">
                  <div>
                    <Select
                      showSearch
                      value={lncFieldKey}
                      onChange={(v) => updateLNCMapping(lncFieldKey, v, mapping?.[lncFieldKey] || null)}
                      className={`sf-lnc-field ${LNCFieldsByKey[lncFieldKey].required ? 'is-required' : ''}`}
                    >
                      {LNCFieldsByKey[lncFieldKey].required ? (
                        <Select.Option key={lncFieldKey} value={lncFieldKey} className="sf-lnc-field-option">
                          {LNCFieldsByKey[lncFieldKey].icon && <img src={LNCFieldsByKey[lncFieldKey].icon} width="24" height="24" alt="" />}
                          {LNCFieldsByKey[lncFieldKey].label}
                        </Select.Option>
                      ) : (
                        <>
                          <Select.Option key={lncFieldKey} value={lncFieldKey} className="sf-lnc-field-option">
                            {LNCFieldsByKey[lncFieldKey].icon && <img src={LNCFieldsByKey[lncFieldKey].icon} width="24" height="24" alt="" />}
                            {LNCFieldsByKey[lncFieldKey].label}
                          </Select.Option>
                          {unselectedLNCFields.map((field) => (
                            <Select.Option key={field.key} value={field.key} className="sf-lnc-field-option">
                              {field.icon && <img src={field.icon} width="24" height="24" alt="" />}
                              {field.label}
                            </Select.Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </div>
                </div>
                <div className="mapping-grid_item arrow">
                  {touched?.action?.metadata?.mapping?.[lncFieldKey] && errors?.action?.metadata?.mapping?.[lncFieldKey] && (
                    <ErrorTooltip isShown text={errors?.action?.metadata?.mapping?.[lncFieldKey]} />
                  )}
                  <img src={connector} width="128" height="12" alt="" />
                </div>
                <div className="mapping-grid_item">
                  <SalesforceFieldsOptions updateMapping={updateMapping} fields={data} mapping={mapping} lncFieldKey={lncFieldKey} />
                </div>
                <div className="mapping-grid_item remove" onClick={() => removeMapping(lncFieldKey)}>
                  {!LNCFieldsByKey[lncFieldKey].required && <Trash />}
                </div>
              </div>
            ))}
            {canAddMoreFields && (
              <div className="mapping-grid_line">
                <div>
                  <Button onClick={handleNewFieldClick} className="add-field-btn">Add Field</Button>
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      {error && (
        <div className="text-center error-text">
          {error?.response?.status === 400 ? <>{error.response.data.message}</> : <>We have encountered a general error</>}
        </div>
      )}
    </div>
  );
}

/*
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import lncIcon from '../../../../../../../../../../Assets/Images/icons/icon-lnc.png';
import salesforceIcon from '../../../../../../../../../../Assets/Images/icons/repo-icon-salesforce.svg';
import { useSalesforceFields } from './hooks/useSalesforce';
import { LNCFields, LNCFieldsByKey } from './_data/fields';
import SFMapLine from './line';

export default function SalesforceOpportunityMapping() {
  const { values: { action: { metadata } }, setFieldValue } = useFormikContext();
  const { fields: { error, data, isLoading } } = useSalesforceFields(metadata.object);
  const currentMapping = useMemo(() => Object.keys(metadata.mapping || {}).sort((a, b) => LNCFieldsByKey[b].required - LNCFieldsByKey[a].required), [metadata.mapping]);
  console.log(data);
  useEffect(() => {
    if (data) {
      const final = metadata.mapping || {};
      const required = LNCFields.filter((f) => f.required);
      required.forEach((f) => {
        if (!final?.[f.key]) {
          final[f.key] = f.autoMatch ? f.autoMatch(data)?.fullName || null : null;
        }
      });
      setFieldValue('action.metadata.mapping', final);
    } else {
      setFieldValue('action.metadata.mapping', metadata?.mapping || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="mapping-grid">
      <div className="mapping-grid_line">
        <div className="mapping-grid_head">
          <img src={lncIcon} width="24" height="24" alt="LNC" />
          Column on
          {' '}
          <span>LoudNClear</span>
        </div>
        <div className="mapping-grid_head" />
        <div className="mapping-grid_head">
          <img src={salesforceIcon} width="24" height="24" alt="LNC" />
          Column on
          {' '}
          <span>Salesforce</span>
        </div>
      </div>
      {data && (
        <>
          {currentMapping.map((lncFieldKey) => (
            <SFMapLine key={lncFieldKey} isRequired={LNCFieldsByKey[lncFieldKey].required} />
          ))}
        </>
      )}
    </div>
  );
}

*/
