import { Select } from 'antd';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import Dal from '../../../Data/Dal';
import PlatformTemplate, { PlatformDisplayValue } from './commonTemplates/platform';
import TimespanTemplate, { TimespanDisplayValue } from './commonTemplates/timespan';
import { ReactComponent as SelectDown } from '../../../Assets/Images/icons/select-down.svg';
import defaultIntegrations from '../../../Data/integrations';
import QuantificationTemplate from './aggregationFilters/qunatification';
import { OPERATOR_QUANT_PERCENTAGE_OPTIONS, OPERATOR_QUANT_NUMBER_OPTIONS } from './aggregationFilters/_config';

const { Option, OptGroup } = Select;

const AggregationFilterSchema = {
  validationSchema: Yup.object({
    timespan: Yup.mixed().required('Time is required'),
    platform: Yup.mixed().required('Platform is required'),
    has: Yup.mixed().required('Required'),
    operator: Yup.mixed().required('Operator is required').when('timespan', {
      is: (v) => v.toString() === 'all_time',
      then: (schema) => schema.notOneOf(OPERATOR_QUANT_PERCENTAGE_OPTIONS, 'Select another operator'),
      otherwise: (schema) => schema,
    }),
    quantification: Yup.mixed().required('Required').when('operator', {
      is: (v) => OPERATOR_QUANT_NUMBER_OPTIONS.includes(v),
      then: () => Yup.mixed(),
      otherwise: () => Yup.number().required('Required').typeError('Must be a number'),
    }),
    threshold: Yup.mixed(),
  }),
  fields: [
    {
      label: '',
      defaultValue: null,
      key: 'threshold',
      styles: {
        flexBasis: '0px',
      },
      showWhen: () => false,
      // Template: () => <div className="prefix_number" />,
    },
    {
      label: 'User on',
      key: 'platform',
      defaultValue: null,
      type: 'select',
      styles: {
        flexBasis: '130px',
      },
      Template: (props) => (
        <PlatformTemplate
          {...props}
          limitTo={['s3file',
            'mixpanelproject',
            'segmentworkspace',
            'elasticsearchindex',
            'appsflyerworkspace',
            'amplitudeworkspace',
            'pendoworkspace',
            'adobeanalyticsworkspace',
            'walmartworkspace',
            'firebaseworkspace',
            'snowflakeworkspace',
          ]}
        />
      ),
      DisplayValue: PlatformDisplayValue,
    },
    {
      label: 'Has a',
      key: 'has',
      defaultValue: null,
      // styles: {
      //   flexBasis: '130px',
      // },
      DisplayValue: ({ value }) => value === 'actions_count' ? 'Total' : value,
      Template: observer(({
        value, onChange, getValueByKey,
      }) => {
        const customs = toJS(Dal.customFields);
        const plat = getValueByKey('platform');
        const displayFields = useMemo(() => {
          const mapper = (f) => ({
            id: f.id,
            label: f.label,
            icon: defaultIntegrations.find((a) => a.targetTag === (f.service_name === 's3' ? 'csv' : f.service_name))?.image,
          });
          if (plat) {
            return customs.filter((f) => f.data_type === 'actions' && (f.service_name === 's3' ? 'csv' : f.service_name) === plat).map(mapper);
          }
          return customs.filter((f) => f.data_type === 'actions').map(mapper);
        }, [plat, customs]);
        useEffect(() => { // resetting on platform change
          if (!displayFields.find((x) => x.label === value)) {
            onChange('actions_count');
          }
        }, [displayFields]); // eslint-disable-line
        return (
          <Select showSearch dropdownMatchSelectWidth={false} placeholder="Select type" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
            <Option className="superfilter-option" value="actions_count">Total</Option>
            {displayFields.map((opt) => (
              <Option className="superfilter-option" key={opt.id} value={opt.label}>
                <img src={opt.icon} width="18" height="18" alt="" />
                {opt.label}
              </Option>
            ))}
          </Select>
        );
      }),
    },
    {
      label: 'Function',
      defaultValue: 'sum',
      key: 'agg_function',
      styles: {
        flexBasis: '115px',
      },
      DisplayValue: ({ value: opt }) => opt.charAt(0).toUpperCase() + opt.slice(1),
      Template: observer(({ value, onChange }) => (
        <Select dropdownMatchSelectWidth={false} placeholder="Select" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
          <Option className="superfilter-option" key="sum" value="sum">Sum</Option>
          <Option className="superfilter-option" key="min" value="min">Minimum</Option>
          <Option className="superfilter-option" key="max" value="max">Maximum</Option>
        </Select>
      )),
    },
    {
      label: 'Operator',
      defaultValue: null,
      key: 'operator',
      styles: {
        flexBasis: '115px',
      },
      DisplayValue: ({ value: opt }) => opt.charAt(0).toUpperCase() + opt.slice(1),
      Template: observer(({ value, getValueByKey, onChange }) => {
        const aggFunction = getValueByKey('agg_function');
        const isNotSum = useMemo(() => aggFunction !== 'sum', [aggFunction]);
        useEffect(() => {
          if (isNotSum && OPERATOR_QUANT_PERCENTAGE_OPTIONS.includes(value)) {
            onChange(null);
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value, isNotSum]);
        return (
          <Select dropdownMatchSelectWidth={false} placeholder="Select" suffixIcon={<SelectDown />} value={value} onChange={onChange}>
            {OPERATOR_QUANT_NUMBER_OPTIONS.map((opt) => (
              <Option className="superfilter-option" key={opt} value={opt}>{opt.charAt(0).toUpperCase() + opt.slice(1)}</Option>
            ))}
            {!isNotSum && OPERATOR_QUANT_PERCENTAGE_OPTIONS.map((opt) => (
              <Option className="superfilter-option" key={opt} value={opt}>{opt.charAt(0).toUpperCase() + opt.slice(1)}</Option>
            ))}
          </Select>
        );
      }),
    },
    {
      label: 'of',
      defaultValue: null,
      key: 'quantification',
      styles: {
        flexBasis: '50px',
      },
      DisplayValue: ({ value, getValueByKey }) => {
        const op = getValueByKey('operator');
        if (OPERATOR_QUANT_PERCENTAGE_OPTIONS.includes(op)) {
          return `${value}%`;
        }
        return value;
      },
      Template: QuantificationTemplate,
    },
    {
      label: 'In the',
      key: 'timespan',
      defaultValue: null,
      styles: {
        flexBasis: '130px',
      },
      Template: TimespanTemplate,
      DisplayValue: TimespanDisplayValue,
    },
  ],
};

export default AggregationFilterSchema;
