import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import Confetti from 'react-confetti';
import { Button } from 'antd';
import lottie from 'lottie-web';
import loadingAnim from '../../../../Assets/Lotties/field-mapper.json';
import StepWrapper from '../../common/stepWrapper';
import dalInstance from '../../../../Data/Dal';

function ConnectStep({ globalContext, setStep, updateContext, onIntegrationSuccess }) {
  const [phase, setPhase] = useState(0); // 0 - uploading, 1 - done
  const [animation, setAnimation] = useState(null);
  const lottieLoadContainer = useRef();
  const lottieSuccessContainer = useRef();

  const history = useHistory();

  useEffect(() => {
    if (lottieSuccessContainer && lottieLoadContainer && animation === null) {
      const finalData = { load: null, success: null };
      const loadAnim = lottie.loadAnimation({
        container: lottieLoadContainer.current,
        animationData: loadingAnim,
        renderer: 'svg', // "canvas", "html"
        loop: true, // boolean
        autoplay: true, // boolean
      });
      loadAnim.playSegments([0, 300], true);
      finalData.load = loadAnim;

      const sucAnim = lottie.loadAnimation({
        container: lottieSuccessContainer.current,
        animationData: loadingAnim,
        renderer: 'svg', // "canvas", "html"
        loop: false, // boolean
        autoplay: true, // boolean
      });
      sucAnim.playSegments([300, 600], true);
      finalData.success = sucAnim;
      setAnimation(finalData);
    }
  }, [lottieLoadContainer, lottieSuccessContainer, animation]);
  useEffect(() => {
    if (phase === 1) {
      animation.success.playSegments([300, 600], true);
    }
  }, [phase, animation]);
  useEffect(() => {
    (async () => {
      try {
        const assetId = await dalInstance.uploadCsvFile(globalContext.file);
        await dalInstance.mapCsvFile(globalContext.listType, assetId, globalContext.mapping);
        setPhase(1);
        setStep((cur) => cur + 1);
        onIntegrationSuccess();
      } catch (e) {
        updateContext({ filePostError: globalContext.file.name });
        setStep(1);
      }
    })();
  }, [globalContext]); // eslint-disable-line
  return (
    <StepWrapper step="connect" className="step-component step-connect">
      {phase === 1 && (
        <div className="confetti-container">
          <Confetti
            width={860}
            height={250}
            gravity={0.2}
          />
        </div>
      )}
      <div className="inner">
        <div className="processing">
          <div className={`lotties ${phase === 1 ? 'success' : 'loading'}`}>
            <div className="lotties-loading" ref={lottieLoadContainer} />
            <div className="lotties-success" ref={lottieSuccessContainer} />
          </div>
          <div className="processing-title">{phase === 1 ? 'Good to go!' : 'We are on it!'}</div>
          <div className="processing-subtitle">{phase === 1 ? '' : 'This can take up to a minute.'}</div>
          {phase === 1 && (<Button onClick={() => history.push('/dashboard/crm')}>Check out your CRM</Button>)}
        </div>
      </div>
    </StepWrapper>
  );
}

export default observer(ConnectStep);
