import { getCoreRowModel, flexRender, useReactTable } from '@tanstack/react-table';
import { useVirtual } from '@tanstack/react-virtual';
import {
  memo, useCallback, useMemo, useRef,
} from 'react';
import chevronDown from '../../Assets/Images/icons/menuchevron-chevron-down.svg';
import './style.scss';

function StylizedTable({
  columns,
  shouldShowSkeletonOnEmptyResult,
  infiniteQuery,
  skeletons,
  sorting = [{ id: 'date', desc: true }],
  setSorting,
  onRowClick,
}) {
  const tableContainerRef = useRef();
  const {
    data, isFetching, fetchNextPage, hasNextPage,
  } = infiniteQuery;

  const flatData = useMemo(() => {
    const flat = data?.pages?.map((p) => p.data).flat() ?? [];
    if (isFetching || (flat.length === 0 && shouldShowSkeletonOnEmptyResult)) {
      return [...flat, ...skeletons];
    }
    return flat;
  }, [data, skeletons, shouldShowSkeletonOnEmptyResult, isFetching]);

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting,
    },
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < 300 && !isFetching && hasNextPage) {
          // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, hasNextPage],
  );

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return (
    <div className="absolute-box" onScroll={(e) => fetchMoreOnBottomReached(e.target)}>
      <div className="table-container general-style" ref={tableContainerRef}>
        <table
          {...{
            style: {
              width: table.getCenterTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    {...{
                      key: header.id,
                      colSpan: header.colSpan,
                      style: {
                        width: header.getSize(),
                      },
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: '', // TODO: Not for now
                      desc: <img src={chevronDown} width="14" height="14" alt="" />,
                    }[header.column.getIsSorted()] ?? null}

                    <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                      }}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <tr key={row.id} onClick={() => onRowClick(row)}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default memo(StylizedTable);
