import { useContext, useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as Dragger } from '../../../Assets/Images/icons/dashboards/draghandle.svg';
import { ReactComponent as EditIcon } from '../../../Assets/Images/icons/dashboards/edit-widget.svg';

import { BoardContext } from '../hooks/useBoard';
import GhostButton from '../../Buttons/ghostButton';
import { WidgetContext } from '../hooks/useWidget';
import iconsMapping from '../_data/iconsMapping';
import SkeletonLine from '../../Skeletons/line';

import { all as allDimensions } from '../_data/options/dimension';
import { all as allMeasureFuncs } from '../_data/options/measure_funcs';
import MoreMenu from './dropdowns/more';
import TimerangeMenu from './dropdowns/timerange';
import TimeIntervalMenu from './dropdowns/timeinterval';

const upperFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export default function WidgetHeader() {
  const { editMode, setEdittorModal } = useContext(BoardContext);
  const { widgetQuery: { data }, deleteMutation } = useContext(WidgetContext);
  const title = useMemo(() => {
    if (data && data.config) {
      const Icon = iconsMapping.measureY[data.config.measureY];
      const dimensionLabel = allDimensions?.[data.config.dimension]?.label || data.config.dimension;
      const meausreFuncLabel = allMeasureFuncs?.[data.config.measureFunc]?.label || data.config.measureFunc;
      return (
        <>
          <div className="widget-wrapper_header_title_icon"><Icon /></div>
          {data.config.name || upperFirstLetter(`${data.config.measureY} ${meausreFuncLabel} by ${dimensionLabel}`)}
        </>
      );
    }
    return <><SkeletonLine style={{ width: '140px', marginLeft: '5px', height: '20px' }} /></>;
  }, [data]);
  return (
    <div className="widget-wrapper_header">
      <div className="widget-wrapper_header_title">
        <AnimatePresence>
          {editMode && (
            <motion.div
              initial={{ width: 0, overflow: 'hidden', opacity: 0 }}
              animate={{ width: 'auto', overflow: 'hidden', opacity: 1 }}
              exit={{ width: 0, overflow: 'hidden', opacity: 0 }}
              className="drag-handle"
            >
              <Dragger />
            </motion.div>
          )}
        </AnimatePresence>
        {title}
      </div>
      <div className="widget-wrapper_header_actions">
        {data?.config?.timerange && (
          <div className="action-item">
            <TimerangeMenu widgetId={data?.config.id} />
          </div>
        )}
        {data?.config?.timespan && (
          <div className="action-item">
            <TimeIntervalMenu widgetId={data?.config.id} />
          </div>
        )}
        <div className="action-item">
          <GhostButton onClick={() => setEdittorModal(data?.config)} styles={{ padding: '0 2px', height: '30px' }} icon={<EditIcon />} />
        </div>
        <div className="action-item">
          <MoreMenu openEdittor={() => setEdittorModal(data?.config)} onDelete={() => deleteMutation.mutate()} />
        </div>
      </div>
    </div>
  );
}
