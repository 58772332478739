/* eslint-disable react/destructuring-assignment */
import { Col, Row } from 'antd';
import emptyStateIcon from '../../../Assets/Images/redesign/segments-ico.svg';

import './styles.scss';

function CrmEmptySegmentView({ canEdit, cta }) {
  return (
    <div className="dashboard-loading-state-content">
      <Col>
        <Row>
          <img
            src={emptyStateIcon}
            alt="empty"
            style={{ position: 'relative', display: 'block', margin: '0 auto' }}
          />
        </Row>
        <Row
          className="dashboard-empty-state-title"
          style={{ marginTop: '30px' }}
        >
          No users in segment... yet
        </Row>
        {canEdit && (
          <Row
            className="dashboard-empty-state-body"
            style={{ marginTop: '30px' }}
          >
            You can always
            <a onClick={cta} style={{ margin: '0 6px', color: '#0084ff', cursor: 'pointer' }}> { /* eslint-disable-line */ }
              edit
            </a>
            your segment conditions.
          </Row>
        )}
        {/* <Row onClick={this.importClicked.bind(this)}  style={{marginTop: '30px'}} className="dashboard-empty-state-body">
                        <Link to={'/dashboard/integrations?importCsv=true'} onClick={() => this.importClicked()}>
                            <a className="dashboard-empty-state-body" style={{color: '#0084ff', cursor: "pointer"}}>Import contacts</a>
                        </Link>
                    </Row> */}
      </Col>
    </div>
  );
}

export default CrmEmptySegmentView;
