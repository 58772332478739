import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Select } from 'antd';
import { useMemo } from 'react';
import dalInstance from '../../../../Data/Dal';
import { ReactComponent as SelectDown } from '../../../../Assets/Images/icons/select-down.svg';

const { Option } = Select;

const PlatformTemplate = observer(({
  value, onChange, limitTo, exclude,
}) => {
  const integrations = useMemo(() => {
    const connected = [...new Set(toJS(dalInstance.sources).map((src) => `${src.platform_name}`))];
    const ints = [];
    toJS(dalInstance.integrations).forEach((int) => {
      const targetTag = int.targetTag === 'csv' ? 's3' : int.targetTag;
      const assetTag = int.assetTag === 'csv' ? 's3file' : int.assetTag;
      if (connected.includes(targetTag) && !ints.find((i) => i.targetTag === targetTag)) {
        ints.push({
          ...int,
          targetTag,
          assetTag,
        });
      }
    });
    return ints.filter((c) => {
      if (limitTo) return limitTo.includes(c.assetTag);
      if (exclude && exclude.includes(c.assetTag)) return false;
      return true;
    }).map((int) => ({
      name: int.shortTitle,
      targetTag: int.targetTag === 's3' ? 'csv' : int.targetTag,
      image: int?.image,
    }));
  }, [limitTo, exclude]);
  return (
    <Select placeholder="Select" dropdownMatchSelectWidth={false} suffixIcon={<SelectDown />} value={value} onChange={onChange}>
      {integrations.map((int) => (
        <Option className="superfilter-option" key={int.targetTag} value={int.targetTag}>
          <img src={int.image} />
          <span>{int.name}</span>
        </Option>
      ))}
    </Select>
  );
});

export const PlatformDisplayValue = observer(({ value }) => {
  const int = dalInstance.integrations.find((f) => f.targetTag === value);
  // console.log('v', value);
  if (!int) return value;
  return (
    <div className="superfilter-option">
      <img width="20" height="20" alt="platform" src={int.image} />
      <span>{int.shortTitle}</span>
    </div>
  );
});

export default PlatformTemplate;
