import { Link } from 'react-router-dom';
import { useState } from 'react';
import WatchIcon from '../../../../Assets/Images/icons/icon-20-x-20-ic-20-play-circle.svg';
import WatchIconHover from '../../../../Assets/Images/icons/icon-20-x-20-ic-20-play-circle (1).svg';
import HoverImg from '../../../../Assets/Images/hoverstate-texture.png';
/**
 *
 * @param {String} c2aText Button text
 * @param {String} c2aLink Link to
 * @param {String} video URL to video
 */
export default function Box({
  c2aText,
  c2aLink,
  video,
  title,
  icon,
  text,
  minified,
  openVideo,
}) {
  const [hover, setHover] = useState(false);
  return (
    <div
      className="welcomebox"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="welcomebox-hover">
        <img src={HoverImg} width="390" alt="hover" />
      </div>
      <div className="welcomebox-inner">
        <div className="welcomebox-icon">
          <img src={icon} alt="icon" width="80" height="80" />
        </div>
        <div className="welcomebox-text">
          <div className="inner">{minified ? title : text}</div>
        </div>
        <div className="welcomebox-actions">
          <div
            tabIndex="0"
            role="button"
            onClick={() => openVideo(video)}
            className="welcomebox-actions_watch"
          >
            <span>
              <img
                src={hover ? WatchIconHover : WatchIcon}
                width="20"
                height="20"
                alt="watch"
              />
            </span>
            <span>Watch</span>
          </div>
          <Link to={c2aLink} className="welcomebox-actions_c2a">
            <div className="welcome-btn">{c2aText}</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
