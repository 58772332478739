import { Dropdown, Menu } from 'antd';
import { useMemo } from 'react';
import Switch from '../Switch';
import './styles.scss';

export default function DropdownMenu({ button, items, onVisibleChange }) {
  const menu = useMemo(() => (
    <Menu className="at-dropdown">
      {items.map((item) => (
        !item.divider ? (
          <Menu.Item
            onClick={item.onClick}
            style={item.disabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}
            className={`${item.danger ? 'is-danger' : ''} ${item.isSelected ? 'is-selected' : ''} ${`dropdown-item_${item.key}`}`}
            key={item.key}
          >
            {item.toggle ? (
              <Switch isChecked={item.toggle.isChecked} onChange={() => null} label="" />
            ) : (
              item.icon && item.icon
            )}
            <span>{item.label}</span>
          </Menu.Item>
        ) : (
          <div className="at-dropdown_divider" key={item.key} />
        )
      ))}
    </Menu>
  ), [items]);
  return (
    <Dropdown overlayClassName="at-dropdown-overlay" onVisibleChange={onVisibleChange} overlay={menu}>
      <a> {/* eslint-disable-line */}
        {button}
      </a>
    </Dropdown>
  );
}
