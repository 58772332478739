/* eslint-disable no-mixed-operators */
// import useDictionaryQueries from '../../../../Utils/community/dictionaries/useDictionaryQueries';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import WidgetWrapper from '../wrapper';
import { useCommunityContext } from '../../../../Utils/contexts/community';
import { platformColorsByBg } from '../../../../Data/colors';
import widgetSkeletons from './_skeletons';
import kFormatter from '../../../../Utils/funcs/kFormatter';
import { ReactComponent as Icon } from '../../../../Assets/Images/icons/dictionaries/bykeyword.svg';

export default function DictionaryWidgetKeywords({ query }) {
  const { dictionaries } = useCommunityContext();
  const { dictId } = useParams();
  const thisDict = useMemo(() => dictionaries[dictId], [dictId, dictionaries]);
  const colors = useMemo(() => platformColorsByBg[thisDict.color], [thisDict]);
  const series = useMemo(() => {
    if (query.isLoading) return widgetSkeletons.keywords.series;
    const data = query?.data?.keywords || {};
    const highest = Object.keys(data).reduce((acc, cur) => Math.max(data[cur], acc), 0);
    return Object.keys(data).reduce((acc, keywordId) => {
      const kw = thisDict.keywords.find((x) => Number(x.id) === Number(keywordId))?.keyword;
      if (!kw) return acc;
      return [
        ...acc,
        {
          id: keywordId,
          keyword: kw,
          width: data[keywordId] / highest * 100,
          count: kFormatter(data[keywordId]),
          rawCount: data[keywordId],
        },
      ];
    }, []).sort((a, b) => b.rawCount - a.rawCount);
  }, [query.data, query.isLoading, thisDict]);
  return (
    <WidgetWrapper
      className="widget-bykeywords"
      title={(
        <>
          <Icon />
          {' '}
          Matches by keywords
        </>
      )}
      isEmpty={series.length === 0}
    >
      <div className="info-table">
        {series.map((dataPoint) => (
          <div key={dataPoint.id} className="info-table_row">
            <div>
              <span style={query.isLoading ? {} : { backgroundColor: colors.bgColor, color: colors.textColor }}>{dataPoint.keyword}</span>
            </div>
            <div>{dataPoint.count}</div>
            <div>
              <div className="bar" style={{ backgroundColor: query.isLoading ? '#C7CED3' : colors.bgColor, width: `${dataPoint.width}%` }} />
            </div>
          </div>
        ))}
      </div>
    </WidgetWrapper>
  );
}
