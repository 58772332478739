import { useInfiniteQuery, useQuery } from 'react-query';
import { useMemo } from 'react';
import NetworkManager from '../../../Network/NetworkManager';
import { useCommunityContext } from '../../../Utils/contexts/community';
import filtersSanitize from '../queries/utils/filtersSanitize';

export default function useQueryQueries({ filters = [] }) {
  const { communityId } = useCommunityContext();
  const sanitized = useMemo(() => filtersSanitize(filters), [filters]);
  const matches = useInfiniteQuery(
    ['queries', 'mentions', sanitized],
    ({ pageParam = 1 }) => NetworkManager.getMentions(communityId, { filters: sanitized, page: pageParam, recordsPerPage: 70 }),
    {
      staleTime: 1000 * 30, // 30sec
      cacheTime: 1000 * 10, // 10sec
      enabled: !!filters,
      retry: 7,
      retryDelay: 3000 * 1,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      placeholderData: {
        total: 0,
        data: [],
      },
      refetchInterval: (response) => {
        if (response?.pages?.[0]?.data?.length === 0) return 1000 * 60;
        return false;
      },
      getNextPageParam: (lastPage) => lastPage.nextPage,
      onSuccess: (resp) => {
        // dalInstance.updateDictionaryCount(Number(dictId), resp.pages?.[0]?.total);
      },
    },
  );
  const widgets = useQuery(
    ['queries', 'widgets', sanitized],
    () => NetworkManager.getWidgetsForQuery(communityId, { filters: sanitized }),
    {
      staleTime: 1000 * 60 * 60,
      enabled: !!filters,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  return {
    matches,
    widgets,
  };
}
