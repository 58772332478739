import { useQuery } from 'react-query';
import NetworkManager from '../../../../Network/NetworkManager';
import { useCommunityContext } from '../../../../Utils/contexts/community';

export default function useHistory(contactId) {
  const { communityId } = useCommunityContext();
  const history = useQuery(['crm', contactId, 'history'], () => NetworkManager.getMemberHistory(communityId, contactId), {
    retry: 5,
    retryDelay: 1700 * 1,
    staleTime: 1000 * 60 * 10,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  return {
    history,
  };
}
