import { Button } from 'antd';
import { motion } from 'framer-motion';
import KeywordChip from '../../common/chip';

export default function ExampleBox({
  colors, keywords, title, onClick, ind, initted,
}) {
  return (
    <motion.div
      initial={{ y: '150px', opacity: 0 }}
      animate={{ y: 0, opacity: 1, transition: { delay: (initted ? 0 : 0.5) + (ind * 0.1), duration: 0.5, ease: 'easeOut' } }}
      whileHover={{ scale: 1.04 }}
      className="example-box"
      onClick={onClick}
    >
      <div className="example-box_main">
        <div className="example-box_main_title">
          {title}
        </div>
        <div className="example-box_main_keywords">
          {keywords.map((kw) => (
            <KeywordChip label={kw} key={kw} colors={colors} />
          ))}
        </div>
      </div>
      <div className="example-box_actions">
        <Button className="example-box_actions_btn">Add</Button>
      </div>
    </motion.div>
  );
}
