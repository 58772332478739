import { Button } from 'antd';

export default function SaveChanges({ onCancel, onSave, isLoading }) {
  return (
    <div className="save-changes">
      <div className="save-changes_descs">
        <div>Edit board</div>
        <div>Drag widgets to reorder, resize and personalize your board</div>
      </div>
      <div className="save-changes_btns">
        <Button className="btn_back" onClick={onCancel}>Cancel</Button>
        <Button className="btn_next" loading={isLoading} onClick={onSave}>Done</Button>
      </div>
    </div>
  );
}
