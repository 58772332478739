import DataTypeStep from './dataType';
import MapperStep from './mapper';
import LoadStep from './load';
import ConnectStep from './connect';

const stepsConfig = [
  {
    id: 0,
    label: 'Data type',
    class: 'step-datatype',
    Component: DataTypeStep,
  },
  {
    id: 1,
    label: 'Upload',
    class: 'step-upload',
    Component: LoadStep,
  },
  {
    id: 2,
    label: 'Validate fields',
    class: 'step-map',
    Component: MapperStep,
  },
  {
    id: 3,
    label: 'Connect',
    class: 'step-connect',
    Component: ConnectStep,
  },
];

export default stepsConfig;
