import { useQuery } from 'react-query';
import { useCommunityContext } from '../../../Utils/contexts/community';
import NetworkManager from '../../../Network/NetworkManager';

export default function useQueriesQueries() {
  const { communityId } = useCommunityContext();
  const queries = useQuery([communityId, 'queries'], () => NetworkManager.getAllQueries(communityId), {
    enabled: !!communityId,
    staleTime: Infinity,
  });

  return {
    queries,
  };
}
