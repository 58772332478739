import emptyStateIcon from '../../../Assets/Images/redesign/no-filter-match-ico.svg';
import { useCommunityContext } from '../../../Utils/contexts/community';
import useDictionaryContext from '../context/dictionary.context';

export default function EmptyDictResultState() {
  const { setDictModal, dictId } = useDictionaryContext();
  const { dictionaries } = useCommunityContext();
  return (
    <div className="dict-empty result-state">
      <img src={emptyStateIcon} alt="" width="80" height="80" />
      <div className="dict-empty_title">No matches... yet</div>
      <div className="dict-empty_desc">
        You can always
        {' '}
        <span className="dict-empty_link" onClick={() => setDictModal(dictionaries[dictId])}>edit</span>
        {' '}
        your dictionary conditions
      </div>
    </div>
  );
}
