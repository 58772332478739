import React from 'react';

import { observer } from 'mobx-react';
import { List, Avatar } from 'antd';
import Dal from '../../../Data/Dal';

import checkboxDeselectedIcon from '../../../Assets/Images/icons/checkmark-circ-deselected.svg';
import checkboxSelectedIcon from '../../../Assets/Images/icons/checkmark-circ-selected.svg';
import checkboxDisabledIcon from '../../../Assets/Images/icons/checkmark-circ-disabled.svg';

class ChannelsSelect extends React.Component {
  isChannelConnected(channel) {
    let isConnected = false;
    Dal.assets.accounts.forEach((asset) => {
      if (asset.asset_id == channel.id) { // eslint-disable-line
        isConnected = true;
      }
    });
    return isConnected;
  }

  getCheckboxIcon(channel) {
    if (this.isChannelConnected(channel)) {
      return checkboxDisabledIcon;
    }
    if (channel.isSelected) {
      return checkboxSelectedIcon;
    }
    return checkboxDeselectedIcon;
  }

  getLabelForConnectedChannel(channel) {
    if (this.isChannelConnected(channel) === true) {
      return <span className="connected-channel-label">| Connected</span>;
    }
    return null;
  }

  onChannelClick(channel) {
    if (!this.isChannelConnected(channel)) {
      this.props.onChannelClick(channel);
    }
  }

  doesFilterByTypeMatch(item) {
    if (this.props.filterByType === 'all') {
      return true;
    }
    if (this.props.filterByType === 'admin') {
      return item.tag === 'Admin';
    }
    if (this.props.filterByType === 'public') {
      return item.tag !== 'Admin';
    }
  }

  doesFilterByNameMatch(item) {
    return (
      this.props.filterValue === ''
      || (this.props.filterValue !== ''
        && item.name.toLowerCase().includes(this.props.filterValue.toLowerCase()))
    );
  }

  doesItemMatchFilters(item) {
    if (this.props.filterValue === '') {
      // if no search by feed name
      if (this.props.filterByType !== undefined) {
        // if there is also by-type filter (reddit)
        return this.doesFilterByTypeMatch(item);
      }
      return true;
    } if (
      this.props.filterValue !== ''
      && this.props.filterByType === undefined
    ) {
      // only filter by feed name
      return this.doesFilterByNameMatch(item);
    } if (
      this.props.filterValue !== ''
      && this.props.filterByType !== undefined
    ) {
      return (
        this.doesFilterByNameMatch(item) && this.doesFilterByTypeMatch(item)
      );
    }
  }

  renderItem(item) {
    if (this.doesItemMatchFilters(item)) {
      return (
        <List.Item
          onClick={() => this.onChannelClick(item)}
          className={
            this.isChannelConnected(item)
              ? 'integration-channels-select-list-item disabled'
              : item.isSelected
                ? 'integration-channels-select-list-item selected'
                : 'integration-channels-select-list-item'
          }
          extra={
            item.tag ? (
              <div className="integration-channels-select-tag">
                <p className="integration-channels-select-tag-label">
                  {item.tag}
                </p>
              </div>
            ) : null
          }
        >
          <img
            src={this.getCheckboxIcon(item)}
            alt=""
            width={24}
            height={24}
            style={{ marginRight: 36, marginLeft: 20 }}
          />
          <List.Item.Meta
            avatar={<Avatar src={item.picture} shape="square" size={60} />}
            title={item.name}
            description={(
              <span>
                {item.followers_count}
                {' '}
                followers
                {' '}
                {this.getLabelForConnectedChannel(item)}
              </span>
            )}
          />
        </List.Item>
      );
    }
    return null;
  }

  render() {
    return (
      <List
        className="integration-channels-select-list"
        itemLayout="horizontal"
        dataSource={this.props.channels ?? []}
        split={false}
        renderItem={(item) => this.renderItem(item)}
      />
    );
  }
}

export default observer(ChannelsSelect);
