/* import {
   CarouselProvider,
  Slider,
  Slide,
// ButtonBack,
// ButtonNext,
} from 'pure-react-carousel'; */
// import { useState, useLayoutEffect } from 'react';
// import { runInAction } from "mobx";
import 'pure-react-carousel/dist/react-carousel.es.css';
import Box from './box';

// import arrow from "../../../../Assets/Images/icons/general-arrow-line-ui-arrow-line-ico-left.svg";
import FirstIcon from '../../../../Assets/Images/icons/boarding-ico-1.svg';
import SecondIcon from '../../../../Assets/Images/icons/boarding-ico-2.svg';
import ThirdIcon from '../../../../Assets/Images/icons/boarding-ico-3.svg';

const boxes = [
  {
    key: 'one',
    icon: FirstIcon,
    title: 'Create a set',
    c2aText: 'Add Set',
    c2aLink: '/dashboard/data?createSet',
    video: 'https://player.vimeo.com/video/674776590',
    text: (
      <div>
        <span>Create a Set</span>
        {' '}
        to better understand what you customers are
        talking about anywhere they are.
      </div>
    ),
  },
  {
    key: 'two',
    icon: SecondIcon,
    title: 'Connect more platforms',
    c2aText: 'Connect',
    c2aLink: '/dashboard/integrations',
    video: 'https://player.vimeo.com/video/674776696',
    text: (
      <div>
        <span>Connect more platforms</span>
        {' '}
        to make sure you are on top of any
        customer interaction.
      </div>
    ),
  },
  {
    key: 'three',
    icon: ThirdIcon,
    title: 'Invite team members',
    c2aText: 'Invite',
    c2aLink: '?invite',
    video: 'https://player.vimeo.com/video/674776666',
    text: (
      <div>
        <span>Invite team members</span>
        {' '}
        and and start collaborating, assign
        tasks, and build your workspace together.
      </div>
    ),
  },
];

/*
const Btn = ({ isForward }) => {
  return (
    <div className="welcome-slider_btn">
      <img
        src={arrow}
        style={{ transform: `rotate(${isForward ? 180 : 0}deg)` }}
        width="24"
        height="24"
        alt="arrow"
      />
    </div>
  );
}; */

export default function WelcomeSlider({ openVideo, minified }) {
  // const [visibleSlides, setSlides] = useState(3);
  /* useLayoutEffect(() => {
    setSlides(window.innerWidth > 1300 ? 3 : 2);
  }, []); */
  return (
    <div className="welcome-slider">
      <div className="welcome-slider_overlay" />
      <div style={{ display: 'flex' }}>
        {boxes.map((box) => (
          <Box key={box.key} minified={minified} openVideo={openVideo} {...box} />
        ))}
      </div>
    </div>
  );
}
