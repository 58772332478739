import { useQuery } from 'react-query';
import { useCommunityContext } from '../../contexts/community';
import NetworkManager from '../../../Network/NetworkManager';

export default function useTopicsQueries() {
  const { communityId } = useCommunityContext();
  const topics = useQuery([communityId, 'topics'], () => NetworkManager.getCommunityTopics(communityId), {
    staleTime: 1000 * 60 * 60,
    refetchOnMount: false,
  });
  return { topics };
}
