import { useState } from 'react';

export default function useDisclosure(initial) {
  const [isOpen, setOpen] = useState(initial ?? false);
  return {
    isOpen,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onToggle: () => setOpen((op) => !op),
  };
}
